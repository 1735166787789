import NProgress from "nprogress";
import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import {
  HRMS_UTILITY_API_URL,
  ADMIN_WORKSPACE,
  BASE_URL
} from "../../../Constants/baseConstant";
import { v4 as uuidv4 } from "uuid";
import { AddEmailTokenHelper } from "../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { renderEmail } from "react-html-email";
import BaseEmailTemplate from "../../Base/views/BaseEmailTemplate";
import SignUpEmailTemplate from "../../SignUp/views/SignUpEmailTemplate";
import { EmailHelper } from "../../Ancillary/Email/helper/EmailHelper";

export const AddExpirePlansHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/plans/PlansExpire`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: ADMIN_WORKSPACE,
      request_demo_id: params?.request_demo_id,
      planid: params?.planid,
      plan_day: params?.plan_day,
      expiry_days:params?.expiry_days,
      access_grp_org: params?.access_grp_org,
      access_face_recg: params?.access_face_recg
    },
  })
    .then((res) => {
      NProgress.done();
      toast.dismiss();
      let domain = res?.data?.data?.domainname;
      let token = uuidv4() + `=${domain}`;
      AddEmailTokenHelper({
        token: token,
        email_id: params?.emailid,
        domain: domain,
      });
      let cc = res?.data?.data?.cc;
      let content = renderEmail(
        <BaseEmailTemplate>
          <SignUpEmailTemplate
            name={params?.full_name}
            guid={token}
            workspace={domain}
            userid={params?.emailid}
          />
        </BaseEmailTemplate>
      );
      EmailHelper({
        to: params?.emailid,
        cc: cc,
        subject: BASE_URL === "https://meepl.day" ? "Welcome to MEEPL":"[QA] Welcome to MEEPL",
        content: content,
      });
      toast.success("User Enable Sucessfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
