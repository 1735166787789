import * as ActionTypes from '../ActionTypes';

const InitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: "",
    updateSuccess: false
}

export const LeaveApprovalReducer = function (state = InitialState, action) {
    switch (action.type) {
        case ActionTypes.LEAVE_APPROVAL_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.LEAVE_APPROVAL_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_APPROVAL_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.LEAVE_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.LEAVE_STATUS_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_STATUS_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}