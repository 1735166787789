import { Autocomplete, CircularProgress, createFilterOptions, TextField } from "@mui/material";
import React from "react";

const filter = createFilterOptions();

const CreatableSearch = (props) => {
    const [open, setOpen] = React.useState(false);
    const loading = open && props?.data?.values?.length === 0 ? true : false;
    var optionLabel = props?.fieldLabel || "optionname"

    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        if (active) {
            props?.loading();
        }
        return () => {
            active = false;
        };
        //eslint-disable-next-line
    }, [loading, props?.data?.values]);
    const handleOptionRender = (props, option, { selected }) => {
        const isLabelOption = option.designation_id === null;
        return (
            <li
                {...props}
                style={{
                    fontStyle: isLabelOption ? 'italic' : 'normal',
                    color: isLabelOption ? 'grey' : 'black',
                    cursor: isLabelOption ? 'not-allowed' : 'pointer',
                }}
                aria-disabled={isLabelOption}
            >
                {option[optionLabel]}
            </li>
        );
    };

    return (
        <React.Fragment>
            <Autocomplete
                multiple={props?.multiple}
                freeSolo={props?.disabled}
                disabled={props?.disabled}
                size="small"
                limitTags={3}
                noOptionsText={props?.noOptionText || "No Options"}
                value={props?.value}
                id={props?.id}
                name={props?.name}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(event, newValue) => {
                    if (newValue && newValue?.inputValue) {
                        // Create a new value from the user input
                        props?.onChangeEvent(props?.name, { id: null, [optionLabel]: newValue.inputValue });
                    } else if (typeof newValue === 'string') {
                        props?.onChangeEvent(props?.name, newValue);
                    } else {
                        props?.onChangeEvent(props?.name, newValue);
                    }
                }}
                filterSelectedOptions
                getOptionLabel={(option) => {
                    if (option && option[optionLabel]) {
                        return option[optionLabel];
                    } else {
                        return "";
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = props?.data?.values?.some((option) => inputValue === option[optionLabel]);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            [optionLabel]: `Add "${inputValue}"`,
                        });
                    }
                    return filtered;
                }}
                options={
                    props.showAddOption 
                        ? [{ designation_id: null, [optionLabel]: props?.OptionName }, ...(props?.data?.values || [])] 
                        : props?.data?.values || []
                }
                loading={props?.data?.load}
                renderOption={handleOptionRender}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props?.label}
                        autoFocus={props?.autoFocus}
                        required={props?.required}
                        variant={props?.variantType ? props?.variantType : "outlined"}
                        name={props?.name}
                        onBlur={props?.onBlur}
                        error={props?.error}
                        size={props?.size}
                        helperText={props?.helperText}
                        inputprops={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {props?.data?.load ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps?.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                {...(props?.isOptionEqualToValue && { isOptionEqualToValue: props?.isOptionEqualToValue })}
            />
        </React.Fragment>
    )
}

export default CreatableSearch;
