import * as ActionTypes from '../ActionTypes';

export const GetLeaveOverallBalanceAction = (show) => {
    return {
        type: ActionTypes.LEAVE_OVERALL_BALANCE_GET_REQUEST,
        show
    }
}

export const GetPendingLeaveApprovalAction = () => {
    return {
        type: ActionTypes.PENDING_LEAVE_APPROVAL_GET_REQUEST
    }
}
