import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddPaygradeAction, DeletePaygradeAction, UpdatePaygradeAction } from "../../../../../store/Ancillary/Paygrade/Action";
import { useEffect } from "react";
import { GetSalaryStructureAction } from "../../../../../store/Finance/SalaryStructure/Action";

const Paygrade = () => {

    const dispatch = useDispatch()
    const { paygrade_options } = useSelector(state => state?.AncillaryReducer?.PaygradeReducer);
    const { salaryStructure_data } = useSelector(state => state?.FinanceReducer?.SalaryStructureReducer);

    useEffect(() => {
        dispatch(GetSalaryStructureAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "Name", cellStyle: { padding: "0px 10px" }, field: "name", validate: rowData => rowData?.name === '' || rowData?.name === undefined ? 'Name cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        },
                        {
                            title: "Paygrade Code", cellStyle: { padding: "0px 10px" }, field: "pay_grade_code", validate: rowData => rowData?.pay_grade_code === '' || rowData?.pay_grade_code === undefined ? 'Paygrade Code cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            ),
                        },
                        {
                            title: "Pay Scale", cellStyle: { padding: "0px 10px" }, field: "pay_scale", validate: rowData => rowData?.pay_scale === '' || rowData?.pay_scale === undefined ? 'Payscale cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    type="number"
                                    value={props.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            ),
                        },
                        {
                            title: "Pay Structure", cellStyle: { padding: "0px 10px" }, field: "pay_structure", validate: rowData => {
                                if (rowData?.pay_structure) {
                                    return ''
                                } else {
                                    return 'Pay Structure cannot be empty'
                                }
                            },
                            editComponent: props => (
                                <Autocomplete
                                    defaultValue={{ name: props.rowData?.pay_structure || "", id: props.rowData?.sal_structure_id || "" }}
                                    id="businessunit_id"
                                    options={salaryStructure_data}
                                    onChange={(e, data) => { props.onChange(data) }}
                                    size="small"
                                    required={true}
                                    getOptionLabel={option => { return option?.name }}
                                    renderInput={(params) => <TextField size="small" {...params}
                                        variant="standard"
                                        error={props?.error}
                                        helperText={props?.helperText} />}
                                />
                            ),
                        },
                        {
                            title: "Minimum Pay", cellStyle: { padding: "0px 10px" }, field: "min_pay_pmon", validate: rowData => rowData?.min_pay_pmon === '' || rowData?.min_pay_pmon === undefined ? 'Minimun Pay cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props.value}
                                    type="number"
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            ),
                        },
                        {
                            title: "Maximum Pay", cellStyle: { padding: "0px 10px" }, field: "max_pay_pmon", validate: rowData => rowData?.max_pay_pmon === '' || rowData?.max_pay_pmon === undefined ? 'Maximum Pay cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    type="number"
                                    variant="standard"
                                    value={props.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            ),
                        },
                    ]}
                    data={paygrade_options || []}
                    icons={{
                        Add: () => <AddIcon />,
                        Edit: () => <EditIcon />,
                        Delete: () => <DeleteIcon />
                    }}
                    editable={{
                        onRowAdd: (newRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(AddPaygradeAction(newRow))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(UpdatePaygradeAction(newRow))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowDelete: (selectedRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(DeletePaygradeAction({ paygrade_id: `{${selectedRow?.paygrade_id}}` }))
                                setTimeout(() => resolve(), 1000);
                            }),
                    }}
                    options={{
                        maxBodyHeight: 220,
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: false,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    title=""
                />
            </Box>
        </>
    );
}

export default Paygrade;
