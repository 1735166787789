import * as ActionTypes from '../ActionTypes';

export const CreatedSchemasGetAction = (params) => {
    return {
        type: ActionTypes.CREATED_SCHEMAS_GET_REQUEST,
        params
    }
}

export const EnableWorkspaceAction = (params) => {
    return {
        type: ActionTypes.ENABLE_WORKSPACE_REQUEST,
        params
    }
}

export const DisableWorkspaceAction = (params) => {
    return {
        type: ActionTypes.DISABLE_WORKSPACE_REQUEST,
        params
    }
}

export const UserArchiveAction = (params) => {
    return {
        type: ActionTypes.USER_ARCHIVE_REQUEST,
        params
    }
}

export const getWorkspaceExpiryRequestAction = (params) => {
    return {
        type: ActionTypes.GET_WORKSPACE_EXPIRY_REQUEST,
        params
    }
}

export const requestAdminAction = (params) => {
    return {
        type: ActionTypes.ADMIN_REQUEST,
        params
    }
}

export const updateWorkspaceExpiryRequestAction = (params) => {
    return {
        type: ActionTypes.UPDATE_WORKSPACE_EXPIRE_REQUEST,
        params
    }
}

export const updatePlanAction = (params) => {
    return {
        type: ActionTypes.UPDATE_PLAN_REQUEST,
        params
    }
}

export const AddRequestAction = (params) => {
    return {
        type: ActionTypes.ADD_REQUEST,
        params
    }
}

export const GetRequestAction = (params) => {
    return {
        type: ActionTypes.GET_REQUEST,
        params
    }
}