import * as ActionTypes from '../ActionTypes';

export const GetMeeplAdminSkillsAction = (params) => {
    return {
        type: ActionTypes.MEEPL_ADMIN_SKILLS_GET_REQUEST,
        params
    }
}

export const GetSkillsAction = (params) => {
    return {
        type: ActionTypes.SKILLS_GET_REQUEST,
        params
    }
}

export const AddSkillsAction = (params) => {
    return {
        type: ActionTypes.SKILLS_ADD_REQUEST,
        params
    }
}


export const UpdateSkillsAction = (params) => {
    return {
        type: ActionTypes.SKILLS_UPDATE_REQUEST,
        params
    }
}


export const DeleteSkillsAction = (params) => {
    return {
        type: ActionTypes.SKILLS_DELETE_REQUEST,
        params
    }
}

