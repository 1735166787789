import React from "react";
import DownTimePage from "../views/DownTime/DownTimePage";
const DownTime = () =>{

        return(
        <>
                <DownTimePage />
        </>

        );

}
export default DownTime;