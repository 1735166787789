import * as ActionTypes from '../ActionTypes';

export const GetAtsJobPostAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_POST_GET_REQUEST,
        params
    }
}

export const AddAtsJobPostAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_POST_ADD_REQUEST,
        params
    }
}
export const UpdateAtsJobPostAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_POST_UPDATE_REQUEST,
        params
    }
}
export const AddAtsJobPostFillterAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_FILTER_ADD_REQUEST,
        params
    }
}

export const AtsJobPostModeAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_POST_MODE,
        params
    }
}

export const GetAtsJobOfferAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_OFFER_GET_REQUEST,
        params
    }
}