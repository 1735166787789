import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import Base from '../../../Base/views/Base'
import AtsJobPost from './AtsJobPost'
import KanbanLanding from './Kanban/KanbanLanding'
import { useDispatch } from 'react-redux'
import { GetATSModuleFeaturesAction } from '../../../../../store/RoleAccess/ATSModuleFeatures/Action'

const AtsJobPostLandingPage = () => {
    const [currentMode, setCurrentMode] = useState("grid")
    const [currentData, setCurrentData] = useState();
    const [archived, setArchived] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentMode("grid");
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Job Post" }));
        // eslint-disable-next-line
    }, [location]);

    return (
        <Base>
             {
                currentMode === "grid" ? <AtsJobPost archived={archived} setArchived={setArchived} currentData={currentData} setCurrentMode={setCurrentMode} setCurrentData={setCurrentData} /> : <KanbanLanding  setArchived={setArchived} currentMode={currentMode} currentJobData={currentData}  setCurrentMode={setCurrentMode}/>
            }
        </Base>
    )
}

export default withRouter(AtsJobPostLandingPage)
