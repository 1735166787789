import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";

export const GetNotificationHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/user/getNotifications`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.ats_user_id,
            all_unread: params?.all_unread || null
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddNotificationHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    params?.toast && toast.info("Loading...")
    return await axiosInstance(`/user/addNotification`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain ? params?.domain : data?.domain,
            user_emp_id: params?.employee_id ? params?.employee_id : data?.employee_id,
            notification_data: params?.notification_data,
            notification_type: params?.notification_type || null,
        }
    })
        .then((res) => {
            toast.dismiss();
            params?.toast && toast.success(params?.message)
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateNotificationHelper = async (params) => {
    const data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/user/updateNotification`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.ats_user_id,
            is_read: params?.is_read,
            all_unread: params?.all_unread || null,
            notification_data: params?.notification_data,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}