import { TextField, Box, Button, FormControl, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAtsJobPostAction } from '../../../../../store/ATS/JobPost/Action'
import SelectableSearch from '../../../Base/views/SelectableSearch'
import { AddAtsJobKanbanHelper } from '../../helper/AtsJobKanbanHelper'
import { ProposeCandidateConfirmationAction } from '../../../../../store/ATS/Candidate/Action'
import { getUserData } from '../../../Base/helper/baseFunctions'


const ProposeCandidateForm = ({ currentData, setOpenPopup, isProfile, editedRecord }) => {
    const userData = getUserData();
    const initialValue = { ats_jobpost_id: "", description: "" }
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(initialValue)
    const [jobData, setJobData] = useState({ load: true, values: [] })
    const { atsJobPostData } = useSelector(state => state.AtsReducer?.AtsJobPostReducer);
    useEffect(() => {
        dispatch(GetAtsJobPostAction({ company_id: 1, consultancy_id: null, status: "Active" }))
        // eslint-disable-next-line
    }, [])

    const handleInputChange = (event, value) => {
        if (["ats_jobpost_id"].includes(event)) {
            if (value) {
                if (value.description) setFormData({ ...formData, [event]: value, description: value.description });
                else setFormData({ ...formData, [event]: value, description: "" });
            } else {
                setFormData({ ...formData, [event]: "", description: "" });
            }
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    }


    const handleAddCandidate = () => {
        AddAtsJobKanbanHelper({
            kanban_data: JSON.stringify([{
                "proposed_by":userData?.ats_user_id,
                "candidate_id": currentData?.candidate_id, "ats_jobpost_id": isProfile ? editedRecord?.ats_jobpost_id : formData?.ats_jobpost_id?.ats_jobpost_id,
                "comment": "", "ats_job_stages_id": null, "priority": 5, "ats_status_id": null
            }]),
        }).then((res) => {
            if (res?.data?.statuscode === 200) {
                dispatch(ProposeCandidateConfirmationAction({ candidate_data: currentData, job_data: formData?.ats_jobpost_id, kanban_data:res?.data?.data?.[0] }))
            }
        })
        setOpenPopup(false)
    }

    return (

        <Box sx={{ minWidth: "300px" }}>
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid item xs={12} md={12} p={1}>
                    {
                        isProfile === true ?
                            <TextField
                                disabled={isProfile}
                                name="ats_company_id"
                                label="Job Title"
                                fieldLabel="title"
                                size='small'
                                fullWidth
                                variant="outlined"
                                value={editedRecord.title}
                            />
                            :
                            <FormControl fullWidth sx={{ pr: 1 }}>
                                <SelectableSearch
                                    isOptionEqualToValue={(option, value) => +option.ats_jobpost_id === +value.ats_jobpost_id}
                                    filterOptions="ats_jobpost_id"
                                    keys={["job_id", "title"]}
                                    label="Job"
                                    value={formData?.ats_jobpost_id}
                                    loading={() => setJobData({ load: false, values: atsJobPostData || [] })}
                                    fieldLabel="title"
                                    required={false}
                                    variant={"contained"}
                                    name="ats_jobpost_id"
                                    size="small"
                                    data={jobData}
                                    onChangeEvent={handleInputChange}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.title} - {option.job_id}
                                        </Box>
                                    )}
                                />
                            </FormControl>
                    }
                </Grid>
                {
                    isProfile !== true ?
                        formData?.description &&
                        <Grid item xs={12} md={12} p={1}>
                            <Typography>Job Description</Typography>
                            <div dangerouslySetInnerHTML={{ __html: formData?.description }}></div>
                        </Grid> : ""}
            </Grid>
            <Grid container sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "sticky",
                bottom: -20,
                zIndex: 1,
                backgroundColor: "#ffffff",
                p: 1,
            }}>
                <Button size="small" onClick={handleAddCandidate} disabled={isProfile ? !editedRecord.ats_jobpost_id : !formData?.ats_jobpost_id} variant="outlined">
                    Propose
                </Button>
            </Grid>
        </Box>
    )
}

export default ProposeCandidateForm