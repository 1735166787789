import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL,ADMIN_WORKSPACE } from "../../../Constants/baseConstant";
import { toast } from "react-toastify";
import { AddEmailTokenHelper } from "../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { renderEmail } from "react-html-email";
import BaseEmailTemplate from "../../Base/views/BaseEmailTemplate";
import SignUpEmailTemplate from "../views/SignUpEmailTemplate";
import { EmailHelper } from "../../Ancillary/Email/helper/EmailHelper";
import { v4 as uuidv4 } from 'uuid';
import RequestEmailTemplate from "../views/RequestEmailTemplate";

export const signUpEmailHelper = async (params) => {
    return await axiosInstance("/user/signUp", {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            full_name: params?.full_name,
            email_id: params?.emailid,
            contact_no: params?.contactno,
            company_name: params?.company_name,
            teamsize: params?.teamsize?.value || "",
        }
    })
        .then((res) => {
            // take domain from response
            let domain = res?.data?.data?.domainname
            let token = uuidv4() + `=${domain}`
            AddEmailTokenHelper({ token: token, email_id: params?.emailid, domain: domain })
            let content = renderEmail(<BaseEmailTemplate>
                <SignUpEmailTemplate
                    name={params?.full_name}
                    guid={token}
                    workspace={domain}
                    userid={params?.emailid}
                />
            </BaseEmailTemplate>)
            EmailHelper({ to: params?.emailid, subject: "Welcome to MEEPL", content: content });
            toast.success("Signed Up Successfully")
            return { message: res?.data };
        })
        .catch((err) => {
            err?.response?.data?.error?.[0]?.message && toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: err?.response?.data };
        });
};

export const addDemoRequestHelper = async (params) => {
    toast.info("Please be patient, This will take some time...")
    return await axiosInstance("/user/addDemoRequest", {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: ADMIN_WORKSPACE,
            first_name: params?.first_name,
            last_name: params?.last_name,
            emailid: params?.emailid,
            country_code: params?.country_code,
            contactno: params?.contactno,
            company_name: params?.company_name,
            teamsize: params?.teamsize || "",
            plan_type: params?.plan_type || "",

        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success(res?.data?.data?.[0]?.fn_add_requestdemo);
            let content = renderEmail(<BaseEmailTemplate>
                <RequestEmailTemplate
                    name={params?.first_name}
                />
            </BaseEmailTemplate>)
            EmailHelper({ to: params?.emailid, subject: "Welcome to MEEPL", content: content });
            return { message: res?.data };
        })
        .catch((err) => {
            toast.dismiss()
            err?.response?.data?.error?.[0]?.message && toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: err?.response?.data };
        });
};
