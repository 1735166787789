import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },

    container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    earnings: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    monthlyRate: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    currentMonth: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    arrear: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    total: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    deductions: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    total1: {
        width: '10%'
    },

    // Table Data

    rowData: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    earningsData: {
        width: '40%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        flexWrap: "wrap"
    },
    monthlyRateData: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    currentMonthData: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    arrearData: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    totalData: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    deductionsData: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    total1Data: {
        width: '10%',
        textAlign: 'right',
        paddingRight: 8,
    },

});

const PaySlipItemTable = ({ payslip }) => {
    return (
        <View style={styles.tableContainer}>
            <View style={styles.container}>
                <Text style={styles.earnings}>EARNINGS</Text>
                <Text style={styles.monthlyRate}>MONTHLY RATE</Text>
                <Text style={styles.currentMonth}>CURRENT MONTH</Text>
                <Text style={styles.arrear}>ARREAR</Text>
                <Text style={styles.total}>TOTAL</Text>
                <Text style={styles.deductions}>DEDUCTIONS</Text>
                <Text style={styles.total1}>TOTAL</Text>
            </View>

            {payslip?.payrollData?.map(item =>
                <View style={styles.rowData} key={item.earnings}>
                    <Text style={styles.earningsData}>{item.earnings}</Text>
                    <Text style={styles.monthlyRateData}>{item.monthlyRate}</Text>
                    <Text style={styles.currentMonthData}>{item.currentMonth}</Text>
                    <Text style={styles.arrearData}>{item.arrear}</Text>
                    <Text style={styles.totalData}>{item.total}</Text>
                    <Text style={styles.deductionsData}>{item.deductions}</Text>
                    <Text style={styles.total1}>{item.totalAmount}</Text>
                </View>
            )}
        </View>
    )
}

export default PaySlipItemTable