import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
};

export const JobTemplateReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MEEPL_ADMIN_JOB_TEMPLATE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.JOB_TEMPLATE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.JOB_TEMPLATE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                jobTemplateData: action.payload?.data?.data
            };
        case ActionTypes.JOB_TEMPLATE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.JOB_TEMPLATE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.JOB_TEMPLATE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                jobTemplateData: action.payload?.data?.data
            };
        case ActionTypes.JOB_TEMPLATE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.JOB_TEMPLATE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.JOB_TEMPLATE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                jobTemplateData: action.payload?.data?.data
            };
        case ActionTypes.JOB_TEMPLATE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.JOB_TEMPLATE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.JOB_TEMPLATE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                jobTemplateData: action.payload?.data?.data
            };
        case ActionTypes.JOB_TEMPLATE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}