import * as ActionTypes from '../ActionTypes';

export const GetMyTimeSheetAction = (params) => {
    return {
        type: ActionTypes.MY_TIME_SHEET_GET_REQUEST,params
    }
}

export const GetMyTimeSheetLeaveInfoAction = (params) => {
    return {
        type: ActionTypes.MY_TIME_SHEET_LEAVE_INFO_GET_REQUEST,params
    }
}


export const AddMyTimeSheetAction = (params) => {
    return {
        type: ActionTypes.MY_TIME_SHEET_ADD_REQUEST,
        params
    }
}

export const UpdateMyTimeSheetAction = (params) => {
    return {
        type: ActionTypes.MY_TIME_SHEET_UPDATE_REQUEST,
        params
    }
}

export const DeleteMyTimeSheetAction = (params) => {
    return {
        type: ActionTypes.MY_TIME_SHEET_DELETE_REQUEST,
        params
    }
}

export const GetFaceDataAction = (params) => {
    return {
        type: ActionTypes.GET_FACE_DATA_REQUEST,
        params
    }
}

