import { Box, Button, Divider, InputBase, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { toast } from "react-toastify";
import { AtsEmailHelper } from "../../helper/ATSEmailHeper";
import MentionInputStyles from "./MentionStyles/MentionInputStyles";
import { grey } from "@mui/material/colors";
import { GetAtsUsersEmailHelper } from "../../helper/AtsUserHelper";

const ContactEmailForm = ({ setOpenPopup, currentData }) => {
  const initialValues = {
    to: "",
    subject: "",
    content: "",
  };
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [mentionCc, setMentionCc] = useState("");
  const [mentionBcc, setMentionBcc] = useState("");
  const [mentionUsers, setMentionUsers] = useState([]);
  const [inputData, setInputData] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("to" in fieldValues) {
      temp.to = fieldValues.to?.trim() === "" ? "To Email is required" : "";
    }
    if ("subject" in fieldValues) {
      temp.subject =
        fieldValues.subject?.trim() === "" ? "Subject is required" : "";
    }
    if ("responser_name" in fieldValues) {
      temp.responser_name =
        fieldValues.responser_name?.trim() === ""
          ? "Responser Name is required"
          : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    currentData?.first_name &&
      setInputData({
        ...inputData,
        to: `${currentData?.email_id}`,
      });
    GetAtsUsersEmailHelper().then((res) => {
      let arr = [];
      res?.data?.data?.forEach((temp) =>
        arr.push({ ...temp, id: temp?.email_id, display: temp?.email_id })
      );
      setMentionUsers(arr);
    });
    // eslint-disable-next-line
  }, []);

  const handleShowCc = () => {
    setMentionCc("");
    setShowCc(!showCc);
  };
  const handleShowBcc = () => {
    setMentionBcc("");
    setShowBcc(!showBcc);
  };
  const handleInputChange = (event, value) => {
    setInputData({ ...inputData, [event?.target?.name]: event?.target?.value });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...inputData, [event.target.name]: event.target.value });
  };
  const handleChange = (e,name) => {
    const inputValue = e.target.value;
    const endsWithSpace = inputValue.endsWith(" ");
    if (endsWithSpace) {
      const trimmedValue = inputValue.trim();
      const updatedValue = trimmedValue + ",";
      name === "mentionCc"
        ? setMentionCc(updatedValue)
        : setMentionBcc(updatedValue);
    } else {
      name === "mentionCc"
        ? setMentionCc(inputValue)
        : setMentionBcc(inputValue);
    }
  };
  const handleSend = () => {
    const processEmails = (emailString) => {
      const emailArray = emailString.split(",");
      const uniqueEmailSet = new Set(emailArray);
      const uniqueEmailArray = Array.from(uniqueEmailSet);
      return uniqueEmailArray.join(",");
    };
    const cc = processEmails(mentionCc);
    const bcc = processEmails(mentionBcc);
    if (validate(inputData)) {
      AtsEmailHelper({ ...inputData, cc:cc, bcc:bcc })
        .then((res) => {
          toast.success("Email Sent Successfully");
        })
        .catch((err) => {
          toast.error("Unable to Send Email");
        });
      setOpenPopup(false);
    }
  };

  return (
    <div>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <InputBase
            disabled
            value={inputData?.to}
            multiline
            fullWidth
            name="to"
            onChange={handleInputChange}
            sx={{ padding: "5px 0px" }}
            placeholder="To"
          />
        </Box>
        <Typography variant="body1">
          <Box
            onClick={handleShowCc}
            sx={{ cursor: "pointer" }}
            component={"span"}
          >
            CC
          </Box>
          <Box
            sx={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
            component={"span"}
          >
            |
          </Box>
          <Box
            onClick={handleShowBcc}
            sx={{ cursor: "pointer" }}
            component={"span"}
          >
            BCC
          </Box>
        </Typography>
      </Box>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.to}</Typography>
      {showCc && (
        <>
          <MentionsInput
            fullWidth
            style={MentionInputStyles}
            value={mentionCc}
            name="mentionCc"
            onChange={(e,name) => handleChange(e, "mentionCc")}
            placeholder={"CC"}
          >
            <Mention
              markup="__id__,"
              displayTransform={(email_id) => `${email_id}, `}
              data={mentionUsers}
              displayName="email_id"
            />
          </MentionsInput>
          <Divider sx={{ borderStyle: "dashed" }} />
        </>
      )}
      {showBcc && (
        <>
         <MentionsInput
            fullWidth
            style={MentionInputStyles}
            value={mentionBcc}
            name="mentionBcc"
            onChange={(e,name) => handleChange(e, "mentionBcc")}
            placeholder={"BCC"}
          >
            <Mention
              markup="__id__,"
              displayTransform={(email_id) => `${email_id}, `}
              data={mentionUsers}
              displayName="email_id"
            />
          </MentionsInput>
          <Divider sx={{ borderStyle: "dashed" }} />
        </>
      )}
      <InputBase
        fullWidth
        name="subject"
        value={inputData?.subject}
        onChange={handleInputChange}
        sx={{ padding: "5px 0px" }}
        placeholder="Subject"
      />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.subject}</Typography>
      <InputBase
        fullWidth
        name="content"
        value={inputData?.content}
        onChange={handleInputChange}
        minRows={4}
        multiline
        sx={{ padding: "5px 0px" }}
        placeholder="Content"
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="mpl-secondary-btn"
          sx={{ ":hover": { color: grey[50] } }}
          size="small"
          variant="text"
          onClick={() => setOpenPopup(false)}
        >
          Cancel
        </Button>
        <Button
          sx={{
            ml: 1,
            color: grey[50],
            ":hover": { backgroundColor: "#f9c307", color: "#000000" },
          }}
          size="small"
          variant="contained"
          className="mpl-primary-btn"
          onClick={handleSend}
        >
          Send
        </Button>
      </Box>
    </div>
  );
};

export default ContactEmailForm;
