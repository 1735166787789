import * as ActionTypes from '../ActionTypes';

const initialState = {
    success_message: "",
    error: false,
    cost_center_options: []
};

const CostCenterReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.COST_CENTER_GET_REQUEST:
            return {
                ...state,
                cost_center_options: []
            };
        case ActionTypes.COST_CENTER_GET_SUCCESS:
            return {
                ...state,
                success_message: action.payload.message,
                error: action.payload.has_error,
                cost_center_options: action.payload?.data?.data
            };
        case ActionTypes.COST_CENTER_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.COST_CENTER_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                cost_center_options: action.payload?.data?.data
            };
        case ActionTypes.COST_CENTER_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.COST_CENTER_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.COST_CENTER_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                cost_center_options: action.payload?.data?.data
            };
        case ActionTypes.COST_CENTER_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.COST_CENTER_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.COST_CENTER_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                cost_center_options: action.payload?.data?.data
            };
        case ActionTypes.COST_CENTER_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}

export default CostCenterReducer;