import * as ActionTypes from '../ActionTypes';

// Role
export const GetRoleAction = () => {
    return {
        type: ActionTypes.ROLE_GET_REQUEST,
    }
}

export const GetUserRoleAction = () => {
    return {
        type: ActionTypes.ROLE_USER_GET_REQUEST,
    }
}