import {
  Box,
  Button,
  Divider,
  InputBase,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import MentionInputStyles from "../ATSCandidate/MentionStyles/MentionInputStyles";
import { AddEmailTokenHelper } from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { v4 as uuidv4 } from "uuid";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { renderEmail } from "react-html-email";
import { AddAtsCandidateJobHistoryHelper } from "../../helper/AtsCandidateJobHistoryHelper";
import { ShareCandidateResumeHelper } from "../../helper/AtsCandidateHelper";
import { DownloadAttachment } from "../../../Base/helper/baseFunctions";
import { grey } from "@mui/material/colors";
import { GetAtsUsersEmailHelper } from "../../helper/AtsUserHelper";
import { useEffect } from "react";
import OfferApproveEmail from "../ATSCandidate/OfferApproveEmail";
import { OfferEmailSentHelper } from "../../../ATSSettings/helper/AtsFormTemplateResponseHelper";
import { GetAtsJobKanbanOfferHelper } from "../../helper/AtsJobKanbanHelper";

const SendApprovalOfferLetter = ({ setOpenPopup, data, currentJobData, setElements}) => {
  let userData = getUserData();
  const initialValues = {
    subject: "Request for Offer Letter Approval",
    content: `${userData?.name} has requested your review for this offer letter.`,
    responser_name: "",
  };
  const [mentionTo, setMentionTo] = useState("");
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [mentionCc, setMentionCc] = useState("");
  const [mentionBcc, setMentionBcc] = useState("");
  const [mentionUsers, setMentionUsers] = useState([]);
  const [inputData, setInputData] = useState(initialValues);
  const [errors, setErrors] = useState({});
 

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("mentionTo" in fieldValues) {
      temp.mentionTo =
        fieldValues.mentionTo?.trim() === "" ? "To Email is required" : "";
    }
    if ("subject" in fieldValues) {
      temp.subject =
        fieldValues.subject?.trim() === "" ? "Subject is required" : "";
    }
    if ("responser_name" in fieldValues) {
      temp.responser_name =
        fieldValues.responser_name?.trim() === ""
          ? "Approver Name is required"
          : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  useEffect(() => {
    GetAtsUsersEmailHelper().then((res) => {
      let arr = [];
      res?.data?.data?.forEach((temp) =>
        arr.push({ ...temp, id: temp?.email_id, display: temp?.email_id })
      );
      setMentionUsers(arr);
    });
    // eslint-disable-next-line
  }, []);

  const handleShowCc = () => {
    setMentionCc("");
    setShowCc(!showCc);
  };
  const handleShowBcc = () => {
    setMentionBcc("");
    setShowBcc(!showBcc);
  };
  const handleInputChange = (event) => {
    setInputData({ ...inputData, [event?.target?.name]: event?.target?.value });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...inputData, [event.target.name]: event.target.value });
  };

  const handleChange = (e, name) => {
    const inputValue = e.target.value;
    const endsWithSpace = inputValue.endsWith(" ");
    const trimmedValue = inputValue.trim();
    const updatedValue = trimmedValue + ",";
  
    switch (name) {
      case "mentionTo":
        if (endsWithSpace) {
          setMentionTo(updatedValue);
        } else {
          setMentionTo(inputValue);
        }
        break;
      case "mentionCc":
        if (endsWithSpace) {
          setMentionCc(updatedValue);
        } else {
          setMentionCc(inputValue);
        }
        break;
      case "mentionBcc":
        if (endsWithSpace) {
          setMentionBcc(updatedValue);
        } else {
          setMentionBcc(inputValue);
        }
        break;
      default:
        break;
    }
  };

  const handleSend = () => {
    const processEmails = (emailString) => {
      const emailArray = emailString.split(",");
      const uniqueEmailSet = new Set(emailArray);
      const uniqueEmailArray = Array.from(uniqueEmailSet);
      return uniqueEmailArray.join(",");
    };
    const to = processEmails(mentionTo);
    const cc = processEmails(mentionCc);
    const bcc = processEmails(mentionBcc);
    if (validate({ ...inputData, mentionTo })) {
      let token = uuidv4() + `=${userData?.domain}`;
      AddEmailTokenHelper({
        token: token,
        email_id: to,
        json_data: JSON.stringify([
          {
            ...inputData,
            ...data,
            request_emp_id: userData?.employee_id,
            to: mentionTo,
          },
        ]),
      });
      ShareCandidateResumeHelper(
        {
          to: to,
          cc: cc,
          bcc: bcc,
          subject: inputData?.subject,
          resume_path: data?.offer_letter_cloud_path,
          resume_name:
            data?.offer_letter_cloud_path &&
            `${data?.first_name}.${data?.offer_letter_cloud_path
              .split(".")
              .at(-1)}`,
          attachments: data?.offer_letter_cloud_path,
          message: "Approve Offer Letter sent successfully",
          content: renderEmail(
              <OfferApproveEmail
                userName={inputData?.responser_name}
                guid={token}
                message={inputData?.content}
                approvebutton={"Approve"}
                rejectbutton={"Reject"}
                url="/ats/offer-approval/"
              />
          ),
        },
        "Email sent Successfully"
      );
      AddAtsCandidateJobHistoryHelper({
        candidate_fk: data?.candidate_id,
        candidate_job_fk: data?.candidate_job_id,
        ats_jobpost_fk: data?.ats_jobpost_id,
        ats_job_stages_fk: data?.ats_job_stages_id,
        responder_name: inputData?.responser_name,
      });
      OfferEmailSentHelper({
        update_tblcolumn: "approver_email_sent",
        offer_letter_response_id: data?.offer_letter_response_id,
        update_tblcolumn_value: "true",
      }).then((res) => {
        GetAtsJobKanbanOfferHelper({
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
        }).then((res) => {
          res?.data?.data && setElements(res?.data?.data);
          setOpenPopup(false);
        });
      });
    }
  };
  return (
    <div>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <MentionsInput
            fullWidth
            style={MentionInputStyles}
            value={mentionTo}
            name="mentionTo"
            onChange={(e, name) => handleChange(e, "mentionTo")}
            placeholder={"TO"}
          >
            <Mention
              markup="__id__,"
              displayTransform={(email_id) => `${email_id}, `}
              data={mentionUsers}
              displayName="email_id"
            />
          </MentionsInput>
          
        </Box>
        <Typography variant="body1">
          <Box
            onClick={handleShowCc}
            sx={{ cursor: "pointer" }}
            component={"span"}
          >
            CC
          </Box>
          <Box
            sx={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
            component={"span"}
          >
            |
          </Box>
          <Box
            onClick={handleShowBcc}
            sx={{ cursor: "pointer" }}
            component={"span"}
          >
            BCC
          </Box>
        </Typography>
      </Box>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.mentionTo}</Typography>
      {showCc && (
        <>
          <MentionsInput
            fullWidth
            style={MentionInputStyles}
            value={mentionCc}
            name="mentionCc"
            onChange={(e,name) => handleChange(e, "mentionCc")}
            placeholder={"CC"}
          >
            <Mention
              markup="__id__,"
              displayTransform={(email_id) => `${email_id}, `}
              data={mentionUsers}
              displayName="email_id"
            />
          </MentionsInput>
          <Divider sx={{ borderStyle: "dashed" }} />
        </>
      )}
      {showBcc && (
        <>
         <MentionsInput
            fullWidth
            style={MentionInputStyles}
            value={mentionBcc}
            name="mentionBcc"
            onChange={(e,name) => handleChange(e, "mentionBcc")}
            placeholder={"BCC"}
          >
            <Mention
              markup="__id__,"
              displayTransform={(email_id) => `${email_id}, `}
              data={mentionUsers}
              displayName="email_id"
            />
          </MentionsInput>
          <Divider sx={{ borderStyle: "dashed" }} />
        </>
      )}
      <InputBase
        fullWidth
        name="subject"
        value={inputData?.subject}
        onChange={handleInputChange}
        sx={{ padding: "5px 0px" }}
        placeholder="Subject"
      />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.subject}</Typography>
      <InputBase
        fullWidth
        name="responser_name"
        value={inputData?.responser_name}
        onChange={handleInputChange}
        sx={{ padding: "5px 0px" }}
        placeholder="Approver Name"
      />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.responser_name}</Typography>
      <Typography sx={{ fontWeight: 600, pt: 1, pb: 1 , fontStyle:"italic"}}>
        Note: This email is to get the approval before sending the attached
        offer letter to the candidate.
      </Typography>
      <InputBase
        fullWidth
        name="content"
        value={inputData?.content}
        onChange={handleInputChange}
        minRows={4}
        multiline
        sx={{ minHeight: "10px", adding: "5px 0px" }}
        placeholder="Add Comment if any..."
      />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid
        container
        style={{ display: "flex", flexDirection: "column" }}
        spacing={2}
      >
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            First Name: &nbsp;
          </Typography>
          <Typography>{data?.first_name}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>Last Name:&nbsp;</Typography>
          <Typography>{data?.last_name}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>Contact No:&nbsp;</Typography>
          <Typography>{data?.contact_no}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>Email:&nbsp;</Typography>
          <Typography>{data?.email_id}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>Job Title:&nbsp;</Typography>
          <Typography>{data?.title}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            Job Location:&nbsp;
          </Typography>
          <Typography>{data?.location}</Typography>
        </Grid>
      </Grid>
      {data?.offer_letter_cloud_path && (
        <Box marginTop={2} display="flex" alignItems={"center"}>
          <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
            Offer Letter:&nbsp;
          </Typography>
          <Typography
            className="file-name"
            sx={{ color: "#1155cc" }}
            onClick={() => DownloadAttachment(data?.offer_letter_cloud_path)}
            style={{ cursor: "pointer" }}
          >
            {data?.first_name || " "}_Offer_Letter.
            {data?.offer_letter_cloud_path.split(".").at(-1)}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="mpl-secondary-btn"
          sx={{ ":hover": { color: grey[50] } }}
          size="small"
          variant="text"
          onClick={() => setOpenPopup(false)}
        >
          Cancel
        </Button>
        <Button
          sx={{
            ml: 1,
            color: grey[50],
            ":hover": { backgroundColor: "#f9c307", color: "#000000" },
          }}
          size="small"
          variant="contained"
          className="mpl-primary-btn"
          onClick={handleSend}
        >
          Send
        </Button>
      </Box>
    </div>
  );
};

export default SendApprovalOfferLetter;
