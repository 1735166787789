import React from "react";
// import "./Product.css";
function ProductPage() {
  return (
    <>
      <div id="Product" className="section">
        <div className="container">
          <div className="hero-dashboard-container">
            <div className="hero-devices-holder">
              <div
                data-w-id="1578d692-4a33-258e-661f-47964b93c761"
                className="hero-dashboard-holder"
              >
                <div className="hero-browser-skeleton">
                  <div className="brower-icons-holder">
                    <div className="small-icon"></div>
                    <div className="small-icon yellow"></div>
                    <div className="small-icon green"></div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="ec91cd61-affb-8f92-b835-a34883851278"
                className="hero-dashboard"
              >
                <img
                  src="https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard.webp"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 93vw, (max-width: 1439px) 94vw, 1314px"
                  srcSet="https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-500.webp 500w, https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-800.webp 800w, https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1080.webp 1080w, https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1600.webp 1600w, https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2000.webp 2000w, https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2600.webp 2600w, https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-3200.webp 3200w"
                  alt=""
                  className="hero-dashboard-image"
                />
              </div>
            </div>
            <div
              data-w-id="ddb3b09a-6dcd-d88e-06d3-511d4d734f45"
              className="hero-iphone-holder"
            >
              <img
                src="https://assets.website-files.com/634e968b219cc43522715fb8/634eb3af77434fe0d7319118_iPhone%2013%20Pro%20-%20Starlight.png"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 991px) 180px, 310px"
                srcSet="https://assets.website-files.com/634e968b219cc43522715fb8/634eb3af77434fe0d7319118_iPhone%2013%20Pro%20-%20Starlight-p-500.png 500w, https://assets.website-files.com/634e968b219cc43522715fb8/634eb3af77434fe0d7319118_iPhone%2013%20Pro%20-%20Starlight-p-800.png 800w, https://assets.website-files.com/634e968b219cc43522715fb8/634eb3af77434fe0d7319118_iPhone%2013%20Pro%20-%20Starlight.png 1002w"
                alt=""
                className="iphone-image"
              />
              <div className="hero-iphone-screen-holder">
                <img
                  src="https://assets.website-files.com/634e968b219cc43522715fb8/634eb3fd4146bc51a396b30b_Dashboard%20Mobile.svg"
                  loading="lazy"
                  alt=""
                  className="iphone-screen-image"
                />
              </div>
            </div>
            {/* <div>
              <div
                data-w-id="de75bb0c-f140-7787-003d-373d39d7545f"
                className="trusted-logo-holder"
              >
                <p className="no-margins">
                  Trusted by leading product manufacturers
                </p>
                <div className="trusted-logo-list">
                  <div className="trusted-logo">
                    <img
                      src="https://assets.website-files.com/634e968b219cc43522715fb8/634eb28f80b926106da1d623_Logo%20To%20Use%2004.svg"
                      loading="lazy"
                      alt=""
                      className="trusted-logo-image"
                    />
                  </div>
                  <div className="trusted-logo">
                    <img
                      src="https://assets.website-files.com/634e968b219cc43522715fb8/634eb28f60e7cf72f121b0e4_Logo%20To%20Use%2005.svg"
                      loading="lazy"
                      alt=""
                      className="trusted-logo-image"
                    />
                  </div>
                  <div className="trusted-logo">
                    <img
                      src="https://assets.website-files.com/634e968b219cc43522715fb8/634eb28f43522e1d2b21c41b_Logo%20To%20Use%2002.svg"
                      loading="lazy"
                      alt=""
                      className="trusted-logo-image"
                    />
                  </div>
                  <div className="trusted-logo">
                    <img
                      src="https://assets.website-files.com/634e968b219cc43522715fb8/634eb28f42b34530c15d3027_Logo%20To%20Use%2001.svg"
                      loading="lazy"
                      alt=""
                      className="trusted-logo-image"
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPage;
