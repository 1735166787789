import {
  Box,
  Button,
  Divider,
  InputBase,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import MentionInputStyles from "../ATSCandidate/MentionStyles/MentionInputStyles";
import { AddEmailTokenHelper } from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { v4 as uuidv4 } from "uuid";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { renderEmail } from "react-html-email";
import BaseEmailTemplate from "../../../Base/views/BaseEmailTemplate";
import AddCandidateEmail from "../ATSCandidate/AddCandidateEmail";
import { AddAtsCandidateJobHistoryHelper } from "../../helper/AtsCandidateJobHistoryHelper";
import { APP_NAME } from "../../../../Constants/baseConstant";
import { ShareCandidateResumeHelper } from "../../helper/AtsCandidateHelper";
import { DownloadAttachment } from "../../../Base/helper/baseFunctions";
import { grey } from "@mui/material/colors";
import { GetAtsUsersEmailHelper } from "../../helper/AtsUserHelper";
import { useEffect } from "react";

const CandidateEmailData = ({ data }) => {
  const maxWords = 50;
  const words = data?.primary_skill;
  const secondary_words = data?.secondary_skill;
  const truncatedData = words?.slice(0, maxWords);
  const secondary_truncatedData = secondary_words?.slice(0, maxWords);
  const showEllipsis = words?.length > maxWords;
  const secondary_showEllipsis = secondary_words?.length > maxWords;
  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            textAlign:"left",
            height: "35px",
            width:"100%"
          }}
        >
          <h5 style={{ fontWeight: "bold", width:"30%", color:"#000" }}>First Name</h5>
          <h5 style={{ fontWeight: "normal" ,color:"#000"}}> : &nbsp; {data?.first_name}</h5>
        </div>
        <div
          style={{
            display: "flex",
            textAlign:"left",
            height: "35px",
            width:"100%"
          }}
        >
          <h5 style={{ fontWeight: "bold", width:"30%" ,color:"#000" }}>Last Name</h5>
          <h5 style={{ fontWeight: "normal" ,color:"#000" }}> : &nbsp; {data?.last_name}</h5>
        </div>
        <div
          style={{
            display: "flex",
            textAlign:"left",
            height: "35px",
            width:"100%"
          }}
        >
          <h5 style={{ fontWeight: "bold", width:"30%" ,color:"#000" }}>Email</h5>
          <h5 style={{ fontWeight: "normal" ,color:"#000" }}> : &nbsp; {data?.email_id}</h5>
        </div>
        <div
          style={{
            display: "flex",
            textAlign:"left",
            height: "35px",
            width:"100%"
          }}
        >
          <h5 style={{ fontWeight: "bold", width:"30%" ,color:"#000" }}>Contact No </h5>
          <h5 style={{ fontWeight: "normal" ,color:"#000" }}> : &nbsp; {data?.contact_no}</h5>
        </div>
        <div style={{ display: "flex", textAlign:"left",
            height: "35px",
            width:"100%" }}>
          <h5 style={{ fontWeight: "bold", width:"30%" ,color:"#000"  }}>Primary Skills</h5>
          <h5 style={{ fontWeight: "normal" ,color:"#000" }}> : &nbsp; {truncatedData}{showEllipsis ? '...' : ''} </h5>
        </div>
        <div style={{ display: "flex",   textAlign:"left",
            height: "35px",
            width:"100%" }}>
          <h5 style={{ fontWeight: "bold", width:"30%" ,color:"#000" }}>Secondary Skills</h5>
          <h5 style={{ fontWeight: "normal" ,color:"#000" }}> : &nbsp; {secondary_truncatedData}{secondary_showEllipsis ? '...' : ''}</h5>
        </div>
      </div>
    </div>
  );
};

const RequestFeedback = ({ setOpenPopup, data }) => {
  const initialValues = {
    subject: "Give Feedback about the candidate",
    content: `Please share your feedback for the below Candidate(Add Comment if any)`,
    responser_name: "",
  };
  const [showCc, setShowCc] = useState(false);
  const [mentionTo, setMentionTo] = useState("");
  const [mentionCc, setMentionCc] = useState("");
  const [mentionUsers, setMentionUsers] = useState([]);
  const [inputData, setInputData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  let userData = getUserData();

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("mentionTo" in fieldValues) {
      temp.mentionTo =
        fieldValues.mentionTo?.trim() === "" ? "To Email is required" : "";
    }
    if ("subject" in fieldValues) {
      temp.subject =
        fieldValues.subject?.trim() === "" ? "Subject is required" : "";
    }
    if ("responser_name" in fieldValues) {
      temp.responser_name =
        fieldValues.responser_name?.trim() === ""
          ? "Responser Name is required"
          : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  useEffect(() => {
    GetAtsUsersEmailHelper().then((res) => {
      let arr = [];
      res?.data?.data?.forEach((temp) =>
        arr.push({ ...temp, id: temp?.email_id, display: temp?.email_id })
      );
      setMentionUsers(arr);
    });
    // eslint-disable-next-line
  }, []);

  const handleShowCc = () => {
    setMentionCc("");
    setShowCc(!showCc);
  };

  const handleInputChange = (event) => {
    setInputData({ ...inputData, [event?.target?.name]: event?.target?.value });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...inputData, [event.target.name]: event.target.value });
  };

  const handleChange = (e, name) => {
    const inputValue = e.target.value;
    const endsWithSpace = inputValue.endsWith(" ");
    if (endsWithSpace) {
      const trimmedValue = inputValue.trim();
      const updatedValue = trimmedValue + ",";
      name === "mentionTo"
        ? setMentionTo(updatedValue)
        : setMentionCc(updatedValue);
    } else {
      name === "mentionTo"
        ? setMentionTo(inputValue)
        : setMentionCc(inputValue);
    }
  };

  const handleSend = () => {
    const processEmails = (emailString) => {
      const emailArray = emailString.split(",");
      const uniqueEmailSet = new Set(emailArray);
      const uniqueEmailArray = Array.from(uniqueEmailSet);
      return uniqueEmailArray.join(",");
    };
    const to = processEmails(mentionTo);
    const cc = processEmails(mentionCc);
    if (validate({ ...inputData, mentionTo })) {
      let token = uuidv4() + `=${userData?.domain}`;
      AddEmailTokenHelper({
        token: token,
        email_id: to,
        json_data: JSON.stringify([
          { ...inputData, ...data, request_emp_id: userData?.employee_id,to:mentionTo },
        ]),
      });
      ShareCandidateResumeHelper(
        {
          to: to,
          cc: cc,
          bcc: inputData?.bcc,
          subject: inputData?.subject,
          resume_path: data?.resume,
          resume_name:
            data?.resume &&
            `${data?.first_name}.${data?.resume.split(".").at(-1)}`,
          attachments: data?.resume,
          message: "Feedback form sent successfully",
          content: renderEmail(
            <BaseEmailTemplate>
              <AddCandidateEmail
                userName={inputData?.responser_name}
                guid={token}
                message={`Please Share your feedback for the below candidate`}
                button={"Share Feedback"}
                url="/ats/feedback-response/"
                data={<CandidateEmailData data={data} />}
              />
            </BaseEmailTemplate>
          ),
        },
        "Email sent Successfully"
      );
      AddAtsCandidateJobHistoryHelper({
        candidate_fk: data?.candidate_id,
        candidate_job_fk: data?.candidate_job_id,
        ats_jobpost_fk: data?.ats_jobpost_id,
        ats_job_stages_fk: data?.ats_job_stages_id,
        responder_name: inputData?.responser_name,
      });
      setOpenPopup(false);
    }
  };
  return (
    <div>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <MentionsInput
            fullWidth
            style={MentionInputStyles}
            value={mentionTo}
            name="mentionTo"
            onChange={(e, name) => handleChange(e, "mentionTo")}
            placeholder={"TO"}
          >
            <Mention
              markup="__id__,"
              displayTransform={(email_id) => `${email_id}, `}
              data={mentionUsers}
              displayName="email_id"
            />
          </MentionsInput>
        </Box>
        <Typography variant="body1">
          <Box
            onClick={handleShowCc}
            sx={{ cursor: "pointer" }}
            component={"span"}
          >
            CC
          </Box>
        </Typography>
      </Box>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.mentionTo}</Typography>
      {showCc && (
        <>
          <MentionsInput
            fullWidth
            style={MentionInputStyles}
            value={mentionCc}
            name="mentionCc"
            onChange={(e, name) => handleChange(e, "mentionCc")}
            placeholder={"CC"}
          >
            <Mention
              markup="__id__,"
              displayTransform={(email_id) => `${email_id}, `}
              data={mentionUsers}
              displayName="email_id"
            />
          </MentionsInput>
          <Divider sx={{ borderStyle: "dashed" }} />
        </>
      )}
      <InputBase
        fullWidth
        name="subject"
        value={inputData?.subject}
        onChange={handleInputChange}
        sx={{ padding: "5px 0px" }}
        placeholder="Subject"
      />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.subject}</Typography>
      <InputBase
        fullWidth
        name="responser_name"
        value={inputData?.responser_name}
        onChange={handleInputChange}
        sx={{ padding: "5px 0px" }}
        placeholder="Responser Name (Name of the Feedback provider)"
      />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.responser_name}</Typography>
      <Typography sx={{ fontWeight: 600, pt: 1, pb: 1 }}>
        Note: Feed Back Form will be attached to this Email.
      </Typography>
      <InputBase
        fullWidth
        name="content"
        value={inputData?.content}
        onChange={handleInputChange}
        minRows={4}
        multiline
        sx={{ minHeight: "10px", adding: "5px 0px" }}
        placeholder="Content"
      />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid
        container
        style={{ display: "flex", flexDirection: "column" }}
        spacing={2}
      >
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            First Name: &nbsp;
          </Typography>
          <Typography>{data?.first_name}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>Last Name:&nbsp;</Typography>
          <Typography>{data?.last_name}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>Contact No:&nbsp;</Typography>
          <Typography>{data?.contact_no}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold" }}>Email:&nbsp;</Typography>
          <Typography>{data?.email_id}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
            Primary Skill:&nbsp;
          </Typography>
          <Typography>{data?.primary_skill}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
            Secondary Skill:&nbsp;
          </Typography>
          <Typography>{data?.secondary_skill}</Typography>
        </Grid>
      </Grid>
      {data?.resume && (
        <Box marginTop={2} display="flex" alignItems={"center"}>
          <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
            Resume:&nbsp;
          </Typography>
          <Typography
            className="file-name"
            sx={{ color: "#1155cc" }}
            onClick={() => DownloadAttachment(data?.resume)}
            style={{ cursor: "pointer" }}
          >
            {data?.first_name || " "}_{APP_NAME}.
            {data?.resume.split(".").at(-1)}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="mpl-secondary-btn"
          sx={{ ":hover": { color: grey[50] } }}
          size="small"
          variant="text"
          onClick={() => setOpenPopup(false)}
        >
          Cancel
        </Button>
        <Button
          sx={{
            ml: 1,
            color: grey[50],
            ":hover": { backgroundColor: "#f9c307", color: "#000000" },
          }}
          size="small"
          variant="contained"
          className="mpl-primary-btn"
          onClick={handleSend}
        >
          Send
        </Button>
      </Box>
    </div>
  );
};

export default RequestFeedback;
