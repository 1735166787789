import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table"
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AddJobTemplateAction, DeleteJobTemplateAction, GetJobTemplateAction, GetMeeplAdminJobTemplateAction, UpdateJobTemplateAction } from "../../../../../store/ATS/JobTemplate/Action";
import { makeStyles } from "@mui/styles";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import Popup from "../../../Base/views/Popup";
import { grey } from "@mui/material/colors";
import Quill from "../../../Base/views/Quill";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { GetIndustryTypeAction } from "../../../../../store/ATS/IndustryType/Action";

const JobTemplate = () => {
    const useStyles = makeStyles((theme) => ({
        toolbarWrapper: {
            '& .MuiToolbar-gutters': {
                paddingLeft: 0,
                paddingRight: 0,
            },
            minHeight: "20px"
        },
    }))

    const initialValues = {
        industry_name: "",
        job_title: "",
        job_description: "",
        roles_responsibilities: ""
    };

    const classes = useStyles();
    const dispatch = useDispatch()
    const { jobTemplateData } = useSelector(state => state.AtsReducer?.JobTemplateReducer);
    const { industryTypeData } = useSelector(state => state.AtsReducer?.IndustryTypeReducer);
    const [jobTemplatedata, setJobTemplatedata] = useState(initialValues);
    const [industryTypedataList, setIndustryTypedataList] = useState({ load: false, values: [] })
    const [filters, setFilters] = useState([]);
    const [errors, setErrors] = useState({});
    const [rolesresponsibilities, setRolesResponsibilities] = useState('');
    const [description, setDescription] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const domain_name = sessionStorage.getItem("userManagement")

    useEffect(() => {
        domain_name === "meepladmin" ? dispatch(GetMeeplAdminJobTemplateAction({ industry_code: filters?.industry_name?.code || null }))
            : dispatch(GetJobTemplateAction({ industry_code: filters?.industry_name?.code || null }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("industry_name" in fieldValues) {
            temp.industry_name = fieldValues.industry_name === "" || fieldValues.industry_name === null ? "Industry Type is required" : "";
        }
        if ("job_title" in fieldValues) {
            temp.job_title = fieldValues.job_title === "" ? "Job Title is required" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleFilter = (event, value) => {
        if (["industry_name"].includes(event)) {
            setFilters({ [event]: value })
            setJobTemplatedata({ ...jobTemplatedata, [event]: value })
        }
    }
    const handleLoading = () => {
        setIndustryTypedataList({ load: false, values: industryTypeData })
    }

    const filter = [
        <FormControl fullWidth sx={{ minWidth: "300px", ml: 1, padding: 1 }}>
            <SelectableSearch
                isOptionEqualToValue={(option, value) =>
                    +option.code === +value.code
                }
                label="Industry Sector"
                value={filters?.industry_name}
                onBlur={() => setIndustryTypedataList({ load: false, values: [] })}
                loading={() => handleLoading()}
                // loading={() => setIndustryTypedata({ load: false, values: industryTypeData })}
                fieldLabel="industry_name"
                size="small"
                variant={"outlined"}
                name="industry_name"
                onChangeEvent={(e, value) => handleFilter(e, value)}
                data={industryTypedataList}
                {...(errors.industry_name && {
                    error: true,
                    helperText: errors.industry_name,
                })}

            />
        </FormControl>]

    const handleChange = (event, value) => {
        if (["industry_name"].includes(event)) {
            setJobTemplatedata({ ...jobTemplatedata, [event]: value })
            Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
                validate({ ...jobTemplatedata, [event]: value })
        }

        else {
            setJobTemplatedata({ ...jobTemplatedata, [event.target.name]: event.target.value })
        }
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
            validate({ ...jobTemplatedata, [event.target.name]: event.target.value });
    }

    const handleSubmit = () => {
        if (validate(jobTemplatedata)) {
            if (jobTemplatedata?.industry_id) {
                dispatch(UpdateJobTemplateAction({
                    industry_code: jobTemplatedata?.industry_name?.industry_code || jobTemplatedata?.industry_code,
                    job_template_id: jobTemplatedata?.job_template_id,
                    job_title: jobTemplatedata?.job_title,
                    job_description: description,
                    roles_responsibilities: rolesresponsibilities
                }))
                resetForm()
                setOpenPopup(false)
            }
            else {
                dispatch(AddJobTemplateAction({
                    job_title: jobTemplatedata?.job_title,
                    industry_code: jobTemplatedata?.industry_name?.code,
                    job_description: description,
                    roles_responsibilities: rolesresponsibilities
                }))
                resetForm()
                setOpenPopup(false)
            }
        }

    }


    const handleDelete = () => {
        setOpenDialog(false);
        dispatch(DeleteJobTemplateAction({ job_template_id: currentData?.job_template_id }))
    };

    const handleEdit = (rowData) => {
        setJobTemplatedata({ ...rowData, industry_name: { industry_type_id: rowData?.industry_type_id, industry_name: rowData?.industry_name, industry_code: rowData?.industry_code } })
        setDescription(rowData?.job_description)
        setRolesResponsibilities(rowData?.roles_responsibilities)
        setOpenPopup(true);
    };

    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };

    const handleAdd = () => {
        setOpenPopup(true);
    };

    const resetForm = () => {
        setJobTemplatedata({ ...initialValues, industry_name: filters?.industry_name });
        setRolesResponsibilities('');
        setDescription('');
        setErrors(false);
    };

    const handleCancel = () => {
        resetForm()
        setOpenPopup(false);
    }


    return (
        <>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "Industry Sector", cellStyle: { padding: "0px 10px" }, field: "industry_name"
                        },
                        {
                            title: "Job Title", cellStyle: { padding: "0px 10px" }, field: "job_title"
                        },
                    ]}
                    data={jobTemplateData || []}

                    options={{
                        maxBodyHeight: "35vh",
                        minBodyHeight: "35vh",
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    actions={[
                        rowData => ({
                            icon: () => <EditIcon />,
                            position: "row",
                            tooltip: "Edit",
                            disabled: rowData?.is_public_data,
                            onClick: (_event, rowData) => handleEdit(rowData)
                        }),
                        rowData => ({
                            icon: () => <DeleteOutlineIcon color='error' />,
                            position: "row",
                            tooltip: "Delete",
                            disabled: rowData?.is_public_data,
                            onClick: (_event, rowData) => handleConfirmDelete(rowData)
                        }),
                        {
                            icon: () => <Button component="span" variant="contained" startIcon={<AddIcon />} className="mpl-primary-btn">Add</Button>,
                            tooltip: 'Add',
                            isFreeAction: true,
                            onClick: (event) => { handleAdd() }
                        }

                    ]}
                    title={filter}
                    components={{
                        Toolbar: (props) => <div className={classes.toolbarWrapper}><MTableToolbar {...props} /></div>,
                    }}
                />
                <Popup
                    title={jobTemplatedata?.industry_type_id ? "Edit JD Template" : "Add JD Template"}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    close={false}
                    maxWidth="lg"
                    fullWidth={true}
                >
                    <>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Grid container spacing={2} >
                                <Grid item xs={6} md={6}>
                                    <FormControl fullWidth>
                                        <SelectableSearch
                                            isOptionEqualToValue={(option, value) =>
                                                option?.code === value?.code
                                            }
                                            label="Industry Sector"
                                            value={filters?.industry_name || jobTemplatedata?.industry_name}
                                            onBlur={() => setIndustryTypedataList({ load: false, values: [] })}
                                            loading={() => handleLoading()}
                                            fieldLabel="industry_name"
                                            required={true}
                                            size="small"
                                            variant={"outlined"}
                                            name="industry_name"
                                            onChangeEvent={(e, value) => handleChange(e, value)}
                                            data={industryTypedataList}
                                            {...(errors.industry_name && {
                                                error: true,
                                                helperText: errors.industry_name,
                                            })}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoFocus={true}
                                            required
                                            size="small"
                                            value={jobTemplatedata.job_title}
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            fullWidth
                                            id="job_title"
                                            label="Job Title"
                                            name="job_title"
                                            {...(errors.job_title && {
                                                error: true,
                                                helperText: errors.job_title,
                                            })}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <FormControl fullWidth>
                                        <Typography>Job Description</Typography>
                                        <Quill name='description' value={description} setValue={setDescription} style={{ height: "300px" }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <FormControl fullWidth>
                                        <Typography>Roles and Responsibilities</Typography>
                                        <Quill name='roles_responsibilities' value={rolesresponsibilities} setValue={setRolesResponsibilities} style={{ height: "300px" }} />
                                    </FormControl>
                                </Grid>
                                <Grid container sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mt: 3
                                }}>
                                    <Button
                                        sx={{ ":hover": { color: grey[50] }, marginTop: "1.75rem", marginRight: "1rem" }}
                                        variant="outlined"
                                        className="mpl-secondary-btn"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1.75rem", marginRight: "1rem" }}>
                                        {jobTemplatedata?.industry_code ? "Update" : "Submit"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>

                </Popup>
                <ConfirmationDialog
                    title="Are you sure to Delete JD Template?"
                    openDialog={openDialog}
                    closeDialog={setOpenDialog}
                    OkButtonText="OK"
                    onSubmit={handleDelete}
                />

            </Box>
        </>
    )
}
export default JobTemplate;