import * as ActionTypes from '../ActionTypes';

const initialState = {
    UsersIsLoading: false,
    error: false,
    errorMessage: "",
    Users: false,
};


export const UsersReducer = function (state = initialState, action) {
    switch (action.type) {

        case ActionTypes.GET_USERS_REQUEST:
            return {
                ...state,
                UsersIsLoading: true,
                error: false,
                UsersSuccess: false
            };
        case ActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                UsersIsLoading: false,
                UsersSuccess: true,
                successMessage: action.payload?.message,
                UsersData: action.payload?.data?.data
            };
        case ActionTypes.GET_USERS_ERROR:
            return {
                ...state,
                UsersIsLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}
export default UsersReducer;