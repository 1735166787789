import { combineReducers } from "redux";
import { AtsUserReducer } from "./User/Reducer";
import { AtsCompanyReducer } from "./Company/Reducer";
import { AtsGroupCompanyReducer } from "./GroupCompany/Reducer";
import { AtsConsultancyReducer } from './Consultancy/Reducer';
import { AtsCandidateReducer } from "./Candidate/Reducer";
import { AtsJobPostReducer } from "./JobPost/Reducer";
import { AtsProfileScoreReducer } from "./ProfileScore/Reducer";
import { AtsInterviewDetailsReducer } from "./InterviewDetails/Reducer";
import { AtsUserInfoReducer } from "./AtsUserInfo/Reducer";
import { AtsInvoiceReducer } from "./Invoice/Reducer";
import { AtsWorkSpaceReducer } from "./SwitchWorkspace/Reducer";
import { AtsUploadReducer } from "./Resume/Reducer";
import { AtsPythonApiReducer } from "./PythonApi/Reducer";
import { AtsReportReducer } from "./Report/Reducer";
import { AtsCandidateExportReducer } from "./CandidateExport/Reducer";
import { IndustryTypeReducer } from "./IndustryType/Reducer";
import { SkillsReducer } from "./Skills/Reducer";
import { JobTemplateReducer } from "./JobTemplate/Reducer";
import { AtsTaskReducer } from "./MyTask/Reducer";
import { FormTemplateReducer } from "./FormTemplate/Reducer";
import AtsDesignationReducer from "./Designation/Reducer";
import { AtsJobFilterReducer } from "./JobFilter/Reducer";

const AtsReducer = combineReducers({
    AtsUserReducer,
    AtsCompanyReducer,
    AtsGroupCompanyReducer,
    AtsConsultancyReducer,
    AtsCandidateReducer,
    AtsJobPostReducer,
    AtsJobFilterReducer,
    AtsProfileScoreReducer,
    AtsInterviewDetailsReducer,
    AtsUserInfoReducer,
    AtsInvoiceReducer,
    AtsWorkSpaceReducer,
    AtsUploadReducer,
    AtsPythonApiReducer,
    AtsReportReducer,
    AtsCandidateExportReducer,
    IndustryTypeReducer,
    SkillsReducer,
    JobTemplateReducer,
    AtsTaskReducer,
    FormTemplateReducer,
    AtsDesignationReducer
});

export default AtsReducer;