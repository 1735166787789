import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";

export const GetStateHelper = async (country_id) => {
    return await axiosInstance(`/ancillary/getStates`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            country_id: +country_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })

}