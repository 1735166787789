import React from 'react';
import Base from '../../../Base/views/Base';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import { Box, Paper, Tab } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useState, useEffect } from 'react';
import PaySlip from './PaySlip';


const ReportsLandingPage = () => {
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue, calendarValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        //eslint-disable-next-line
    }, [])

    return (
        <Base>
            <Paper sx={{ borderRadius: "10px", minHeight: "87vh" }}>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList sx={{
                                minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                '& .MuiTabs-indicator': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: "center !important",
                                },
                            }} onChange={handleChange} aria-label="lab API tabs example">
                                <Tab icon={<ReceiptIcon fontSize='small' />} iconPosition="start" label="Payslip" value="1" />
                                <Tab icon={<TimelineIcon fontSize='small' />} iconPosition="start" label="Tax Forecast" value="2" />
                                <Tab icon={<AssessmentIcon fontSize='small' />} iconPosition="start" label="Form 16" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ padding: "0px" }}>
                            <PaySlip />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                            Tax Forecast
                        </TabPanel>
                        <TabPanel value="3" sx={{ padding: "0px" }}>
                            Form 16
                        </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
        </Base>
    );
}

export default ReportsLandingPage;
