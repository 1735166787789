import * as ActionTypes from '../ActionTypes';

export const GetMyTimeConfigureAction = () => {
    return {
        type: ActionTypes.MY_TIME_CONFIGURE_GET_REQUEST,
    }
}


export const AddMyTimeConfigureAction = (params) => {
    return {
        type: ActionTypes.MY_TIME_CONFIGURE_ADD_REQUEST,
        params
    }
}

export const UpdateMyTimeConfigureAction = (params) => {
    return {
        type: ActionTypes.MY_TIME_CONFIGURE_UPDATE_REQUEST,
        params
    }
}

export const DeleteMyTimeConfigureAction = (params) => {
    return {
        type: ActionTypes.MY_TIME_CONFIGURE_DELETE_REQUEST,
        params
    }
}