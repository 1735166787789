import { useEffect, useState } from 'react';
import Base from '../../../Base/views/Base';
import Popup from '../../../Base/views/Popup';
import RegimeSelectionPopup from './RegimeSelectionPopup';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import TDStep1 from './TDStep1';
import TDStep2 from './TDStep2';
import TDStep3 from './TDStep3';
import TDStep4 from './TDStep4';
import TDStep5 from './TDStep5';
import TaxSummary from './TaxSummary';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as ActionTypes from "../../../../../store/Menu/ActionTypes";
import { GetTaxDeclarationAction } from '../../../../../store/Finance/TaxDeclaration/Action';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#890daf',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#890daf',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[500] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#890daf',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#890daf',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};


const steps = [
    'Section 80C - Maximum Eligible deduction Rs 1.50 lakhs',
    'Other Chapter VIA - Maximum Eligible deduction as per Section Limits',
    'House Rent Exemption (as per U/s 10(13A))',
    'Section 24 - Income/Loss on house property, additional deduction under Section 80EE / 80EEA',
    'Other Details',
    'Summary'
];

const TaxLandingPage = () => {

    const dispatch = useDispatch();
    const { fixedMenu } = useSelector(state => state?.MenuReducer);
    const { taxDeclaration_data, isLoading } = useSelector(state => state?.FinanceReducer?.TaxDeclarationReducer)

    const houseObj = {
        lease_period_id: null,
        rent_from: "",
        rent_to: "",
        rent_per_month: "",
        city: "",
        lp_address: "",
        landl_name: "",
        landl_panno: "",
    };

    const housePropertyObj = {
        house_property_id: null,
        address: "",
        city: "",
        status: "",
        loan_sanc_date: "",
        lender_name: "",
        lender_type: "",
        possession_date: "",
        lender_pan: "",
        principal_amount: "",
        interest_amount: ""
    };

    const regimeValue = {
        agree: false,
        regime: 'old_regime'
    }

    const [inputData, setInputData] = useState()
    const theme = useTheme();
    const [show, setShow] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [step1, setStep1] = useState({});
    const [step2, setStep2] = useState({});
    const [step3, setStep3] = useState([]);
    const [step4, setStep4] = useState([]);
    const [step5, setStep5] = useState({});
    const [regimeData, setRegimeData] = useState(regimeValue)

    useEffect(() => {
        !fixedMenu && dispatch({ type: ActionTypes.CLOSE_MENU })
        dispatch(GetTaxDeclarationAction())
        if (taxDeclaration_data) {
            setStep4(taxDeclaration_data?.[0]?.ehp)
            setStep3(taxDeclaration_data?.[0]?.elp)
            setStep5({ oth1: taxDeclaration_data?.[0]?.emptd?.[0]?.oth1, oth2: taxDeclaration_data?.[0]?.emptd?.[0]?.oth2, comment: taxDeclaration_data?.[0]?.emptd?.[0]?.comment })
            setStep1({
                et_80c_lip: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_lip,
                et_80c_ulip: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_ulip,
                et_80ccc_pension: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80ccc_pension,
                et_80c_ppf: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_ppf,
                et_80c_nsc: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_nsc,
                et_80c_elss: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_elss,
                et_80c_fees: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_fees,
                et_80c_fd: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_fd,
                et_80c_sukanya: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_sukanya,
                et_80c_stamp_duty: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_stamp_duty,
                et_80c_hlp: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80c_hlp,
            })
            setStep2({
                et_80ccd_1b_nps: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80ccd_1b_nps,
                et_80d_sec80d: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80d_sec80d,
                et_80ds_sec80ds: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80ds_sec80ds,
                et_80dp_sec80dp: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80dp_sec80dp,
                et_80dps_sec80dps: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80dps_sec80dps,
                et_80ddp_sec80ddb: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80ddp_sec80ddb,
                et_80ddbs_sec80ddbs: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80ddbs_sec80ddbs,
                et_80e_sec80e: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80e_sec80e,
                et_80dd_sec80dd: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80dd_sec80dd,
                et_80u_sec80u: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80u_sec80u,
                et_80eeb_sec80eeb: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80eeb_sec80eeb,
                et_80ggc_sec80ggc: taxDeclaration_data?.[0]?.emptd?.[0]?.et_80ggc_sec80ggc,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxDeclaration_data?.length === 0]);

    const data = {
        regimeData: regimeData,
        step1: step1,
        step2: step2,
        step3: step3,
        step4: step4,
        step5: step5,
        taxDeclaration_data: taxDeclaration_data
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <TDStep1 activeStep={activeStep} setActiveStep={setActiveStep} step1={step1} setStep1={setStep1} handleInputChange={handleInputChange} />
            case 1:
                return <TDStep2 activeStep={activeStep} setActiveStep={setActiveStep} step2={step2} setStep2={setStep2} handleInputChange={handleInputChange} />
            case 2:
                return <TDStep3 activeStep={activeStep} setActiveStep={setActiveStep} step3={step3} setStep3={setStep3} houseObj={houseObj} handleInputChange={handleInputChange} />
            case 3:
                return <TDStep4 activeStep={activeStep} setActiveStep={setActiveStep} step4={step4} setStep4={setStep4} housePropertyObj={housePropertyObj} handleInputChange={handleInputChange} />
            case 4:
                return <TDStep5 activeStep={activeStep} setActiveStep={setActiveStep} step5={step5} setStep5={setStep5} handleInputChange={handleInputChange} />
            case 5:
                return <TaxSummary activeStep={activeStep} setActiveStep={setActiveStep} inputData={inputData} data={data} />
            default:
                return "Unknown stepIndex";
        }
    }

    const handleInputChange = (event) => {
        setInputData({ ...inputData, [event.target.name]: event.target.value })
    }

    return (
        <Base>
            {
                !isLoading && taxDeclaration_data?.length === 0 &&
                <Popup
                    isMasterProduct
                    fullWidth
                    title="Regime Selection"
                    openPopup={show}
                    setOpenPopup={setShow}
                    close={false}
                >
                    <RegimeSelectionPopup inputData={regimeData} setInputData={setRegimeData} setOpenPopup={setShow} />
                </Popup>
            }
            <Paper sx={{ ".MuiDrawer-paper": { backgroundColor: `${theme.palette.mode === "dark" ? "#544166" : "white"}` }, borderRadius: "10px", paddingTop: "5px" }}>
                <Box sx={{ width: '100%' }}>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>
                                        {/* {label} */}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                    <Divider variant="middle" flexItem sx={{ paddingTop: "5px", paddingBottom: "5px" }} />

                    <div style={{ padding: "0px 30px 20px 30px", minHeight: "81vh", maxHeight: "81vh", overflowY: "auto" }}>
                        <Box container sx={{ justifyContent: "space-between", display: "flex", mt: 2, mb: 2 }}>
                            <Typography>Current Status: Old Regime Opted</Typography>
                            <Typography>Opted Date: 25/10/2022</Typography>
                        </Box>
                        {getStepContent(activeStep)}
                    </div>
                </Box>
            </Paper >
        </Base >
    );
}

export default TaxLandingPage;
