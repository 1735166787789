import { Box, TextField } from '@mui/material';
import MaterialTable from 'material-table';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import { AddYearlyHolidayCalendarAction, DeleteYearlyHolidayCalendarAction, UpdateYearlyHolidayCalendarAction } from '../../../../../store/Leave/YearlyHolidayCalendar/Action';
import { useEffect } from 'react';
import { GetCityListAction } from '../../../../../store/Ancillary/City/Action';
import CommonDatePicker from '../../../Base/views/CommonDatePicker';


const YearlyHolidayCalendar = () => {

    const dispatch = useDispatch();
    const { holidayData } = useSelector(state => state.LeaveReducer?.YearlyHolidayCalendarReducer);
    const { cityList_options } = useSelector(state => state?.AncillaryReducer?.CityReducer)

    useEffect(() => {
        dispatch(GetCityListAction());
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "Holiday Name", cellStyle: { padding: "10px 5px" }, field: "name", validate: rowData => rowData?.name === '' || rowData?.name === undefined ? 'Name cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        },
                        {
                            title: "Date", cellStyle: { padding: "10px 15px", }, field: "date", validate: rowData => rowData?.date === '' || rowData?.date === undefined ? 'Date cannot be empty' : '',
                            editComponent: props => {
                                return (
                                    <CommonDatePicker
                                        size="small"
                                        variant="standard"
                                        name="date"
                                        pickerType="date"
                                        onKeyPress={(e) => e.preventDefault()}
                                        onKeyDown={(e) => e.preventDefault()}
                                        pickerFormat="dd/MM/yyyy"
                                        DefaultDTvalue={props?.value}
                                        handleChange={(value) => props.onChange(`${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}`)}
                                        error={props?.error}
                                        helperText={props?.helperText}
                                    />
                                )
                            },
                        },
                    ]}
                    data={holidayData || ""}
                    icons={{
                        Add: () => <AddIcon />,
                        Edit: () => <EditIcon />,
                        Delete: () => <DeleteIcon />
                    }}
                    editable={{
                        onRowAdd: (newRow) => {
                            if (Object.values(newRow).length === 2) {
                                return new Promise((resolve, reject) => {
                                    dispatch(AddYearlyHolidayCalendarAction({...newRow, cityList_options}))
                                    setTimeout(() => resolve(), 500);
                                });
                            } else {
                                return Promise.resolve(); 
                            }
                        },
                        onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(UpdateYearlyHolidayCalendarAction(newRow))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowDelete: (selectedRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(DeleteYearlyHolidayCalendarAction({ ...selectedRow, holidaycalendar_id: `{${selectedRow?.holidaycalendar_id}}` }))
                                setTimeout(() => resolve(), 1000);
                            }),
                    }}
                    options={{
                        maxBodyHeight: 220,
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: false,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    title=""
                />
            </Box>
        </>
    );
}

export default YearlyHolidayCalendar;
