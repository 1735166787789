import { takeLatest } from 'redux-saga/effects';
import {
    GetRoleSaga,
    GetModuleSaga,
    GetSubModuleSaga,
    GetPermissionSaga,
    GetRoleAccessSaga,
    AddRoleAccessSaga,
    UpdateRoleAccessSaga,
    DeleteRoleAccessSaga,
    GetATSModuleSaga,
    GetATSModuleFeaturesSaga,
    GetUserRoleSaga
} from './RoleAndAccessSaga';
import * as ActionTypes from './ActionTypes';

export default function* RoleAndAccessWatcher() {
    yield takeLatest(ActionTypes.ROLE_GET_REQUEST, GetRoleSaga);
    yield takeLatest(ActionTypes.ROLE_USER_GET_REQUEST, GetUserRoleSaga);
    yield takeLatest(ActionTypes.MODULE_GET_REQUEST, GetModuleSaga);
    yield takeLatest(ActionTypes.ATS_MODULE_GET_REQUEST, GetATSModuleSaga);
    yield takeLatest(ActionTypes.ATS_MODULE_FEATURES_GET_REQUEST, GetATSModuleFeaturesSaga);
    yield takeLatest(ActionTypes.SUB_MODULE_GET_REQUEST, GetSubModuleSaga);
    yield takeLatest(ActionTypes.PERMISSION_GET_REQUEST, GetPermissionSaga);
    yield takeLatest(ActionTypes.ROLE_ACCESS_GET_REQUEST, GetRoleAccessSaga);
    yield takeLatest(ActionTypes.ROLE_ACCESS_ADD_REQUEST, AddRoleAccessSaga);
    yield takeLatest(ActionTypes.ROLE_ACCESS_UPDATE_REQUEST, UpdateRoleAccessSaga);
    yield takeLatest(ActionTypes.ROLE_ACCESS_DELETE_REQUEST, DeleteRoleAccessSaga);
}
