import * as ActionTypes from '../ActionTypes';

const initialState = {
    configurestatusData: [],
    CountryData:[],
    isLoading: false,
};

const ConfigureReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CONFIGURE_STATUS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                configurestatusData: "",
            };
        case ActionTypes.CONFIGURE_STATUS_GET_SUCCESS:
            return {
                ...state,
                success_message: action.payload.message,
                error: action.payload.has_error,
                isLoading: false,
                configurestatusData: action.payload?.data?.data
            };
        
        case ActionTypes.CONFIGURE_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.CONFIGURE_STATUS_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                configurestatusData: action.payload?.data?.data
            };
        case ActionTypes.CONFIGURE_STATUS_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
     
            case ActionTypes.COUNTRY_GET_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                    CountryData: "",
                };
            case ActionTypes.COUNTRY_GET_SUCCESS:
                return {
                    ...state,
                    success_message: action.payload.message,
                    error: action.payload.has_error,
                    isLoading: false,
                    CountryData: action.payload?.data?.data
                };

        default:
            return state;
    }
}

export default ConfigureReducer;