import { put, call } from 'redux-saga/effects';
import { GetUserInfoHelper, GetUserRoleAccessHelper } from '../../app/Components/Accesses/helper/AccessHelper';
import * as ActionTypes from './ActionTypes';

export function* UserRoleAccessSaga(payload) {
    const response = yield call(GetUserRoleAccessHelper, payload?.data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.USER_ROLE_ACCESS_SUCCESS, payload: response })
    }
}

export function* UserInfoSaga(payload) {
    const response = yield call(GetUserInfoHelper, payload?.params, payload?.dispatch)
    if (response?.hasError === false) {
        yield put({ type: ActionTypes.USER_INFO_SUCCESS, payload: response })
    }
}
