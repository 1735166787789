
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddEmploymentTypeAction, UpdateEmploymentTypeAction, DeleteEmploymentTypeAction } from "../../../../../store/Ancillary/EmploymentType/Action";

const EmploymentType = () => {
    const dispatch = useDispatch()
    const { employment_type_options } = useSelector(state => state?.AncillaryReducer?.EmploymentTypeReducer);

    return (
        <Box>
            <MaterialTable
                columns={[
                    {
                        title: "Name", cellStyle: { padding: "0px 10px" }, field: "etype_name", validate: rowData => rowData?.etype_name === '' || rowData?.etype_name === undefined ? 'Name cannot be empty' : '',
                        editComponent: props => (
                            <TextField
                                required={true}
                                variant="standard"
                                value={props?.value}
                                onChange={e => props.onChange(e.target.value)}
                                error={props?.error}
                                helperText={props?.helperText}
                            />
                        )
                    },
                ]}
                data={employment_type_options || []}
                icons={{
                    Add: () => <AddIcon />,
                    Edit: () => <EditIcon />,
                    Delete: () => <DeleteIcon />
                }}
                editable={{
                    isEditable: rowData => !rowData?.is_default,
                    isDeletable: rowData => !rowData?.is_default,
                    onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(AddEmploymentTypeAction(newRow))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                            !newRow?.is_default &&
                            dispatch(UpdateEmploymentTypeAction(newRow))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowDelete: (selectedRow) =>
                        new Promise((resolve, reject) => {
                            !selectedRow?.is_default &&
                            dispatch(DeleteEmploymentTypeAction({ employmenttype_id: `{${selectedRow?.employmenttype_id}}` }))
                            setTimeout(() => resolve(), 1000);
                        }),
                }}
                options={{
                    maxBodyHeight: 220,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                    sorting: true,
                    search: false,
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: false,
                    selection: false,
                    actionsColumnIndex: -1,
                    grouping: false,
                    addRowPosition: "first",
                }}
                title=""
            />
        </Box>
    );
}
export default EmploymentType;