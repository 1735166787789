import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetTaxDeclarationHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/finance/getTaxDeclaration`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id,
        }
    })
        .then(res => {
            return { message: "Tax Declaration Retrived Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddTaxDeclarationHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/finance/addTaxDeclaration`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id,
            emptd: params?.emptd || null,
            emplp: params?.emplp || null,
            emphp: params?.emphp || null,
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Tax Declaration Added Successfully");
            return { message: "Tax Declaration Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateTaxDeclarationHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/finance/updateTaxDeclaration`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id,
            emp_tax_declaration_id: params?.id,
            emptd: params?.emptd || null,
            emplp: params?.emplp || null,
            emphp: params?.emphp || null,
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Tax Declaration Updated Successfully");
            return { message: "Tax Declaration Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}