import * as ActionTypes from './ActionTypes';

const initialState = {
    loading: false,
    error: false,
    errorMessage: "",
    tourStatus: true
};

const TourReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.START_TOUR_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            };
        case ActionTypes.START_TOUR_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                tourStatus: action.payload
            };
        case ActionTypes.START_TOUR_ERROR:
            return {
                ...state,
                errorMessage: action?.payload,
                error: true,
                success: false,
                loading: false
            };
        case ActionTypes.END_TOUR_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            };
        case ActionTypes.END_TOUR_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                tourStatus: action.payload
            };
        case ActionTypes.END_TOUR_ERROR:
            return {
                ...state,
                errorMessage: action?.payload,
                error: true,
                success: false,
                loading: false
            };

        default:
            return state;
    }
}

export default TourReducer;