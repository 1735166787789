import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
    taskData: [],
    assignedData: [],
};

export const AtsTaskReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MY_TASK_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.MY_TASK_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                taskData: action.payload?.data?.data
            };
        case ActionTypes.MY_TASK_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.MY_TASK_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.MY_TASK_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                taskData: action.payload?.data?.data
            };
        case ActionTypes.MY_TASK_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.ASSIGNED_TASK_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ASSIGNED_TASK_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                assignedData: action.payload?.data?.data
            };
        case ActionTypes.ASSIGNED_TASK_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.ASSIGNED_TASK_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ASSIGNED_TASK_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                assignedData: action.payload?.data?.data
            };
        case ActionTypes.ASSIGNED_TASK_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.ASSIGNED_TASK_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ASSIGNED_TASK_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                assignedData: action.payload?.data?.data
            };
        case ActionTypes.ASSIGNED_TASK_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.ASSIGNED_TASK_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ASSIGNED_TASK_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                assignedData: action.payload?.data?.data
            };
        case ActionTypes.ASSIGNED_TASK_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        default:
            return state;
    }
}