import * as ActionTypes from '../ActionTypes';

export const GetEmployeeEducationAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_EDUCATION_GET_REQUEST,
        params
    }
}
export const AddEmployeeEducationAction = (educationData) => {
    return {
        type: ActionTypes.EMPLOYEE_EDUCATION_ADD_REQUEST,
        educationData
    }
}
export const UpdateEmployeeEducationAction = (educationData) => {
    return {
        type: ActionTypes.EMPLOYEE_EDUCATION_UPDATE_REQUEST,
        educationData
    }
}
export const DeleteEmployeeEducationAction = (educationData) => {
    return {
        type: ActionTypes.EMPLOYEE_EDUCATION_DELETE_REQUEST,
        educationData
    }
}

export const GetEmployeeWorkExperienceAction = (workExperienceData) => {
    return {
        type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_GET_REQUEST,
        workExperienceData
    }
}
export const AddEmployeeWorkExperienceAction = (workExperienceData) => {
    return {
        type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_ADD_REQUEST,
        workExperienceData
    }
}
export const UpdateEmployeeWorkExperienceAction = (workExperienceData) => {
    return {
        type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_UPDATE_REQUEST,
        workExperienceData
    }
}
export const DeleteEmployeeWorkExperienceAction = (workExperienceData) => {
    return {
        type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_DELETE_REQUEST,
        workExperienceData
    }
}
