import { combineReducers } from "redux";
import RelationshipReducer from "./Relationship/Reducer"
import BankReducer from "./Bank/Reducer"
import StudyLevelReducer from "./StudyLevel/Reducer"
import StudyProgramReducer from "./StudyProgram/Reducer"
import DesignationReducer from "./Designation/Reducer";
import JobReducer from "./Job/Reducer";
import EmploymentTypeReducer from "./EmploymentType/Reducer"
import PaygradeReducer from "./Paygrade/Reducer"
import DocumentationsReducer from "./Documentations/Reducer"
import CostCenterReducer from "./CostCenter/Reducer";
import CityReducer from "./City/Reducer";
import LeavingReasonReducer from "./LeavingReason/Reducer";
import BusinessUnitReducer from "./BusinessUnit/Reducer";
import SubBusinessUnitReducer from "./SubBusinessUnit/Reducer";
import CasteReducer from "./Caste/Reducer";
import WorkLocationReducer from "./WorkLocation/Reducer";
import ProofTypeReducer from "./ProofType/Reducer";
import NotificationReducer from "./Notification/Reducer";
import UsersReducer from "./Users/Reducer";
import ConfigureReducer from "./ConfigureStatus/Reducer";
import WeekDaysReducer from "./WeekDays/Reducer";



const AncillaryReducer = combineReducers({
    RelationshipReducer,
    BankReducer,
    StudyLevelReducer,
    StudyProgramReducer,
    DesignationReducer,
    JobReducer,
    EmploymentTypeReducer,
    PaygradeReducer,
    CostCenterReducer,
    CityReducer,
    LeavingReasonReducer,
    BusinessUnitReducer,
    SubBusinessUnitReducer,
    CasteReducer,
    WorkLocationReducer,
    ProofTypeReducer,
    NotificationReducer,
    DocumentationsReducer,
    UsersReducer,
    ConfigureReducer,
    WeekDaysReducer
});

export default AncillaryReducer;