import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { convertBase64 } from "../../../Base/helper/baseFunctions";
import { v4 as uuidv4 } from "uuid";
import { AddFormTemplateResponseHelper } from "../../helper/AtsFormTemplateResponseHelper";
import { useEffect } from "react";
import { FormComponentsRender } from "./FormComponentsRender";
import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";
import { useParams } from "react-router";
import {
  DeleteEmailTokenHelper,
  GetEmailTokenHelper,
} from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { AddCandidateFeedbackHistoryHelper } from "../../../ATS/helper/AtsCandidateHelper";
import { AddNotificationHelper } from "../../../Ancillary/Notification/helper/NotificationHelper";
import FeedBackTokenNotFound from "../../../Base/views/FeedbackTokenNotFound";
import { GetFormTemplateHelper } from "../../helper/AtsFormTemplateHelper";

const FormResponse = ({ data, userData }) => {
  const { guid } = useParams();
  const [candidateData, setCandidateData] = useState({});
  const [viewMode, setViewMode] = useState("Loading");
  const [formFields, setFormFields] = useState([]);
  const [formComponents, setFormComponents] = useState([]);
  const [formHeading, setFormHeading] = useState({ heading: "" });
  const [formData, setFormData] = useState([]);
  const [previewErrors, setpreviewErrors] = useState({});
  const [checkData, setCheckData] = useState({ values: [] });
  const [requestedDate, setRequestedDate] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (guid) {
        GetEmailTokenHelper({
          domain: guid?.split("=")?.[1],
          token: guid,
        }).then((res) => {
          if (res?.data?.data?.[0]?.email_tokens_id) {
            setCandidateData({
              ...res?.data?.data?.[0]?.json_data?.[0],
              domain: guid?.split("=")?.[1],
            });
            setViewMode("Feedback");
            setRequestedDate(res?.data?.data?.[0]?.createdon);
          } else {
            setViewMode("TokenNotFound");
          }
        });
      } else {
        setCandidateData({ ...data });
      }
    }, 1000);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (candidateData?.feedback_template_id) {
      GetFormTemplateHelper({
        workspace: guid?.split("=")?.[1] || userData?.domain,
        template_id: candidateData?.feedback_template_id,
      }).then((res) => {
        setFormFields(res?.data?.data[0]?.template_fields);
        setFormHeading({ heading: res?.data?.data[0]?.template_name });
        setViewMode("Feedback");
      });
    }
    //eslint-disable-next-line
  }, [candidateData]);

  useEffect(() => {
    if (formFields?.length > 0) {
      setFormComponents([]);
      FormComponentsRender({
        formFields,
        checkData,
        setCheckData,
        formData,
        setFormData,
        previewErrors,
        setFormComponents,
        Previewvalidate,
      });
    }
    //eslint-disable-next-line
  }, [formFields]);

  useEffect(() => {
    FormComponentsRender({
      formFields,
      checkData,
      setCheckData,
      formData,
      setFormData,
      previewErrors,
      setFormComponents,
      Previewvalidate,
    });
    //eslint-disable-next-line
  }, [previewErrors]);

  const Previewvalidate = (fieldDefinitions, fieldValues) => {
    let temp = { ...previewErrors };
    fieldDefinitions?.forEach((field, index) => {
      const isRequired = field.required;
      if (isRequired && !fieldValues[field.name]) {
        temp[field.name] = `${field.label} is required`;
      } else {
        temp[field.name] = "";
      }
    });
    setpreviewErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleResponseSubmit = async () => {
    if (Previewvalidate(formFields, formData)) {
      let b64string = "";
      let filename = "";
      for (const field of formFields) {
        const fieldName = field.name;
        const fieldType = field.type;
        if (fieldType === "file" && formData[fieldName]) {
          const fileField = formData[fieldName];
          if (fileField instanceof File) {
            b64string = await convertBase64(fileField);
            const ext = fileField.name.split(".").pop();
            filename = `${
              guid?.split("=")?.[1]
            }/ATS/Feedbacks/${uuidv4()}.${ext}`;
            formData[fieldName] = filename;
            break;
          }
        }
      }
      AddFormTemplateResponseHelper({
        workspace: guid?.split("=")?.[1] || userData?.domain,
        user_id: candidateData?.request_emp_id || userData?.employee_id,
        feedback_template_id: candidateData?.feedback_template_id,
        feedback_response: JSON.stringify(formData),
        file: b64string,
        file_name: filename,
        requested_by_id: guid
          ? candidateData?.request_emp_id
          : userData?.employee_id,
        given_by_name: guid
          ? candidateData?.responser_name
          : userData?.user_name,
        given_by_email: guid ? candidateData?.email_id : userData?.email_id,
        requested_date: guid ? requestedDate : null,
      }).then((res) => {
        if (res?.data?.statuscode === 200) {
          setViewMode("FeedbackSuccess");
          if (guid) {
            DeleteEmailTokenHelper({
              domain: guid?.split("=")?.[1],
              token: guid,
            });
          }
          AddCandidateFeedbackHistoryHelper({
            domain: guid?.split("=")?.[1] || userData?.domain,
            employee_id: candidateData?.request_emp_id || userData?.employee_id,
            requester_name:
              candidateData?.responser_name || userData?.user_name,
            candidate: candidateData?.candidate_id,
            ats_jobpost: candidateData?.ats_jobpost_id,
            ats_job_stages: candidateData?.ats_job_stages_id,
            feedback_response_id:
              res?.data?.data?.[0]?.fn_add_feedback_response,
          });
          AddNotificationHelper({
            domain: guid?.split("=")?.[1] || userData?.domain,
            employee_id: candidateData?.request_emp_id || userData?.employee_id,
            notification_data: JSON.stringify([
              {
                user_id: +candidateData?.request_emp_id,
                comment: `${candidateData?.responser_name} has provided feedback of ${candidateData?.first_name} for the position of ${candidateData?.title}.`,
                is_read: "N",
              },
            ]),
          });
        }
      });
    }
  };
  return (
    <UnauthorizedBase>
      <Box
        className="bg_form_main"
        sx={{ minHeight: "90vh", paddingTop: "50px", width: "100%" }}
      >
        {viewMode === "Loading" ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", pb: 2 }}
                    >
                      <Skeleton animation="wave" width={200} />
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Skeleton
                          animation="wave"
                          variant="rectangualar"
                          height={350}
                          width={400}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Skeleton animation="wave" height={50} width={100} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        ) : viewMode === "Feedback" ? (
          <Box className="response-main">
            {formComponents?.length > 0 && (
              <>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="Form_head">
                      <Typography
                        mt="2.125rem"
                        variant="h4"
                        gutterBottom
                        fontSize="2.25rem"
                      >
                        {formHeading?.heading}
                      </Typography>
                    </Box>
                    <Grid container className="form_content">
                      {formComponents.map((component, index) => (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ padding: "1.5rem 1.25rem" }}
                        >
                          {component}
                        </Grid>
                      ))}
                    </Grid>
                    <Box
                      className="form_content_btn"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={{ fontSize: "15px" }}
                        variant="contained"
                        className="mpl-primary-btn"
                        onClick={handleResponseSubmit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        ) : viewMode === "TokenNotFound" ? (
          <FeedBackTokenNotFound />
        ) : (
          viewMode === "FeedbackSuccess" && (
            <Grid item>
              <Box
                className="response-main"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "400px",
                }}
              >
                <Typography
                  textAlign={"center"}
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                  padding={"20px"}
                >
                  Feedback Submitted successfully!
                </Typography>
                <Typography
                  padding={"10px"}
                  textAlign={"center"}
                  variant="body1"
                  color="text.secondary"
                >
                  All your responses have been successfully recorded! You may
                  now leave the page.
                </Typography>
              </Box>
            </Grid>
          )
        )}
      </Box>
    </UnauthorizedBase>
  );
};
export default FormResponse;
