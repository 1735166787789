import * as ActionTypes from '../ActionTypes';

export const GetEmployeePersonalAction = (personalData) => {
    return {
        type: ActionTypes.EMPLOYEE_PERSONAL_GET_REQUEST,
        personalData
    }
}

export const AddEmployeePersonalAction = (personalData) => {
    return {
        type: ActionTypes.EMPLOYEE_PERSONAL_ADD_REQUEST,
        personalData
    }
}

export const UpdateEmployeePersonalAction = (personalData) => {
    return {
        type: ActionTypes.EMPLOYEE_PERSONAL_UPDATE_REQUEST,
        personalData
    }
}

export const DeleteEmployeePersonalAction = (personalData) => {
    return {
        type: ActionTypes.EMPLOYEE_PERSONAL_DELETE_REQUEST,
        personalData
    }
}
