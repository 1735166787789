import * as ActionTypes from '../ActionTypes';

const componentTypeInitialState = {
    isLoading: false,
    success: false,
    error: false,
    salaryComponentType_data: []
}

export const SalaryComponentTypeReducer = function (state = componentTypeInitialState, action) {
    switch (action.type) {
        case ActionTypes.SALARY_COMPONENT_TYPE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.SALARY_COMPONENT_TYPE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                salaryComponentType_data: action.payload?.data?.data
            };
        case ActionTypes.SALARY_COMPONENT_TYPE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}

