import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: true,
    error: false,
    errorMessage: "",
    success: false,
    atsJobPostData: [],
    atsJobPostMode: "",
    atsJobOffer:"",
    updateSuccess:""

};

export const AtsJobPostReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_JOB_POST_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                atsJobPostData: []
            };
        case ActionTypes.ATS_JOB_POST_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsJobPostData: action.payload?.data?.data
            };
        case ActionTypes.ATS_JOB_POST_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_JOB_POST_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ATS_JOB_POST_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsJobPostData: action.payload?.data?.data
            };
        case ActionTypes.ATS_JOB_POST_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_JOB_POST_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                updateSuccess:""
            };
        case ActionTypes.ATS_JOB_POST_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsJobPostData: action.payload?.data?.data,
                updateSuccess:action.payload?.data?.statuscode
            };
        case ActionTypes.ATS_JOB_POST_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message,
                updateSuccess:""
            };
        case ActionTypes.ATS_JOB_FILTER_ADD_REQUEST:
            return {
                ...state,
                isFilterLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ATS_JOB_FILTER_ADD_SUCCESS:
            return {
                ...state,
                isFilterLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsJobFilterData: action.payload
            };
        case ActionTypes.ATS_JOB_FILTER_ADD_ERROR:
            return {
                ...state,
                isFilterLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_JOB_POST_MODE:
            return {
                ...state,
                atsJobPostMode: action?.params?.job_mode,
                currentJobData: action?.params?.currentJobData
            };
            case ActionTypes.ATS_JOB_OFFER_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                atsJobOffer: ""
            };
        case ActionTypes.ATS_JOB_OFFER_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsJobOffer: action.payload?.data?.data
            };
        case ActionTypes.ATS_JOB_OFFER_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}