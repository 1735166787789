import { lighten } from '@mui/material'
import { useTheme } from "@mui/material/styles";

const ArrowSvg = () => {

    const theme = useTheme()
    let color = lighten(theme?.palette?.primary?.main, 0)

    return (
        <>
            <svg class="MuiBox-root css-pp6g9h" width={'25%'} fill="none" viewBox="0 0 96 97" xmlns="http://www.w3.org/2000/svg">
                <path fill={color} d="M80.476 16.01c-2-2-48.544 35.833-52.269 43.652 0 0-.155 29.249 5.21 30.093 2.904 0 8.228-15.41 9.35-18.755.143-.428.339-.805.612-1.163 11.697-15.343 39.074-51.85 37.097-53.827z">
                </path>
                <path fill={color} d="M11.167 39.109c-2.51 1.716-2.606 4.943-.319 6.946 3.06 2.68 8.372 7.018 17.359 13.62v-.013c3.724-7.82 50.269-45.652 52.269-43.652 1.977 1.977-25.4 38.484-37.097 53.826-.273.36-.47.736-.613 1.163-1.082 3.227-6.071 17.672-9.03 18.699 6.326-1.22 12.623-6.279 18.458-13.265 7.403 4.887 12.643 8.034 15.829 9.86 2.228 1.279 4.962.804 6.134-1.482 6.764-13.202 14.387-52.323 15.821-72.819.231-3.305-2.447-5.951-5.707-5.355-20.295 3.714-59.214 22.977-73.104 32.472zM33.417 89.755z">
                </path>
                <g fill="#fff" fill-opacity="0.04" filter="url(#filter0_i_1870_134242)" style={{ mixBlendMode: "overlay" }}>
                    <path d="M11.167 39.109c-2.51 1.716-2.606 4.943-.319 6.946 3.06 2.68 8.372 7.018 17.359 13.62v-.013c3.724-7.82 50.269-45.652 52.269-43.652 1.977 1.977-25.4 38.484-37.097 53.826-.273.36-.47.736-.613 1.163-1.082 3.227-6.071 17.672-9.03 18.699 6.326-1.22 12.623-6.279 18.458-13.265 7.403 4.887 12.643 8.034 15.829 9.86 2.228 1.279 4.962.804 6.134-1.482 6.764-13.202 14.387-52.323 15.821-72.819.231-3.305-2.447-5.951-5.707-5.355-20.295 3.714-59.214 22.977-73.104 32.472zM33.417 89.755z">
                    </path>
                </g>
                <defs>
                    <filter id="filter0_i_1870_134242" width="82.787" height="85.202" x="7.205" y="4.553" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix">
                        </feFlood>
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape">
                        </feBlend>
                        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0">
                        </feColorMatrix>
                        <feOffset dx="-2" dy="-2">
                        </feOffset>
                        <feGaussianBlur stdDeviation="2">
                        </feGaussianBlur>
                        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic">
                        </feComposite>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0">
                        </feColorMatrix>
                        <feBlend in2="shape" result="effect1_innerShadow_1870_134242">
                        </feBlend>
                    </filter>
                </defs>
            </svg>        </>
    )
}
export default ArrowSvg