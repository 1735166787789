import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { convertBase64 } from "../../../Base/helper/baseFunctions";
import { v4 as uuidv4 } from "uuid";
import { AddPreScreenTemplateResponseHelper } from "../../helper/AtsFormTemplateResponseHelper";
import { useEffect } from "react";
import { PreScreenComponentsRender } from "./PreScreenComponentsRender";
import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";
import { useParams } from "react-router";
import {
  DeleteEmailTokenHelper,
  GetEmailTokenHelper,
} from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { GetPreScreenTemplateHelper } from "../../helper/AtsFormTemplateHelper";
import PreScreenTokenNotFound from "../../../Base/views/PreScreenTokenNotFound";
import history from "../../../../../history";


const PreScreenResponse = ({ data, userData, carrerPortal, domainname, externalData, setPop }) => {
  const { guid } = useParams();
  const [candidateData, setCandidateData] = useState({});
  const [viewMode, setViewMode] = useState("Loading");
  const [formFields, setFormFields] = useState([]);
  const [formComponents, setFormComponents] = useState([]);
  const [formHeading, setFormHeading] = useState({ heading: "" });
  const [formData, setFormData] = useState([]);
  const [previewErrors, setpreviewErrors] = useState({});
  const [checkData, setCheckData] = useState({ values: [] });
  const [id, setId] = useState();
  useEffect(() => {
    setTimeout(() => {
      if (guid) {
        GetEmailTokenHelper({
          domain: guid?.split("=")?.[1],
          token: guid,
        }).then((res) => {
          if (res?.data?.data?.[0]?.email_tokens_id) {
            setCandidateData({
              ...res?.data?.data?.[0]?.json_data?.jobData,
              domain: guid?.split("=")?.[1],
            });
            setId(res?.data?.data?.[0]?.json_data?.kanban_data?.candidate_job_id)
            setViewMode("Feedback");
          } else {
            setViewMode("TokenNotFound");
          }
        });
      } else {
        setCandidateData({ ...data });
      }
    }, 1000);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (candidateData?.prescreen_template_id) {
      GetPreScreenTemplateHelper({
        workspace: guid?.split("=")?.[1] || userData?.domain || domainname,
        prescreen_template_id: candidateData?.prescreen_template_id,
      }).then((res) => {
        setFormFields(res?.data?.data[0]?.prescreen_template_fields);
        setFormHeading({ heading: res?.data?.data[0]?.prescreen_template_name });
        setViewMode("Feedback");
      });
    }
    //eslint-disable-next-line
  }, [candidateData]);

  useEffect(() => {
    if (formFields?.length > 0) {
      setFormComponents([]);
      PreScreenComponentsRender({
        formFields,
        checkData,
        setCheckData,
        formData,
        setFormData,
        previewErrors,
        setFormComponents,
        Previewvalidate,
      });
    }
    //eslint-disable-next-line
  }, [formFields]);

  useEffect(() => {
    PreScreenComponentsRender({
      formFields,
      checkData,
      setCheckData,
      formData,
      setFormData,
      previewErrors,
      setFormComponents,
      Previewvalidate,
    });
    //eslint-disable-next-line
  }, [previewErrors]);

  const Previewvalidate = (fieldDefinitions, fieldValues) => {
    let temp = { ...previewErrors };
    fieldDefinitions?.forEach((field, index) => {
      const isRequired = field.required;
      if (isRequired && !fieldValues[field.name]) {
        temp[field.name] = `${field.label} is required`;
      } else {
        temp[field.name] = "";
      }
    });
    setpreviewErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleResponseSubmit = async () => {
    if (Previewvalidate(formFields, formData)) {
      let b64string = "";
      let filename = "";
      for (const field of formFields) {
        const fieldName = field.name;
        const fieldType = field.type;
        if (fieldType === "file" && formData[fieldName]) {
          const fileField = formData[fieldName];
          if (fileField instanceof File) {
            b64string = await convertBase64(fileField);
            const ext = fileField.name.split(".").pop();
            filename = `${
              guid?.split("=")?.[1]
            }/ATS/PreScreenQuestions/${uuidv4()}.${ext}`;
            formData[fieldName] = filename;
            break;
          }
        }
      }
      AddPreScreenTemplateResponseHelper({
        workspace: guid?.split("=")?.[1] || userData?.domain || domainname,
        user_id: candidateData?.createdby || null,
        prescreen_template_id: candidateData?.prescreen_template_id,
        prescreen_response: JSON.stringify(formData),
        file: b64string,
        file_name: filename,
        candidate_job_id: guid
          ? id
          : externalData?.fn_ats_ext_addcandidate,
      }).then((res) => {
        if (res?.data?.statuscode === 200) {
          setViewMode("FeedbackSuccess");
          if (guid) {
            DeleteEmailTokenHelper({
              domain: guid?.split("=")?.[1],
              token: guid,
            });
          }
          if(carrerPortal){
            setPop(true)
            setTimeout(() => history.push("/"), 3000);

          }
        }
      });
    }
  };
  return (
    <>
      {carrerPortal ? (
        <>
          <Box sx={{ paddingTop:"10px", width:"100%"}}>
            {viewMode === "Loading" ? (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Card>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            pb: 2,
                          }}
                        >
                          <Skeleton animation="wave" width={200} />
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Skeleton
                              animation="wave"
                              variant="rectangualar"
                              height={350}
                              width={400}
                            />
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Skeleton animation="wave" height={50} width={100} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            ) : viewMode === "Feedback" ? (
              <Box className="response-main">
                {formComponents?.length > 0 && (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Box className="Form_head">
                            <Typography
                            mt="2.125rem"
                              variant="h4"
                              gutterBottom
                              fontSize="2.25rem"
                            >
                              {formHeading?.heading}
                            </Typography>
                            </Box>
                            <Grid className="form_content" container>
                              {formComponents.map((component, index) => (
                                <Grid
                                sx={{ padding:"1.5rem 1.25rem"}}
                                  item
                                  key={index}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                >
                                  {component}
                                </Grid>
                              ))}
                            </Grid>
                            <Box
                            className="form_content_btn"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                sx={{ fontSize:"15px"}}
                                variant="contained"
                                className="mpl-primary-btn"
                                onClick={handleResponseSubmit}
                              >
                                Submit
                              </Button>
                            </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            ) : viewMode === "TokenNotFound" ? (
              <PreScreenTokenNotFound />
            ) : (
              viewMode === "FeedbackSuccess" && (
                <Grid item>
                  <Box
                    className="response-main"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "400px",
                    }}
                  >
                    <Typography
                       padding={"20px"}
                      textAlign={"center"}
                      component="h1"
                      variant="h3"
                      fontWeight={700}
                      lineHeight={1.5}
                    >
                      Pre screening form submitted successfully!
                    </Typography>
                  </Box>
                </Grid>
              )
            )}
          </Box>
        </>
      ) : (
        <UnauthorizedBase>
          <Box className="bg_form_main" sx={{minHeight:"90vh", paddingTop:"50px", width:"100%"}}>
            {viewMode === "Loading" ? (
              <Box >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Card>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            pb: 2,
                          }}
                        >
                          <Skeleton animation="wave" width={200} />
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Skeleton
                              animation="wave"
                              variant="rectangualar"
                              height={350}
                              width={400}
                            />
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Skeleton animation="wave" height={50} width={100} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            ) : viewMode === "Feedback" ? (
              <Box className="response-main">
                {formComponents?.length > 0 && (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Box className="Form_head">
                            <Typography
                            mt="2.125rem"
                              variant="h4"
                              gutterBottom
                              fontSize="2.25rem"
                            >
                              {formHeading?.heading}
                            </Typography>
                            </Box>
                            <Grid className="form_content" container>
                              {formComponents.map((component, index) => (
                                <Grid
                                sx={{ padding:"1.5rem 1.25rem"}}
                                  item
                                  key={index}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                >
                                  {component}
                                </Grid>
                              ))}
                            </Grid>
                            <Box
                            className="form_content_btn"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                sx={{ fontSize:"15px"}}
                                variant="contained"
                                className="mpl-primary-btn"
                                onClick={handleResponseSubmit}
                              >
                                Submit
                              </Button>
                            </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            ) : viewMode === "TokenNotFound" ? (
              <PreScreenTokenNotFound />
            ) : (
              viewMode === "FeedbackSuccess" && (
                <Grid item>
                  <Box
                    className="response-main"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "400px",
                    }}
                  >
                    <Typography
                      textAlign={"center"}
                      component="h1"
                      variant="h3"
                      fontWeight={700}
                      lineHeight={1.5}
                      padding={"20px"}
                    >
                      Pre screening form submitted successfully!
                    </Typography>
                    <Typography
                      padding={"10px"}
                      textAlign={"center"}
                      variant="body1"
                      color="text.secondary"
                    >
                      All your responses have been successfully recorded! You
                      may now leave the page.
                    </Typography>
                  </Box>
                </Grid>
              )
            )}
          </Box>
        </UnauthorizedBase>
      )}
    </>
  );
};
export default PreScreenResponse;
