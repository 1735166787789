import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CustomMention";
import '../css/quill.css';

const Quill = ({
  value,
  setValue,
  readonly,
  Toolbar,
  showBorder,
  style,
  hideHeading,
  mentionUsers,
}) => {
  const [showMentionList, setShowMentionList] = useState(false);
  const quillRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [mentionDropdownPosition, setMentionDropdownPosition] = useState({ top: 0, left: 0 });
  const Font = ReactQuill.Quill.import("formats/font");
  Font.whitelist = ["nunito"];
  ReactQuill.Quill.register(Font, true);

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    const handleSelectionChange = (range) => {
      if (range) {
        setCursorPosition(range.index);
        const bounds = quill.getBounds(range.index);
        setMentionDropdownPosition({
          top: bounds.top,
          left: bounds.left,
        });
        setShowMentionList(false);
      }
    };
    quill.on("selection-change", handleSelectionChange);
    quill.keyboard.addBinding({ key: 50, shiftKey: true }, () => {
      setShowMentionList(true);
    });

    return () => {
      quill.off("selection-change", handleSelectionChange);
    };
  }, []);

  const handleMentionSelect = (user) => {
    const quillEditor = quillRef.current.getEditor();
    const mentionText = `@{${user.name}} `;
    quillEditor.insertText(cursorPosition, mentionText, "user");
    quillEditor.setSelection(cursorPosition + mentionText.length);
    setValue(quillEditor.root.innerHTML);
    setShowMentionList(false);
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "header",
    "align",
    "list",
    "indent",
  ];

  let toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: ["", "right"] }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }],
  ];

  if (hideHeading) {
    toolbarOptions = toolbarOptions.filter(
      (option) => !option.some((item) => item.header)
    );
  }

  const modules = {
    toolbar: Toolbar === false ? false : toolbarOptions,
  };

  const containerClassName =
    showBorder === false
      ? "quill-container hide-border"
      : "quill-container show-border";

  return (
    <div className={containerClassName} style={{ position: "relative" }}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={(content, delta, source, editor) => {
          setValue(content);
          setCursorPosition(editor.getSelection()?.index || 0); 
        }}
        formats={formats}
        modules={modules}
        style={style}
        readOnly={readonly}
      />
      {showMentionList && (
        <ul
          className="mention-dropdown"
          style={{
            position: "absolute",
            top: mentionDropdownPosition.top,
            left: mentionDropdownPosition.left,
          }}
        >
          {mentionUsers?.map((user, index) => (
            <li key={index} onClick={() => handleMentionSelect(user)}>
              {user.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Quill;
