import { takeLatest } from 'redux-saga/effects';
import { MeeplAdminSaga, UserArchiveSaga, GetCreatedSchemasSaga, DisableWorkspaceSaga, GetMeeplAdminUserSaga, AddMeeplAdminUserSaga, UpdateMeeplAdminUserSaga, EnableWorkspaceSaga, getWorkspaceExpiryRequestSaga, requestAdminSaga, updateWorkspaceExpiryRequestSaga, updatePlanRequestSaga, AddRequestSaga, GetRequestSaga  } from './MeeplAdminSaga';
import * as ActionTypes from './ActionTypes';

export default function* MeeplAdminWatcher() {
    yield takeLatest(ActionTypes.MEEPL_ADMIN_GET_REQUEST, MeeplAdminSaga);
    yield takeLatest(ActionTypes.CREATED_SCHEMAS_GET_REQUEST, GetCreatedSchemasSaga);
    yield takeLatest(ActionTypes.ENABLE_WORKSPACE_REQUEST, EnableWorkspaceSaga);
    yield takeLatest(ActionTypes.DISABLE_WORKSPACE_REQUEST, DisableWorkspaceSaga)
    yield takeLatest(ActionTypes.USER_ARCHIVE_REQUEST, UserArchiveSaga);
    yield takeLatest(ActionTypes.MEEPL_ADMIN_GET_USER_REQUEST, GetMeeplAdminUserSaga);
    yield takeLatest(ActionTypes.MEEPL_ADMIN_ADD_USER_REQUEST, AddMeeplAdminUserSaga);
    yield takeLatest(ActionTypes.MEEPL_ADMIN_UPDATE_USER_REQUEST, UpdateMeeplAdminUserSaga);
    yield takeLatest(ActionTypes.GET_WORKSPACE_EXPIRY_REQUEST, getWorkspaceExpiryRequestSaga);
    yield takeLatest(ActionTypes.ADMIN_REQUEST, requestAdminSaga);
    yield takeLatest(ActionTypes.UPDATE_WORKSPACE_EXPIRE_REQUEST, updateWorkspaceExpiryRequestSaga);
    yield takeLatest(ActionTypes.UPDATE_PLAN_REQUEST, updatePlanRequestSaga);
    yield takeLatest(ActionTypes.ADD_REQUEST, AddRequestSaga);
    yield takeLatest(ActionTypes.GET_REQUEST, GetRequestSaga);

    
}

