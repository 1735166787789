import { put, call } from 'redux-saga/effects';
import { GetTourRequest, UpdateTourRequest } from '../../app/Components/SignIn/helper/SigninHelper';
import * as ActionTypes from './ActionTypes';


export function* GetTourStatusSaga() {
    const response = yield call(GetTourRequest)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.START_TOUR_SUCCESS, payload: response?.data?.data?.[0]?.tour_completed })
    } else {
        yield put({ type: ActionTypes.START_TOUR_ERROR, payload: response?.message?.error?.[0]?.message })
    }
}

export function* UpdateTourStatusSaga() {
    const response = yield call(UpdateTourRequest)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.END_TOUR_SUCCESS, payload: response?.data?.data?.[0]?.tour_completed })
    } else {
        yield put({ type: ActionTypes.END_TOUR_ERROR, payload: response?.message?.error?.[0]?.message })
    }
}