import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    isLoading: false,
    role_data: [],
    userRoleData: []
};

const RoleReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ROLE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                role_data: []
            };
        case ActionTypes.ROLE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success_message: action.payload.message,
                error: action.payload.hasError,
                role_data: action.payload?.data?.data
            };
        case ActionTypes.ROLE_USER_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                userRoleData: []
            };
        case ActionTypes.ROLE_USER_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success_message: action.payload.message,
                error: action.payload.hasError,
                userRoleData: action.payload?.data?.data
            };
        default:
            return state;
    }
}

export default RoleReducer;