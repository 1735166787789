import * as ActionTypes from '../ActionTypes';

export const GetSalaryStructureAction = () => {
    return {
        type: ActionTypes.SALARY_STRUCTURE_GET_REQUEST,
    }
}

export const AddSalaryStructureAction = (params) => {
    return {
        type: ActionTypes.SALARY_STRUCTURE_ADD_REQUEST,
        params
    }
}

export const UpdateSalaryStructureAction = (params) => {
    return {
        type: ActionTypes.SALARY_STRUCTURE_UPDATE_REQUEST,
        params
    }
}

export const DeleteSalaryStructureAction = (params) => {
    return {
        type: ActionTypes.SALARY_STRUCTURE_DELETE_REQUEST,
        params
    }
}