import * as ActionTypes from "../ActionTypes";

const initialState = {
  isLoading: true,
  Loading: true,
  success: false,
  error: false,
  AdminTrackStatusdata: [],
  CreatedSchemasdata: [],
  ExtendWorkspaceRequestdata: [],
  Statusdata:[]
};

export const WorkspaceReducer = function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CREATED_SCHEMAS_GET_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
        CreatedSchemasdata: [],
      };
    case ActionTypes.CREATED_SCHEMAS_GET_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        CreatedSchemasdata: action.payload?.data?.data,
      };
    case ActionTypes.CREATED_SCHEMAS_GET_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
        CreatedSchemasdata: [],
      };
    case ActionTypes.ENABLE_WORKSPACE_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
      };
    case ActionTypes.ENABLE_WORKSPACE_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        CreatedSchemasdata: action.payload?.data?.data,
      };
    case ActionTypes.ENABLE_WORKSPACE_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
      };
    case ActionTypes.DISABLE_WORKSPACE_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
      };
    case ActionTypes.DISABLE_WORKSPACE_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        CreatedSchemasdata: action.payload?.data?.data,
      };
    case ActionTypes.DISABLE_WORKSPACE_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
      };
    case ActionTypes.USER_ARCHIVE_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
        AdminTrackStatusdata: [],
      };
    case ActionTypes.USER_ARCHIVE_GET_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        AdminTrackStatusdata: action.payload?.data?.data,
      };
    case ActionTypes.USER_ARCHIVE_GET_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
        AdminTrackStatusdata: [],
      };
    case ActionTypes.GET_WORKSPACE_EXPIRY_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
        ExtendWorkspaceRequestdata: [],
      };
    case ActionTypes.GET_WORKSPACE_EXPIRY_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        ExtendWorkspaceRequestdata: action.payload?.data?.data,
      };
    case ActionTypes.GET_WORKSPACE_EXPIRY_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
        ExtendWorkspaceRequestdata: [],
      };

    case ActionTypes.ADMIN_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
      };
    case ActionTypes.ADMIN_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        ExtendWorkspaceRequestdata: action.payload?.data?.data,
      };
    case ActionTypes.ADMIN_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
      };
    case ActionTypes.UPDATE_WORKSPACE_EXPIRE_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
      };
    case ActionTypes.UPDATE_WORKSPACE_EXPIRE_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        ExtendWorkspaceRequestdata: action.payload?.data?.data,
      };
    case ActionTypes.UPDATE_WORKSPACE_EXPIRE_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
      };
    case ActionTypes.UPDATE_PLAN_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
        CreatedSchemasdata: [],
      };
    case ActionTypes.UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        CreatedSchemasdata: action.payload?.data?.data,
      };
    case ActionTypes.UPDATE_PLAN_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
        CreatedSchemasdata: [],
      };

      case ActionTypes.ADD_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
        Statusdata: [],
      };
    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        Statusdata: action.payload?.data?.data,
      };
    case ActionTypes.ADD_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
        Statusdata: [],
      };
      case ActionTypes.GET_REQUEST:
        return {
          ...state,
          Loading: true,
          success: false,
          error: false,
          Statusdata: [],
        };
      case ActionTypes.GET_SUCCESS:
        return {
          ...state,
          Loading: false,
          success: true,
          error: false,
          Statusdata: action.payload?.data?.data,
        };
      case ActionTypes.GET_ERROR:
        return {
          ...state,
          Loading: false,
          success: false,
          error: true,
          Statusdata: [],
        };
    default:
      return state;
  }
};

export default WorkspaceReducer;
