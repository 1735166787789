import React from 'react';
import SalaryStructure from './SalaryStructure';

const FinanceLandingPage = ({ accordian }) => {

    return (
        <div style={{ padding: "10px 20px" }}>
            <SalaryStructure />
        </div>
    );
}

export default FinanceLandingPage;
