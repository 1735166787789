import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";

export const GetCountryHelper = async () => {
    return await axiosInstance(`/ancillary/getCountries`, {
        method: "GET",
        baseURL: HRMS_UTILITY_API_URL,
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })

}