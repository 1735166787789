import { Box, Button, IconButton, Tooltip } from '@mui/material';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Popup from "../../../Base/views/Popup"
import { useState } from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import "../../css/styles.css"
import WeekOffPopup from './WeekOffPopup';

const WeekOff = () => {

    const dispatch = useDispatch();
    const { week_off_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);
    const [editedRecord, setEditedRecord] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);


    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };
    const handleAdd = () => {
        setEditedRecord()
        setOpenPopup(true);
    };
    const addMode = (resetForm) => {
        resetForm();
        setEditedRecord(null);
        setOpenPopup(false);
    };

    const addButton = <Button onClick={handleAdd} variant="contained" className="mpl-primary-btn" startIcon={<AddIcon />} >
        Add
    </Button>

    return (
        <Box>
            <MaterialTable
                columns={[
                    { title: "Day", field: "day_name", cellStyle: { padding: "10px 10px 10px 10px" } },
                    {
                        title: "Full Day", field: "full_day_off",headerStyle:{ textAlign:"center"}, cellStyle: { padding: "10px 10px 10px 13px", textAlign:"center" },
                        render: rowData => {
                            return rowData.full_day_off ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                        }
                    },
                    {
                        title: "Half Day", field: "half_day_off",headerStyle:{ textAlign:"center"}, cellStyle: { padding: "10px 10px 10px 13px", textAlign:"center" },
                        render: rowData => {
                            return rowData.half_day_off ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                        }
                    },
                ]}
                data={week_off_data}
                options={{
                    maxBodyHeight: 385,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 10px 10px 10px", fontWeight: "bold" },
                    sorting: false,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: true,
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: false,
                }}
                onSelectionChange={(rows) => { return (rows.filter(row => row?.status === "Submitted")) }}
                actions={[
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                        tooltip: "Edit",
                        onClick: (_event, rowData) => { handleEdit(rowData) },
                    },
                ]}
                title={addButton}
            />
            <Popup
                title={editedRecord ? "Edit" : "Add"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <WeekOffPopup editedRecord={editedRecord} addMode={addMode} setOpenPopup={setOpenPopup} />
            </Popup>
        </Box>
    );
}

export default WeekOff;
