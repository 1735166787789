/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory,Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { subscribeEmailHelper } from "../../helper/SubscribeHelper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Footer() {
  const initialValues = {
    emailid: "",
  };

  const [data, setData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...data, [e.target.name]: e.target.value });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("emailid" in fieldValues) {
      temp.emailid =
        fieldValues.emailid === ""
          ? "Email ID is required"
          : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              fieldValues.emailid
            )
          ? ""
          : "Invalid Email.";
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate(data)) {
      subscribeEmailHelper(data)
        .then((res) => {
          if (res?.message?.statuscode === 200) setData(initialValues);
          // if (res?.message?.statuscode === 400) setData(initialValues);
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  }, []);
  const Foot_er = () => {
    history.push("/");
  };
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="section">
        <div className="container">
          <div className="footer-wrapper">
            <div className="footer-grid">
              <div
                id="w-node-b999d761-3f35-127d-3273-8f1e844d9164-844d9160"
                className="footer-brand"
              >
                <h4 className="title">
                  Subscribe to the <br />
                  newsletter
                </h4>
                <div className="form-block footer-form w-form">
                  <form
                    id="wf-form-Footer-Form"
                    name="wf-form-Footer-Form"
                    className="form"
                    aria-label="Footer Form"
                  >
                    <TextField
                      value={data?.emailid}
                      onChange={handleChange}
                      className="text-field w-input"
                      maxLength="256"
                      name="emailid"
                      placeholder="Your email"
                      id="emailid"
                      {...(errors.emailid && {
                        error: true,
                        helperText: errors.emailid,
                      })}
                    />
                    <input
                      onClick={handleSubmit}
                      type="submit"
                      className="button w-button footer-btn"
                    />
                  </form>
                  <div
                    className="success-message-footer w-form-done"
                    tabIndex="-1"
                    role="region"
                    aria-label="Footer Form success"
                  >
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div
                    className="w-form-fail"
                    tabIndex="-1"
                    role="region"
                    aria-label="Footer Form failure"
                  >
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-b999d761-3f35-127d-3273-8f1e844d9173-844d9160"
                className="footer-block"
              >
                <div className="title-small">Menu</div>
                <a onClick={Foot_er} href="#Product" className="footer-link">
                  Product
                </a>
                <a onClick={Foot_er} href="#Solution" className="footer-link">
                  Whyus
                </a>
                <a onClick={Foot_er} href="#Feature" className="footer-link">
                  Features
                </a>
                {/* <a href="/MeetOurTeam" className="footer-link">
                  Meet our Team
                </a> */}
                <a onClick={Foot_er} href="#Pricing" className="footer-link">
                  Pricing
                </a>
              </div>
              <div
                id="w-node-b999d761-3f35-127d-3273-8f1e844d9185-844d9160"
                className="footer-block"
              >
                <div className="title-small">Social Media</div>
                <a href="#" className="footer-link">
                  Instagram
                </a>
                <a href="#" className="footer-link">
                  Linkedin
                </a>
                <a href="#" className="footer-link">
                  Facebook
                </a>
                {/* <a onClick={Foot_er} href="#FAQ" className="footer-link">
                  Blog
                </a> */}
              </div>
              <div
                id="w-node-b999d761-3f35-127d-3273-8f1e844d9185-844d9160"
                className="footer-block"
              >
                <div className="title-small">Legal</div>
                {/* <a href="/Terms&Conditions" className="footer-link">
                  Terms & Conditions
                </a> */}
                {/* <a href="/Privacy&Policy" className="footer-link">
                  Privacy & Policy
                </a> */}
                <a onClick={Foot_er} href="#FAQ" className="footer-link">
                  FAQ
                </a>
                <Link to="/ContactUs" className="footer-link">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <div className="container">
            <div className="copyright-text-holder">
              <div className="copyright-text">
                Crafted with ❤️ by <a href="#">Talents Tech Pvt Ltd.</a>,
                Powered by <a href="#">Meepl</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
