import { combineReducers } from "redux";
import { AdminUserReducer } from "./AdminUser/Reducer";
import { WorkspaceReducer } from "./Workspace/Reducer";
import MeeplAdminUserReducer from "./Users/Reducer"

const MeeplAdminReducer = combineReducers({
    AdminUserReducer,
    WorkspaceReducer,
    MeeplAdminUserReducer
});

export default MeeplAdminReducer;