import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { createStyles, makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import { PowerUserDisabledAction } from "../../../../../../../store/RoleAccess/Permission/Action";
import history from "../../../../../../../history";
import { AtsJobPostModeAction } from "../../../../../../../store/ATS/JobPost/Action";
import { AddProfileScoreHelper } from "../../../../helper/AtsProfileScoreHelper";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  borderRadius: "8px",
  fontWeight: 700,
  "& input:hover": {
    borderRadius: "6px",
    paddingLeft: "8px",
    border: "1px solid black",
    borderColor: theme?.palette?.primary?.main,
    borderWidth: "2px",
    transition:
      "padding-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& input:focus": {
    borderRadius: "6px",
    paddingLeft: "8px",
    border: "1px solid black",
    borderColor: theme?.palette?.primary?.main,
    borderWidth: "2px",
    transition:
      "padding-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}));
const useStyles = makeStyles((theme) =>
  createStyles({
    ColumnHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    DroppableStyles: {
      marginRight: "10px",
      marginLeft: "10px",
      padding: "10px",
      borderRadius: "8px",
      background: "#e6d9fb7a",
      border:
        theme?.palette?.mode === "dark"
          ? "1px solid #ffffff73"
          : "1px solid #c7c3c352",
    },
  })
);
const DraggableElement = ({
  currentMode,
  getKanbanData,
  handleDeleteStage,
  handleProposeCandidate,
  prefix,
  elements,
  currentData,
  setCurrentData,
  setAnchor,
  handleChange,
  handleStoreStage,
  JobData,
  stageData,
  handleNewStage,
  index,
  setIsVerified,
  stageName,
  offerdata,
  setElements,
}) => {
  const classes = useStyles();
  const defaultStages = [
    "Offer Accepted",
    "Joined",
    "Rejected",
    "Offer Released",
    "Offer Rejected",
    "Offer Preparation",
    "Profile Shortlisted",
  ];
  const [showMenu, setShowMenu] = useState(false);
  const [addStage, setAddStage] = useState({});
  const openMoreVertIcon = Boolean(showMenu);
  const dispatch = useDispatch();
  const { featuresData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer
  );

  useEffect(() => {
    var rejId = stageData?.findIndex((obj) => obj?.name === "Rejected");
    rejId && setAddStage(stageData[rejId - 1]);
  }, [stageData]);

  const handleProposeClick = (id) => {
    handleProposeCandidate(id);
    setShowMenu(false);
  };

  const handleDeleteClick = (stages_id) => {
    setShowMenu(false);
    handleDeleteStage(stages_id);
  };

  const handleDisabled = (permission) => {
    let value = featuresData?.find((item) => item?.permission === permission);
    return value ? true : false;
  };

  const suggestCandidate = (value) => {
    dispatch(AtsJobPostModeAction({ currentJobData: value }));
    AddProfileScoreHelper({ ats_jobpost_id: value?.ats_jobpost_id }).then(
      (res) => {
        history.push(`/ats/suggest-candidate/${btoa(value?.ats_jobpost_id)}`);
      }
    );
  };

  return (
    <>
      <div className={classes?.DroppableStyles}>
        <div className={classes.ColumnHeader}>
          <div sx={{ ml: "0px" }}>
            {currentMode !== "archive" && !defaultStages.includes(stageName) ? (
              <StyledInputBase
                autoFocus={prefix?.focus}
                value={prefix?.name}
                onBlur={(e) => handleStoreStage(e, prefix?.ats_job_stages_id)}
                onChange={(e) => handleChange(e, prefix?.ats_job_stages_id)}
              />
            ) : (
              <Typography sx={{ color: "black", fontWeight: "600" }}>
                {prefix?.name}
              </Typography>
            )}
          </div>
          <div className={classes.ColumnHeader} style={{ mt: 2 }}>
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: "linear-gradient(to left, #facf39, #fbdb6b)",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Optional shadow
                mt: -0.3,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "15px", fontWeight: 600, color: "#000" }}
              >
                {elements?.length}
              </Typography>
            </Box>
            {!defaultStages.includes(stageName) &&
              stageName !== addStage?.name && (
                <IconButton
                  id="basic-button"
                  sx={{ padding: "4px" }}
                  disabled={currentMode === "archive"}
                  onClick={(e) => handleDeleteClick(prefix?.ats_job_stages_id)}
                >
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              )}
            {addStage && stageName === addStage?.name && (
              <IconButton
                id="basic-button"
                sx={{ padding: "4px" }}
                disabled={currentMode === "archive"}
                onClick={(e) => setShowMenu(e.currentTarget)}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </div>
        <Box sx={{ maxHeight: "67vh", minHeight: "67vh", overflowY: "auto" }}>
          <Droppable droppableId={`${prefix?.ats_job_stages_id}`}>
            {(provided) => (
              <>
                {prefix?.sort_key === "1" && (
                  <Card
                    sx={{
                      border: "2px dotted #cbcbcb",
                      background: "#f9f9f991",
                      boxShadow: 3,
                      cursor: "pointer",
                      mb: 1,
                    }}
                    onClick={() => {
                      handleDisabled("job_post_kanban_propose_candidate")
                        ? dispatch(PowerUserDisabledAction())
                        : currentMode !== "archive" &&
                          suggestCandidate(JobData);
                    }}
                  >
                    <CardContent sx={{ textAlign: "center", p: 0 }}>
                      <IconButton color="primary">
                        <AddIcon />
                      </IconButton>
                      <Typography>Propose candidate by</Typography>
                      <Typography>MEEPL's AI Suggestion.</Typography>
                    </CardContent>
                  </Card>
                )}
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    minWidth: 300,
                    minHeight: "55vh",
                  }}
                >
                  {elements?.map((item, index) => (
                    <ListItem
                      currentMode={currentMode}
                      handleDisabled={handleDisabled}
                      setIsVerified={setIsVerified}
                      stageData={stageData}
                      JobData={JobData}
                      getKanbanData={getKanbanData}
                      currentData={currentData}
                      setCurrentData={setCurrentData}
                      setAnchor={setAnchor}
                      key={item.candidate_job_id}
                      item={item}
                      index={index}
                      offerdata={offerdata}
                      setElements={setElements}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
        </Box>
        <Menu
          id="long-menu"
          anchorEl={showMenu}
          open={openMoreVertIcon}
          onClose={() => setShowMenu(false)}
        >
          {addStage && stageName === addStage?.name && (
            <>
              <MenuItem
                onClick={() => {
                  handleNewStage(index, prefix?.sort_key);
                  setShowMenu(false);
                }}
              >
                <ListItemIcon>
                  <AddIcon sx={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText>Add New Stage</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => handleDeleteClick(prefix?.ats_job_stages_id)}
              >
                <ListItemIcon>
                  <DeleteForeverIcon sx={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText>Delete Stage</ListItemText>
              </MenuItem>
            </>
          )}
        </Menu>
      </div>
    </>
  );
};
export default DraggableElement;
