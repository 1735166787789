import { Box, Fab, FormControl, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';

const AtsContact = ({ contactData, setContactData, contactValues, errors, setErrors, handleContactDelete, idField, loading }) => {
    const handleInputChange = (event, index) => {
        let newArr = [...contactData];
        let item = newArr[index];
        item = { ...item, [event.target.name]: event.target.value };
        newArr[index] = item;
        setContactData(newArr);
    }

    const hanldeAddStore = () => {
        let objDetail = JSON.parse(JSON.stringify(contactValues));
        setContactData(contactData.concat(JSON.parse(JSON.stringify([objDetail]))));
    }

    const onRemoveContact = (e, index, id) => {
        const list = [...contactData];
        list.splice(index, 1);
        setContactData([...list]);
        const errorList = [...errors];
        errorList.splice(index, 1);
        setErrors([...errorList]);
        id && handleContactDelete(id);
    }


    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", ml: 2, mr: 2 }}>
                <Typography variant='h6'>Additional Contact</Typography>
                <Box display="flex" flexDirection="row-reverse">
                    <Box mb={1}>
                        <LoadingButton
                            loading={loading}
                            onClick={hanldeAddStore}
                            variant="contained"
                            size="medium"
                            startIcon={<AddIcon />}
                            className='mpl-primary-btn'>
                            Add Contact
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
            {
                contactData?.map((res, id) => {
                    return (
                        <div key={id}>
                            {
                                res?.status === "Active" &&
                                <>
                                    <Grid container>
                                        <Grid key="first_name" item xs={12} md={2.8} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    required
                                                    size='small'
                                                    onChange={(e) => handleInputChange(e, id)}
                                                    name="first_name"
                                                    label="First Name"
                                                    fullWidth
                                                    value={res?.first_name || ""}
                                                    {...(errors[id] &&
                                                        errors[id].first_name && {
                                                        error: true,
                                                        helperText: errors[id].first_name,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid key="last_name" item xs={12} md={2.8} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    size='small'
                                                    onChange={(e) => handleInputChange(e, id)}
                                                    name="last_name"
                                                    label="Last Name"
                                                    fullWidth
                                                    value={res?.last_name || ""}
                                                    {...(errors[id] &&
                                                        errors[id].last_name && {
                                                        error: true,
                                                        helperText: errors[id].last_name,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid key="email_id" item xs={12} md={2.8} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    required
                                                    size='small'
                                                    onChange={(e) => handleInputChange(e, id)}
                                                    name="email_id"
                                                    label="E-mail"
                                                    fullWidth
                                                    value={res?.email_id || ""}
                                                    {...(errors[id] &&
                                                        errors[id].email_id && {
                                                        error: true,
                                                        helperText: errors[id].email_id,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid key="mobile_number" item xs={12} md={2.8} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    required
                                                    size='small'
                                                    type="number"
                                                    onChange={(e) => handleInputChange(e, id)}
                                                    name="mobile_number"
                                                    label="Mobile Number"
                                                    fullWidth
                                                    value={res?.mobile_number || ""}
                                                    {...(errors[id] &&
                                                        errors[id].mobile_number && {
                                                        error: true,
                                                        helperText: errors[id].mobile_number,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid key="delete" item xs={12} md={0.8} sx={{ marginTop: "1rem", paddingRight: "10px", paddingLeft: "10px" }}>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1, pt: 0 }}>
                                                <Fab
                                                    id={res.id}
                                                    onClick={(e) => {
                                                        onRemoveContact(e, id, res?.[idField]);
                                                    }}
                                                    size="small"
                                                    color='error'
                                                    aria-label="delete"
                                                >
                                                    <DeleteIcon fontSize='small' />
                                                </Fab>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </div>
                    )
                })
            }
        </Box>
    );
}

export default AtsContact;
