import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetLeaveYearTypeHelper = async (params) => {
  var data = getUserData();
  return await axiosInstance(`/ancillary/lms/getLeaveYearType`, {
    method: "POST",
    baseURL: HRMS_LMS_API_URL,
    data: {
      domain_name: data.domain,
      year_type: params?.year_type || null,
    },
  })
    .then((res) => {
      toast.dismiss();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const UpdateLeaveYearTypeHelper = async (params) => {
  var data = getUserData();
  const status = params?.start_date_change && params?.year_type === "financial_year" ? false : null;
  toast.info("Saving...");
  return await axiosInstance(`/ancillary/lms/updateLeaveYearType`, {
    method: "PUT",
    baseURL: HRMS_LMS_API_URL,
    data: {
      domain_name: data?.domain,
      user_empid: data?.emp_users_pk,
      year_type: params?.year_type,
      start_date: params?.start_date,
      end_date: params?.end_date,
      carry_over_days: params?.carry_over_days,
      status:status
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Updated successfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const ProceedLeaveHelper = async (params) => {
  var data = getUserData();
  toast.info("Saving...");
  return await axiosInstance(`/ancillary/lms/proceedLeave`, {
    method: "POST",
    baseURL: HRMS_LMS_API_URL,
    data: {
      domain_name: data?.domain,
      user_empid: data?.emp_users_pk
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Proceed successfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
