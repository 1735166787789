import * as ActionTypes from './ActionTypes';

export const PlansAction = (params) => {
    return {
        type: ActionTypes.PLANS_GET_REQUEST,
        params
    }
}



