import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";
import {
  Chip,
  Box,
  Grid,
  Typography,
  Divider,
  Tooltip,
  Skeleton,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { GetAtsJobPostByIdHelper } from "../../helper/JobPostHelper";
import { useParams } from "react-router-dom";
import AtsCareerPortalCandidateForm from "./AtsCareerPortalCandidate";
import NotFound from "../../../Base/views/NotFound";
import positions_yet_to_close from "../../images/positions_yet_to_close.png";
import { GetCompanyLogoHelper } from "../../../Ancillary/CompanyDetails/helper/CompanyDetailsHelper";
import { s3Client } from "../../../Base/helper/baseFunctions";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";

const CareePortalJob = () => {
  const [jobdata, setJobData] = useState([]);
  const { domain, jobid } = useParams();
  const decDomain = atob(domain);
  const domainname = decDomain?.split("=")?.[1];
  const decjobId = atob(jobid);
  const [view, setView] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [notfound, setNotfound] = useState(false);

  useEffect(() => {
    setisLoading(true);
    GetAtsJobPostByIdHelper({
      domain: domainname,
      ats_jobpost_id: `{${decjobId}}`,
    }).then((res) => {
      if (res.hasError === true) {
        setisLoading(true);
        setNotfound(true);
        setView("");
      } else {
        setNotfound(false);
        setisLoading(false);
        setView("View");
        setJobData(res.data.data);
      }
    });
    GetCompanyLogoHelper({ domain: domainname }).then((res) => {
      res?.data?.data?.[0]?.companylogo && getImageSignedUrl(res?.data?.data?.[0]?.companylogo);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [domainname, decjobId]);

  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
    }
  };
  const HandleClick = () => {
    setView("Apply");
  };
  return (
    <>
      {notfound === true && <NotFound />}
      {view !== "Apply" && notfound === false ? (
        <UnauthorizedBase imageUrl={imageUrl} companyName = {jobdata?.[0]?.company_full_name}>
          {isLoading === true ? (
            <>
              <Box
                sx={{
                  marginTop:"16px",
                  p: 2,
                  display: "flex",
                  flex: "1",
                  justifyContent: "space-around",
                }}
              >
                <Grid container display={"flex"}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4">
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={510}
                        height={60}
                      />{" "}
                    </Typography>
                    <Divider
                      sx={{ borderStyle: "solid" }}
                      variant="fullWidth"
                    />
                  </Grid>
                  <Grid container item xs={12} sm={10} lg={10} mb>
                    <>
                      <Grid item xs={12} md={12} marginTop={2}>
                        <Box sx={{ display: "flex", mt: 1, mb: 1 }}>
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            variant="rectangular"
                          />
                        </Box>
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          sm={10}
                          lg={10}
                          spacing={2}
                          mb
                        >
                          {Array(12)
                            .fill()
                            .map((d, i) => (
                              <Grid
                                item
                                xs={6}
                                sx={{ alignItems: "center", textAlign: "left" }}
                              >
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  width={410}
                                  height={60}
                                />
                              </Grid>
                            ))}
                          <Grid item xs={12} md={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                width={100}
                                height={40}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} sm={2} lg={2}>
                          <img
                            src="https://demos.themeselection.com/materio-mui-react-nextjs-admin-template/demo-1/images/cards/pose_m18.png"
                            alt="Jobs"
                          ></img>
                        </Grid>
                      </Box>
                    </>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            view === "View" && (
              <>
                <Box
                  sx={{
                    marginTop:"16px",
                    padding:"0px 16px 16px 16px",
                    display: "flex",
                    flex: "1",
                    justifyContent: "space-around",
                    background: "#ffffff",
                  }}
                >
                  <Grid container display={"flex"}>
                  
                    <Grid  item xs={12} md={12}>
                      <Typography sx={{ display: "flex", justifyContent: "center" }} variant="h4" color="primary">
                        Job Details
                      </Typography>
                      <Divider
                        sx={{ borderStyle: "solid" }}
                        variant="fullWidth"
                      />
                    </Grid>
                    <Grid container item xs={12} md={12} sm={12} lg={12} mb>
                      {jobdata?.[0] && (
                        <>
                          <Grid item xs={12} md={12} marginTop={2}>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="h5">
                                {" "}
                                {jobdata?.[0]?.title} - {jobdata?.[0]?.job_id}{" "}
                              </Typography>
                              <Box sx={{ display: "flex", mt: 1, mb: 1 }}>
                                <Box sx={{ display: "flex", mr: 4 }}>
                                  <Box sx={{ display: "flex", mr: 4 }}>
                                    <Tooltip
                                      title="Location"
                                      placement="bottom"
                                    >
                                      <Box sx={{ display: "flex" }}>
                                        <Typography>
                                          <LocationOnRoundedIcon
                                            pr={1}
                                            color="primary"
                                            sx={{ mr: 1, mb: 0.2 }}
                                          />
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#000000",
                                          }}
                                        >
                                          {jobdata?.[0]?.job_location}{" "}
                                        </Typography>
                                      </Box>
                                    </Tooltip>
                                  </Box>
                                  <Box>
                                    <Tooltip
                                      title="Vacancies"
                                      placement="bottom"
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#000000",
                                        }}
                                      >
                                        <Chip
                                          label={jobdata?.[0]?.vaccancies}
                                          variant="filled"
                                          color="primary"
                                          style={{ color: "white" }}
                                          sx={{ mr: 1, mt: -0.2 }}
                                          size="small"
                                        />{" "}
                                        Vacancies{" "}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                </Box>
                                <Box sx={{ ml: 1, display: "flex" }}>
                                  <Tooltip
                                    title="Applications Recieved"
                                    placement="bottom"
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        color: "#000000",
                                      }}
                                    >
                                      <Chip
                                        label={
                                          jobdata?.[0]?.received_applicants
                                        }
                                        variant="filled"
                                        color="primary"
                                        style={{ color: "white" }}
                                        sx={{ mr: 1, mt: -0.2 }}
                                        size="small"
                                      />{" "}
                                      Applications Recieved{" "}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Grid>

                            <Divider
                              sx={{ borderStyle: "solid" }}
                              variant="fullWidth"
                            />
                            {jobdata?.[0]?.description && (
                              <>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                  mt={0.3}
                                >
                                  Description
                                </Typography>
                                <Typography>
                                  <div dangerouslySetInnerHTML={{ __html: jobdata?.[0]?.description }}></div>
                                </Typography>
                              </>
                            )}
                          </Grid>
                          <Box sx={{ display: "flex", mt: 1 }}>
                            <Grid
                              container
                              item
                              xs={10}
                              sm={10}
                              lg={10}
                              spacing={2}
                            >
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  {" "}
                                  Company
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontSize: 17,
                                    textAlign: "left",
                                  }}
                                >
                                  {jobdata?.[0]?.company || "Internal"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Consultancy
                                </Typography>
                                {jobdata?.[0]?.consultancy ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    {jobdata?.[0]?.consultancy}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Job Position
                                </Typography>
                                {jobdata?.[0]?.title ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    {jobdata?.[0]?.title}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Job Location
                                </Typography>
                                {jobdata?.[0]?.job_location ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    {jobdata?.[0]?.job_location}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Preferred Gender
                                </Typography>
                                {jobdata?.[0]?.preferred_gender ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    {jobdata?.[0]?.preferred_gender}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Preferred Qualification
                                </Typography>
                                {jobdata?.[0]?.preferred_qualification ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    {jobdata?.[0]?.preferred_qualification}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Minimum Salary
                                </Typography>
                                {jobdata?.[0]?.min_salary ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    ₹ {jobdata?.[0]?.min_salary}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Maximum Salary
                                </Typography>
                                {jobdata?.[0]?.max_salary ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    ₹ {jobdata?.[0]?.max_salary}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Primary Skill
                                </Typography>
                                {jobdata?.[0]?.primary_skill ? (
                                  jobdata?.[0]?.primary_skill
                                    .split(",")
                                    .map((row) => {
                                      return (
                                        <Chip
                                          label={row}
                                          color="primary"
                                          variant="outlined"
                                          size="small"
                                          sx={{ mr: 2 }}
                                          mt={1}
                                        />
                                      );
                                    })
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Secondary Skill
                                </Typography>
                                {jobdata?.[0]?.secondary_skill ? (
                                  jobdata?.[0]?.secondary_skill
                                    .split(",")
                                    .map((row) => {
                                      return (
                                        <Chip
                                          label={row}
                                          color="primary"
                                          variant="outlined"
                                          size="small"
                                          sx={{ mr: 2 }}
                                          mt={1}
                                        />
                                      );
                                    })
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Minimum Experience Required
                                </Typography>
                                {jobdata?.[0]?.min_exp_required ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    {jobdata?.[0]?.min_exp_required}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  Maximum Experience Required
                                </Typography>
                                {jobdata?.[0]?.max_exp_required ? (
                                  <Typography
                                    sx={{ color: "#000000", fontSize: 17 }}
                                  >
                                    {jobdata?.[0]?.max_exp_required}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{ fontSize: 17, fontStyle: "italic" }}
                                  >
                                    NA
                                  </Typography>
                                )}
                              </Grid>
                              {jobdata?.[0]?.roles_responsibilities && (
                                <Grid item xs={12} md={12}>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        color: "#000000",
                                      }}
                                    >
                                      Roles and Responsibilities
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: jobdata[0].roles_responsibilities }}></div>
                                  </Box>
                                </Grid>
                              )}
                              <Grid item xs={12} md={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    className="mpl-primary-btn"
                                    onClick={HandleClick}
                                    sx={{ mb: 1 }}
                                  >
                                    Apply
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sm={2}
                              md={2}
                              lg={2}
                              sx={{ mb: 10 }}
                            >
                              <img
                                src={positions_yet_to_close}
                                alt="Jobs"
                              ></img>
                            </Grid>
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </>
            )
          )}
        </UnauthorizedBase >
      ) : (
        notfound === false && (
          <AtsCareerPortalCandidateForm jobdata={jobdata} view={view} imageUrl={imageUrl} />
        )
      )}
    </>
  );
};

export default CareePortalJob;
