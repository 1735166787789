import NProgress from "nprogress";
import axiosInstance from "../../../../axiosInstance";
import {
  HRMS_UTILITY_API_URL,
  ADMIN_WORKSPACE,
} from "../../../Constants/baseConstant";
import { toast } from "react-toastify";
import { renderEmail } from "react-html-email";
import { EmailHelper } from "../../Ancillary/Email/helper/EmailHelper";
import BaseEmailTemplate from "../../Base/views/BaseEmailTemplate";
import { ExpirydateUpdateEmailTemplate } from "../views/ExpirydateUpdateEmailTemplate";
import { RequestAdminEmailTemplate } from "../views/RequestAdminEmailTemplate";
import { UpdatePlanEmailTemplate } from "../views/UpdatePlanEmailTemplate";



export const GetCreatedSchemastHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/getCreatedSchemas`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      is_active: params?.is_active
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const EnableWorkspaceHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/enableWorkspace`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      company_id: params?.company_id,
      expiry_date: params?.expiry_date
    },
  })
    .then((res) => {
      NProgress.done();
      toast.success("Workspace Enabled Successfully")
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const DisableWorkspaceHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/disableWorkspace`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      company_id: params?.company_id,
      disable_reason: params?.disable_reason || null
    },
  })
    .then((res) => {
      NProgress.done();
      toast.success("Workspace Disabled Successfully")
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};


export const UserStatusHelper = async (params) => {
  !params?.hide && toast.dismiss();
  !params?.hide && toast.info("Updating...");
  return await axiosInstance(`/meepladmin/updateRequestDemo`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: ADMIN_WORKSPACE,
      update_id: params?.update_id,
      status: params?.status,
      reason: params?.reason,
    },
  })
    .then((res) => {
      !params?.hide && toast.dismiss();
      !params?.hide &&
        (params?.successMessage
          ? toast.success(params?.successMessage)
          : toast.success("Status Updated Successfully"));
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Failed", hasError: true, data: err?.data };
    });
};

export const getWorkspaceExpiryRequestHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/getWorkspaceExpiryRequest`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      pre_req: params?.pre_req
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};


export const requestAdminHelper = async (params) => {
  
  NProgress.start();
  return await axiosInstance(`/meepladmin/requestAdmin`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      company_id: params?.company_id,
      user_id:params?.userid,
      pre_req: params?.pre_req
    },
  })
    .then((res) => {
      NProgress.done();
      let to = res?.data?.data?.cc;
      const formattedDate = new Date(params?.expiry_date).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'});
      let content = renderEmail(<BaseEmailTemplate><RequestAdminEmailTemplate expiry={formattedDate} workspace={params?.domainname} /></BaseEmailTemplate>)
      EmailHelper({ to: to, subject: "Workspace Expiry Extension Request Received", content: content })
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};


export const updateWorkspaceExpiryRequestHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/updateWorkspaceExpiryRequest`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      company_id: params?.company_id,
      expiry_date: params?.expiry_date,
      planid:params?.planid?.planid,
      pre_req: params?.pre_req
    },
  })
    .then((res) => {
      let cc = res?.data?.data?.cc;
      NProgress.done();
      toast.success("Updated Sucessfully");
      const formattedDate = new Date(params?.expiry_date).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'});
      let content = renderEmail(<BaseEmailTemplate><ExpirydateUpdateEmailTemplate name={params?.owner_name} expiry={formattedDate} plan={params?.planname} workspace={params?.domainname} /></BaseEmailTemplate>)
      EmailHelper({ to: params?.email_id, cc: cc, subject: `${params?.domainname} Expiry Extended Successfully`, content: content })
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const updatePlanHelper = async (params) => {
  NProgress.start();
  return await axiosInstance(`/meepladmin/updatePlan`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      is_active: params?.is_active,
      company_id: params?.company_id,
      planid:params?.planid?.planid,
    },
  })
    .then((res) => {
      NProgress.done();
      toast.success("Plan Updated Sucessfully");
      let oldplan = params?.planname;
      let newplan = res?.data?.data?.[0]?.planname;
      if(oldplan !== newplan){
        let content = renderEmail(<BaseEmailTemplate><UpdatePlanEmailTemplate name={params?.owner_name} workspace={params?.domainname} oldplan={oldplan} newplan={newplan}/></BaseEmailTemplate>)
        EmailHelper({ to: params?.email_id, subject: `${params?.domainname} Plan Updated Successfully`, content: content })
      }
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const AddRequestHelper = async (params) => {
  return await axiosInstance(`/meepladmin/addRequest`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      user_id:params?.userid,
      domain_name: params.domainname,
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const GetRequestHelper = async (params) => {
  return await axiosInstance(`/meepladmin/getRequest`, {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      user_id:params?.userid,
      domain_name: params.domainname,
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};