import * as ActionTypes from '../ActionTypes';

const initialLeaveRequestState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
    getEmployee: true,
};

export const LeaveRequestReducer = function (state = initialLeaveRequestState, action) {
    switch (action.type) {
        case ActionTypes.LEAVE_REQUEST_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                leaveRequestData: []
            };
        case ActionTypes.LEAVE_REQUEST_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveRequestData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_REQUEST_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.LEAVE_REQUEST_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                addSuccess: false,
            };
        case ActionTypes.LEAVE_REQUEST_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                addSuccess: true,
                successMessage: action.payload?.message,
                leaveRequestData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_REQUEST_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                addSuccess: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.LEAVE_REQUEST_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.LEAVE_REQUEST_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveRequestData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_REQUEST_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.LEAVE_REQUEST_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.LEAVE_REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveRequestData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_REQUEST_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}