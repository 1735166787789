import { BASE_URL } from "../../../../../Constants/baseConstant"


export const RequestContent = ({ userName, managerName, fromDate, toDate, leaveType, guid }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {managerName}
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    {userName} has requested {leaveType} between&nbsp;<span style={{ fontWeight: "bold" }}>{fromDate}</span>&nbsp; and &nbsp;<span style={{ fontWeight: "bold" }}>{toDate}</span>
                </span>
                <br />
                <br />
                <a href={`${BASE_URL}/signup/`} style={{
                    background: "#20e277",
                    textDecoration: "none !important", fontWeight: 500, marginTop: "35px",
                    color: "#fff", textTransform: "uppercase", fontSize: "14px",
                    padding: "10px 24px", display: "inlineBlock", borderRadius: "50px"
                }}>
                    Approve
                </a>
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)

export const CancelRequestContent = ({ userName, managerName, fromDate, toDate, leaveType }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {managerName}
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    {userName} has requested to Cancel his/her {leaveType} which has applied between&nbsp;<span style={{ fontWeight: "bold" }}>{fromDate}</span>&nbsp; and &nbsp;<span style={{ fontWeight: "bold" }}>{toDate}</span>
                </span>
                <br />
                <br />
                <a href="http://google.com" style={{
                    background: "#20e277",
                    textDecoration: "none !important", fontWeight: 500, marginTop: "35px",
                    color: "#fff", textTransform: "uppercase", fontSize: "14px",
                    padding: "10px 24px", display: "inlineBlock", borderRadius: "50px"
                }}>
                    Cancel
                </a>
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)

export const ApprovedContent = ({ userName, fromDate, toDate, leaveType }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {userName}
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    Your {leaveType} has been &nbsp;<span style={{ fontWeight: "bold" }}>Approved</span> which has applied between&nbsp;<span style={{ fontWeight: "bold" }}>{fromDate}</span>&nbsp; and &nbsp;<span style={{ fontWeight: "bold" }}>{toDate}</span>
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)

export const RejectedContent = ({ userName, fromDate, toDate, leaveType }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {userName}
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    Your {leaveType} has been &nbsp;<span style={{ fontWeight: "bold" }}>Rejected</span> which has applied between&nbsp;<span style={{ fontWeight: "bold" }}>{fromDate}</span>&nbsp; and &nbsp;<span style={{ fontWeight: "bold" }}>{toDate}</span>
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)
export const CancelledContent = ({ userName, fromDate, toDate, leaveType }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {userName}
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    Your {leaveType} has been &nbsp;<span style={{ fontWeight: "bold" }}>Cancelled</span> which has applied between&nbsp;<span style={{ fontWeight: "bold" }}>{fromDate}</span>&nbsp; and &nbsp;<span style={{ fontWeight: "bold" }}>{toDate}</span>
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)
