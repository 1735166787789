import { Paper } from '@mui/material'
import { useState, useEffect } from 'react'
import Base from '../../../Base/views/Base'
import AtsGroupCompany from './AtsGroupCompany'
import AtsGroupCompanyForm from './AtsGroupCompanyForm'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { GetATSModuleFeaturesAction } from '../../../../../store/RoleAccess/ATSModuleFeatures/Action'
import { GetAtsGroupCompanyAction } from '../../../../../store/ATS/GroupCompany/Action'

const AtsCompanyGroupLandingPage = () => {
    const initialValues = {
        name: "",
        address: "",
        contact_person: "",
        mobile_number: "",
        office_number: "",
        country_id: "",
        state_id: "",
        city_id: "",
        zip_code: "",
        industry: "",
        about_company: "",
        website: "",
        primary_owner: "",
        company_lead_id: "",
        business_unit_id: "",
        email_id: "",
        billing_address: "",
        billing_name: "",
        gst_applicable: "",
        gst: "",
        sgst: "9",
        cgst: "",
        igst: "9",
        sac_code: "",
    }
    const dispatch = useDispatch();
    const location = useLocation();
    const defaultValue = location.state?.defaultValue || "";
    const { atsGroupCompanyData } = useSelector(state => state.AtsReducer?.AtsGroupCompanyReducer);
    const [currentMode, setCurrentMode] = useState(defaultValue? defaultValue : "grid")
    const [currentData, setCurrentData] = useState(initialValues)
    const [companyData, setCompanyData] = useState(atsGroupCompanyData)
    useEffect(() => {
        dispatch(GetAtsGroupCompanyAction());
        setCompanyData(atsGroupCompanyData);
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Manage" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <Base>
            <Paper sx={{ borderRadius: "10px", maxHeight: "87vh", minHeight: "87vh", overflowY: "auto" }}>
                {
                    currentMode === "grid" ? <AtsGroupCompany companyData={companyData} setCurrentMode={setCurrentMode} setCurrentData={setCurrentData} currentData={currentData} />
                        : <AtsGroupCompanyForm setAtsCompanyData={setCompanyData} currentData={currentData} setCurrentMode={setCurrentMode} />
                }
            </Paper>
        </Base>
    )
}

export default AtsCompanyGroupLandingPage