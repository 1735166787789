import * as ActionTypes from '../ActionTypes';


export const GetAtsUserAction = (params) => {
    return {
        type: ActionTypes.ATS_USER_GET_REQUEST,
        params
    }
}

export const AddAtsUserAction = (params) => {
    return {
        type: ActionTypes.ATS_USER_ADD_REQUEST,
        params
    }
}
export const UpdateAtsUserAction = (params) => {
    return {
        type: ActionTypes.ATS_USER_UPDATE_REQUEST,
        params
    }
}
