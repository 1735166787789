import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    week_days_options: []
};

const WeekDaysReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.WEEK_DAYS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                week_days_options: []
            };
        case ActionTypes.WEEK_DAYS_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: action.payload.has_error,
                week_days_options: action.payload?.data?.data
            };
       
        default:
            return state;
    }
}

export default WeekDaysReducer;