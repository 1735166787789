import * as ActionTypes from '../ActionTypes';

export const MeeplAdminAction = (params) => {
    return {
        type: ActionTypes.MEEPL_ADMIN_GET_REQUEST,
        params
    }
}



