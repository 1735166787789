import * as ActionTypes from '../ActionTypes';

// Role Access
export const GetRoleAccessAction = () => {
    return {
        type: ActionTypes.ROLE_ACCESS_GET_REQUEST,
    }
}

export const AddRoleAccessAction = (params) => {
    return {
        type: ActionTypes.ROLE_ACCESS_ADD_REQUEST,
        params
    }
}

export const UpdateRoleAccessAction = (params) => {
    return {
        type: ActionTypes.ROLE_ACCESS_UPDATE_REQUEST,
        params
    }
}

export const DeleteRoleAccessAction = (params) => {
    return {
        type: ActionTypes.ROLE_ACCESS_DELETE_REQUEST,
        params
    }
}