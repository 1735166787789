export const UpdatePlanEmailTemplate = ({ name, workspace, oldplan, newplan }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
       <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {name},
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr style={{textAlign:"left"}}>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    We hope this message finds you well. We are writing to inform you that the plan for your workspace has been successfully updated. Please find the related information below.
                </span>
                <br />
                <br />
                    <div style={{ display: "flex", flexDirection: "row",marginBottom:"10px" }}>
                        <span style={{ width:"40%",textAlign: "left",fontWeight:"bold" }}>Workspace Name </span>
                        <span style={{ width: "60%", textAlign: "left",}}> : {workspace}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row",marginBottom:"10px" }}>
                        <span style={{ width:"40%",textAlign: "left",fontWeight:"bold" }}>Old Plan  </span>
                        <span style={{ width: "60%", textAlign: "left",}}> : {oldplan}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ width:"40%",textAlign: "left",fontWeight:"bold" }}>New Plan  </span>
                        <span style={{ width: "60%", textAlign: "left",}}> : {newplan}</span>
                    </div>
                <br />
                <br />
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Thank you for choosing our services. We appreciate your continued partnership.
                </span>
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)