import { combineReducers } from "redux";
import { LeaveTypeReducer } from "./LeaveType/Reducer";
import { LeaveRequestReducer } from "./LeaveRequest/Reducer";
import { LeaveHistoryReducer } from "./LeaveHistory/Reducer"
import { LeaveReportsReducer } from "./LeaveReports/Reducer";
import { LeaveDashboardReducer } from "./LeaveDashboard/Reducer";
import { HolidayCalendarReducer } from "./HolidayCalendar/Reducer";
import { LeaveApprovalReducer } from "./ManageApproval/Reducer";
import { YearlyHolidayCalendarReducer } from "./YearlyHolidayCalendar/Reducer";
import { LeaveReasonReducer } from "./LeaveReason/Reducer";
import { LeaveYearTypeReducer } from "./LeaveYearType/Reducer";
import { MyTimeConfigureReducer } from "./MyTimeConfigure/Reducer";
import { CurrentTimeReducer } from "./MyTime/Reducer";
import { MyTimeSheetReducer } from "./MyTimeSheet/Reducer";
import { TimeSheetApprovalReducer } from "./MyTeam/Reducer";
import { WeekOffConfigureReducer } from "./WeekOff/Reducer";
import { ReportingEmployeeReducer } from "./ReportingEmployees/Reducer";



const LeaveReducer = combineReducers({
    LeaveHistoryReducer,
    LeaveTypeReducer,
    LeaveRequestReducer,
    LeaveReportsReducer,
    LeaveDashboardReducer,
    HolidayCalendarReducer,
    LeaveApprovalReducer,
    YearlyHolidayCalendarReducer,
    LeaveReasonReducer,
    LeaveYearTypeReducer,
    MyTimeConfigureReducer,
    CurrentTimeReducer,
    MyTimeSheetReducer,
    TimeSheetApprovalReducer,
    WeekOffConfigureReducer,
    ReportingEmployeeReducer
});

export default LeaveReducer;