import * as ActionTypes from "../ActionTypes";

const initialState = {
  isLoading: true,
  Loading: true,
  success: false,
  error: false,
  AdminTrackStatusdata: [],
};

export const AdminUserReducer = function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.MEEPL_ADMIN_GET_REQUEST:
      return {
        ...state,
        Loading: true,
        success: false,
        error: false,
        AdminTrackStatusdata: [],
      };
    case ActionTypes.MEEPL_ADMIN_GET_SUCCESS:
      return {
        ...state,
        Loading: false,
        success: true,
        error: false,
        AdminTrackStatusdata: action.payload?.data?.data,
      };
    case ActionTypes.MEEPL_ADMIN_GET_ERROR:
      return {
        ...state,
        Loading: false,
        success: false,
        error: true,
        AdminTrackStatusdata: [],
      };
    default:
      return state;
  }
};

export default AdminUserReducer;
