import * as ActionTypes from '../ActionTypes';

const familyInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: ""
}

export const EmployeeFamilyReducer = function (state = familyInitialState, action) {
    switch (action.type) {
        case ActionTypes.EMPLOYEE_FAMILY_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                familyData: []
            };
        case ActionTypes.EMPLOYEE_FAMILY_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                familyData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_FAMILY_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.EMPLOYEE_FAMILY_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.EMPLOYEE_FAMILY_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                familyData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_FAMILY_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.EMPLOYEE_FAMILY_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.EMPLOYEE_FAMILY_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                familyData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_FAMILY_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.EMPLOYEE_FAMILY_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.EMPLOYEE_FAMILY_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: "Data Deleted Successfully",
                familyData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_FAMILY_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}