import React from 'react';
import { Page, Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import PaySlipItemTable from './PaySlipItemTable';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    // TITLE
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
    },
    reportTitle: {
        color: '#61dafb',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    // Pay Slip No.
    payslipNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    payslipDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    payslipDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 60
    }
});

const PaySlipPdf = ({ payslip }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>Pay Slip</Text>
                </View>
                <View style={styles.payslipNoContainer}>
                    <Text style={styles.label}>Pay Slip No:</Text>
                    <Text style={styles.payslipDate}>{"invoice.PAYSLIP No"}</Text>
                </View >
                <View style={styles.payslipDateContainer}>
                    <Text style={styles.label}>Date: </Text>
                    <Text >{"invoice.date"}</Text>
                </View >
                <PaySlipItemTable payslip={payslip} />
            </Page>
        </Document>
    )
}

export default PaySlipPdf