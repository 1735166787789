import { Backdrop, Box, Button, Checkbox, CircularProgress, Grid, InputBase, Tooltip, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAtsCandidateByEmailAction } from "../../../../../store/ATS/Candidate/Action";
import Popup from "../../../Base/views/Popup";
import { AddExternalCandidateHelper, MergeExternalCandidateHelper } from "../../helper/AtsCandidateHelper";
import styled from '@emotion/styled';
import { DownloadAttachment, getUserData } from "../../../Base/helper/baseFunctions";
import { APP_NAME } from "../../../../Constants/baseConstant";
import { grey } from "@mui/material/colors";

const StyledInputBase = styled(InputBase)(() => ({
    borderRadius: "6px",
    paddingLeft: "8px",
    border: "1px solid black",
    borderColor: "#f4f4f5",
    borderWidth: "2px",
    transition: "padding-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    margin: "1px",
    width: "-webkit-fill-available"
}));


const AtsCandidateValidate = ({ setOpenValidatePopup, currentData, openValidatePopup, getKanbanData, setValue, setCurrentMode, kanban }) => {
    var user = getUserData();
    const useStyles = makeStyles({
        row: {
            display: "flex", flexDirection: "row", margin: "5px 0px"
        },
        label: {
            fontSize: "0.80rem", fontWeight: 400, width: "130px", flexShrink: 0,
            color: "rgb(99, 115, 129)", height: "30px", lineHeight: "30px", margin: "4px 0px 4px 0px"
        },
        value: {
            display: "flex", flexFlow: "row wrap", alignItems: "center"
        }
    });

    const classes = useStyles();
    const dispatch = useDispatch();
    const [candidateData, setCandidateData] = useState({
        ...currentData,
        years_of_experience: currentData?.years_of_experience || null,
        current_ctc: currentData?.current_ctc || null,
        expected_pay: currentData?.expected_pay || null,
        notice_period: currentData?.notice_period || null,
        identity_id: currentData?.identity_id || null,
        designation: currentData?.designation?.name || currentData?.designation || null
    });
    const [checkedData, setCheckedData] = useState({});
    const { atsCandidateByEmailData, isCandidateLoading } = useSelector(state => state?.AtsReducer?.AtsCandidateReducer)
    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(GetAtsCandidateByEmailAction({ email_id: currentData?.email_id, uan_number: currentData?.uan_number }))
        let checkData = {}
        Object.keys(candidateData)?.forEach((res) => {
            let name = candidateData[res]
            checkData = ({ ...checkData, [res]: (name === "" || name === null) ? false : true })
        })
        setCheckedData(checkData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("contact_no" in fieldValues) {
            temp.contact_no =
                fieldValues.contact_no === "" || fieldValues.contact_no === null
                    ? ""
                    : fieldValues?.contact_no.toString() !== "" &&
                        !fieldValues?.contact_no
                            ?.toString()
                            ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
                        ? "Invalid Mobile Number."
                        : "";
        }

        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleSubmitValidate = () => {
        if (validate(candidateData)) {
            AddExternalCandidateHelper({
                ext_candidate_id: currentData?.candidate_id,
                candidate_data: JSON.stringify([{ ...candidateData, status: "Active", contact_no: candidateData?.contact_no || null, dob: candidateData?.dob || null,  consultancy_fk: user?.user_type === "Consultancy" ? user?.consultancy_id : "0", ats_company_fk: candidateData?.ats_company_fk || (user?.user_type === "Company" ? user?.ats_company_id : "0")}]),
                certificate_details: `${JSON.stringify(candidateData?.certificates)}`
            }).then(res => {
                if (res?.data?.statuscode === 200) {
                    if (kanban) {
                        getKanbanData()
                    }
                    setOpenValidatePopup(false)
                    setCurrentMode("grid")
                    setValue(1);
                }
            })
        }
    }

    const handleChange = (e) => {
        if (e.target.value === "") {
            setCheckedData({ ...checkedData, [e.target.name]: false })
            setCandidateData({ ...candidateData, [e.target.name]: e.target.value })
        } else {
            setCheckedData({ ...checkedData, [e.target.name]: true })
            setCandidateData({ ...candidateData, [e.target.name]: e.target.value })
        }
        errors.contact_no && validate({ ...candidateData, [e.target.name]: e.target.value })
    }

    const handleCheckChange = (event) => {
        if (event?.target?.checked) {
            setCheckedData({ ...checkedData, [event?.target?.name]: true })
        } else {
            setCheckedData({ ...checkedData, [event?.target?.name]: false })
        }
    }

    const handleMergeValidate = () => {
        if (validate(candidateData)) {
            let data = {
                candidate_id: atsCandidateByEmailData?.[0]?.candidate_id,
                first_name: checkedData?.first_name === true ? candidateData?.first_name : atsCandidateByEmailData?.[0]?.first_name,
                last_name: checkedData?.last_name === true ? candidateData?.last_name : atsCandidateByEmailData?.[0]?.last_name,
                contact_no: checkedData?.contact_no === true ? candidateData?.contact_no : atsCandidateByEmailData?.[0]?.contact_no,
                email_id: atsCandidateByEmailData?.[0]?.email_id,
                dob: checkedData?.dob === true ? candidateData?.dob : atsCandidateByEmailData?.[0]?.dob,
                years_of_experience: checkedData?.years_of_experience === true ? candidateData?.years_of_experience : atsCandidateByEmailData?.[0]?.years_of_experience,
                current_ctc: checkedData?.current_ctc === true ? candidateData?.current_ctc : atsCandidateByEmailData?.[0]?.current_ctc,
                expected_pay: checkedData?.expected_pay === true ? candidateData?.expected_pay : atsCandidateByEmailData?.[0]?.expected_pay,
                current_employer: checkedData?.current_employer === true ? candidateData?.current_employer : atsCandidateByEmailData?.[0]?.current_employer,
                notice_period: checkedData?.notice_period === true ? candidateData?.notice_period : atsCandidateByEmailData?.[0]?.notice_period,
                gender: checkedData?.gender === true ? candidateData?.gender : atsCandidateByEmailData?.[0]?.gender,
                primary_skill: checkedData?.primary_skill === true ? candidateData?.primary_skill : atsCandidateByEmailData?.[0]?.primary_skill,
                secondary_skill: checkedData?.secondary_skill === true ? candidateData?.secondary_skill : atsCandidateByEmailData?.[0]?.first_name,
                location: checkedData?.first_locationname === true ? candidateData?.location : atsCandidateByEmailData?.[0]?.location,
                uan_number: checkedData?.uan_number === true ? candidateData?.uan_number : atsCandidateByEmailData?.[0]?.uan_number,
                resume: candidateData?.resume,
                identity_id: candidateData?.proof_id || null,
                proof_number: candidateData?.proof_number || null,
                designation: candidateData?.designation || null,
            }
            MergeExternalCandidateHelper({ internal_candidate_id: atsCandidateByEmailData?.[0]?.candidate_id, ext_candidate_id: candidateData?.candidate_id, candidate_data: JSON.stringify([{ ...data, status: "Active" }]), certificate_details: candidateData?.certificates?.length > 1 ? `${JSON.stringify(candidateData?.certificates)}` : null })
                .then(res => {
                    if (res?.data?.statuscode === 200) {
                        setOpenValidatePopup(false);
                        if(kanban){
                            getKanbanData()
                        }
                    }
                })
        }
    }

    return (
        <>

            <Popup
                fullWidth={true}
                maxWidth={atsCandidateByEmailData?.length > 0 ? "md" : "sm"}
                title="Verify Candidate"
                openPopup={openValidatePopup}
                setOpenPopup={setOpenValidatePopup}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isCandidateLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {
                    atsCandidateByEmailData?.length > 0 &&
                    <Typography color="#e5bc1b" variant="h6" sx={{ paddingLeft: "20px", paddingBottom: "20px", }}>
                        There is a verified candidate with same Email-id. Do you want to merge the data?
                    </Typography>
                }
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className="mpl-secondary-btn" sx={{ ":hover": { color: grey[50] } }} size="small" variant='text' onClick={() => setOpenValidatePopup(false)}>Cancel</Button>
                    {
                        atsCandidateByEmailData?.length > 0 ?
                            <Button sx={{
                                ml: 1, color: grey[50],
                                ":hover": { backgroundColor: "#f9c307", color: "#000000" },
                            }} size="small" variant='contained' className='mpl-primary-btn' onClick={handleMergeValidate}>Merge Candidate</Button>
                            : <Button sx={{
                                ml: 1, color: grey[50],
                                ":hover": { backgroundColor: "#f9c307", color: "#000000" },
                            }} size="small" variant='contained' className='mpl-primary-btn' onClick={handleSubmitValidate}> Save & Verify Candidate</Button>
                    }
                </Box>
                <Grid container sx={{ textAlign: "left" }}>
                    {
                        atsCandidateByEmailData?.length > 0 &&
                        <Grid item xs={12} sm={5} md={5}>
                            <Typography variant="h6" sx={{ paddingLeft: "20px" }}>Existing Data</Typography>
                            <div style={{ display: "flex", flexDirection: "column", padding: "10px 20px 10px 20px" }}>
                                <Typography variant="body1" sx={{ fontWeight: "bold", color: "black" }}>Personal Info</Typography>
                                <div className={classes.row}>
                                    <span className={classes.label}>First Name</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.first_name}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Last Name</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.last_name}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Email</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.email_id}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Contact no</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.contact_no}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Date of Birth</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.dob}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Gender</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.gender}</div>
                                </div>
                                <Typography variant="body1" sx={{ fontWeight: "bold", color: "black" }}>Professional Info</Typography>
                                <div className={classes.row}>
                                    <span className={classes.label}>UAN Number</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.uan_number}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Primary Skills</span>
                                    <Tooltip title={atsCandidateByEmailData?.[0]?.primary_skill}>
                                        <div className={classes.value} style={{
                                            width: "50%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}>{atsCandidateByEmailData?.[0]?.primary_skill}</div>
                                    </Tooltip>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Secondary Skills</span>
                                    <Tooltip title={atsCandidateByEmailData?.[0]?.secondary_skill}>
                                        <div className={classes.value} style={{
                                            width: "50%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}>{atsCandidateByEmailData?.[0]?.secondary_skill}</div>
                                    </Tooltip>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Year of Experience</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.years_of_experience}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Current CTC</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.current_ctc}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Expected Pay</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.expected_pay}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Current Employer</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.current_employer}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Notice Period</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.notice_period}</div>
                                </div>
                                <div className={classes.row}>
                                    <span className={classes.label}>Location</span>
                                    <div className={classes.value}>{atsCandidateByEmailData?.[0]?.location}</div>
                                </div>
                            </div>
                        </Grid>
                    }
                    {
                        atsCandidateByEmailData?.length > 0 &&
                        <Grid item md={1} sx={{ textAlign: "left" }}>
                            <Typography variant="h6" >Select to merge</Typography>
                            <div style={{ display: "flex", flexDirection: "column", paddingTop: "5px" }}>
                                {/* <div style={{ marginTop: "5px" }}>&nbsp;</div> */}
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.first_name || false} onChange={(e) => handleCheckChange(e)} name={"first_name"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.last_name || false} onChange={(e) => handleCheckChange(e)} name={"last_name"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={true} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.contact_no || false} onChange={(e) => handleCheckChange(e)} name={"contact_no"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.dob || false} onChange={(e) => handleCheckChange(e)} name={"dob"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.gender || false} onChange={(e) => handleCheckChange(e)} name={"gender"} />
                                </div>
                                <div style={{ marginTop: "5px" }}>&nbsp;</div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.uan_number || false} onChange={(e) => handleCheckChange(e)} name={"uan_number"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.primary_skill || false} onChange={(e) => handleCheckChange(e)} name={"primary_skill"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.secondary_skill || false} onChange={(e) => handleCheckChange(e)} name={"secondary_skill"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.years_of_experience || false} onChange={(e) => handleCheckChange(e)} name={"years_of_experience"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.current_ctc || false} onChange={(e) => handleCheckChange(e)} name={"current_ctc"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.expected_pay || false} onChange={(e) => handleCheckChange(e)} name={"expected_pay"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.current_employer || false} onChange={(e) => handleCheckChange(e)} name={"current_employer"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.notice_period || false} onChange={(e) => handleCheckChange(e)} name={"notice_period"} />
                                </div>
                                <div className={classes.row}>
                                    <Checkbox size='small' checked={checkedData?.location || false} onChange={(e) => handleCheckChange(e)} name={"location"} />
                                </div>
                            </div>
                        </Grid>
                    }
                    <Grid item xs={12} sm={atsCandidateByEmailData?.length > 0 ? 6 : 12}>
                        <Typography variant="h6" sx={{ paddingLeft: "20px" }}>{atsCandidateByEmailData?.length > 0 && "New Data"}</Typography>
                        <div style={{ display: "flex", flexDirection: "column", padding: "10px 20px 10px 20px" }}>
                            <Typography variant="body1" sx={{ fontWeight: "bold", color: "black", display: "flex", justifyContent: "space-between" }}>
                                Personal Info
                                {candidateData?.resume &&
                                    <Box display="flex" alignItems={"center"}>
                                        <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>Resume:&nbsp;</Typography>
                                        <Typography className='file-name' sx={{ color: "#1155cc" }} onClick={() => DownloadAttachment(candidateData?.resume)} style={{ cursor: "pointer" }}>{candidateData?.first_name || " "}_{APP_NAME}.{candidateData?.resume.split(".").at(-1)}</Typography>
                                    </Box>}
                            </Typography>
                            <div className={classes.row}>
                                <span className={classes.label}>First Name</span>
                                <StyledInputBase value={candidateData?.first_name} name="first_name" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Last Name</span>
                                <StyledInputBase value={candidateData?.last_name} name="last_name" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Email</span>
                                <div className={classes.value}>{candidateData?.email_id}</div>
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Contact No</span>
                                <StyledInputBase value={candidateData?.contact_no} name="contact_no" onChange={e => handleChange(e)} />
                            </div>
                            <Typography color='error'>{errors.contact_no}</Typography>
                            <div className={classes.row}>
                                <span className={classes.label}>Date of Birth</span>
                                <StyledInputBase value={candidateData?.dob} name="dob" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Gender</span>
                                <StyledInputBase value={candidateData?.gender} name="gender" onChange={e => handleChange(e)} />
                            </div>
                            <Typography variant="body1" sx={{ fontWeight: "bold", color: "black" }}>Professional Info</Typography>
                            <div className={classes.row}>
                                <span className={classes.label}>UAN Number</span>
                                <StyledInputBase value={candidateData?.uan_number} type="number" name="uan_number" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Primary Skills</span>
                                <StyledInputBase value={candidateData?.primary_skill} name="primary_skill" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Secondary Skills</span>
                                <StyledInputBase value={candidateData?.secondary_skill} name="secondary_skill" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Year of Experience</span>
                                <StyledInputBase value={candidateData?.years_of_experience} type="number" name="years_of_experience" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Current CTC</span>
                                <StyledInputBase value={candidateData?.current_ctc} type="number" name="current_ctc" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Expected Pay</span>
                                <StyledInputBase value={candidateData?.expected_pay} type="number" name="expected_pay" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Current Employer</span>
                                <StyledInputBase value={candidateData?.current_employer} name="current_employer" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Notice Period</span>
                                <StyledInputBase value={candidateData?.notice_period} type="number" name="notice_period" onChange={e => handleChange(e)} />
                            </div>
                            <div className={classes.row}>
                                <span className={classes.label}>Location</span>
                                <StyledInputBase value={candidateData?.location} name="location" onChange={e => handleChange(e)} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Popup >
        </>
    )
}
export default AtsCandidateValidate
