import { takeLatest } from 'redux-saga/effects';
import {
    GetBankSaga,
    GetDesignationSaga,
    AddDesignationSaga,
    UpdateDesignationSaga,
    DeleteDesignationSaga,
    GetJobSaga,
    GetRelationshipSaga,
    GetStudyLevelSaga,
    AddStudyLevelSaga,
    UpdateStudyLevelSaga,
    DeleteStudyLevelSaga,
    GetStudyProgramSaga,
    AddStudyProgramSaga,
    UpdateStudyProgramSaga,
    DeleteStudyProgramSaga,
    GetEmploymentTypeSaga,
    AddEmploymentTypeSaga,
    UpdateEmploymentTypeSaga,
    DeleteEmploymentTypeSaga,
    GetPaygradeSaga,
    GetCostCenterSaga,
    AddCostCenterSaga,
    UpdateCostCenterSaga,
    DeleteCostCenterSaga,
    GetHolidayCalendarCitySaga,
    AddBankSaga,
    UpdateBankSaga,
    DeleteBankSaga,
    GetLeavingReasonSaga,
    AddLeavingReasonSaga,
    UpdateLeavingReasonSaga,
    DeleteLeavingReasonSaga,
    UpdateRelationshipSaga,
    DeleteRelationshipSaga,
    AddRelationshipSaga,
    AddJobSaga,
    UpdateJobSaga,
    DeleteJobSaga,
    GetBusinessUnitSaga,
    AddBusinessUnitSaga,
    UpdateBusinessUnitSaga,
    DeleteBusinessUnitSaga,
    GetSubBusinessUnitSaga,
    AddSubBusinessUnitSaga,
    UpdateSubBusinessUnitSaga,
    DeleteSubBusinessUnitSaga,
    GetCasteSaga,
    AddCasteSaga,
    UpdateCasteSaga,
    DeleteCasteSaga,
    AddPaygradeSaga,
    UpdatePaygradeSaga,
    DeletePaygradeSaga,
    GetWorkLocationSaga,
    AddWorkLocationSaga,
    UpdateWorkLocationSaga,
    DeleteWorkLocationSaga,
    GetCityListSaga,
    GetProofTypeSaga,
    AddProofTypeSaga,
    UpdateProofTypeSaga,
    DeleteProofTypeSaga,
    GetNotificationSaga,
    AddNotificationSaga,
    UpdateNotificationSaga,
    GetDocumentationsSaga,
    AddDocumentationsSaga,
    UpdateDocumentationsSaga,
    DeleteDocumentationsSaga,
    GetUsersSaga,
    GetConfigureStatusSaga,
    UpdateConfigureStatusSaga,
    GetCountrySaga,
    GetWeekDaysSaga
} from './AncillarySaga';
import * as ActionTypes from './ActionTypes';

export default function* AncillaryWatcher() {
    yield takeLatest(ActionTypes.RELATIONSHIP_GET_REQUEST, GetRelationshipSaga);
    yield takeLatest(ActionTypes.RELATIONSHIP_ADD_REQUEST, AddRelationshipSaga);
    yield takeLatest(ActionTypes.RELATIONSHIP_UPDATE_REQUEST, UpdateRelationshipSaga);
    yield takeLatest(ActionTypes.RELATIONSHIP_DELETE_REQUEST, DeleteRelationshipSaga);
    yield takeLatest(ActionTypes.GET_BANK_REQUEST, GetBankSaga);
    yield takeLatest(ActionTypes.ADD_BANK_REQUEST, AddBankSaga);
    yield takeLatest(ActionTypes.UPDATE_BANK_REQUEST, UpdateBankSaga);
    yield takeLatest(ActionTypes.DELETE_BANK_REQUEST, DeleteBankSaga);
    yield takeLatest(ActionTypes.STUDYLEVEL_GET_REQUEST, GetStudyLevelSaga);
    yield takeLatest(ActionTypes.STUDYLEVEL_ADD_REQUEST, AddStudyLevelSaga);
    yield takeLatest(ActionTypes.STUDYLEVEL_UPDATE_REQUEST, UpdateStudyLevelSaga);
    yield takeLatest(ActionTypes.STUDYLEVEL_DELETE_REQUEST, DeleteStudyLevelSaga);
    yield takeLatest(ActionTypes.STUDYPROGRAM_GET_REQUEST, GetStudyProgramSaga);
    yield takeLatest(ActionTypes.STUDYPROGRAM_ADD_REQUEST, AddStudyProgramSaga);
    yield takeLatest(ActionTypes.STUDYPROGRAM_UPDATE_REQUEST, UpdateStudyProgramSaga);
    yield takeLatest(ActionTypes.STUDYPROGRAM_DELETE_REQUEST, DeleteStudyProgramSaga);
    yield takeLatest(ActionTypes.DESIGNATION_GET_REQUEST, GetDesignationSaga);
    yield takeLatest(ActionTypes.DESIGNATION_ADD_REQUEST, AddDesignationSaga);
    yield takeLatest(ActionTypes.DESIGNATION_UPDATE_REQUEST, UpdateDesignationSaga);
    yield takeLatest(ActionTypes.DESIGNATION_DELETE_REQUEST, DeleteDesignationSaga);
    yield takeLatest(ActionTypes.JOB_GET_REQUEST, GetJobSaga);
    yield takeLatest(ActionTypes.JOB_ADD_REQUEST, AddJobSaga);
    yield takeLatest(ActionTypes.JOB_UPDATE_REQUEST, UpdateJobSaga);
    yield takeLatest(ActionTypes.JOB_DELETE_REQUEST, DeleteJobSaga);
    yield takeLatest(ActionTypes.EMPLOYMENT_TYPE_GET_REQUEST, GetEmploymentTypeSaga);
    yield takeLatest(ActionTypes.EMPLOYMENT_TYPE_ADD_REQUEST, AddEmploymentTypeSaga);
    yield takeLatest(ActionTypes.EMPLOYMENT_TYPE_UPDATE_REQUEST, UpdateEmploymentTypeSaga);
    yield takeLatest(ActionTypes.EMPLOYMENT_TYPE_DELETE_REQUEST, DeleteEmploymentTypeSaga);
    yield takeLatest(ActionTypes.PAYGRADE_GET_REQUEST, GetPaygradeSaga);
    yield takeLatest(ActionTypes.PAYGRADE_ADD_REQUEST, AddPaygradeSaga);
    yield takeLatest(ActionTypes.PAYGRADE_UPDATE_REQUEST, UpdatePaygradeSaga);
    yield takeLatest(ActionTypes.PAYGRADE_DELETE_REQUEST, DeletePaygradeSaga);
    yield takeLatest(ActionTypes.COST_CENTER_GET_REQUEST, GetCostCenterSaga);
    yield takeLatest(ActionTypes.COST_CENTER_ADD_REQUEST, AddCostCenterSaga);
    yield takeLatest(ActionTypes.COST_CENTER_UPDATE_REQUEST, UpdateCostCenterSaga);
    yield takeLatest(ActionTypes.COST_CENTER_DELETE_REQUEST, DeleteCostCenterSaga);
    yield takeLatest(ActionTypes.CITY_LIST_GET_REQUEST, GetCityListSaga);
    yield takeLatest(ActionTypes.HOLIDAY_CALENDAR_CITY_GET_REQUEST, GetHolidayCalendarCitySaga);
    yield takeLatest(ActionTypes.LEAVING_REASON_GET_REQUEST, GetLeavingReasonSaga);
    yield takeLatest(ActionTypes.LEAVING_REASON_ADD_REQUEST, AddLeavingReasonSaga);
    yield takeLatest(ActionTypes.LEAVING_REASON_UPDATE_REQUEST, UpdateLeavingReasonSaga);
    yield takeLatest(ActionTypes.LEAVING_REASON_DELETE_REQUEST, DeleteLeavingReasonSaga);
    yield takeLatest(ActionTypes.BUSINESSUNIT_GET_REQUEST, GetBusinessUnitSaga);
    yield takeLatest(ActionTypes.BUSINESSUNIT_ADD_REQUEST, AddBusinessUnitSaga);
    yield takeLatest(ActionTypes.BUSINESSUNIT_UPDATE_REQUEST, UpdateBusinessUnitSaga);
    yield takeLatest(ActionTypes.BUSINESSUNIT_DELETE_REQUEST, DeleteBusinessUnitSaga);
    yield takeLatest(ActionTypes.SUB_BUSINESSUNIT_GET_REQUEST, GetSubBusinessUnitSaga);
    yield takeLatest(ActionTypes.SUB_BUSINESSUNIT_ADD_REQUEST, AddSubBusinessUnitSaga);
    yield takeLatest(ActionTypes.SUB_BUSINESSUNIT_UPDATE_REQUEST, UpdateSubBusinessUnitSaga);
    yield takeLatest(ActionTypes.SUB_BUSINESSUNIT_DELETE_REQUEST, DeleteSubBusinessUnitSaga);
    yield takeLatest(ActionTypes.CASTE_GET_REQUEST, GetCasteSaga);
    yield takeLatest(ActionTypes.CASTE_ADD_REQUEST, AddCasteSaga);
    yield takeLatest(ActionTypes.CASTE_UPDATE_REQUEST, UpdateCasteSaga);
    yield takeLatest(ActionTypes.CASTE_DELETE_REQUEST, DeleteCasteSaga);
    yield takeLatest(ActionTypes.WORK_LOCATION_GET_REQUEST, GetWorkLocationSaga);
    yield takeLatest(ActionTypes.WORK_LOCATION_ADD_REQUEST, AddWorkLocationSaga);
    yield takeLatest(ActionTypes.WORK_LOCATION_UPDATE_REQUEST, UpdateWorkLocationSaga);
    yield takeLatest(ActionTypes.WORK_LOCATION_DELETE_REQUEST, DeleteWorkLocationSaga);
    yield takeLatest(ActionTypes.PROOF_TYPE_GET_REQUEST, GetProofTypeSaga);
    yield takeLatest(ActionTypes.PROOF_TYPE_ADD_REQUEST, AddProofTypeSaga);
    yield takeLatest(ActionTypes.PROOF_TYPE_UPDATE_REQUEST, UpdateProofTypeSaga);
    yield takeLatest(ActionTypes.PROOF_TYPE_DELETE_REQUEST, DeleteProofTypeSaga);
    yield takeLatest(ActionTypes.NOTIFICATION_GET_REQUEST, GetNotificationSaga);
    yield takeLatest(ActionTypes.NOTIFICATION_ADD_REQUEST, AddNotificationSaga);
    yield takeLatest(ActionTypes.NOTIFICATION_UPDATE_REQUEST, UpdateNotificationSaga);
    yield takeLatest(ActionTypes.DOCUMENTATIONS_GET_REQUEST, GetDocumentationsSaga);
    yield takeLatest(ActionTypes.DOCUMENTATIONS_ADD_REQUEST, AddDocumentationsSaga);
    yield takeLatest(ActionTypes.DOCUMENTATIONS_UPDATE_REQUEST, UpdateDocumentationsSaga);
    yield takeLatest(ActionTypes.DOCUMENTATIONS_DELETE_REQUEST, DeleteDocumentationsSaga);
    yield takeLatest(ActionTypes.GET_USERS_REQUEST, GetUsersSaga);
    yield takeLatest(ActionTypes.CONFIGURE_STATUS_GET_REQUEST, GetConfigureStatusSaga);
    yield takeLatest(ActionTypes.CONFIGURE_STATUS_UPDATE_REQUEST, UpdateConfigureStatusSaga);
    yield takeLatest(ActionTypes.COUNTRY_GET_REQUEST, GetCountrySaga);
    yield takeLatest(ActionTypes.WEEK_DAYS_GET_REQUEST, GetWeekDaysSaga);
    
}
