import * as ActionTypes from '../ActionTypes';

export const GetEmployeeWorkDetailAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_WORK_DETAIL_GET_REQUEST,
        params
    }
}

export const AddEmployeeWorkDetailAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_WORK_DETAIL_ADD_REQUEST,
        params
    }
}

export const UpdateEmployeeWorkDetailAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_WORK_DETAIL_UPDATE_REQUEST,
        params
    }
}
