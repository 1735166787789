import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react'


const TDStep2 = ({ handleInputChange, step2, setStep2, activeStep, setActiveStep, disableEdit = false }) => {
    const useStyles = makeStyles((theme) =>
        createStyles({
            paper: {
                boxShadow: "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
                padding: "20px",
                borderRadius: "10px",
                minHeight: "130px"
            }
        }),
    );
    const classes = useStyles();

    const fields = [
        { name: "et_80ccd_1b_nps", desc: "National Pension Scheme (upto Rs.50000/-)", label: "80CCD(1B) - NPS" },
        { name: "et_80d_sec80d", desc: "Mediclaim Policy Permium (For Self, spouse & children). Below 60 years - Rs. 25000/- (Including amount paid for Preventive health check-up Rs.5000/-)", label: "80D - SEC80D" },
        { name: "et_80ds_sec80ds", desc: "Mediclaim Policy Permium (For Self, spouse & children). Above 60 years - Rs. 50000/- (Including amount paid for Preventive health check-up Rs.5000/-)", label: "80DS - SEC80DS" },
        { name: "et_80dp_sec80dp", desc: "Mediclaim Policy Permium (For Dependent Parents). Below 60 years - Rs. 25000/- (Including amount paid for Preventive health check-up Rs.5000/-)", label: "80DP - SEC80DP" },
        { name: "et_80dps_sec80dps", desc: "Mediclaim Policy Permium (For Dependent Parents). Above 60 years - Rs. 50000/- (Including amount paid for Preventive health check-up Rs.5000/-)", label: "80DPS - SEC80DPS" },
        { name: "et_80ddp_sec80ddb", desc: "Treatment of specified dieseases for self/dependents - Below 60 years - Actual Expenditure upto Rs.40000/- reduced by insurance claims", label: "80DDB - SEC80DDB" },
        { name: "et_80ddbs_sec80ddbs", desc: "Treatment of specified dieseases for self/dependents - Above 60 years - Actual Expenditure upto Rs.100000/- reduced by insurance claims", label: "80DDBS - SEC80DDBS" },
        { name: "et_80e_sec80e", desc: "Payment of interest on loan taken for higher education for a full time course - Without any limit (Self/Spouse/Children)", label: "80E - SEC80E" },
        { name: "et_80dd_sec80dd", desc: "Medical Treatment if handicapped dependent (Dependents) - Rs.75000/Rs.125000/-", label: "80DD - SEC80DD" },
        { name: "et_80u_sec80u", desc: "Decuctions in case of self being totlly blind or physically handicapped - Rs.75000/Rs.125000/-", label: "80U - SEC80U" },
        { name: "et_80eeb_sec80eeb", desc: "Interest On loan taken for purchase of Electric Vehicle (Upto Rs.150000/-)", label: "80EEB - SEC80EEB" },
        { name: "et_80ggc_sec80ggc", desc: "Deduction in respect of contributions given to political parties.", label: "80GGC - SEC80GGC" },
    ]

    const handleChange = (e) => {
        setStep2({ ...step2, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        handleInputChange({ target: { name: "tds2", value: step2 } })
        setActiveStep(activeStep + 1)
    }

    return (
        <Box>
            <Typography variant='h6'>Other Chapter VIA - Maximum Eligible deduction as per Section Limits</Typography>
            <Grid container>
                {
                    fields.map((data, id) => {
                        return (
                            <>
                                {
                                    !disableEdit ?
                                        <Grid item key={id} xs={12} sm={6} md={6} padding={1.5}>
                                            <Paper className={classes.paper}>
                                                <Box sx={{ minHeight: "4rem" }}>
                                                    <Typography variant="body2" gutterBottom mb={2}>
                                                        {data?.desc}
                                                    </Typography>
                                                </Box>
                                                <TextField
                                                    size='small'
                                                    autoComplete='first_name'
                                                    onChange={handleChange}
                                                    name={data?.name}
                                                    label={data?.label}
                                                    placeholder='Enter Amount'
                                                    fullWidth
                                                    value={step2?.[data?.name] || ""}
                                                    type="number"
                                                />
                                            </Paper>
                                        </Grid>
                                        : disableEdit && step2?.[data?.name] !== null && step2?.[data?.name] !== undefined &&
                                        <Grid item key={id} xs={12} sm={6} md={6} padding={1.5}>
                                            <Paper className={classes.paper}>
                                                <Box sx={{ minHeight: "4rem" }}>
                                                    <Typography variant="body2" gutterBottom mb={2}>
                                                        {data?.desc}
                                                    </Typography>
                                                </Box>
                                                <TextField
                                                    size='small'
                                                    autoComplete='first_name'
                                                    onChange={handleChange}
                                                    name={data?.name}
                                                    label={data?.label}
                                                    disabled={true}
                                                    placeholder='Enter Amount'
                                                    fullWidth
                                                    value={step2?.[data?.name] || ""}
                                                    type="number"
                                                />
                                            </Paper>
                                        </Grid>
                                }
                            </>

                        )
                    })
                }
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {activeStep !== 0 && !disableEdit &&
                    <Button
                        sx={{ marginRight: "10px", mt: 5 }}
                        type="submit"
                        variant="outlined"
                        size="medium"
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Back
                    </Button>
                }
                {activeStep < 5 &&
                    <Button
                        sx={{ marginRight: "10px", mt: 5, }}
                        size="medium"
                        variant="contained"
                        className="mpl-primary-btn"
                        onClick={() => handleSubmit()}
                    >
                        Next
                    </Button>
                }
            </Box>
        </Box >
    )
}

export default TDStep2