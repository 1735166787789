import * as ActionTypes from '../ActionTypes';

export const GetMyTaskAction = (params) => {
    return {
        type: ActionTypes.MY_TASK_GET_REQUEST,
        params
    }
}

export const UpdateMyTaskAction = (params) => {
    return {
        type: ActionTypes.MY_TASK_UPDATE_REQUEST,
        params
    }
}

export const GetAssignedTaskAction = (params) => {
    return {
        type: ActionTypes.ASSIGNED_TASK_GET_REQUEST,
        params
    }
}

export const AddAssignedTaskAction = (params) => {
    return {
        type: ActionTypes.ASSIGNED_TASK_ADD_REQUEST,
        params
    }
}

export const UpdateAssignedTaskAction = (params) => {
    return {
        type: ActionTypes.ASSIGNED_TASK_UPDATE_REQUEST,
        params
    }
}

export const DeleteAssignedTaskAction = (params) => {
    return {
        type: ActionTypes.ASSIGNED_TASK_DELETE_REQUEST,
        params
    }
}