import * as ActionTypes from '../ActionTypes';

export const GetBusinessUnitAction = () => {
    return {
        type: ActionTypes.BUSINESSUNIT_GET_REQUEST,
    }
}

export const AddBusinessUnitAction = (params) => {
    return {
        type: ActionTypes.BUSINESSUNIT_ADD_REQUEST,
        params
    }
}

export const UpdateBusinessUnitAction = (params) => {
    return {
        type: ActionTypes.BUSINESSUNIT_UPDATE_REQUEST,
        params
    }
}

export const DeleteBusinessUnitAction = (params) => {
    return {
        type: ActionTypes.BUSINESSUNIT_DELETE_REQUEST,
        params
    }
}