import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetAtsJobPostHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    return await axiosInstance(`/ats/getJobPosts`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            company_data: params?.company_data || null,
            consultancy_data: params?.consultancy_data || null,
            status: params?.status || "Active" ,
            severity_data: params?.severity_data || null,
            search: params?.search || null,
            pagenumber: params?.pagenumber || null,
            pagesize: params?.pagesize || null,
            sort_column: params?.sort_column || null,
            sort_order: params?.sort_order || null,
            primary_recruiter: params?.primary_recruiter || null,
            secondary_recruiter: params?.secondary_recruiter || null,
            grouporg_data:params?.grouporg_data || null,
            tab_type: params?.tab_type||"Active"
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsJobPostByIdHelper = async (params) => {
    toast.dismiss();
    return await axiosInstance(`/ats/getJobPostById`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params?.domain,
            ats_jobpost_id: params?.ats_jobpost_id || 0,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAtsJobPostHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Saving...");
    return await axiosInstance(`/ats/addJobPost`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            jobpost_data: params.jobpost_data,
            consultancy: params?.consultancy_id || null,
            primary_recruiter: params?.primary_recruiter || null,
            secondary_recruiter: params?.secondary_recruiter || null,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Job Post Added Successfully")
            return { message: "Job Post Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsJobPostHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Saving...");
    return await axiosInstance(`/ats/updateJobPost`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            jobpost_data: params.jobpost_data,
            consultancy: params?.consultancy_id || null,
            primary_recruiter: params?.primary_recruiter || null,
            secondary_recruiter: params?.secondary_recruiter || null,
            tab_type: params?.tab_type || "Active",
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Job Post Updated Successfully")
            return { message: "Job Post Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsJobPostStatusHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Saving...");
    return await axiosInstance(`/ats/updateJobPostStatus`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            jobpost_id: params.update_id,
            status: params?.status,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Status Updated Successfully")
            return { message: "Status Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsJobOfferHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    return await axiosInstance(`/ats/getJobOfferStatus`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            jobpost_id: params?.ats_jobpost_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
