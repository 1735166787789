import { takeLatest } from 'redux-saga/effects';
import {
    GetEmployeeBasicSaga,
    AddEmployeeBasicSaga,
    UpdateEmployeeBasicSaga,
    AddEmployeeBankSaga,
    UpdateEmployeeBankSaga,
    DeleteEmployeeBankSaga,
    GetEmployeeBankSaga,
    GetEmployeeFamilySaga,
    AddEmployeeFamilySaga,
    DeleteEmployeeFamilySaga,
    UpdateEmployeeFamilySaga,
    GetEmployeeEducationSaga,
    AddEmployeeEducationSaga,
    UpdateEmployeeEducationSaga,
    DeleteEmployeeEducationSaga,
    GetEmployeeWorkExperienceSaga,
    AddEmployeeWorkExperienceSaga,
    UpdateEmployeeWorkExperienceSaga,
    DeleteEmployeeWorkExperienceSaga,
    GetEmployeePersonalSaga,
    UpdateEmployeePersonalSaga,
    AddEmployeePersonalSaga,
    DeleteEmployeePersonalSaga,
    GetEmployeeOtherPersonalSaga,
    UpdateEmployeeOtherPersonalSaga,
    GetEmployeeWorkDetailSaga,
    UpdateEmployeeWorkDetailSaga,
    AddEmployeeWorkDetailSaga,
    GetEmployeesSaga,
    GetEmployeePayrollDetailsSaga,
    UpdateEmployeePayrollDetailsSaga,
    GetAtsEmployeesSaga,
    UpdateEmployeeAddressSaga,
    GetEmployeeAddressSaga,
    GetEmployeesFaceSaga
} from './EmployeeSaga';
import * as ActionTypes from './ActionTypes';

export default function* EmployeeWatcher() {
    yield takeLatest(ActionTypes.EMPLOYEES_GET_REQUEST, GetEmployeesSaga);
    yield takeLatest(ActionTypes.EMPLOYEES_FACE_GET_REQUEST, GetEmployeesFaceSaga);
    yield takeLatest(ActionTypes.ATS_EMPLOYEES_GET_REQUEST, GetAtsEmployeesSaga);
    yield takeLatest(ActionTypes.GET_EMPLOYEE_BASIC_REQUEST, GetEmployeeBasicSaga);
    yield takeLatest(ActionTypes.ADD_EMPLOYEE_BASIC_REQUEST, AddEmployeeBasicSaga);
    yield takeLatest(ActionTypes.UPDATE_EMPLOYEE_BASIC_REQUEST, UpdateEmployeeBasicSaga);
    yield takeLatest(ActionTypes.GET_EMPLOYEE_BANK_REQUEST, GetEmployeeBankSaga);
    yield takeLatest(ActionTypes.ADD_EMPLOYEE_BANK_REQUEST, AddEmployeeBankSaga);
    yield takeLatest(ActionTypes.UPDATE_EMPLOYEE_BANK_REQUEST, UpdateEmployeeBankSaga);
    yield takeLatest(ActionTypes.DELETE_EMPLOYEE_BANK_REQUEST, DeleteEmployeeBankSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_FAMILY_GET_REQUEST, GetEmployeeFamilySaga);
    yield takeLatest(ActionTypes.EMPLOYEE_FAMILY_ADD_REQUEST, AddEmployeeFamilySaga);
    yield takeLatest(ActionTypes.EMPLOYEE_FAMILY_UPDATE_REQUEST, UpdateEmployeeFamilySaga);
    yield takeLatest(ActionTypes.EMPLOYEE_FAMILY_DELETE_REQUEST, DeleteEmployeeFamilySaga);
    yield takeLatest(ActionTypes.EMPLOYEE_EDUCATION_GET_REQUEST, GetEmployeeEducationSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_EDUCATION_ADD_REQUEST, AddEmployeeEducationSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_EDUCATION_UPDATE_REQUEST, UpdateEmployeeEducationSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_EDUCATION_DELETE_REQUEST, DeleteEmployeeEducationSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_WORK_EXPERIENCE_GET_REQUEST, GetEmployeeWorkExperienceSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_WORK_EXPERIENCE_ADD_REQUEST, AddEmployeeWorkExperienceSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_WORK_EXPERIENCE_UPDATE_REQUEST, UpdateEmployeeWorkExperienceSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_WORK_EXPERIENCE_DELETE_REQUEST, DeleteEmployeeWorkExperienceSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_PERSONAL_GET_REQUEST, GetEmployeePersonalSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_PERSONAL_ADD_REQUEST, AddEmployeePersonalSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_PERSONAL_UPDATE_REQUEST, UpdateEmployeePersonalSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_PERSONAL_DELETE_REQUEST, DeleteEmployeePersonalSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_OTHER_PERSONAL_GET_REQUEST, GetEmployeeOtherPersonalSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_OTHER_PERSONAL_UPDATE_REQUEST, UpdateEmployeeOtherPersonalSaga);

    yield takeLatest(ActionTypes.EMPLOYEE_ADDRESS_GET_REQUEST, GetEmployeeAddressSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_ADDRESS_UPDATE_REQUEST, UpdateEmployeeAddressSaga);
    
    yield takeLatest(ActionTypes.EMPLOYEE_WORK_DETAIL_GET_REQUEST, GetEmployeeWorkDetailSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_WORK_DETAIL_ADD_REQUEST, AddEmployeeWorkDetailSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_WORK_DETAIL_UPDATE_REQUEST, UpdateEmployeeWorkDetailSaga);
    yield takeLatest(ActionTypes.GET_EMPLOYEE_PAYROLL_DETAILS_REQUEST, GetEmployeePayrollDetailsSaga);
    yield takeLatest(ActionTypes.UPDATE_EMPLOYEE_PAYROLL_DETAILS_REQUEST, UpdateEmployeePayrollDetailsSaga);
}
