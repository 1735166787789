import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetEmployeeProfileAction } from '../../../../../store/Finance/Profile/Action';
import payroll from "../../images/image.png";

const Dashboard = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetEmployeeProfileAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={payroll} alt="payroll" width="50%" style={{ maxHeight: "25rem" }} />
        </div>
    );
}

export default Dashboard;
