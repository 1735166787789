import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";

export const GetRelationshipHelper = async () => {
    return await axiosInstance(`/ancillary/getRelationships`, {
        method: "GET",
        baseURL: HRMS_UTILITY_API_URL,
        data: {}
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddRelationshipHelper = async (relationship) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addRelationship`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data?.employee_id,
            name: relationship?.reship_name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Relationship Added Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateRelationshipHelper = async (relationship) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateRelationship`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data?.employee_id,
            relationship_id: relationship?.relationship_id,
            name: relationship?.reship_name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Relationship Updated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteRelationshipHelper = async (relationship) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteRelationship`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data?.employee_id,
            relationship_id: relationship?.relationship_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Relationship Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}