import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import NProgress from 'nprogress';


export const GetAtsInvoiceCalculationHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getInoviceCalculation`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            domain_name: user?.domain,
            ats_company_id: params.ats_company_id,
            candidate_data: params.candidate_data,
            is_grouporg: user?.grouporg_flow || false
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsInvoiceHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getInovice`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            status: params.status,
            ats_company_id: params?.ats_company_id?.ats_company_id || params?.company_filter?.ats_company_id || params?.ats_company_id?.ats_grouporg_id || params?.company_filter?.ats_grouporg_id || null,
            past_days: `${params?.past_days} days`,
            is_grouporg: user?.grouporg_flow || false
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsInvoiceCandidatesHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getInoviceCandidates`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            ats_invoice_id: params?.ats_invoice_id
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsInvoiceGenerateCandidatesHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getGenerateInoviceCandidates`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_empid: user.ats_user_id,
            ats_company_id: params?.ats_company_id?.ats_company_id || params?.company_filter?.ats_company_id || params?.ats_company_id?.ats_grouporg_id || params?.company_filter?.ats_grouporg_id || null,
            past_days: `${params?.past_days} days`,
            is_grouporg: user?.grouporg_flow || false
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAtsInvoiceHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addInvoice`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_empid: user?.ats_user_id,
            invoice_date: params?.invoice_date,
            invoice_amount: params?.invoice_amount,
            gst_total: params?.gst_total,
            paid_date: params?.paid_date || null,
            company_id: params?.company_id,
            candidates_json: params?.candidates_json,
            candidate_job_ids: params?.candidate_job_ids,
            is_grouporg: user?.grouporg_flow || false
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Invoice Generated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsInvoiceHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateInvoice`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_empid: user?.ats_user_id,
            ats_invoice_id: params?.ats_invoice_id,
            paid_status: params?.paid_status,
            status: params?.status,
            ats_company_id: params?.ats_company_id || null,
            past_days: `${params?.past_days} days`,
            paid_date: params?.paid_date,
            is_grouporg: user?.grouporg_flow || false
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Status Updated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}