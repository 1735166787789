import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";

export const UpdateCompanyLogoHelper = async (params) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/ancillary/updateLogo`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data.domain,
            user_empid: data.emp_users_pk,
            image: params?.image || "",
            image_name: params?.image_name || "",
            old_image:params?.old_image || "",
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Company Logo Updated Successfully")
            return { message: "Company Logo Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetCompanyLogoHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/getLogo`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain || data.domain,
        }
    })
        .then(res => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}