import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: true,
    error: false,
    errorMessage: "",
    success: false,
    atsJobFilter: []
};

export const AtsJobFilterReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_JOB_FILTER_ADMIN_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                atsJobFilter: []
            };
        case ActionTypes.ATS_JOB_FILTER_ADMIN_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsJobFilter: action.payload?.data?.data
            };
        case ActionTypes.ATS_JOB_FILTER_ADMIN_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };

            case ActionTypes.ATS_JOB_FILTER_INTERNAL_GET_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                    error: false,
                    success: false,
                    atsJobFilter: []
                };
            case ActionTypes.ATS_JOB_FILTER_INTERNAL_GET_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    successMessage: action.payload?.message,
                    atsJobFilter: action.payload?.data?.data
                };
            case ActionTypes.ATS_JOB_FILTER_INTERNAL_GET_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    error: action?.payload?.hasError,
                    errorMessage: action.payload?.data?.error?.[0]?.message
                };

                case ActionTypes.ATS_JOB_FILTER_CONSULTANCY_GET_REQUEST:
                    return {
                        ...state,
                        isLoading: true,
                        error: false,
                        success: false,
                        atsJobFilter: []
                    };
                case ActionTypes.ATS_JOB_FILTER_CONSULTANCY_GET_SUCCESS:
                    return {
                        ...state,
                        isLoading: false,
                        success: true,
                        successMessage: action.payload?.message,
                        atsJobFilter: action.payload?.data?.data
                    };
                case ActionTypes.ATS_JOB_FILTER_CONSULTANCY_GET_ERROR:
                    return {
                        ...state,
                        isLoading: false,
                        error: action?.payload?.hasError,
                        errorMessage: action.payload?.data?.error?.[0]?.message
                    };

                    case ActionTypes.ATS_JOB_FILTER_COMPANY_GET_REQUEST:
                        return {
                            ...state,
                            isLoading: true,
                            error: false,
                            success: false,
                            atsJobFilter: []
                        };
                    case ActionTypes.ATS_JOB_FILTER_COMPANY_GET_SUCCESS:
                        return {
                            ...state,
                            isLoading: false,
                            success: true,
                            successMessage: action.payload?.message,
                            atsJobFilter: action.payload?.data?.data
                        };
                    case ActionTypes.ATS_JOB_FILTER_COMPANY_GET_ERROR:
                        return {
                            ...state,
                            isLoading: false,
                            error: action?.payload?.hasError,
                            errorMessage: action.payload?.data?.error?.[0]?.message
                        };
                        case ActionTypes.ATS_JOB_FILTER_GROUP_ORG_GET_REQUEST:
                            return {
                                ...state,
                                isLoading: true,
                                error: false,
                                success: false,
                                atsJobFilter: []
                            };
                        case ActionTypes.ATS_JOB_FILTER_GROUP_ORG_GET_SUCCESS:
                            return {
                                ...state,
                                isLoading: false,
                                success: true,
                                successMessage: action.payload?.message,
                                atsJobFilter: action.payload?.data?.data
                            };
                        case ActionTypes.ATS_JOB_FILTER_GROUP_ORG_GET_ERROR:
                            return {
                                ...state,
                                isLoading: false,
                                error: action?.payload?.hasError,
                                errorMessage: action.payload?.data?.error?.[0]?.message
                            };
        default:
            return state;
    }
}