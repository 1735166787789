import * as ActionTypes from '../ActionTypes';

export const GetCostCenterAction = () => {
    return {
        type: ActionTypes.COST_CENTER_GET_REQUEST
    }
}


export const AddCostCenterAction = (params) => {
    return {
        type: ActionTypes.COST_CENTER_ADD_REQUEST,
        params
    }
}

export const UpdateCostCenterAction = (params) => {
    return {
        type: ActionTypes.COST_CENTER_UPDATE_REQUEST,
        params
    }
}

export const DeleteCostCenterAction = (params) => {
    return {
        type: ActionTypes.COST_CENTER_DELETE_REQUEST,
        params
    }
}