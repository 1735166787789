import React from "react";
import { Route, Redirect } from "react-router-dom"
import { isAuthenticated } from "./app/Components/SignIn/helper/SigninHelper";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to='/' />
        )
      }
    />
  );
};

export default PrivateRoute;