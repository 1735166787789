import { Alert, Box } from '@mui/material';
import React from 'react';

export const NotAuthorized = ({ title = "You don't have access to this page. Please contact your manager or administrator!" }) => {
    return (
        <>
            <Box sx={{
                display: "inline-block",
                verticalAlign: "center",
                lineHeight: "20px",
                justifyContent: "center",
                paddingTop: "20px",
                alignItems: "center",
                position: "fixed",
                top: "50%",
                bottom: "50%",
                right: "25%",
                left: "30%",
            }}>
                <Alert severity="warning">{title}</Alert>
            </Box>
        </>
    )
}


export const NoData = ({ title = "No Jobs Found" }) => {
    return (
        <>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
                alignItems: "center"
            }}>
                <Alert severity="warning">{title}</Alert>
            </Box>
        </>
    )
}
