import * as ActionTypes from '../ActionTypes';

const initialHolidayCalendarState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
};

export const HolidayCalendarReducer = function (state = initialHolidayCalendarState, action) {
    switch (action.type) {
        case ActionTypes.UPCOMING_HOLIDAYS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                upcomingHolidaysData: []
            };
        case ActionTypes.UPCOMING_HOLIDAYS_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                upcomingHolidaysData: action.payload?.data?.data
            };
        case ActionTypes.UPCOMING_HOLIDAYS_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.HOLIDAY_CALENDAR_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                holidayCalendarData: []
            };
        case ActionTypes.HOLIDAY_CALENDAR_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                holidayCalendarData: action.payload?.data?.data
            };
        case ActionTypes.HOLIDAY_CALENDAR_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.HOLIDAY_CALENDAR_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.HOLIDAY_CALENDAR_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                holidayCalendarData: action.payload?.data?.data
            };
        case ActionTypes.HOLIDAY_CALENDAR_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.HOLIDAY_CALENDAR_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.HOLIDAY_CALENDAR_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                holidayCalendarData: action.payload?.data?.data
            };
        case ActionTypes.HOLIDAY_CALENDAR_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.HOLIDAY_CALENDAR_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.HOLIDAY_CALENDAR_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                holidayCalendarData: action.payload?.data?.data
            };
        case ActionTypes.HOLIDAY_CALENDAR_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}