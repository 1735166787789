import * as ActionTypes from '../ActionTypes';

export const GetPaygradeAction = () => {
    return {
        type: ActionTypes.PAYGRADE_GET_REQUEST
    }
}

export const AddPaygradeAction = (paygrade) => {
    return {
        type: ActionTypes.PAYGRADE_ADD_REQUEST,
        paygrade
    }
}

export const UpdatePaygradeAction = (paygrade) => {
    return {
        type: ActionTypes.PAYGRADE_UPDATE_REQUEST,
        paygrade
    }
}

export const DeletePaygradeAction = (paygrade) => {
    return {
        type: ActionTypes.PAYGRADE_DELETE_REQUEST,
        paygrade
    }
}