import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { Box, IconButton, Typography } from "@mui/material";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Done from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import Popup from '../../../Base/views/Popup';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetNotificationAction, UpdateNotificationAction } from '../../../../../store/Ancillary/Notification/Action';

export default function Notification() {
    const dispatch = useDispatch();
    const { notificationData } = useSelector(state => state?.AncillaryReducer?.NotificationReducer);
    const [openPopup, setOpenPopup] = useState(false);
    const [currentData, setCurrentData] = useState({});

    useEffect(() => {
     dispatch(GetNotificationAction())
        // eslint-disable-next-line
    }, [])

    const handleClickOpen = (e, data) => {
        setCurrentData(data);
        setOpenPopup(true);
    };

    const handleUpdateNotification = (event, id) => {
        event.stopPropagation()
        dispatch(UpdateNotificationAction({ is_read: "Y", notification_data: `{${id}}` }))
    }

    const handleUpdateAll = (event) => {
        event.stopPropagation()
        let id = []
        notificationData?.[0]?.notifiedusers?.forEach(res => id.push(res?.notification_id))
        dispatch(UpdateNotificationAction({ is_read: "Y", notification_data: `{${id.join()}}` }))
    }

    return (
        <Box sx={{ maxHeight: "33vh", minHeight: "33vh", overflowY: "auto" }}>
            <Popup title={"Notification"} maxWidth="sm" fullwidth openPopup={openPopup} setOpenPopup={setOpenPopup}>
                {currentData?.comment}
            </Popup>
            {
                notificationData?.[0]?.notifiedusers?.filter(item => item?.is_read !== true)?.length > 0 ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1, mt: -1 }}>
                            <Typography sx={{ color: 'dark', fontWeight: 'bold', fontSize: 20 }}>Notifications</Typography>
                            <Tooltip title="Mark all as read" placement="bottom" sx={{ "&:hover": { color: "green" } }}>
                                <IconButton onClick={handleUpdateAll}>
                                    <DoneAllIcon sx={{ mt: -1 }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Divider />
                        {
                            notificationData?.[0]?.notifiedusers?.map((res, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <MenuItem onClick={(e) => { handleClickOpen(e, res); handleUpdateNotification(e, res?.notification_id) }} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography overflow="hidden" textOverflow="ellipsis">
                                                {res?.comment}
                                            </Typography>
                                            <Tooltip title="Mark as read" placement="bottom" sx={{ "&:hover": { color: "green" } }}>
                                                <IconButton onClick={(e) => handleUpdateNotification(e, res?.notification_id)}>
                                                    <Done />
                                                </IconButton>
                                            </Tooltip>
                                        </MenuItem>
                                    </React.Fragment>
                                )
                            })
                        }
                    </>
                    : <Typography sx={{ color: 'dark', fontWeight: 'bold', textAlign: "center" }}>Well done. All notifications are addressed.</Typography>
            }
        </Box>
    );
}