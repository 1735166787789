import * as ActionTypes from '../ActionTypes';

const initialState = {
    workLocation_options: []
};

const WorkLocationReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.WORK_LOCATION_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                isSuccess:false,
                error: false
            };
        case ActionTypes.WORK_LOCATION_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                isSuccess:true,
                error: action.payload.has_error,
                workLocation_options: action.payload?.data?.data
            };
        case ActionTypes.WORK_LOCATION_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                isSuccess:false,
                error: false
            };
        case ActionTypes.WORK_LOCATION_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                isSuccess:true,
                error: action.payload.has_error,
                workLocation_options: action.payload?.data?.data
            };
        case ActionTypes.WORK_LOCATION_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload.has_error,
            };
        case ActionTypes.WORK_LOCATION_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false
            };
        case ActionTypes.WORK_LOCATION_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: action.payload.has_error,
                workLocation_options: action.payload?.data?.data
            };
        case ActionTypes.WORK_LOCATION_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload.has_error,
            };
        case ActionTypes.WORK_LOCATION_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false
            };
        case ActionTypes.WORK_LOCATION_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: action.payload.has_error,
                workLocation_options: action.payload?.data?.data
            };
        case ActionTypes.WORK_LOCATION_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload.has_error,
            };
        default:
            return state;
    }
}

export default WorkLocationReducer;