import { A, Email, Item } from "react-html-email";
import { BASE_URL } from "../../../Constants/baseConstant";

const BaseEmailTemplate = ({ children }) => {
    return (
        <>
            <Email title="Hello World!">
                <Item>
                    <body marginHeight="0" topmargin="0" marginWidth="0" style={{ margin: "0px", backgroundColor: "#f2f3f8" }} leftmargin="0">
                        <table cellSpacing="0" border="0" cellPadding="0" width="100%" bgcolor="#f2f3f8">
                            <tr>
                                <td>
                                    <table style={{ backgroundColor: "#f2f3f8", maxWidth: "1070px", margin: 0 }} width="100%" border="0"
                                        align="center" cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <td style={{ textAlign: "center" }}>
                                                <A href={BASE_URL} title="logo" target="_blank">
                                                    <img width="60" style={{ minHeight: "60px", minWidth: "200px", padding: "10px" }} src="https://meepldevstorage.nyc3.digitaloceanspaces.com/fulllogo.PNG" title="logo" alt="logo" />
                                                    {/* <Image width={70} height={40} src={fulllogo} alt="Logo" title="Logo"> </Image> */}
                                                </A>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {children}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: "20px" }}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>
                                                <span style={{ fontSize: "14px", color: "rgba(69, 80, 86, 0.7411764705882353)", lineHeight: "18px", margin: "0 0 0" }}> <strong>© Copyright @ {new Date().getFullYear()} Meepl, All Rights Reserved</strong></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: "20px" }}>&nbsp;</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </body>
                </Item>
            </Email>
        </>
    )

}

export default BaseEmailTemplate