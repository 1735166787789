import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
    templateData: [],
    prescreenData:[],
    offerletterData:[]
};

export const FormTemplateReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.FORM_TEMPLATE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.FORM_TEMPLATE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                templateData: action.payload?.data?.data
            };
        case ActionTypes.FORM_TEMPLATE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.FORM_TEMPLATE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.FORM_TEMPLATE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                templateData: action.payload?.data?.data
            };
        case ActionTypes.FORM_TEMPLATE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.FORM_TEMPLATE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.FORM_TEMPLATE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                templateData: action.payload?.data?.data
            };
        case ActionTypes.FORM_TEMPLATE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.FORM_TEMPLATE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.FORM_TEMPLATE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                templateData: action.payload?.data?.data
            };
        case ActionTypes.FORM_TEMPLATE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
            
            case ActionTypes.PRE_SCREEN_TEMPLATE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                prescreenData: action.payload?.data?.data
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                prescreenData: action.payload?.data?.data
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                prescreenData: action.payload?.data?.data
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                prescreenData: action.payload?.data?.data
            };
        case ActionTypes.PRE_SCREEN_TEMPLATE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };   
            case ActionTypes.OFFER_LETTER_TEMPLATE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                offerletterData: action.payload?.data?.data
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                offerletterData: action.payload?.data?.data
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                offerletterData: action.payload?.data?.data
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                offerletterData: action.payload?.data?.data
            };
        case ActionTypes.OFFER_LETTER_TEMPLATE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        default:
            return state;
    }
}