const ProposeCandidateEmail = ({
  proposedJobData,
  formLink,
  PreScreenLink,
  button = "Validate Your Details",
  prebutton = "Pre Screen Question",
}) => {
  const maxWords = 50;
  const words = proposedJobData?.primary_skill;
  const truncatedData = words?.slice(0, maxWords);
  const showEllipsis = words?.length > maxWords;

  return (
    <>
      <table
        width="100%"
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        style={{
          maxWidth: "1070px",
          background: "#fff",
          borderRadius: "3px",
          textAlign: "center",
        }}
      >
        <tr>
          <td style={{ padding: "20px" }}>
            <h2>You have been Proposed to a {proposedJobData?.title} Job</h2>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <a
                  href={formLink}
                  style={{
                    background: "#20e277",
                    textDecoration: "none !important",
                    fontWeight: 500,
                    marginTop: "35px",
                    color: "#fff",
                    textTransform: "uppercase",
                    fontSize: "14px",
                    padding: "10px 24px",
                    display: "inlineBlock",
                    borderRadius: "50px",
                  }}
                >
                  {button}
                </a>
              </div>
              <div>
                <a
                  href={PreScreenLink}
                  style={{
                    background: "#20e277",
                    textDecoration: "none !important",
                    fontWeight: 500,
                    marginTop: "35px",
                    color: "#fff",
                    textTransform: "uppercase",
                    fontSize: "14px",
                    padding: "10px 24px",
                    display: "inlineBlock",
                    borderRadius: "50px",
                  }}
                >
                  {prebutton}
                </a>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ paddingLeft: "10px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3>Job Details</h3>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" , marginBottom:"10px" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "30%" }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Job Title
                </span>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row"}}
              >
                <div>: &nbsp;{proposedJobData?.title}</div>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" , marginBottom:"10px" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "30%" }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Client
                </span>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div>: &nbsp;{proposedJobData?.company}</div>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" , marginBottom:"10px" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "30%" }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Skills Required
                </span>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div>: &nbsp; {truncatedData}{showEllipsis ? '...' : ''}</div>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" , marginBottom:"10px"}}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "30%" }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Vacancies
                </span>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div>: &nbsp;{proposedJobData?.vaccancies}</div>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom:"10px" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", width: "30%" }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Location
                </span>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div>: &nbsp;{proposedJobData?.job_location}</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          {proposedJobData?.description ? (
            <td style={{ paddingLeft: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Job Description:
                </span>
              </div>
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: proposedJobData?.description,
                }}
              ></div>
            </td>
          ) : (
            <td style={{ paddingLeft: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Job Description:
                </span>
                <div>NA</div>
              </div>
            </td>
          )}
        </tr>
        <tr>
          {proposedJobData?.roles_responsibilities ? (
            <td style={{ paddingLeft: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Roles and Responsibilities:
                </span>
              </div>
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: proposedJobData?.roles_responsibilities,
                }}
              ></div>
            </td>
          ) : (
            <td style={{ paddingLeft: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  Roles and Responsibilities:
                </span>
                <div>NA</div>
              </div>
            </td>
          )}
        </tr>
      </table>
    </>
  );
};

export default ProposeCandidateEmail;
