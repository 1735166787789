import { Box, Button, ClickAwayListener, Divider, Drawer, IconButton, Skeleton, Tooltip, Typography, FormControl, Grid, TextField } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetIndustryTypeHelper } from '../../helper/Ancillary/IndustryTypeHelper';
import { AddJobTemplateAction, GetJobTemplateAction, GetMeeplAdminJobTemplateAction } from "../../../../../store/ATS/JobTemplate/Action";
import Popup from "../../../Base/views/Popup";
import { grey } from "@mui/material/colors";
import Quill from "../../../Base/views/Quill";
import SelectableSearch from "../../../Base/views/SelectableSearch";

const AtsJobTemplatePreview = ({ drawerdata, toggleDrawer, anchor, handleApplyTemplate, jobPostData, setPostJobData }) => {
    const [templateValue, setTemplateValue] = useState(0);
    const { jobTemplateData, isLoading } = useSelector(state => state?.AtsReducer?.JobTemplateReducer);
    const [openPopup, setOpenPopup] = useState(false);

    const [jobTemplatedata, setJobTemplatedata] = useState({
        industry_name: "",
        industry_code:"",
        job_title: "",
        job_description: "",
        roles_responsibilities: "",
    });
    
    const [description, setDescription] = useState('');
    const [rolesresponsibilities, setRolesResponsibilities] = useState('');
    const [errors, setErrors] = useState({});
    const [industryTypedataList, setIndustryTypedataList] = useState({ load: false, values: [] });
    const domain_name = sessionStorage.getItem("userManagement")
    const [loading, setLoading] = useState(false);
    const [flag,setFlag] = useState(false);

    const dispatch = useDispatch();


    

    useEffect(() => {
        
        domain_name === "meepladmin" ? dispatch(GetMeeplAdminJobTemplateAction({ industry_code: [].industry_name?.code || null }))
            : dispatch(GetJobTemplateAction({ industry_code: [].industry_name?.code || null }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (flag) {
            setJobTemplatedata(prevData => ({
                ...prevData,
                industry_name: {
                    industry_type_id: jobPostData?.industry_type?.industry_type_id,
                    industry_name: jobPostData?.industry_type?.industry_name,
                    industry_code: jobPostData?.industry_type?.code
                }
            }));
            
        }
    }, [flag, jobPostData]);

    const handleTemplateData = () => {
        setOpenPopup(true);
        setFlag(true);
    };
    const handleLoading = () => {
        setLoading(true);
    }

    useEffect(() => {
        if (loading) {
            GetIndustryTypeHelper().then(res => {
                if (res?.data?.statuscode === 200) {
                    setIndustryTypedataList({ load: false, values: res?.data?.data });
                }
                setLoading(false);
            });
        }
    }, [loading]);

    const handleChange = (event, value) => {
        if (event === "industry_name") {
            setJobTemplatedata(prevData => ({ ...prevData, [event]: value }));
            if (Object.values(errors).some(res => res !== "")) {
                validate({ ...jobTemplatedata, [event]: value });
            }
        } else if (event && event.target) {
            const { name, value } = event.target;
            setJobTemplatedata(prevData => ({ ...prevData, [name]: value }));
            if (Object.values(errors).some(res => res !== "")) {
                validate({ ...jobTemplatedata, [name]: value });
            }
        }
    };

    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("industry_name" in fieldValues) {
            temp.industry_name = fieldValues.industry_name === "" || fieldValues.industry_name === null ? "Industry Type is required" : "";
        }
        if ("job_title" in fieldValues) {
            temp.job_title = fieldValues.job_title === "" ? "Job Title is required" : "";
        }
        if ("job_description" in fieldValues) {
            temp.description = description === "" ? "Description is required" : description === "<p><br></p>" ? "Description is required" : "";
        }
        if ("roles_responsibilities" in fieldValues) {
            temp.roles_responsibilities = rolesresponsibilities === "" ? "Roles and Responsibilities are required" : rolesresponsibilities === "<p><br></p>" ? "Roles and Responsibilities are required" : "";
        }
        
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleSubmit = () => {
        if (validate(jobTemplatedata)) {
                dispatch(AddJobTemplateAction({
                    industry_code: jobTemplatedata?.industry_name?.industry_code,
                    job_template_id: jobTemplatedata?.job_template_id,
                    job_title: jobTemplatedata?.job_title,
                    job_description: description,
                    roles_responsibilities: rolesresponsibilities
                }))
                handleApplyTemplate({
                    description: description,
                    roles_responsibilities: rolesresponsibilities
                });
                resetForm()
                setOpenPopup(false)
                setFlag(false)
        }
    }

    const resetForm = () => {
        setJobTemplatedata({ industry_name: "", job_title: "", job_description: "", roles_responsibilities: "" });
        setRolesResponsibilities('');
        setDescription('');
        setErrors({});
    };

    const handleCancel = () => {
        resetForm();
        setOpenPopup(false);
        setFlag(false);
    };

    const jobTitleExists = Array.isArray(jobTemplateData) && jobTemplateData.some(template => template.job_title === jobTemplatedata.job_title);


    useEffect(() => {
        if (drawerdata) {
            setJobTemplatedata({
                industry_name: drawerdata.industry_name || "",
                job_title: drawerdata.job_title || "",
                job_description: drawerdata.job_description || "",
                roles_responsibilities: drawerdata.roles_responsibilities || ""
            });
            setDescription(drawerdata.job_description || "");
            setRolesResponsibilities(drawerdata.roles_responsibilities || "");
        }
    }, [drawerdata]);
    
    return (
        <>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                open={anchor}
                onClickAway={() => anchor && toggleDrawer(false)}
            >
                <Drawer
                    variant="temporary"
                    ModalProps={{
                        keepMounted: false,
                    }}
                    PaperProps={{
                        sx: { width: "35%" },
                    }}
                    anchor="right"
                    open={anchor}
                    onClose={() => toggleDrawer(false)}
                >
                    {isLoading ? (
                        <>
                            <Skeleton sx={{ mt: 2 }} variant="rectangular" height={60} />
                            <Skeleton sx={{ mt: 4 }} variant="rectangular" height={180} />
                            <Skeleton sx={{ mt: 1 }} variant="rectangular" height={180} />
                        </>
                    ) : (
                        <Box sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box>
                                <Box sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#ffffff", display: "flex", justifyContent: "space-between", alignItems: "center", p: 1 }}>
                                    <Typography variant="h4">{drawerdata?.job_title}</Typography>
                                    <Box>
                                    {!jobTitleExists ? (
                                        <Tooltip title="Add New Template">
                                            <IconButton onClick={handleTemplateData} className="mpl-primary-btn">
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>):(
                                        <Button
                                            sx={{ ml: 2 }}
                                            component="span"
                                            variant="contained"
                                            className="mpl-primary-btn"
                                            onClick={() => {
                                                handleApplyTemplate({ 
                                                    description: jobTemplateData?.[templateValue]?.job_description, 
                                                    roles_responsibilities: jobTemplateData?.[templateValue]?.roles_responsibilities 
                                                });
                                                toggleDrawer(false);
                                            }}
                                        >
                                            Apply & Edit
                                        </Button>)}
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ mt: 2, overflowY: "auto" }}>
                                    <Typography variant="h5">Job Description</Typography>
                                    {jobTemplateData?.[templateValue]?.job_description ? (
                                        <Typography sx={{ pl: 2.5, mt: 1 }} dangerouslySetInnerHTML={{ __html: jobTemplateData?.[templateValue]?.job_description }} />
                                    ) : (
                                        <Typography sx={{ fontStyle: "italic", pl: 2.5 }}>
                                            {jobTemplateData?.[templateValue]?.job_description || "NA"}
                                        </Typography>
                                    )}
                                    <Typography variant="h5" sx={{ mt: 2 }}>Roles and Responsibilities</Typography>
                                    {jobTemplateData?.[templateValue]?.roles_responsibilities ? (
                                        <Typography sx={{ pl: 2.5, mt: 1 }} dangerouslySetInnerHTML={{ __html: jobTemplateData?.[templateValue]?.roles_responsibilities }} />
                                    ) : (
                                        <Typography sx={{ fontStyle: "italic", pl: 2.5 }}>
                                            {jobTemplateData?.[templateValue]?.roles_responsibilities || "NA"}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box sx={{ position: "sticky", bottom: 0, zIndex: 1, backgroundColor: "#ffffff", display: "flex", justifyContent: "space-between", alignItems: "center", p: 2 }}>
                                <KeyboardArrowLeftIcon sx={{ cursor: "pointer" }} onClick={() => { templateValue !== 0 && setTemplateValue(templateValue - 1) }} />
                                <Typography>{templateValue + 1} / {jobTemplateData?.length}</Typography>
                                <KeyboardArrowRightIcon sx={{ cursor: "pointer" }} onClick={() => { templateValue + 1 !== jobTemplateData?.length && setTemplateValue(templateValue + 1) }} />
                            </Box>
                        </Box>
                    )}
                </Drawer>
            </ClickAwayListener>
            
            <Popup
                title={jobTemplatedata?.industry_type_id ? "Edit JD Template" : "Add JD Template"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                close={false}
                maxWidth="lg"
                fullWidth={true}
            >
                <>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                                <FormControl fullWidth>
                                <TextField
                                        isOptionEqualToValue={(option, value) =>
                                            option?.code === value?.code
                                        }
                                        disabled={true}
                                        size="small"
                                        value={jobTemplatedata?.industry_name?.industry_name}
                                        fullWidth
                                        label="Industry Sector"
                                        name="industry_name"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoFocus={true}
                                        required
                                        size="small"
                                        value={jobTemplatedata?.job_title}
                                        onChange={(e) => handleChange(e)}
                                        variant="outlined"
                                        fullWidth
                                        id="job_title"
                                        label="Job Title"
                                        name="job_title"
                                        {...(errors.job_title && {
                                            error: true,
                                            helperText: errors.job_title,
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={6} >
                                <Typography>Job Description*</Typography>
                                <Quill name='description' value={description} setValue={setDescription} error={Boolean(errors.description)} style={{ height: "300px" }} />
                                {errors?.description && <Typography sx={{ color: "red", paddingLeft: "20px", paddingTop: 6 }}>{errors?.description}</Typography>}
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Typography>Roles and Responsibilities*</Typography>
                                <Quill name='roles_responsibilities' value={rolesresponsibilities} setValue={setRolesResponsibilities} style={{ height: "300px" }} />
                                {errors?.roles_responsibilities && <Typography sx={{ color: "red", paddingLeft: "20px", paddingTop: 6 }}>{errors?.roles_responsibilities}</Typography>}
                            </Grid>
                            <Grid container sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                mt: 3
                            }}>
                                <Button
                                    sx={{ ":hover": { color: grey[50] }, marginTop: "1.75rem", marginRight: "1rem" }}
                                    variant="outlined"
                                    className="mpl-secondary-btn"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                                <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1.75rem", marginRight: "1rem" }}>
                                    {jobTemplatedata?.industry_code ? "Update" : "Submit"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            </Popup>
        </>
    );
};

export default AtsJobTemplatePreview;
