import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup, isMasterProduct, fullWidth, maxWidth, close = true, setIndex, setInputData, setErrors, hideDividers } = props;

  const handle = () => {
    if (setIndex) {
      setIndex(() => "")
      setInputData({ user: "", note: "" })
      setErrors(() => "")
    }

  }
  return (
    <Dialog
      sx={{ ".MuiDialog-paper": { borderRadius: "10px" } }}
      open={openPopup}
      maxWidth={maxWidth ? maxWidth : isMasterProduct ? "md" : "sm"}
      fullWidth={fullWidth}
      classes={{
        paper: {
          padding: "2px",
          position: "absolute",
          top: "5px",
        },
      }
      }
      id={title}
    >
      <DialogTitle sx={{ paddingBottom: "0px", paddingTop: "7px" }}>
        <div style={{ display: "flex" }}>
          <Typography variant="h5" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {
            close &&
            <IconButton
              onClick={() => {
                setOpenPopup(false)
                handle()
              }}
              sx={{ m: 0, p: .5, pt: 0 }}
            >
              <CloseIcon color="action" />
            </IconButton>
          }
        </div>
      </DialogTitle>
      {hideDividers ? (
        <DialogContent>{children}</DialogContent>
      ) : (
        <DialogContent dividers>{children}</DialogContent>
      )}

    </Dialog >
  );
}
