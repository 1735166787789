
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddSubBusinessUnitAction, UpdateSubBusinessUnitAction, DeleteSubBusinessUnitAction } from "../../../../../store/Ancillary/SubBusinessUnit/Action";

const SubBusinessUnit = () => {
    const dispatch = useDispatch()
    const { subBusinessunitData } = useSelector(state => state?.AncillaryReducer?.SubBusinessUnitReducer);
    const { businessunitData } = useSelector(state => state.AncillaryReducer?.BusinessUnitReducer);

    return (
        <Box>
            <MaterialTable
                columns={[
                    {
                        title: "Name", cellStyle: { padding: "0px 10px" }, field: "name", validate: rowData => rowData?.name === '' || rowData?.name === undefined ? 'Name cannot be empty' : '',
                        editComponent: props => (
                            <TextField
                                required={true}
                                variant="standard"
                                value={props?.value}
                                onChange={e => props.onChange(e.target.value)}
                                error={props?.error}
                                helperText={props?.helperText}
                            />
                        )
                    },
                    {
                        title: "Business Unit", cellStyle: { padding: "0px 10px" }, field: "businessunit", validate: rowData => {
                            if (rowData?.businessunit) {
                                return ''
                            } else {
                                return 'Business Unit cannot be empty'
                            }
                        },
                        editComponent: props => {
                            return (
                                <Autocomplete
                                    defaultValue={{ name: props.rowData?.businessunit || "", id: props.rowData?.businessunit_id || "" }}
                                    id="businessunit_id"
                                    options={businessunitData}
                                    onChange={(e, data) => { props.onChange(data) }}
                                    size="small"
                                    required={true}
                                    getOptionLabel={option => { return option?.name }}
                                    renderInput={(params) => <TextField size="small" {...params}
                                        variant="standard"
                                        label="Business Unit"
                                        error={props?.error}
                                        helperText={props?.helperText} />}
                                />
                            )
                        }
                    }
                ]}
                data={subBusinessunitData || []}
                icons={{
                    Add: () => <AddIcon />,
                    Edit: () => <EditIcon />,
                    Delete: () => <DeleteIcon />
                }}
                editable={{
                    onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(AddSubBusinessUnitAction(newRow))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(UpdateSubBusinessUnitAction(newRow))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowDelete: (selectedRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(DeleteSubBusinessUnitAction({ subbusinessunit_id: `{${selectedRow?.subbusinessunit_id}}` }))
                            setTimeout(() => resolve(), 1000);
                        }),
                }}
                options={{
                    maxBodyHeight: 220,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                    sorting: true,
                    search: false,
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: false,
                    selection: false,
                    actionsColumnIndex: -1,
                    grouping: false,
                    addRowPosition: "first",
                }}
                title=""
            />
        </Box>
    );
}
export default SubBusinessUnit;