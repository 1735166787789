
import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: true,
    success: false,
    error: false,
};

const MeeplAdminUserReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MEEPL_ADMIN_GET_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                userData: []
            };
        case ActionTypes.MEEPL_ADMIN_GET_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: false,
                userData: action.payload?.data?.data
            };
        case ActionTypes.MEEPL_ADMIN_GET_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
            };
        case ActionTypes.MEEPL_ADMIN_ADD_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.MEEPL_ADMIN_ADD_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: false,
                userData: action.payload?.data?.data
            };
        case ActionTypes.MEEPL_ADMIN_ADD_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
            };
        case ActionTypes.MEEPL_ADMIN_UPDATE_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.MEEPL_ADMIN_UPDATE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: false,
                userData: action.payload?.data?.data
            };
        case ActionTypes.MEEPL_ADMIN_UPDATE_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
            };
        default:
            return state;
    }
}

export default MeeplAdminUserReducer;