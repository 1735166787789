import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField, InputAdornment } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useState } from 'react';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from 'react-redux';
import { changePasswordHelper, signOut } from "../../SignIn/helper/SigninHelper";
import IconButton from "@mui/material/IconButton";
import history from "../../../../history";

const ChangePasswordPopup = (props) => {
    const { setOpenPopup } = props;
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const sessionData = sessionStorage.getItem("userManagement");
    const AdminEmail = sessionStorage.getItem("AdminEmail");
    const userid = sessionStorage.getItem("userid");
    const [inputData, setInputData] = useState({
        old_password: "",
        new_password: "",
        confirm_password: "",
    });
    const handleInputChange = (e) => {
        e.preventDefault();
        setInputData({
            ...inputData,
            [e.target.name]: e.target.value,
        });
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
            validate({ ...inputData, [e.target.name]: e.target.value });
    };
    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("new_password" in fieldValues) {
            temp.new_password =
                fieldValues.new_password === "" ? "New Password is required" : "";
        }
        if ("old_password" in fieldValues) {
            temp.old_password =
                fieldValues.old_password === "" ? "Old Password is required" : "";
        }
        if ("confirm_password" in fieldValues) {
            temp.confirm_password =
                fieldValues.confirm_password === ""
                    ? "Confirm Password is required"
                    : fieldValues?.new_password !== fieldValues?.confirm_password
                        ? "Confirm Password is not match with New Password"
                        : "";
        }
        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };
    const handleChangePassword = () => {
        if (validate(inputData)) {
            setLoading(true);
            changePasswordHelper({
                domain: sessionData,
                userid: userid,
                email_id: AdminEmail,
                old_password: inputData?.old_password,
                password: inputData?.new_password,
            }).then((res) => {
                setLoading(false);
                if (res?.data?.statuscode === 200) {
                    setOpenPopup(false);
                    setTimeout(() => {
                        handleLogout();
                    }, 1500);
                }
            });
        }
    };
    const handleLogout = () => {
        dispatch({ type: "USER_LOGOUT" });
        signOut();
        history.push("/");
    };
    return (
        <>
            <Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id="old_password"
                        label="Old Password"
                        name="old_password"
                        type={showOldPassword ? "text" : "password"}
                        autoComplete="old_password"
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        tabIndex={-1}
                                        aria-label="toggle old_password visibility"
                                        onClick={() => {
                                            setShowOldPassword(!showOldPassword);
                                        }}
                                    >
                                        {showOldPassword ? (
                                            <VisibilityIcon fontSize="small" />
                                        ) : (
                                            <VisibilityOffIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        {...(errors.old_password && {
                            error: true,
                            helperText: errors.old_password,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id="new_password"
                        label="New Password"
                        name="new_password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new_password"
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        tabIndex={-1}
                                        aria-label="toggle new_password visibility"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                    >
                                        {showPassword ? (
                                            <VisibilityIcon fontSize="small" />
                                        ) : (
                                            <VisibilityOffIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        {...(errors.new_password && {
                            error: true,
                            helperText: errors.new_password,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id="confirm_password"
                        label="Confirm Password"
                        name="confirm_password"
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="confirm_password"
                        onChange={handleInputChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        tabIndex={-1}
                                        aria-label="toggle new_password visibility"
                                        onClick={() => {
                                            setShowConfirmPassword(!showConfirmPassword);
                                        }}
                                    >
                                        {showConfirmPassword ? (
                                            <VisibilityIcon fontSize="small" />
                                        ) : (
                                            <VisibilityOffIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        {...(errors.confirm_password && {
                            error: true,
                            helperText: errors.confirm_password,
                        })}
                    />
                </Grid>
                <Grid container display="flex" justifyContent="flex-end">
                    <Button
                        sx={{
                            ":hover": { color: grey[50] },
                            marginTop: "1.75rem",
                            marginRight: "1rem",
                        }}
                        variant="outlined"
                        className="mpl-secondary-btn"
                        onClick={() => setOpenPopup(false)}
                    >
                        cancel
                    </Button>
                    {loading ? (
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            size="small"
                        >
                            Change Password
                        </LoadingButton>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="mpl-primary-btn"
                            sx={{ marginTop: "1.75rem" }}
                            onClick={handleChangePassword}
                        >
                            Change Password
                        </Button>
                    )}
                </Grid>
            </Grid>

        </>
    );
}

export default ChangePasswordPopup;
