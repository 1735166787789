// Tax Declaration
export const TAX_DECLARATION_GET_REQUEST = "TAX_DECLARATION_GET_REQUEST";
export const TAX_DECLARATION_GET_SUCCESS = "TAX_DECLARATION_GET_SUCCESS";
export const TAX_DECLARATION_GET_ERROR = "TAX_DECLARATION_GET_ERROR";

export const TAX_DECLARATION_ADD_REQUEST = "TAX_DECLARATION_ADD_REQUEST";
export const TAX_DECLARATION_ADD_SUCCESS = "TAX_DECLARATION_ADD_SUCCESS";
export const TAX_DECLARATION_ADD_ERROR = "TAX_DECLARATION_ADD_ERROR";

export const TAX_DECLARATION_UPDATE_REQUEST = "TAX_DECLARATION_UPDATE_REQUEST";
export const TAX_DECLARATION_UPDATE_SUCCESS = "TAX_DECLARATION_UPDATE_SUCCESS";
export const TAX_DECLARATION_UPDATE_ERROR = "TAX_DECLARATION_UPDATE_ERROR";

// Employee Profile
export const EMPLOYEE_PROFILE_PAYROLL_GET_REQUEST = "EMPLOYEE_PROFILE_PAYROLL_GET_REQUEST";
export const EMPLOYEE_PROFILE_PAYROLL_GET_SUCCESS = "EMPLOYEE_PROFILE_PAYROLL_GET_SUCCESS";
export const EMPLOYEE_PROFILE_PAYROLL_GET_ERROR = "EMPLOYEE_PROFILE_PAYROLL_GET_ERROR";

// Salary Structure
export const SALARY_STRUCTURE_GET_REQUEST = "SALARY_STRUCTURE_GET_REQUEST";
export const SALARY_STRUCTURE_GET_SUCCESS = "SALARY_STRUCTURE_GET_SUCCESS";
export const SALARY_STRUCTURE_GET_ERROR = "SALARY_STRUCTURE_GET_ERROR";

export const SALARY_STRUCTURE_ADD_REQUEST = "SALARY_STRUCTURE_ADD_REQUEST";
export const SALARY_STRUCTURE_ADD_SUCCESS = "SALARY_STRUCTURE_ADD_SUCCESS";
export const SALARY_STRUCTURE_ADD_ERROR = "SALARY_STRUCTURE_ADD_ERROR";

export const SALARY_STRUCTURE_UPDATE_REQUEST = "SALARY_STRUCTURE_UPDATE_REQUEST";
export const SALARY_STRUCTURE_UPDATE_SUCCESS = "SALARY_STRUCTURE_UPDATE_SUCCESS";
export const SALARY_STRUCTURE_UPDATE_ERROR = "SALARY_STRUCTURE_UPDATE_ERROR";

export const SALARY_STRUCTURE_DELETE_REQUEST = "SALARY_STRUCTURE_DELETE_REQUEST";
export const SALARY_STRUCTURE_DELETE_SUCCESS = "SALARY_STRUCTURE_DELETE_SUCCESS";
export const SALARY_STRUCTURE_DELETE_ERROR = "SALARY_STRUCTURE_DELETE_ERROR";

//Salary Components
export const SALARY_COMPONENT_GET_REQUEST = "SALARY_COMPONENT_GET_REQUEST";
export const SALARY_COMPONENT_GET_SUCCESS = "SALARY_COMPONENT_GET_SUCCESS";
export const SALARY_COMPONENT_GET_ERROR = "SALARY_COMPONENT_GET_ERROR";

export const SALARY_COMPONENT_ADD_REQUEST = "SALARY_COMPONENT_ADD_REQUEST";
export const SALARY_COMPONENT_ADD_SUCCESS = "SALARY_COMPONENT_ADD_SUCCESS";
export const SALARY_COMPONENT_ADD_ERROR = "SALARY_COMPONENT_ADD_ERROR";

export const SALARY_COMPONENT_UPDATE_REQUEST = "SALARY_COMPONENT_UPDATE_REQUEST";
export const SALARY_COMPONENT_UPDATE_SUCCESS = "SALARY_COMPONENT_UPDATE_SUCCESS";
export const SALARY_COMPONENT_UPDATE_ERROR = "SALARY_COMPONENT_UPDATE_ERROR";

export const SALARY_COMPONENT_DELETE_REQUEST = "SALARY_COMPONENT_DELETE_REQUEST";
export const SALARY_COMPONENT_DELETE_SUCCESS = "SALARY_COMPONENT_DELETE_SUCCESS";
export const SALARY_COMPONENT_DELETE_ERROR = "SALARY_COMPONENT_DELETE_ERROR";

// Salary Component Types
export const SALARY_COMPONENT_TYPE_GET_REQUEST = "SALARY_COMPONENT_TYPE_GET_REQUEST";
export const SALARY_COMPONENT_TYPE_GET_SUCCESS = "SALARY_COMPONENT_TYPE_GET_SUCCESS";
export const SALARY_COMPONENT_TYPE_GET_ERROR = "SALARY_COMPONENT_TYPE_GET_ERROR";
