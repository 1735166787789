import { Box, Paper, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import Base from '../../../Base/views/Base'
import AtsUserGrid from './AtsUserGrid'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetATSModuleFeaturesAction } from '../../../../../store/RoleAccess/ATSModuleFeatures/Action';
import { useEffect } from 'react';
import { getUserData } from '../../../Base/helper/baseFunctions';

const AtsUserLandingPage = () => {
    const user = getUserData();
    const isGroupOrgFlowTrue = user?.grouporg_flow === true;
    const dispatch = useDispatch();
    const location = useLocation();
    const [value, setValue] = useState(0);
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)

    useEffect(() => {
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Manage" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Paper sx={{ borderRadius: "10px", maxHeight: "87vh", overflowY: "auto" }}>
                        {children}
                    </Paper>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Base>
            <Box sx={{ borderRadius: "10px", overflowY: "auto" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ mb: 1 }}>
                    <Tab label="Internal" {...a11yProps(0)} />
                    <Tab label= { isGroupOrgFlowTrue ? "Group User" :"Client" }{...a11yProps(1)} />
                    <Tab label="Hiring Partner" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <AtsUserGrid user_type={"Internal"} featuresData={featuresData} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AtsUserGrid user_type={isGroupOrgFlowTrue ? "Group User" :"Company"} featuresData={featuresData} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <AtsUserGrid user_type={"Consultancy"} featuresData={featuresData} />
                </TabPanel>
            </Box>
        </Base >
    )
}

export default AtsUserLandingPage