import { toast } from "react-toastify";
import NProgress from 'nprogress';
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetAtsConsultancyHelper = async () => {
    var user = getUserData();
    toast.dismiss()
    NProgress.start()
    return await axiosInstance(`/ats/getConsultancy`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const AddAtsConsultancyHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addConsultancy`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_emp_id: user?.ats_user_id,
            name: params?.name,
            address: params?.address,
            contact_person: params?.contact_person,
            mobile_number: +params?.mobile_number || null,
            office_number: params?.office_number,
            country_id: +params?.country_id?.country_id || null,
            state_id: +params?.state_id?.state_id || null,
            city_id: +params?.city_id?.city_id || null,
            zip_code: params?.zip_code,
            website: params?.website,
            primary_owner: params?.primary_owner,
            company_lead_id: params?.company_lead_id,
            business_unit_id: +params?.business_unit_id || null,
            email_id: params?.email_id,
            status: params?.status
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Hiring Partner added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsConsultancyHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateConsultancy`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_emp_id: user?.ats_user_id,
            consultancy_id: params?.consultancy_id,
            name: params?.name,
            address: params?.address,
            contact_person: params?.contact_person,
            mobile_number: +params?.mobile_number || null,
            office_number: params?.office_number,
            country_id: params?.country_id?.country_id || null,
            state_id: params?.state_id?.state_id || null,
            city_id: params?.city_id?.city_id || null,
            zip_code: params?.zip_code,
            website: params?.website,
            primary_owner: params?.primary_owner,
            company_lead_id: params?.company_lead_id,
            business_unit_id: params?.business_unit_id,
            email_id: params?.email_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Hiring Partner Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteAtsConsultancyHelper = async (params) => {
    var user = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/ats/deleteConsultancy`, {
        method: "DELETE",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            consultancy_id: params?.consultancy_id,
            int_user: params?.int_user,
            status: params?.status
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}