import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";

export const GetPaygradeHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getPaygrades`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddPaygradeHelper = async (paygrade) => {
    const data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addPaygrade`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            name: paygrade?.name,
            pay_grade_code: paygrade?.pay_grade_code,
            pay_structure: paygrade?.pay_structure?.sal_structure_id,
            pay_scale: paygrade?.pay_scale,
            min_pay_pmon: paygrade?.min_pay_pmon,
            max_pay_pmon: paygrade?.max_pay_pmon,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Paygrade Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdatePaygradeHelper = async (paygrade) => {
    const data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updatePaygrade`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            paygrade_id: paygrade?.paygrade_id,
            name: paygrade?.name,
            pay_grade_code: paygrade?.pay_grade_code,
            pay_structure: typeof paygrade?.pay_structure === "object" ? paygrade?.pay_structure?.sal_structure_id : paygrade?.sal_structure_id,
            pay_scale: paygrade?.pay_scale,
            min_pay_pmon: paygrade?.min_pay_pmon,
            max_pay_pmon: paygrade?.max_pay_pmon,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Paygrade Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeletePaygradeHelper = async (paygrade) => {
    const data = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deletePaygrade`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            paygrade_id: paygrade?.paygrade_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Paygrade Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}