import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";
import { Box, Grid, Card, Typography, Skeleton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import { GetAtsJobPostByIdHelper } from "../../helper/JobPostHelper";
import { useParams } from "react-router-dom";
import NotFound from "../../../Base/views/NotFound";
import { BASE_URL } from "../../../../Constants/baseConstant";
import { GetCompanyLogoHelper } from "../../../Ancillary/CompanyDetails/helper/CompanyDetailsHelper";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { s3Client } from "../../../Base/helper/baseFunctions";

const CareePortalJobs = () => {
  const [jobdata, setJobData] = useState([]);
  const { domain, jobid } = useParams();
  const decDomain = atob(domain);
  const domainname = decDomain?.split("=")?.[1];
  const decjobId = atob(jobid);
  const decjobsId = decjobId.replace(/[[\]']/g, "");
  const [isLoading, setisLoading] = useState(true);
  
  const [imageUrl, setImageUrl] = useState("");
  const [notfound, setNotfound] = useState(false);
  useEffect(() => {
    setisLoading(true);
    GetAtsJobPostByIdHelper({
      domain: domainname,
      ats_jobpost_id: `{${decjobsId}}`,
    }).then((res) => {
      if (res.hasError === true) {
        setisLoading(true);
        setNotfound(true);
      } else {
        setNotfound(false);
        setisLoading(false);
        setJobData(res.data.data);
      }
    });
    GetCompanyLogoHelper({ domain: domainname }).then((res) => {
      res?.data?.data?.[0]?.companylogo && getImageSignedUrl(res?.data?.data?.[0]?.companylogo);
    })
    .catch((err) => {
      console.log(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
    }
  };
  const handleLinkClick = (id) => {
    const url = `${BASE_URL}/ats/career-portal-job/${btoa(id)}/${btoa(
      decDomain
    )}`;
    window.open(url, "_blank");
  };
  return (
    <>
      {notfound === true && <NotFound />}
      <UnauthorizedBase imageUrl={imageUrl} companyName= {jobdata?.[0]?.company_full_name}>
        {isLoading === true ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Grid container item xs={12} sm={10} lg={10} spacing={4} mb>
                {Array(4)
                  .fill()
                  .map((d, i) => (
                    <Grid key={i} item xs={6}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={570}
                        height={275}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                mt: 1,
                padding:"0px 16px 16px 16px",
                display: "flex",
                flex: "1",
                justifyContent: "space-around",
                background: "#ffffff",
              }}
            >
              <Grid container direction="row" alignItems="center">
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography className="workspace_name" variant="h3">
                    Job Board
                  </Typography>
                </Grid>
                {jobdata?.map((job, index) => (
                  <Grid
                    className="Card-Job"
                    onClick={() => handleLinkClick(job?.ats_jobpost_id)}
                    key={index}
                    item
                    p={1.5}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <Card key={index}>
                      <Grid container p={4}>
                        <Grid item xs={12} md={6} lg={7}>
                          <Typography
                            className="Job_title"
                            sx={{ mb: 1 }}
                            variant="h5"
                          >
                            {job?.title}
                          </Typography>
                        </Grid>
                        <Grid
                          className="job-type-main"
                          item
                          xs={12}
                          md={6}
                          lg={5}
                          pl={2}
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "baseline",
                          }}
                        >
                          <Typography className="job-type" variant="h6">
                            {job?.job_type || "Full Time"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container pb={4}>
                        <Grid container pl={4}>
                          <Grid
                            sx={{
                              mb: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                            item
                            xs={12}
                            md={6}
                            lg={7}
                          >
                            <RadioButtonCheckedRoundedIcon
                              color="primary"
                              sx={{ mr: 1 }}
                              fontSize="8"
                            />
                            <Typography className="Sub-header-job" variant="p">
                              Location : &nbsp;
                            </Typography>
                            <Tooltip
                              title={
                                job?.job_location === null
                                  ? "Not Available"
                                  : ""
                              }
                            >
                              <Typography variant="p">
                                {job?.job_location || "NA"}
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid
                            className="sub-content-values"
                            sx={{ display: "flex", alignItems: "center" }}
                            item
                            xs={12}
                            md={6}
                            lg={5}
                          >
                            {job?.min_salary !== null ||
                            job?.max_salary !== null ? (
                              <>
                                <RadioButtonCheckedRoundedIcon
                                  color="primary"
                                  sx={{ mr: 1 }}
                                  fontSize="8"
                                />
                                <Typography
                                  className="Sub-header-job"
                                  variant="p"
                                >
                                  Salary : &nbsp;
                                </Typography>
                                {job?.min_salary !== null && (
                                  <Typography variant="p">
                                    {job?.min_salary}
                                  </Typography>
                                )}
                                {job?.min_salary !== null &&
                                  job?.max_salary !== null &&
                                  "-"}
                                {job?.max_salary !== null && (
                                  <Typography variant="p">
                                    {job?.max_salary}
                                  </Typography>
                                )}
                              </>
                            ) : null}

                            {job?.min_salary === null &&
                            job?.max_salary === null &&
                            (job?.min_exp_required !== null ||
                              job?.max_exp_required !== null) ? (
                              <>
                                <RadioButtonCheckedRoundedIcon
                                  color="primary"
                                  sx={{ mr: 1 }}
                                  fontSize="8"
                                />
                                <Typography
                                  className="Sub-header-job"
                                  variant="p"
                                >
                                  Experience : &nbsp;
                                </Typography>
                                {job?.min_exp_required !== null && (
                                  <Typography variant="p">
                                    {job?.min_exp_required}
                                  </Typography>
                                )}
                                {job?.min_exp_required !== null &&
                                  job?.max_exp_required !== null &&
                                  "-"}
                                {job?.max_exp_required !== null && (
                                  <Typography variant="p">
                                    {job?.max_exp_required}
                                  </Typography>
                                )}
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container pl={4}>
                          <Grid
                            className="sub-content-values"
                            sx={{ display: "flex", alignItems: "center" }}
                            item
                            xs={12}
                            md={6}
                            lg={7}
                          >
                            <RadioButtonCheckedRoundedIcon
                              color="primary"
                              sx={{ mr: 1 }}
                              fontSize="8"
                            />
                            <Typography className="Sub-header-job" variant="p">
                              Vacancies : &nbsp;
                            </Typography>
                            <Tooltip
                              title={
                                job?.vaccancies === null ? "Not Available" : ""
                              }
                            >
                              <Typography variant="p">
                                {job?.vaccancies || "NA"}
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid
                            className="sub-content-values"
                            sx={{ display: "flex", alignItems: "center" }}
                            item
                            xs={12}
                            md={6}
                            lg={5}
                          >
                            {job?.min_salary !== null ||
                            job?.max_salary !== null ? (
                              <>
                                {(job?.min_exp_required !== null ||
                                  job?.max_exp_required !== null) && (
                                  <>
                                   <RadioButtonCheckedRoundedIcon
                                      color="primary"
                                      sx={{ mr: 1 }}
                                      fontSize="8"
                                    />
                                    <Typography
                                      className="Sub-header-job"
                                      variant="p"
                                    >
                                      Experience : &nbsp;
                                    </Typography>
                                    {job?.min_exp_required !== null && (
                                      <Typography variant="p">
                                        {job?.min_exp_required}
                                      </Typography>
                                    )}
                                    {job?.min_exp_required !== null &&
                                      job?.max_exp_required !== null &&
                                      "-"}
                                    {job?.max_exp_required !== null && (
                                      <Typography variant="p">
                                        {job?.max_exp_required}
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
      </UnauthorizedBase>
    </>
  );
};

export default CareePortalJobs;
