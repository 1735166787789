import React from "react";
import Contactus from "../views/Contact/ContactUs";
const Contact = () =>{

        return(
        <>
                <Contactus />
        </>

        );

}
export default Contact;