import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

// Roles
export const GetRoleHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/user/getRoles`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then(res => {
            toast.dismiss();
            res?.data?.data?.length === 0 && toast.warning("No Records Found")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

// Modules
export const GetModuleHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/user/getModules`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const GetATSModuleHelper = async (params) => {
    return await axiosInstance(`/user/getATSModules`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain,
            user_id: params?.userid,
            user_type:params?.user_type,
            enable_ats: params?.enable_ats,
            enable_lms: params?.enable_lms,
            enable_corehr: params?.enable_corehr
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const GetATSModuleFeaturesHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/user/getATSModuleFeatures`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            module_name: params?.module_name,
            role_id: data?.role_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}


// Sub Modules
export const GetSubModuleHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/user/getSubModules`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

// Sub Permission
export const GetPermissionHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/user/getPermissions`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

// Role Access
export const GetRoleAccessHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/user/getRoleAccess`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddRoleAccessHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/user/addRoleAccess`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data.employee_id,
            role: params?.role_id,
            permission_id: params?.permission_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Role Access Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateRoleAccessHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/user/updateRoleAccess`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            role_id: params?.role_id,
            update: params?.update,
            permission_id: params?.permission_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Role Access Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteRoleAccessHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/user/deleteRoleAccess`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            role_sm_permission_mapping_id: params?.role_sm_permission_mapping_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Role Access Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const GetUserRolesHelper = async (params) => {
    return await axiosInstance(`/user/getUserRoles`, {
        method: "GET",
        baseURL: HRMS_UTILITY_API_URL,
        data: {}
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}