import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Fab, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonDatePicker from '../../../Base/views/CommonDatePicker';
import { Add, Delete } from '@mui/icons-material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    ActionSummaryStyle: {
        marginTop: "1%",
        minHeight: 50,
        maxHeight: 50,
        borderRadius: "10px 10px 0px 0px",
        '&.Mui-expanded': {
            minHeight: 50,
            maxHeight: 50,
            backgroundColor: '#76548B',
            color: "white",
        }
    }
}));


const TDStep4 = ({ handleInputChange, disableEdit = false, housePropertyObj, step4, setStep4, activeStep, setActiveStep }) => {
    const classes = useStyles();
    const maxHousePropertyList = 1
    const [expanded, setExpanded] = useState(0);
    const [errors, setErrors] = useState([]);
    const [numberOfHouseProperty, setNumberOfHouseProperty] = useState();

    function handleChange(event, index) {
        let newArr = [...step4];
        let item = newArr[index];
        item = { ...item, [event.target.name]: event.target.value };
        newArr[index] = item;
        setStep4(newArr);
    }
    const validate = () => {
        step4?.forEach((hp, i) => {
            const error = {};
            if (!hp.address) error.address = "Full Address is required";
            if (!hp.city) error.city = "City is required";
            if (!hp.status)
                error.status = "Status is required";
            if (!hp.loan_sanc_date) error.loan_sanc_date = "Load Sanctioned Date is required";
            if (!hp.lender_name) error.lender_name = "Lender Name is required";
            if (!hp.lender_type) error.lender_type = "Lender Type is required";
            if (!hp.possession_date) error.possession_date = "Possession Date is required";
            if (!hp.lender_pan) error.lender_pan = "PAN Lender is required";
            if (!hp.principal_amount) error.principal_amount = "Principal Amount is required";
            if (!hp.interest_amount) error.interest_amount = "Interest Amount is required";
            const temp = errors;
            temp[i] = error;
            setErrors([...temp])
        })
        let hasError = []
        errors.forEach((err) => {
            if (!Object.values(err).every((e) => e === "")) {
                hasError.push(true)
            }
        })
        return !hasError.includes(true)
    };

    const handleSaveHouseProperty = () => {
        if (validate()) {
            handleInputChange({ target: { name: "tds4", value: step4 } })
            setActiveStep(activeStep + 1)
        }
    };

    const onAddHouseProperty = () => {
        setNumberOfHouseProperty(numberOfHouseProperty + 1);
        let objDetail = JSON.parse(JSON.stringify(housePropertyObj));
        setStep4(step4.concat(JSON.parse(JSON.stringify([objDetail]))));
    };

    const onRemoveHouseProperty = (e, index) => {
        const list = [...step4];
        list.splice(index, 1);
        setStep4([...list]);
        const errorList = [...errors];
        errorList.splice(index, 1);
        setErrors([...errorList]);
    };

    return (
        <>
            <Box>
                {
                    !disableEdit &&
                    <Box display="flex" justifyContent={"space-between"}>
                        <Typography variant='h6'>Section 24 - Income / Lose on House Property, additional deduction under Section 80EE / 80EEA </Typography>
                        {step4 && step4.length <= maxHousePropertyList &&
                            <Box mb={1}>
                                <Button
                                    onClick={onAddHouseProperty}
                                    variant="contained"
                                    size="medium"
                                    startIcon={<Add />}
                                    className='mpl-primary-btn'
                                >
                                    Add House Property &nbsp;&nbsp;
                                </Button>
                            </Box>
                        }
                    </Box>
                }
                {step4?.map((hp, i) => (
                    <Accordion key={i} expanded={i === expanded} onChange={e => i === expanded ? setExpanded("") : setExpanded(i)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.ActionSummaryStyle}
                        >
                            <Typography variant='body1' fontWeight={600}>House Property {i + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} sm={4} p={1}>
                                    <TextField
                                        required
                                        autoFocus={true}
                                        size='small'
                                        autoComplete='first_name'
                                        onChange={(event) => { handleChange(event, i); }}
                                        name='address'
                                        label='Full Address'
                                        fullWidth
                                        disabled={disableEdit}
                                        value={hp?.address || ""}
                                        {...(errors[i] &&
                                            errors[i].address && {
                                            error: true,
                                            helperText: errors[i].address,
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <TextField
                                        required
                                        size='small'
                                        autoComplete='city'
                                        onChange={(event) => { handleChange(event, i); }}
                                        name='city'
                                        label='City'
                                        fullWidth
                                        disabled={disableEdit}
                                        value={hp?.city || ""}
                                        {...(errors[i] &&
                                            errors[i].city && {
                                            error: true,
                                            helperText: errors[i].city,
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <TextField
                                        required
                                        size='small'
                                        autoComplete='status'
                                        onChange={(event) => { handleChange(event, i); }}
                                        name='status'
                                        label='Status'
                                        fullWidth
                                        disabled={disableEdit}
                                        value={hp?.status || ""}
                                        {...(errors[i] &&
                                            errors[i].status && {
                                            error: true,
                                            helperText: errors[i].status,
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <FormControl fullWidth >
                                        <CommonDatePicker
                                            size="small"
                                            name="loan_sanc_date"
                                            label="Loan Sanctioned Date"
                                            pickerType="date"
                                            pickerFormat="dd/MM/yyyy"
                                            DefaultDTvalue={hp?.loan_sanc_date}
                                            disabled={disableEdit}
                                            handleChange={(value) => handleChange({ target: { name: "loan_sanc_date", value: `${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}` } }, i)}
                                            {...(errors[i] &&
                                                errors[i].loan_sanc_date && {
                                                error: true,
                                                helperText: errors[i].loan_sanc_date,
                                            })}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <TextField
                                        size='small'
                                        autoComplete='lender_name'
                                        onChange={(event) => { handleChange(event, i); }}
                                        name='lender_name'
                                        label='Name of Lender'
                                        required={true}
                                        fullWidth
                                        disabled={disableEdit}
                                        value={hp?.lender_name || ""}
                                        {...(errors[i] &&
                                            errors[i].lender_name && {
                                            error: true,
                                            helperText: errors[i].lender_name,
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <FormControl fullWidth size="small" error={errors[i]?.lender_type ? true : false}>
                                        <InputLabel id="lender-type-select-small" required={true} >Type of Lender</InputLabel>
                                        <Select
                                            labelId="lender-type-select-small"
                                            name="lender_type"
                                            id="lender-type-select-small"
                                            value={hp?.lender_type || ""}
                                            label="Type Of Lender"
                                            disabled={disableEdit}
                                            onChange={(event) => { handleChange(event, i); }}
                                        >
                                            <MenuItem value="0">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value='Bank / Housing FI'>Bank / Housing FI</MenuItem>
                                            <MenuItem value='Employer'>Employer</MenuItem>
                                            <MenuItem value='Others'>Others</MenuItem>
                                        </Select>
                                        <FormHelperText>
                                            {errors[i]?.lender_type ? errors[i].lender_type : ""}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <FormControl fullWidth >
                                        <CommonDatePicker
                                            size="small"
                                            name="possession_date"
                                            label="Possession Date"
                                            pickerType="date"
                                            required={true}
                                            pickerFormat="dd/MM/yyyy"
                                            DefaultDTvalue={hp?.possession_date}
                                            disabled={disableEdit}
                                            handleChange={(value) => handleChange({ target: { name: "possession_date", value: `${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}` } }, i)}
                                            {...(errors[i] &&
                                                errors[i].possession_date && {
                                                error: true,
                                                helperText: errors[i].possession_date,
                                            })}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <TextField
                                        size='small'
                                        autoComplete='lender_pan'
                                        onChange={(event) => { handleChange(event, i); }}
                                        name='lender_pan'
                                        required={true}
                                        label='PAN Lender'
                                        fullWidth
                                        disabled={disableEdit}
                                        value={hp?.lender_pan || ""}
                                        {...(errors[i] &&
                                            errors[i].lender_pan && {
                                            error: true,
                                            helperText: errors[i].lender_pan,
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <TextField
                                        required
                                        size='small'
                                        autoComplete='principal_amount'
                                        onChange={(event) => { handleChange(event, i); }}
                                        name='principal_amount'
                                        label='Principal Amount'
                                        fullWidth
                                        type="number"
                                        disabled={disableEdit}
                                        value={hp?.principal_amount || ""}
                                        {...(errors[i] &&
                                            errors[i].principal_amount && {
                                            error: true,
                                            helperText: errors[i].principal_amount,
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} p={1}>
                                    <TextField
                                        required
                                        size='small'
                                        autoComplete='interest_amount'
                                        onChange={(event) => { handleChange(event, i); }}
                                        name='interest_amount'
                                        label='Interest Amount'
                                        type="number"
                                        fullWidth
                                        disabled={disableEdit}
                                        value={hp?.interest_amount || ""}
                                        {...(errors[i] &&
                                            errors[i].interest_amount && {
                                            error: true,
                                            helperText: errors[i].interest_amount,
                                        })}
                                    />
                                </Grid>
                            </Grid>
                            <Box display={"flex"} justifyContent="space-between">
                                <Typography p={1}><span style={{ fontWeight: 600 }}>Note:</span> Amount of Principal repayment will be considered for deduction under Section 80C (maximum of Rs. 1.50 lakhs)</Typography>
                                <Fab
                                    id={hp.id}
                                    onClick={(e) => {
                                        onRemoveHouseProperty(e, i);
                                    }}
                                    size="small"
                                    color="error"
                                    aria-label="delete"
                                >
                                    <Delete />
                                </Fab>
                            </Box>

                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
            {
                !disableEdit &&
                <Box sx={{ display: "flex", justifyContent: "space-between", bottom: 30 }}>
                    {activeStep !== 0 &&
                        <Button
                            sx={{ marginRight: "10px", mt: 5 }}
                            type="submit"
                            variant="outlined"
                            size="medium"
                            onClick={() => setActiveStep(activeStep - 1)}
                        >
                            Back
                        </Button>
                    }
                    {activeStep < 5 &&
                        <Button
                            sx={{ marginRight: "10px", mt: 5, }}
                            size="medium"
                            variant="contained"
                            className="mpl-primary-btn"
                            onClick={() => handleSaveHouseProperty()}
                        >
                            Next
                        </Button>
                    }
                </Box>
            }
        </>

    )
}

export default TDStep4