import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: true,
    isSuccess: false,
    isError: false,
    employeeProfile_data: []
}

export const EmployeeProfileReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            }
        case ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                employeeProfile_data: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state
    }
}