import * as ActionTypes from '../ActionTypes';

export const MeeplAdminGetUserAction = () => {
    return {
        type: ActionTypes.MEEPL_ADMIN_GET_USER_REQUEST,
    }
}

export const MeeplAdminAddUserAction = (params) => {
    return {
        type: ActionTypes.MEEPL_ADMIN_ADD_USER_REQUEST,
        params
    }
}

export const MeeplAdminUpdateUserAction = (params) => {
    return {
        type: ActionTypes.MEEPL_ADMIN_UPDATE_USER_REQUEST,
        params
    }
}
