import { Box, Card, Divider, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetIndustryTypeAction } from "../../../../store/ATS/IndustryType/Action";
import { GetSkillsAction } from "../../../../store/ATS/Skills/Action";
import { GetJobTemplateAction } from "../../../../store/ATS/JobTemplate/Action";
import { NoData } from '../../Base/views/NotAuthorized';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GetEmploymentTypeAction } from '../../../../store/Ancillary/EmploymentType/Action';
import { GetDocumentationsAction } from '../../../../store/Ancillary/Documentations/Action';
import { GetFormTemplateAction } from '../../../../store/ATS/FormTemplate/Action';
// import { GetAtsDesignationAction } from '../../../../store/ATS/Designation/Action';


const Dashboard = ({ setValue, setAccordian }) => {

    const { industryTypeData, isLoading } = useSelector(state => state.AtsReducer?.IndustryTypeReducer);
    const { skillsData } = useSelector(state => state.AtsReducer?.SkillsReducer);
    const { jobTemplateData } = useSelector(state => state.AtsReducer?.JobTemplateReducer);
    // const { ats_designation_options } = useSelector(state => state?.AtsReducer?.AtsDesignationReducer);
    const { employment_type_options } = useSelector(state => state?.AncillaryReducer?.EmploymentTypeReducer);
    const { documentations_options } = useSelector(state => state.AncillaryReducer?.DocumentationsReducer);
    const { templateData } = useSelector((state) => state.AtsReducer?.FormTemplateReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetIndustryTypeAction());
        dispatch(GetSkillsAction({ industry_type_id: null }))
        dispatch(GetJobTemplateAction({ industry_type_id: null }))
        // dispatch(GetAtsDesignationAction());
        dispatch(GetEmploymentTypeAction());
        dispatch(GetDocumentationsAction());
        dispatch(GetFormTemplateAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (accordian) => {
        if (['Industry Sector', 'Skills', 'JD Template'].includes(accordian)) {
            setValue("2");
            setAccordian(accordian)
        }
        else if (accordian === "Form Builder") {
            setValue("4");
            setAccordian(accordian)
        }
        else {
            setValue("3");
            setAccordian(accordian)
        }
    }

    const data = [
        { title: "Industry Sector", data: industryTypeData || [], field_name: "industry_name" },
        { title: "Skills", data: skillsData || [], field_name: "skill" },
        { title: "JD Template", data: jobTemplateData || [], field_name: "job_title" },
        // { title: "Designation", data: ats_designation_options || [], field_name: "name" },
        { title: "Employment Type", data: employment_type_options || [], field_name: "etype_name" },
        { title: "Documentations", data: documentations_options || [], field_name: "proof_name" },
        { title: "Form Builder", data: templateData || [], field_name: "template_name" },
    ]

    return (
        <>
            <Grid container sx={{ padding: "10px" }} className="hr-settings">
                {
                    data?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick(res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item?.[res?.field_name]}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    );
}

export default Dashboard;
