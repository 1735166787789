import NProgress from 'nprogress';
import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from '../../Base/helper/baseFunctions';

// Employee Dashboard Data
export const GetEmployeeDashboardHelper = async (data) => {
    NProgress.start();
    return await axiosInstance(`/user/getEmployeeDashboard`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            emp_id: data?.emp_id
        }
    })
        .then((res) => {
            NProgress.done();
            toast.dismiss();
            return { message: "Dashboard Data Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done();
            toast.dismiss();
            return { message: "Unable to fetch Dashboard Data", hasError: false, data: err?.response?.data }
        })
}

// Employees Birthday Data
export const GetBirthdaysHelper = async (data) => {
    return await axiosInstance(`/user/getBirthdays`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Birthday Data Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch Birthday Data", hasError: false, data: err?.response?.data }
        })
}

export const GetHomeHelper = async (data) => {
    var userData = getUserData();
    NProgress.start();
    return await axiosInstance(`/user/getHome`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain || userData?.domain,
            emp_id: data?.emp_id || parseInt(userData?.emp_users_fk) || parseInt(userData?.emp_users_pk)
        }
    })
        .then((res) => {
            NProgress.done();
            toast.dismiss();
            return { message: "Dashboard Data Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done();
            toast.dismiss();
            return { message: "Unable to fetch Dashboard Data", hasError: false, data: err?.response?.data }
        })
}

export const GetQuickLinkHelper = async (data) => {
    var userData = getUserData();
    NProgress.start();
    return await axiosInstance(`/user/quickLinks`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain || userData?.domain,
            emp_id: data?.emp_id || parseInt(userData?.emp_users_fk)
        }
    })
        .then((res) => {
            NProgress.done();
            toast.dismiss();
            return { message: "Dashboard Data Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done();
            toast.dismiss();
            return { message: "Unable to fetch Dashboard Data", hasError: false, data: err?.response?.data }
        })
}
