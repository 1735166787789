import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    DeleteSuccess:"",
    success: false,
};

export const IndustryTypeReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MEEPL_ADMIN_INDUSTRY_TYPE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                DeleteSuccess:""
            };
        case ActionTypes.INDUSTRY_TYPE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                DeleteSuccess:""
            };
        case ActionTypes.INDUSTRY_TYPE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                industryTypeData: action.payload?.data?.data
            };
        case ActionTypes.INDUSTRY_TYPE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.INDUSTRY_TYPE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                DeleteSuccess:""
            };
        case ActionTypes.INDUSTRY_TYPE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                industryTypeData: action.payload?.data?.data
            };
        case ActionTypes.INDUSTRY_TYPE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.INDUSTRY_TYPE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                DeleteSuccess:""
            };
        case ActionTypes.INDUSTRY_TYPE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                industryTypeData: action.payload?.data?.data
            };
        case ActionTypes.INDUSTRY_TYPE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.INDUSTRY_TYPE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                DeleteSuccess:""
            };
        case ActionTypes.INDUSTRY_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                DeleteSuccess:action.payload?.data?.statuscode,
                industryTypeData: action.payload?.data?.data
            };
        case ActionTypes.INDUSTRY_TYPE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}