import {
  AppBar,
  Box,
  Link,
  Toolbar,
  Typography,
  ListItemIcon,
} from "@mui/material";
import React from "react";
import history from "../../../../history";
import { useState } from "react";
import { FullLogo } from "../../../../svg/Logo";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import LockResetIcon from "@mui/icons-material/LockReset";
import Logout from "@mui/icons-material/Logout";
import { signOut } from "../../SignIn/helper/SigninHelper";
import Popup from "../../Base/views/Popup";
import ChangePasswordPopup from "../../SignIn/views/ChangePasswordPopup";

const Header = ({ imageUrl, companyName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const sessionData = sessionStorage.getItem("userManagement");

  const handleLogout = () => {
    signOut();
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="static" sx={{ background: "none" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ height: "60px" }} onClick={() => history.push("/")}>
            <Link sx={{ cursor: "pointer" }}>
              {imageUrl ? (
                <img src={imageUrl} alt="Logo" height={60} />
              ) : (
                <FullLogo />
              )}
            </Link>
          </Box>
          {companyName ? (
            <Box sx={{ marginRight:"120px"}}>
              <Typography className="workspace_name" variant="h3">
                {companyName}
              </Typography>
            </Box>
          ) : null}
          {sessionData === "meepladmin" ? (
            <MenuIcon cursor="pointer" onClick={handleMenu} />
          ) : (
            <Box></Box>
          )}
          {sessionData === "meepladmin" ? (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ marginTop: "2.5rem" }}
            >
              <MenuItem onClick={() => setOpenPopup(true)}>
                <ListItemIcon>
                  <LockResetIcon fontSize="small" />
                </ListItemIcon>
                Change Password
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Signout
              </MenuItem>
            </Menu>
          ) : (
            <></>
            // <Typography>
            //   Have a meepl account already?
            //   <Link
            //     sx={{ cursor: "pointer" }}
            //     onClick={() => history.push("/signup")}
            //   >
            //     Sign In
            //   </Link>
            // </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Popup
        title={"Change Password"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidth="xs"
        fullWidth={true}
      >
        <ChangePasswordPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
};

export default Header;
