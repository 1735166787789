import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetBusinessUnitHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getBusinessUnits`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddBusinessUnitHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addBusinessUnit`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data.employee_id,
            name: params?.name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Business Unit Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateBusinessUnitHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateBusinessUnit`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data.employee_id,
            businessunit_id: params?.businessunit_id,
            name: params?.name
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Business Unit Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteBusinessUnitHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteBusinessUnit`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            businessunit_id: params?.businessunit_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Business Unit Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}