import React from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Switch, TextField } from "@mui/material";
import { Form } from "../../../Base/views/ModalPopUpForm"
import { useState } from 'react';
import { grey } from '@mui/material/colors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddLeaveTypeAction, UpdateLeaveTypeAction } from '../../../../../store/Leave/LeaveType/Action';
import SelectableSearch from '../../../Base/views/SelectableSearch';

const LeaveTypesPopup = ({ editedRecord, setOpenPopup }) => {
    const { leavetypes_options } = useSelector(state => state.LeaveReducer?.LeaveTypeReducer);
    const isAnyCfwdLeaveTrue = leavetypes_options?.some(leave => leave.cfwd_leave === true);
    const dispatch = useDispatch();
    const [leaveTypes, setLeaveTypes] = useState({
        name: "",
        code: "",
        actual_days: "",
        req_second_approval: false,
        cfwd_leave: false,
        gender_based: false,
        is_unlimited: false,
        noof_cfwd_days: "",
        gender: "",
        colour_code: "#de4fcd",
    });

    const [errors, setErrors] = useState({});
    const [genderOptions, setGenderOptions] = useState({ load: true, values: [] })

    useEffect(() => {
        if (editedRecord != null) {
            setLeaveTypes({ ...editedRecord, gender: { id: 0, name: editedRecord?.gender } });
        }
    }, [editedRecord])

    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("name" in fieldValues) {
            temp.name = fieldValues.name === "" ? "Name is required" : "";
        }
        if ("code" in fieldValues) {
            temp.code = fieldValues.code === "" ? "Code is required" : "";
        }
        if ("actual_days" in fieldValues) {
            temp.actual_days = fieldValues.actual_days === "" ? "Allotted Days is required" :
                fieldValues?.actual_days?.toString().match(/^[0-9]{1,3}$/)
                    ? ""
                    : "Invalid Allotted Days.";
        }
        if ("req_second_approval" in fieldValues) {
            temp.req_second_approval = fieldValues.req_second_approval === "" ? "Request Second Approval is required" : "";
        }
        if (leaveTypes?.cfwd_leave && "noof_cfwd_days" in fieldValues) {
            temp.noof_cfwd_days = fieldValues.noof_cfwd_days === "" ? "Days is required" :
                fieldValues?.noof_cfwd_days?.toString().match(/^[0-9]{1,3}$/)
                    ? ""
                    : "Invalid Percentage.";
        }

        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };

    const handleInputChange = (event, value) => {
        if (["gender"].includes(event)) {
            if (value) {
                setLeaveTypes({ ...leaveTypes, [event]: value });
            } else {
                setLeaveTypes({ ...leaveTypes, [event]: "" });
            }
        } else if (["req_second_approval", "is_unlimited"].includes(event.target.name)) {
            setLeaveTypes({ ...leaveTypes, [event.target.name]: event.target.checked });
        } else if (event.target.name === "gender_based") {
            if (event.target.checked === false) setLeaveTypes({ ...leaveTypes, [event.target.name]: event.target.checked, gender: "" });
            else setLeaveTypes({ ...leaveTypes, [event.target.name]: event.target.checked });
        } else if (event.target.name === "cfwd_leave") {
            if (event.target.checked) setLeaveTypes({ ...leaveTypes, [event.target.name]: event.target.checked });
            else {
                setLeaveTypes({ ...leaveTypes, [event.target.name]: event.target.checked, noof_cfwd_days: "" });
                setErrors({ ...errors, noof_cfwd_days: "" })
            }
        } else {
            setLeaveTypes({ ...leaveTypes, [event.target.name]: event.target.value });
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...leaveTypes, [event.target.name]: event.target.value })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (event.type === "submit" || event.which === 13) {
            if (validate(leaveTypes)) {
                if (leaveTypes?.leavetype_id) dispatch(UpdateLeaveTypeAction(leaveTypes));
                else dispatch(AddLeaveTypeAction(leaveTypes));
                setOpenPopup(false);
            }
        }
    }

    const genderLoading = () => {
        setGenderOptions({ load: false, values: [{ id: 1, name: "Male" }, { id: 2, name: "Female" }] })
    }

    return (
        <>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Grid container>
                    <Grid container>
                        <Grid key="name" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={leaveTypes?.name}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    autoFocus={true}
                                    id="name"
                                    label="Name"
                                    name="name"
                                    {...(errors.name && {
                                        error: true,
                                        helperText: errors.name,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="code" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={leaveTypes?.code}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    autoFocus={true}
                                    id="code"
                                    label="Code"
                                    name="code"
                                    {...(errors.code && {
                                        error: true,
                                        helperText: errors.code,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="actual_days" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={leaveTypes?.actual_days}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    autoFocus={true}
                                    id="actual_days"
                                    label="Allotted Days"
                                    name="actual_days"
                                    {...(errors.actual_days && {
                                        error: true,
                                        helperText: errors.actual_days,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="colour_code" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth>
                                <TextField
                                    type={"color"}
                                    size="small"
                                    value={leaveTypes?.colour_code}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    autoFocus={true}
                                    id="colour_code"
                                    label="Colour Code"
                                    name="colour_code"
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="cfwd_leave" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl
                                component="fieldset"
                                variant="standard"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="cfwd_leave"
                                            checked={leaveTypes?.cfwd_leave}
                                            onChange={handleInputChange}
                                            disabled={isAnyCfwdLeaveTrue}
                                        />}
                                    label="Carry Forward Leave?"
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="gender_based" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl
                                component="fieldset"
                                variant="standard"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="gender_based"
                                            checked={leaveTypes?.gender_based}
                                            onChange={handleInputChange}
                                        />}
                                    label="Gender based leave?"
                                />
                            </FormControl>
                        </Grid>
                        {
                            leaveTypes?.cfwd_leave &&
                            <Grid key="noof_cfwd_days" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={leaveTypes?.noof_cfwd_days}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        fullWidth
                                        id="noof_cfwd_days"
                                        label="Carry Forward Days"
                                        placeholder='EX: 60'
                                        name="noof_cfwd_days"
                                        {...(errors.noof_cfwd_days && {
                                            error: true,
                                            helperText: errors.noof_cfwd_days,
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        {
                            leaveTypes?.gender_based &&
                            <Grid key="gender" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                                <FormControl fullWidth>
                                    <SelectableSearch
                                        label="Gender"
                                        value={leaveTypes?.gender}
                                        loading={genderLoading}
                                        fieldLabel="name"
                                        autoFocus={false}
                                        required={false}
                                        variant={"outlined"}
                                        name="gender"
                                        size="small"
                                        onChangeEvent={handleInputChange}
                                        data={genderOptions}
                                        {...(errors.gender && {
                                            error: true,
                                            helperText: errors.gender,
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid key="req_second_approval" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>

                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch
                                        checked={leaveTypes?.req_second_approval}
                                        name="req_second_approval"
                                        onChange={handleInputChange}
                                    />}
                                    label="Request Second Approval"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid key="is_unlimited" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>

                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch
                                        checked={leaveTypes?.is_unlimited}
                                        name="is_unlimited"
                                        onChange={handleInputChange}
                                    />}
                                    label="Unlimited Leaves?"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}>
                        <Button
                            sx={{ marginTop: "1rem", marginRight: "1rem", ":hover": { color: grey[50] } }}
                            variant="outlined"
                            onClick={() => setOpenPopup(false)}
                            className="mpl-secondary-btn"
                        >
                            cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1rem", marginRight: "1rem" }}>
                            {editedRecord ? "Update" : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </>
    );
}

export default LeaveTypesPopup;
