import * as ActionTypes from '../ActionTypes';

const initialState = {
    success_message: "",
    error: false,
    city_options: [],
    cityList_options: [],
    holiday_calendar_city_options: []
};

const CityReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CITY_LIST_GET_REQUEST:
            return {
                ...state,
                cityList_options: []
            };
        case ActionTypes.CITY_LIST_GET_SUCCESS:
            return {
                ...state,
                success_message: action.payload.message,
                error: action.payload.has_error,
                cityList_options: action.payload?.data?.data
            };
        case ActionTypes.HOLIDAY_CALENDAR_CITY_GET_REQUEST:
            return {
                ...state,
                holiday_calendar_city_options: []
            };
        case ActionTypes.HOLIDAY_CALENDAR_CITY_GET_SUCCESS:
            return {
                ...state,
                success_message: action.payload.message,
                error: action.payload.has_error,
                holiday_calendar_city_options: action.payload?.data?.data
            };
        default:
            return state;
    }
}

export default CityReducer;