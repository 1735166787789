import { toast } from "react-toastify";
import React from "react";
import { GoogleLogin } from "react-google-login";
import LogIn from "../../images/open-enrollment.gif";
import { Grid, Typography } from "@mui/material"
import ErrorIcon from '@mui/icons-material/Error';
import { CLIENT_ID, SCOPES } from "../../../../Constants/baseConstant";

const Alert = () => {
  const containerStyle = {
    backgroundColor: "#e0f7ff",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 15px",
    color: "#000",
    position: "relative",
    borderBottom:"1px solid #007bff"
  };

  const infoIconStyle = {
    color: "#007bff",
    marginRight: "10px",
    fontSize: "18px",
  };

  const textStyle = {
    margin: 0,
    flex: 1,
    color:"#1b1c21"
  };

  return (
    <div style={containerStyle}>
      <span style={infoIconStyle}><ErrorIcon /></span>
      <p style={textStyle}>
      Please note that events created in Meepl Calendar will be in sync with your Google Calendar. However events created / modified directly in Google Calendar will not reflect in your Meepl Calendar. 
      </p>
    </div>
  );
};
const LoginForm = (props) => {
  const { setOpenPopup, setAccessGranted } = props;
  const onSuccess = (response) => {
    localStorage.setItem("accessTokenforGoogle", response.accessToken);
    localStorage.setItem("GoogleUserInfo", JSON.stringify(response.profileObj));
    setAccessGranted(true);
    setOpenPopup(false);
    toast.success("Login success");
  };

  const onFailure = (response) => {
    toast.error("Login failed");
  };
  return (
    <>
    <Grid container spacing={2} justifyContent="center" alignItems="center"  flexDirection="column">
      <Grid item>
        <img
          src={LogIn}
          alt="Loading..."
          width={"100px"}
          height={"100px"}
          style={{
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Grid>
      <Grid item>
        <Alert />
      </Grid>
      <Grid item>
       <Typography fontWeight="bold">* Google API Services Limited Use Disclosure</Typography>
       <Typography fontSize="12px">Meepl's use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank"  rel="noreferrer" style={{ color:"blue"}}>Google API Services User Data Policy</a>, including the Limited Use requirements.</Typography>
      </Grid>
      <Grid item>
        <GoogleLogin
          clientId={CLIENT_ID}
          buttonText="Login with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          scope={SCOPES}
        />
      </Grid>  
    </Grid>
    </>
  );
};

export default LoginForm;
