/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetEmploymentTypeAction } from "../../../../../store/Ancillary/EmploymentType/Action";
import { GetPaygradeAction } from "../../../../../store/Ancillary/Paygrade/Action";
import {
  GetEmployeeWorkDetailAction,
  AddEmployeeWorkDetailAction,
  UpdateEmployeeWorkDetailAction,
} from "../../../../../store/Employee/WorkDetail/Action";
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import { GetCostCenterAction } from "../../../../../store/Ancillary/CostCenter/Action";
import { getUserData } from "../../../Base/helper/baseFunctions";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { createStyles, makeStyles } from "@mui/styles";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import history from "../../../../../history";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { GetWorkLocationAction } from "../../../../../store/Ancillary/WorkLocation/Action";
import { AddWorkLocationHelper } from "../../../Ancillary/WorkLocation/helper/WorkLocation";
import CreatableSearch from "../../../Base/views/CreatableSearch";

export default function WorkDetailsPage({
  OnboardEmployee,
  setCurrentMode,
  OnboardingEmpData,
  currentMode,
  newdata,
  setActiveStep,
}) {
  const { atsModuleData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleReducer
  );
  const roleAccess = atsModuleData?.find(
    (item) => item.module_name === "My Employee"
  );
  const [disableEdit, setdisableEdit] = useState(
    currentMode === "addemployee" ? false : true
  );
  const EditButton = roleAccess ? true : false;
  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
      },
    })
  );
  const classes = useStyles();
  const userData = getUserData();
  const initialValues = {
    employmenttype_id: "",
    paygrade_id: "",
    costcenter_id: "",
    dorejoining: null,
    phy_disabled: false,
    phy_disabilities: "",
    international_worker: false,
    contractor: "",
    contract_from: null,
    contract_to: null,
    hrmanager_id: "",
    ats_work_location_id: "",
    pf_no: "",
    esi_no: "",
  };
  const dispatch = useDispatch();
  const { employees_options } = useSelector(
    (state) => state.EmployeeReducer.EmployeesReducer
  );
  const { employment_type_options } = useSelector(
    (state) => state.AncillaryReducer.EmploymentTypeReducer
  );
  const { cost_center_options } = useSelector(
    (state) => state.AncillaryReducer.CostCenterReducer
  );
  const { isLoading, workData } = useSelector(
    (state) => state?.EmployeeReducer?.EmployeeWorkDetailReducer
  );
  const { workLocation_options } = useSelector(
    (state) => state?.AncillaryReducer?.WorkLocationReducer
  );
  const [employeesData, setEmployeesData] = useState({
    load: true,
    values: [],
  });
  const [costCenterData, setCostCentereData] = useState({
    load: true,
    values: [],
  });
  const [employmentTypeData, setEmploymentTypeData] = useState({
    load: true,
    values: [],
  });
  const [worklocationData, setWorklocationData] = useState({
    load: false,
    values: [],
  });
  const [errors, setErrors] = useState({});
  const [workDetails, setWorkDetails] = useState(initialValues);
  const [openCancel, setOpenCancel] = useState(false);
  const [backList, setBackList] = useState(false);
  const [backStepper, setBackStepper] = useState(false);
  const [candidateEditedData, setCandidateEditedData] = useState();
  useEffect(() => {
    if (currentMode === "add") {
      setdisableEdit(false);
    } else {
      setdisableEdit(true);
    }
    isLoading && setWorkDetails(initialValues);
    dispatch(GetEmployeesAction({ is_active: true }));
    dispatch(GetWorkLocationAction());
    dispatch(GetEmploymentTypeAction());
    dispatch(GetCostCenterAction());
    dispatch(GetPaygradeAction());
    if (currentMode !== "addemployee") {
      if (OnboardingEmpData) {
        dispatch(
          GetEmployeeWorkDetailAction({
            domain: userData?.domain,
            employee_id: OnboardingEmpData?.employee_id,
          })
        );
      } else {
        dispatch(
          GetEmployeeWorkDetailAction({
            domain: userData?.domain,
            employee_id: userData?.employee_id,
          })
        );
      }

      if (workData?.[0]) {
        setWorkDetails({
          ...workData?.[0],
          domain: userData?.domain,
          employee_id: workData?.[0]?.employee_id,
          employmenttype_id: {
            employmenttype_id: workData?.[0]?.employmenttype_id,
            etype_name: workData?.[0]?.employmenttype,
          },
          paygrade_id: {
            paygrade_id: workData?.[0]?.paygrade_id,
            name: workData?.[0]?.paygrade,
          },
          costcenter_id: {
            costcenter_id: workData?.[0]?.costcenter_id,
            name: workData?.[0]?.costcenter,
          },
          hrmanager_id: {
            employee_id: workData?.[0]?.hrmanager_id,
            name: workData?.[0]?.hrmanager,
          },
          ats_work_location_id: workData?.[0]?.ats_work_location_id
          ? {
            ats_work_location_id: workData?.[0]?.ats_work_location_id,
            work_location_name: workData?.[0]?.work_location_name,
            }
          : "",
        });
        setCandidateEditedData({
          ...workData?.[0],
          domain: userData?.domain,
          employee_id: workData?.[0]?.employee_id,
          employmenttype_id: {
            employmenttype_id: workData?.[0]?.employmenttype_id,
            etype_name: workData?.[0]?.employmenttype,
          },
          paygrade_id: {
            paygrade_id: workData?.[0]?.paygrade_id,
            name: workData?.[0]?.paygrade,
          },
          costcenter_id: {
            costcenter_id: workData?.[0]?.costcenter_id,
            name: workData?.[0]?.costcenter,
          },
          hrmanager_id: {
            employee_id: workData?.[0]?.hrmanager_id,
            name: workData?.[0]?.hrmanager,
          },
          ats_work_location_id: workData?.[0]?.ats_work_location_id
          ? {
            ats_work_location_id: workData?.[0]?.ats_work_location_id,
            work_location_name: workData?.[0]?.work_location_name,
            }
          : "",
        });
      } else {
        setWorkDetails({ ...initialValues });
      }
    }
  }, []);
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("ats_work_location_id" in fieldValues) {
      temp.ats_work_location_id =
        fieldValues.ats_work_location_id === "" || null
          ? "Work Location is required"
          : "";
    }
    if ("pf_no" in fieldValues) {
      temp.pf_no =
          fieldValues.pf_no === "" || fieldValues.pf_no === null
              ? ""
              : fieldValues.pf_no.toString() !== "" &&
                !fieldValues.pf_no.toString().match(/^[A-Z]{2}\/[A-Z]{3}\/\d{7}\/\d{3}\/\d{7}$/)
              ? "Invalid EPF Number."
              : "";
  }
  if ("esi_no" in fieldValues) {
    temp.esi_no =
        fieldValues.esi_no === "" || fieldValues.esi_no === null
            ? ""
            : fieldValues.esi_no.toString() !== "" &&
            !fieldValues.esi_no.toString().match(/^\d{17}$/)
            ? "Invalid ESI Number."
            : "";
}
    setErrors({
      ...temp,
    });
    const temp1 = { ...temp };
    return Object.values(temp1).every((x) => x === "");
  };

  const handleEdit = () => {
    setdisableEdit(false);
  };

  const handleInputChange = (event, value) => {
    if (["paygrade_id", "costcenter_id", "hrmanager_id"].includes(event)) {
      if (value) {
        setWorkDetails({ ...workDetails, [event]: value });
      } else {
        setWorkDetails({ ...workDetails, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event]: value ? value : "" });
    } else if (
      ["ats_work_location_id"].includes(
        event
      )
    ) {
        if (value?.id === null) {
          AddWorkLocationHelper({ name: value?.work_location_name }).then((res) => {
            setWorklocationData({ load: false, values: res?.data?.data });
            setWorkDetails({
              ...workDetails,
              [event]: res?.data?.data?.filter(
                (resp) => resp?.work_location_name === value?.work_location_name
              )?.[0],
            });
          });
        } else setWorkDetails({ ...workDetails, [event]: value });
      
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event]: value ? value : "" });
    }
    else if (event === "employmenttype_id") {
      if (value) {
        setWorkDetails({
          ...workDetails,
          [event]: value,
          contractor: "",
          contract_from: "",
          contract_to: "",
        });
      } else {
        setWorkDetails({
          ...workDetails,
          [event]: "",
          contractor: "",
          contract_from: "",
          contract_to: "",
        });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event]: value ? value : "" });
    } else if (["international_worker"].includes(event?.target?.name)) {
      setWorkDetails({
        ...workDetails,
        [event.target.name]: event.target.checked,
      });
    } else if (event.target.name === "phy_disabled") {
      setWorkDetails({
        ...workDetails,
        [event.target.name]: event.target.checked,
        phy_disabilities: "",
      });
    } else {
      setWorkDetails({
        ...workDetails,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = (event, next) => {
    if (validate(workDetails)) {
      if (currentMode === "editemployee" && OnboardingEmpData) {
        dispatch(
          UpdateEmployeeWorkDetailAction({
            ...workDetails,
            employee_id: OnboardingEmpData?.employee_id,
          })
        );
      } else if (currentMode === "addemployee") {
        dispatch(
          AddEmployeeWorkDetailAction({
            ...workDetails,
            employee_id: newdata?.employee_id,
          })
        );
      } else {
        dispatch(
          UpdateEmployeeWorkDetailAction({
            ...workDetails,
            employee_id: userData?.employee_id,
          })
        );
      }
      if (!OnboardEmployee) {
        setdisableEdit(true);
      }
      if (next === "Next") {
        setActiveStep(2);
      }
    }
  };

  const handleEmployees = () => {
    const filteredData = employees_options.filter((employee) => {
      return (
        employee.employee_id !== userData?.employee_id ||
        userData?.core_hr_user_type === "Owner"
      );
    });
    employees_options &&
      setEmployeesData({ load: false, values: filteredData });
  };
  const handleEmploymentType = () => {
    employment_type_options &&
      setEmploymentTypeData({ load: false, values: employment_type_options });
  };
  const handleCostCenter = () => {
    cost_center_options &&
      setCostCentereData({ load: false, values: cost_center_options });
  };
  const NextStepper = (e, next) => {

    if (
      !disableEdit &&
      workDetails?.employee_id &&
      JSON.stringify(workDetails) !== JSON.stringify(candidateEditedData)
    ) {
      handleSubmit(e, next);
    } else setActiveStep(2);
  };
  const BackStepper = () => {
    setBackStepper(true);
    if (
      !disableEdit &&
      workDetails?.employee_id &&
      JSON.stringify(workDetails) !== JSON.stringify(candidateEditedData)
    ) {
      setOpenCancel(true);
    } else setActiveStep(0);
  };
  const handleCancel = () => {
    if (backList) {
      history.push("/myemployee");
    } else if (backStepper) {
      setActiveStep(0);
    } else {
      setActiveStep(2);
    }
  };
  const handleBackList = () => {
    setBackList(true);
    if (
      !disableEdit &&
      workDetails?.employee_id &&
      JSON.stringify(workDetails) !== JSON.stringify(candidateEditedData)
    ) {
      setOpenCancel(true);
    } else history.push("/myemployee");
  };
  return (
    <>
      <Box sx={{ width: "100%", padding: OnboardEmployee ? "0px 0px 20px 25px" : "0px 30px 20px 30px" }}>
        <Grid
          container
          sx={{ minHeight: "87vh", maxHeight: "87vh", overflowY: "auto" }}
        >
          {!OnboardEmployee && (
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "10px",
                marginTop: "20px",
              }}
            >
              <Box>
                <Button
                  sx={{ marginRight: "10px" }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  className="mpl-primary-btn"
                  onClick={BackStepper}
                >
                  Back
                </Button>

                {currentMode !== undefined ? (
                  <Button
                    sx={{ marginRight: "10px" }}
                    type="submit"
                    variant="contained"
                    className="mpl-primary-btn"
                    size="medium"
                    onClick={handleBackList}
                  >
                    Back to List
                  </Button>
                ) : null}
              </Box>
              <Typography variant="h6">Work Details</Typography>
              <Box>
                {currentMode === "addemployee" && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={(e) => handleSubmit(e)}
                    className="mpl-primary-btn"
                  >
                    Save
                  </Button>
                )}
                {disableEdit && EditButton && currentMode !== "addemployee" && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={(e) => handleEdit(e)}
                    className="mpl-primary-btn"
                  >
                    Edit
                  </Button>
                )}
                {!disableEdit && currentMode !== "addemployee" && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={(e) => handleSubmit(e)}
                    className="mpl-primary-btn"
                  >
                    Save
                  </Button>
                )}
                <Button
                  sx={{ marginLeft: "10px" }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={(e) => NextStepper(e, "Next")}
                  className="mpl-primary-btn"
                >
                  Next
                </Button>
              </Box>
            </Grid>
          )}

          {OnboardEmployee && (
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "10px",
                marginTop: "20px",
              }}
            >
              <Box>
                <Button
                  sx={{ marginRight: "10px" }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  className="mpl-primary-btn"
                  onClick={BackStepper}
                >
                  Back
                </Button>
              </Box>
              <Typography variant="h6">Work Details</Typography>
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={(e) => handleSubmit(e)}
                  className="mpl-primary-btn"
                >
                  Save
                </Button>

                <Button
                  sx={{ marginLeft: "10px" }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={(e) => NextStepper(e, "Next")}
                  className="mpl-primary-btn"
                >
                  Next
                </Button>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={12} sx={{ padding: "24px 0px 24px 0px" }}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                Project Details
              </Typography>
              <Grid pt={2.5} container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SelectableSearch
                      disabled={disableEdit}
                      label="Cost Center"
                      value={workDetails?.costcenter_id}
                      loading={() => handleCostCenter()}
                      fieldLabel="name"
                      autoFocus={true}
                      required={false}
                      variant={"outlined"}
                      name="costcenter_id"
                      size="small"
                      onChangeEvent={handleInputChange}
                      data={costCenterData}
                      {...(errors.costcenter_id && {
                        error: true,
                        helperText: errors.costcenter_id,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SelectableSearch
                      disabled={disableEdit}
                      label="HR Manager"
                      value={workDetails?.hrmanager_id}
                      loading={() => handleEmployees()}
                      fieldLabel="name"
                      variant={"outlined"}
                      name="hrmanager_id"
                      size="small"
                      onChangeEvent={handleInputChange}
                      data={employeesData}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                <FormControl fullWidth>
                <CreatableSearch
                  disabled={disableEdit}
                  required
                  label="Work Location"
                  value={workDetails?.ats_work_location_id}
                  loading={() =>
                    setWorklocationData({
                      load: false,
                      values: workLocation_options,
                    })
                  }
                  fieldLabel="work_location_name"
                  variant={"contained"}
                  name="ats_work_location_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={worklocationData}
                  showAddOption={true}
                  OptionName="Type to add new Work Location"
                  {...(errors.ats_work_location_id && {
                    error: true,
                    helperText: errors.ats_work_location_id,
                  })}
                />
                 </FormControl>
              </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                Employment Details
              </Typography>
              <Grid pt={2.5} container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <SelectableSearch
                      disabled={disableEdit}
                      label="Type of Employment"
                      value={workDetails?.employmenttype_id}
                      loading={() => handleEmploymentType()}
                      fieldLabel="etype_name"
                      required={false}
                      variant={"outlined"}
                      name="employmenttype_id"
                      size="small"
                      onChangeEvent={handleInputChange}
                      data={employmentTypeData}
                      {...(errors.employmenttype_id && {
                        error: true,
                        helperText: errors.employmenttype_id,
                      })}
                    />
                  </FormControl>
                </Grid>
               
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={disableEdit}
                      label="EPF Number"
                      name="pf_no"
                      onChange={handleInputChange}
                      value={workDetails?.pf_no}
                      size="small"
                      {...(errors.pf_no && {
                        error: true,
                        helperText: errors.pf_no,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={disableEdit}
                      label="ESI Number"
                      name="esi_no"
                      onChange={handleInputChange}
                      value={workDetails?.esi_no}
                      size="small"
                      {...(errors.esi_no && {
                        error: true,
                        helperText: errors.esi_no,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disableEdit}
                        name="international_worker"
                        checked={workDetails?.international_worker || false}
                        onChange={handleInputChange}
                      />
                    }
                    label="International Worker?"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disableEdit}
                        name="phy_disabled"
                        checked={workDetails?.phy_disabled || false}
                        onChange={handleInputChange}
                      />
                    }
                    label="Physically Challenged/Disabled?"
                  />
                </Grid>
                {workDetails?.phy_disabled && (
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={disableEdit}
                        label="Disabilities"
                        name="phy_disabilities"
                        onChange={handleInputChange}
                        value={workDetails?.phy_disabilities || ""}
                        size="small"
                        {...(errors?.phy_disabilities && {
                          error: true,
                          helperText: errors.phy_disabilities,
                        })}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          {workDetails?.employmenttype_id?.etype_name === "On Contract" && (
            <Grid item xs={12} md={12} padding={1.5}>
              <Paper className={classes.paper}>
                <Typography variant="h6" gutterBottom>
                  Type of Employement - Contract
                </Typography>
                <Grid pt={2.5} container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={disableEdit}
                        label="Contractor"
                        name="contractor"
                        required
                        onChange={handleInputChange}
                        value={workDetails?.contractor}
                        size="small"
                        {...(errors?.contractor && {
                          error: true,
                          helperText: errors.contractor,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <CommonDatePicker
                        disabled={disableEdit}
                        maxDate={new Date()}
                        size="small"
                        name="contract_from"
                        label="Contract Date From"
                        pickerType="date"
                        onKeyPress={(e) => e.preventDefault()}
                        onKeyDown={(e) => e.preventDefault()}
                        pickerFormat="dd/MM/yyyy"
                        DefaultDTvalue={workDetails?.contract_from}
                        handleChange={(value) =>
                          handleInputChange({
                            target: {
                              name: "contract_from",
                              value: `${
                                value?.getFullYear() +
                                "-" +
                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + value?.getDate()).slice(-2)
                              }`,
                            },
                          })
                        }
                        {...(errors.contract_from && {
                          error: true,
                          helperText: errors.contract_from,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <CommonDatePicker
                        disabled={disableEdit}
                        minDate={
                          workDetails?.contract_from
                            ? new Date(workDetails.contract_from)
                            : ""
                        }
                        size="small"
                        name="contract_to"
                        label="Contract Date To"
                        pickerType="date"
                        onKeyPress={(e) => e.preventDefault()}
                        onKeyDown={(e) => e.preventDefault()}
                        pickerFormat="dd/MM/yyyy"
                        DefaultDTvalue={workDetails?.contract_to}
                        handleChange={(value) =>
                          handleInputChange({
                            target: {
                              name: "contract_to",
                              value: `${
                                value?.getFullYear() +
                                "-" +
                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + value?.getDate()).slice(-2)
                              }`,
                            },
                          })
                        }
                        {...(errors.contract_to && {
                          error: true,
                          helperText: errors.contract_to,
                        })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
      <ConfirmationDialog
        title="Unsaved Changes"
        content="You have some unsaved changes. Please click on Save before proceeding"
        openDialog={openCancel}
        closeDialog={setOpenCancel}
        popupTitle="Consultancy"
        OkButtonText="Proceed without Save"
        CancelButtonText="OK"
        onSubmit={handleCancel}
      />
    </>
  );
}
