import * as ActionTypes from '../ActionTypes';

export const GetStudyLevelAction = () => {
    return {
        type: ActionTypes.STUDYLEVEL_GET_REQUEST
    }
}

export const AddStudyLevelAction = (params) => {
    return {
        type: ActionTypes.STUDYLEVEL_ADD_REQUEST,
        params
    }
}

export const UpdateStudyLevelAction = (params) => {
    return {
        type: ActionTypes.STUDYLEVEL_UPDATE_REQUEST,
        params
    }
}

export const DeleteStudyLevelAction = (params) => {
    return {
        type: ActionTypes.STUDYLEVEL_DELETE_REQUEST,
        params
    }
}