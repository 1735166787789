import {
  Box,
  Grid,
  Card,
  CardHeader,
  Typography,
  FormControl,
} from "@mui/material";
import { useEffect, useState } from "react";
import Base from "../../../../Base/views/Base";
import CommonDatePicker from "../../../../Base/views/CommonDatePicker";
import Chart from "react-apexcharts";
import { GetDashboardDateHelper, GetQualityReportGraphHelper } from "../../../helper/AtsReportHelper";
import { AddMenuHeadingAction } from "../../../../../../store/Menu/Action";
import history from "../../../../../../history";
import { useDispatch } from "react-redux";

const AtsReportDashboardPage = () => {
    const dispatch = useDispatch();
  const [dqData, setDqData] = useState([]);
  const [qrData, setDrData] = useState([]);
  const date = new Date();
  const fromDate = new Date();
fromDate.setDate(date.getDate() - 30);
  const [dq_report, setDq_report] = useState({
    date: "",
  });
  const [q_report, setQ_report] = useState({
    from_date: "",
    to_date:"",
  });
  const handleDq = (event, value) => {
    if (["date"].includes(event?.target?.name)) {
      setDq_report({
        ...dq_report,
        [event?.target?.name]: event?.target?.value,
      });
    }
  };
  const handleQr = (event, value) => {
    if (["from_date", "to_date"].includes(event?.target?.name)) {
      setQ_report({ ...q_report, [event?.target?.name]: event?.target?.value });
    }
  };
  useEffect(() => {
    GetDashboardDateHelper({
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        setDq_report(prevState => ({
          ...prevState,
          date: res?.data?.data?.[0]?.dashboard_date
        }));
        setQ_report(prevState => ({
          ...prevState,
          from_date: res?.data?.data?.[0]?.dashboard_date,
          to_date: res?.data?.data?.[0]?.dashboard_date,
        }));
      }
    });
  }, []);
  useEffect(() => {
    if(dq_report?.date){
      GetQualityReportGraphHelper({
        from_date: dq_report?.date,
        to_date: dq_report?.date,
      }).then((res) => {
        if (res?.data?.statuscode === 200) {
          setDqData(res?.data?.data);
        }
      });
    }
    
  }, [dq_report]);

  useEffect(() => {
    if(q_report?.from_date && q_report?.to_date){
      GetQualityReportGraphHelper({
        from_date: q_report?.from_date,
        to_date: q_report?.to_date,
      }).then((res) => {
        if (res?.data?.statuscode === 200) {
          setDrData(res?.data?.data);
        }
      });
    }
   
  }, [q_report]);

  const showDqr_Chart = dqData?.length === 0;
  const showQr_Chart = qrData?.length === 0;

  const shortlisted = parseInt(dqData?.[0]?.shortlisted);
  const joined = parseInt(dqData?.[0]?.joined);
  const interviewsScheduled = parseInt(dqData?.[0]?.interviews_scheduled);
  const rejected = parseInt(dqData?.[0]?.rejected);

  const shortlisted_qa = parseInt(qrData?.[0]?.shortlisted);
  const joined_qa = parseInt(qrData?.[0]?.joined);
  const interviewsScheduled_qa = parseInt(qrData?.[0]?.interviews_scheduled);
  const rejected_qa = parseInt(qrData?.[0]?.rejected);

  const handleDqrChartClick = (event, chartContext, config) => {
    event.preventDefault()
    sessionStorage.setItem("selectedMenu", "Operational Reports");
    dispatch(AddMenuHeadingAction({ heading: "Operational Reports" }));
    history.push({
      pathname: "/ats/reports/",
      state: { defaultValue: 0 },
    });
  }

  const handleQrChartClick = (event, chartContext, config) => {
    sessionStorage.setItem("selectedMenu", "Operational Reports");
    dispatch(AddMenuHeadingAction({ heading: "Operational Reports" }));
    history.push({
      pathname: "/ats/reports/",
      state: { defaultValue: 3 },
    });
  }
  const dpr_chart_data = {
    series: [joined, shortlisted, rejected, interviewsScheduled ],
  
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        events: {
          click: handleDqrChartClick,
        },
        type: "pie",
        height: 450,
      },
      dataLabels: {
        formatter(val, opts) {
          const value = opts.w.globals.series[opts.seriesIndex]
          return [value]
        }
      },
      legend: {
        position: "bottom",
        fontFamily: "Nunito Sans",
        fontWeight: 600,
      },
      labels: ["Joined", "Candidates Proposed", "Candidates Rejected","Interviews Scheduled"],
      colors: ["#84FFC9", "#AAB2FF","#F8E16C","#ECA0FF"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Nunito Sans",
                fontWeight: 600,
                color: "#8b44ff",
                offsetY: -10,
                formatter: function (val) {
                  return val;
                }
              },
              value: {
                show: false,
                fontSize: "16px",
                fontFamily: "Nunito Sans",
                fontWeight: 400,
                color: "#8b44ff",
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        
      },
    },
  };
  const qr_chart_data = {
    series: [joined_qa, shortlisted_qa, rejected_qa, interviewsScheduled_qa],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        events: {
          click: handleQrChartClick,
        },
        type: "pie",
        height: 450,
      },
      legend: {
        position: "bottom",
        fontFamily: "Nunito Sans",
        fontWeight: 600,
      },
      dataLabels: {
        formatter(val, opts) {
          const value = opts.w.globals.series[opts.seriesIndex] 
          return [value]
        }
      },
      labels: [
        "Joined",
        "Candidates Proposed",
        "Candidates Rejected",
        "Interviews Scheduled",
      ],
      colors: ["#156064", "#00C49A", "#F8E16C", "#84FFC9"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Nunito Sans",
                fontWeight: 600,
                color: "#8b44ff",
                offsetY: -10,
                formatter: function (val) {
                  return val;
                },
              },
              value: {
                show: false,
                fontSize: "16px",
                fontFamily: "Nunito Sans",
                fontWeight: 400,
                color: "#8b44ff",
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
      },
    },
  };
  return (
    <Base>
      <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
        <Grid
          mt={1}
          container
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Card>
              <CardHeader
                title={
                  <Typography pb={1} variant="h6">
                    Daily Productivity
                  </Typography>
                }
              />
              <FormControl sx={{ width: "50%", paddingLeft: "24px" }}>
                <CommonDatePicker
                  size="small"
                  name="date"
                  label="Date"
                  pickerType="date"
                  onKeyDown={(e) => e.preventDefault()}
                  onKeyPress={(e) => e.preventDefault()}
                  pickerFormat="dd/MM/yyyy"
                  DefaultDTvalue={dq_report.date}
                  maxDate={new Date()}
                  handleChange={(value) =>
                    handleDq({
                      target: {
                        name: "date",
                        value: value
                          ? `${
                              value?.getFullYear() +
                              "-" +
                              ("0" + (value?.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + value?.getDate()).slice(-2)
                            }`
                          : "",
                      },
                    })
                  }
                />
              </FormControl>

              {showDqr_Chart === true ? (
                <>
                  <Box
                    className="my-donut-chart"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "370px",
                    }}
                  >
                    <Typography
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {" "}
                      Data Not Available{" "}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    className="my-donut-chart"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Chart
                      options={dpr_chart_data.options}
                      series={dpr_chart_data.series}
                      type="pie"
                      width="380"
                      height="400"
                    />
                  </Box>
                </>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card>
              <CardHeader
                title={
                  <Typography pb={1} variant="h6">
                    Quality
                  </Typography>
                }
              />
              <FormControl sx={{ width: "50%", paddingLeft: "24px" }}>
                <CommonDatePicker
                  size="small"
                  name="from_date"
                  label="From Date"
                  pickerType="date"
                  onKeyDown={(e) => e.preventDefault()}
                  onKeyPress={(e) => e.preventDefault()}
                  pickerFormat="dd/MM/yyyy"
                  DefaultDTvalue={q_report.from_date}
                  maxDate={new Date()}
                  handleChange={(value) =>
                    handleQr({
                      target: {
                        name: "from_date",
                        value: value
                          ? `${
                              value?.getFullYear() +
                              "-" +
                              ("0" + (value?.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + value?.getDate()).slice(-2)
                            }`
                          : "",
                      },
                    })
                  }
                />
              </FormControl>
              <FormControl sx={{ width: "50%", paddingLeft: "24px" }}>
                <CommonDatePicker
                  size="small"
                  name="to_date"
                  label="To Date"
                  pickerType="date"
                  onKeyDown={(e) => e.preventDefault()}
                  onKeyPress={(e) => e.preventDefault()}
                  pickerFormat="dd/MM/yyyy"
                  DefaultDTvalue={q_report.to_date}
                  maxDate={new Date()}
                  handleChange={(value) =>
                    handleQr({
                      target: {
                        name: "to_date",
                        value: value
                          ? `${
                              value?.getFullYear() +
                              "-" +
                              ("0" + (value?.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + value?.getDate()).slice(-2)
                            }`
                          : "",
                      },
                    })
                  }
                />
              </FormControl>

              {showQr_Chart === true ? (
                <>
                  <Box
                    className="my-donut-chart"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "370px",
                    }}
                  >
                    <Typography
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {" "}
                      Data Not Available{" "}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    className="my-donut-chart"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Chart
                      options={qr_chart_data.options}
                      series={qr_chart_data.series}
                      type="pie"
                      width="380"
                      height="400"
                    />
                  </Box>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Base>
  );
};

export default AtsReportDashboardPage;
