import * as ActionTypes from '../ActionTypes';

const initialState = {
    atsUserInfoIsLoading: false,
    error: false,
    errorMessage: "",
    atsUserInfoSuccess: false,
};


export const AtsUserInfoReducer = function (state = initialState, action) {
    switch (action.type) {

        case ActionTypes.ATS_USER_INFO_REQUEST:
            return {
                ...state,
                atsUserInfoIsLoading: true,
                error: false,
                atsUserInfoSuccess: false
            };
        case ActionTypes.ATS_USER_INFO_SUCCESS:
            return {
                ...state,
                atsUserInfoIsLoading: false,
                atsUserInfoSuccess: true,
                successMessage: action.payload?.message,
                atsUserInfoData: action.payload?.data?.data
            };
        case ActionTypes.ATS_USER_INFO_ERROR:
            return {
                ...state,
                atsUserInfoIsLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}