import { Button, FormControl, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { Form } from "../../../Base/views/ModalPopUpForm"
import SelectableSearch from '../../../Base/views/SelectableSearch';
import { useDispatch, useSelector } from "react-redux";
import { AddCostCenterAction, UpdateCostCenterAction } from "../../../../../store/Ancillary/CostCenter/Action";
import { grey } from "@mui/material/colors";
import { useEffect } from "react";
import CreatableSearch from "../../../Base/views/CreatableSearch";
import { AddBusinessUnitHelper } from "../../BusinessUnit/helper/BusinessUnitHelper";
import { GetBusinessUnitAction } from "../../../../../store/Ancillary/BusinessUnit/Action";
import { AddSubBusinessUnitHelper } from "../../SubBusinessUnit/helper/SubBusinessUnitHelper";
import { GetSubBusinessUnitAction } from "../../../../../store/Ancillary/SubBusinessUnit/Action";

export default function CostCenterModalForm(props) {
    const dispatch = useDispatch()
    const [businessUnitOptions, setBusinessUnitOptions] = useState({ load: true, values: [] });
    const [subBusinessUnitOptions, setSubBusinessUnitOptions] = useState({ load: true, values: [] });
    const { subBusinessunitData } = useSelector(state => state?.AncillaryReducer?.SubBusinessUnitReducer);
    const { businessunitData } = useSelector(state => state.AncillaryReducer?.BusinessUnitReducer);
    const [errors, setErrors] = useState({});
    const { addMode, editedRecord, setOpenPopup, costCenterModalData, setCostCenterModalData, initialFValues } = props;
    useEffect(() => {
        (costCenterModalData?.businessunit_id && subBusinessunitData) && setSubBusinessUnitOptions({ load: false, values: subBusinessunitData?.filter((sub) => sub?.businessunit_id === costCenterModalData?.businessunit_id?.businessunit_id) })
        //eslint-disable-next-line
    }, [subBusinessunitData]);
    const validate = (fieldValues = costCenterModalData) => {
        let temp = { ...errors };
        if ("name" in fieldValues) {
            temp.name = fieldValues.name === "" ? "Name is required" : "";
        } 
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };
    const handleInputChange = (event, value) => {
        if (["businessunit_id"].includes(event)) {
            setSubBusinessUnitOptions({ load: true, values: [] })
            if (value) {
                setCostCenterModalData({ ...costCenterModalData, subbusinessunit_id: "", [event]: value })
                let vals = subBusinessunitData.filter((sub) => sub?.businessunit_id === value?.businessunit_id)
                vals && setSubBusinessUnitOptions({ load: false, values: vals })

                if (value?.id === null) {
                    AddBusinessUnitHelper({ name: value?.name }).then(res => {
                        setBusinessUnitOptions({ load: false, values: res?.data?.data })
                        setCostCenterModalData({ ...costCenterModalData, businessunit_id: res?.data?.data?.filter(resp => resp?.name === value?.name)?.[0] })
                        dispatch(GetBusinessUnitAction())
                    })
                } else setCostCenterModalData({ ...costCenterModalData, [event]: value });
            } else {
                setCostCenterModalData({ ...costCenterModalData, [event]: "", subbusinessunit_id: "" })
            }
        } else if (["subbusinessunit_id"].includes(event)) {
            if (value) {
                if (value?.id === null) {
                    AddSubBusinessUnitHelper({ name: value?.name, businessunit_id:costCenterModalData?.businessunit_id }).then(res => {
                        setSubBusinessUnitOptions({ load: false, values: res?.data?.data })
                        setCostCenterModalData({ ...costCenterModalData, subbusinessunit_id: res?.data?.data?.filter(resp => resp?.name === value?.name)?.[0] })
                        dispatch(GetSubBusinessUnitAction())
                    })
                } else setCostCenterModalData({ ...costCenterModalData, [event]: value });

                setCostCenterModalData({ ...costCenterModalData, [event]: value })
            } else {
                setCostCenterModalData({ ...costCenterModalData, [event]: "" })
            }
        }
        else {
            setCostCenterModalData({ ...costCenterModalData, [event.target.name]: event.target.value });
        }
    }
    const HandleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addMode(resetForm);
            if (editedRecord) {
                dispatch(UpdateCostCenterAction(costCenterModalData))
            } else {
                dispatch(AddCostCenterAction(costCenterModalData))
            }
            setOpenPopup(false)
        }
    };
    const resetForm = () => {
        setCostCenterModalData(initialFValues);
        setErrors({});
    };
    const handleBusinessUnit = () => {
        businessunitData && setBusinessUnitOptions({ load: false, values: businessunitData });
    }
    const handleSubBusinessUnit = () => {
        subBusinessunitData && setSubBusinessUnitOptions({ load: false, values: subBusinessunitData })
    }
    return (
        <Form onSubmit={HandleSubmit}>
            <Grid container>
                <Grid container>
                    <Grid key="name" item xs={12} md={6} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                focused
                                value={costCenterModalData?.name}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                autoComplete="name"
                                {...(errors.name && {
                                    error: true,
                                    helperText: errors.name,
                                })}
                            />
                        </FormControl>
                    </Grid>
                     <Grid key="businessunit_id" item xs={12} md={6} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <CreatableSearch
                                label="Business Unit"
                                value={costCenterModalData?.businessunit_id}
                                loading={() => handleBusinessUnit()}
                                fieldLabel="name"
                                autoFocus={false}
                                required={false}
                                variant={"outlined"}
                                name="businessunit_id"
                                size="small"
                                onChangeEvent={handleInputChange}
                                data={businessUnitOptions}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="subbusinessunit_id" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <CreatableSearch
                                label="Sub Business Unit"
                                value={costCenterModalData?.subbusinessunit_id}
                                loading={() => handleSubBusinessUnit()}
                                fieldLabel="name"
                                required={false}
                                variant={"outlined"}
                                name="subbusinessunit_id"
                                size="small"
                                onChangeEvent={handleInputChange}
                                data={subBusinessUnitOptions}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <Button
                        sx={{ ":hover": { color: grey[50] }, marginTop: "1.75rem", marginRight: "1rem" }}
                        variant="outlined"
                        className="mpl-secondary-btn"
                        onClick={() => setOpenPopup(false)}
                    >
                        cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1.75rem", marginRight: "1rem" }}>
                        {editedRecord ? "Update" : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}
