import { IconButton, Tooltip } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const ArrowBackButton = ({
    onClick,
    toolTip
}) => {
    return (
        <>
            <Tooltip title="Back">
                <IconButton component="span" variant="contained" className="mpl-primary-btn" onClick={onClick}>
                    <ArrowBackIosNewIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    )

}

export default ArrowBackButton