import * as ActionTypes from '../ActionTypes';

export const GetEmployeeBankAction = (bankDetails) => {
    return {
        type: ActionTypes.GET_EMPLOYEE_BANK_REQUEST,
        bankDetails
    }
}

export const AddEmployeeBankAction = (bankDetails) => {
    return {
        type: ActionTypes.ADD_EMPLOYEE_BANK_REQUEST,
        bankDetails
    }
}
export const UpdateEmployeeBankAction = (bankDetails) => {
    return {
        type: ActionTypes.UPDATE_EMPLOYEE_BANK_REQUEST,
        bankDetails
    }
}
export const DeleteEmployeeBankAction = (bankDetails) => {
    return {
        type: ActionTypes.DELETE_EMPLOYEE_BANK_REQUEST,
        bankDetails
    }
}