import { useEffect } from 'react';
import { useState } from 'react';
import { Box, Button, Fab, Grid, Tab, Tabs, tabsClasses, TextField } from '@mui/material'
import { Delete, PostAdd } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux';
import { GetSalaryComponentAction } from '../../../../../store/Finance/SalaryComponent/Action';
import { AddSalaryStructureAction, DeleteSalaryStructureAction, UpdateSalaryStructureAction } from '../../../../../store/Finance/SalaryStructure/Action';
import SalaryComponent from './SalaryComponent';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';


const SalaryStructure = () => {
    const dispatch = useDispatch()
    const { salaryStructure_data } = useSelector(state => state.FinanceReducer?.SalaryStructureReducer);
    let maxTabIndex = 0;
    const initialValues = { name: "", code: "" }
    const [salStructData, setSalStructData] = useState(initialValues);
    const [openDialog, setOpenDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const [tabId, setTabId] = useState(salaryStructure_data?.[0]?.sal_structure_id || 0);
    const [tabs, setAddTab] = useState([]);
    const [actionMode, setActionMode] = useState();

    useEffect(() => {
        let struct = []
        if (salaryStructure_data?.length) {
            dispatch(GetSalaryComponentAction({ sal_structure_id: salaryStructure_data?.filter((s) => s.sal_structure_id === tabId)?.[0]?.sal_structure_id }))
            setSalStructData(salaryStructure_data?.filter((s) => s.sal_structure_id === tabId)?.[0])
            salaryStructure_data?.forEach((s) => {
                struct.push(<Tab value={s?.sal_structure_id} label={s?.name} key={s.code} />)
            })
            setAddTab([...struct]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salaryStructure_data])

    // Handle Tab Button Click
    const handleConfirmAdd = () => {
        setActionMode("add")
        setOpenDialog(true);
    };
    const handleTabChange = (event, newTabId) => {
        if (newTabId === "tabProperties") {
            handleConfirmAdd()
        } else {
            setTabId(newTabId);
            let salStruct = {}
            salStruct = salaryStructure_data?.filter((s, i) => s.sal_structure_id === newTabId)
            salStruct?.[0]?.sal_structure_id && setSalStructData(salStruct[0])
            newTabId && dispatch(GetSalaryComponentAction({ sal_structure_id: newTabId }))
        }
    };

    // Handle Add Tab Button
    const handleAddTab = () => {
        maxTabIndex = tabs.length + 1;
        dispatch(AddSalaryStructureAction({ name: `Structure ${maxTabIndex}`, code: `ST${maxTabIndex}` }))
        setOpenDialog(false);
    };

    const validate = (fieldValues) => {
        let temp = {};
        if (!fieldValues.name) temp.name = "Name is required";
        if (!fieldValues.code) temp.code = "Code is required";
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleInputChange = (event) => {
        setSalStructData({ ...salStructData, [event.target.name]: event.target.value });
        Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
            validate({ ...salStructData, [event.target.name]: event.target.value })
    }

    const handleConfirmDelete = () => {
        setActionMode("delete")
        setOpenDialog(true);
    };
    const handleDelete = () => {
        dispatch(DeleteSalaryStructureAction({ id: `{${salStructData?.sal_structure_id}}` }))
        if (salStructData?.sal_structure_id === salaryStructure_data?.[0]?.sal_structure_id) {
            setTabId(salaryStructure_data?.[1]?.sal_structure_id || "")
        } else {
            setTabId(salaryStructure_data?.[0]?.sal_structure_id || "")
        }
        setOpenDialog(false);
    }

    const handleSubmit = () => {
        if (validate(salStructData)) {
            dispatch(UpdateSalaryStructureAction(salStructData))
        }
    }

    return (
        <div>
            <Tabs
                value={tabId}
                onChange={handleTabChange}
                variant="scrollable"
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
                scrollButtons
                allowScrollButtonsMobile
            >
                {tabs.map(child => child)}
                <Tab icon={<PostAdd />} value="tabProperties" />
            </Tabs>
            <Box padding={2}>
                <Grid container>
                    <Grid item xs={12} md={4} padding="10px">
                        <TextField
                            fullWidth
                            required
                            label="Salary Structure Name"
                            name="name"
                            onChange={handleInputChange}
                            value={salStructData?.name}
                            disabled={salStructData?.is_public === "T"}
                            size="small"
                            {...(errors?.name && {
                                error: true,
                                helperText: errors.name,
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} padding="10px">
                        <TextField
                            fullWidth
                            required
                            label="Salary Structure Code"
                            name="code"
                            onChange={handleInputChange}
                            value={salStructData?.code}
                            disabled={salStructData?.is_public === "T"}
                            size="small"
                            {...(errors?.code && {
                                error: true,
                                helperText: errors.code,
                            })}
                        />
                    </Grid>
                    {
                        salStructData?.is_public !== "T" &&
                        <Grid item xs={12} md={4} padding="10px">
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={(event) => handleSubmit(event)}
                                className="mpl-primary-btn"
                            >
                                Update
                            </Button>
                        </Grid>
                    }
                </Grid>
                <SalaryComponent structureData={salStructData} />
            </Box>
            {
                salStructData?.is_public !== "T" &&
                <Box display={"flex"} justifyContent="flex-end">
                    <Fab onClick={handleConfirmDelete} size="small" color="error" aria-label="delete">
                        <Delete />
                    </Fab>
                </Box>
            }
            <ConfirmationDialog
                title={actionMode === "delete" ? "Do you wish to proceed?" : "Do you want to add Salary Structure"}
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Salary Structure Management"
                OkButtonText={actionMode === "delete" ? "OK" : "Create"}
                onSubmit={actionMode === "delete" ? handleDelete : handleAddTab}
            />
        </div>
    )
}

export default SalaryStructure;