import { IconButton, InputAdornment, TextField, Typography, Button, Box, Card, CardContent, CardMedia } from "@mui/material";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { useDispatch, useSelector } from "react-redux";
import { SignInAction } from "../../../../../store/Signin/Action";
import history from "../../../../../history";
import { GREY } from "../../../theme/palette";
import ArrowSvg from "../../../../../svg/ArrowSvg";
import * as ActionTypes from "../../../../../store/ATS/ActionTypes";
import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";

const AtsSwitchWorkspace = () => {
    var data = getUserData();
    const dispatch = useDispatch();

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [switchspacedata, setSwitchSpaceData] = useState({ domain: "", email: "", password: "", emp_id: "" });
    const { atsSwitchWorkspaceData } = useSelector(state => state.AtsReducer.AtsWorkSpaceReducer)

    const handleSwitchWorkSpace = (e) => {
        setSwitchSpaceData({ ...switchspacedata, [e.target.name]: e.target.value, "emp_id": data?.employee_id });
    };

    const handleSwitchWorkSpaceSubmit = () => {
        dispatch(
            SignInAction(
                {
                    email: atsSwitchWorkspaceData?.domain?.userid,
                    password: switchspacedata.password,
                    domain: atsSwitchWorkspaceData?.domain?.domainname,
                    step: 2
                },
                dispatch
            )
        );
    }

    const handleCancel = () => {
        history.push('/ats/tasks')
        dispatch({ type: ActionTypes.ATS_WORKSPACE_ADD_ERROR })
    }

    return (
        <UnauthorizedBase>
            <Box sx={{ display: "flex", justifyContent: "center", p: 15, paddingBottom: 15 }}>
                <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px", p: 5 }} >
                    <CardMedia sx={{ p: 4, textAlign: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <ArrowSvg />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h3">Switch to: {atsSwitchWorkspaceData?.domain?.domainname}</Typography>
                        </Box>
                    </CardMedia>
                    <CardContent>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="h5" color={GREY[600]}>Enter Password</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <TextField
                                    sx={{ width: "300px" }}
                                    size='small'
                                    margin="normal"
                                    required
                                    id="confirm_password"
                                    label="Password"
                                    name="password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    autoComplete="password"
                                    onChange={handleSwitchWorkSpace}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    tabIndex={-1}
                                                    aria-label="toggle new_password visibility"
                                                    onClick={() => {
                                                        setShowConfirmPassword(!showConfirmPassword);
                                                    }}
                                                >
                                                    {showConfirmPassword ? (
                                                        <VisibilityIcon fontSize='small' />
                                                    ) : (
                                                        <VisibilityOffIcon fontSize='small' />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "space-between", flexDirection: "row" }}>
                                    <Button sx={{ ":hover": { color: "primary" }, marginTop: "1.75rem", mr: 3 }} variant="outlined" size="small" onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                    <Button disabled={switchspacedata?.password === ""} variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1.75rem" }} onClick={handleSwitchWorkSpaceSubmit}>
                                        Change WorkSpace
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </UnauthorizedBase>
    )
}

export default AtsSwitchWorkspace