import { put, call } from 'redux-saga/effects';
import { GetBirthdaysHelper, GetEmployeeDashboardHelper, GetHomeHelper, GetQuickLinkHelper } from '../../app/Components/Home/helper/EmployeeDashboardHelper';
import * as ActionTypes from './ActionTypes';

export function* EmployeeDashboardSaga(payload) {
    const response = yield call(GetEmployeeDashboardHelper, payload?.data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_DASHBOARD_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_DASHBOARD_GET_ERROR, payload: response?.message?.error?.[0]?.message })
    }
}

export function* BirthdaysSaga(payload) {
    const response = yield call(GetBirthdaysHelper, payload?.data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_BIRTHDAY_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_BIRTHDAY_ERROR, payload: response?.message?.error?.[0]?.message })
    }
}

export function* HomeSaga(payload) {
    const response = yield call(GetHomeHelper, payload?.data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.HOME_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.HOME_GET_ERROR, payload: response?.message?.error?.[0]?.message })
    }
}

export function* GetQuickLinkSaga(payload) {
    const response = yield call(GetQuickLinkHelper, payload?.data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.QUICK_LINKS_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.QUICK_LINKS_GET_ERROR, payload: response?.message?.error?.[0]?.message })
    }
}