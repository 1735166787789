import NProgress from 'nprogress';
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL, HRMS_RESUME_PARSER_API } from "../../../Constants/baseConstant";
import { getUserData } from '../../Base/helper/baseFunctions';

export const GetUserProfileScoreHelper = async (params) => {
    NProgress.start()
    return await axiosInstance(`/ats/getUserProfileScore`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params?.domain,
            ats_jobpost_id: params?.ats_jobpost_id || 0,
            page_number: params?.page_number || null,
            page_size: params?.page_size || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddProfileScoreHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/profilescore`, {
        method: "POST",
        baseURL: HRMS_RESUME_PARSER_API,
        data: {
            workspace_name: user.domain,
            job_post_id: params?.ats_jobpost_id || 0,
            user_id: user.ats_user_id,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}