import * as ActionTypes from '../ActionTypes';


export const GetSalaryComponentAction = (params) => {
    return {
        type: ActionTypes.SALARY_COMPONENT_GET_REQUEST,
        params
    }
}

export const AddSalaryComponentAction = (params) => {
    return {
        type: ActionTypes.SALARY_COMPONENT_ADD_REQUEST,
        params
    }
}

export const UpdateSalaryComponentAction = (params) => {
    return {
        type: ActionTypes.SALARY_COMPONENT_UPDATE_REQUEST,
        params
    }
}

export const DeleteSalaryComponentAction = (params) => {
    return {
        type: ActionTypes.SALARY_COMPONENT_DELETE_REQUEST,
        params
    }
}