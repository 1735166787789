import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import { sendLeaveEmail } from "./EmailHelper";

//Leave Status Helper
export const LeaveStatusHelper = async (leaveDetails, bulkApprovalData) => {
    var data = getUserData();
    toast.dismiss();
    toast.info("Updating...")
    return await axiosInstance(`/lms/leaveRequestApprovalStatus`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            leave_request_id: leaveDetails.leaverequest_id,
            status: leaveDetails.status,
            note: leaveDetails.note
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Status Updated Successfully")
            if (bulkApprovalData.length > 0) {
                bulkApprovalData.map((leave) => sendLeaveEmail(leave))
            } else {
                sendLeaveEmail(leaveDetails)
            }
            return { message: "Leave Status Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Unable to Change Leave Status", hasError: false, data: err?.response?.data }
        })
}
