import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: true,
    error: false,
    success: false,
    isCandidateLoading: true,
};

export const AtsInvoiceReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_INVOICE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                invoiceData: [],
                success: false
            };
        case ActionTypes.ATS_INVOICE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                invoiceData: action.payload?.data?.data
            };
        case ActionTypes.ATS_INVOICE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_INVOICE_CANDIDATES_GET_REQUEST:
            return {
                ...state,
                isCandidateLoading: true,
                error: false,
                invoiceCandidateData: [],
                success: false
            };
        case ActionTypes.ATS_INVOICE_CANDIDATES_GET_SUCCESS:
            return {
                ...state,
                isCandidateLoading: false,
                success: true,
                successMessage: action.payload?.message,
                invoiceCandidateData: action.payload?.data?.data
            };
        case ActionTypes.ATS_INVOICE_CANDIDATES_GET_ERROR:
            return {
                ...state,
                isCandidateLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_INVOICE_GENERATE_CANDIDATES_GET_REQUEST:
            return {
                ...state,
                isGenerateCandidateLoading: true,
                error: false,
                generateInvoiceCandidateData: [],
                success: false
            };
        case ActionTypes.ATS_INVOICE_GENERATE_CANDIDATES_GET_SUCCESS:
            return {
                ...state,
                isGenerateCandidateLoading: false,
                success: true,
                successMessage: action.payload?.message,
                generateInvoiceCandidateData: action.payload?.data?.data
            };
        case ActionTypes.ATS_INVOICE_GENERATE_CANDIDATES_GET_ERROR:
            return {
                ...state,
                isGenerateCandidateLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_INVOICE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_INVOICE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                invoiceData: action.payload?.data?.data
            };
        case ActionTypes.ATS_INVOICE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_INVOICE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_INVOICE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                invoiceData: action.payload?.data?.data
            };
        case ActionTypes.ATS_INVOICE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}