import * as ActionTypes from '../ActionTypes';

export const GetConfigureStatusAction = (params) => {
    return {
        type: ActionTypes.CONFIGURE_STATUS_GET_REQUEST,
        params
    }
}

export const UpdateConfigureStatusAction = (params) => {
    return {
        type: ActionTypes.CONFIGURE_STATUS_UPDATE_REQUEST,
        params
    }
}

export const GetCountryAction = () => {
    return {
        type: ActionTypes.COUNTRY_GET_REQUEST
    }
}