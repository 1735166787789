import { Box, Grid, Typography, Button } from "@mui/material";
import * as React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SchoolIcon from "@mui/icons-material/School";
import EducationPage from "./EducationPage";
import WorkExperiencePage from "./WorkExperiencePage";
import EngineeringIcon from "@mui/icons-material/Engineering";
import history from "../../../../../history";

export default function ProfessionalSummaryPage(props) {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const NextStepper = () => {
    if(props?.OnboardEmployee){
      props?.setActiveStep(2);
    }
    else{
      props?.setActiveStep(3);
    }
   
  };
  const BackStepper = () => {   
    if(props?.OnboardEmployee){
      props?.setActiveStep(0);
    }
    else{
      props?.setActiveStep(1)
    }  
   
}
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1", padding: props?.OnboardEmployee ? "0px 0px 20px 25px" : "0px 0px 0px 0px" }}>
       
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent:
                props?.currentMode !== undefined ? "space-between" : "flex-end",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
             <Button
                    className="mpl-primary-btn"
                    sx={{ marginRight: "10px" }}
                    type="submit"
                    variant="contained"
                    size="medium"
                    onClick={BackStepper}
                  >
                  Back
          </Button>
          
            {!props?.OnboardEmployee && props?.currentMode !== undefined ? (
              <Button
                className="mpl-primary-btn"
                sx={{ marginRight: "10px" }}
                type="submit"
                variant="contained"
                size="medium"
                onClick={() => history.push("/myemployee")}
              >
                Back to List
              </Button>
            ) : null}
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                textAlign:
                  props?.currentMode !== undefined ? "center" : "center",
              }}
            >
              Professional Summary
            </Typography>
            <Button
              className="mpl-primary-btn"
              sx={{ marginRight: "10px" }}
              onClick={NextStepper}
              type="submit"
              variant="contained"
              size="medium"
            >
              Next
            </Button>
          </Grid>
        
        <TabContext value={value}>
          <Box>
            <TabList
              sx={{
                minHeight: "40px",
                ".MuiTab-root": {
                  minHeight: "20px",
                  padding: "10px 0px 10px 30px",
                },
                "& .MuiTabs-indicator": {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center !important",
                },
              }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{ fontWeight: "600" }}
                icon={<SchoolIcon fontSize="small" />}
                iconPosition="start"
                label="Education"
                value="1"
              />
              <Tab
                sx={{ fontWeight: "600" }}
                icon={<EngineeringIcon fontSize="small" />}
                iconPosition="start"
                label="Work Experience"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
            <EducationPage {...props} />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0px" }}>
            <WorkExperiencePage {...props} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
