import * as ActionTypes from './ActionTypes';

export const GetEmployeeDashboardAction = (data) => {
    return {
        type: ActionTypes.EMPLOYEE_DASHBOARD_GET_REQUEST,
        data
    }
}

export const GetBirthdaysAction = (data) => {
    return {
        type: ActionTypes.EMPLOYEE_BIRTHDAY_REQUEST,
        data
    }
}

export const GetHomeAction = (data) => {
    return {
        type: ActionTypes.HOME_GET_REQUEST,
        data
    }
}

export const GetQuickLinksAction = (data) => {
    return {
        type: ActionTypes.QUICK_LINKS_GET_REQUEST,
        data
    }
}