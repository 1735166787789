import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL, HRMS_RESUME_PARSER_API } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
export const ResumeParserHelper = async (data) => {
  return await axiosInstance({
    method: "POST",
    baseURL: HRMS_RESUME_PARSER_API,
    data: JSON.stringify(data),
  })
    .then((res) => {
      if(data?.job_id?.[0] === false){
        toast.success("Uploaded resumes parsed sucessfully")
      }
      else{
        toast.success("Parsing completed successfully.Candidate moved to not verified section.")
      }
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.error("Candidate record is already available")
      return { message: "Error" };
    });
}

export const AddAtsResumeHelper = async (params) => {
  var user = getUserData();
  return await axiosInstance(`/ats/addResume`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain,
      user_id: user?.ats_user_id,
      original_filename: params?.original_filename,
      encrypt_filename: params?.encrypt_filename,
      digitalocean_filename: params?.digitalocean_filename
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
export const GetAtsFailedResumeHelper = async (params) => {
  var user = getUserData();
  return await axiosInstance(`/ats/getFailedResume`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain,
      user_id: user?.ats_user_id,
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};


export const AtsArchiveResumeHelper = async (params) => {
  var user = getUserData();
  return await axiosInstance(`/ats/archiveResume`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain,
      user_id: user?.ats_user_id,
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const AddAtsBulkResumeHelper = async (params) => {
  var user = getUserData();
  return await axiosInstance(`/ats/bulkResume`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain,
      user_id: user?.ats_user_id,
      file_data: JSON.stringify(params?.file_data),
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};