
// SETUP COLORS
export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const PRIMARY = {
  lighter: '#FFFFFF',
  light: '#D278C7',
  main: '#8b44ff',
  dark: '#f9c307',
  darker: '#752782',
  contrastText: '#000000',
  bg:"#e9dcfe",
  back:"rgb(237 224 255)",
  backyellow:"#faf2d7",
  toolbar:"#e6d9fb7a"
};

export const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#f9c307',
  dark: '#f9c307',
  darker: '#091A7A',
  contrastText: '#000000',
};

export const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#000000',
};

export const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

export const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

export const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#000000',
};

export const DARK = {
  main: '#32384e',
};
export const ROOT = {
  main: "#FFFFFF"
}