import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
};

export const SkillsReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MEEPL_ADMIN_SKILLS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.SKILLS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.SKILLS_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                skillsData: action.payload?.data?.data
            };
        case ActionTypes.SKILLS_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.SKILLS_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.SKILLS_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                skillsData: action.payload?.data?.data
            };
        case ActionTypes.SKILLS_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.SKILLS_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.SKILLS_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                skillsData: action.payload?.data?.data
            };
        case ActionTypes.SKILLS_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.SKILLS_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.SKILLS_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                skillsData: action.payload?.data?.data
            };
        case ActionTypes.SKILLS_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}