import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Rating from '@mui/material/Rating'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Box } from '@mui/material';
import { useTheme } from "@mui/material/styles";

const Permissions = ({ subModules, iconChecked, handleChange, value }) => {

    const headers = ["Submodule", "Create", "Read", "Update", "Delete"]
    const theme = useTheme()
    return (
        <>
            {
                subModules?.length > 0 ?
                    <TableContainer component={Paper}>
                        <Table stickyHeader size="medium" sx={{ minWidth: 650 }} aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {
                                        headers?.map((res, id) => {
                                            return (<TableCell key={id} sx={{ fontWeight: "600", pt: 1, pb: 1 }}>{res}</TableCell>)
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subModules?.map((row, id) => {
                                    return (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ p: 1 }} component="th" scope="row">{row.submodule}</TableCell>
                                            <TableCell sx={{ p: 1, pl: 3 }} component="th" scope="row">
                                                <Rating
                                                    precision={1}
                                                    icon={<CheckCircleRoundedIcon />}
                                                    emptyIcon={null}
                                                    name='create'
                                                    sx={{ color: theme?.palette?.success?.dark }}
                                                    max={1}
                                                    value={iconChecked?.[value]?.[id]?.permission?.[0] === "C" ? 1 : 0}
                                                    onChange={(e) => handleChange(e, row)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ p: 1, pl: 3 }} component="th" scope="row">
                                                <Rating
                                                    precision={1}
                                                    icon={<CheckCircleRoundedIcon />}
                                                    emptyIcon={null}
                                                    name='read'
                                                    sx={{ color: theme?.palette?.success?.dark }}
                                                    max={1}
                                                    value={iconChecked?.[value]?.[id]?.permission?.[1] === "R" ? 1 : 0}
                                                    onChange={(e) => handleChange(e, row)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ p: 1, pl: 3 }} component="th" scope="row">
                                                <Rating
                                                    precision={1}
                                                    icon={<CheckCircleRoundedIcon />}
                                                    emptyIcon={null}
                                                    value={iconChecked?.[value]?.[id]?.permission?.[2] === "U" ? 1 : 0}
                                                    name='update'
                                                    sx={{ color: theme?.palette?.success?.dark }}
                                                    max={1}
                                                    onChange={(e) => handleChange(e, row)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ p: 1, pl: 3 }} component="th" scope="row">
                                                <Rating
                                                    precision={1}
                                                    icon={<CheckCircleRoundedIcon />}
                                                    emptyIcon={null}
                                                    value={iconChecked?.[value]?.[id]?.permission?.[3] === "D" ? 1 : 0}
                                                    name='delete'
                                                    sx={{ color: theme?.palette?.success?.dark }}
                                                    max={1}
                                                    onChange={(e) => handleChange(e, row)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <Box sx={{ textAlign: "center" }}>No submodule found</Box>
            }
        </>
    );
}

export default Permissions;
