import * as ActionTypes from '../ActionTypes';

export const GetLeaveYearTypeAction = (params) => {
    return {
        type: ActionTypes.LEAVE_YEAR_TYPE_GET_REQUEST,
        params
    }
} 
export const UpdateLeaveYearTypeAction = (params) => {
    return {
        type: ActionTypes.LEAVE_YEAR_TYPE_UPDATE_REQUEST,
        params
    }
}
