import { toast } from "react-toastify";
import NProgress from 'nprogress';
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";


export const GetMeeplAdminIndustryTypeHelper = async (params) => {
    let domain = sessionStorage.getItem('userManagement')
    NProgress.start()
    return await axiosInstance(`/ats/ancillary/getMeeplAdminIndustryTypes`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetIndustryTypeHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    NProgress.start()
    return await axiosInstance(`/ats/ancillary/getIndustryTypes`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddIndustryTypeHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    let user_id = sessionStorage.getItem('userid')
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/ancillary/addIndustryTypes`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
            industry_name: params?.industry_name,
            user_id: user_id ? user_id : user?.ats_user_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Industry Sector added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateIndustryTypeHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    let user_id = sessionStorage.getItem('userid')
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/ancillary/updateIndustryTypes`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
            industry_type_id: params?.industry_type_id,
            industry_name: params?.industry_name,
            code: params?.code,
            user_id: user_id ? user_id : user?.ats_user_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Industry Sector Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const DeleteIndustryTypeHelper = async (params) => {
    var user = getUserData();
    let value = sessionStorage.getItem('userManagement')
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ats/ancillary/deleteIndustryTypes`, {
        method: "DELETE",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: value ? value : user.domain,
            industry_type_id: params?.industry_type_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Industry Sector Deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}