import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        paddingTop: 30,
        paddingBottom: 60,
        paddingHorizontal: 50,
    },
    header: {
        marginBottom: 30,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        backgroundColor: '#8b44ff',
        color: "white"
    },
    addressContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    address: {
        width: '100%',
    },
    addressContent: {
        flexDirection: 'row',
        alignItems: 'baseline',
        marginBottom: 2,
    },

    addressLabel: {
        fontSize: 10,
        marginBottom: 2,
        width: '40%',
        textAlign: 'left'
    },

    addressValue: {
        fontSize: 10,
        marginBottom: 2,
        width: '60%',
        textAlign: 'left',
        marginLeft: -15
    },
    logoContainer: {
        width: '30%',
    },
    logo: {
        width: 60,
        height: 60,
    },
    metaContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    meta: {
        width: '30%',
    },
    metaLabel: {
        fontSize: 10,
        fontWeight: 'bold',
        marginBottom: 2,
    },
    metaValue: {
        fontSize: 10,
    },
    tableContainer: {
        marginBottom: 30,
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#eaeaea',
        padding: 5,
        marginBottom: 2,
    },
    columnHeader: {
        fontSize: 10,
        fontWeight: 'bold',
        flex: 1,
        textAlign: 'left',
    },
    tableBody: { padding: 5 },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
    },
    tableCell: {
        fontSize: 10,
        flex: 1,
        textAlign: 'left',
    },
    balanceContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        textAlign: "right"
    },
    balance: {
        width: '30%',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginBottom: 5,
        textAlign: "right",
    },
    balanceLabel: {
        flex: 1,
        fontSize: 10,
        fontWeight: 'bold',
        marginBottom: 2,
        textAlign: "right"
    },
    balanceValue: {
        flex: 1,
        fontSize: 10,
        marginBottom: 2,
        textAlign: "right"
    },
    addressLabel1: {
        fontSize: 10,
        marginBottom: 2,
        width: '40%',
        textAlign: 'left',
        paddingLeft: "80px"
    },

    addressValue1: {
        fontSize: 10,
        marginBottom: 2,
        width: '60%',
        textAlign: 'left',
        paddingLeft: "80px",
        marginLeft: -15
    },
});

const AtsInvoicePdf = ({ data, invoiceCandidateData, performadata, performaCandidateData, amountData, getAmount, today }) => {

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.header}>
                    <Text style={styles.title}>{performaCandidateData ? "Proforma" : "Invoice"}</Text>
                </View>
                <View style={styles.addressContainer}>
                    {data ?
                        <>
                            <View style={styles.address}>
                                <View style={styles.addressContent}>
                                    <Text style={styles.addressLabel}>Bill To</Text>
                                    <Text style={styles.addressValue}>{data?.billing_name || "NA"}</Text>
                                </View>
                                <View style={styles.addressContent}>
                                    <Text style={styles.addressLabel}>Billing Address</Text>
                                    <Text style={styles.addressValue}>{data?.billing_address || "NA"}</Text>
                                </View>
                                <View style={styles.addressContent}>
                                    <Text style={styles.addressLabel}>GST No.</Text>
                                    <Text style={styles.addressValue}>{data?.gst || "NA"}</Text>
                                </View>
                            </View>
                            <View style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", fontSize: 10 }}>
                                <View style={styles.addressContent}>
                                    <Text style={styles.addressLabel1}> Invoice Date</Text>
                                    <Text style={styles.addressValue1}>{data?.invoice_date || "NA"}</Text>
                                </View>
                                <View style={styles.addressContent}>
                                    <Text style={styles.addressLabel1}> Invoice No</Text>
                                    <Text style={styles.addressValue1}>{data?.ats_invoice_no || "NA"}</Text>
                                </View>
                            </View> </> :
                        <>
                            <View style={styles.address}>
                                <View style={styles.addressContent}>
                                    <Text style={styles.addressLabel}>Bill To</Text>
                                    <Text style={styles.addressValue}>{performadata?.billing_name || "NA"}</Text>
                                </View>
                                <View style={styles.addressContent}>
                                    <Text style={styles.addressLabel}>Billing Address</Text>
                                    <Text style={styles.addressValue}>{performadata?.billing_address || "NA"}</Text>
                                </View>
                                <View style={styles.addressContent}>
                                    <Text style={styles.addressLabel}>GST No.</Text>
                                    <Text style={styles.addressValue}>{performadata?.gst || "NA"}</Text>
                                </View>
                            </View>
                            <View style={styles.address}>
                                <Text style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", fontSize: 10, }}>
                                    Proforma Date&emsp; .&emsp;{today || "NA"}
                                </Text>
                            </View>
                        </>}
                </View>
                <View style={styles.tableContainer}>
                    <View style={styles.tableHeader}>
                        {data ?
                            <>
                                <Text style={styles.columnHeader}>Candidate Name</Text>
                                <Text style={styles.columnHeader}>Job Title</Text>
                                <Text style={styles.columnHeader}>Joined Date</Text>
                                <Text style={{ textAlign: 'right', fontSize: 10, flex: 1, fontWeight: "bold" }}>Billable CTC</Text>
                                <Text style={{ textAlign: 'right', fontSize: 10, flex: 1, fontWeight: "bold" }}>Payable Amount</Text>
                            </> :
                            <>
                                <Text style={styles.columnHeader}>Candidate Name</Text>
                                <Text style={styles.columnHeader}>Job Title</Text>
                                <Text style={{ textAlign: 'right', fontSize: 10, flex: 1, fontWeight: "bold" }}>Professional Fee %</Text>
                                <Text style={{ textAlign: 'right', fontSize: 10, flex: 1, fontWeight: "bold" }}>Billable CTC</Text>
                                <Text style={{ textAlign: 'right', fontSize: 10, flex: 1, fontWeight: "bold" }}>Payable Amount</Text>
                            </>}
                    </View>
                    <View style={styles.tableBody}>
                        {invoiceCandidateData ?
                            <>
                                {invoiceCandidateData?.map((row, index) => {
                                    return (
                                        <View key={index} style={styles.tableRow}>
                                            <Text style={styles.tableCell}>{row?.first_name}</Text>
                                            <Text style={styles.tableCell}>{row?.job_title}</Text>
                                            <Text style={styles.tableCell}>{row?.joined_date}</Text>
                                            <Text style={{ fontSize: 10, flex: 1, textAlign: "right", pr: 1 }}>{row?.billable_ctc}</Text>
                                            <Text style={{ fontSize: 10, flex: 1, textAlign: "right", pr: 1 }}>{row?.amount}</Text>
                                        </View>
                                    )
                                }
                                )}
                            </> :
                            <>
                                {performaCandidateData?.map((row, index) => {
                                    return (
                                        <View key={index} style={styles.tableRow}>
                                            <Text style={styles.tableCell}>{row?.candidate_name}</Text>
                                            <Text style={styles.tableCell}>{row?.job_title}</Text>
                                            <Text style={{ fontSize: 10, flex: 1, textAlign: "right", pr: 1 }}>{row?.commission_percentage}</Text>
                                            <Text style={{ fontSize: 10, flex: 1, textAlign: "right", pr: 1 }}>{row?.billable_ctc}</Text>
                                            <Text style={{ fontSize: 10, flex: 1, textAlign: "right", pr: 1 }}>{getAmount(row?.candidate_id)}</Text>
                                        </View>
                                    )
                                }
                                )}
                            </>}

                    </View>
                </View>
                {
                    data ?
                        <View style={styles.balanceContainer}>
                            <View style={styles.balance}>
                                <Text style={styles.balanceLabel}>GST Amount</Text>
                                <Text style={styles.balanceValue}>{data?.gst_total || 0}</Text>
                            </View>
                            <View style={styles.balance}>
                                <Text style={styles.balanceLabel}>Invoice Amount</Text>
                                <Text style={styles.balanceValue}>{data?.invoice_amount || 0}</Text>
                            </View>
                        </View>
                        :
                        <View style={styles.balanceContainer}>
                            <View style={styles.balance}>
                                <Text style={styles.balanceLabel}>Invoice Amount</Text>
                                <Text style={styles.balanceValue}>{amountData?.[0]?.candidate_total || 0}</Text>
                            </View>
                            {
                                amountData?.[0]?.gst_applicable &&
                                <>
                                    <View style={styles.balance}>
                                        <Text style={styles.balanceLabel}>CGST&nbsp;-&nbsp;{amountData?.[0]?.cgst || 0}%</Text>
                                        <Text style={styles.balanceValue}>{amountData?.[0]?.cgst_total || 0}</Text>
                                    </View>
                                    <View style={styles.balance}>
                                        <Text style={styles.balanceLabel}>SGST&nbsp;-&nbsp;{amountData?.[0]?.sgst || 0}%</Text>
                                        <Text style={styles.balanceValue}>{amountData?.[0]?.sgst_total || 0}</Text>
                                    </View>
                                </>
                            }
                            <View style={styles.balance}>
                                <Text style={styles.balanceLabel}>Total Amount</Text>
                                <Text style={styles.balanceValue}>{amountData?.[0]?.invoice_amt || 0}</Text>
                            </View>
                        </View>
                }

            </Page>
        </Document>
    )

}

export default AtsInvoicePdf;
