import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";


export const GetBankHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getBanks/`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddBankHelper = async (bank_data) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addBank/`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            name: bank_data?.name,
            code: bank_data?.code,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Bank Added Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateBankHelper = async (bank_data) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateBank/`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            bank_id: bank_data?.bank_id,
            name: bank_data?.name,
            code: bank_data?.code,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Bank Update Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteBankHelper = async (bank_data) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteBank/`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            bank_id: bank_data?.bank_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Bank Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}