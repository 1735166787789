
const initialState = {
  activeComponent: false,
};
const SlideReducer = function (state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE_COMPONENT":
      return {
        ...state,
        activeComponent: action.payload,
      };
    default:
      return state;
  }
};

export default SlideReducer;