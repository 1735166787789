import { put, call } from 'redux-saga/effects';
import { GetEmployeeProfileHelper } from '../../app/Components/Finance/helper/ProfileHelper';
import { AddTaxDeclarationHelper, GetTaxDeclarationHelper, UpdateTaxDeclarationHelper } from '../../app/Components/Finance/helper/TaxDeclarationHelper';
import * as ActionTypes from './ActionTypes';
import {
    AddSalaryComponentHelper,
    AddSalaryStructureHelper,
    DeleteSalaryComponentHelper,
    DeleteSalaryStructureHelper,
    GetSalaryComponentHelper,
    GetSalaryComponentTypeHelper,
    GetSalaryStructureHelper,
    UpdateSalaryComponentHelper,
    UpdateSalaryStructureHelper
} from '../../app/Components/FinanceSettings/helper/finance';


export function* GetTaxDeclaration() {
    const response = yield call(GetTaxDeclarationHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.TAX_DECLARATION_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.TAX_DECLARATION_GET_ERROR, payload: response })
    }
}

export function* AddTaxDeclaration(payload) {
    const response = yield call(AddTaxDeclarationHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.TAX_DECLARATION_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.TAX_DECLARATION_ADD_ERROR, payload: response })
    }
}

export function* UpdateTaxDeclaration(payload) {
    const response = yield call(UpdateTaxDeclarationHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.TAX_DECLARATION_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.TAX_DECLARATION_UPDATE_ERROR, payload: response })
    }
}

export function* GetEmployeeProfile() {
    const response = yield call(GetEmployeeProfileHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_ERROR, payload: response })
    }
}

export function* GetSalaryStructure() {
    const response = yield call(GetSalaryStructureHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_GET_ERROR, payload: response })
    }
}

export function* AddSalaryStructure(payload) {
    const response = yield call(AddSalaryStructureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_ADD_ERROR, payload: response })
    }
}

export function* UpdateSalaryStructure(payload) {
    const response = yield call(UpdateSalaryStructureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteSalaryStructure(payload) {
    const response = yield call(DeleteSalaryStructureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_DELETE_ERROR, payload: response })
    }
}

export function* GetSalaryComponent(payload) {
    const response = yield call(GetSalaryComponentHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_GET_ERROR, payload: response })
    }
}

export function* AddSalaryComponent(payload) {
    const response = yield call(AddSalaryComponentHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_ADD_ERROR, payload: response })
    }
}

export function* UpdateSalaryComponent(payload) {
    const response = yield call(UpdateSalaryComponentHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteSalaryComponent(payload) {
    const response = yield call(DeleteSalaryComponentHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_DELETE_ERROR, payload: response })
    }
}

export function* GetSalaryComponentType() {
    const response = yield call(GetSalaryComponentTypeHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_TYPE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_TYPE_GET_ERROR, payload: response })
    }
}