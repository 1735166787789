import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading: true,
    Loading: true,
    success: false,
    error: false,
    dashboardData: [],
    homeData:[],
    quicklinksData:[]
};

const EmployeeDashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.EMPLOYEE_DASHBOARD_GET_REQUEST:
            return {
                ...state,
                Loading: true,
                success: false,
                error: false,
                dashboardData: []
            };
        case ActionTypes.EMPLOYEE_DASHBOARD_GET_SUCCESS:
            return {
                ...state,
                Loading: false,
                success: true,
                error: false,
                dashboardData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_DASHBOARD_GET_ERROR:
            return {
                ...state,
                Loading: false,
                success: false,
                error: true,
                dashboardData: []
            };
        case ActionTypes.EMPLOYEE_BIRTHDAY_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                birthdayData: []
            };
        case ActionTypes.EMPLOYEE_BIRTHDAY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: false,
                birthdayData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_BIRTHDAY_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: true,
                birthdayData: []
            };
            case ActionTypes.HOME_GET_REQUEST:
            return {
                ...state,
                Loading: true,
                success: false,
                error: false,
                homeData: []
            };
        case ActionTypes.HOME_GET_SUCCESS:
            return {
                ...state,
                Loading: false,
                success: true,
                error: false,
                homeData: action.payload?.data?.data
            };
        case ActionTypes.HOME_GET_ERROR:
            return {
                ...state,
                Loading: false,
                success: false,
                error: true,
                homeData: []
            };
            case ActionTypes.QUICK_LINKS_GET_REQUEST:
                return {
                    ...state,
                    Loading: true,
                    success: false,
                    error: false,
                    quicklinksData: []
                };
            case ActionTypes.QUICK_LINKS_GET_SUCCESS:
                return {
                    ...state,
                    Loading: false,
                    success: true,
                    error: false,
                    quicklinksData: action.payload?.data?.data
                };
            case ActionTypes.QUICK_LINKS_GET_ERROR:
                return {
                    ...state,
                    Loading: false,
                    success: false,
                    error: true,
                    quicklinksData: []
                };
        default:
            return state;
    }
}

export default EmployeeDashboardReducer;