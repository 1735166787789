
import { useDispatch, useSelector } from "react-redux";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteCostCenterAction } from "../../../../../store/Ancillary/CostCenter/Action";
import { useState } from "react";
import Popup from "../../../Base/views/Popup";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { GetSubBusinessUnitAction } from "../../../../../store/Ancillary/SubBusinessUnit/Action";
import CostCenterModalForm from "./CostCenterModalForm";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from "@mui/styles";

const CostCenter = () => {
    const useStyles = makeStyles((theme) => ({
        toolbarWrapper: {
            '& .MuiToolbar-gutters': {
                paddingLeft: 0,
                paddingRight: 0,
            },
            minHeight: "20px"
        },
    }))
    const classes = useStyles();
    const dispatch = useDispatch()
    const { cost_center_options } = useSelector(state => state?.AncillaryReducer?.CostCenterReducer);
    const [editedRecord, setEditedRecord] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const initialFValues = {
        businessunit_id: "",
        subbusinessunit_id: "",
        name: "",
        code: ""
    };
    const [costCenterModalData, setCostCenterModalData] = useState(initialFValues);
    const openMoreVertIcon = Boolean(anchorEl);
    const handleMoreVertIconClose = () => {
        setAnchorEl(null);
    };
    const handleMoreVertIconClick = (event, row) => {
        setCurrentData(row)
        setAnchorEl(event.currentTarget);
    };
    const handleDelete = () => {
        setOpenDialog(false);
        var id = [];
        if (Array.isArray(currentData)) {
            currentData.forEach(item => {
                id.push(item.costcenter_id);
            })
            dispatch(DeleteCostCenterAction({ costcenter_id: `{${id}}` }))
        } else {
            dispatch(DeleteCostCenterAction({ costcenter_id: `{${currentData?.costcenter_id}}` }))
        }
    };
    const addMode = (resetForm) => {
        resetForm();
        setEditedRecord(null);
        setOpenPopup(false);
    };
    const handleEdit = (rowData) => {
        setAnchorEl(false);
        setEditedRecord(rowData);
        setCostCenterModalData({
            ...rowData,
            businessunit_id: { businessunit_id: rowData?.businessunit_id, name: rowData?.businessunit },
            subbusinessunit_id: { subbusinessunit_id: rowData?.subbusinessunit_id, name: rowData?.subbusinessunit },
        });
        dispatch(GetSubBusinessUnitAction({ businessunit_id: rowData?.businessunit_id }))
        setOpenPopup(true);
    };
    const handleConfirmDelete = (rowData) => {
        setAnchorEl(false);
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleAdd = () => {
        setEditedRecord(null);
        setCostCenterModalData(initialFValues);
        setOpenPopup(true);
    };
    return (
        <Box>
            <MaterialTable
                columns={[
                    {
                        title: "Name", cellStyle: { padding: "0px 10px" }, field: "name"
                    },
                    {
                        title: "Business Unit", cellStyle: { padding: "0px 10px" }, field: "businessunit"
                    },
                    {
                        title: "Sub Business Unit", cellStyle: { padding: "0px 10px" }, field: "subbusinessunit"
                    },
                ]}
                data={cost_center_options || []}
                icons={{
                    Add: () => <AddIcon />,
                    Edit: () => <EditIcon />,
                    Delete: () => <DeleteIcon />
                }}
                options={{
                    maxBodyHeight: 220,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                    sorting: true,
                    search: false,
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: false,
                    selection: false,
                    actionsColumnIndex: -1,
                    grouping: false,
                    addRowPosition: "first",
                }}
                actions={[
                    {
                        icon: () => <MoreVertIcon />,
                        position: "row",
                        tooltip: "Setting",
                        onClick: (_event, rowData) => handleMoreVertIconClick(_event, rowData)
                    },
                    {
                        icon: 'add',
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: (event) => { handleAdd() }
                    }
                ]}
                title=""
                components={{
                    Toolbar: (props) => <div className={classes.toolbarWrapper}><MTableToolbar {...props} /></div>,
                }}
            />
            <Popup
                title={editedRecord ? "Edit Cost Center" : "Add Cost Center"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <CostCenterModalForm
                    editedRecord={editedRecord}
                    addMode={addMode}
                    setOpenPopup={setOpenPopup}
                    costCenterModalData={costCenterModalData}
                    setCostCenterModalData={setCostCenterModalData}
                    initialFValues={initialFValues}
                />
            </Popup>
            <ConfirmationDialog
                title="Do you wish to proceed?"
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Education Management"
                OkButtonText="OK"
                onSubmit={handleDelete}
            />
            {
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={openMoreVertIcon}
                    onClose={handleMoreVertIconClose}
                >
                    {
                        <MenuItem key="edit" onClick={() => (handleEdit(currentData))}>
                            <EditIcon sx={{ fontSize: "15px" }} /> &nbsp; <Typography sx={{ fontSize: "15px" }}>Edit</Typography>
                        </MenuItem>
                    }
                    {
                        <MenuItem key="delete" onClick={() => (handleConfirmDelete(currentData))}>
                            <DeleteIcon sx={{ fontSize: "15px" }} /> &nbsp; <Typography sx={{ fontSize: "15px" }}>Delete</Typography>
                        </MenuItem>
                    }
                </Menu>
            }
        </Box>
    );
}
export default CostCenter;