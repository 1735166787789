import React from "react";
import { Button, FormControl, Grid, TextField } from "@mui/material";
import { Form } from "../../../Base/views/ModalPopUpForm";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import {
  AddMyTimeConfigureAction,
  UpdateMyTimeConfigureAction,
} from "../../../../../store/Leave/MyTimeConfigure/Action";
import { GetWeekDaysAction } from "../../../../../store/Ancillary/WeekDays/Action";

const names = [
  {
    id: "7",
    name: "Sunday",
  },
  {
    id: "1",
    name: "Monday",
  },
  {
    id: "2",
    name: "Tuesday",
  },
  {
    id: "3",
    name: "Wednesday",
  },
  {
    id: "4",
    name: "Thursday",
  },
  {
    id: "5",
    name: "Friday",
  },
  {
    id: "6",
    name: "Saturday",
  },
];
const MyTimePopup = ({ editedRecord, setOpenPopup }) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState({
    project_code: "",
    daily_hours: "",
    working_days: [],
  });
  const [daysOptions, setDaysOptions] = useState({ load: true, values: [] });
  const [errors, setErrors] = useState({});

  const { week_days_options } = useSelector(
    (state) => state.AncillaryReducer.WeekDaysReducer
  );

  useEffect(() => {
    if (editedRecord != null) {
      setTime({
        ...editedRecord,
      });
    }
    dispatch(GetWeekDaysAction());
  }, [editedRecord]);

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("project_code" in fieldValues) {
      temp.project_code =
        fieldValues.project_code === "" ? "Project code is required" : "";
    }
    if ("daily_hours" in fieldValues) {
      temp.daily_hours =
        fieldValues.daily_hours === "" ? "Week hours is required" : "";
    }
    if ("working_days" in fieldValues) {
      temp.working_days =
        fieldValues.working_days === "" ? "Work Days is required" : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (event, value) => {
    if (["working_days"].includes(event)) {
      if (value) {
        setTime({ ...time, [event]: value });
      } else {
        setTime({ ...time, [event]: "" });
      }
    } else {
      setTime({ ...time, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...time, [event.target.name]: event.target.value });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.type === "submit" || event.which === 13) {
      if (validate(time)) {
        const weekly_working_days = {};
      names.forEach(day => {
        weekly_working_days[day.id] = "0";
      });
      time?.working_days.forEach(({ week_day_pk }) => {
        weekly_working_days[week_day_pk] = time?.daily_hours;
      });
        let weekly_hours = time?.daily_hours * time?.working_days?.length;
        let stages = [];
        time?.working_days?.length > 0 &&
        time?.working_days?.forEach(res => stages.push({ "working_days": +res?.week_day_pk }))
        if (time?.mytime_pk) dispatch(UpdateMyTimeConfigureAction({
          ...time,
          weekly_hours: weekly_hours,
          weekly_working_days: JSON.stringify(weekly_working_days),
          working_days: stages?.length > 0 ? JSON.stringify(stages) : null,
        }));
        else
          dispatch(
            AddMyTimeConfigureAction({
              ...time,
              weekly_hours: weekly_hours,
              weekly_working_days: JSON.stringify(weekly_working_days),
              working_days: stages?.length > 0 ? JSON.stringify(stages) : null,
            })
          );
        setOpenPopup(false);
      }
    }
  };



  return (
    <>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Grid container>
          <Grid container>
            <Grid
              key="project_code"
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  size="small"
                  value={time?.project_code}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  id="project_code"
                  label="Project Code"
                  name="project_code"
                  {...(errors.project_code && {
                    error: true,
                    helperText: errors.project_code,
                  })}
                />
              </FormControl>
            </Grid>
            <Grid
              key="daily_hours"
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  size="small"
                  value={time?.daily_hours}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  id="daily_hours"
                  label="Daily Hours"
                  name="daily_hours"
                  {...(errors.daily_hours && {
                    error: true,
                    helperText: errors.daily_hours,
                  })}
                />
              </FormControl>
            </Grid>
            <Grid
              key="days"
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <FormControl fullWidth>
                <SelectableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option.week_day_pk === +value.week_day_pk
                  }
                  label="Days"
                  value={time?.working_days}
                  loading={() =>
                    setDaysOptions({ load: false, values: week_days_options })
                  }
                  fieldLabel="week_day_name"
                  multiple={true}
                  variant={"outlined"}
                  name="working_days"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={daysOptions}
                  {...(errors.working_days && {
                    error: true,
                    helperText: errors.working_days,
                  })}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                marginTop: "1rem",
                marginRight: "1rem",
                ":hover": { color: grey[50] },
              }}
              variant="outlined"
              onClick={() => setOpenPopup(false)}
              className="mpl-secondary-btn"
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="mpl-primary-btn"
              sx={{ marginTop: "1rem", marginRight: "1rem" }}
            >
              {editedRecord ? "Update" : "Save"}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default MyTimePopup;
