import React from "react";
import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useEffect } from "react";
import { GetOfferLetterTemplateResponseHelper } from "../../../ATSSettings/helper/AtsFormTemplateResponseHelper";
import { s3Client } from "../../../Base/helper/baseFunctions";
import html2pdf from 'html2pdf.js';
const ViewOffer = ({ data }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [offer, setOfferData] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (data) {
      GetOfferLetterTemplateResponseHelper({
        offer_letter_response_id: data?.offer_letter_response_id,
      }).then((res) => {
        setOfferData(res?.data?.data?.[0]);
        res?.data?.data[0]?.company_logo &&
          getImageSignedUrl(res?.data?.data[0]?.company_logo);
      });
    }
    //eslint-disable-next-line
  }, [data]);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const generatePDF = () => {
    setIsLoading(true);
    const element = document.querySelector('.a4-body');
    const opt = {
      margin: [30, 10, 15, 10],
      filename: `${data?.full_name}_offer_letter.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };
    html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        if (imageUrl) {
          pdf.addImage(imageUrl, 'JPEG', 10, 5, 20, 15);
        }
        if (offer?.header) {
          pdf.setFontSize(12);
          const headerText = pdf.splitTextToSize(offer.header, pageWidth - 60);
          pdf.text(headerText, pageWidth / 2, 12, { align: 'center' });
        }
        if (offer?.header || offer?.company_logo) {
          pdf.setLineWidth(0.5);
          pdf.line(10, 25, pageWidth - 10, 25);
        }
        if (offer?.footer) {
          pdf.line(10, pageHeight - 12, pageWidth - 10, pageHeight - 12);
          pdf.setFontSize(10);
          pdf.text(offer.footer, pageWidth / 2, pageHeight - 6, { align: 'center' });
        }
      }
      pdf.save(`${data?.full_name}_offer_letter.pdf`);
    }).catch((error) => {
      console.error('Error generating PDF:', error);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography sx={{ mb: 1, mt: 1, textAlign: "center" }} variant="h6">
          {data?.title}
        </Typography>
      </Box>
      <Grid container>
        <div className="a4-sheet">
          <div className="a4-header">
            {offer?.company_logo && (
              <img className="company_logo" alt="Nothing" src={imageUrl} />
            )}
            <div className="header-text">{offer?.header}</div>
          </div>
          <div className="a4-body">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  offer?.offer_letter_edited || offer?.offer_letter_response,
              }}
            ></div>
          </div>
          <div className="a4-footer"> {offer?.footer}</div>
        </div>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              padding: "20px 20px",
            }}
          >
            {
              isLoading ?
                <CircularProgress disableShrink />
                :
                <Button
                  variant="contained"
                  className="mpl-primary-btn"
                  onClick={generatePDF}
                >
                  Download
                </Button>
            }

          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default ViewOffer;
