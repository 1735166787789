import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Paper, Tab, Box } from '@mui/material'
import { useState } from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import '../../css/style.css'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AtsSettings from '../../../ATSSettings/views/AtsSettings';
import Dashboard from './Dashboard';
import ArrowBackButton from '../../../Base/views/ArrowBackButton';

const ConfigurationLandingPage = ({ setConfiguration }) => {
    const [value, setValue] = useState('1');
    const [accordian, setAccordian] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Paper className='ats-settings admin-setting' sx={{ borderRadius: "10px", minHeight: "87vh", maxHeight: "87vh", overflowY: "auto" }}>
                <TabContext value={value}>
                    <ArrowBackButton onClick={() => { setConfiguration(false) }} />
                    <Box sx={{ display: "flex" }}>
                        <TabList sx={{
                            minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                            '& .MuiTabs-indicator': {
                                width: '100%',
                                display: 'flex',
                                ml: 1,
                                justifyContent: "center !important",
                            },
                        }} onChange={handleChange} aria-label="lab API tabs example">
                            <Tab icon={<DashboardIcon fontSize='small' />} iconPosition="start" label="Dashboard" value="1" />
                            <Tab icon={<ManageAccountsIcon fontSize='small' />} iconPosition="start" label="ATS" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                        <Dashboard setValue={setValue} setAccordian={setAccordian} />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px", minHeight: "45px" }}>
                        <AtsSettings accordian={accordian} />
                    </TabPanel>
                </TabContext>
            </Paper>
        </>
    );
}

export default ConfigurationLandingPage