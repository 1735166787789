import * as ActionTypes from '../ActionTypes';

export const GetWorkLocationAction = () => {
    return {
        type: ActionTypes.WORK_LOCATION_GET_REQUEST,
    }
}

export const AddWorkLocationAction = (params) => {
    return {
        type: ActionTypes.WORK_LOCATION_ADD_REQUEST,
        params
    }
}

export const UpdateWorkLocationAction = (params) => {
    return {
        type: ActionTypes.WORK_LOCATION_UPDATE_REQUEST,
        params
    }
}

export const DeleteWorkLocationAction = (params) => {
    return {
        type: ActionTypes.WORK_LOCATION_DELETE_REQUEST,
        params
    }
}