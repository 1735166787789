import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  darken,
  lighten,
  TextField,
  Typography,
  ClickAwayListener,
  Tooltip,
  IconButton,
  Fade,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import {
  Accessibility,
  Badge,
  Dashboard,
  SettingsSuggest,
} from "@mui/icons-material";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { getSessionData } from "../../Base/helper/baseHelper";
import history from "../../../../history";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import EmployeeProfile from "../../Employee/views/EmployeeProfile";
import { GetEmployeesHelper } from "../../Employee/helper/EmployeeHelper";
import { getUserData } from "../../Base/helper/baseFunctions";

const SearchBar = () => {
  const userData = getUserData();
  const disableModuleSearch = true;
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [search, setSearch] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const { userInfo } = useSelector((state) => state?.SignInReducer);
  useEffect(() => {
    setSelectedMenu(getSessionData({ key: "selectedMenu" }));
    let active = true;
    if (!loading) {
      return undefined;
    }
    if (active) {
      handleEmployeesLoad();
    }
    return () => {
      active = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleOnChange = (event, value) => {
    value?.url && history.push(value?.url);
    value?.module && sessionStorage.setItem("selectedMenu", value?.module);
  };

  const handleEmployeesLoad = () => {
    GetEmployeesHelper({ domain: userInfo?.domain, is_active: true }).then((res) => {
      setOptions(res?.data?.data);
    });
  };

  const employeeSearchOnChange = (event, value) => {
    setData(value);
    value && setOpenPopup(true);
  };

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  const meeplModules = [
    {
      label: "Dashboard",
      url: "/home",
      module: "Dashboard",
      subModule: "Dashboard",
      icon: <Dashboard fontSize="small" />,
    },
    {
      label: "It's Me",
      url: "/employee",
      module: "Its Me",
      subModule: "It's Me",
      icon: <Badge fontSize="small" />,
    },
    {
      label: "My Leave",
      url: "/leave",
      module: "My Leave",
      subModule: "Dashboard",
      icon: <EditCalendarIcon fontSize="small" />,
    },
    {
      label: "General",
      url: "/settings",
      module: "General",
      subModule: "Dashboard",
      icon: <SettingsSuggest fontSize="small" />,
    },
    {
      label: "Roles & Access",
      url: "/role-access",
      module: "Roles & Access",
      subModule: "Roles & Access",
      icon: <Accessibility fontSize="small" />,
    },
  ];

  return (
    <div>
       {data && (
        <EmployeeProfile
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          data={data}
        />
      )}
      {["owner", "Owner", "Admin", "Internal"].includes(userData?.user_type) && (
        <>
          {search ? (
            <>
              <ClickAwayListener
                onClickAway={() => {
                  handleSearch();
                }}
              >
                <Fade in={search}>
                  <Autocomplete
                    freeSolo
                    getOptionLabel={(option) => option.name || ""}
                    open={open}
                    onInputChange={(_, value) => {
                      if (value.length === 0) {
                        if (open) setOpen(false);
                      } else {
                        if (!open) setOpen(true);
                      }
                    }}
                    onClose={() => setOpen(false)}
                    id="search-bar"
                    options={options}
                    sx={{
                      borderRadius: "10px",
                      backgroundColor: theme.palette.common.white,
                      "&:hover": {
                        backgroundColor: theme.palette.common.white,
                      },
                      width: 200,
                      // '.Mui-focused': { width: 388 },
                     
                    }}
                    placeholder="Search Employee..."
                    onChange={employeeSearchOnChange}
                    size="small"
                    renderInput={(params) => (
                      <TextField placeholder="Search Employee..." {...params} />
                    )}
                  />
                </Fade>
              </ClickAwayListener>
            </>
          ) : (
            <Tooltip title="Search">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}

      {!disableModuleSearch && (
        <Autocomplete
          freeSolo
          open={open}
          onInputChange={(_, value) => {
            if (value.length === 0) {
              if (open) setOpen(false);
            } else {
              if (!open) setOpen(true);
            }
          }}
          onClose={() => setOpen(false)}
          getOptionLabel={(option) => option.label || ""}
          id="search-bar"
          options={meeplModules}
          groupBy={(option) => option.module}
          sx={{
            borderRadius: "10px",
            backgroundColor: theme.palette.common.white,
            "&:hover": {
              backgroundColor: theme.palette.common.white,
            },
            width: 400,
            // '.Mui-focused': { width: 388 },
           
          }}
          placeholder="Search..."
          onChange={handleOnChange}
          size="small"
          renderInput={(params) => (
            <TextField placeholder="Search Employee..." {...params} />
          )}
          renderGroup={(params) => (
            <li>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Box
                component="span"
                sx={{
                  width: 14,
                  height: 14,
                  flexShrink: 0,
                  borderRadius: "3px",
                  mr: 1,
                  mt: "2px",
                }}
              />
              {option?.icon}
              <Box sx={{ ml: 2 }}>
                <Typography variant="body1">{option?.label}</Typography>
              </Box>
            </li>
          )}
        />
      )}
    </div>
  );
};

export default SearchBar;
