import { Typography } from '@mui/material';
import React from 'react';


const CustomToggle = ({ isChecked, handleToggleChange, Yestext, NoText, style, disableRipple, disabled }) => {
    const toggleSwitchStyle = {
        ...style,
        borderRadius: '30px',
        padding: style?.padding || '5px',
        backgroundColor: style?.backgroundColor || (disabled && 'grey'),
        height: style?.height || '30px',
        disableRipple
    };

    const labelStyle = {
        display: 'inline-block',
        padding: '0px',
        position: 'relative',
        textAlign: 'center',
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        cursor: 'pointer',
        minWidth: '60px',
        transition: 'background 600ms ease, color 600ms ease',
        userSelect: 'none',
        borderRadius: '30px',
        borderRight: '0px',
        height: 'auto'
    };

    const toggleLeftStyle = {
        ...labelStyle,
        borderRight: '0',
        borderTopLeftRadius: '30px',
        borderBottomLeftRadius: '30px',
        fontWeight: 600,
        padding: style?.labelPadding || '0px',
    };

    const toggleRightStyle = {
        ...labelStyle,
        borderTopRightRadius: '30px',
        borderBottomRightRadius: '30px',
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        fontWeight: 600,
        padding: style?.labelPadding || '0px',
    };

    const toggleSwitchInputStyle = {
        display: 'none',
    };


    return (
        <div style={toggleSwitchStyle}>
            <input
                id="toggle-off"
                className="toggle toggle-right"
                name="toggle"
                value="true"
                type="radio"
                checked={!isChecked}
                onChange={handleToggleChange}
                style={toggleSwitchInputStyle}
                disabled={disabled}
            />
            <label
                htmlFor="toggle-off"
                className={`btn ${isChecked ? '' : 'checked'}`}
                style={isChecked ? toggleRightStyle : { ...toggleRightStyle, backgroundImage: disabled ? 'linear-gradient(to left, #cccccc, #cccccc)' : 'linear-gradient(to left, #facf39, #fbdb6b)', color: '#000', cursor: 'default' }}
            >
                <Typography sx={{ ...toggleRightStyle, color: '#000', fontWeight: !isChecked ? 600 : 400 }}>{NoText || 'No'}</Typography>
            </label>

            <input
                id="toggle-on"
                className="toggle toggle-left"
                name="toggle"
                value="false"
                type="radio"
                checked={isChecked}
                onChange={handleToggleChange}
                style={toggleSwitchInputStyle}
                disabled={disabled}
            />
            <label
                htmlFor="toggle-on"
                className={`btn ${isChecked ? 'checked' : ''}`}
                style={isChecked ? { ...toggleLeftStyle, backgroundImage: disabled ? 'linear-gradient(to left, #cccccc, #cccccc)' : 'linear-gradient(to left, #facf39, #fbdb6b)', color: '#000', cursor: 'default' } : toggleLeftStyle}
            >
                <Typography sx={{ ...toggleLeftStyle, color: '#000', fontWeight: isChecked ? 600 : 400 }} >{Yestext || 'Yes'}</Typography>
            </label>
        </div >
    );
};

export default CustomToggle;
