import React from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Link
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@mui/styles";
import Meepl_logo from "../image/Meepl_Logo.jpeg";
import history from "../../../../history";

export const useStyles = makeStyles({
  root: {
    "& label.Mui-focused": {
      color: "rgb(59,59,59)",
      fontSize: 25,
    },
    "& MuiFormControlLabel-label": {
    },
    "& label": {
      fontSize: 21,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
});

const SignIn = ({
  signinData,
  setSigninData,
  handleClick,
  emailErrorLabel,
  error,
  rememberCheck,
  setRememberCheck,
  handleKeyDown,
}) => {
  const classes = useStyles();

  const storeSigninData = (e) => {
    const { name, value } = e.target;
    setSigninData((preData) => ({ ...preData, [name]: value }));
  };

  return (
    <>
      <Box className="login-box">
        {/* FORM SIGN IN*/}
        <img
          src={Meepl_logo}
          alt="Logo"
          style={{
            width: "300px",
            height: "auto",
            borderRadius: "4%",
            marginBottom: "10%",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        />

        <Typography
          className="head-sign-sec-h5"
          variant="h5"
        >
          Sign In
        </Typography>
        <TextField
          style={{
            margin: "8px 0",
            width: "100%",
            padding: "12px 4px",
          }}
          variant="standard"
          helperText={error.email && emailErrorLabel}
          type="email"
          label="Registered Email-ID"
          name="email"
          className={classes.root}
          error={error.email}
          onChange={storeSigninData}
          value={signinData.email}
          onKeyDown={handleKeyDown}
        />
        <Box sx={{ display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}> 
        <FormControlLabel
          value="start"
          sx={{ fontSize: "12px" }}
          control={
            <Checkbox
              checked={rememberCheck}
              size="small"
              onClick={() => setRememberCheck(!rememberCheck)}
              disabled={signinData?.email === ""}
            />
          }
          className="rember-sec"
          label="Remember Me"
          labelPlacement="end"
        />
         <Link
         className="forget-sec"
          onClick={() => history.push("/forgotPassword")}
          variant="body2"
          sx={{
            cursor: "pointer",
            fontSize: "16px",
            color: "black",
          }}
        >
          Forgot Password
        </Link>
        </Box>
        <Button
          onClick={handleClick}
          sx={{ alignSelf: "end", color: "black" }}
          variant="text"
        >
          <ArrowForwardIcon style={{ fontSize: "35px" }} />
        </Button>
      </Box>
    </>
  );
};

export default SignIn;
