import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Divider, Paper } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import Prompt from './Prompt';
import General from './General';
import Preview from './Preview';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#8b44ff',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#8b44ff',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[500] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#8b44ff',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#8b44ff',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};


const steps = [
    'Prompt Customization',
    'Offer Letter Customization',
    'Preview',
];

const OfferLetterForm = (props) => {
    const { setCurrentMode, currentMode, data, setData } = props
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <Prompt
                        setCurrentMode={setCurrentMode}
                        currentMode={currentMode}
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                        data={data}
                        setData={setData}
                    />
                );
                case 1:
                return (
                    <General
                        setCurrentMode={setCurrentMode}
                        currentMode={currentMode}
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                        data={data}
                        setData={setData}
                    />
                );
                case 2:
                    return (
                        <Preview
                            setCurrentMode={setCurrentMode}
                            currentMode={currentMode}
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            data={data}
                            setData={setData}
                        />
                    );
            default:
                return "Unknown stepIndex";
        }
    }


    return (
        
            
            <Paper sx={{ ".MuiDrawer-paper": { backgroundColor: `${theme.palette.mode === "dark" ? "#544166" : "white"}` }, borderRadius: "10px", paddingTop: "5px" }}>
                <Box sx={{ width: '100%' }}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                    <Divider variant="middle" flexItem sx={{ paddingTop: "5px", paddingBottom: "5px" }} />

                    <div style={{ padding: "20px 0px 20px 0px", }}>
                        {getStepContent(activeStep)}
                    </div>
                </Box>
            </Paper >
       
    );
}

export default OfferLetterForm;
