import { Box, Button, Grid, Typography } from "@mui/material"
import NotFoundSvg from "../../../../svg/NotFoundSvg"
import history from "../../../../history"
import UnauthorizedBase from "./UnauthorizedBase"

const NotFound = ({ navigateButton, message }) => {
    return (
        <UnauthorizedBase>

            <Grid item >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column  ',
                        alignItems: 'center',
                        justifyContent: "center",
                        alignContent: "center",
                        padding: 2,
                        mt: 10
                    }}
                >
                    <Typography textAlign={"center"} component="h1" variant="h3" fontWeight={700} lineHeight={1.5}>
                        URL not Found
                    </Typography>
                    <Typography>
                        {message}
                    </Typography>
                    <div style={{ height: "196px" }}>
                        <NotFoundSvg />
                    </div>
                    {navigateButton && navigateButton}
                    <Button sx={{ mt: 3 }} onClick={() => history.push("/")}>Back to login</Button>
                </Box>
            </Grid>
        </UnauthorizedBase>
    )
}

export default NotFound