import { takeLatest } from 'redux-saga/effects';
import { GetTourStatusSaga, UpdateTourStatusSaga } from './Saga';
import * as ActionTypes from './ActionTypes';

export default function* TourWatcher() {
    yield takeLatest(ActionTypes.START_TOUR_REQUEST, GetTourStatusSaga);
    yield takeLatest(ActionTypes.END_TOUR_REQUEST, UpdateTourStatusSaga);
}


