import { Chip, Button, Typography } from "@mui/material";
import { PRIMARY } from "../../../../theme/palette";
import DeleteIcon from "@mui/icons-material/Delete";

const AppliedFilter = (props) => {
    const {
        filteredData,
        handleFilter,
        handleClearFilter,
    } = props;
    let filterStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "8px",
        paddingRight: "8px",
        lineHeight: 1.57143,
        fontSize: "0.875rem",
        color: " #000000",
        backgroundColor: PRIMARY.dark,
        borderRight: "1px solid rgba(145, 158, 171, 0.24)",
    };
    let filterStyle1 = {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        margin: "4px",
        borderRadius: "8px",
        overflow: "hidden",
        border: "1px solid rgba(145, 158, 171, 0.24)",
    };

    return (
        <div
            style={{
                display: "flex",
                flexFlow: "row wrap",
                flexGrow: 1,
                alignItems: "center",
            }}
        >
            {filteredData?.primary_skill?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Primary Skill</Typography>
                    <div style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}>
                        {filteredData?.primary_skill?.map((comp, index) => (
                            <Chip
                                key={index}
                                sx={{ margin: "4px" }}
                                label={comp?.primary_skill}
                                size="small"
                                name="primary_skill"
                                onDelete={() => {
                                    handleFilter("primary_skill", filteredData.primary_skill.filter((item, i) => i !== index), "delete");
                                }}
                            />
                        ))}
                    </div>
                </div>
            )}
            {filteredData?.secondary_skill?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Secondary Skill</Typography>
                    <div style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}>
                        {filteredData?.secondary_skill?.map((comp, index) => (
                            <Chip
                                key={index}
                                sx={{ margin: "4px" }}
                                label={comp?.secondary_skill}
                                size="small"
                                name="secondary_skill"
                                onDelete={() => {
                                    handleFilter("secondary_skill", filteredData.secondary_skill.filter((item, i) => i !== index), "delete");
                                }}
                            />
                        ))}
                    </div>
                </div>
            )}
            {filteredData?.location?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Location</Typography>
                    <div style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}>
                        {filteredData?.location?.map((comp, index) => (
                            <Chip
                                key={index}
                                sx={{ margin: "4px" }}
                                label={comp?.location}
                                size="small"
                                name="location"
                                onDelete={() => {
                                    handleFilter("location", filteredData.location.filter((item, i) => i !== index), "delete");
                                }}
                            />))}
                    </div>
                </div>
            )}
            {filteredData?.notice_period && filteredData?.notice_period?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Notice Period</Typography>
                    <div style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}>
                        <Chip
                            sx={{ margin: "4px" }}
                            label={filteredData?.notice_period}
                            size="small"
                            name="notice_period"
                            onDelete={() => {
                                handleFilter({ target: { name: "notice_period", value: [] } }, "", "delete");
                            }}
                        />
                    </div>
                </div>
            )}
            {filteredData?.yoe && filteredData.yoe.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Years of Experience</Typography>
                    <div style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}>
                        <Chip
                            sx={{ margin: "4px" }}
                            label={filteredData?.yoe} size="small"
                            name="yoe"
                            onDelete={() => {
                                handleFilter({ target: { name: "yoe", value: [] } }, "", "delete");
                            }}
                        />
                    </div>
                </div>
            )}
            {(filteredData &&
                (filteredData.primary_skill?.length > 0 ||
                    filteredData.secondary_skill?.length > 0 ||
                    filteredData.location?.skill ||
                    filteredData.notice_period?.length > 0 ||
                    filteredData.yoe?.length > 0)) && (
                    <Button
                        color="error"
                        variant="text"
                        startIcon={<DeleteIcon />}
                        onClick={handleClearFilter}
                    >
                        Delete
                    </Button>
                )}
        </div>
    );
};

export default AppliedFilter;