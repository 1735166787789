import * as ActionTypes from '../ActionTypes';

export const GetAtsGroupCompanyAction = () => {
    return {
        type: ActionTypes.ATS_GROUP_COMPANY_GET_REQUEST,
    }
}

export const AddAtsGroupCompanyAction = (params) => {
    return {
        type: ActionTypes.ATS_GROUP_COMPANY_ADD_REQUEST,
        params
    }
}
export const UpdateAtsGroupCompanyAction = (params) => {
    return {
        type: ActionTypes.ATS_GROUP_COMPANY_UPDATE_REQUEST,
        params
    }
}
export const DeleteAtsGroupCompanyAction = (params) => {
    return {
        type: ActionTypes.ATS_GROUP_COMPANY_DELETE_REQUEST,
        params
    }
}