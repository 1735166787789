
const FullLogo = () => {
    return (
        <>
            <svg width="100%" height="100%" viewBox="0 0 2022 563" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_0_1)">
                    <path d="M498.079 196.101C524.423 196.101 545.779 176.276 545.779 151.82C545.779 127.365 524.423 107.54 498.079 107.54C471.735 107.54 450.379 127.365 450.379 151.82C450.379 176.276 471.735 196.101 498.079 196.101Z" fill="#FECB03" />
                    <path d="M500.351 204.536C498.079 204.536 495.808 204.536 491.265 204.536C470.822 204.536 452.651 191.884 445.836 175.015C429.937 170.798 414.037 168.689 398.137 168.689C373.151 168.689 350.437 175.015 332.265 183.449C329.994 185.558 325.451 185.558 323.18 187.667C320.908 189.775 318.637 189.775 316.366 191.884C314.094 193.993 314.094 196.101 316.366 196.101C318.637 196.101 320.908 196.101 320.908 196.101C336.808 191.884 354.98 187.667 375.422 187.667C407.222 189.775 439.022 198.21 464.008 212.97C500.351 234.056 532.15 267.794 536.693 307.858C536.693 312.075 541.236 326.835 543.507 309.966C543.507 307.858 543.507 305.749 543.507 301.532C545.779 265.685 529.879 229.839 500.351 204.536Z" fill="#FECB03" />
                    <path d="M264.123 88.5618C290.467 88.5618 311.823 68.7366 311.823 44.2809C311.823 19.8252 290.467 0 264.123 0C237.779 0 216.423 19.8252 216.423 44.2809C216.423 68.7366 237.779 88.5618 264.123 88.5618Z" fill="#FECB03" />
                    <path d="M316.365 65.3671C316.365 67.4757 314.094 69.5843 311.823 71.6929C300.465 88.5619 280.023 96.9963 261.851 92.7791C250.494 103.322 239.137 113.865 232.323 126.517C220.966 145.494 214.152 166.581 211.88 187.667C211.88 191.884 211.88 193.993 211.88 198.21C211.88 200.318 211.88 204.536 211.88 206.644C211.88 208.753 214.152 210.862 216.423 208.753C218.694 206.644 218.694 204.536 218.694 204.536C223.237 187.667 227.78 172.906 239.137 158.146C257.309 132.843 282.294 111.757 307.28 99.1049C345.894 80.1274 393.594 73.8015 432.208 88.5618C436.751 90.6705 452.651 94.8877 436.751 84.3446C434.479 82.236 432.208 82.236 429.936 80.1274C395.865 63.2585 354.98 56.9326 316.365 65.3671Z" fill="#FECB03" />
                    <path d="M48.3381 227.73C74.682 227.73 96.0379 207.905 96.0379 183.449C96.0379 158.994 74.682 139.169 48.3381 139.169C21.9942 139.169 0.638306 158.994 0.638306 183.449C0.638306 207.905 21.9942 227.73 48.3381 227.73Z" fill="#FECB03" />
                    <path d="M96.0379 153.929C98.3093 156.038 100.581 158.146 100.581 160.255C109.666 177.124 107.395 198.21 93.7665 210.861C96.0379 225.622 102.852 238.273 109.666 253.034C121.023 272.011 136.923 288.88 157.366 299.423C159.638 301.532 164.18 303.641 166.452 305.749C168.723 307.858 170.995 307.858 173.266 307.858C175.538 307.858 177.809 307.858 177.809 305.749C177.809 303.641 175.538 305.749 173.266 301.532C159.638 290.989 148.281 278.337 139.195 261.468C123.295 234.056 118.752 204.536 118.752 177.124C121.024 137.06 136.923 94.8877 173.266 71.6929C177.809 69.5843 189.166 59.0413 170.995 65.3671C168.723 65.3671 166.452 67.4757 164.18 69.5843C130.109 88.5618 105.124 120.191 96.0379 153.929Z" fill="#FECB03" />
                    <path d="M73.3238 463.895C99.6677 463.895 121.024 444.07 121.024 419.614C121.024 395.159 99.6677 375.333 73.3238 375.333C46.9799 375.333 25.624 395.159 25.624 419.614C25.624 444.07 46.9799 463.895 73.3238 463.895Z" fill="#FECB03" />
                    <path d="M68.781 371.116C71.0524 371.116 73.3238 371.116 77.8667 371.116C98.3095 371.116 116.481 383.768 123.295 400.637C139.195 404.854 155.095 406.963 170.995 406.963C195.98 406.963 218.695 400.637 236.866 392.202C239.137 390.094 243.68 387.985 245.952 387.985C248.223 385.876 250.495 385.876 252.766 383.768C255.037 381.659 255.037 379.551 252.766 379.551C250.495 379.551 248.223 379.551 248.223 379.551C232.323 383.768 214.152 387.985 193.709 387.985C161.909 387.985 130.109 377.442 102.852 362.682C66.5096 341.595 34.7097 307.858 30.1669 267.794C30.1669 263.577 25.624 248.816 23.3526 265.685C23.3526 267.794 23.3526 269.903 23.3526 274.12C23.3526 307.858 41.524 343.704 68.781 371.116Z" fill="#FECB03" />
                    <path d="M309.551 563C335.895 563 357.251 543.175 357.251 518.719C357.251 494.263 335.895 474.438 309.551 474.438C283.207 474.438 261.851 494.263 261.851 518.719C261.851 543.175 283.207 563 309.551 563Z" fill="#FECB03" />
                    <path d="M257.309 497.633C257.309 495.525 259.58 493.416 261.852 491.307C273.209 474.438 291.38 466.004 311.823 470.221C323.18 459.678 332.266 449.135 341.351 434.375C352.708 415.397 359.523 394.311 359.523 373.225C359.523 369.008 359.523 366.899 359.523 362.682C359.523 360.573 359.523 356.356 357.251 354.247C357.251 352.139 354.98 350.03 352.708 352.139C350.437 354.247 350.437 356.356 350.437 356.356C345.894 373.225 341.351 387.985 332.266 404.854C314.094 430.158 291.38 451.244 264.123 463.895C225.509 482.873 180.08 491.307 139.195 476.547C134.652 474.438 118.752 470.221 134.652 480.764C136.924 482.873 139.195 482.873 141.466 484.982C177.809 501.85 220.966 508.176 257.309 497.633Z" fill="#FECB03" />
                    <path d="M516.25 425.94C542.594 425.94 563.95 406.115 563.95 381.659C563.95 357.204 542.594 337.378 516.25 337.378C489.907 337.378 468.551 357.204 468.551 381.659C468.551 406.115 489.907 425.94 516.25 425.94Z" fill="#FECB03" />
                    <path d="M468.551 409.071C466.279 406.963 464.008 404.854 464.008 402.745C452.651 385.876 457.193 364.79 468.551 352.139C466.279 337.378 459.465 324.727 452.651 309.966C441.294 290.989 425.394 274.12 404.951 263.577C402.679 261.468 398.137 259.36 395.865 259.36C393.594 257.251 391.322 257.251 389.051 257.251C386.779 257.251 384.508 257.251 384.508 259.36C384.508 261.468 386.779 261.468 389.051 263.577C402.679 274.12 414.036 286.772 423.122 303.641C439.022 331.053 445.836 360.573 443.565 387.985C441.294 428.049 425.394 470.221 389.051 493.416C384.508 495.524 373.151 506.068 391.322 499.742C393.594 499.742 395.865 497.633 398.137 495.524C434.479 476.547 459.465 444.918 468.551 409.071Z" fill="#FECB03" />
                </g>
                <g style={{ mixBlendMode: "darken" }}>
                    <path d="M840.982 151.875L906.594 352.46L970.332 151.875H1062.19V442.442H992.827V374.956L1000.33 236.233L930.965 442.442H884.099L812.863 236.233L820.361 374.956V442.442H751V151.875H840.982Z" fill="black" />
                    <path d="M1287.14 316.842H1176.54V386.204H1307.77V440.568H1107.18V151.875H1307.77V206.239H1176.54V268.102H1287.14V316.842Z" fill="black" />
                    <path d="M1515.85 316.842H1405.25V386.204H1534.6V440.568H1335.88V151.875H1536.47V206.239H1405.25V268.102H1515.85V316.842Z" fill="black" />
                    <path d="M1633.95 341.212V440.568H1564.59V150H1680.82C1703.31 150 1722.06 153.749 1738.93 163.122C1755.8 170.621 1768.92 183.743 1778.3 198.74C1787.67 213.737 1793.29 230.609 1793.29 249.355C1793.29 277.475 1783.92 299.97 1763.3 316.842C1742.68 333.714 1714.56 343.087 1678.94 343.087L1633.95 341.212ZM1633.95 288.723H1678.94C1692.06 288.723 1703.31 284.973 1710.81 279.35C1718.31 271.851 1722.06 262.478 1722.06 251.23C1722.06 238.108 1718.31 226.86 1710.81 219.361C1703.31 211.863 1693.94 206.239 1680.82 206.239H1633.95V288.723Z" fill="black" />
                    <path d="M1900.15 386.204H2022V440.568H1830.79V151.875H1900.15V386.204Z" fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_0_1">
                        <rect width="2022" height="563" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}

export { FullLogo };


const Logo = () => {
    return (
        <>
            <svg width="100%" height="100%" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_0_1)">
                    <path d="M61.8145 24.081C65.0881 24.081 67.7419 21.6465 67.7419 18.6434C67.7419 15.6402 65.0881 13.2057 61.8145 13.2057C58.5409 13.2057 55.8871 15.6402 55.8871 18.6434C55.8871 21.6465 58.5409 24.081 61.8145 24.081Z" fill="#FECB03" />
                    <path d="M62.0968 25.1168C61.8145 25.1168 61.5323 25.1168 60.9677 25.1168C58.4274 25.1168 56.1693 23.5632 55.3226 21.4917C53.3468 20.9738 51.371 20.7149 49.3952 20.7149C46.2903 20.7149 43.4677 21.4917 41.2097 22.5274C40.9274 22.7864 40.3629 22.7864 40.0806 23.0453C39.7984 23.3042 39.5161 23.3042 39.2339 23.5632C38.9516 23.8221 38.9516 24.081 39.2339 24.081C39.5161 24.081 39.7984 24.081 39.7984 24.081C41.7742 23.5632 44.0323 23.0453 46.5726 23.0453C50.5242 23.3042 54.4758 24.34 57.5806 26.1525C62.0968 28.7419 66.0484 32.8848 66.6129 37.8046C66.6129 38.3225 67.1774 40.1351 67.4597 38.0636C67.4597 37.8046 67.4597 37.5457 67.4597 37.0278C67.7419 32.6259 65.7661 28.224 62.0968 25.1168Z" fill="#FECB03" />
                    <path d="M32.7419 10.8753C36.0156 10.8753 38.6694 8.44078 38.6694 5.43765C38.6694 2.43452 36.0156 9.53674e-07 32.7419 9.53674e-07C29.4683 9.53674e-07 26.8145 2.43452 26.8145 5.43765C26.8145 8.44078 29.4683 10.8753 32.7419 10.8753Z" fill="#FECB03" />
                    <path d="M39.2339 8.02701C39.2339 8.28595 38.9516 8.54489 38.6693 8.80382C37.2581 10.8753 34.7177 11.9111 32.4597 11.3932C31.0484 12.6879 29.6371 13.9825 28.7903 15.5362C27.379 17.8666 26.5323 20.4559 26.25 23.0453C26.25 23.5632 26.25 23.8221 26.25 24.34C26.25 24.5989 26.25 25.1168 26.25 25.3757C26.25 25.6346 26.5322 25.8936 26.8145 25.6346C27.0968 25.3757 27.0968 25.1168 27.0968 25.1168C27.6613 23.0453 28.2258 21.2327 29.6371 19.4202C31.8952 16.313 35 13.7236 38.1048 12.17C42.9032 9.83956 48.8306 9.06276 53.629 10.8753C54.1935 11.1342 56.1693 11.6521 54.1935 10.3574C53.9113 10.0985 53.629 10.0985 53.3468 9.83956C49.1129 7.76808 44.0322 6.99127 39.2339 8.02701Z" fill="#FECB03" />
                    <path d="M5.92742 27.9651C9.20104 27.9651 11.8548 25.5305 11.8548 22.5274C11.8548 19.5243 9.20104 17.0898 5.92742 17.0898C2.65379 17.0898 -9.53674e-07 19.5243 -9.53674e-07 22.5274C-9.53674e-07 25.5305 2.65379 27.9651 5.92742 27.9651Z" fill="#FECB03" />
                    <path d="M11.8548 18.9023C12.1371 19.1612 12.4194 19.4202 12.4194 19.6791C13.5484 21.7506 13.2661 24.34 11.5726 25.8936C11.8548 27.7061 12.7016 29.2597 13.5484 31.0723C14.9597 33.4027 16.9355 35.4742 19.4758 36.7689C19.7581 37.0278 20.3226 37.2868 20.6048 37.5457C20.8871 37.8046 21.1694 37.8046 21.4516 37.8046C21.7339 37.8046 22.0161 37.8046 22.0161 37.5457C22.0161 37.2868 21.7339 37.5457 21.4516 37.0278C19.7581 35.7331 18.3468 34.1795 17.2177 32.108C15.2419 28.7419 14.6774 25.1168 14.6774 21.7506C14.9597 16.8308 16.9355 11.6521 21.4516 8.80382C22.0161 8.54488 23.4274 7.2502 21.1694 8.02701C20.8871 8.02701 20.6048 8.28594 20.3226 8.54488C16.0887 10.8753 12.9839 14.7593 11.8548 18.9023Z" fill="#FECB03" />
                    <path d="M9.03226 56.9659C12.3059 56.9659 14.9597 54.5314 14.9597 51.5282C14.9597 48.5251 12.3059 46.0906 9.03226 46.0906C5.75864 46.0906 3.10484 48.5251 3.10484 51.5282C3.10484 54.5314 5.75864 56.9659 9.03226 56.9659Z" fill="#FECB03" />
                    <path d="M8.46774 45.5727C8.75 45.5727 9.03226 45.5727 9.59678 45.5727C12.1371 45.5727 14.3952 47.1263 15.2419 49.1978C17.2177 49.7157 19.1935 49.9746 21.1694 49.9746C24.2742 49.9746 27.0968 49.1978 29.3548 48.1621C29.6371 47.9031 30.2016 47.6442 30.4839 47.6442C30.7661 47.3853 31.0484 47.3853 31.3306 47.1263C31.6129 46.8674 31.6129 46.6085 31.3306 46.6085C31.0484 46.6085 30.7661 46.6085 30.7661 46.6085C28.7903 47.1263 26.5323 47.6442 23.9919 47.6442C20.0403 47.6442 16.0887 46.3495 12.7016 44.537C8.18548 41.9476 4.23387 37.8046 3.66936 32.8849C3.66936 32.367 3.10484 30.5544 2.82258 32.6259C2.82258 32.8849 2.82258 33.1438 2.82258 33.6617C2.82258 37.8046 5.08065 42.2065 8.46774 45.5727Z" fill="#FECB03" />
                    <path d="M38.3871 69.1358C41.6607 69.1358 44.3145 66.7013 44.3145 63.6982C44.3145 60.6951 41.6607 58.2606 38.3871 58.2606C35.1135 58.2606 32.4597 60.6951 32.4597 63.6982C32.4597 66.7013 35.1135 69.1358 38.3871 69.1358Z" fill="#FECB03" />
                    <path d="M31.8952 61.1088C31.8952 60.8499 32.1774 60.591 32.4597 60.332C33.871 58.2606 36.129 57.2248 38.6694 57.7427C40.0806 56.448 41.2097 55.1533 42.3387 53.3408C43.75 51.0104 44.5968 48.421 44.5968 45.8316C44.5968 45.3138 44.5968 45.0548 44.5968 44.537C44.5968 44.278 44.5968 43.7602 44.3145 43.5012C44.3145 43.2423 44.0323 42.9833 43.75 43.2423C43.4677 43.5012 43.4677 43.7602 43.4677 43.7602C42.9032 45.8316 42.3387 47.6442 41.2097 49.7157C38.9516 52.8229 36.129 55.4123 32.7419 56.9659C27.9435 59.2963 22.2984 60.332 17.2177 58.5195C16.6532 58.2606 14.6774 57.7427 16.6532 59.0374C16.9355 59.2963 17.2177 59.2963 17.5 59.5552C22.0161 61.6267 27.379 62.4035 31.8952 61.1088Z" fill="#FECB03" />
                    <path d="M64.0726 52.305C67.3462 52.305 70 49.8705 70 46.8674C70 43.8642 67.3462 41.4297 64.0726 41.4297C60.799 41.4297 58.1452 43.8642 58.1452 46.8674C58.1452 49.8705 60.799 52.305 64.0726 52.305Z" fill="#FECB03" />
                    <path d="M58.1451 50.2335C57.8629 49.9746 57.5806 49.7157 57.5806 49.4567C56.1693 47.3852 56.7339 44.7959 58.1451 43.2423C57.8629 41.4297 57.0161 39.8761 56.1693 38.0636C54.7581 35.7331 52.7822 33.6617 50.2419 32.367C49.9597 32.108 49.3951 31.8491 49.1129 31.8491C48.8306 31.5902 48.5484 31.5902 48.2661 31.5902C47.9839 31.5902 47.7016 31.5902 47.7016 31.8491C47.7016 32.108 47.9839 32.108 48.2661 32.367C49.9597 33.6617 51.371 35.2153 52.5 37.2868C54.4758 40.6529 55.3226 44.278 55.0403 47.6442C54.7581 52.564 52.7822 57.7427 48.2661 60.591C47.7016 60.8499 46.2903 62.1446 48.5484 61.3678C48.8306 61.3678 49.1129 61.1088 49.3951 60.8499C53.9113 58.5195 57.0161 54.6355 58.1451 50.2335Z" fill="#FECB03" />
                </g>
                <defs>
                    <clipPath id="clip0_0_1">
                        <rect width="70" height="70" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}

export { Logo };

