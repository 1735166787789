import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import history from "../../../../history";
import UnauthorizedBase from "../../Base/views/UnauthorizedBase";
import { Grid } from "@mui/material";
import LockSvg from "../../../../svg/LockSvg";
import { useState } from "react";
import { validateWorkspaceHelper } from "../helper/SigninHelper";
import { LoadingButton } from "@mui/lab";
import EmailSvg from "../../../../svg/EmailSvg";
import { InputAdornment, IconButton } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function ForgotWorkspace() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [viewMode, setViewMode] = useState("forgotWorkspace");
  const [showPassword, setShowPassword] = useState(false);
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate(inputData)) {
      setLoading(true);
      const data = new FormData(event.currentTarget);
      validateWorkspaceHelper({
        email: data.get("email"),
        password: data.get("password"),
      }).then((res) => {
        setLoading(false);
        res?.message?.statuscode === 200 && setViewMode("emailSentView");
      });
    }
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email === "" ? "Email is required" : "";
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password === "" ? "Password is required" : "";
    }

    if ("domain" in fieldValues) {
      temp.domain = fieldValues.domain === "" ? "Workspace is required" : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  return (
    <UnauthorizedBase>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
          marginTop="48px"
        >
          {viewMode === "forgotWorkspace" && (
            <Grid item>
              <Box
                sx={{
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "400px",
                }}
              >
                <div style={{ marginBottom: "40px", height: "96px" }}>
                  <LockSvg />
                </div>
                <Typography
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                >
                  Forgot your workspace?
                </Typography>
                <Typography
                  color="text.secondary"
                  textAlign={"center"}
                  sx={{
                    fontWeight: 600,
                    fontSize: "0.775rem",
                  }}
                >
                  Please enter the email address associated with your account We
                  will send workspace.
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleInputChange}
                    {...(errors.email && {
                      error: true,
                      helperText: errors.email,
                    })}
                  />

                  <TextField
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    id="password"
                    autoComplete="password"
                    label="Password"
                    onChange={handleInputChange}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    {...(errors.password && {
                      error: true,
                      helperText: errors.password,
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* /> */}
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color:grey[50],
                      ":hover": { backgroundColor: "#f9c307",color:"#000000"},
                    }}
                  >
                    Send Request
                  </LoadingButton>
                  <Button
                    className="mpl-secondary-btn"
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2, ":hover": { color: grey[50] } }}
                    onClick={() => history.push("/signup")}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
          {viewMode === "emailSentView" && (
            <Grid item>
              <Box
                sx={{
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "400px",
                }}
              >
                <div style={{ marginBottom: "40px", height: "96px" }}>
                  <EmailSvg />
                </div>
                <Typography
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                >
                  Please check your email!
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  color="text.secondary"
                >
                  We have sent a workspace name to your email ID{" "}
                  <span style={{ fontWeight: 600 }}>{inputData?.email} </span> ,
                  please open your email and see the workspace allocated for
                  you.
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    color:grey[50],
                    ":hover": { backgroundColor: "#f9c307",color:"#000000"},
                  }}
                  onClick={() => history.push("/signup")}
                >
                  Back to login
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </UnauthorizedBase>
  );
}
