import {
  Box,
  Typography,
  IconButton,
  FormControl,
  Tab,
  Grid,
  TableFooter,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import CommonDatePicker from "../../../../Base/views/CommonDatePicker";
import MyTimeSheetForm from "./MyTimeSheetForm";
import { styled } from "@mui/material/styles";
import { GetMyTimeSheetAction } from "../../../../../../store/Leave/MyTimeSheet/Action";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderCollapse: "collapse",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "12px", // Adding padding of 8 for body cells
  },
}));

const MyTimeSheetGrid = () => {
  const classes = useStyles();
  const currentDate = new Date(); // Current date
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const first_date = `${firstDayOfMonth.getFullYear()}-${(
    firstDayOfMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${firstDayOfMonth
    .getDate()
    .toString()
    .padStart(2, "0")}`;
  const end_date = `${lastDayOfMonth.getFullYear()}-${(
    lastDayOfMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    from_date: first_date,
    to_date: end_date,
  });
  const [value, setValue] = useState("1");
  const [timesheetData, setTimesheetData] = useState([]);
  const [tableData, setTableData] = useState();
  const [formMode, setFormMode] = useState("card");
  const [successMode, setSuccessMode] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { mytime_sheet_options, GetSuccess } = useSelector(
    (state) => state?.LeaveReducer?.MyTimeSheetReducer
  );
  const columns = [
    { id: "timeoff", label: "Time Off", align: "center", minWidth: 170 },
    { id: "work", label: "Work + Time Off", align: "center", minWidth: 100 },
  ];
  const totalTimeOff = timesheetData?.reduce((total, item) => {
    return total + (item.total_time_off ? parseFloat(item.total_time_off) : 0);
  }, 0);

  const totalWorkTime = timesheetData?.reduce((total, item) => {
    return (
      total + (item.total_work_time ? parseFloat(item.total_work_time) : 0)
    );
  }, 0);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const lastThreeYearsMonths = [];

    for (let year = currentYear - 2; year <= currentYear; year++) {
      for (let month = 0; month < 12; month++) {
        const date = new Date(year, month);
        const monthName = date.toLocaleString("en-US", { month: "short" });
        const yearMonth = `${monthName} ${year}`;

        lastThreeYearsMonths.push({
          ats_users_id: `${year}_${month}`, // replace with a unique identifier
          first_name: yearMonth,
        });
      }
    }
    const fromdateObject = new Date(filters?.from_date);
    const todateObject = new Date(filters?.to_date);

    const from_day = fromdateObject.getDate();
    const from_month = fromdateObject.getMonth() + 1;
    const from_year = fromdateObject.getFullYear();

    const to_day = todateObject.getDate();
    const to_month = todateObject.getMonth() + 1;
    const to_year = todateObject.getFullYear();
    dispatch(
      GetMyTimeSheetAction({
        from_date_day: from_day,
        from_date_month: from_month,
        from_date_year: from_year,
        to_date_day: to_day,
        to_date_month: to_month,
        to_date_year: to_year,
        my_time_flag: true
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, successMode]);
  useEffect(() => {
    if (GetSuccess === 200) {
      setTimesheetData(mytime_sheet_options);
    }
  }, [GetSuccess]);

  const { current_time } = useSelector(
    (state) => state?.LeaveReducer?.CurrentTimeReducer
  );
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (timesheetData.length > 0 && location?.state?.click_event === "2") {
      const currentTimeSheetData = timesheetData.filter((el) => el?.start_date === current_time?.[0]?.start_date);
      if (currentTimeSheetData.length > 0) {
        handleOpen(currentTimeSheetData[0]);
        history.replace({ ...location, state: {} }); 
      }
    }
  }, [timesheetData, current_time]);

  const handleFilter = (event, value) => {
    if (["months"].includes(event)) {
      setFilters({ ...filters, [event]: value });
    }
    if (["from_date", "to_date"].includes(event?.target?.name)) {
      setFilters({ ...filters, [event?.target?.name]: event?.target?.value });
    }
  };

  const handleChange = (event, newValue, calendarValue) => {
    setValue(newValue);
    if (newValue === "1") {
      setTimesheetData(mytime_sheet_options);
    }
    if (newValue === "2") {
      const notSubmittedEntries = mytime_sheet_options?.filter(
        (item) => item.flag === "Not Submitted"
      );
      setTimesheetData(notSubmittedEntries);
    } else if (newValue === "3") {
      const RejectedEntries = mytime_sheet_options?.filter(
        (item) => item.flag === "Rejected"
      );
      setTimesheetData(RejectedEntries);
    } else if (newValue === "4") {
      const WaitingEntries = mytime_sheet_options?.filter(
        (item) => item.flag === "Submitted"
      );
      setTimesheetData(WaitingEntries);
    } else if (newValue === "5") {
      const ApprovedEntries = mytime_sheet_options?.filter(
        (item) => item.flag === "Approved"
      );
      setTimesheetData(ApprovedEntries);
    } else if (newValue === "6") {
      const ReopendEntries = mytime_sheet_options?.filter(
        (item) => item.flag === "Reopened"
      );
      setTimesheetData(ReopendEntries);
    }
  };
  const handlePrevMonth = () => {
    const prevMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    );
    const firstDayOfPrevMonth = `${prevMonth.getFullYear()}-${(
      "0" +
      (prevMonth.getMonth() + 1)
    ).slice(-2)}-01`;
    const lastDayOfPrevMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      0
    );
    const lastDayFormatted = `${lastDayOfPrevMonth.getFullYear()}-${(
      "0" +
      (lastDayOfPrevMonth.getMonth() + 1)
    ).slice(-2)}-${("0" + lastDayOfPrevMonth.getDate()).slice(-2)}`;
    setFilters({
      ...filters,
      from_date: firstDayOfPrevMonth,
      to_date: lastDayFormatted,
    });
    setCurrentMonth(prevMonth);
  };
  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      1
    );
    const firstDayOfNextMonth = `${nextMonth.getFullYear()}-${(
      "0" +
      (nextMonth.getMonth() + 1)
    ).slice(-2)}-01`;
    const lastDayOfNextMonth = new Date(
      nextMonth.getFullYear(),
      nextMonth.getMonth() + 1,
      0
    );
    const lastDayFormatted = `${lastDayOfNextMonth.getFullYear()}-${(
      "0" +
      (lastDayOfNextMonth.getMonth() + 1)
    ).slice(-2)}-${("0" + lastDayOfNextMonth.getDate()).slice(-2)}`;
    setFilters({
      ...filters,
      from_date: firstDayOfNextMonth,
      to_date: lastDayFormatted,
    });
    setCurrentMonth(nextMonth);
  };
  const handleOpen = (data) => {
    setTableData(data);
    setFormMode("Form");
    setSuccessMode(false);
  };

  const All = () => {
    return (
      <>
        {" "}
        <Typography> {mytime_sheet_options?.length} </Typography>{" "}
      </>
    );
  };
  const NotSubmit = () => {
    const notSubmittedEntries = mytime_sheet_options?.filter(
      (item) => item.flag === "Not Submitted"
    );
    const notSubmittedCount = notSubmittedEntries?.length;
    return (
      <>
        {" "}
        <Typography>{notSubmittedCount} </Typography>{" "}
      </>
    );
  };
  const Reject = () => {
    const RejectedEntries = mytime_sheet_options?.filter(
      (item) => item.flag === "Rejected"
    );
    const RejectedCount = RejectedEntries?.length;
    return (
      <>
        {" "}
        <Typography>{RejectedCount}</Typography>{" "}
      </>
    );
  };
  const Waiting = () => {
    const WaitingEntries = mytime_sheet_options?.filter(
      (item) => item.flag === "Submitted"
    );
    const WaitingCount = WaitingEntries?.length;
    return (
      <>
        {" "}
        <Typography>{WaitingCount}</Typography>{" "}
      </>
    );
  };
  const Approved = () => {
    const ApprovedEntries = mytime_sheet_options?.filter(
      (item) => item.flag === "Approved"
    );
    const ApprovedCount = ApprovedEntries?.length;
    return (
      <>
        {" "}
        <Typography> {ApprovedCount}</Typography>{" "}
      </>
    );
  };
  const Reopened = () => {
    const ReopeneEntries = mytime_sheet_options?.filter(
      (item) => item.flag === "Reopened"
    );
    const ReOpenCount = ReopeneEntries?.length;
    return (
      <>
        {" "}
        <Typography> {ReOpenCount}</Typography>{" "}
      </>
    );
  };

  return ["Form"].includes(formMode) ? (
    <MyTimeSheetForm
      setFormMode={setFormMode}
      tableData={tableData}
      setSuccessMode={setSuccessMode}
    />
  ) : (
    <div>
      <Grid pt={3} pb={2} container>
        <Grid pr={1} item xs={12} sm={3.5}>
          <IconButton onClick={handlePrevMonth}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <FormControl>
            <CommonDatePicker
              name="from_date"
              label="From Date"
              size="small"
              pickerType="date"
              onKeyPress={(e) => e.preventDefault()}
              onKeyDown={(e) => e.preventDefault()}
              pickerFormat="dd/MM/yyyy"
              DefaultDTvalue={filters.from_date}
              handleChange={(value) =>
                handleFilter({
                  target: {
                    name: "from_date",
                    value: value
                      ? `${
                          value?.getFullYear() +
                          "-" +
                          ("0" + (value?.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + value?.getDate()).slice(-2)
                        }`
                      : "",
                  },
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid pr={1} item xs={12} sm={3.5}>
          <FormControl>
            <CommonDatePicker
              name="to_date"
              label="To Date"
              pickerType="date"
              size="small"
              onKeyPress={(e) => e.preventDefault()}
              onKeyDown={(e) => e.preventDefault()}
              pickerFormat="dd/MM/yyyy"
              DefaultDTvalue={filters.to_date}
              handleChange={(value) =>
                handleFilter({
                  target: {
                    name: "to_date",
                    value: value
                      ? `${
                          value?.getFullYear() +
                          "-" +
                          ("0" + (value?.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + value?.getDate()).slice(-2)
                        }`
                      : "",
                  },
                })
              }
            />
          </FormControl>
          <IconButton onClick={handleNextMonth}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
            >
              <Tab
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                icon={<All />}
                label="All"
                value="1"
              />
              <Tab
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                icon={<NotSubmit />}
                label="Not Submit"
                value="2"
              />
              <Tab
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                icon={<Reject />}
                label="Rejected"
                value="3"
              />
              <Tab
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                icon={<Waiting />}
                label="Submitted"
                value="4"
              />
              <Tab
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                icon={<Approved />}
                label="Approved"
                value="5"
              />
              <Tab
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                icon={<Reopened />}
                label="Reopened"
                value="6"
              />
            </TabList>
          </Box>
        </TabContext>
      </Box>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" rowSpan={2}>
                  Timesheet Period
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={2}>
                  Approval Status
                </StyledTableCell>
                <StyledTableCell align="center" colSpan={2}>
                  Entered Time
                </StyledTableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            {timesheetData?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <StyledTableCell colSpan={6} align="center">
                    No Records found
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {timesheetData?.map((item, index) => (
                  <TableRow key={index}>
                    <StyledTableCell
                      sx={{ cursor: "pointer", color: "blue" }}
                      onClick={() => handleOpen(item)}
                      align="center"
                    >{`${item.start_date} - ${item.end_date}`}</StyledTableCell>
                    <StyledTableCell align="center">
                      {item?.flag}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item?.total_time_off}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item?.total_work_time}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            <TableFooter
              sx={{ backgroundColor: "#8b44ff", color: "#fff !important" }}
            >
              <TableRow>
                <StyledTableCell
                  sx={{ color: "#fff !important" }}
                  align="center"
                  colSpan={2}
                >
                  Total
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: "#fff !important" }}
                  align="center"
                >
                  {totalTimeOff}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: "#fff !important" }}
                  align="center"
                >
                  {totalWorkTime}
                </StyledTableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default MyTimeSheetGrid;
