import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect } from 'react';
import { AddSalaryComponentAction, DeleteSalaryComponentAction, GetSalaryComponentAction, UpdateSalaryComponentAction } from '../../../../../store/Finance/SalaryComponent/Action';
import { GetSalaryComponentTypesAction } from '../../../../../store/Finance/SalaryComponentType/Action';

const SalaryComponent = ({ structureData }) => {
    const dispatch = useDispatch();
    const { salaryComponent_data } = useSelector(state => state.FinanceReducer?.SalaryComponentReducer);
    const { salaryComponentType_data } = useSelector(state => state.FinanceReducer?.SalaryComponentTypeReducer);

    useEffect(() => {
        structureData?.sal_structure_id && dispatch(GetSalaryComponentAction({ sal_structure_id: structureData?.sal_structure_id }))
        dispatch(GetSalaryComponentTypesAction())
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MaterialTable
                columns={[
                    {
                        title: "Name", cellStyle: { padding: "0px 10px" }, field: "name", validate: rowData => rowData?.name === '' || rowData?.name === undefined ? 'Name is required' : '',
                        editComponent: props => {
                            return (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    disabled={props?.rowData?.is_public === "T"}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        }
                    },
                    {
                        title: "Code", cellStyle: { padding: "0px 10px" }, field: "code", validate: rowData => rowData?.code === '' || rowData?.code === undefined ? 'Code is required' : '',
                        editComponent: props => (
                            <TextField
                                required={true}
                                variant="standard"
                                value={props.value}
                                disabled={props?.rowData?.is_public === "T"}
                                onChange={e => props.onChange(e.target.value)}
                                error={props?.error}
                                helperText={props?.helperText}
                            />
                        ),
                    },
                    {
                        title: "Component Type", cellStyle: { padding: "0px 10px" }, field: "sal_comp_type", validate: rowData => {
                            if (rowData?.sal_comp_type) {
                                return ''
                            } else {
                                return 'Component Type is required'
                            }
                        }, editComponent: props => (
                            <Autocomplete
                                defaultValue={{ name: props.rowData?.sal_comp_type || "", id: props.rowData?.sal_comp_type_id || "" }}
                                id="businessunit_id"
                                options={salaryComponentType_data}
                                onChange={(e, data) => { props.onChange(data) }}
                                size="small"
                                required={true}
                                disabled={props?.rowData?.is_public === "T"}
                                getOptionLabel={option => { return option?.name }}
                                renderInput={(params) => <TextField size="small" {...params}
                                    variant="standard"
                                    error={props?.error}
                                    helperText={props?.helperText} />}
                            />
                        ),
                    },
                    {
                        title: "Earnings/Deductions", cellStyle: { padding: "0px 10px" }, field: "ed_flag",
                        render: rowData => { return rowData?.ed_flag === "E" ? "Earnings" : rowData?.ed_flag === "D" ? "Deductions" : "" },
                        validate: rowData => {
                            if (rowData?.ed_flag) {
                                return ''
                            } else {
                                return 'Earnings/Deductions is required'
                            }
                        }, editComponent: props => (
                            <Autocomplete
                                defaultValue={{ name: props.rowData?.ed_flag === "E" ? "Earnings" : props.rowData?.ed_flag === "D" && ("Deductions" || ""), id: (props.rowData?.ed_flag || "") }}
                                id="businessunit_id"
                                options={[{ id: "E", name: "Earnings" }, { id: "D", name: "Deductions" }]}
                                onChange={(e, data) => { props.onChange(data) }}
                                size="small"
                                required={true}
                                disabled={props?.rowData?.is_public === "T"}
                                getOptionLabel={option => { return option?.name || "" }}
                                renderInput={(params) => <TextField size="small" {...params}
                                    variant="standard"
                                    error={props?.error}
                                    helperText={props?.helperText} />}
                            />
                        ),
                    },
                    {
                        title: "Calculation Type", cellStyle: { padding: "0px 10px" }, field: "cal_type", validate: rowData => {
                            if (rowData?.cal_type) {
                                return ''
                            } else {
                                return 'Calculation Type is required'
                            }
                        }, editComponent: props => {
                            return (
                                < Autocomplete
                                    defaultValue={{ name: props.rowData?.cal_type || "", id: props.rowData?.cal_type || "" }}
                                    id="businessunit_id"
                                    options={[{ id: "Amount", name: "Amount" }, { id: "Percentage", name: "Percentage" }]}
                                    onChange={(e, data) => { props.onChange(data) }}
                                    size="small"
                                    disabled={props?.rowData?.is_public === "T"}
                                    required={true}
                                    getOptionLabel={option => { return option?.name || "" }}
                                    renderInput={(params) => <TextField size="small" {...params}
                                        variant="standard"
                                        error={props?.error}
                                        helperText={props?.helperText} />}
                                />
                            )
                        },
                    },
                    {
                        title: "Calculation Value", headerStyle: { textAlign: "right" }, cellStyle: { padding: "0px 30px", textAlign: "right" }, field: "cal_value",
                        validate: rowData => {
                            return rowData?.cal_value === '' || rowData?.cal_value === undefined ? 'Calculation Value is required' :
                                (typeof (rowData?.cal_type) === "object" ? rowData?.cal_type?.id === "Percentage" : rowData?.cal_type === "Percentage")
                                    && rowData?.cal_value > 100 ? "Percentage must be below 100" : ""
                        },
                        editComponent: props => (
                            <TextField
                                required={true}
                                type="number"
                                variant="standard"
                                disabled={props?.rowData?.is_public === "T"}
                                value={props.value}
                                onChange={e => e?.target?.value?.lenght >= 9 ? e.preventDefault() : props.onChange(e.target.value)}
                                error={props?.error}
                                helperText={props?.helperText}
                            />
                        ),
                    },
                    {
                        title: "Alias Name", cellStyle: { padding: "0px 10px" }, field: "comp_alias",
                        editComponent: props => (
                            <TextField
                                required={false}
                                variant="standard"
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}
                            />
                        ),
                    },
                ]}
                data={salaryComponent_data || []}
                icons={{
                    Add: () => <AddIcon />,
                    Edit: () => <EditIcon />,
                    Delete: () => <DeleteIcon />
                }}
                editable={{
                    isDeletable: rowData => rowData.is_public !== 'T',
                    onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(AddSalaryComponentAction({ ...newRow, sal_structure_id: structureData?.sal_structure_id }))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(UpdateSalaryComponentAction({ ...newRow, sal_structure_id: structureData?.sal_structure_id }))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowDelete: (selectedRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(DeleteSalaryComponentAction({ sal_structure_id: structureData?.sal_structure_id, salary_component_id: `{${selectedRow?.salary_component_id}}` }))
                            setTimeout(() => resolve(), 1000);
                        }),
                }}
                options={{
                    maxBodyHeight: 420,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                    sorting: true,
                    search: false,
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: false,
                    selection: false,
                    actionsColumnIndex: -1,
                    grouping: false,
                    addRowPosition: "first",
                }}
                title="Salary Components"
            />
        </>
    );
}

export default SalaryComponent;
