import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

// Leave Overall Balance
export const GetLeaveOverallBalanceHelper = async (params) => {
    var data = getUserData();
    toast.dismiss();
    params?.show !== "no" && toast.info("Loading...")
    return await axiosInstance(`/lms/getLeaveOverallBalance`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            employee_id: params?.employee_id || data.employee_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Leave Overall Balance Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch Leave Overall Balance", hasError: false, data: err?.response?.data }
        })
}

// Pending Leave Approval
export const GetPendingLeaveApprovalHelper = async () => {
    var data = getUserData();
    toast.dismiss();
    return await axiosInstance(`/lms/getPendingApproval`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            employee_id: data.employee_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Pending Leave Approval Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch Pending Leave Approval", hasError: false, data: err?.response?.data }
        })
}
