import { combineReducers } from "redux";
import { TaxDeclarationReducer } from "./TaxDeclaration/Reducer";
import { EmployeeProfileReducer } from "./Profile/Reducer";
import { SalaryStructureReducer } from "./SalaryStructure/Reducer";
import { SalaryComponentReducer } from "./SalaryComponent/Reducer";
import { SalaryComponentTypeReducer } from "./SalaryComponentType/Reducer";

const FinanceReducer = combineReducers({
    TaxDeclarationReducer,
    EmployeeProfileReducer,
    SalaryStructureReducer,
    SalaryComponentReducer,
    SalaryComponentTypeReducer
});

export default FinanceReducer;