import * as ActionTypes from "../ActionTypes";

export const ProposeCandidateConfirmationAction = (params) => {
  return {
    type: ActionTypes.PROPOSE_CANDIDATE_CONFIRMATION_REQUEST,
    params,
  };
};

export const GetAtsCandidateByEmailAction = (params) => {
  return {
    type: ActionTypes.ATS_CANDIDATE_BY_EMAIL_GET_REQUEST,
    params,
  };
};

export const GetAtsCandidateAction = (params) => {
  return {
    type: ActionTypes.ATS_CANDIDATE_GET_REQUEST,
    params,
  };
};

export const DeleteAtsCandidateAction = (params) => {
  return {
    type: ActionTypes.ATS_CANDIDATE_DELETE_REQUEST,
    params,
  };
};

export const GetQuickSearchAction = (params) => {
  return {
    type: ActionTypes.ATS_QUICK_SEARCH_GET_REQUSET,
    params,
  };
};

export const AddAtsCandidateAction = (params) => {
  return {
    type: ActionTypes.ATS_CANDIDATE_ADD_REQUEST,
    params,
  };
};

export const UpdateAtsCandidateAction = (params) => {
  return {
    type: ActionTypes.ATS_CANDIDATE_UPDATE_REQUEST,
    params,
  };
};
