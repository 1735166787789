import { Box, Button, Grid, Typography } from "@mui/material"
import NotFoundSvg from "../../../../svg/NotFoundSvg"
import history from "../../../../history"


const TokenNotFound = ({ navigateButton }) => {
    return (
        <Grid item>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: "400px"
                }}
            >
                <Typography textAlign={"center"} component="h1" variant="h3" fontWeight={700} lineHeight={1.5}>
                    Sorry, Token not found or expired!
                </Typography>
                <Typography textAlign={"center"} variant="body1" color="text.secondary">
                    We could not find the token or it may be expired. Please click on Forgot Password button to reset password
                </Typography>

                <div style={{ marginBottom: "40px", height: "196px" }}>
                    <NotFoundSvg />
                </div>
                {navigateButton && navigateButton}
                <Button sx={{ mt: 3 }} onClick={() => history.push("/")}>Back to login</Button>
            </Box>
        </Grid>
    )
}

export default TokenNotFound