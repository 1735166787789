const style = {
    control: {
        backgroundColor: '#fff',
    },
    '&multiLine': {
        control: {
            fontFamily: 'Nunito Sans',
            minHeight: "100px",
            maxHeight: "100px",
            overflow: "auto",
            borderRadius: "10px",
            margin: "20px",
        },
        highlighter: {
            padding: 9,
            overflow: "hidden",
        },
        input: {
            padding: 9,
            borderRadius: "10px",
            border: "1px solid #c7c2c2"
        },
    },
    '&singleLine': {
        display: 'inline-block',
        width: 180,
        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },
        input: {
            padding: 1,
            border: '2px inset',
        },
    },
    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 16,
            borderRadius: "10px"
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#c69ac9',
            },
        },
    },
}
 export default style;