
const UpdateCandidateEmail = ({ proposeCandidateData }) => {

    return (
        <>
            <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
                style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", }}
            >
                <tr></tr>
                <tr></tr>
                <tr style={{ paddingLeft: "5px" }}>Your Form has been submitted Successfully. Please contact Recruiter for Further updates.</tr>
                <tr></tr>
                <tr></tr>
                <tr>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>First Name: </span>
                        <div>{proposeCandidateData?.first_name}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Last Name : </span>
                        <div>{proposeCandidateData?.last_name}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Date of Birth : </span>
                        <div>{proposeCandidateData?.dob}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Email : </span>
                        <div>{proposeCandidateData?.email_id}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Contact : </span>
                        <div>{proposeCandidateData?.contact_no}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Notice Period : </span>
                        <div>{proposeCandidateData?.notice_period}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Expected Pay : </span>
                        <div>{proposeCandidateData?.expected_pay}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Year of Experience : </span>
                        <div>{proposeCandidateData?.years_of_experience}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Skills : </span>
                        <div>{proposeCandidateData?.skills}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Current CTC : </span>
                        <div>{proposeCandidateData?.current_ctc}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span style={{ fontWeight: "bold" }}>Current Employer : </span>
                        <div>{proposeCandidateData?.current_employer}</div>
                    </div>
                </tr>
            </table>
        </>
    )
}

export default UpdateCandidateEmail