import { combineReducers } from "redux";
import { EmployeeBankReducer } from "./Bank/Reducer";
import { EmployeeFamilyReducer } from "./Family/Reducer";
import { EmployeeBasicReducer } from "./Basic/Reducer"
import { EmployeeProfessionalSummaryReducer } from "./ProfessionalSummary/Reducer";
import { EmployeePersonalReducer } from "./Personal/Reducer";
import { EmployeeOtherPersonalReducer } from "./OtherPersonal/Reducer";
import { EmployeesReducer } from "./Employee/Reducer";
import { EmployeeWorkDetailReducer } from "./WorkDetail/Reducer";
import { EmployeePayrollDetailsReducer } from "./Payroll/Reducer";


const EmployeeReducer = combineReducers({
    EmployeesReducer,
    EmployeeBasicReducer,
    EmployeeBankReducer,
    EmployeeFamilyReducer,
    EmployeeProfessionalSummaryReducer,
    EmployeeWorkDetailReducer,
    EmployeePersonalReducer,
    EmployeeOtherPersonalReducer,
    EmployeePayrollDetailsReducer
});

export default EmployeeReducer;