import * as ActionTypes from './ActionTypes';

export const GetUserAccessAction = (data) => {
    return {
        type: ActionTypes.USER_ROLE_ACCESS_REQUEST,
        data
    }
}

export const GetUserInfoAction = (params, dispatch) => {
    return {
        type: ActionTypes.USER_INFO_REQUEST,
        params, dispatch
    }
}