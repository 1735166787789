import * as ActionTypes from '../ActionTypes';

export const GetAtsInterviewDetaiilsAction = (params) => {
    return {
        type: ActionTypes.ATS_INTERVIEW_DETAILS_GET_REQUEST,
        params
    }
}

export const AddAtsInterviewDetaiilsAction = (params) => {
    return {
        type: ActionTypes.ATS_INTERVIEW_DETAILS_ADD_REQUEST,
        params
    }
}
export const UpdateAtsInterviewDetaiilsAction = (params) => {
    return {
        type: ActionTypes.ATS_INTERVIEW_DETAILS_UPDATE_REQUEST,
        params
    }
}
export const DeleteAtsInterviewDetaiilsAction = (params) => {
    return {
        type: ActionTypes.ATS_INTERVIEW_DETAILS_DELETE_REQUEST,
        params
    }
}
