import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetCurrentTimeHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/getCurrentTime`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_id: data?.emp_users_pk,
            current_date_day: params?.current_date_day,
            current_date_month: params?.current_date_month,
            current_date_year: params?.current_date_year
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
