import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ConvertDate } from '../../../../Base/helper/baseFunctions';

const Personal = ({ data }) => {
    return (
        <>
            <Box sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ color: "gray" }}>Personal</Typography>
                <Grid container>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", mt: 1 }}>
                        <Typography sx={{ fontWeight: "600" }}>Name : &nbsp;</Typography>
                        <Typography>{data?.first_name || "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", mt: 1 }}>
                        <Typography sx={{ fontWeight: "600" }}>Gender : &nbsp;</Typography>
                        <Typography>{data?.gender || "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", mt: 1 }}>
                        <Typography sx={{ fontWeight: "600" }}>DOB : &nbsp;</Typography>
                        <Typography>{data?.birthdate ? ConvertDate(data?.birthdate) : "-"}</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ color: "gray" }}>Administrative</Typography>
                <Grid container>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", mt: 1 }}>
                        <Typography sx={{ fontWeight: "600" }}>PAN : &nbsp;</Typography>
                        <Typography>{data?.pan_no || "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", mt: 1 }}>
                        <Typography sx={{ fontWeight: "600" }}>UAN : &nbsp;</Typography>
                        <Typography>{data?.uan_no || "-"}</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ color: "gray" }}>Bank Details</Typography>
                <Grid container>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", mt: 1 }}>
                        <Typography sx={{ fontWeight: "600" }}>Bank : &nbsp;</Typography>
                        <Typography>{data?.bank_name || "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", mt: 1 }}>
                        <Typography sx={{ fontWeight: "600" }}>A/c No : &nbsp;</Typography>
                        <Typography>{data?.bank_account || "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", mt: 1 }}>
                        <Typography sx={{ fontWeight: "600" }}>IFSC : &nbsp;</Typography>
                        <Typography>{data?.ifsc_code || "-"}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Personal;
