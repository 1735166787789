import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../css/style.css";
import {
  ConvertDate,
  ConvertDateTime,
  s3Client,
} from "../../Base/helper/baseFunctions";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AutoModeOutlinedIcon from "@mui/icons-material/AutoModeOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CakeIcon from "@mui/icons-material/Cake";
import MaleAvatar from "../images/Male.jpg";
import FemaleAvatar from "../images/Female.jpg";
import TransgenderAvatar from "../images/Trans.jpg";
import {
  Grid,
  Box,
  Paper,
  CardMedia,
  Card,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Base from "../../Base/views/Base";
import QuickLinks from "./QuickLinks";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { AddMenuHeadingAction } from "../../../../store/Menu/Action";
import history from "../../../../history";
import MyTaskPopup from "../../ATS/views/ATSTask/MyTaskPopup";
import { GetHomeAction } from "../../../../store/EmployeeDashboard/Action";

export default function MyProfile() {
  const [imageUrl, setImageUrl] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [mode, setMode] = useState("");
  const [popupData, setPopupData] = useState([]);
  const { homeData } = useSelector((state) => state?.EmployeeDashboardReducer);
  const { quicklinksData } = useSelector(
    (state) => state?.EmployeeDashboardReducer
  );
  const useStyles = makeStyles((theme) =>
    createStyles({
      Paper: {
        boxShadow:
          "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        borderRadius: "25px",
      },
      profileItem: {
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
      },
    })
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetHomeAction());
    getImageSignedUrl(homeData?.[0]?.image);
    //eslint-disable-next-line
  }, []);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const handleTaskClick = (menu, value) => {
    sessionStorage.setItem("selectedMenu", menu);
    dispatch(AddMenuHeadingAction({ heading: menu }));
    history.push({
      pathname: "/ats/tasks/",
      state: { defaultValue: value },
    });
  };

  const handleAdd = () => {
    setMode("Add");
    setOpenPopup(true);
  };

  return (
    <div className="home">
      <Base>
        <MyTaskPopup
          my_task={true}
          mode={mode}
          setMode={setMode}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          data={popupData}
          setData={setPopupData}
          home={true}
        />
        <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
          <Grid container>
            <Grid item xs={12} sx={{ paddingRight: "1.5rem" }}>
              <Paper elevation={1} className={classes.Paper}>
                <Card>
                  <CardMedia className="background_img"></CardMedia>
                  <CardContent
                    sx={{
                      display: "flex",
                      gap: "1.5rem",
                      justifyContent: { xs: "center", md: "flex-start" },
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: { xs: "center", md: "flex-start" },
                      paddingTop: "30px",
                    }}
                  >
                    <Box sx={{ display: "flex" }} className="Profile">
                      {homeData?.[0]?.image ? (
                        <img
                          width={120}
                          height={120}
                          style={{ padding: "4px 4px", borderRadius: "6px" }}
                          alt="Nothing"
                          src={imageUrl}
                        />
                      ) : (
                        <img
                          width={120}
                          height={120}
                          style={{ padding: "4px 4px", borderRadius: "6px" }}
                          alt="Nothing"
                          src={
                            homeData?.[0]?.gender === "Female"
                              ? FemaleAvatar
                              : homeData?.[0]?.gender === "Transgender"
                              ? TransgenderAvatar
                              : MaleAvatar
                          }
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: { xs: "center", md: "flex-start" },
                        gap: 5,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "1.25rem",
                          flexDirection: { xs: "column", md: "row" },
                          flexWrap: "wrap",
                          alignItems: { xs: "center", md: "end" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: ".5rem",
                            flexDirection: "column",
                            alignItems: { xs: "center", md: "flex-start" },
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: { xs: "center", md: "left" },
                            }}
                            variant="h4"
                          >
                            {homeData?.[0]?.name || homeData?.[0]?.user_name}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              gap: "1.25rem",
                            }}
                          >
                            {homeData?.[0]?.user_creation_date && (
                              <div className={classes.profileItem}>
                                <AccessTimeIcon
                                  fontSize="small"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "rgb(46 38 61 / 70%)",
                                  }}
                                />
                                <Typography variant="body1" fontWeight="500">
                                  <span style={{ fontStyle: "italic" }}>
                                    {" "}
                                    User Since: &nbsp;
                                  </span>
                                  {ConvertDateTime(
                                    homeData?.[0]?.user_creation_date
                                  )}
                                </Typography>
                              </div>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={5}
              sx={{
                paddingRight: "1.5rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              {["owner", "Owner", "Admin", "Internal", null].includes(
                homeData?.[0]?.user_type
              ) && (
                <Grid container spacing={6} sx={{ marginBottom: "24px" }}>
                  <Grid item xs={12}>
                    <Paper elevation={1} className={classes.Paper}>
                      <Card>
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1rem",
                            }}
                          >
                            <span
                              style={{
                                color: "#212B36",
                                fontWeight: "800",
                                letterSpacing: "0.4px",
                              }}
                            >
                              {" "}
                              ABOUT
                            </span>
                            {homeData?.[0]?.employee_no && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: ".5rem",
                                }}
                              >
                                <Grid3x3Icon
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    color: "rgb(46 38 61 / 70%)",
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Employee No:
                                  </p>
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {homeData?.[0]?.employee_no}
                                  </p>
                                </Box>
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: ".5rem",
                              }}
                            >
                              <PersonOutlineIcon
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                  color: "rgb(46 38 61 / 70%)",
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: ".5rem",
                                  flexWrap: "wrap",
                                }}
                              >
                                <p
                                  style={{
                                    color: "rgb(46 38 61 / 70%)",
                                    fontWeight: "500",
                                  }}
                                >
                                  Full Name:
                                </p>
                                <p
                                  style={{
                                    color: "rgb(46 38 61 / 70%)",
                                    fontWeight: "400",
                                  }}
                                >
                                  {homeData?.[0]?.name}
                                </p>
                              </Box>
                            </Box>

                            {homeData?.[0]?.birthdate && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: ".5rem",
                                }}
                              >
                                <CakeIcon
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    color: "rgb(46 38 61 / 70%)",
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Date of Birth:
                                  </p>
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {ConvertDate(homeData?.[0]?.birthdate)}
                                  </p>
                                </Box>
                              </Box>
                            )}

                            {homeData?.[0]?.personal_mobile && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: ".5rem",
                                }}
                              >
                                <PhoneOutlinedIcon
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    color: "rgb(46 38 61 / 70%)",
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Contact:
                                  </p>
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {homeData?.[0]?.personal_mobile}
                                  </p>
                                </Box>
                              </Box>
                            )}
                            {homeData?.[0]?.office_email && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: ".5rem",
                                }}
                              >
                                <DraftsOutlinedIcon
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    color: "rgb(46 38 61 / 70%)",
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Email:
                                  </p>
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {homeData?.[0]?.office_email}
                                  </p>
                                </Box>
                              </Box>
                            )}

                            {homeData?.[0]?.designation && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: ".5rem",
                                }}
                              >
                                <ColorLensOutlinedIcon
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    color: "rgb(46 38 61 / 70%)",
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Designation:
                                  </p>
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {homeData?.[0]?.designation}
                                  </p>
                                </Box>
                              </Box>
                            )}

                            {homeData?.[0]?.work_location && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: ".5rem",
                                }}
                              >
                                <LocationOnOutlinedIcon
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    color: "rgb(46 38 61 / 70%)",
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Location:
                                  </p>
                                  <p
                                    style={{
                                      color: "rgb(46 38 61 / 70%)",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {homeData?.[0]?.work_location}
                                  </p>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                </Grid>
              )}
              {homeData?.[0]?.enable_ats && (
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Paper elevation={1} className={classes.Paper}>
                      <Card>
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1rem",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  color: "#212B36",
                                  fontWeight: "800",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                TASK
                              </span>

                              <Tooltip title="Add Task">
                                <IconButton onClick={handleAdd}>
                                  <AddIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>

                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={4} md={4}>
                                <Box
                                  className="quick-app"
                                  onClick={() => handleTaskClick("My Tasks", 0)}
                                >
                                  <div className="quick-app-icon">
                                    {" "}
                                    <AutoModeOutlinedIcon />
                                  </div>
                                  <div
                                    style={{ marginBottom: "5px" }}
                                    className="quick-app-name"
                                  >
                                    To Do
                                  </div>
                                  <div
                                    style={{ fontWeight: "600" }}
                                    className="quick-app-name"
                                  >
                                    {homeData?.[0]?.task_to_do_count}
                                  </div>
                                </Box>
                              </Grid>

                              <Grid item xs={6} sm={4} md={4}>
                                <Box
                                  className="quick-app"
                                  onClick={() => handleTaskClick("My Tasks", 1)}
                                >
                                  <div className="quick-app-icon">
                                    {" "}
                                    <AssignmentIndOutlinedIcon />
                                  </div>
                                  <div
                                    style={{ marginBottom: "5px" }}
                                    className="quick-app-name"
                                  >
                                    Assigned
                                  </div>
                                  <div
                                    style={{ fontWeight: "600" }}
                                    className="quick-app-name"
                                  >
                                    {homeData?.[0]?.task_assigned_count}
                                  </div>
                                </Box>
                              </Grid>

                              <Grid item xs={6} sm={4} md={4}>
                                <Box
                                  className="quick-app"
                                  onClick={() => handleTaskClick("My Tasks", 2)}
                                >
                                  <div className="quick-app-icon">
                                    {" "}
                                    <CheckIcon />
                                  </div>
                                  <div
                                    style={{ marginBottom: "5px" }}
                                    className="quick-app-name"
                                  >
                                    Done
                                  </div>
                                  <div
                                    style={{ fontWeight: "600" }}
                                    className="quick-app-name"
                                  >
                                    {homeData?.[0]?.task_done_count}
                                  </div>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={7}
              sx={{
                paddingRight: "1.5rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              <QuickLinks quicklinksData={quicklinksData} />
            </Grid>
          </Grid>
        </Box>
      </Base>
    </div>
  );
}
