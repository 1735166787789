import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";


export const EmailHelper = async (params, message) => {
    return await axiosInstance(`/ancillary/sendEmail`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            to: params?.to,
            cc: params?.cc,
            bcc: params?.bcc,
            subject: params?.subject,
            email_content: params?.content,
            attachments: params?.attachments || false,
        }
    })
        .then((res) => {
            toast.dismiss();
            message && toast.success(message)
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
