import * as ActionTypes from '../ActionTypes';

export const GetFormTemplateAction = (params) => {
    return {
        type: ActionTypes.FORM_TEMPLATE_GET_REQUEST,
        params
    }
}


export const AddFormTemplateAction = (params) => {
    return {
        type: ActionTypes.FORM_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const UpdateFormTemplateAction = (params) => {
    return {
        type: ActionTypes.FORM_TEMPLATE_UPDATE_REQUEST,
        params
    }
}

export const DeleteFormTemplateAction = (params) => {
    return {
        type: ActionTypes.FORM_TEMPLATE_DELETE_REQUEST,
        params
    }
}

//Pre Screen Questions

export const GetPreScreenTemplateAction = (params) => {
    return {
        type: ActionTypes.PRE_SCREEN_TEMPLATE_GET_REQUEST,
        params
    }
}


export const AddPreScreenTemplateAction = (params) => {
    return {
        type: ActionTypes.PRE_SCREEN_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const UpdatePreScreenTemplateAction = (params) => {
    return {
        type: ActionTypes.PRE_SCREEN_TEMPLATE_UPDATE_REQUEST,
        params
    }
}

export const DeletePreScreenTemplateAction = (params) => {
    return {
        type: ActionTypes.PRE_SCREEN_TEMPLATE_DELETE_REQUEST,
        params
    }
}

//Offer Letter

export const GetOfferLetterTemplateAction = (params) => {
    return {
        type: ActionTypes.OFFER_LETTER_TEMPLATE_GET_REQUEST,
        params
    }
}

export const AddOfferLetterTemplateAction = (params) => {
    return {
        type: ActionTypes.OFFER_LETTER_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const UpdateOfferLetterTemplateAction = (params) => {
    return {
        type: ActionTypes.OFFER_LETTER_TEMPLATE_UPDATE_REQUEST,
        params
    }
}

export const DeleteOfferLetterTemplateAction = (params) => {
    return {
        type: ActionTypes.OFFER_LETTER_TEMPLATE_DELETE_REQUEST,
        params
    }
}