import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";

// Add Employee (Onboarding)
export const AddEmployeeOnboardingHelper = async (params) => {
    let enableAtsValue = params?.enable_ats;
    if (params?.user_type === "Admin") {
        enableAtsValue = true;
    }
    toast.info("Saving...")
    return await axiosInstance(`/employee/onBoarding/addEmployee`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain,
            user_empid: params?.user_emp_id,
            candidate_id: params?.candidate_id,
            emp: params?.empDetails,
            employment: params?.employmentDetails,
            ctc: params?.ctc || null,
            salary_structure_id: params?.salary_structure_id ? params?.salary_structure_id.salary_structure_id : null,
            proof_details: params?.proof_details,
            b64Files: params?.b64Files,
            fileNames: params?.fileNames,
            contentTypes: params?.contentTypes,
            office_email: params?.is_internal ? params?.office_email : null,
            enable_ats: params?.is_internal ? enableAtsValue : false,
            enable_corehr: params?.is_internal ? true : false,
            enable_lms: params?.is_internal ? true : false,
            user_type: params?.is_internal ? params?.user_type : null
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Employee Added Successfully")
            return { message: "Employee Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            err?.response?.data?.error?.[0]?.message && toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to Add Employee", hasError: true, data: err?.response?.data }
        })
}