import axiosInstance from "../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetEmployeeProfileHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/finance/getEmployeeProfile`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id,
        }
    })
        .then(res => {
            return { message: "Profile Retrived Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
