import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import LeaveTypes from '../../../Leave/views/LeaveTypes/LeaveTypes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import YearlyHolidayCalendar from '../../../Leave/views/YearlyHolidayCalendar/YearlyHolidayCalendar';
import LeaveReason from '../../../Leave/views/LeaveReason/LeaveReason';
import { useTheme } from "@mui/material/styles";

const Leave = ({ accordian }) => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState(accordian);

    const { leavetypes_options } = useSelector(state => state.LeaveReducer?.LeaveTypeReducer);
    const { holidayData } = useSelector(state => state?.LeaveReducer?.YearlyHolidayCalendarReducer)
    const { leavereasonData } = useSelector(state => state?.LeaveReducer?.LeaveReasonReducer);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const values = [
        { name: "Leave Types", comp: <LeaveTypes />, count: leavetypes_options?.length || 0 },
        { name: "Holidays", comp: <YearlyHolidayCalendar />, count: holidayData?.length || 0 },
        { name: "Leave Reason", comp: <LeaveReason />, count: leavereasonData?.length || 0 },
    ]

    const actionSummaryStyle = {
        minHeight: 50,
        maxHeight: 50,
        borderRadius: "10px 10px 0px 0px",
        // backgroundColor: '#a5a5a5',
        '&.Mui-expanded': {
            minHeight: 50,
            maxHeight: 50,
            backgroundColor: theme?.palette?.primary?.dark,
            color: "white",
        }
    }

    return (
        <div style={{ padding: "10px 20px" }}>
            {values.map((value, id) => {
                return (
                    <Accordion key={id} expanded={expanded === `${value.name}`} onChange={handleChange(`${value.name}`)} sx={{ borderRadius: "10px" }}>
                        <AccordionSummary sx={actionSummaryStyle}
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                        >
                            <Typography sx={{color:theme.palette.primary.contrastText}} variant='body1' fontWeight={600}>{value.name} ({value.count})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {value.comp}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}

export default Leave;
