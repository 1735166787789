import { toast } from "react-toastify";
import NProgress from 'nprogress';
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";


export const GetMeeplAdminJobTemplateHelper = async (params) => {
    let domain = sessionStorage.getItem('userManagement')
    NProgress.start()
    return await axiosInstance(`/ats/ancillary/getMeeplAdminJobTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain,
            industry_code: params?.industry_code || null,
            job_title: params?.job_title || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetJobTemplateHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    NProgress.start()
    return await axiosInstance(`/ats/ancillary/getJobTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
            industry_code: params?.industry_code || null,
            job_title: params?.job_title || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddJobTemplateHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    let user_id = sessionStorage.getItem('userid')
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/ancillary/addJobTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
            user_id: user_id ? user_id : user?.ats_user_id,
            industry_code: params?.industry_code,
            job_title: params?.job_title,
            job_description: params?.job_description,
            roles: params?.roles_responsibilities
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("JD Template added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateJobTemplateHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    let user_id = sessionStorage.getItem('userid')
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/ancillary/updateJobTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
            user_id: user_id ? user_id : user?.ats_user_id,
            job_template_id: params?.job_template_id,
            job_title: params?.job_title,
            industry_code: params?.industry_code,
            job_description: params?.job_description,
            roles: params?.roles_responsibilities
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("JD Template Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteJobTemplateHelper = async (params) => {
    var user = getUserData();
    let value = sessionStorage.getItem('userManagement')
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/ancillary/deleteJobTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: value ? value : user.domain,
            job_template_id: params?.job_template_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("JD Template Deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

