import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import { GREY } from '../../../theme/palette';

export default function CarreerportalSidbar({ jobdata }) {

    return (
        <Box sx={{ backgroundColor: GREY[200],margin:"12px" }} width={"100%"} >
            <Grid container spacing={2} p={2} direction={"column"}>
                <Grid item>
                    <Typography variant="h4">Apply To</Typography>
                    <Typography >{jobdata?.[0]?.company}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6">Job Title</Typography>
                    <Typography>{jobdata?.[0]?.title}</Typography>
                </Grid>
                {jobdata?.[0]?.job_type &&
                    <Grid item>
                        <Typography variant="h6">Job Type</Typography>
                        <Typography >{jobdata?.[0]?.job_type}</Typography>
                    </Grid>}
                <Grid item>
                    <Typography variant="h6">Prefferred Qualification</Typography>
                    <Typography >{jobdata?.[0]?.preferred_qualification}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6">Location</Typography>
                    <Typography >{jobdata?.[0]?.job_location}</Typography>
                </Grid>
            </Grid>
        </Box >
    );
}