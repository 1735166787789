import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetAtsJobFilterAdminHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    return await axiosInstance(`/ats/getJobFilterAdmin`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            company_data: params?.company_data || null,
            consultancy_data: params?.consultancy_data || null,
            status: params?.status || "Active" ,
            severity_data: params?.severity_data || null,
            search: params?.search || null,
            pagenumber: params?.pagenumber || null,
            pagesize: params?.pagesize || null,
            sort_column: params?.sort_column || null,
            sort_order: params?.sort_order || null,
            primary_recruiter: params?.primary_recruiter || null,
            secondary_recruiter: params?.secondary_recruiter || null,
            grouporg_data:params?.grouporg_data || null
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsJobFilterInternalHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    return await axiosInstance(`/ats/getJobFilterInternal`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            company_data: params?.company_data || null,
            consultancy_data: params?.consultancy_data || null,
            status: params?.status || "Active" ,
            severity_data: params?.severity_data || null,
            search: params?.search || null,
            pagenumber: params?.pagenumber || null,
            pagesize: params?.pagesize || null,
            sort_column: params?.sort_column || null,
            sort_order: params?.sort_order || null,
            primary_recruiter: params?.primary_recruiter || null,
            secondary_recruiter: params?.secondary_recruiter || null,
            grouporg_data:params?.grouporg_data || null
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsJobFilterConsultancyHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    return await axiosInstance(`/ats/getJobFilterConsultancy`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            company_data: params?.company_data || null,
            consultancy_data: params?.consultancy_data || null,
            status: params?.status || "Active" ,
            severity_data: params?.severity_data || null,
            search: params?.search || null,
            pagenumber: params?.pagenumber || null,
            pagesize: params?.pagesize || null,
            sort_column: params?.sort_column || null,
            sort_order: params?.sort_order || null,
            primary_recruiter: params?.primary_recruiter || null,
            secondary_recruiter: params?.secondary_recruiter || null,
            grouporg_data:params?.grouporg_data || null
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsJobFilterCompanyHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    return await axiosInstance(`/ats/getJobFilterCompany`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            company_data: params?.company_data || null,
            consultancy_data: params?.consultancy_data || null,
            status: params?.status || "Active" ,
            severity_data: params?.severity_data || null,
            search: params?.search || null,
            pagenumber: params?.pagenumber || null,
            pagesize: params?.pagesize || null,
            sort_column: params?.sort_column || null,
            sort_order: params?.sort_order || null,
            primary_recruiter: params?.primary_recruiter || null,
            secondary_recruiter: params?.secondary_recruiter || null,
            grouporg_data:params?.grouporg_data || null
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const GetAtsJobFilterGroupOrgHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    return await axiosInstance(`/ats/getJobFilterGroupuser`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            company_data: params?.company_data || null,
            consultancy_data: params?.consultancy_data || null,
            status: params?.status || "Active" ,
            severity_data: params?.severity_data || null,
            search: params?.search || null,
            pagenumber: params?.pagenumber || null,
            pagesize: params?.pagesize || null,
            sort_column: params?.sort_column || null,
            sort_order: params?.sort_order || null,
            primary_recruiter: params?.primary_recruiter || null,
            secondary_recruiter: params?.secondary_recruiter || null,
            grouporg_data:params?.grouporg_data || null
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
