import React, { useEffect, useRef } from "react";
import { Grid, Box, Button, FormControl } from "@mui/material";
import Base from "../../../Base/views/Base";
import MaterialTable from "material-table";
import { useState } from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { useDispatch, useSelector } from "react-redux";
import { GetAtsJobPostAction } from '../../../../../store/ATS/JobPost/Action'
import { GetCandidateExportAction } from "../../../../../store/ATS/CandidateExport/Action";
import { GetAtsJobStageHelper } from "../../helper/AtsJobStageHelper";
import { CSVLink } from "react-csv";


const ATSCandidateExport = () => {

    const csvLinkRef = useRef(null);
    const dispatch = useDispatch();
    const initialValue = { ats_jobpost_id: "", ats_job_stages_id: [] }
    const [formData, setFormData] = useState(initialValue)
    const filename = formData?.ats_jobpost_id?.title || "Candidates Progress Details";
    const [jobData, setJobData] = useState({ load: true, values: [] })
    const [atsJobStageData, setAtsJobStageData] = useState([]);
    const { atsJobPostData } = useSelector(state => state.AtsReducer?.AtsJobPostReducer);
    const { atsCandidateExport } = useSelector(state => state.AtsReducer?.AtsCandidateExportReducer);
    const headers = [
        { label: "Job ID", key: "job_id" },
        { label: "Job Title", key: "title" },
        { label: "Candidate Name", key: "full_name" },
        { label: "Candidate Email", key: "email_id" },
        { label: "Location", key: "location" },
        { label: "Stage Name", key: "stage_name" },
        { label: "Profile Received Date", key: "profile_received_date" },
        { label: "Accepetd CTC", key: "accepted_ctc" },
        { label: "Expected Joining Date", key: "tentative_joining_date" },
        { label: "Contact Number", key: "contact_no" },
        { label: "Date of Birth", key: "dob" },
        { label: "Years of Experience", key: "years_of_experience" },
        { label: "Current CTC", key: "current_ctc" },
        { label: "Expected Pay", key: "expected_pay" },
        { label: "Current Employer", key: "current_employer" },
        { label: "Notice Period", key: "notice_period" },
        { label: "Skills", key: "skills" },
        { label: "Gender", key: "gender" },
        { label: "Primary Skill", key: "primary_skill" },
        { label: "Secondary Skill", key: "secondary_skill" },
        { label: "Proof Number", key: "proof_number" },
        { label: "Uan Number", key: "uan_number" },
        { label: "Alternative Mobile", key: "alternative_mobile" },
        { label: "Joined Date", key: "joined_date" },
        { label: "Job Position", key: "job_position" },
    ];

    useEffect(() => {
        dispatch(GetAtsJobPostAction({ company_id: 1, consultancy_id: null }))
        dispatch(GetCandidateExportAction({
            ats_jobpost_id: null,
            ats_job_stages_id: null,

        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAllStageLoading = () => {
        if (formData?.ats_jobpost_id) {
            const allOption = {
                ats_job_stages_id: null,
                name: 'All'
            };
            setAtsJobStageData({ load: false, values: [allOption, ...atsJobStageData] })
        }
    };
    const handleInputChange = (event, value) => {
        if (["ats_jobpost_id"].includes(event)) {
            const allOption = {
                ats_job_stages_id: null,
                name: 'All'
            };
            setFormData({ ...formData, [event]: value, ats_job_stages_id: [allOption] })
            if (value) GetAtsJobStageHelper({ ats_jobpost_id: value?.ats_jobpost_id }).then(res => {
                setAtsJobStageData(res?.data?.data)
                dispatch(GetCandidateExportAction({
                    ats_jobpost_id: value?.ats_jobpost_id,
                    ats_job_stages_id: null,

                }))
            })
            else setFormData({ ...formData, [event]: "" })
        }
        if (["ats_job_stages_id"].includes(event)) {
            if (value.length > 0 && value?.at(-1).name === "All") {
                setFormData({ ...formData, [event]: [{ name: "All" }] });
                dispatch(GetCandidateExportAction({
                    ats_jobpost_id: formData?.ats_jobpost_id?.ats_jobpost_id,
                    ats_job_stages_id: null,

                }))
            } else if (value?.length > 0 && value?.filter(res => res.name === "All")?.length > 0) {
                let finalValue = value?.splice(value?.findIndex(item => item?.name !== "All"), 1)
                setFormData({ ...formData, [event]: finalValue });
                let stages = []
                finalValue?.forEach(res => stages.push(res?.ats_job_stages_id))
                dispatch(GetCandidateExportAction({
                    ats_jobpost_id: formData?.ats_jobpost_id?.ats_jobpost_id,
                    ats_job_stages_id: stages?.length > 0 ? `{${stages.join(',')}}` : null,

                }))
            } else {
                setFormData({ ...formData, [event]: value });
                let stages = []
                value?.forEach(res => stages.push(res?.ats_job_stages_id))
                dispatch(GetCandidateExportAction({
                    ats_jobpost_id: formData?.ats_jobpost_id?.ats_jobpost_id,
                    ats_job_stages_id: stages?.length > 0 ? `{${stages.join(',')}}` : null,

                }))
            }
        }
    }
    const handleExport = () => {
        if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
        }
    };

    return (
        <Base>
         <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
            <Box sx={{ mb: 2 }}>
                <Grid container columnSpacing={1}>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth sx={{ pr: 1 }}>
                            <SelectableSearch
                                isOptionEqualToValue={(option, value) => +option.ats_jobpost_id === +value.ats_jobpost_id}
                                filterOptions="ats_jobpost_id"
                                keys={["job_id", "title"]}
                                label="Job Title"
                                value={formData?.ats_jobpost_id}
                                loading={() => setJobData({ load: false, values: atsJobPostData || [] })}
                                fieldLabel="title"
                                required={false}
                                variant={"contained"}
                                name="ats_jobpost_id"
                                data={jobData}
                                onChangeEvent={handleInputChange}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.title} - {option.job_id}
                                    </Box>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormControl size="small" fullWidth>
                            <SelectableSearch
                                isOptionEqualToValue={(option, value) =>
                                    +option.ats_job_stages_id === +value.ats_job_stages_id
                                }
                                label="Job Stage"
                                value={formData.ats_job_stages_id}
                                loading={() => handleAllStageLoading()}
                                fieldLabel="name"
                                required={false}
                                multiple={true}
                                variant={"outlined"}
                                name="ats_job_stages_id"
                                onChangeEvent={handleInputChange}
                                data={atsJobStageData}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>
            <>
                <MaterialTable
                    columns={[
                        { title: "Job ID", cellStyle: { padding: "10px 10px 10px 13px" }, field: "job_id", grouping: false },
                        { title: "Job Title", cellStyle: { padding: "10px 10px 10px 13px" }, field: "title", grouping: false },
                        { title: " Candidate Name", cellStyle: { padding: "10px 10px 10px 13px" }, field: "full_name", grouping: false },
                        { title: "Candidate Email", cellStyle: { padding: "10px 10px 10px 13px" }, field: "email_id", grouping: false },
                        { title: "Location", cellStyle: { padding: "10px 10px 10px 13px" }, field: "location", grouping: false },
                        { title: "Stage", cellStyle: { padding: "10px 10px 10px 13px" }, field: "stage_name", grouping: false },
                    ]}
                    data={atsCandidateExport || []}
                    options={{
                        maxBodyHeight: "69vh",
                        minBodyHeight: "69vh",
                        rowStyle: { fontSize: "13px" },
                        sorting: true,
                        search: true,
                        searchText: "",
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        paginationType: "stepped",
                        showFirstLastPageButtons: false,
                        paginationPosition: "bottom",
                        exportButton: false,
                        exportAllData: true,
                        exportFileName: "Client",
                        addRowPosition: "first",
                        selection: false,
                        actionsColumnIndex: -1,
                        showSelectAllCheckbox: true,
                        showTextRowsSelected: true,
                        grouping: false,
                        columnsButton: false,
                    }}
                    actions={[
                        {
                            icon: () => <Button component="span" variant="contained" startIcon={<CloudDownloadIcon />} className="mpl-primary-btn">
                                Export
                            </Button>,
                            isFreeAction: true,
                            position: "row",
                            tooltip: "Export",
                            onClick: (_event, rowData) => handleExport(),
                        },
                    ]}
                    title="Candidates Progress "

                />
                {atsCandidateExport.length > 0 && (
                    <CSVLink
                        ref={csvLinkRef}
                        style={{ display: 'none' }}
                        data={atsCandidateExport}
                        headers={headers}
                        filename={filename}
                    >
                    </CSVLink>
                )}
            </>
        </Box>
        </Base >
    )
}

export default ATSCandidateExport;