import React from "react";
import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import {
  DeleteEmailTokenHelper,
  GetEmailTokenHelper,
} from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { OfferAcceptHelper } from "../../../ATSSettings/helper/AtsFormTemplateResponseHelper";
import { AddNotificationHelper } from "../../../Ancillary/Notification/helper/NotificationHelper";
import { AtsEmailHelper } from "../../helper/ATSEmailHeper";
const OfferApproval = () => {
  const { guid } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const domain = guid.split("=")[1];
  const approver_response = params.get("approved");

  useEffect(() => {
    setTimeout(() => {
      GetEmailTokenHelper({
        domain: domain,
        token: guid,
      }).then((res) => {
        if (res?.data?.data?.[0]?.email_tokens_id) {
          let result = res?.data?.data?.[0]?.json_data?.[0];
          OfferAcceptHelper({
            workspace: domain,
            offer_letter_response_id: result?.offer_letter_response_id,
            requested_by_id: result?.request_emp_id,
            requested_date: res?.data?.data?.[0]?.createdon,
            candidate_response: approver_response,
          }).then((res) => {
            const message =
              approver_response === "true"
                ? `Offer Letter response has been captured for ${result?.title}. Please find the details below:<br><br>Candidate name: ${result?.full_name}<br><br>Job title: ${result?.title}<br><br>Status: Accepted`
                : `Offer Letter response has been captured for ${result?.title}. Please find the details below:<br><br>Candidate name: ${result?.full_name}<br><br>Job title: ${result?.title}<br><br>Status: Rejected`;
            const notification_message =
              approver_response === "true"
                ? `${result?.full_name} has accepted the offer letter, Please check the status.`
                : `${result?.full_name} has rejected the offer letter, Please check the status.`;
            AddNotificationHelper({
              domain: domain,
              employee_id: result?.request_ats_id,
              notification_data: JSON.stringify([
                {
                  user_id: "" + result?.request_ats_id,
                  comment: notification_message,
                  is_read: "N",
                },
              ]),
            });
            AtsEmailHelper({
              to: result?.request_emp_email,
              subject: `${result?.full_name} responsed to ${result?.title}`,
              content: message,
            });
            DeleteEmailTokenHelper({
              domain: domain,
              token: guid,
            });
          });
        }
      });
    }, 1000);
    //eslint-disable-next-line
  }, []);

  return (
    <UnauthorizedBase>
      <Box className="bg_form_main">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "90vh" }}
        >
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Box sx={{ paddingTop: "50px", width: "100%" }}>
              <Box
                className="response-main"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  textAlign={"center"}
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                  padding={"20px"}
                >
                  Thanks! You’re all set. Feel free to close this page.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </UnauthorizedBase>
  );
};

export default OfferApproval;
