
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from "react";
import { GetRoleAction } from "../../../../../store/RoleAccess/Role/Action";

const RoleAccess = (props) => {
    const { setMode, setEditedRecord } = props
    const dispatch = useDispatch()
    const { role_data } = useSelector(state => state?.RoleAndAccessReducer?.RoleReducer);

    useEffect(() => {
        dispatch(GetRoleAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleEdit = (rowData) => {
        setMode("edit")
        setEditedRecord({
            ...rowData
        })
    };

    const handleAdd = () => {
        setMode("add")
        setEditedRecord()
    };

    return (
        <Box>
            <MaterialTable
                columns={[
                    { title: "Role", cellStyle: { padding: "10px 30px", }, field: "name" },
                ]}
                data={role_data || []}
                icons={{
                    Add: () => <AddIcon />,
                    Edit: () => <EditIcon />,
                }}
                options={{
                    maxBodyHeight: 450,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 30px", fontWeight: "bold", paddingRight: "10px" },
                    sorting: false,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: true,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: false,
                    exportFileName: "Role and Access",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: false,
                }}
                actions={[
                    {
                        icon: () => <EditIcon />,
                        position: "row",
                        tooltip: "Edit",
                        onClick: (_event, rowData) => handleEdit(rowData)
                    },
                    {
                        icon: 'add',
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: (event) => { handleAdd() }
                    },
                ]}
                title="Role & Access"
            />
        </Box>
    );
}
export default RoleAccess;