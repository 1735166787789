import * as ActionTypes from '../ActionTypes';

export const GetLeaveSummaryAction = (show) => {
    return {
        type: ActionTypes.LEAVE_SUMMARY_GET_REQUEST,
        show
    }
}

export const GetLeaveReportAction = (report) => {
    return {
        type: ActionTypes.LEAVE_REPORT_GET_REQUEST,
        report
    }
}

export const GetBookingsLeaveAction = (report) => {
    return {
        type: ActionTypes.BOOKINGS_LEAVE_GET_REQUEST,
        report
    }
}

export const GetLeaveLedgerAction = () => {
    return {
        type: ActionTypes.LEAVE_LEDGER_GET_REQUEST,
    }
}
