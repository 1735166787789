
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddStudyLevelAction, UpdateStudyLevelAction, DeleteStudyLevelAction } from "../../../../../store/Ancillary/StudyLevel/Action";

const StudyLevel = () => {
    const dispatch = useDispatch()
    const { studylevel_options } = useSelector(state => state?.AncillaryReducer?.StudyLevelReducer);

    return (
        <Box>
            <MaterialTable
                columns={[
                    {
                        title: "Name", cellStyle: { padding: "0px 10px" }, field: "name", validate: rowData => rowData?.name === '' || rowData?.name === undefined ? 'Name cannot be empty' : '',
                        editComponent: props => (
                            <TextField
                                required={true}
                                variant="standard"
                                value={props?.value}
                                onChange={e => props.onChange(e.target.value)}
                                error={props?.error}
                                helperText={props?.helperText}
                            />
                        )
                    },
                    {
                        title: "Code", cellStyle: { padding: "0px 10px" }, field: "code", validate: rowData => rowData?.code === '' || rowData?.code === undefined ? 'Code cannot be empty' : '',
                        editComponent: props => (
                            <TextField
                                required={true}
                                variant="standard"
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}
                                error={props?.error}
                                helperText={props?.helperText}
                            />
                        ),
                    },
                ]}
                data={studylevel_options || []}
                icons={{
                    Add: () => <AddIcon />,
                    Edit: () => <EditIcon />,
                    Delete: () => <DeleteIcon />
                }}
                editable={{
                    onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(AddStudyLevelAction(newRow))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(UpdateStudyLevelAction(newRow))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowDelete: (selectedRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(DeleteStudyLevelAction({ studylevel_id: `{${selectedRow?.studylevel_id}}` }))
                            setTimeout(() => resolve(), 1000);
                        }),
                }}
                options={{
                    maxBodyHeight: 220,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                    sorting: true,
                    search: false,
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: false,
                    selection: false,
                    actionsColumnIndex: -1,
                    grouping: false,
                    addRowPosition: "first",
                }}
                title=""
            />
        </Box>
    );
}
export default StudyLevel;