import * as ActionTypes from '../ActionTypes';

export const GetHolidayCalendarCityAction = () => {
    return {
        type: ActionTypes.HOLIDAY_CALENDAR_CITY_GET_REQUEST,
    }
}

export const GetCityListAction = () => {
    return {
        type: ActionTypes.CITY_LIST_GET_REQUEST
    }
}