export const GET_BANK_REQUEST = "GET_BANK_REQUEST";
export const GET_BANK_SUCCESS = "GET_BANK_SUCCESS";

export const ADD_BANK_REQUEST = "ADD_BANK_REQUEST";
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS";
export const ADD_BANK_ERROR = "ADD_BANK_ERROR";

export const UPDATE_BANK_REQUEST = "UPDATE_BANK_REQUEST";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_ERROR = "UPDATE_BANK_ERROR";

export const DELETE_BANK_REQUEST = "DELETE_BANK_REQUEST";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_ERROR = "DELETE_BANK_ERROR";

export const RELATIONSHIP_GET_REQUEST = "GET_RELATIONSHIP_REQUEST";
export const RELATIONSHIP_GET_SUCCESS = "GET_RELATIONSHIP_SUCCESS";

export const RELATIONSHIP_ADD_REQUEST = "ADD_RELATIONSHIP_REQUEST";
export const RELATIONSHIP_ADD_SUCCESS = "ADD_RELATIONSHIP_SUCCESS";
export const RELATIONSHIP_ADD_ERROR = "ADD_RELATIONSHIP_ERROR";

export const RELATIONSHIP_UPDATE_REQUEST = "UPDATE_RELATIONSHIP_REQUEST";
export const RELATIONSHIP_UPDATE_SUCCESS = "UPDATE_RELATIONSHIP_SUCCESS";
export const RELATIONSHIP_UPDATE_ERROR = "UPDATE_RELATIONSHIP_ERROR";

export const RELATIONSHIP_DELETE_REQUEST = "DELETE_RELATIONSHIP_REQUEST";
export const RELATIONSHIP_DELETE_SUCCESS = "DELETE_RELATIONSHIP_SUCCESS";
export const RELATIONSHIP_DELETE_ERROR = "DELETE_RELATIONSHIP_ERROR";

// Study Level
export const STUDYLEVEL_GET_REQUEST = "GET_STUDYLEVEL_REQUEST";
export const STUDYLEVEL_GET_SUCCESS = "GET_STUDYLEVEL_SUCCESS";

export const STUDYLEVEL_ADD_REQUEST = "STUDYLEVEL_ADD_REQUEST";
export const STUDYLEVEL_ADD_SUCCESS = "STUDYLEVEL_ADD_SUCCESS";
export const STUDYLEVEL_ADD_ERROR = "STUDYLEVEL_ADD_ERROR";

export const STUDYLEVEL_UPDATE_REQUEST = "STUDYLEVEL_UPDATE_REQUEST";
export const STUDYLEVEL_UPDATE_SUCCESS = "STUDYLEVEL_UPDATE_SUCCESS";
export const STUDYLEVEL_UPDATE_ERROR = "STUDYLEVEL_UPDATE_ERROR";

export const STUDYLEVEL_DELETE_REQUEST = "STUDYLEVEL_DELETE_REQUEST";
export const STUDYLEVEL_DELETE_SUCCESS = "STUDYLEVEL_DELETE_SUCCESS";
export const STUDYLEVEL_DELETE_ERROR = "STUDYLEVEL_DELETE_ERROR";


// Study Program,
export const STUDYPROGRAM_GET_REQUEST = "STUDYPROGRAM_GET_REQUEST";
export const STUDYPROGRAM_GET_SUCCESS = "STUDYPROGRAM_GET_SUCCESS";

export const STUDYPROGRAM_ADD_REQUEST = "STUDYPROGRAM_ADD_REQUEST";
export const STUDYPROGRAM_ADD_SUCCESS = "STUDYPROGRAM_ADD_SUCCESS";
export const STUDYPROGRAM_ADD_ERROR = "STUDYPROGRAM_ADD_ERROR";

export const STUDYPROGRAM_UPDATE_REQUEST = "STUDYPROGRAM_UPDATE_REQUEST";
export const STUDYPROGRAM_UPDATE_SUCCESS = "STUDYPROGRAM_UPDATE_SUCCESS";
export const STUDYPROGRAM_UPDATE_ERROR = "STUDYPROGRAM_UPDATE_ERROR";

export const STUDYPROGRAM_DELETE_REQUEST = "STUDYPROGRAM_DELETE_REQUEST";
export const STUDYPROGRAM_DELETE_SUCCESS = "STUDYPROGRAM_DELETE_SUCCESS";
export const STUDYPROGRAM_DELETE_ERROR = "STUDYPROGRAM_DELETE_ERROR";

// Designation
export const DESIGNATION_GET_REQUEST = "DESIGNATION_GET_REQUEST";
export const DESIGNATION_GET_SUCCESS = "DESIGNATION_GET_SUCCESS";

export const DESIGNATION_ADD_REQUEST = "DESIGNATION_ADD_REQUEST";
export const DESIGNATION_ADD_SUCCESS = "DESIGNATION_ADD_SUCCESS";
export const DESIGNATION_ADD_ERROR = "DESIGNATION_ADD_ERROR";

export const DESIGNATION_UPDATE_REQUEST = "DESIGNATION_UPDATE_REQUEST";
export const DESIGNATION_UPDATE_SUCCESS = "DESIGNATION_UPDATE_SUCCESS";
export const DESIGNATION_UPDATE_ERROR = "DESIGNATION_UPDATE_ERROR";

export const DESIGNATION_DELETE_REQUEST = "DESIGNATION_DELETE_REQUEST";
export const DESIGNATION_DELETE_SUCCESS = "DESIGNATION_DELETE_SUCCESS";
export const DESIGNATION_DELETE_ERROR = "DESIGNATION_DELETE_ERROR";

// Job
export const JOB_GET_REQUEST = "JOB_GET_REQUEST";
export const JOB_GET_SUCCESS = "JOB_GET_SUCCESS";

export const JOB_ADD_REQUEST = "JOB_ADD_REQUEST";
export const JOB_ADD_SUCCESS = "JOB_ADD_SUCCESS";
export const JOB_ADD_ERROR = "JOB_ADD_ERROR";

export const JOB_UPDATE_REQUEST = "JOB_UPDATE_REQUEST";
export const JOB_UPDATE_SUCCESS = "JOB_UPDATE_SUCCESS";
export const JOB_UPDATE_ERROR = "JOB_UPDATE_ERROR";

export const JOB_DELETE_REQUEST = "JOB_DELETE_REQUEST";
export const JOB_DELETE_SUCCESS = "JOB_DELETE_SUCCESS";
export const JOB_DELETE_ERROR = "JOB_DELETE_ERROR";

// Employment Type
export const EMPLOYMENT_TYPE_GET_REQUEST = "EMPLOYMENT_TYPE_GET_REQUEST";
export const EMPLOYMENT_TYPE_GET_SUCCESS = "EMPLOYMENT_TYPE_GET_SUCCESS";

export const EMPLOYMENT_TYPE_ADD_REQUEST = "EMPLOYMENT_TYPE_ADD_REQUEST";
export const EMPLOYMENT_TYPE_ADD_SUCCESS = "EMPLOYMENT_TYPE_ADD_SUCCESS";
export const EMPLOYMENT_TYPE_ADD_ERROR = "EMPLOYMENT_TYPE_ADD_ERROR";

export const EMPLOYMENT_TYPE_UPDATE_REQUEST = "EMPLOYMENT_TYPE_UPDATE_REQUEST";
export const EMPLOYMENT_TYPE_UPDATE_SUCCESS = "EMPLOYMENT_TYPE_UPDATE_SUCCESS";
export const EMPLOYMENT_TYPE_UPDATE_ERROR = "EMPLOYMENT_TYPE_UPDATE_ERROR";

export const EMPLOYMENT_TYPE_DELETE_REQUEST = "EMPLOYMENT_TYPE_DELETE_REQUEST";
export const EMPLOYMENT_TYPE_DELETE_SUCCESS = "EMPLOYMENT_TYPE_DELETE_SUCCESS";
export const EMPLOYMENT_TYPE_DELETE_ERROR = "EMPLOYMENT_TYPE_DELETE_ERROR";

// Paygrade
export const PAYGRADE_GET_REQUEST = "PAYGRADE_GET_REQUEST";
export const PAYGRADE_GET_SUCCESS = "PAYGRADE_GET_SUCCESS";

export const PAYGRADE_ADD_REQUEST = "PAYGRADE_ADD_REQUEST";
export const PAYGRADE_ADD_SUCCESS = "PAYGRADE_ADD_SUCCESS";
export const PAYGRADE_ADD_ERROR = "PAYGRADE_ADD_ERROR";

export const PAYGRADE_UPDATE_REQUEST = "PAYGRADE_UPDATE_REQUEST";
export const PAYGRADE_UPDATE_SUCCESS = "PAYGRADE_UPDATE_SUCCESS";
export const PAYGRADE_UPDATE_ERROR = "PAYGRADE_UPDATE_ERROR";

export const PAYGRADE_DELETE_REQUEST = "PAYGRADE_DELETE_REQUEST";
export const PAYGRADE_DELETE_SUCCESS = "PAYGRADE_DELETE_SUCCESS";
export const PAYGRADE_DELETE_ERROR = "PAYGRADE_DELETE_ERROR";

// Cost Center
export const COST_CENTER_GET_REQUEST = "COST_CENTER_GET_REQUEST";
export const COST_CENTER_GET_SUCCESS = "COST_CENTER_GET_SUCCESS";

export const COST_CENTER_ADD_REQUEST = "COST_CENTER_ADD_REQUEST";
export const COST_CENTER_ADD_SUCCESS = "COST_CENTER_ADD_SUCCESS";
export const COST_CENTER_ADD_ERROR = "COST_CENTER_ADD_ERROR";

export const COST_CENTER_UPDATE_REQUEST = "COST_CENTER_UPDATE_REQUEST";
export const COST_CENTER_UPDATE_SUCCESS = "COST_CENTER_UPDATE_SUCCESS";
export const COST_CENTER_UPDATE_ERROR = "COST_CENTER_UPDATE_ERROR";

export const COST_CENTER_DELETE_REQUEST = "COST_CENTER_DELETE_REQUEST";
export const COST_CENTER_DELETE_SUCCESS = "COST_CENTER_DELETE_SUCCESS";
export const COST_CENTER_DELETE_ERROR = "COST_CENTER_DELETE_ERROR";

export const CITY_LIST_GET_REQUEST = "CITY_LIST_GET_REQUEST";
export const CITY_LIST_GET_SUCCESS = "CITY_LIST_GET_SUCCESS";

export const HOLIDAY_CALENDAR_CITY_GET_REQUEST = "HOLIDAY_CALENDAR_CITY_GET_REQUEST";
export const HOLIDAY_CALENDAR_CITY_GET_SUCCESS = "HOLIDAY_CALENDAR_CITY_GET_SUCCESS";

// Leaving Reason
export const LEAVING_REASON_GET_REQUEST = "LEAVING_REASON_GET_REQUEST";
export const LEAVING_REASON_GET_SUCCESS = "LEAVING_REASON_GET_SUCCESS";

export const LEAVING_REASON_ADD_REQUEST = "LEAVING_REASON_ADD_REQUEST";
export const LEAVING_REASON_ADD_SUCCESS = "LEAVING_REASON_ADD_SUCCESS";
export const LEAVING_REASON_ADD_ERROR = "LEAVING_REASON_ADD_ERROR";

export const LEAVING_REASON_UPDATE_REQUEST = "LEAVING_REASON_UPDATE_REQUEST";
export const LEAVING_REASON_UPDATE_SUCCESS = "LEAVING_REASON_UPDATE_SUCCESS";
export const LEAVING_REASON_UPDATE_ERROR = "LEAVING_REASON_UPDATE_ERROR";

export const LEAVING_REASON_DELETE_REQUEST = "LEAVING_REASON_DELETE_REQUEST";
export const LEAVING_REASON_DELETE_SUCCESS = "LEAVING_REASON_DELETE_SUCCESS";
export const LEAVING_REASON_DELETE_ERROR = "LEAVING_REASON_DELETE_ERROR";

// Busiiness Unit
export const BUSINESSUNIT_GET_REQUEST = "BUSINESSUNIT_GET_REQUEST";
export const BUSINESSUNIT_GET_SUCCESS = "BUSINESSUNIT_GET_SUCCESS";

export const BUSINESSUNIT_ADD_REQUEST = "BUSINESSUNIT_ADD_REQUEST";
export const BUSINESSUNIT_ADD_SUCCESS = "BUSINESSUNIT_ADD_SUCCESS";
export const BUSINESSUNIT_ADD_ERROR = "BUSINESSUNIT_ADD_ERROR";

export const BUSINESSUNIT_UPDATE_REQUEST = "BUSINESSUNIT_UPDATE_REQUEST";
export const BUSINESSUNIT_UPDATE_SUCCESS = "BUSINESSUNIT_UPDATE_SUCCESS";
export const BUSINESSUNIT_UPDATE_ERROR = "BUSINESSUNIT_UPDATE_ERROR";

export const BUSINESSUNIT_DELETE_REQUEST = "BUSINESSUNIT_DELETE_REQUEST";
export const BUSINESSUNIT_DELETE_SUCCESS = "BUSINESSUNIT_DELETE_SUCCESS";
export const BUSINESSUNIT_DELETE_ERROR = "BUSINESSUNIT_DELETE_ERROR";

// Sub Business Unit
export const SUB_BUSINESSUNIT_GET_REQUEST = "SUB_BUSINESSUNIT_GET_REQUEST";
export const SUB_BUSINESSUNIT_GET_SUCCESS = "SUB_BUSINESSUNIT_GET_SUCCESS";

export const SUB_BUSINESSUNIT_ADD_REQUEST = "SUB_BUSINESSUNIT_ADD_REQUEST";
export const SUB_BUSINESSUNIT_ADD_SUCCESS = "SUB_BUSINESSUNIT_ADD_SUCCESS";
export const SUB_BUSINESSUNIT_ADD_ERROR = "SUB_BUSINESSUNIT_ADD_ERROR";

export const SUB_BUSINESSUNIT_UPDATE_REQUEST = "SUB_BUSINESSUNIT_UPDATE_REQUEST";
export const SUB_BUSINESSUNIT_UPDATE_SUCCESS = "SUB_BUSINESSUNIT_UPDATE_SUCCESS";
export const SUB_BUSINESSUNIT_UPDATE_ERROR = "SUB_BUSINESSUNIT_UPDATE_ERROR";

export const SUB_BUSINESSUNIT_DELETE_REQUEST = "SUB_BUSINESSUNIT_DELETE_REQUEST";
export const SUB_BUSINESSUNIT_DELETE_SUCCESS = "SUB_BUSINESSUNIT_DELETE_SUCCESS";
export const SUB_BUSINESSUNIT_DELETE_ERROR = "SUB_BUSINESSUNIT_DELETE_ERROR";

// Caste
export const CASTE_GET_REQUEST = "CASTE_GET_REQUEST";
export const CASTE_GET_SUCCESS = "CASTE_GET_SUCCESS";

export const CASTE_ADD_REQUEST = "CASTE_ADD_REQUEST";
export const CASTE_ADD_SUCCESS = "CASTE_ADD_SUCCESS";
export const CASTE_ADD_ERROR = "CASTE_ADD_ERROR";

export const CASTE_UPDATE_REQUEST = "CASTE_UPDATE_REQUEST";
export const CASTE_UPDATE_SUCCESS = "CASTE_UPDATE_SUCCESS";
export const CASTE_UPDATE_ERROR = "CASTE_UPDATE_ERROR";

export const CASTE_DELETE_REQUEST = "CASTE_DELETE_REQUEST";
export const CASTE_DELETE_SUCCESS = "CASTE_DELETE_SUCCESS";
export const CASTE_DELETE_ERROR = "CASTE_DELETE_ERROR";

// Work Location
export const WORK_LOCATION_GET_REQUEST = "WORK_LOCATION_GET_REQUEST";
export const WORK_LOCATION_GET_SUCCESS = "WORK_LOCATION_GET_SUCCESS";
export const WORK_LOCATION_GET_ERROR = "WORK_LOCATION_GET_ERROR";

export const WORK_LOCATION_ADD_REQUEST = "WORK_LOCATION_ADD_REQUEST";
export const WORK_LOCATION_ADD_SUCCESS = "WORK_LOCATION_ADD_SUCCESS";
export const WORK_LOCATION_ADD_ERROR = "WORK_LOCATION_ADD_ERROR";

export const WORK_LOCATION_UPDATE_REQUEST = "WORK_LOCATION_UPDATE_REQUEST";
export const WORK_LOCATION_UPDATE_SUCCESS = "WORK_LOCATION_UPDATE_SUCCESS";
export const WORK_LOCATION_UPDATE_ERROR = "WORK_LOCATION_UPDATE_ERROR";

export const WORK_LOCATION_DELETE_REQUEST = "WORK_LOCATION_DELETE_REQUEST";
export const WORK_LOCATION_DELETE_SUCCESS = "WORK_LOCATION_DELETE_SUCCESS";
export const WORK_LOCATION_DELETE_ERROR = "WORK_LOCATION_DELETE_ERROR";


// Proof Type
export const PROOF_TYPE_GET_REQUEST = "PROOF_TYPE_GET_REQUEST";
export const PROOF_TYPE_GET_SUCCESS = "PROOF_TYPE_GET_SUCCESS";
export const PROOF_TYPE_GET_ERROR = "PROOF_TYPE_GET_ERROR";

export const PROOF_TYPE_ADD_REQUEST = "PROOF_TYPE_ADD_REQUEST";
export const PROOF_TYPE_ADD_SUCCESS = "PROOF_TYPE_ADD_SUCCESS";
export const PROOF_TYPE_ADD_ERROR = "PROOF_TYPE_ADD_ERROR";

export const PROOF_TYPE_UPDATE_REQUEST = "PROOF_TYPE_UPDATE_REQUEST";
export const PROOF_TYPE_UPDATE_SUCCESS = "PROOF_TYPE_UPDATE_SUCCESS";
export const PROOF_TYPE_UPDATE_ERROR = "PROOF_TYPE_UPDATE_ERROR";

export const PROOF_TYPE_DELETE_REQUEST = "PROOF_TYPE_DELETE_REQUEST";
export const PROOF_TYPE_DELETE_SUCCESS = "PROOF_TYPE_DELETE_SUCCESS";
export const PROOF_TYPE_DELETE_ERROR = "PROOF_TYPE_DELETE_ERROR";

// Notification
export const NOTIFICATION_GET_REQUEST = "NOTIFICATION_GET_REQUEST";
export const NOTIFICATION_GET_SUCCESS = "NOTIFICATION_GET_SUCCESS";
export const NOTIFICATION_GET_ERROR = "NOTIFICATION_GET_ERROR";

export const NOTIFICATION_ADD_REQUEST = "NOTIFICATION_ADD_REQUEST";
export const NOTIFICATION_ADD_SUCCESS = "NOTIFICATION_ADD_SUCCESS";
export const NOTIFICATION_ADD_ERROR = "NOTIFICATION_ADD_ERROR";

export const NOTIFICATION_UPDATE_REQUEST = "NOTIFICATION_UPDATE_REQUEST";
export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_ERROR = "NOTIFICATION_UPDATE_ERROR";


// Documentations
export const DOCUMENTATIONS_GET_REQUEST = "DOCUMENTATIONS_GET_REQUEST";
export const DOCUMENTATIONS_GET_SUCCESS = "DOCUMENTATIONS_GET_SUCCESS";

export const DOCUMENTATIONS_ADD_REQUEST = "DOCUMENTATIONS_ADD_REQUEST";
export const DOCUMENTATIONS_ADD_SUCCESS = "DOCUMENTATIONS_ADD_SUCCESS";
export const DOCUMENTATIONS_ADD_ERROR = "DOCUMENTATIONS_ADD_ERROR";

export const DOCUMENTATIONS_UPDATE_REQUEST = "DOCUMENTATIONS_UPDATE_REQUEST";
export const DOCUMENTATIONS_UPDATE_SUCCESS = "DOCUMENTATIONS_UPDATE_SUCCESS";
export const DOCUMENTATIONS_UPDATE_ERROR = "DOCUMENTATIONS_UPDATE_ERROR";

export const DOCUMENTATIONS_DELETE_REQUEST = "DOCUMENTATIONS_DELETE_REQUEST";
export const DOCUMENTATIONS_DELETE_SUCCESS = "DOCUMENTATIONS_DELETE_SUCCESS";
export const DOCUMENTATIONS_DELETE_ERROR = "DOCUMENTATIONS_DELETE_ERROR";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const CONFIGURE_STATUS_GET_REQUEST = "CONFIGURE_STATUS_GET_REQUEST";
export const CONFIGURE_STATUS_GET_SUCCESS = "CONFIGURE_STATUS_GET_SUCCESS";
export const CONFIGURE_STATUS_GET_ERROR = "CONFIGURE_STATUS_GET_ERROR";

export const CONFIGURE_STATUS_UPDATE_REQUEST = "CONFIGURE_STATUS_UPDATE_REQUEST";
export const CONFIGURE_STATUS_UPDATE_SUCCESS = "CONFIGURE_STATUS_UPDATE_SUCCESS";
export const CONFIGURE_STATUS_UPDATE_ERROR = "CONFIGURE_STATUS_UPDATE_ERROR";

export const COUNTRY_GET_REQUEST = "COUNTRY_GET_REQUEST";
export const COUNTRY_GET_SUCCESS = "COUNTRY_GET_SUCCESS";
export const COUNTRY_GET_ERROR = "COUNTRY_GET_ERROR";

export const WEEK_DAYS_GET_REQUEST = "WEEK_DAYS_GET_REQUEST";
export const WEEK_DAYS_GET_SUCCESS = "WEEK_DAYS_GET_SUCCESS";
export const WEEK_DAYS_GET_ERROR = "WEEK_DAYS_GET_ERROR";


