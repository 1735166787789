import * as ActionTypes from '../ActionTypes';

export const GetCandidateExportAction = (params) => {
    return {
        type: ActionTypes.ATS_CANDIDATE_EXPORT_GET_REQUEST,
        params
    }
}



