import * as ActionTypes from "./ActionTypes";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  loading: false,
  errorToast: false,
  startTourValue: false,
  allowedWokspaceData: [],
  userInfoData:[]
};

const SignInReducer = function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        loading: true,
        success: false,
        errorToast: false,
      };
    case ActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        success: true,
        loading: false,
        userInfo: action.payload?.payload,
        userInfoData:action.payload?.message?.data?.data,
        workSpaceData: action.payload?.data?.data,
      };
    case ActionTypes.SIGN_IN_ERROR:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errorMessage: action?.payload,
        errorToast: true,
        success: false,
        loading: false,
      };
    case ActionTypes.GET_WORKSPACE_REQUEST:
      return {
        ...state,
        isWorspaceLoading: true,
        success: false,
        error: false,
        allowedWokspaceData: []
      };
    case ActionTypes.GET_WORKSPACE_SUCCESS:
      return {
        ...state,
        isWorspaceLoading: false,
        success: true,
        error: false,
        allowedWokspaceData: action.payload?.data?.data,
      }
    case ActionTypes.GET_WORKSPACE_ERROR:
      return {
        ...state,
        isWorspaceLoading: false,
        success: false,
        error: true,
        allowedWokspaceData: []
      };
    case ActionTypes.CLEAR_ERROR:
      return {
        ...state,
        errorMessage: "",
        errorToast: false,
      };
    default:
      return state;
  }
};

export default SignInReducer;
