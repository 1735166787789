import { renderEmail } from "react-html-email";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import BaseEmailTemplate from "../../Base/views/BaseEmailTemplate";
import { ApprovedContent, CancelledContent, CancelRequestContent, RejectedContent, RequestContent } from "../views/ManageLeave/ApplyLeave/EmailTemplate";

const componenet = {
    "Submitted": RequestContent,
    "Cancel Requested": CancelRequestContent,
    "Approved": ApprovedContent,
    "Rejected": RejectedContent,
    "Cancelled": CancelledContent
}

// Email
export const sendLeaveEmail = async (params) => {
    const data = getUserData();
    var Component = componenet[params?.status]
    var userName = ["Submitted", "Cancel Requested"].includes(params?.status) ? data?.name : params?.employee
    return await axiosInstance(`/ancillary/lms/sendEmail`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_email: ["Submitted", "Cancel Requested"].includes(params?.status) ? params?.dashboardData?.Email : params?.office_email,
            email_content: renderEmail(<BaseEmailTemplate>
                <Component
                    userName={userName}
                    managerName={params?.dashboardData?.Name }
                    fromDate={params?.from_date}
                    toDate={params?.to_date}
                    leaveType={params?.leavetype_id?.leavetype_name}
                    guid={params?.guid}
                />
            </BaseEmailTemplate>)
        }
    })
        .then((res) => {
            return { message: "Email Sent Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to Send Email", hasError: false, data: err?.response?.data }
        })

}