import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const CustomRating = ({ max, value, handleInputChange, name, readonly }) => {
    const [selectedValue, setSelectedValue] = useState(null);
    useEffect(() => {
        setSelectedValue(+value);
    }, [value]);

    const getColorForRating = (rating) => {
        if (selectedValue >= 1 && rating <= selectedValue) {
            switch (rating) {
                case 1:
                    return '#ff0000';
                case 2:
                    return ' #ff1919';
                case 3:
                    return ' #ff3232';
                case 4:
                    return ' #ff4c4c';
                case 5:
                    return ' #ff6666';
                case 6:
                    return ' #ff7f7f';
                case 7:
                    return '#e5b000';
                case 8:
                    return '#ffc400';
                case 9:
                    return '#329932';
                default:
                    return '#198c19';
            }
        } else {
            return 'transparent';
        }
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {Array(max).fill().map((id, val) => {
                let value = val + 1
                return (
                    <button
                        key={value}
                        style={{
                            backgroundColor: selectedValue >= value ? getColorForRating(value) : 'transparent',
                            border: 'none',
                            borderRadius: "5px",
                            width: "10%",
                            color: selectedValue >= value ? 'white' : 'black',
                            padding: '5px',
                            height: '40%',
                            margin: '5px',
                            cursor: 'pointer',
                        }}
                        name={name}
                        onClick={() => {
                            !readonly && setSelectedValue(value);
                            !readonly && handleInputChange({ target: { name, value } });
                        }}
                        value={selectedValue}
                    >
                        {value}
                    </button>
                )
            })}
        </div>
    );
};

export default CustomRating;