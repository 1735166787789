import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const TDStep5 = ({ handleInputChange, step5, setStep5, disableEdit, activeStep, setActiveStep }) => {

    const useStyles = makeStyles((theme) =>
        createStyles({
            paper: {
                boxShadow: "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
                padding: "20px",
                borderRadius: "10px",
                minHeight: "130px"
            }
        }),
    );

    const classes = useStyles()

    const handleChange = (e) => {
        setStep5({ ...step5, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        handleInputChange({ target: { name: "tds5", value: step5 } })
        setActiveStep(activeStep + 1)
    }

    return (
        <>
            <Box>
                <Typography variant='h6'>Other Details</Typography>
                <Grid container>
                    <Grid item xs={12} sm={6} md={6} padding={1.5}>
                        <Paper className={classes.paper} >
                            <Typography variant="body2" gutterBottom mb={2}>
                                OTH1 - (Income - Savings Bank Account Interest)
                            </Typography>
                            <TextField
                                required
                                type="number"
                                size='small'
                                autoComplete='first_name'
                                onChange={handleChange}
                                name="oth1"
                                label="Amount"
                                disabled={disableEdit}
                                fullWidth
                                value={step5?.oth1 || ""}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} padding={1.5}>
                        <Paper className={classes.paper} >
                            <Typography variant="body2" gutterBottom mb={2}>
                                OTH2 - (Other Income (Other than Savings Bank Interest))
                            </Typography>
                            <TextField
                                required
                                type="number"
                                size='small'
                                autoComplete='first_name'
                                onChange={handleChange}
                                name="oth2"
                                disabled={disableEdit}
                                label="Amount"
                                fullWidth
                                value={step5?.oth2 || ""}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} padding={1.5}>
                        <TextField
                            required
                            type="number"
                            size='small'
                            placeholder='Enter Comments (If any)'
                            autoComplete='first_name'
                            multiline
                            rows={4}
                            onChange={handleChange}
                            name="comment"
                            disabled={disableEdit}
                            label="Comment"
                            fullWidth
                            value={step5?.comment || ""}
                        />
                    </Grid>
                </Grid>
            </Box>
            {
                !disableEdit &&
                <Box sx={{ bottom: 30, display: "flex", justifyContent: 'space-between' }}>
                    {activeStep !== 0 &&
                        <Button
                            sx={{ marginRight: "10px", mt: 5 }}
                            type="submit"
                            variant="outlined"
                            size="medium"
                            onClick={() => setActiveStep(activeStep - 1)}
                        >
                            Back
                        </Button>
                    }
                    {activeStep < 5 &&
                        <Button
                            sx={{ marginRight: "10px", mt: 5 }}
                            size="medium"
                            variant="contained"
                            className="mpl-primary-btn"
                            onClick={() => handleSubmit()}
                        >
                            Next
                        </Button>
                    }
                </Box>
            }
        </>
    );
}

export default TDStep5;
