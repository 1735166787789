import { data } from "jquery";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetConfigureStatusHelper = async (params) => {
    return await axiosInstance(`/ancillary/getConfigureStatus`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params.domainname,
            user_emp_id: params.userid
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateConfigureStatusHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/updateConfigureStatus`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name:data.domain,
            user_emp_id: data.emp_users_pk,
            tour_status:true
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}


export const GetCountryHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getCountrycode`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetEmployeeOnboardStatusHelper = async (params) => {
    return await axiosInstance(`/ancillary/getEmployeeOnboardStatus`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params.domainname,
            employee_id: params.employee_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateEmployeeOnboardStatusHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/updateEmployeeOnboardStatus`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name:data.domain,
            employee_id: data.employee_id,
            form_status:true
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}