import { renderEmail } from "react-html-email";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import AddCandidateEmail from "../views/ATSCandidate/AddCandidateEmail";
import { toast } from "react-toastify";
import BaseEmailTemplate from "../../Base/views/BaseEmailTemplate";

// Email
export const sendAddCandidateEmail = async (params) => {
    const data = getUserData();
    var guid = params?.guid;
    var userName = params?.name
    return await axiosInstance(`/ats/ancillary/sendAtsEmail`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            domain_name: data?.domain,
            to: params?.email_id,
            subject: params?.subject,
            email_content: renderEmail(<BaseEmailTemplate>
                <AddCandidateEmail
                    userName={userName}
                    guid={guid}
                />
            </BaseEmailTemplate>)
        }
    })
        .then((res) => {
            return { message: "Email Sent Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to Send Email", hasError: false, data: err?.response?.data }
        })

}

export const AtsEmailHelper = async (params, message) => {
    toast.info("Sending Email...")
    return await axiosInstance(`/ats/ancillary/sendAtsEmail`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            to: params?.to,
            cc: params?.cc,
            bcc: params?.bcc,
            subject: params?.subject,
            email_content: params?.content,
            attachments: params?.attachments || false,
        }
    })
        .then((res) => {
            toast.dismiss();
            message && toast.success(message)
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const ShareInvoiceHelper = async (params) => {
    toast.dismiss();
    toast.info("Sending Email...")
    return await axiosInstance(`/ats/shareInvoice`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            to: params?.to,
            cc: params?.cc,
            bcc: params?.bcc,
            subject: params?.subject,
            email_content: params?.content,
            invoice_file: params?.invoice_file,
            file_name: params?.file_name
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(params?.message || "Invoice shared successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
