import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetLeaveApprovalHelper = async () => {
    const data = getUserData();
    toast.info("Loading...");
    return await axiosInstance(`/lms/leaveApproval/get`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id
        }
    })
        .then((res) => {
            toast.dismiss();
            res?.data?.data?.length === 0 && toast.warning("No Records Found")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateLeaveApprovalHelper = async (leaveDetails) => {
    const data = getUserData();
    toast.info("Loading...");
    return await axiosInstance(`/lms/leaveApproval/update`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_empid: data?.employee_id,
            leaverequest_id: leaveDetails?.id,
            status: leaveDetails?.status,
            note: leaveDetails?.note,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(`Leave ${leaveDetails?.status} successfully`);
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}