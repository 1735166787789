import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    personalDatas: ""
}

export const EmployeePersonalReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.EMPLOYEE_PERSONAL_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                personalDatas: []
            };
        case ActionTypes.EMPLOYEE_PERSONAL_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                personalDatas: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_PERSONAL_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.EMPLOYEE_PERSONAL_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                personalDatas: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_PERSONAL_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                success: action.payload?.hasError,
            };
        case ActionTypes.EMPLOYEE_PERSONAL_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.EMPLOYEE_PERSONAL_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                personalDatas: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_PERSONAL_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: action.payload?.hasError,
            };
        case ActionTypes.EMPLOYEE_PERSONAL_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.EMPLOYEE_PERSONAL_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                personalDatas: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_PERSONAL_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: action.payload?.hasError,
            };
        default:
            return state;
    }
}