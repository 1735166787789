import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Paper, Tab, Box } from '@mui/material'
import { useState } from 'react'
import Base from '../../Base/views/Base'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Employee from './Employee/Employee';
import Dashboard from './Dashboard/Dashboard';
import "../css/style.css";
import Leave from './Leave/Leave';
import Others from './Others/Others';


const HRSettings = () => {
    const [value, setValue] = useState('1');
    const [accordian, setAccordian] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Base>
            <Paper className='admin-setting' sx={{ borderRadius: "10px", minHeight: "87vh", maxHeight: "87vh", overflowY: "auto" }}>
                <TabContext value={value}>
                    <Box>
                        <TabList sx={{
                            minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                            '& .MuiTabs-indicator': {
                                width: '100%',
                                display: 'flex',
                                justifyContent: "center !important",
                            },
                        }} onChange={handleChange} aria-label="lab API tabs example">
                            <Tab icon={<DashboardIcon fontSize='small' />} iconPosition="start" label="Dashboard" value="1" />
                            <Tab icon={<ManageHistoryIcon fontSize='small' />} iconPosition="start" label="Employee" value="2" />
                            <Tab icon={<AssessmentIcon fontSize='small' />} iconPosition="start" label="Leave" value="3" />
                            <Tab icon={<AutoAwesomeIcon fontSize='small' />} iconPosition="start" label="Organization" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                        <Dashboard setValue={setValue} setAccordian={setAccordian} />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px" }}>
                        <Employee accordian={accordian} />
                    </TabPanel>
                    <TabPanel value="3" sx={{ padding: "0px" }}>
                        <Leave accordian={accordian} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: "0px" }}>
                        <Others accordian={accordian} />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Base>

    )
}

export default HRSettings;