import * as ActionTypes from '../ActionTypes';

export const GetLeaveTypeAction = () => {
    return {
        type: ActionTypes.LEAVE_TYPE_GET_REQUEST,
    }
}


export const AddLeaveTypeAction = (params) => {
    return {
        type: ActionTypes.LEAVE_TYPE_ADD_REQUEST,
        params
    }
}

export const UpdateLeaveTypeAction = (params) => {
    return {
        type: ActionTypes.LEAVE_TYPE_UPDATE_REQUEST,
        params
    }
}

export const DeleteLeaveTypeAction = (params) => {
    return {
        type: ActionTypes.LEAVE_TYPE_DELETE_REQUEST,
        params
    }
}