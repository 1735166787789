import * as ActionTypes from '../ActionTypes';

export const GetStudyProgramAction = (params) => {
    return {
        type: ActionTypes.STUDYPROGRAM_GET_REQUEST,
        params
    }
}

export const AddStudyProgramAction = (params) => {
    return {
        type: ActionTypes.STUDYPROGRAM_ADD_REQUEST,
        params
    }
}

export const UpdateStudyProgramAction = (params) => {
    return {
        type: ActionTypes.STUDYPROGRAM_UPDATE_REQUEST,
        params
    }
}

export const DeleteStudyProgramAction = (params) => {
    return {
        type: ActionTypes.STUDYPROGRAM_DELETE_REQUEST,
        params
    }
}