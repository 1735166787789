import React from "react";
import "./Down.css";
import Downimg from "../../../../../Image/Down.jpg";
function DownTimePage() {
  
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="workflow-title-wrapper-down">
            <div
              style={{ marginTop:"50px"}}
              data-w-id="36162ea4-93b5-cb92-902f-d3aece1925f2"
              className="workflow-content-left"
            >
             <h2 style={{ marginBottom:"20px"}} className="title">We are making Meepl better!</h2>
             <p style={{ fontSize:"20px", fontWeight:"400"}}>Currently we are undergoing scheduled maintenance.<br></br>Thank you for you patience.</p>
            </div>
            <div
              data-w-id="36162ea4-93b5-cb92-902f-d3aece1925f8"
              className="workflow-content-right"
            >
                <div className="fade-in-move-on-scroll">
                <div className="title-with-emoji">
                  <div className="contact_img">
                    <img alt="" src={Downimg} />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DownTimePage;
