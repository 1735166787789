import { toast } from "react-toastify";
import NProgress from 'nprogress';
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";


export const GetMeeplAdminSkillsHelper = async (params) => {
    let domain = sessionStorage.getItem('userManagement')
    NProgress.start()
    return await axiosInstance(`/ats/ancillary/getMeeplAdminSkills`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain,
            industry_code: params?.industry_code || null
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetSkillsHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    NProgress.start()
    return await axiosInstance(`/ats/ancillary/getSkills`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
            industry_code: params?.industry_code || null
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddSkillsHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    let user_id = sessionStorage.getItem('userid')
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/ancillary/addSkills`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
            user_id: user_id ? +user_id : +user?.ats_user_id,
            industry_code: params?.industry_code,
            skill_name: params?.skill_name
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Skills added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateSkillsHelper = async (params) => {
    var user = getUserData();
    let domain = sessionStorage.getItem('userManagement')
    let user_id = sessionStorage.getItem('userid')
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/ancillary/updateSkills`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: domain ? domain : user.domain,
            user_id: user_id ? +user_id : +user?.ats_user_id,
            industry_code: params?.industry_code,
            skills_id: params?.skills_id,
            skill_code: params?.skill_code,
            skill_name: params?.skill_name
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Skills updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteSkillsHelper = async (params) => {
    var user = getUserData();
    let value = sessionStorage.getItem('userManagement')
    toast.info("Saving...")
    return await axiosInstance(`/ats/ancillary/deleteSkills`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: value ? value : user.domain,
            skill_code: params?.skill_code,
            industry_code: params?.industry_code || null,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Skill Deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}