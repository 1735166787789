import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
};

const NotificationReducer = function (state = initialState, action) {
    switch (action.type) {

        case ActionTypes.NOTIFICATION_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.NOTIFICATION_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                notificationData: action.payload?.data?.data
            };
        case ActionTypes.NOTIFICATION_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.NOTIFICATION_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.NOTIFICATION_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                notificationData: action.payload?.data?.data
            };
        case ActionTypes.NOTIFICATION_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.NOTIFICATION_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.NOTIFICATION_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                notificationData: action.payload?.data?.data
            };
        case ActionTypes.NOTIFICATION_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}
export default NotificationReducer;