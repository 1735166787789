import { Box, Grid, IconButton, Snackbar, Typography } from '@mui/material';
import React from 'react';
import Avatar from 'react-avatar';
import Popup from '../../Base/views/Popup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useEffect, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { month } from '../../Base/helper/baseFunctions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeProfile = ({ openPopup, setOpenPopup, data }) => {
    const [copied, setCopied] = useState(false)
    const handleClick = () => {
        setCopied(true)
        navigator.clipboard.writeText(data?.office_email)
    }
    useEffect(() => {
        if (data && !data.employee_id) {
            toast.error('Employee does not exist');
            setOpenPopup(false)
        }
    }, [data]);
    return (
        <>
            <Popup
                title="Employee"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                isMasterProduct
            >
                <Snackbar
                    open={copied}
                    onClose={() => setCopied(false)}
                    autoHideDuration={2000}
                    message="Copied to clipboard"
                />
                <Box sx={{ maxWidth: "450px", minWidth: "450px" }}>
                    <Grid container justifyContent="space-evenly" alignItems="center">
                        <Grid item xs={12} sm={4} md={4} sx={{ textAlign: "center" }}>
                            {
                                data?.image ?
                                    <img style={{ padding: "0px 4px", borderRadius: "10px" }} height="120" alt="Nothing" src={data?.image} />
                                    :
                                    <Avatar name={data?.name} round="10%" size='100' style={{ padding: "3px", paddingLeft: "0px" }} />
                            }
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} paddingLeft="20px">
                            <Typography variant="h6" sx={{ paddingBottom: "10px", color: "dodgerblue" }}>{data?.name} </Typography>
                            {data?.businessunit &&
                            <Typography variant='body1' >
                                <IconButton sx={{ padding: "0px 10px 3px 0px" }} disableFocusRipple disabled>
                                    <AccountBalanceIcon fontSize='small' sx={{ fontSize: "20px" }} />
                                </IconButton>
                                {data?.designation} {data?.businessunit && "at"} <span style={{ fontWeight: "bold" }}>{data?.businessunit}</span>
                            </Typography>
                            }
                            {data?.reporting_manager &&
                                <Typography variant='body1' >
                                    <IconButton sx={{ padding: "0px 10px 3px 0px" }} disableFocusRipple disabled>
                                        <PermIdentityIcon fontSize='small' sx={{ fontSize: "20px" }} />
                                    </IconButton>
                                    Reporting to <span style={{ fontWeight: "bold" }}>{data?.reporting_manager}</span>
                                </Typography>
                            }

                            <Typography variant='body1' ><IconButton sx={{ padding: "0px 10px 3px 0px" }} disableFocusRipple disabled><EmailIcon fontSize='small' sx={{ fontSize: "20px" }} /></IconButton>{data?.office_email}<IconButton sx={{ padding: "0px 8px", fontSize: "12px" }} title="Copy" placement="top-start" onClick={handleClick}><ContentCopyIcon fontSize='small' sx={{ fontSize: "15px" }} /></IconButton></Typography>
                            {data?.date_of_birth && <Typography variant='body1' >
                                <IconButton sx={{ padding: "0px 10px 3px 0px" }} disableFocusRipple disabled>
                                    <CakeIcon fontSize='small' sx={{ fontSize: "20px" }} />
                                </IconButton>{new Date(data?.date_of_birth).getDate() + "-" + month[new Date(data?.date_of_birth).getMonth()]}
                            </Typography>}
                            {data?.work_location && <Typography variant='body1' ><IconButton sx={{ padding: "0px 10px 3px 0px" }} disableFocusRipple disabled><LocationOnIcon fontSize='small' sx={{ fontSize: "20px" }} /></IconButton>{data?.work_location}</Typography>}
                        </Grid>
                    </Grid>
                </Box>
            </Popup>
        </>
    );
}

export default EmployeeProfile;
