import React from "react";
import { Box, Button, Typography } from "@mui/material";

const LoginCart = ({ toggle, setToggle }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        top: 0,
        height: "100%",
        width: "50%",
        gap: 3,
      }}
    >
      <Typography
        className="head-sign-sec-h5"
        variant="h5"
      >
        Welcome Back!
      </Typography>
      <Typography
        className="head-sign-sec-h3"
        variant="h3"
        sx={{ width: "70%" }}
      >
        To keep connected with us please login with your personal info
      </Typography>
      <Button
        className="button-sign-sec"
        style={{ fontFamily: "Clashgrotesk, sans-serif" }}
        onClick={() => setToggle(true)}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#ddb106")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#fecb03")}
      >
        Sign In
      </Button>
    </Box>
  );
};

export default LoginCart;
