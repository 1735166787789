import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    isLoading: false,
    featuresData: []
};

const ATSModuleFeaturesReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_MODULE_FEATURES_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                featuresData: []
            };
        case ActionTypes.ATS_MODULE_FEATURES_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success_message: action.payload.message,
                error: action.payload.hasError,
                featuresData: action.payload?.data?.data
            };
        default:
            return state;
    }
}

export default ATSModuleFeaturesReducer;