import { toast } from "react-toastify";
import NProgress from 'nprogress';
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetUserCompanyConsultancyHelper = async () => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getUserCompanyConsultancy`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_empid: user?.ats_user_id,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsCompanysHelper = async () => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getCompany`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAtsCompanyHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addCompany`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_emp_id: user?.ats_user_id,
            name: params?.name,
            address: params?.address,
            contact_person: params?.contact_person,
            mobile_number: +params?.mobile_number || null,
            office_number: params?.office_number,
            country_id: +params?.country_id?.country_id || null,
            state_id: +params?.state_id?.state_id || null,
            city_id: +params?.city_id?.city_id || null,
            zip_code: params?.zip_code,
            industry: params?.industry,
            about_company: params?.about_company,
            website: params?.website,
            primary_owner: params?.primary_owner,
            company_lead_id: params?.company_lead_id,
            business_unit_id: +params?.business_unit_id || null,
            email_id: params?.email_id,
            billing_name: params?.billing_name,
            billing_address: params?.billing_address,
            gst_applicable: params?.gst_applicable,
            gst: params?.gst || null,
            cgst: params?.cgst || 9,
            sgst: params?.sgst || 9,
            igst: params?.igst || null,
            sac_code: params?.sac_code || null,
            status: params?.status,
            is_same: params?.is_same
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Client added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsCompanyHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateCompany`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_emp_id: user?.ats_user_id,
            company_id: params?.ats_company_id,
            name: params?.name,
            address: params?.address,
            contact_person: params?.contact_person,
            mobile_number: +params?.mobile_number || null,
            office_number: params?.office_number,
            country_id: +params?.country_id?.country_id || null,
            state_id: +params?.state_id?.state_id || null,
            city_id: +params?.city_id?.city_id || null,
            zip_code: params?.zip_code,
            industry: params?.industry,
            about_company: params?.about_company,
            website: params?.website,
            primary_owner: params?.primary_owner,
            company_lead_id: params?.company_lead_id,
            business_unit_id: +params?.business_unit_id || null,
            email_id: params?.email_id,
            billing_name: params?.billing_name,
            billing_address: params?.billing_address,
            gst_applicable: params?.gst_applicable,
            gst: params?.gst,
            cgst: params?.cgst ,
            sgst: params?.sgst ,
            igst: params?.igst || null,
            sac_code: params?.sac_code || null,
            is_same: params?.is_same
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Client Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const DeleteAtsCompanyHelper = async (params) => {
    var user = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/ats/deleteCompany`, {
        method: "DELETE",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            company_id: params?.company_id,
            status: params?.status
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
