import * as ActionTypes from '../ActionTypes';

const initialLeaveHistoryState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
};

export const LeaveHistoryReducer = function (state = initialLeaveHistoryState, action) {
    switch (action.type) {
        case ActionTypes.LEAVE_HISTORY_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                leaveHistoryData: []
            };
        case ActionTypes.LEAVE_HISTORY_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveHistoryData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_HISTORY_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}