import * as ActionTypes from '../ActionTypes';
export const PythonApiAction = (params) => {
    return {
        type: ActionTypes.ATS_RESUME_PYTHON_API_REQUEST,
        params
    }
}

export const PythonResetApiAction = (params) => {
    return {
        type: ActionTypes.ATS_RESUME_PYTHON_API_RESET ,
        params
    }
}
