import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";

export const PreScreenComponentsRender = ({
    setCheckData,
    setFormData,
    checkData,
    previewErrors,
    Previewvalidate,
    formFields,
    setFormComponents,
    formData
}) => {

    const handleCheckChange = (e, fieldName) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckData((prevCheckData) => ({
                values: [...prevCheckData.values, value],
            }));
        } else {
            setCheckData((prevCheckData) => ({
                values: prevCheckData.values.filter((val) => val !== value),
            }));
        }
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: checkData?.values,
        }));
    };

    const handlePreviewChange = (e, type) => {
        const fieldName = e.target?.name;
        let fieldValue;
        if (e.target?.type === 'file') {
            fieldValue = e.target?.files[0];
            setFormData((prevData) => ({
                ...prevData,
                [fieldName]: fieldValue,
            }));
        } else if (type === 'checkbox') {
            handleCheckChange(e, fieldName);
        } else {
            fieldValue = e.target?.value;
            setFormData((prevData) => ({
                ...prevData,
                [fieldName]: fieldValue,
            }));
        }
        Object.values?.(previewErrors)?.find(res => res !== "")?.length > 0 &&
            Previewvalidate(formFields, { ...formData, [fieldName]: fieldValue, });
    }

    const generatedComponents = formFields?.map((field, index) => {
        switch (field.type) {
            case "text":
                return (
                    <>
                        <FormLabel sx={{ mb: -1.5, fontSize:"18px"}}>{`${index + 1}. ${field.label}`}</FormLabel>
                        <TextField
                            sx={{margin:"2.2rem 0px 0px 1.25rem",
                            borderRadius:"4px",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "transparent",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#883bd5",
                                    borderBottomWidth: 2,
                                    borderBottomColor: "#883bd5",
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                },
                            },
                            
                            
                            }}
                            
                            InputLabelProps={{
                                sx: {
                                    marginLeft:"5px", // Adjust this value to move the label to the right if needed
                                }
                            }}
                            key={field.name}
                            label={"Enter your answer"}
                            name={field.name}
                            fullWidth
                            multiline
                            variant="standard"
                            margin="normal"
                            size="small"
                            required={field?.required}
                            defaultValue=""
                            onChange={handlePreviewChange}
                            {
                            ...field && field?.required && previewErrors[field?.name] && {
                                error: true,
                                helperText: previewErrors[field?.name],
                            }
                            }
                        />
                    </>
                );
            case "radio":
                return (
                    <>
                        {/* {field?.multiple ? <FormGroup>
                            <FormLabel>{field.label}</FormLabel>
                            {field?.options &&
                                field?.options.map((option, optionIndex) => (
                                    <FormControlLabel name={field.name} onChange={(e) => handlePreviewChange(e, 'checkbox')} control={<Checkbox />} value={option.value} label={option.label} required={field?.required} />))}
                            {previewErrors[field.name] && (
                                <Typography variant="body2" color="error">
                                    {previewErrors[field.name]}
                                </Typography>
                            )}
                        </FormGroup>
                            : */}
                        <FormControl key={field.name} component="fieldset" margin="normal">
                            <FormLabel sx={{fontSize:"18px"}} required={field?.required} component="legend">{`${index + 1}. ${field.label}`}</FormLabel>
                            <RadioGroup>
                                {field?.options &&
                                    field?.options.map((option, optionIndex) => (
                                        <FormControlLabel
                                            sx={{margin:"10px 0px 0px 1.25rem"}}
                                            key={option.value}
                                            value={option.value}
                                            control={<Radio />}
                                            label={option.label}
                                            name={field.name}
                                            onChange={handlePreviewChange}
                                        />
                                    ))}
                            </RadioGroup>
                            {previewErrors[field.name] && (
                                <Typography variant="body2" color="error">
                                    {previewErrors[field.name]}
                                </Typography>
                            )}
                        </FormControl>
                        {/* } */}
                    </>
                );
            case "file":
                return (
                    <>
                        <FormLabel sx={{ mb: -1.5, fontSize:"18px"}} required={field?.required}>{`${index + 1}. ${field.label}`}</FormLabel>
                        <TextField
                            sx={{margin:"2.2rem 0px 0px 1.25rem",
                            borderRadius:"4px",
                            }}
                            key={field.name}
                            name={field.name}
                            size="small"
                            required={field?.required}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            type="file"
                            onChange={handlePreviewChange}
                            {...field && field?.required && previewErrors[field?.name] && {
                                error: true,
                                helperText: previewErrors[field?.name],
                            }}
                        />
                    </>
                );
            default:
                return null;
        }
    });

    setFormComponents(generatedComponents);
};