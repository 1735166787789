import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MyTaskPopup from "./MyTaskPopup";
import React from 'react';
import { Typography, Box, Card, CardContent, List, Divider, IconButton, Tooltip, ListItem, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from 'react-redux';
import { DeleteAssignedTaskAction, GetAssignedTaskAction, UpdateMyTaskAction } from '../../../../../store/ATS/MyTask/Action';
import { ConvertDate, ConvertDateTime } from '../../../Base/helper/baseFunctions';
import { AddNotificationHelper } from '../../../Ancillary/Notification/helper/NotificationHelper';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


const AssignedTask = ({ mode, setMode, openPopup, setOpenPopup, popupData, setPopupData }) => {
    const theme = useTheme();
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const today = year + '-' + month.toString().padStart(2, '0') + '-' + day;

    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const { assignedData } = useSelector((state) => state?.AtsReducer?.AtsTaskReducer);
    const [deleteId, setDeleteId] = useState('');
    const [sort,setSort]= useState(false);
    const [sortassignedData,setsortassignedData] = useState(assignedData);
    useEffect(() => {
        dispatch(GetAssignedTaskAction({ type: 'assigned_by' }));
        //eslint-disable-next-line
    }, []);
    useEffect(()=>{
        setsortassignedData(assignedData);
    },[assignedData])
    const handleAdd = () => {
        setPopupData([]);
        setMode("Add")
        setOpenPopup(true);
    };

    const handleSort =()=>{
        setSort(!sort);
        sorting();
    }
    const sorting = (e)=>{
        
         const sortedData = [...assignedData].sort((a, b) => {
            return !sort ? new Date(a.due_date) - new Date(b.due_date) : 0;
          });
          setsortassignedData(sortedData);
             
         }

    const handleReminder = (e, id, date, title, task_id) => {
        e.stopPropagation();
        AddNotificationHelper({ notification_data: JSON.stringify([{ user_id: id, comment: `Your task ${title} is due on ${ConvertDate(date)}`, is_read: "N", toast: true, message: "Reminder sent successfully" }]) })
        let mytask = assignedData?.filter(res => +res?.task_id === +task_id)[0]
        dispatch(UpdateMyTaskAction({
            assigned_to: mytask?.assigned_to,
            title: mytask?.title,
            task: mytask.task,
            task_id: mytask?.task_id,
            status: mytask?.status,
            due_date: mytask.due_date,
            priority: mytask.priority,
            proof: null,
            proof_name: mytask.proof || null,
            comments: mytask.comments,
            completed_date: mytask.completed_date || null,
            remainder_date: new Date().toISOString(),
            is_my_task: false,
            message: "Reminder sent successfully"
        }))
        dispatch(GetAssignedTaskAction({ type: 'assigned_by' }));
    }

    const handleEdit = (task) => {
        setPopupData(task);
        setMode("Edit")
        setOpenPopup(true);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        setDeleteId(id);
        setOpenDialog(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(DeleteAssignedTaskAction({ task_id: deleteId }));
        setOpenDialog(false);
        setDeleteId('')
    };
    return (
        <>
            <Box sx={{ display: 'block', minHeight: "39vh" }}>
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        py: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: 2,
                        }}
                    >
                        <Typography sx={{ pl: 2 }} variant="h6">
                            Task List
                        </Typography>
                        <Tooltip title="Add Task">
                            <IconButton onClick={handleAdd}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Divider />
                    <List sx={{ px: 2 }}>
                        <ListItem sx={{ height: "12px" }}>
                            <ListItemText primary="Task" sx={{ flex: '0 0 40%' }} />
                            <ListItemText primary="Assign To" sx={{ flex: '0 0 15%' }} />
                            <ListItemText primary="Due Date"  secondary={sort?  <ArrowUpwardIcon onClick={()=>(handleSort())} fontSize='inherit' sx={{width:'1rem',height:'1rem', marginTop:'0.3rem',marginLeft:'0.2rem',cursor:'pointer'}}/> :<ArrowDownwardIcon onClick={()=>(handleSort())}  fontSize='inherit' sx={{width:'1rem',height:'1rem', marginTop:'0.3rem',marginLeft:'0.2rem',cursor:'pointer'}}/>} sx={{ flex: '0 0 20%' }} style={{display:'flex',flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}} />        
                            <ListItemText primary="Status" sx={{ flex: '0 0 10%' }} />
                            <ListItemText primary="Reminder" sx={{ flex: '0 0 10%', textAlign: "center" }} />
                            <ListItemText primary="Action" sx={{ flex: '0 0 10%', textAlign: "center" }} />
                        </ListItem>
                    </List>
                    <Divider sx={{ mb: -1 }} />
                </Box>
                <Box sx={{ maxHeight: "30vh", overflowY: "scroll", backgroundColor: "white" }}>
                    <Card sx={{ mt: -3, borderRadius: "0px" }}>
                        <CardContent>
                            {assignedData.length === 0 ? (
                                <Typography variant="body1" align="center" p={10}>
                                    Well done. All tasks has been catched up.
                                </Typography>
                            ) : (
                                <List>
                                    {sortassignedData?.map((res) => {
                                        let date = new Date()
                                        let disable = res?.remainder_date && `${ConvertDateTime(res?.remainder_date)}` === ConvertDate(`${date?.getFullYear()}-${date?.getMonth() + 1}-${date.getDate()}`);
                                        return (
                                            <Card key={res?.task_id} sx={{ mb: 1, height: '33px' }}>
                                                <ListItem
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer'
                                                        },
                                                        mt: -1,
                                                        backgroundColor:theme?.palette?.primary?.bg,
                                                        fontSize:"13px"
                                                    }}
                                                    onClick={() => handleEdit(res)}>
                                                    <ListItemText primary={res?.title} sx={{ flex: '0 0 40%' }} />
                                                    <ListItemText primary={res?.name} sx={{ flex: '0 0 15%' }} />
                                                    <ListItemText primary={ConvertDateTime(res?.due_date)} sx={{ flex: '0 0 15%' }} />
                                                    <ListItemText primary={res?.status} sx={{ flex: '0 0 10%' }} />
                                                    <ListItemText sx={{ flex: '0 0 10%', textAlign: "center" }}>
                                                        <Tooltip title={disable ? "Reminder already sent" : res?.due_date >= today ? "Task due date not reached" : "Send reminder"}>
                                                            <span>
                                                                <IconButton disabled={res?.due_date >= today || disable} onClick={(e) => handleReminder(e, res?.assigned_to, res?.due_date, res?.title, res?.task_id)} sx={{ p: 0 }}>
                                                                    <NotificationsActiveIcon fontSize='small' color={res?.due_date >= today || disable ? 'none' : 'primary'} />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </ListItemText>
                                                    <ListItemText sx={{ flex: '0 0 10%', textAlign: "center" }}>
                                                        <Tooltip title="Delete">
                                                            <span>
                                                                <IconButton disabled={res?.status !== 'Yet to Start'} onClick={(e) => handleDelete(e, res?.task_id)} sx={{ p: 0 }}>
                                                                    <DeleteOutlineIcon fontSize='small' color={res?.status !== 'Yet to Start' ? 'none' : 'error'} />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </ListItemText>
                                                </ListItem>
                                            </Card>
                                        );
                                    })}
                                </List>)}
                        </CardContent>
                    </Card>
                </Box>
                <MyTaskPopup mode={mode} setMode={setMode} assigned_task={true} openPopup={openPopup} setOpenPopup={setOpenPopup} data={popupData} setData={setPopupData} />
                <ConfirmationDialog
                    title="Are you sure to Delete this Task?"
                    openDialog={openDialog}
                    closeDialog={setOpenDialog}
                    OkButtonText="Delete"
                    onSubmit={handleDeleteConfirm}
                />
            </Box>
        </>
    );
};

export default AssignedTask;