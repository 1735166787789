import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import * as CryptoJS from "crypto-js";
import { addSessionData } from "../../../Base/helper/baseHelper";

export const GetUsersHelper = async (params, dispatch) => {
    return await axiosInstance(`/user/users`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            workspace: params.domain,
            user_emp_id: params.userid
        }
    })
        .then((res) => {
            let data = { ...res.data.data?.[0], domain: params?.domain}
            addSessionData({ key: "userData", value: CryptoJS.AES.encrypt(JSON.stringify(data), 'userData').toString() });
            addSessionData({ key: "company_full_name", value: res.data.data?.[0].company_full_name });
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}