import * as ActionTypes from "../ActionTypes";

const familyInitialState = {
  isLoading: false,
  success: false,
  error: false,
  successMessage: "",
  otherPersonalData: "",
  employeeAddressData: "",
};

export const EmployeeOtherPersonalReducer = function (
  state = familyInitialState,
  action
) {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_OTHER_PERSONAL_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        successMessage: "",
        error: false,
        errorMessage: "",
        otherPersonalData: "",
      };
    case ActionTypes.EMPLOYEE_OTHER_PERSONAL_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        otherPersonalData: action.payload?.data?.data,
      };
    case ActionTypes.EMPLOYEE_OTHER_PERSONAL_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };
    case ActionTypes.EMPLOYEE_OTHER_PERSONAL_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        otherPersonalData: action.payload?.data?.data,
      };
    case ActionTypes.EMPLOYEE_OTHER_PERSONAL_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        success: action.payload?.hasError,
      };
    case ActionTypes.EMPLOYEE_ADDRESS_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        successMessage: "",
        error: false,
        errorMessage: "",
        employeeAddressData: "",
      };
    case ActionTypes.EMPLOYEE_ADDRESS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        employeeAddressData: action.payload?.data?.data,
      };

    case ActionTypes.EMPLOYEE_ADDRESS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };
    case ActionTypes.EMPLOYEE_ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        employeeAddressData: action.payload?.data?.data,
      };
    case ActionTypes.EMPLOYEE_ADDRESS_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        success: action.payload?.hasError,
      };
    default:
      return state;
  }
};
