import * as ActionTypes from '../ActionTypes';

export const GetLeavingReasonAction = () => {
    return {
        type: ActionTypes.LEAVING_REASON_GET_REQUEST,
    }
}

export const AddLeavingReasonAction = (params) => {
    return {
        type: ActionTypes.LEAVING_REASON_ADD_REQUEST,
        params
    }
}

export const UpdateLeavingReasonAction = (params) => {
    return {
        type: ActionTypes.LEAVING_REASON_UPDATE_REQUEST,
        params
    }
}

export const DeleteLeavingReasonAction = (params) => {
    return {
        type: ActionTypes.LEAVING_REASON_DELETE_REQUEST,
        params
    }
}