import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Avatar, Box, Card, Divider, Grid, Tab, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import Personal from './Personal';
import Employment from './Employment';
import "../../../css/style.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { useSelector } from 'react-redux';

const ProfileLandingPage = () => {

    const [profileValue, setProfileValue] = useState("1");
    const { employeeProfile_data } = useSelector(state => state.FinanceReducer.EmployeeProfileReducer)
    let data = employeeProfile_data?.[0]

    const handleProfileChange = (event, newValue) => {
        setProfileValue(newValue);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                    <Card sx={{ m: 2, p: 3, minHeight: "100%" }}>
                        <Box sx={{ mb: 2, display: "flex", justifyContent: "center", cursor: "default" }}>
                            <Box sx={{ border: "none" }}>
                                <div className='presentation'>
                                    <div className='placeholder'>
                                        {
                                            data?.image ?
                                                <img style={{ borderRadius: "50%", padding: "2px" }} alt="Nothing" width="100%" height="100%" src={data?.image} /> :
                                                <Avatar src="" sx={{ width: 120, height: 120 }} />
                                        }
                                    </div>
                                </div>
                                <Typography className='content-sec'> {data?.first_name} </Typography>
                                <Typography className='content-sec'> {data?.designation} </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{ mt: 2 }}>
                            <Typography sx={{ pt: 2, pb: 2 }}>Name : {data?.first_name}</Typography>
                            <Typography sx={{ pt: 2, pb: 2 }}>Employee-ID : {data?.employee_no}</Typography>
                            <Typography sx={{ pt: 2, pb: 2 }}>Official Email : {data?.office_email}</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    <Card sx={{ m: 2, minHeight: "100%" }}>
                        <Box sx={{ width: '100%' }}>
                            <TabContext value={profileValue}>
                                <Box>
                                    <TabList sx={{
                                        minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                        '& .MuiTabs-indicator': {
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: "center !important",
                                        },
                                    }} onChange={handleProfileChange} aria-label="lab API tabs example">
                                        <Tab icon={<AccountCircleIcon fontSize='small' />} iconPosition="start" label="Personal Details" value="1" />
                                        <Tab icon={<HomeRepairServiceIcon fontSize='small' />} iconPosition="start" label="Employment Details" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                                    <Personal data={data} />
                                </TabPanel>
                                <TabPanel value="2" sx={{ padding: "0px" }}>
                                    <Employment data={data} />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default ProfileLandingPage;
