import { put, call } from "redux-saga/effects";
import { SignInHelper } from "../../app/Components/SignIn/helper/SigninHelper";
import { GetWorkspaceHelper } from "../../app/Components/SignIn/helper/SigninHelper";
import * as ActionTypes from "./ActionTypes";

export function* SignInSaga(payload) {
  let Value = payload.user.step;
  if (Value === 1) {
    const response = yield call(GetWorkspaceHelper, payload.user);
    if (response?.data?.statuscode === 200) {
      yield put({ type: ActionTypes.SIGN_IN_SUCCESS, payload: response });
    } else {
      yield put({
        type: ActionTypes.SIGN_IN_ERROR,
        payload: response?.data?.error?.[0]?.message,
      });
    }
  } else {
    const response = yield call(SignInHelper, payload.user, payload.dispatch);
    if (response?.message?.status === 200) {
      yield put({ type: ActionTypes.SIGN_IN_SUCCESS, payload: response });
    } else {
      yield put({
        type: ActionTypes.SIGN_IN_ERROR,
        payload: response?.message?.error?.[0]?.message,
      });
    }
  }
}

export function* GetWorkspaceSaga(payload) {
  const response = yield call(GetWorkspaceHelper, payload.params)
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.GET_WORKSPACE_SUCCESS, payload: response })
  } else {
    yield put({ type: ActionTypes.GET_WORKSPACE_ERROR, payload: response })
  }
}

