import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: true,
    isSuccess: false,
    isError: false,
    taxDeclaration_data: []
}

export const TaxDeclarationReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.TAX_DECLARATION_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.TAX_DECLARATION_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                taxDeclaration_data: action.payload?.data?.data
            };
        case ActionTypes.TAX_DECLARATION_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.TAX_DECLARATION_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.TAX_DECLARATION_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                taxDeclaration_data: action.payload?.data?.data
            };
        case ActionTypes.TAX_DECLARATION_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.TAX_DECLARATION_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.TAX_DECLARATION_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                taxDeclaration_data: action.payload?.data?.data
            };
        case ActionTypes.TAX_DECLARATION_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}
