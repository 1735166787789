import * as ActionTypes from '../ActionTypes';

export const GetProofTypeAction = (params) => {
    return {
        type: ActionTypes.PROOF_TYPE_GET_REQUEST,
        params
    }
}

export const AddProofTypeAction = (params) => {
    return {
        type: ActionTypes.PROOF_TYPE_ADD_REQUEST,
        params
    }
}

export const UpdateProofTypeAction = (params) => {
    return {
        type: ActionTypes.PROOF_TYPE_UPDATE_REQUEST,
        params
    }
}

export const DeleteProofTypeAction = (params) => {
    return {
        type: ActionTypes.PROOF_TYPE_DELETE_REQUEST,
        params
    }
}