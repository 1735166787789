import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import {
  UpdateLeaveYearTypeAction
} from "../../../../../store/Leave/LeaveYearType/Action";
import { GetLeaveYearTypeHelper } from "../../helper/LeaveYearTypeHelper";

const YearType = () => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      Paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#e6d9fb7a",
      },
      comingSoon: {
        fontWeight: 600,
        fontSize: "18px",
        marginTop: "30px",
        color: "white",
      },
    })
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { leaveYearTypeData } = useSelector(
    (state) => state?.LeaveReducer?.LeaveYearTypeReducer
  );
  const initialValue = {
    year_type: "",
    start_date: "",
    end_date: "",
    carry_over_days: "",
    start_date_change:false,
  };
  const [inputData, setInputData] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const Disable = inputData?.year_type === "calendar_year";
  useEffect(() => {
    setInputData(leaveYearTypeData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("start_date" in fieldValues) {
        temp.start_date = (fieldValues.start_date === null || fieldValues.start_date === "") ? "Start Date is required" : "";
    }
    if ("carry_over_days" in fieldValues) {
      temp.carry_over_days =
        fieldValues.carry_over_days === "" || null
          ? "Carry Forward Days is required"
          : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const handleInput = (event, value) => {
    if (value === "calendar_year") {
      GetLeaveYearTypeHelper({ year_type: value }).then((res) => {
        if (res?.data?.statuscode === 200) {
          setInputData(res?.data?.data[0]);
        }
      });
    } else if (value === "financial_year") {
      setInputData({
        year_type: "financial_year",
        start_date: null,
        end_date: null,
        carry_over_days: "",
      });
    } else if (event.target.name === "start_date") {
      const newEndDate = addDays(event.target.value, 365);
      setInputData({
        ...inputData,
        [event.target.name]: event.target.value,
        end_date: newEndDate,
        start_date_change:true
      });
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    }
  };
  const addDays = (dateString, days) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    return date.toISOString().split("T")[0]; 
  };
  const handleSubmit = async () => {
    if (validate(inputData)) {
      dispatch(UpdateLeaveYearTypeAction(inputData));
    }
  };

  return (
    <Box>
      <Paper
        className={classes.Paper}
        sx={{ padding: "80px 24px 40px", borderRadius: "16px" }}
      >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="year_type"
            onChange={handleInput}
            value={inputData?.year_type}
          >
            <FormControlLabel
              value="calendar_year"
              control={<Radio />}
              label="Calendar Year"
            />
            <FormControlLabel
              value="financial_year"
              control={<Radio />}
              label="Financial Year"
            />
          </RadioGroup>
        </FormControl>
        <Grid container>
          <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
            <FormControl fullWidth>
              <CommonDatePicker
                required={true}
                disabled={Disable}
                size="small"
                autoFocus={true}
                name="start_date"
                label="Start Date"
                pickerType="date"
                onKeyPress={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
                // maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                pickerFormat="dd/MM/yyyy"
                DefaultDTvalue={inputData?.start_date}
                handleChange={(value) =>
                  handleInput({
                    target: {
                      name: "start_date",
                      value: value
                        ? `${
                            value?.getFullYear() +
                            "-" +
                            ("0" + (value.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value.getDate()).slice(-2)
                          }`
                        : "",
                    },
                  })
                }
                {...(errors.start_date && {
                  error: true,
                  helperText: errors.start_date,
                })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
            <FormControl fullWidth>
              <CommonDatePicker
                required={true}
                disabled
                size="small"
                autoFocus={true}
                name="end_date"
                label="End Date"
                pickerType="date"
                onKeyPress={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
                // maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                pickerFormat="dd/MM/yyyy"
                DefaultDTvalue={inputData?.end_date}
                handleChange={(value) =>
                  handleInput({
                    target: {
                      name: "end_date",
                      value: value
                        ? `${
                            value?.getFullYear() +
                            "-" +
                            ("0" + (value.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value.getDate()).slice(-2)
                          }`
                        : "",
                    },
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
            <FormControl fullWidth>
              <TextField
                type="number"
                label="Carry Forward Leaves"
                fullWidth
                required
                name="carry_over_days"
                onChange={handleInput}
                autoFocus={true}
                value={inputData?.carry_over_days}
                size="small"
                {...(errors.carry_over_days && {
                  error: true,
                  helperText: errors.carry_over_days,
                })}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10px",
          }}
        >
          <Box>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              onClick={handleSubmit}
              className="mpl-primary-btn"
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
};
export default YearType;
