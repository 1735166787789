import * as ActionTypes from '../ActionTypes';

const InitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: "",
}

export const ReportingEmployeeReducer = function (state = InitialState, action) {
    switch (action.type) {
        case ActionTypes.REPORTING_EMPLOYEE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                reporting_employee_data: [],
            };
        case ActionTypes.REPORTING_EMPLOYEE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                reporting_employee_data: action.payload?.data?.data
            };
        case ActionTypes.REPORTING_EMPLOYEE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}