import { useSelector, useDispatch } from "react-redux";
import Popup from "../../../Base/views/Popup";
import { PowerUserDisabledAction } from "../../../../../store/RoleAccess/Permission/Action";
import { Box, Button, Typography } from "@mui/material";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { AddNotificationHelper } from "../../Notification/helper/NotificationHelper";

const PowerUserWarningPopup = () => {

    const dispatch = useDispatch();
    const userData = getUserData()
    const { diablePowerUserShow } = useSelector(state => state?.RoleAndAccessReducer?.PermissionReducer);

    const handlePopup = () => {
        dispatch(PowerUserDisabledAction())
    }

    const handleNotify = () => {
        let value = [{ user_id: userData.employee_id, comment: `${userData?.user_name} has requested for power user access.`, is_read: "N" }]
        AddNotificationHelper({ notification_data: JSON.stringify(value), notification_type: "Admin", toast: true, message: "Request has been sent to admin" });
        handlePopup();
    }

    return (
        <>
            <Popup
                fullWidth={true}
                maxWidth="sm"
                title="Restricted"
                openPopup={diablePowerUserShow}
                setOpenPopup={handlePopup}
            >
                <Typography variant="h6">You Don't have sufficient permission. Please contact Admin.</Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
                    <Button
                        className="mpl-primary-btn"
                        variant="contained"
                        onClick={handleNotify}
                    >
                        Notify Admin
                    </Button>
                    <Button
                        className="mpl-secondary-btn"
                        variant="outlined"
                        onClick={handlePopup}
                        sx={{ ml: 2 }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Popup>
        </>
    )
}
export default PowerUserWarningPopup;