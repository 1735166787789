import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetAtsCandidateGeneralHistoryHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/getCandidateGeneralHistory`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            candidate_id: params?.candidate_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }

        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAtsCandidateGeneralHistoryHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/addCandidateGeneralHistory`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params?.domain ? params?.domain : user?.domain,
            user_empid: params?.user_empid ? params?.user_empid : user?.ats_user_id,
            candidate_id: params?.candidate_id,
            form_sent_date: params?.form_sent_date || null,
            form_submitted_date: params?.form_submitted_date || null,
            sent_by: params?.user_empid ? params?.user_empid : user?.ats_user_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }

        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsCandidateJobHistoryHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/getCandidateJobHistory`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            candidate_job_id: params?.candidate_job_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsCandidateActivityHistoryHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/getCandidateActivityHistory`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            candidate_id: params?.candidate_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAtsCandidateJobHistoryHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/addCandidateJobHistory`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_empid: user?.ats_user_id,
            candidate_fk: params?.candidate_fk,
            candidate_job_fk: params?.candidate_job_fk,
            ats_jobpost_fk: params?.ats_jobpost_fk,
            ats_job_stages_fk: params?.ats_job_stages_fk,
            responder_name: params?.responder_name,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
