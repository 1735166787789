import React from "react";
import ThemeProvider from "../../theme";
import Header from "./Header";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";

export default function UnauthorizedBase({ children, imageUrl, companyName}) {
    return (
        <ThemeProvider>
            <Header imageUrl={imageUrl} companyName={companyName}/>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {children}
            <Footer />
        </ThemeProvider>
    );
}
