import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography, Grid } from "@mui/material";

export const OfferComponentsRender = ({
    setCheckData,
    setFormData,
    checkData,
    previewErrors,
    Previewvalidate,
    formFields,
    setFormComponents,
    formData
}) => {

    const handleCheckChange = (e, fieldName) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckData((prevCheckData) => ({
                values: [...prevCheckData.values, value],
            }));
        } else {
            setCheckData((prevCheckData) => ({
                values: prevCheckData.values.filter((val) => val !== value),
            }));
        }
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: checkData?.values,
        }));
    };

    const handlePreviewChange = (e, type) => {
        const fieldName = e.target?.name;
        let fieldValue;
        if (e.target?.type === 'file') {
            fieldValue = e.target?.files[0];
            setFormData((prevData) => ({
                ...prevData,
                [fieldName]: fieldValue,
            }));
        } else if (type === 'checkbox') {
            handleCheckChange(e, fieldName);
        } else {
            fieldValue = e.target?.value;
            setFormData((prevData) => ({
                ...prevData,
                [fieldName]: fieldValue,
            }));
        }
        Object.values?.(previewErrors)?.find(res => res !== "")?.length > 0 &&
            Previewvalidate(formFields, { ...formData, [fieldName]: fieldValue, });
    }

    const generatedComponents = formFields?.map((field, index) => {
        switch (field.type) {
            case "text":
                return (
                    <Grid container alignItems="center" key={field.name} spacing={2}>
                        <Grid item xs={4}>
                            <FormLabel sx={{ fontSize: "18px" }} required={field?.required}>
                                {`${index + 1}. ${field.label}`}
                            </FormLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                sx={{
                                    margin: "0px 0px 0px 0px",
                                    borderRadius: "4px",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "transparent",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#883bd5",
                                            borderBottomWidth: 2,
                                            borderBottomColor: "#883bd5",
                                            borderTopWidth: 0,
                                            borderLeftWidth: 0,
                                            borderRightWidth: 0,
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        marginLeft: "5px",
                                    }
                                }}
                                placeholder="Enter your text"
                                name={field.name}
                                fullWidth
                                multiline
                                variant="standard"
                                margin="normal"
                                size="small"
                                required={field?.required}
                                defaultValue={formData[field.name] || ""}
                                onChange={handlePreviewChange}
                                {...field && field?.required && previewErrors[field?.name] && {
                                    error: true,
                                    helperText: previewErrors[field?.name],
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            case "date":
                return (
                    <Grid container alignItems="center" key={field.name} spacing={2}>
                        <Grid item xs={4}>
                            <FormLabel sx={{ fontSize: "18px" }} required={field?.required}>
                                {`${index + 1}. ${field.label}`}
                            </FormLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                sx={{ margin: "0px 0px 0px 0px", borderRadius: "4px" }}
                                name={field.name}
                                size="small"
                                required={field?.required}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                type="date"
                                  placeholder="Enter your date"
                                defaultValue={formData[field.name] || ""}
                                onChange={handlePreviewChange}
                                {...field && field?.required && previewErrors[field?.name] && {
                                    error: true,
                                    helperText: previewErrors[field?.name],
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            case "radio":
                return (
                    <Grid container alignItems="center" key={field.name} spacing={2}>
                        <Grid item xs={4}>
                            <FormLabel sx={{ fontSize: "18px" }} required={field?.required}>
                                {`${index + 1}. ${field.label}`}
                            </FormLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl component="fieldset" margin="normal">
                                <RadioGroup>
                                    {field?.options &&
                                        field?.options.map((option, optionIndex) => (
                                            <FormControlLabel
                                                sx={{ margin: "0px 0px 0px 0px" }}
                                                key={option.value}
                                                value={option.value}
                                                control={<Radio />}
                                                label={option.label}
                                                name={field.name}
                                                onChange={handlePreviewChange}
                                            />
                                        ))}
                                </RadioGroup>
                                {previewErrors[field.name] && (
                                    <Typography variant="body2" color="error">
                                        {previewErrors[field.name]}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                );
            case "file":
                return (
                    <Grid container alignItems="center" key={field.name} spacing={2}>
                        <Grid item xs={4}>
                            <FormLabel sx={{ fontSize: "18px" }} required={field?.required}>
                                {`${index + 1}. ${field.label}`}
                            </FormLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                sx={{ margin: "0px 0px 0px 0px", borderRadius: "4px" }}
                                name={field.name}
                                size="small"
                                required={field?.required}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                type="file"
                                onChange={handlePreviewChange}
                                {...field && field?.required && previewErrors[field?.name] && {
                                    error: true,
                                    helperText: previewErrors[field?.name],
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    });

    setFormComponents(generatedComponents);
};
