import * as React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import BasicDetails from './Basic/BasicDetails';
import BankDetails from './BankDetails/BankDetails';
import FamilyPage from './Family/FamilyPage';
import ProfessionalSummaryPage from "./ProfessionalSummary/ProfessionalSummaryPage";
import Base from "../../Base/views/Base";
import { Divider, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepLabel from '@mui/material/StepLabel';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import WorkDetailsPage from './WorkDetails/WorkDetailsPage';
import "../css/style.css";
import PersonalPage from './Personal/PersonalPage';
import { useLocation } from "react-router-dom";


export default function Employee(props) {
    const location = useLocation();
    const EditData = location?.state?.defaultValue || "";
    const Mode =  location?.state?.Mode || undefined;
  const { setCurrentMode,currentMode } = props
  const { atsModuleData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleReducer);
  const roleAccess = atsModuleData?.find(
    (item) => item.module_name === "My Employee"
  );
    const disableEdit = roleAccess ? false : true;
    const [newdata, setNewData] = React.useState();
    const icons = {
        1: <PermContactCalendarIcon />,
        2: <FolderSharedIcon />,
        3: <WorkHistoryIcon />,
        4: <PrivacyTipIcon />,
        5: <GroupAddIcon />,
        6: <AccountBalanceIcon />,
    };
    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => (
        {
        color: theme.palette.mode === 'dark' ? theme.palette.secondary : '#8f8f94',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: theme?.palette?.primary?.main,
        }),
        '& .QontoStepIcon-completedIcon': {
            color: theme?.palette?.primary?.main,
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            fontWeight: "800"
        },
    }));
    
    function QontoStepIcon(props) {
        const { active, completed, className } = props;
    
        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    props?.icon ? icons[props?.icon] : <Check />
                )}
            </QontoStepIconRoot>
        );
    }
    
    QontoStepIcon.propTypes = {
        active: PropTypes.bool,
        className: PropTypes.string,
        completed: PropTypes.bool,
    };
    const theme = useTheme();

    const steps = [
            'Basic Details',
            'Work Details',
            'Professional Summary',
            'Identity Proof',
            'Nominee / Family',
            'Bank Account',
            
        ];

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <BasicDetails
                        setCurrentMode={setCurrentMode}
                        OnboardingEmpData={EditData}
                        currentMode={ Mode }
                        setStepper={setStepper}
                        setNewData={setNewData}
                        setActiveStep={setActiveStep}
                    />
                );
                case 1:
                return (
                    <WorkDetailsPage
                        setCurrentMode={setCurrentMode}
                        OnboardingEmpData={EditData}
                        currentMode={ Mode }
                        newdata={newdata}
                        setActiveStep={setActiveStep}
                    />
                );
                case 2:
                    return (
                        <ProfessionalSummaryPage
                            setCurrentMode={setCurrentMode}
                            OnboardingEmpData={EditData}
                            disableEdit={disableEdit}
                            newdata={newdata}
                            currentMode={ Mode }
                            setActiveStep={setActiveStep}
                        />
                    );
                case 3:
                    return (
                        <PersonalPage
                            setCurrentMode={setCurrentMode}
                            OnboardingEmpData={EditData}
                            disableEdit={disableEdit}
                            newdata={newdata}
                            currentMode={ Mode}
                            setActiveStep={setActiveStep}
                        />
                    );
                case 4:
                    return (
                        <FamilyPage
                            setCurrentMode={setCurrentMode}
                            OnboardingEmpData={EditData}
                            newdata={newdata}
                            currentMode={ Mode }
                            setActiveStep={setActiveStep}
                        />
                    )
                case 5:
                    return ( <BankDetails
                                    setCurrentMode={setCurrentMode}
                                    OnboardingEmpData={EditData}
                                    newdata={newdata}
                                    currentMode={ Mode }
                                    setActiveStep={setActiveStep}
                        />);
            default:
                return "Unknown stepIndex";
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const [stepper, setStepper] = React.useState(false);

    const handleStep = (step) => () => {
        if(Mode === "addemployee"){
            if(Mode === "addemployee" && stepper === true ){
                setActiveStep(step);
            }
        }
        else{
            setActiveStep(step);
        }
       
    };
    return (
        <Base>
            <Box sx={{ maxHeight: "87vh", overflowY: 'auto',overflowX:'hidden' }}>
            <Paper sx={{ ".MuiDrawer-paper": { backgroundColor: `${theme.palette.mode === "dark" ? "#544166" : "white"}` }, borderRadius: "10px", paddingTop: "5px", minHeight: "87vh" }}>
                <Box sx={{ width: '100%' }}>
                    <Stepper nonLinear activeStep={activeStep} sx={{ paddingTop: "15px", paddingRight: "0px", paddingLeft: "0px" }}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton color="inherit" onClick={handleStep(index)} sx={{ padding: "10px 16px" }}>
                                    <StepLabel sx={{ ".Mui-active": { fontWeight: "700!important" } }} StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <Divider variant="middle" flexItem sx={{ paddingTop: "10px" }} />
                    <div className="employee">{getStepContent(activeStep)}</div>
                </Box>
            </Paper>
            </Box>
        </Base>

    );
}