import React from "react";
import { Button, FormControl, Grid, TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Form } from "../../../Base/views/ModalPopUpForm";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import ErrorIcon from '@mui/icons-material/Error';
import { GetWeekDaysAction } from "../../../../../store/Ancillary/WeekDays/Action";
import { AddWeekOffConfigureAction, UpdateWeekOffConfigureAction } from "../../../../../store/Leave/WeekOff/Action";

const Alert = () => {
  const containerStyle = {
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 15px",
    color: "#000",
    position: "relative",
    width: "100%"
  };

  const infoIconStyle = {
    color: "#007bff",
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
  };

  const textStyle = {
    margin: 0,
    color: "#1b1c21"
  };

  return (
    <div style={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center" }}>
      <div style={containerStyle}>
        <span style={infoIconStyle}><ErrorIcon /></span>
        <p style={textStyle}>
          Kindly select the work-off days only
        </p>
      </div>
    </div>
  );
};
const WeekOffPopup = ({ editedRecord, setOpenPopup }) => {

  const dispatch = useDispatch();
  const [time, setTime] = useState({
    week_off: "",
    full_day_off: true,
    half_day_off: false
  });
  const [daysOptions, setDaysOptions] = useState({ load: true, values: [] });
  const [errors, setErrors] = useState({});

  const { week_days_options } = useSelector(
    (state) => state.AncillaryReducer.WeekDaysReducer
  );
  const { week_off_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);
  useEffect(() => {
    if (editedRecord != null) {
      setTime({
        ...editedRecord,
        week_off: { week_day_name: editedRecord.day_name, week_day_pk: editedRecord.week_off_days_id }
      });
    }
    dispatch(GetWeekDaysAction());
  }, [editedRecord]);
  useEffect(() => {
    if (week_days_options.length > 0 && week_off_data.length > 0) {
      const filteredDays = week_days_options.filter(
        (option) => !week_off_data.some((off) => off.day_name === option.week_day_name)
      );
      setDaysOptions({ load: false, values: filteredDays });
    } else if (week_days_options.length > 0) {
      setDaysOptions({ load: false, values: week_days_options });
    }
  }, [week_days_options, week_off_data]);

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("week_off" in fieldValues) {
      temp.week_off =
        fieldValues.week_off === "" ? "Days is required" : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (event, value) => {
    if (["week_off"].includes(event)) {
      if (value) {
        setTime({ ...time, [event]: value });
      } else {
        setTime({ ...time, [event]: "" });
      }
    } else {
      setTime({ ...time, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...time, [event.target.name]: event.target.value });
    }
  };
  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setTime({
      ...time,
      full_day_off: selectedValue === "full_day",
      half_day_off: selectedValue === "half_day",
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate) {
      if (time?.week_off_days_id) {
        dispatch(UpdateWeekOffConfigureAction({ ...time }));
      }
      else {
        dispatch(AddWeekOffConfigureAction({ ...time }));
      }
      setOpenPopup(false);
    }

  };



  return (
    <>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Grid container>
          <Grid container>
            <Grid
              key="days"
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <FormControl fullWidth>
                <SelectableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option.week_day_pk === +value.week_day_pk
                  }
                  label="Days"
                  value={time?.week_off}
                  loading={() =>
                    setDaysOptions({ load: false, values: week_days_options })
                  }
                  fieldLabel="week_day_name"
                  variant={"outlined"}
                  name="week_off"
                  size="small"
                  required
                  onChangeEvent={handleInputChange}
                  data={daysOptions}
                  {...(errors.week_off && {
                    error: true,
                    helperText: errors.week_off,
                  })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginTop: "1rem", paddingLeft: "15px" }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="day_off_type"
                  value={time.full_day_off ? "full_day" : time.half_day_off ? "half_day" : ""}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="full_day"
                    control={<Radio />}
                    label="Full Day"
                  />
                  <FormControlLabel
                    value="half_day"
                    control={<Radio />}
                    label="Half Day"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Alert />
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                marginTop: "1rem",
                marginRight: "1rem",
                ":hover": { color: grey[50] },
              }}
              variant="outlined"
              onClick={() => setOpenPopup(false)}
              className="mpl-secondary-btn"
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="mpl-primary-btn"
              sx={{ marginTop: "1rem", marginRight: "1rem" }}
            >
              {editedRecord ? "Update" : "Save"}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default WeekOffPopup;
