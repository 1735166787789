import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

// Upcoming Holiday
export const GetUpcomingHolidaysHelper = async (params) => {
    var data = getUserData();
    toast.dismiss();
    params?.show !== "no" && toast.info("Loading...")
    return await axiosInstance(`/ancillary/lms/getUpcomingHolidays`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            city_id: data.work_location_id
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Upcoming Holidays Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch Upcoming Holidays", hasError: false, data: err?.response?.data }
        })
}

// Holiday Calendar
export const GetHolidayCalendarHelper = async (params) => {
    var data = getUserData();
    params?.show !== "no" && toast.dismiss();
    params?.show !== "no" && toast.info("Loading...")
    return await axiosInstance(`/ancillary/lms/getHolidayCalendars`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
             city_id: data.work_location_id,
            year: params.year
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Holiday Calendar Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch Holiday Calendar", hasError: false, data: err?.response?.data }
        })
}

export const AddHolidayCalendarHelper = async (holidayCalendarDetails) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/lms/addHolidayCalendar`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            name: holidayCalendarDetails.name,
            year: holidayCalendarDetails.year,
            country_id: holidayCalendarDetails.country_id,
            region_id: holidayCalendarDetails.region_id,
            date: holidayCalendarDetails.date,
            description: holidayCalendarDetails.status
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Holiday Calendar Added Successfully");
            return { message: "Holiday Calendar Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to add Holiday Calendar", hasError: false, data: err?.response?.data }
        })
}
export const UpdateHolidayCalendarHelper = async (holidayCalendarDetails) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/lms/updateHolidayCalendar`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            holiday_calendar_id: holidayCalendarDetails.holiday_calendar_id,
            name: holidayCalendarDetails.name,
            year: holidayCalendarDetails.year,
            country_id: holidayCalendarDetails.country_id,
            region_id: holidayCalendarDetails.region_id,
            date: holidayCalendarDetails.date,
            description: holidayCalendarDetails.status
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Holiday Calendar Updated Successfully");
            return { message: "Holiday Calendar Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })
}

export const DeleteHolidayCalendarHelper = async (holidayCalendarDetails) => {
    var data = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/lms/deleteHolidayCalendar`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            holiday_calendar_ids: holidayCalendarDetails,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Holiday Calendar Deleted Successfully");
            return { message: "Holiday Calendar Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}
