import { toast } from "react-toastify";
import React from "react";
import LogIn from "../../images/open-enrollment.gif";
import { Grid } from "@mui/material"
import ErrorIcon from '@mui/icons-material/Error';
import { CLIENT_ID } from "../../../../Constants/baseConstant";
import { GoogleLogout } from "react-google-login";

const Alert = () => {
    const containerStyle = {
        backgroundColor: "#e0f7ff",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 15px",
        color: "#000",
        position: "relative",
        borderBottom: "1px solid #007bff"
    };

    const infoIconStyle = {
        color: "#007bff",
        marginRight: "10px",
        fontSize: "18px",
    };

    const textStyle = {
        margin: 0,
        flex: 1,
        color: "#1b1c21"
    };
    

    return (
        <div style={containerStyle}>
            <span style={infoIconStyle}><ErrorIcon /></span>
            <p style={textStyle}>
            To use the Google Calendar feature successfully you will need to grant the necessary "Edit Calendar" permissions. Once permissions are given, you'll be able to create and manage events directly within Meepl Calendar. Please note that any events created or modified directly in Google Calendar will not be synchronized with Meepl Calendar. 
            </p>
        </div>
    );
};
const AccessWarning = (props) => {
    const { setOpenPopup, setAccessGranted } = props;
    const onLogoutSuccess = () => {
        localStorage.removeItem('accessTokenforGoogle');
        localStorage.removeItem('GoogleUserInfo');
        setAccessGranted(false);
        toast.success("Logout success");
        setOpenPopup(false);
    };
    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center" flexDirection="column">
                <Grid item>
                    <img
                        src={LogIn}
                        alt="Loading..."
                        width={"100px"}
                        height={"100px"}
                        style={{
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                            padding: "10px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                    />
                </Grid>
                <Grid item>
                    <Alert />
                </Grid>
                <Grid item>
                    <GoogleLogout
                        clientId={CLIENT_ID}
                        buttonText="Logout"
                        onLogoutSuccess={onLogoutSuccess}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AccessWarning;
