import { useState } from 'react';
import MaterialTable from 'material-table';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import { DeleteAtsCompanyAction } from '../../../../../store/ATS/Company/Action';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from 'react-redux'
import CustomToggle from '../../../Base/views/CustomToggle';
import { toast } from 'react-toastify';


const AtsCompany = ({ setCurrentMode, setCurrentData, currentData }) => {

    const theme = useTheme()
    const [openDialog, setOpenDialog] = useState(false);
    const dispatch = useDispatch()
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
    const { atsCompanyData, isLoading,success } = useSelector(state => state.AtsReducer?.AtsCompanyReducer);
    const [isActive, setIsActive] = useState(true);
    const handleDisabled = (permission) => {
        const foundPermission = featuresData?.find((item) => item.permission === permission);
        return foundPermission ? true : false;
    };
    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleInactive = () => {
        dispatch(DeleteAtsCompanyAction({ company_id: `{${currentData?.ats_company_id}}`,status:"Inactive" }))
        if(success === true){
            toast.dismiss();
            toast.success("Client Deleted successfully")
        }
        setOpenDialog(false);
    };
    const handleActive = () => {
        dispatch(DeleteAtsCompanyAction({ company_id: `{${currentData?.ats_company_id}}`,status:"Active" }))
        if(success === true){
            toast.dismiss();
            toast.success("Client made active successfully")
        }
        setOpenDialog(false);
    };

    const hanldeEdit = (event, selectedRow) => {
        setCurrentData(selectedRow);
        setCurrentMode("edit");
    }

    const handleAdd = () => {
        setCurrentData(null);
        setCurrentMode("add");
    };
    const getActiveCompany = () => {
        let data = atsCompanyData.filter(function (company) {
            return company.status === 'Active'
        })
        return data
    }
    const getInactiveCompany = () => {
        let data = atsCompanyData.filter(function (company) {
            return company.status === 'Inactive'
        })
        return data
    }
    return (
        <>
            <MaterialTable
                onRowClick={(event, rowData) => hanldeEdit(event, rowData)}
                isLoading={isLoading}
                columns={[
                    { title: "Org Name", cellStyle: { padding: "10px 10px 10px 13px" }, field: "name", grouping: false },
                    { title: "Address", cellStyle: { padding: "10px 10px 10px 13px" }, field: "address", grouping: false },
                    { title: "Contact Person", cellStyle: { padding: "10px 10px 10px 13px" }, field: "contact_person", grouping: false },
                    { title: "Mobile", cellStyle: { padding: "10px 10px 10px 13px" }, field: "mobile_number", grouping: false },
                    { title: "Email", cellStyle: { padding: "10px 10px 10px 13px" }, field: "email_id", grouping: false },
                ]}
                data={isActive ? (atsCompanyData ? getActiveCompany() : []) : (atsCompanyData ? getInactiveCompany() : [])}
                options={{
                    maxBodyHeight: "69vh",
                    minBodyHeight: "69vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: {
                        backgroundColor: theme?.palette?.mode === "light" ? theme.palette.grey[0] : theme.palette.grey[500],
                        color: theme?.palette?.mode === "light" ? theme.palette.primary.contrastText : theme.palette.grey[800],
                        fontWeight: "bold",
                        padding: "10px 10px 10px 13px"
                    },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: true,
                    exportFileName: "Client",
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: true,
                }}
                actions={[
                    {
                        icon: () => <Button disabled={handleDisabled("client_add_client")} component="span" variant="contained" startIcon={<AddIcon />} className="mpl-primary-btn">
                            Add
                        </Button>,
                        isFreeAction: true,
                        position: "row",
                        tooltip: handleDisabled("client_add_client") ? "You Don't have sufficient permission. Please contact Admin." : "Add",
                        disabled: handleDisabled("client_add_client"),
                        onClick: (_event, rowData) => handleAdd(),
                    },
                    {
                        icon: () => <ArchiveIcon fontSize='small' />,
                        tooltip: "Archive",
                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                    },
                    {
                        icon: () => (
                            <CustomToggle
                                isChecked={
                                    !isActive
                                }
                                setIsChecked={isActive}
                                handleToggleChange={(e) => setIsActive(!isActive)}
                                Yestext={'Inactive'}
                                NoText={'Active'}
                                style={{ height: '32px', padding: '0px', width: '145px', labelPadding: '1px' }}
                            />
                        ),
                        position: "row",
                        isFreeAction: true,
                        tooltip: "Toggle to view archived Client",
                    },
                ]}
                title="Client"
            />
            <ConfirmationDialog
                title={!isActive ? "Make Active" : "Make Inactive"}
                content={isActive ? "All jobs and users associated with client will be made inactive.Are you sure want to make the client inactive?" : "All jobs and users associated with client will be made active. Are you sure want to activate this client?"}
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Client"
                OkButtonText={!isActive ? "Make Active" : "Make Inactive"}
                onSubmit={isActive ? handleInactive : handleActive}
            />
        </>
    );
}

export default AtsCompany;
