import MaterialTable from 'material-table'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteAtsConsultancyAction } from '../../../../../store/ATS/Consultancy/Action';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
import { useTheme } from "@mui/material/styles";
import CustomToggle from '../../../Base/views/CustomToggle';
import { GetAtsAllowedUsersHelper } from '../../helper/AtsUserHelper';
import { toast } from 'react-toastify';


const AtsConsultancyGrid = ({ setCurrentMode, currentData, setCurrentData }) => {

    const theme = useTheme()
    const dispatch = useDispatch()
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
    const { atsConsultancyData, isLoading,success } = useSelector(state => state.AtsReducer?.AtsConsultancyReducer);
    const [recruiter,setRecruiter] = useState({})
    const [openDialog, setOpenDialog] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const handleDisabled = (permission) => {
        const foundPermission = featuresData?.find((item) => item.permission === permission);
        return foundPermission ? true : false;
    };

    useEffect( () => {
        GetAtsAllowedUsersHelper({
            company_id:  "Internal",
            consultancy_id: "",
            recruiter_id:  null
        }).then(res => {
            setRecruiter(res?.data?.data?.[0])
        });
    },[])

    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const getActiveConsul = () => {
        let data = atsConsultancyData.filter(function (consul) {
            return consul.status === 'Active'
        })
        return data
    }
    const getInactiveConsul = () => {
        let data = atsConsultancyData.filter(function (consul) {
            return consul.status === 'Inactive'
        })
        return data
    }
    const handleInactive = () => {
        dispatch(DeleteAtsConsultancyAction({ consultancy_id: `{${currentData?.consultancy_id}}`,int_user: recruiter?.recruiter_id,status:"Inactive" }))
        if(success===true){
            toast.dismiss();
            toast.success("Hiring Partner Deleted successfully")
        }
        setOpenDialog(false);
    };
    const handleActive = () => {
        dispatch(DeleteAtsConsultancyAction({ consultancy_id: `{${currentData?.consultancy_id}}`,int_user:0,status:"Active" }))
        if(success===true){
            toast.dismiss();
            toast.success("Hiring Partner made active successfully")
        }
        setOpenDialog(false);
    };
    const hanldeEdit = (event, selectedRow) => {
        setCurrentData(selectedRow);
        setCurrentMode("edit");
    }
    const handleAdd = () => {
        setCurrentData(null);
        setCurrentMode("add");
    };
    return (
        <>
            <MaterialTable
                onRowClick={(event, rowData) => hanldeEdit(event, rowData)}
                isLoading={isLoading}
                columns={[
                    { title: "Org Name", cellStyle: { padding: "10px 10px 10px 13px" }, field: "name", grouping: false },
                    { title: "Address", cellStyle: { padding: "10px 10px 10px 13px" }, field: "address", grouping: false },
                    { title: "Contact Person", cellStyle: { padding: "10px 10px 10px 13px" }, field: "contact_person", grouping: false },
                    { title: "Mobile", cellStyle: { padding: "10px 10px 10px 13px" }, field: "mobile_number", grouping: false },
                    { title: "Email", cellStyle: { padding: "10px 10px 10px 13px" }, field: "email_id", grouping: false },
                ]}
                data={isActive ? (atsConsultancyData ? getActiveConsul() : []) : (atsConsultancyData ? getInactiveConsul() : [])}
                options={{
                    maxBodyHeight: "69vh",
                    minBodyHeight: "69vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: {
                        backgroundColor: theme?.palette?.mode === "light" ? theme.palette.grey[0] : theme.palette.grey[500],
                        color: theme?.palette?.mode === "light" ? theme.palette.primary.contrastText : theme.palette.grey[800],
                        fontWeight: "bold",
                        padding: "10px 10px 10px 13px"
                    },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: true,
                    exportFileName: "Hiring Partner",
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: true,
                }}
                actions={[
                    {
                        icon: () => <Button component="span" disabled={handleDisabled("hiring_partner_add_client")} variant="contained" startIcon={<AddIcon />} className="mpl-primary-btn">
                            Add
                        </Button>,
                        isFreeAction: true,
                        position: "row",
                        tooltip: handleDisabled("hiring_partner_add_client") ? "You Don't have sufficient permission. Please contact Admin." : "Add",
                        disabled: handleDisabled("hiring_partner_add_client"),
                        onClick: (_event, rowData) => handleAdd(),
                    },
                    {
                        icon: () => <ArchiveIcon fontSize='small' />,
                        tooltip: "Archive",
                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                    },
                    {
                        icon: () => (
                            <CustomToggle
                                isChecked={
                                    !isActive
                                }
                                setIsChecked={isActive}
                                handleToggleChange={(e) => setIsActive(!isActive)}
                                Yestext={'Inactive'}
                                NoText={'Active'}
                                style={{ height: '32px', padding: '0px', width: '145px', labelPadding: '1px' }}
                            />
                        ),
                        position: "row",
                        isFreeAction: true,
                        tooltip: "Toggle to view archived Hiring Partner",
                    },
                ]}
                title="Hiring Partner"
            />
            <ConfirmationDialog
               title={!isActive ? "Make Active" : "Make Inactive"}
               content={isActive ? `All users will be made incative and jobs associated with this Hiring Partner will replcaed by internal recruiter ${recruiter?.recruiter}.Do you sure want to proceed?` : "All users associated with Hiring Partner will be made active. Do you want to proceed?"}
               openDialog={openDialog}
               closeDialog={setOpenDialog}
               popupTitle="Hiring Partner"
               OkButtonText={!isActive ? "Make Active" : "Make Inactive"}
               onSubmit={isActive ? handleInactive : handleActive}
            />
        </>
    )
}

export default AtsConsultancyGrid

