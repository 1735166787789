import { Box, Button, Grid, Tab, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { GetModule } from "../../../../../store/RoleAccess/Module/Action";
import { AddRoleAccessAction, UpdateRoleAccessAction } from "../../../../../store/RoleAccess/RoleAccess/Action";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Permissions from './Permissions';
import { handleGroupby } from '../../../Base/helper/baseFunctions';
import { GetSubModule } from "../../../../../store/RoleAccess/SubModule/Action";
import { GetRoleAction } from '../../../../../store/RoleAccess/Role/Action';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    titleBox: {
        display: "flex", align: "center", color: "gray", fontSize: "13px"
    },
    title: {
        marginTop: "-5px", fontSize: "20px", color: "cornflowerblue"
    }
}));

const RoleAccessForm = (props) => {
    const { mode, setMode, role_access_data, editedRecord } = props
    const classes = useStyles();

    const dispatch = useDispatch()
    const { module_data } = useSelector(state => state?.RoleAndAccessReducer?.ModuleReducer);
    const { sub_module_data } = useSelector(state => state?.RoleAndAccessReducer?.SubModuleReducer);

    const [subModuleData, setSubModuleData] = useState();
    const [inputData, setInputData] = useState({ rolename: "" });
    const [errors, setErrors] = useState({});
    const [value, setValue] = useState(module_data?.[0]?.module_id || +1);
    const [iconChecked, setIconChecked] = useState();
    const [postData, setPostData] = useState([]);

    useEffect(() => {
        dispatch(GetModule());
        dispatch(GetSubModule())
        if (editedRecord) {
            let role = role_access_data?.filter(res => res?.role_id === editedRecord?.role_id);
            setInputData({ ...inputData, roleData: role, role_id: editedRecord?.role_id, rolename: editedRecord?.name })
            handleFilterData(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        handleFilterData(newValue)
    };

    const handleFilterData = (newValue) => {
        let sub_mod1 = inputData?.roleData?.length > 0 && inputData?.roleData?.[0]?.role?.filter(res => +res?.module_id === +newValue);
        let sub_mod2 = sub_module_data?.filter(res => +res?.module_id === +newValue);
        var finalData = sub_mod1?.length > 0 ? sub_mod1?.concat(sub_mod2?.filter(({ module_id, submodule_id }) => !sub_mod1?.find(f => +f.module_id === +module_id && +f.submodule_id === +submodule_id))) : sub_mod2;
        setSubModuleData(finalData);
        setIconChecked(handleGroupby(finalData, 'module_id'));
    }

    const handleInputChange = (event, values) => {
        let role = role_access_data?.filter(res => res?.role_id === event.target.value);
        setInputData({ ...inputData, [event.target.name]: event.target.value, roleData: role });
        handleFilterData(module_data?.[0]?.module_id);
        setValue(module_data?.[0]?.module_id);
    }

    const validate = (fieldValues = inputData) => {
        let temp = { ...errors };
        if ("rolename" in fieldValues) {
            temp.rolename = fieldValues.rolename === "" ? "Role is required" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleSubmit = () => {
        if (validate()) {
            const create = []
            const update = []
            postData.forEach(item => item.mapping_id ? update.push(item) : create.push(item));
            if (mode === "add") {
                dispatch(AddRoleAccessAction({
                    role_id: inputData?.rolename,
                    permission_id: `${JSON.stringify(postData)}`
                }));
                setMode("list");
                dispatch(GetRoleAction());
            }
            if (mode === "edit") {
                dispatch(UpdateRoleAccessAction({
                    role_id: inputData?.role_id,
                    permission_id: create?.length > 0 ? `${JSON.stringify(create)}` : null,
                    update: update?.length > 0 ? `${JSON.stringify(update)}` : null
                }));
                setMode("list");
                dispatch(GetRoleAction());
            }
        }
    }

    const handleIconChange = (e, value) => {
        let checked = iconChecked;
        let selected = checked?.[value?.module_id]?.filter(res => +res?.submodule_id === +value?.submodule_id);
        let temp = (iconChecked?.[value?.module_id]?.filter(res => res?.submodule_id === value?.submodule_id)?.[0]?.permission) === undefined ||
            (iconChecked?.[value?.module_id]?.filter(res => res?.submodule_id === value?.submodule_id)?.[0]?.permission) === null ?
            "XXXX" : iconChecked?.[value?.module_id]?.filter(res => res?.submodule_id === value?.submodule_id)?.[0]?.permission;
        if (e.target.name === "create") {
            if (selected?.[0]?.permission?.[0] === "C") selected[0].permission = `X${temp?.[1]}${temp?.[2]}${temp?.[3]}`
            else selected[0].permission = `C${temp?.[1]}${temp?.[2]}${temp?.[3]}`
        } else if (e.target.name === "read") {
            if (selected?.[0]?.permission?.[1] === "R") selected[0].permission = `${temp?.[0]}X${temp?.[2]}${temp?.[3]}`
            else selected[0].permission = `${temp?.[0]}R${temp?.[2]}${temp?.[3]}`
        } else if (e.target.name === "update") {
            if (selected?.[0]?.permission?.[2] === "U") selected[0].permission = `${temp?.[0]}${temp?.[1]}X${temp?.[3]}`
            else selected[0].permission = `${temp?.[0]}${temp?.[1]}U${temp?.[3]}`
        } else if (e.target.name === "delete") {
            if (selected?.[0]?.permission?.[3] === "D") selected[0].permission = `${temp?.[0]}${temp?.[1]}${temp?.[2]}X`
            else selected[0].permission = `${temp?.[0]}${temp?.[1]}${temp?.[2]}D`
        }

        var data = mode === "add" ? {
            "module_id": value?.module_id, "submodule_id": value?.submodule_id,
            "one": value?.permission?.[0], "two": (["X"].includes(value?.permission?.[0]) && ["X"].includes(value?.permission?.[2]) && ["X"].includes(value?.permission?.[3])) ? value?.permission[1] : "R",
            "three": value?.permission?.[2], "four": value?.permission?.[3],
        } : value?.mapping_id ? {
            "mapping_id": value?.mapping_id,
            "one": value?.permission?.[0], "two": (["X"].includes(value?.permission?.[0]) && ["X"].includes(value?.permission?.[2]) && ["X"].includes(value?.permission?.[3])) ? value?.permission[1] : "R",
            "three": value?.permission?.[2], "four": value?.permission?.[3],
        } : {
            "module_id": value?.module_id, "submodule_id": value?.submodule_id,
            "one": value?.permission?.[0], "two": (["X"].includes(value?.permission?.[0]) && ["X"].includes(value?.permission?.[2]) && ["X"].includes(value?.permission?.[3])) ? value?.permission[1] : "R",
            "three": value?.permission?.[2], "four": value?.permission?.[3],
        }


        if (value?.mapping_id) {
            if (postData.filter(item => item?.mapping_id === value?.mapping_id).length > 0) {
                let index1 = postData.findIndex(item => item?.mapping_id === value?.mapping_id);
                postData[index1] = data;
            } else {
                setPostData(
                    (prevSelected) => [...prevSelected, data],
                )
            }
        } else {
            if ((postData.filter(item => item?.module_id === value?.module_id && item?.submodule_id === value?.submodule_id).length > 0)) {
                let index = postData.findIndex(item => item?.module_id === value?.module_id && item?.submodule_id === value?.submodule_id);
                postData[index] = data;
            } else {
                setPostData(
                    (prevSelected) => [...prevSelected, data],
                )
            }
        }

        setIconChecked({ ...iconChecked, [value?.module_id]: iconChecked?.[value?.module_id] });
    }

    return (
        <Box sx={{ margin: "20px", mt: 1.5 }}>
            <Typography variant="h6" gutterBottom>
                {mode === "edit" ? "Edit" : "Add"} Role Access
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    {
                        editedRecord?.role_id ?
                            <Box className={classes?.titleBox}>
                                Role:<div className={classes?.title}>&nbsp;&nbsp;{inputData?.rolename} </div>
                            </Box>
                            :
                            <TextField
                                label="Role"
                                name="rolename"
                                fullWidth
                                onChange={handleInputChange}
                                autoFocus={true}
                                disabled={editedRecord?.role_id}
                                value={inputData?.rolename || ""}
                                size="large"
                                {...(errors.rolename && {
                                    error: true,
                                    helperText: errors.rolename,
                                })}
                            />
                    }
                </Grid>
            </Grid>
            {
                inputData?.rolename !== "" ?
                    <TabContext value={value}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                maxWidth: { xs: 620, sm: 680, md: 1200 },
                                mt: 1
                            }}
                        >
                            <TabList variant='scrollable' scrollButtons
                                allowScrollButtonsMobile
                                sx={{
                                    minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                    '& .MuiTabs-indicator': {
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: "center !important",
                                    },
                                }} onChange={handleChange} aria-label="lab API tabs example">
                                {
                                    module_data?.map((res, id) => {
                                        return (
                                            <Tab key={id} iconPosition="start" label={res?.name} value={res?.module_id} />
                                        )
                                    })
                                }
                            </TabList>
                        </Box>
                        <TabPanel value={value} sx={{ paddingTop: "15px", pl: 0, pb: 0, minHeight: "45px" }}>
                            <Permissions
                                subModules={subModuleData}
                                iconChecked={iconChecked}
                                handleChange={handleIconChange}
                                value={value}
                            />
                        </TabPanel>
                    </TabContext>
                    : <Typography sx={{ textAlign: "center", mt: 3 }}>Please Enter Role</Typography>
            }

            <Box paddingTop="40px" sx={{ display: "absolute", bottom: 50, right: 50, position: "fixed", justifyContent: "flex-end" }}>
                <Button variant='outlined' onClick={() => { setMode("list") }} sx={{ marginRight: "10px " }}>Back</Button>
                <Button variant='contained' className='meepl' onClick={handleSubmit}>Save</Button>
            </Box>
        </Box>
    )
}

export default RoleAccessForm;