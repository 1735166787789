import * as ActionTypes from '../ActionTypes';

export const GetDocumentationsAction = () => {
    return {
        type: ActionTypes.DOCUMENTATIONS_GET_REQUEST
    }
}

export const AddDocumentationsAction = (params) => {
    return {
        type: ActionTypes.DOCUMENTATIONS_ADD_REQUEST,
        params
    }
}

export const UpdateDocumentationsAction = (params) => {
    return {
        type: ActionTypes.DOCUMENTATIONS_UPDATE_REQUEST,
        params
    }
}

export const DeleteDocumentationsAction = (params) => {
    return {
        type: ActionTypes.DOCUMENTATIONS_DELETE_REQUEST,
        params
    }
}