
import { toast } from 'react-toastify';
import axiosInstance from '../../../../../axiosInstance';
import { getUserData } from '../../../Base/helper/baseFunctions';
import { HRMS_LMS_API_URL } from '../../../../Constants/baseConstant';

export const GetMyTimeSheetApprovalHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/GetTimeSheetApproval`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            status:params?.status,
            emp_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const ApproveTimeSheetHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/ApproveTimeSheet`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.emp_users_pk,
            flag:params?.flag,
            week_no:params?.week_no,
            weekly_working_days:params?.weekly_working_days,
            timesheet_id:params?.timesheet_id,
            comments:params?.comments,
            employee_id:params?.employee_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(`TimeSheet ${params?.flag} successfully`);
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const ApproveTimeOffHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/ApproveTimeOff`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.emp_users_pk,
            flag:params?.flag,
            comments:params?.comments,
            leaverequest_id:params?.leaverequest_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(`Leave ${params?.flag} successfully`);
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


