import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

// Leave History
export const GetLeaveHistoryHelper = async (leaveHistoryDetails) => {
    var data = getUserData();
    toast.dismiss();
    toast.info("Loading...")
    return await axiosInstance(`/lms/getLeaveHistory`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            leave_request_id: leaveHistoryDetails.leaverequest_id
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Leave History Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch Leave History", hasError: false, data: err?.response?.data }
        })
}
