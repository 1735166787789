import { Box, Button, IconButton, Tooltip } from '@mui/material';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Popup from "../../../Base/views/Popup"
import MyTimePopup from './MyTimePopup';
import { useState } from 'react';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
import "../../css/styles.css"
import { DeleteMyTimeConfigureAction } from '../../../../../store/Leave/MyTimeConfigure/Action';

const MyTime = () => {

    const dispatch = useDispatch();
    const { mytime_options } = useSelector(state => state.LeaveReducer?.MyTimeConfigureReducer);
    const [editedRecord, setEditedRecord] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});

    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };

    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };

    const handleAdd = () => {
        setEditedRecord()
        setOpenPopup(true);
    };

    const handleDelete = () => {
        setOpenDialog(false);
        dispatch(DeleteMyTimeConfigureAction({ mytime_id:  `{${currentData?.mytime_pk}}` }))
    };

    const addMode = (resetForm) => {
        resetForm();
        setEditedRecord(null);
        setOpenPopup(false);
    };

    const addButton = <Button onClick={handleAdd} variant="contained" className="mpl-primary-btn" startIcon={<AddIcon />} >
        Add
    </Button>

    return (
        <Box>
            <MaterialTable
                columns={[
                    { title: "Project Code", field: "project_code", cellStyle: { padding: "10px 10px 10px 10px" } },
                    { title: ( <div>
                        Weekly Working Hours
                        <Tooltip title="This will reflect in your Timecard">
                            <IconButton sx={{ padding: "0px 0px 0px 5px" }}><ErrorOutlineIcon /></IconButton>
                        </Tooltip>
                    </div>), field: "weekly_hours", cellStyle: { padding: "10px 10px 10px 70px", } }

                ]}
                data={mytime_options}
                options={{
                    maxBodyHeight: 385,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 10px 10px 10px", fontWeight: "bold" },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: true,
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: true,
                }}
                onSelectionChange={(rows) => { return (rows.filter(row => row?.status === "Submitted")) }}
                actions={[
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                        tooltip: "Edit",
                        onClick: (_event, rowData) => { handleEdit(rowData) },
                    },
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                        tooltip: "Delete",
                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                    },
                ]}
                title={addButton}
            />
            <Popup
                title={editedRecord ? "Edit" : "Add"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <MyTimePopup editedRecord={editedRecord} addMode={addMode} setOpenPopup={setOpenPopup} />
            </Popup>
            <ConfirmationDialog
                title="Do you wish to proceed?"
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Country Management"
                OkButtonText="Delete"
                onSubmit={handleDelete}
            />
        </Box>
    );
}

export default MyTime;
