import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "../../../Base/views/ModalPopUpForm"
import SelectableSearch from '../../../Base/views/SelectableSearch';
import { useDispatch } from "react-redux";
import { AddEmployeeEducationAction, UpdateEmployeeEducationAction } from "../../../../../store/Employee/ProfessionalSummary/Action";
import { grey } from "@mui/material/colors";
import { GetStudyProgramHelper } from "../../../Ancillary/StudyProgram/helper/StudyProgramHelper";
import { GetStudyLevelHelper } from "../../../Ancillary/StudyLevel/helper/StudyLevelHelper";
import FileUploader from "../../../ATS/views/ATSCandidate/FileUploader";
import { APP_NAME } from "../../../../Constants/baseConstant";
import { convertBase64, getUserData, s3Client, } from "../../../Base/helper/baseFunctions";
import { v4 as uuidv4 } from "uuid";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { toast } from "react-toastify";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export default function EducationModalForm(props) {
    const dispatch = useDispatch()
    const [studyLevelData, setStudyLevelData] = useState({ load: true, values: [] });
    const [studyProgramData, setStudyProgramData] = useState({ load: true, values: [] });
    const [years, setYears] = useState({ load: true, values: [] });
    const [files, setFiles] = useState([]);
    const [fileUrl, setFileUrl] = useState("");
    const [errors, setErrors] = useState({});
    const { addMode, editedRecord, setOpenPopup, educationModalData, setEducationModalData, initialFValues } = props;
    const userData = getUserData();
    useEffect(() => {
        getYears();
        educationModalData?.proof && getResumeSignedUrl();
        //eslint-disable-next-line
    }, [educationModalData]);

    const getResumeSignedUrl = async () => {
        let filename = educationModalData?.proof;
        const bucketParams = {
          Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
          Key: filename,
        };
        try {
          const url = await getSignedUrl(
            s3Client,
            new GetObjectCommand(bucketParams),
            { expiresIn: 3 * 60 }
          ); // Adjustable expiration.
          setFileUrl(url);
          return url;
        } catch (err) {
          toast.error(err);
        }
      };
      const handleDownloadResume = async (event, data) => {
        event.stopPropagation();
        let filename = data?.proof;
        const bucketParams = {
          Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
          Key: filename,
        };
        try {
          const url = await getSignedUrl(
            s3Client,
            new GetObjectCommand(bucketParams),
            { expiresIn: 3 * 60 }
          ); // Adjustable expiration.
          window.open(url, "_blank");
          return url;
        } catch (err) {
          toast.error(err);
        }
      };
    const getYears = () => {
        let year = []
        const cur_year = new Date().getFullYear() - 50;
        for (var i = 0; i <= 50; i++) {
            year.push({ id: i, "year": `${cur_year + i}` })
        }
        setYears({ load: false, values: year });
    }

    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("studylevel_id" in fieldValues) {
            temp.studylevel_id = fieldValues.studylevel_id === "" ? "Study Level is required" : "";
        }
        if ("studyprogram_id" in fieldValues) {
            temp.studyprogram_id = fieldValues.studyprogram_id === "" ? "Study Program is required" : "";
        }
        if ("mode" in fieldValues) {
            temp.mode = fieldValues.mode === "" ? "Mode is required" : "";
        }
        if ("institution" in fieldValues) {
            temp.institution = fieldValues.institution === "" ? "Institution is required" : "";
        }
        if ("passingyear" in fieldValues) {
            temp.passingyear = fieldValues.passingyear === "" ? "Year of Passing is required" : "";
        }
        if (!files?.length && !fileUrl) {
            temp.proof = "Proof upload is required";
        } else {
            temp.proof = "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };
    const fileUploaded = ()=>{
        let temp = { ...errors };
        temp.proof = "";
        setErrors({
            ...temp,
        });
    }

    const handleInputChange = (event, value) => {
        if (["studylevel_id"].includes(event)) {
            setStudyProgramData({ load: true, values: [] })
            if (value) {
                setEducationModalData({ ...educationModalData, studyprogram_id: "", [event]: value })
            } else {
                setEducationModalData({ ...educationModalData, [event]: "", studyprogram_id: "" })
            }
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...educationModalData, [event]: value ? value : "" })
        } else if (["studyprogram_id", "passingyear"].includes(event)) {
            if (value) {
                setEducationModalData({ ...educationModalData, [event]: value })
            } else {
                setEducationModalData({ ...educationModalData, [event]: "" })
            }
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...educationModalData, [event]: value ? value : "" })
        }
        else if (event?.target?.name === "any_arrears") {
            setEducationModalData({ ...educationModalData, [event.target.name]: event.target.checked })
        }
        else {
            setEducationModalData({ ...educationModalData, [event.target.name]: event.target.value });
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...educationModalData, [event.target.name]: event.target.value })
        }
    }

    const handleStudyLevelChange = async () => {
        GetStudyLevelHelper().then(res => {
            setStudyLevelData({ load: false, values: res?.data?.data })
        })
    }

    const handleStudyProgramChange = async () => {
        GetStudyProgramHelper({ studylevel_id: educationModalData?.studylevel_id?.studylevel_id })
            .then(res => {
                setStudyProgramData({ load: false, values: res?.data?.data })
            })
    }

    const HandleSubmit = async (e) => {
        e.preventDefault();
        if (validate(educationModalData)) {
            addMode(resetForm);
            let b64string = "";
            let filename = "";
            let old_proof = "";
            let content_type = "";
            if (files?.[0]) {
                b64string = await convertBase64(files?.[0]);
                let ext = files?.[0]?.name?.split(".").at(-1);
                content_type = ext === "pdf" ? "application/pdf" : "application/msword"
                filename = `${userData?.domain}/Employee/IdentityProof/` + uuidv4() + "." + ext;
                if (educationModalData?.proof) {
                    old_proof = educationModalData?.proof;
                }
              } else {
                filename = educationModalData?.proof;
              }
            if (editedRecord) {
                dispatch(UpdateEmployeeEducationAction({
                    ...educationModalData,
                    proof: filename,
                    proof_name: b64string,
                    old_proof: old_proof,
                    content_type: content_type,
                }))
            } else {
                
                dispatch(AddEmployeeEducationAction(
                    {
                    ...educationModalData,
                    proof: filename,
                    proof_name: b64string,
                    content_type: content_type,
                }))
            }
            setOpenPopup(false)
        }

    };

    const resetForm = () => {
        setEducationModalData(initialFValues);
        setErrors({});
    };

    const handleYear = () => { }

    return (
        <Form onSubmit={HandleSubmit}>
            <Grid container>
                <Grid container>
                    <Grid key="studylevel_id" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <SelectableSearch
                                label="Study Level"
                                value={educationModalData?.studylevel_id}
                                loading={() => handleStudyLevelChange()}
                                fieldLabel="name"
                                // autoFocus={true}
                                required
                                variant={"outlined"}
                                name="studylevel_id"
                                size="small"
                                onChangeEvent={handleInputChange}
                                data={studyLevelData}
                                {...(errors.studylevel_id && {
                                    error: true,
                                    helperText: errors.studylevel_id,
                                })}
                            />
                        </FormControl>
                    </Grid>

                    <Grid key="studyprogram_id" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <SelectableSearch
                                label="Study Program"
                                value={educationModalData?.studyprogram_id}
                                loading={() => handleStudyProgramChange()}
                                fieldLabel="name"
                                required
                                variant={"outlined"}
                                name="studyprogram_id"
                                size="small"
                                onChangeEvent={handleInputChange}
                                data={studyProgramData}
                                {...(errors.studyprogram_id && {
                                    error: true,
                                    helperText: errors.studyprogram_id,
                                })}
                            />
                        </FormControl>
                    </Grid>

                    <Grid key="passingyear" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <SelectableSearch
                                label="Year of Passing"
                                value={educationModalData?.passingyear}
                                fieldLabel="year"
                                loading={() => handleYear()}
                                required
                                variant={"outlined"}
                                name="passingyear"
                                size="small"
                                onChangeEvent={handleInputChange}
                                data={years}
                                {...(errors.passingyear && {
                                    error: true,
                                    helperText: errors.passingyear,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="institution" item xs={12} md={4} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={educationModalData.institution}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                required
                                id="institution"
                                label="Institution"
                                name="institution"
                                autoComplete="institution"
                                {...(errors.institution && {
                                    error: true,
                                    helperText: errors.institution,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="board_unv" item xs={12} md={4} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={educationModalData.board_unv}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="board_unv"
                                label="Board / University"
                                name="board_unv"
                                autoComplete="board_unv"
                                {...(errors.board_unv && {
                                    error: true,
                                    helperText: errors.board_unv,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="mode" item xs={12} md={4} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth {...(errors.mode && {
                            error: true
                        })}>
                            <InputLabel required id="demo-simple-select-disabled-label" size="small">Mode</InputLabel>
                            <Select
                                name="mode"
                                required
                                size="small"
                                labelId="demo-simple-select-disabled-label"
                                id="demo-simple-select-disabled"
                                value={educationModalData.mode}
                                label="Mode"
                                onChange={handleInputChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Regular"}>Regular</MenuItem>
                                <MenuItem value={"Private"}>Private</MenuItem>
                                <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
                            </Select>
                            <FormHelperText>{(errors.mode &&
                                (errors.mode)
                            )}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid key="result" item xs={12} md={4} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={educationModalData.result}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="result"
                                label="Result"
                                name="result"
                                autoComplete="result"
                                {...(errors.result && {
                                    error: true,
                                    helperText: errors.result,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="any_arrears" item xs={12} md={4} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControlLabel control={<Checkbox name="any_arrears" checked={educationModalData?.any_arrears} onChange={handleInputChange} />} label="Any Arrears?" />
                    </Grid>
                    
                    <Grid key="note" item xs={12} md={5} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <Grid onChange={fileUploaded} sx={{ textAlign:"center", border: "2px", padding: "20px", borderRadius: "10px", borderStyle: "dashed", backgroundColor: "primary", borderColor: grey[500] }}>
                        <FileUploader
                        fileUrl={fileUrl}
                        setFileUrl={setFileUrl}
                        files={files}
                        setFiles={setFiles}
                        filename={`EducationProof_${APP_NAME}`}
                        filepath={educationModalData?.proof}
                        handleDownloadResume={handleDownloadResume}
                      />
                        </Grid>
                        {errors.proof&&<div style={{color:'red', paddingTop:'5px'}}>{errors.proof}</div>}
                </Grid>

                </Grid>
                <Grid container sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <Button
                        sx={{ ":hover": { color: grey[50] }, marginTop: "1.75rem", marginRight: "1rem" }}
                        variant="outlined"
                        className="mpl-secondary-btn"
                        onClick={() => setOpenPopup(false)}
                    >
                        cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1.75rem", marginRight: "1rem" }}>
                        {editedRecord ? "Update" : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}
