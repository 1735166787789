import * as ActionTypes from '../ActionTypes';

const leaveTypeInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: ""
}

export const YearlyHolidayCalendarReducer = function (state = leaveTypeInitialState, action) {
    switch (action.type) {
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                holidayData: []
            };
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                holidayData: action.payload?.data?.data
            };
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                holidayData: action.payload?.data?.data
            };
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                holidayData: action.payload?.data?.data
            };
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                holidayData: action.payload?.data?.data
            };
        case ActionTypes.YEARLY_HOLIDAY_CALENDAR_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}