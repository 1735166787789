import * as ActionTypes from '../ActionTypes';

const initialState = {
    job_options: [],
    isLoading: false
};

const JobReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.JOB_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                job_options: "",
            };
        case ActionTypes.JOB_GET_SUCCESS:
            return {
                ...state,
                success_message: action.payload.message,
                error: action.payload.has_error,
                job_options: action.payload?.data?.data,
                isLoading: false
            };
        case ActionTypes.JOB_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false
            };
        case ActionTypes.JOB_ADD_SUCCESS:
            return {
                ...state,
                successMessage: action.payload?.message,
                job_options: action.payload?.data?.data,
                success: true,
                isLoading: false
            };
        case ActionTypes.JOB_ADD_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                errorMessage: action.payload?.data?.error?.[0]?.message,
                success: false,
                isLoading: false
            };
        case ActionTypes.JOB_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false
            };
        case ActionTypes.JOB_UPDATE_SUCCESS:
            return {
                ...state,
                success: true,
                job_options: action.payload?.data?.data,
                successMessage: action.payload?.message,
                isLoading: false
            };
        case ActionTypes.JOB_UPDATE_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                success: false,
                isLoading: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.JOB_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false
            };
        case ActionTypes.JOB_DELETE_SUCCESS:
            return {
                ...state,
                successMessage: action.payload?.message,
                success: true,
                job_options: action.payload?.data?.data,
                isLoading: false
            };
        case ActionTypes.JOB_DELETE_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                errorMessage: action.payload?.data?.error?.[0]?.message,
                success: false,
                isLoading: false
            };
        default:
            return state;
    }
}

export default JobReducer;