import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";


export const GetWeekDaysHelper = async () => {
    return await axiosInstance(`/ancillary/getWeekDays`, {
        method: "GET",
        baseURL: HRMS_UTILITY_API_URL,
        data: {}
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}