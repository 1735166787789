import React from "react";
// import "../../css/CommonCss.css";
const ManageTime = () => {
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="tabs-section">
            <div className="orange-section-title-holder">
              <div className="half-title-container">
                <div className="title-with-emoji">
                  <div className="fade-in-move-on-scroll">
                    <h1 className="title">
                      Manage your time,
                      <span className="text-with-underline purple">
                        your way
                      </span>
                    </h1>
                  </div>
                  <div className="emoji-holder _02">
                    <div>😎</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-current="Tab 2"
              data-easing="ease"
              data-duration-in="300"
              data-duration-out="100"
              className="w-tabs"
            >
              <div
                data-w-id="92675f72-7663-47f1-be8a-8bcc418e8d78"
                className="tab-menu-container w-tab-menu"
                role="tablist"
              >
                <a
                  data-w-tab="Tab 2"
                  className="tab w-inline-block w-tab-link w--current"
                  id="w-tabs-0-data-w-tab-0"
                  href="#w-tabs-0-data-w-pane-0"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-0"
                  aria-selected="true"
                >
                  <div className="tab-icon-container">
                    <div className="tab-icon-holder _01">
                      <img
                        loading="lazy"
                        src="https://assets.website-files.com/634e968b219cc43522715fb8/634ff1ecf7785f6544ba245e_Calendar%20Icon.svg"
                        alt=""
                        className="tab-icon"
                      />
                    </div>
                    <div>Calendar</div>
                  </div>
                </a>
                <a
                  data-w-tab="Tab 3"
                  className="tab w-inline-block w-tab-link"
                  tabIndex="-1"
                  id="w-tabs-0-data-w-tab-1"
                  href="#w-tabs-0-data-w-pane-1"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-1"
                  aria-selected="false"
                >
                  <div className="tab-icon-container">
                    <div className="tab-icon-holder">
                      <img
                        loading="lazy"
                        src="https://assets.website-files.com/634e968b219cc43522715fb8/634ff1ecde84c0ce963a5e48_Check%20Icon.svg"
                        alt=""
                        className="tab-icon"
                      />
                    </div>
                    <div>Tasks</div>
                  </div>
                </a>
                <a
                  data-w-tab="Tab 4"
                  className="tab w-inline-block w-tab-link"
                  tabIndex="-1"
                  id="w-tabs-0-data-w-tab-2"
                  href="#w-tabs-0-data-w-pane-2"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-2"
                  aria-selected="false"
                >
                  <div className="tab-icon-container">
                    <div className="tab-icon-holder _03">
                      <img
                        loading="lazy"
                        src="https://assets.website-files.com/634e968b219cc43522715fb8/634ff1ec45e80bc2f55099ef_Pulse%20Icon.svg"
                        alt=""
                        className="tab-icon"
                      />
                    </div>
                    <div>Collaborate</div>
                  </div>
                </a>
                <a
                  data-w-tab="Tab 5"
                  className="tab w-inline-block w-tab-link"
                  tabIndex="-1"
                  id="w-tabs-0-data-w-tab-3"
                  href="#w-tabs-0-data-w-pane-3"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-3"
                  aria-selected="false"
                >
                  <div className="tab-icon-container">
                    <div className="tab-icon-holder _04">
                      <img
                        loading="lazy"
                        src="https://assets.website-files.com/634e968b219cc43522715fb8/634ff1ec5b088555bda77f2a_Gear%20Icon.svg"
                        alt=""
                        className="tab-icon"
                      />
                    </div>
                    <div>Configuration</div>
                  </div>
                </a>
              </div>
              <div
                data-w-id="92675f72-7663-47f1-be8a-8bcc418e8d82"
                className="tabs-content w-tab-content"
              >
                <div
                  data-w-tab="Tab 2"
                  className="tab-pane w-tab-pane w--tab-active"
                  id="w-tabs-0-data-w-pane-0"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-0"
                >
                  <div className="tab-pane-content-holder">
                    <div className="tab-pane-content">
                      <div className="card-title">
                      View all your tasks & interviews on a calendar
                      </div>
                      <p className="tab-description">
                      Connect your Google Outlook or Apple calendars to single view.
                      </p>
                    </div>
                    <div className="tab-pane-device-holder">
                      <div className="hero-dashboard-holder">
                        <div className="hero-browser-skeleton">
                          <div className="brower-icons-holder">
                            <div className="small-icon"></div>
                            <div className="small-icon yellow"></div>
                            <div className="small-icon green"></div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-brower-screen-holder">
                        <img
                          sizes="(max-width: 479px) 100vw, (max-width: 767px) 51vw, (max-width: 991px) 53vw, (max-width: 1439px) 56vw, 798.09375px"
                          srcSet="
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-500.webp   500w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-800.webp   800w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1080.webp 1080w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1600.webp 1600w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2000.webp 2000w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2600.webp 2600w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-3200.webp 3200w
                        "
                          src="https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard.webp"
                          loading="lazy"
                          alt=""
                          className="tab-brower-screen-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-w-tab="Tab 3"
                  className="tab-pane green w-tab-pane"
                  id="w-tabs-0-data-w-pane-1"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-1"
                >
                  <div className="tab-pane-content-holder">
                    <div className="tab-pane-content white-text">
                      <div className="card-title">
                      Setup & manage all your interview & follow up tasks
                      </div>
                      <p className="tab-description white-text">
                      Create  & manage all interview,resume screening,followup & all your tasks here.
                      </p>
                    </div>
                    <div className="tab-pane-device-holder">
                      <div className="hero-dashboard-holder">
                        <div className="hero-browser-skeleton">
                          <div className="brower-icons-holder">
                            <div className="small-icon"></div>
                            <div className="small-icon yellow"></div>
                            <div className="small-icon green"></div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-brower-screen-holder">
                        <img
                          sizes="(max-width: 479px) 100vw, (max-width: 767px) 51vw, (max-width: 991px) 53vw, (max-width: 1439px) 56vw, 798.09375px"
                          srcSet="
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-500.webp   500w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-800.webp   800w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1080.webp 1080w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1600.webp 1600w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2000.webp 2000w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2600.webp 2600w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-3200.webp 3200w
                        "
                          src="https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard.webp"
                          loading="lazy"
                          alt=""
                          className="tab-brower-screen-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-w-tab="Tab 4"
                  className="tab-pane blue w-tab-pane"
                  id="w-tabs-0-data-w-pane-2"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-2"
                >
                  <div className="tab-pane-content-holder">
                    <div className="tab-pane-content">
                      <div className="card-title">
                      Bring all stake holders on a single Dashboard
                      </div>
                      <p className="tab-description">
                      Get your company, collegaue, candidate, Intreview panel & every stake holders under one dashboard.
                      </p>
                    </div>
                    <div className="tab-pane-device-holder">
                      <div className="hero-dashboard-holder">
                        <div className="hero-browser-skeleton">
                          <div className="brower-icons-holder">
                            <div className="small-icon"></div>
                            <div className="small-icon yellow"></div>
                            <div className="small-icon green"></div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-brower-screen-holder">
                        <img
                          sizes="(max-width: 479px) 100vw, (max-width: 767px) 51vw, (max-width: 991px) 53vw, (max-width: 1439px) 56vw, 798.09375px"
                          srcSet="
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-500.webp   500w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-800.webp   800w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1080.webp 1080w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1600.webp 1600w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2000.webp 2000w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2600.webp 2600w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-3200.webp 3200w
                        "
                          src="https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard.webp"
                          loading="lazy"
                          alt=""
                          className="tab-brower-screen-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-w-tab="Tab 5"
                  className="tab-pane purple w-tab-pane"
                  id="w-tabs-0-data-w-pane-3"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-3"
                >
                  <div className="tab-pane-content-holder">
                    <div className="tab-pane-content white-text">
                      <div className="card-title">
                      Keep all your nuts & bolts configured easily
                      </div>
                      <p className="tab-description white-text">
                      Configure Job hiring flow, candidate selection process & onboarding process using our Kanban dashboard.
                      </p>
                    </div>
                    <div className="tab-pane-device-holder">
                      <div className="hero-dashboard-holder">
                        <div className="hero-browser-skeleton">
                          <div className="brower-icons-holder">
                            <div className="small-icon"></div>
                            <div className="small-icon yellow"></div>
                            <div className="small-icon green"></div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-brower-screen-holder">
                        <img
                          sizes="(max-width: 479px) 100vw, (max-width: 767px) 51vw, (max-width: 991px) 53vw, (max-width: 1439px) 56vw, 798.09375px"
                          srcSet="
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-500.webp   500w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-800.webp   800w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1080.webp 1080w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-1600.webp 1600w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2000.webp 2000w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-2600.webp 2600w,
                          https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard-p-3200.webp 3200w
                        "
                          src="https://assets.website-files.com/634e968b219cc43522715fb8/634ead44e56c2bcf56a9caf6_Hero%20Dashboard.webp"
                          loading="lazy"
                          alt=""
                          className="tab-brower-screen-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ManageTime;
