import * as ActionTypes from '../ActionTypes';

export const GetDesignationAction = () => {
    return {
        type: ActionTypes.DESIGNATION_GET_REQUEST,
    }
}

export const AddDesignationAction = (params) => {
    return {
        type: ActionTypes.DESIGNATION_ADD_REQUEST,
        params
    }
}

export const UpdateDesignationAction = (params) => {
    return {
        type: ActionTypes.DESIGNATION_UPDATE_REQUEST,
        params
    }
}

export const DeleteDesignationAction = (params) => {
    return {
        type: ActionTypes.DESIGNATION_DELETE_REQUEST,
        params
    }
}