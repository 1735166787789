import * as ActionTypes from '../ActionTypes';

export const GetLeaveReasonAction = () => {
    return {
        type: ActionTypes.LEAVE_REASON_GET_REQUEST,
    }
}

export const AddLeaveReasonAction = (params) => {
    return {
        type: ActionTypes.LEAVE_REASON_ADD_REQUEST,
        params
    }
}

export const UpdateLeaveReasonAction = (params) => {
    return {
        type: ActionTypes.LEAVE_REASON_UPDATE_REQUEST,
        params
    }
}

export const DeleteLeaveReasonAction = (params) => {
    return {
        type: ActionTypes.LEAVE_REASON_DELETE_REQUEST,
        params
    }
}