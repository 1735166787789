import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ConvertDate } from '../../../../Base/helper/baseFunctions';

const Employment = ({ data }) => {
    return (
        <div className='employment'>
            <Box sx={{ p: 2 }}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>Entity Name  &nbsp;</Typography>
                            <Typography>{data?.first_name || "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>Employee ID  &nbsp;</Typography>
                            <Typography>{data?.employee_no || "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>EPF No  &nbsp;</Typography>
                            <Typography>{data?.pf_no || "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>ESIC No  &nbsp;</Typography>
                            <Typography>{data?.esi_no || "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>Designation  &nbsp;</Typography>
                            <Typography>{data?.designation || "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>Location  &nbsp;</Typography>
                            <Typography>{data?.work_location || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>Date of Joining  &nbsp;</Typography>
                            <Typography>{data?.doj ? ConvertDate(data?.doj) : "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>Grade  &nbsp;</Typography>
                            <Typography>{data?.paygrade || "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>Business Unit &nbsp;</Typography>
                            <Typography>{data?.business_unit || "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>Cost Center  &nbsp;</Typography>
                            <Typography>{data?.cost_center || "-"}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", mt: 1 }}>
                            <Typography sx={{ fontWeight: "600" }}>State  &nbsp;</Typography>
                            <Typography>{data?.state || "-"}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Employment;
