import * as ActionTypes from '../ActionTypes';

export const GetBankAction = () => {
    return {
        type: ActionTypes.GET_BANK_REQUEST,
    }
}

export const AddBankAction = (bank_data) => {
    return {
        type: ActionTypes.ADD_BANK_REQUEST,
        bank_data
    }
}

export const UpdateBankAction = (bank_data) => {
    return {
        type: ActionTypes.UPDATE_BANK_REQUEST,
        bank_data
    }
}

export const DeleteBankAction = (bank_data) => {
    return {
        type: ActionTypes.DELETE_BANK_REQUEST,
        bank_data
    }
}