import * as ActionTypes from '../ActionTypes';

export const GetYearlyHolidayCalendarAction = (params) => {
    return {
        type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_GET_REQUEST,
        params
    }
}

export const AddYearlyHolidayCalendarAction = (params) => {
    return {
        type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_ADD_REQUEST,
        params
    }
}

export const UpdateYearlyHolidayCalendarAction = (params) => {
    return {
        type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_UPDATE_REQUEST,
        params
    }
}

export const DeleteYearlyHolidayCalendarAction = (params) => {
    return {
        type: ActionTypes.YEARLY_HOLIDAY_CALENDAR_DELETE_REQUEST,
        params
    }
}