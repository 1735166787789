import { Backdrop, Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import Popup from "../../../Base/views/Popup"
import { ConvertDate } from "../../../Base/helper/baseFunctions";
import { useSelector } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import AtsInvoicePdf from "./AtsInvoicePdf";
import { CSVLink } from "react-csv";

const ATSGeneratedInvoicePopup = ({ setShowGeneratedInvoice, currentData }) => {

    const { invoiceCandidateData, isCandidateLoading } = useSelector(state => state.AtsReducer?.AtsInvoiceReducer)

    const handleDownloadPDF = async () => {
        const MyDocument = (
            <AtsInvoicePdf
                data={currentData}
                invoiceCandidateData={invoiceCandidateData}
            />
        );

        const blob = await pdf(MyDocument).toBlob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${currentData?.ats_invoice_no}.pdf`;
        a.click();
        // Clean up the URL object
        URL.revokeObjectURL(url);
    };

    const headers = [
        { label: "Candidate Name", key: "first_name" },
        { label: "Job Title", key: "job_title" },
        { label: "Joined Date", key: "joined_date" },
        { label: "Billable CTC", key: "billable_ctc" },
        { label: "Payable Amount", key: "amount" },
    ];
    return (
        <>
            <Popup
                title={`${currentData?.ats_invoice_no}`}
                openPopup={true}
                setOpenPopup={setShowGeneratedInvoice}
                maxWidth={"md"}
                fullWidth={true}
            >
                {
                    isCandidateLoading &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <Box>
                    <Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: "bold", minWidth: '100px' }}>Bill To</Typography>
                                    <Typography sx={{ ml: 2 }}>{currentData?.billing_name}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: "bold", minWidth: '100px' }}>Billing Address</Typography>
                                    <Typography sx={{ ml: 1.7 }}>{currentData?.billing_address}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: "bold", minWidth: '100px' }}>GST No.</Typography>
                                    <Typography sx={{ ml: 2 }}>{currentData?.gst}</Typography>
                                </Box>
                            </Box>
                            <Box item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: "bold", minWidth: '110px' }}>Invoice Date</Typography>
                                    <Typography sx={{ mr: 2 }}>{ConvertDate(currentData?.invoice_date)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: "bold", minWidth: '110px' }}>Invoice No</Typography>
                                    <Typography sx={{ mr: 2 }}>{currentData?.ats_invoice_no}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: "bold", minWidth: '110px' }}>Invoice Amount</Typography>
                                    <Typography sx={{ mr: 2 }}>{currentData?.invoice_amount}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
                        {!isCandidateLoading &&
                            <>
                                <Button className="mpl-primary-btn" onClick={handleDownloadPDF}>
                                    Download PDF
                                </Button>
                                <Button sx={{ ml: 2 }} className="mpl-primary-btn">
                                    <CSVLink className="ats-csv-btn" data={invoiceCandidateData} headers={headers} filename={currentData?.ats_invoice_no}>
                                        Download CSV
                                    </CSVLink>
                                </Button>
                            </>}
                    </Box>
                    <Box>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Candidate Name</TableCell>
                                        <TableCell>Job Title</TableCell>
                                        <TableCell>Position</TableCell>
                                        <TableCell>Joined Date</TableCell>
                                        <TableCell align="right">Joined CTC</TableCell>
                                        <TableCell align="right">Billable CTC</TableCell>
                                        <TableCell align="right">Payable Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoiceCandidateData.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row?.first_name}</TableCell>
                                            <TableCell>{row?.job_title}</TableCell>
                                            <TableCell>{row?.joined_date ? ConvertDate(row?.joined_date) : ""}</TableCell>
                                            <TableCell align="right">{row?.joined_ctc}</TableCell>
                                            <TableCell align="right">{row?.billable_ctc}</TableCell>
                                            <TableCell align="right">{row?.amount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Popup >
        </>
    )
}

export default ATSGeneratedInvoicePopup