import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetStudyLevelHelper = async () => {
    return await axiosInstance(`/ancillary/getStudyLevels`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {}
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })

}


export const AddStudyLevelHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addStudyLevel`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data.employee_id,
            name: params?.name,
            code: params?.code,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Study Level Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateStudyLevelHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateStudyLevel`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data.employee_id,
            studylevel_id: params?.studylevel_id,
            name: params?.name,
            code: params?.code,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Study Level Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteStudyLevelHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteStudyLevel`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data?.employee_id,
            studylevel_id: params?.studylevel_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Study Level Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}