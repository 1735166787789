import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    isLoading: false,
    atsModuleData: []
};

const ATSModuleReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_MODULE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                atsModuleData: []
            };
        case ActionTypes.ATS_MODULE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success_message: action.payload.message,
                error: action.payload.hasError,
                atsModuleData: action.payload?.data?.data
            };
        default:
            return state;
    }
}

export default ATSModuleReducer;