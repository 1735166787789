import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
    isCandidateLoading: true,
};

export const AtsWorkSpaceReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_WORKSPACE_ADD_REQUEST:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case ActionTypes.ATS_WORKSPACE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsSwitchWorkspaceData: action.payload
            };
        case ActionTypes.ATS_WORKSPACE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                success: true,
                errorMessage: action.payload?.message,
                atsSwitchWorkspaceData: []
            };
        default:
            return state;
    }
}