import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";

export const GetDocumentationsHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getDocumentations`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddDocumentationsHelper = async (params) => {
    const data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addDocumentations`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            proof_name : params?.proof_name,
            proof_folder_name : params?.proof_folder_name,
           
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Documentations Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateDocumentationsHelper = async (params) => {
    const data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateDocumentations`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            proof_id : params?.proof_pk ,
            proof_name : params?.proof_name,
            proof_folder_name : params?.proof_folder_name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Documentations Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteDocumentationsHelper = async (params) => {
    const data = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteDocumentations`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            proof_id: params?.proof_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Documentations Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}