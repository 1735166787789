import * as ActionTypes from '../ActionTypes';

const initialState = {
    success_message: "",
    error: false,
    proof_type_data: []
};

const ProofTypeReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.PROOF_TYPE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                proof_type_data: []
            };
        case ActionTypes.PROOF_TYPE_GET_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: action.payload.has_error,
                proof_type_data: action.payload?.data?.data
            };
        case ActionTypes.PROOF_TYPE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.PROOF_TYPE_ADD_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: action.payload.has_error,
                proof_type_data: action.payload?.data?.data
            };
        case ActionTypes.PROOF_TYPE_ADD_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                isLoading: false,
            };
        case ActionTypes.PROOF_TYPE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.PROOF_TYPE_UPDATE_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: action.payload.has_error,
                proof_type_data: action.payload?.data?.data
            };
        case ActionTypes.PROOF_TYPE_UPDATE_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                isLoading: false,
            };
        case ActionTypes.PROOF_TYPE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.PROOF_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: action.payload.has_error,
                proof_type_data: action.payload?.data?.data
            };
        case ActionTypes.PROOF_TYPE_DELETE_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default ProofTypeReducer;