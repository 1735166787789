import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetWeekOffConfigureHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/getWeekOffConfigure`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddWeekOffConfigureHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/addWeekOffConfigure`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            day_name: params?.week_off?.week_day_name,
            full_day_off: params?.full_day_off,
            half_day_off: params?.half_day_off, 
            user_id: data?.emp_users_pk,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Week Off Configure added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateWeekOffConfigureHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/updateWeekOffConfigure`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            day_name: params?.week_off?.week_day_name,
            full_day_off: params?.full_day_off,
            half_day_off: params?.half_day_off, 
            user_id: data?.emp_users_pk,
            week_off_days_id: params?.week_off_days_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Week Off Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
