import * as ActionTypes from "../ActionTypes";


export const GetTaxDeclarationAction = () => {
    return {
        type: ActionTypes.TAX_DECLARATION_GET_REQUEST,
    }
}

export const AddTaxDeclarationAction = (params) => {
    return {
        type: ActionTypes.TAX_DECLARATION_ADD_REQUEST,
        params
    }
}

export const UpdateTaxDeclarationAction = (params) => {
    return {
        type: ActionTypes.TAX_DECLARATION_UPDATE_REQUEST,
        params
    }
}