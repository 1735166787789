import {
  Box,
  Button,
  FormControl,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import {
  AddWorkLocationAction,
  DeleteWorkLocationAction,
  GetWorkLocationAction,
} from "../../../../store/Ancillary/WorkLocation/Action";
import ErrorIcon from '@mui/icons-material/Error';
import party_img from "../../../../Image/party-popper.png";
import work_img from "../../../../Image/working-at-home.png";
import { useDispatch, useSelector } from "react-redux";
import { UpdateConfigureStatusAction } from "../../../../store/Ancillary/ConfigureStatus/Action";
import Popup from "../../Base/views/Popup";
import ValidationPopup from "./ValidationPopup";
const ConfigureWorklocation = ({
  setStepper,
  setTitle,
  setMessage,
  setImageSrc,
}) => {
  const dispatch = useDispatch();
  const initialFValues = {
    work_location_name: "",
  };

  const [locationDetails, setLocationDetails] = useState(initialFValues);
  const { workLocation_options, isSuccess } = useSelector(
    (state) => state?.AncillaryReducer?.WorkLocationReducer
  );
  const { designation_options } = useSelector(
    (state) => state?.AncillaryReducer?.DesignationReducer
  );
  const [show, setShow] = useState(false);
  const [showContent, setShowContent] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    dispatch(GetWorkLocationAction());
  
  }, []);

  const Alert = () => {
    const containerStyle = {
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "5px 5px",
      color: "#000",
      position: "relative",
      marginBottom:"5px",
      width:"40%"
    };
  
    const infoIconStyle = {
      color: "#007bff",
      marginRight: "5px",
      fontSize: "14px",
    };
  
    const textStyle = {
      margin: 0,
      flex: 1,
      color:"#1b1c21",
      fontSize:"12px",
      fontStyle:"italic"
    };
  
    return (
      <div style={containerStyle}>
        <span style={infoIconStyle}><ErrorIcon fontSize="extra-small" /></span>
        <p style={textStyle}>
        Atleast 1 Work Location is required to proceed 
        </p>
      </div>
    );
  };

  const handleInputChange = (event) => {
    setLocationDetails({
      ...locationDetails,
      [event.target.name]: event.target.value,
    });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...locationDetails, [event.target.name]: event.target.value });
  };
  
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("work_location_name" in fieldValues) {
      temp.work_location_name =
        fieldValues.work_location_name?.trim() === "" ? "Work Location is required" : "";
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleWorkLocation();
    }
  };
  
  async function handleWorkLocation() {
    if (validate(locationDetails)) {
      dispatch(AddWorkLocationAction(locationDetails));
      if (isSuccess) {
        setLocationDetails(initialFValues);
      }
    }
  }
  const handleFinish = () => {
    if(workLocation_options.length === 0){
      setShow(true);
      setShowContent("You Have to Create Atleast One WorkLocation")
    }
    else if (designation_options.length === 0) {
      setShow(true);
      setShowContent(" You Have to Create Atleast One Designation")
    } else {
      setTitle("And we're all set !");
      setMessage(
        "Quick Tip: As a next step, navigate to Settings > General & Setup your workspace with additional parameters "
      );
      setImageSrc(party_img);
      setStepper(2);
      setLocationDetails(initialFValues);
      setErrors([]);
      dispatch(UpdateConfigureStatusAction());
    }
  };
  const handleDelete = (id) => {
    dispatch(DeleteWorkLocationAction({ ats_work_location_id: `{${id}}` }));
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={work_img}
            alt="work_location_name"
            width={"100px"}
            height={"100px"}
          />
          <Typography sx={{ pt: 1, pb: 1 }} variant="h3">
            Where are your offices located at?
          </Typography>
        </Box>
        {workLocation_options?.length === 0 && (
        <Alert />
      )}
        <FormControl fullWidth>
          <TextField
            value={locationDetails?.work_location_name}
            required
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            label="Work Location"
            onKeyDown={handleKeyDown}
            name="work_location_name"
            {...(errors.work_location_name && {
              error: true,
              helperText: errors.work_location_name,
            })}
          />
        </FormControl>
        <Box
          sx={{
            pt: 2,
            maxHeight: "25vh",
            overflowY: "auto",
          }}
        >
          {workLocation_options &&
            workLocation_options.length > 0 &&
            workLocation_options.map((name, index) => (
              <Chip
                key={index}
                onDelete={() => handleDelete(name?.ats_work_location_id)}
                label={`${index + 1}. ${name?.work_location_name}`} // Include the index in the label
                disabled={false} // Set to true or false based on your requirement
                variant="filled"
                style={{ margin: "0.5rem" }} // Adjust the margin as needed
              />
            ))}
        </Box>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={() => {
              setStepper(2);
              setLocationDetails(initialFValues);
              setErrors([]);
            }}
            variant="contained"
            color="primary"
            type="submit"
            className="mpl-primary-btn"
            sx={{
              marginTop: "1.75rem",
              marginRight: "1rem",
              fontSize: "20px",
              padding: "0px 40px 0px 40px",
            }}
          >
            Skip
          </Button>
          <Box>
            <Button
              onClick={() => handleWorkLocation()}
              variant="contained"
              color="primary"
              type="submit"
              className="mpl-primary-btn"
              sx={{
                marginTop: "1.75rem",
                marginRight: "1rem",
                fontSize: "20px",
                padding: "0px 40px 0px 40px",
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                handleFinish();
              }}
              variant="contained"
              color="primary"
              type="submit"
              className="mpl-primary-btn"
              sx={{
                marginTop: "1.75rem",
                marginRight: "1rem",
                fontSize: "20px",
                padding: "0px 40px 0px 40px",
              }}
            >
              Finish
            </Button>
          </Box>
        </div>
      </Box>
      <Popup maxWidth="xs" openPopup={show} setOpenPopup={setShow}>
      <ValidationPopup openPopup={show} setShow={setShow}  showContent={showContent}/>
      </Popup>
    </>
  );
};

export default ConfigureWorklocation;
