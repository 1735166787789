import { Box, Card, Divider, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetPaygradeAction } from '../../../../store/Ancillary/Paygrade/Action';
import { NoData } from '../../Base/views/NotAuthorized';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


const Dashboard = ({ setValue, setAccordian }) => {

    const { paygrade_options, isLoading } = useSelector(state => state.AncillaryReducer?.PaygradeReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetPaygradeAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (value, accordian) => {
        setValue(value);
        setAccordian(accordian)
    }

    const data = [
        { title: "Paygrade", data: paygrade_options || [], field_name: "name" },
    ]

    return (
        <>
            <Grid container sx={{ padding: "10px" }} className="hr-settings">
                {
                    data?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("2", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item?.[res?.field_name]}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    );
}

export default Dashboard;
