/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Button, Typography } from "@mui/material";
import * as React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BadgeIcon from "@mui/icons-material/Badge";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IdentityProof from "./IdentityProof";
import { toast } from "react-toastify";
import {
  GetEmployeeAddressAction,
  UpdateEmployeeAddressAction,
} from "../../../../../store/Employee/OtherPersonal/Action";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { GetCountryHelper } from "../../../Ancillary/Country/helper/CountryHelper";
import { GetStateHelper } from "../../../Ancillary/State/helper/StateHelper";
import { GetCityHelper } from "../../../Ancillary/City/helper/CityHelper";
import AddressDetails from "./AddressDetails";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import history from "../../../../../history";

const PersonalPage = (props) => {
  const userData = getUserData();
  const dispatch = useDispatch();
  const [value, setValue] = useState("1");
  const [curCountryData, setCurCountryData] = useState({
    load: true,
    values: [],
  });
  const [curStateData, setCurStateData] = useState({ load: true, values: [] });
  const [curCityData, setCurCityData] = useState({ load: true, values: [] });
  const [perCountryData, setPerCountryData] = useState({
    load: true,
    values: [],
  });
  const [perStateData, setPerStateData] = useState({ load: true, values: [] });
  const [perCityData, setPerCityData] = useState({ load: true, values: [] });
  const [errors, setErrors] = useState({});
  const [addressDetails, setAddressDetails] = useState({
    cur_city: "",
    cur_state: "",
    cur_country: "",
    per_city: "",
    per_state: "",
    per_country: "",
    cur_street1: "",
    cur_street2: "",
    per_street1: "",
    per_street2: "",
    cur_zipcode: "",
    per_zipcode: "",
    is_permanent: false,
  });
  const [candidateEditedData, setCandidateEditedData] = useState();
  const { employeeAddressData, isLoading } = useSelector(
    (state) => state.EmployeeReducer.EmployeeOtherPersonalReducer
  );
  const { personalDatas } = useSelector(state => state.EmployeeReducer.EmployeePersonalReducer);
  useEffect(() => {
    if (props?.currentMode === "editemployee" && props?.OnboardingEmpData) {
      dispatch(
        GetEmployeeAddressAction({
          domain: userData?.domain,
          employee_id: props?.OnboardingEmpData?.employee_id,
        })
      );
    } else if (props?.currentMode === "addemployee") {
      dispatch(
        GetEmployeeAddressAction({
          domain: userData?.domain,
          employee_id: props?.newdata?.employee_id,
        })
      );
    } else {
      dispatch(
        GetEmployeeAddressAction({
          domain: userData?.domain,
          employee_id: userData?.employee_id,
        })
      );
    }
    employeeAddressData &&
      setAddressDetails({
        ...employeeAddressData?.[0],
        cur_city: employeeAddressData?.[0]?.cur_city_id
          ? {
              city_id: employeeAddressData?.[0]?.cur_city_id,
              city_name: employeeAddressData?.[0]?.cur_city,
            }
          : "",
        cur_country: employeeAddressData?.[0]?.cur_country_id
          ? {
              country_id: employeeAddressData?.[0]?.cur_country_id,
              country_name: employeeAddressData?.[0]?.cur_country,
            }
          : "",
        cur_state: employeeAddressData?.[0]?.cur_state_id
          ? {
              state_id: employeeAddressData?.[0]?.cur_state_id,
              state_name: employeeAddressData?.[0]?.cur_state,
            }
          : "",
          per_city: employeeAddressData?.[0]?.per_city_id
          ? {
              city_id: employeeAddressData?.[0]?.per_city_id,
              city_name: employeeAddressData?.[0]?.per_city,
            }
          : "",
          per_country: employeeAddressData?.[0]?.per_country_id
          ? {
              country_id: employeeAddressData?.[0]?.per_country_id,
              country_name: employeeAddressData?.[0]?.per_country,
            }
          : "",
          per_state: employeeAddressData?.[0]?.per_state_id
          ? {
              state_id: employeeAddressData?.[0]?.per_state_id,
              state_name: employeeAddressData?.[0]?.per_state,
            }
          : ""
      });
      employeeAddressData &&
      setCandidateEditedData({
        ...employeeAddressData?.[0],
        cur_city: employeeAddressData?.[0]?.cur_city_id
          ? {
              city_id: employeeAddressData?.[0]?.cur_city_id,
              city_name: employeeAddressData?.[0]?.cur_city,
            }
          : "",
        cur_country: employeeAddressData?.[0]?.cur_country_id
          ? {
              country_id: employeeAddressData?.[0]?.cur_country_id,
              country_name: employeeAddressData?.[0]?.cur_country,
            }
          : "",
        cur_state: employeeAddressData?.[0]?.cur_state_id
          ? {
              state_id: employeeAddressData?.[0]?.cur_state_id,
              state_name: employeeAddressData?.[0]?.cur_state,
            }
          : "",
          per_city: employeeAddressData?.[0]?.per_city_id
          ? {
              city_id: employeeAddressData?.[0]?.per_city_id,
              city_name: employeeAddressData?.[0]?.per_city,
            }
          : "",
          per_country: employeeAddressData?.[0]?.per_country_id
          ? {
              country_id: employeeAddressData?.[0]?.per_country_id,
              country_name: employeeAddressData?.[0]?.per_country,
            }
          : "",
          per_state: employeeAddressData?.[0]?.per_state_id
          ? {
              state_id: employeeAddressData?.[0]?.per_state_id,
              state_name: employeeAddressData?.[0]?.per_state,
            }
          : ""
      })
     
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const NextStepper = (e, next) => {
    if (
      JSON.stringify(addressDetails) !== JSON.stringify(candidateEditedData)
    ) {
      handleSubmit(e, next);
    }
    if(props?.OnboardEmployee){
      if(validate()){
        props?.setActiveStep(3);
      }
      else{
        toast.error(
          "Address is empty",
          {
            duration: 2000,
          }
        );
      }
     
    }
    else{
      if(validate()){
        props?.setActiveStep(4);
      }
      else{
        toast.error(
          "Address is empty",
          {
            duration: 2000,
          }
        );
      }
      
    }

      if(personalDatas?.length === 0){
        toast.error(
          "You need to have atleast one identity proof",
          {
            duration: 2000,
          }
        );
        return
      }

  };
  const BackStepper = () => {  
    if(props?.OnboardEmployee){
      props?.setActiveStep(1)
    }
    else{
      props?.setActiveStep(2)
    }   
    
}
  const handleCurCountry = () => {
    GetCountryHelper().then((res) => {
      setCurCountryData({ load: false, values: res?.data?.data });
    });
  };

  const handleCurState = () => {
    GetStateHelper(addressDetails?.cur_country?.country_id).then((res) => {
      setCurStateData({ load: false, values: res?.data?.data });
    });
  };

  const handleCurCity = () => {
    GetCityHelper(addressDetails?.cur_state?.state_id).then((res) => {
      setCurCityData({ load: false, values: res?.data?.data });
    });
  };

  const handlePerCountry = () => {
    GetCountryHelper().then((res) => {
      setPerCountryData({ load: false, values: res?.data?.data });
    });
  };

  const handlePerState = () => {
    GetStateHelper(addressDetails?.per_country?.country_id).then((res) => {
      setPerStateData({ load: false, values: res?.data?.data });
    });
  };

  const handlePerCity = () => {
    GetCityHelper(addressDetails?.per_state?.state_id).then((res) => {
      setPerCityData({ load: false, values: res?.data?.data });
    });
  };

  const handleInputChange = (event, value) => {
    if (["cur_city", "per_city"].includes(event)) {
      if (value) {
        setAddressDetails({ ...addressDetails, [event]: value });
      } else {
        setAddressDetails({ ...addressDetails, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...addressDetails, [event]: value ? value : "" });
    } else if (["cur_country", "per_country"].includes(event)) {
      if (event === "cur_country") {
        setCurStateData({ load: true, values: [] });
        setCurCityData({ load: true, values: [] });
        setAddressDetails({
          ...addressDetails,
          [event]: value ? value : "",
          cur_state: "",
          cur_city: "",
        });
      } else {
        setPerStateData({ load: true, values: [] });
        setPerCityData({ load: true, values: [] });
        setAddressDetails({
          ...addressDetails,
          [event]: value ? value : "",
          per_state: "",
          per_city: "",
        });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...addressDetails, [event]: value ? value : "" });
    } else if (["cur_state", "per_state"].includes(event)) {
      if (event === "cur_state") {
        setCurCityData({ load: true, values: [] });
        setAddressDetails({
          ...addressDetails,
          [event]: value ? value : "",
          cur_city: "",
        });
      } else {
        setPerCityData({ load: true, values: [] });
        setAddressDetails({
          ...addressDetails,
          [event]: value ? value : "",
          per_city: "",
        });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...addressDetails, [event]: value ? value : "" });
    } else if (event.target.name === "is_permanent") {
      if (event.target.checked) {
        setAddressDetails({
          ...addressDetails,
          [event.target.name]: event.target.checked,
          cur_street1: addressDetails?.per_street1,
          cur_street2: addressDetails?.per_street2,
          cur_city: addressDetails?.per_city,
          cur_state: addressDetails?.per_state,
          cur_country: addressDetails?.per_country,
          cur_zipcode: addressDetails?.per_zipcode,
        });
      } else {
        setAddressDetails({
          ...addressDetails,
          [event.target.name]: event.target.checked,
          cur_street1: "",
          cur_street2: "",
          cur_city: "",
          cur_state: "",
          cur_country: "",
          cur_zipcode: "",
        });
      }
    } else {
      setAddressDetails({
        ...addressDetails,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...addressDetails,
          [event.target.name]: event.target.value,
        });
    }
    if (
      ["cur_city", "cur_state", "cur_country"].includes(event) ||
      ["cur_street1", "cur_street2", "cur_zipcode"].includes(event)
    ) {
      setAddressDetails((currentArray) => ({
        ...currentArray,
        is_permanent: false,
      }));
    }
  };

  const validate = (fieldValues = addressDetails) => {
    let temp = { ...errors };
    if ("per_city" in fieldValues) {
      temp.per_city = fieldValues.per_city === "" ? "City is required" : "";
    }
    if ("per_state" in fieldValues) {
      temp.per_state = fieldValues.per_state === "" ? "State is required" : "";
    }
    if ("per_country" in fieldValues) {
      temp.per_country =
        fieldValues.per_country === "" ? "Country is required" : "";
    }
    if ("emg_contact_no" in fieldValues) {
      temp.emg_contact_no =
        fieldValues.emg_contact_no === "" || fieldValues.emg_contact_no === null
          ? "Contact Number is required"
          : !fieldValues?.emg_contact_no?.toString().match(/^[0-9]{10,13}$/)
          ? "Invalid Contact Number."
          : "";
    }
    if ("emg_contact_person" in fieldValues) {
      temp.emg_contact_person =
        fieldValues.emg_contact_person === "" ||
        fieldValues.emg_contact_person === null
          ? "Contact Person is required"
          : "";
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e, next) => {
    if (validate()) {
      if (props?.currentMode === "editemployee" && props?.OnboardingEmpData) {
        dispatch(
          UpdateEmployeeAddressAction({
            ...addressDetails,
            domain: userData?.domain,
            employee_id: props?.OnboardingEmpData?.employee_id,
          })
        );
      } else if (props?.currentMode === "addemployee") {
        dispatch(
          UpdateEmployeeAddressAction({
            ...addressDetails,
            domain: userData?.domain,
            employee_id: props?.newdata?.employee_id,
          })
        );
      } else {
        dispatch(
          UpdateEmployeeAddressAction({
            ...addressDetails,
            domain: userData?.domain,
            employee_id: userData?.employee_id,
          })
        );
      }
      if (next === "Next") {
        if(props?.OnboardEmployee){
          props?.setActiveStep(3);
        }
        else{
          props?.setActiveStep(4);
        }
      }
      
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1", padding: props?.OnboardEmployee ? "0px 0px 20px 25px" : "0px 0px 0px 0px"  }}>
       
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent:
                props?.currentMode !== undefined ? "space-between" : "flex-end",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
          <Button
                    sx={{ marginRight: "10px" }}
                    type="submit"
                    className="mpl-primary-btn"
                    variant="contained"
                    size="medium"
                    onClick={BackStepper}
                  >
                  Back
          </Button>
            {!props?.OnboardEmployee && props?.currentMode !== undefined ? (
              <Button
                sx={{ marginRight: "10px" }}
                type="submit"
                className="mpl-primary-btn"
                variant="contained"
                size="medium"
                onClick={() => history.push("/myemployee")}
              >
                Back to List
              </Button>
            ) : null}
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                textAlign:
                  props?.currentMode !== undefined ? "center" : "center",
              }}
            >
              Identity Proof
            </Typography>
            <Button
              sx={{ marginRight: "10px" }}
              onClick={(e) => NextStepper(e, "Next")}
              type="submit"
              variant="contained"
              className="mpl-primary-btn"
              size="medium"
            >
              Next
            </Button>
          </Grid>
      
        <TabContext value={value}>
          <Box>
            <TabList
              sx={{
                minHeight: "40px",
                ".MuiTab-root": {
                  minHeight: "20px",
                  padding: "10px 0px 10px 10px",
                },
                "& .MuiTabs-indicator": {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center !important",
                },
              }}
              onChange={handleChange}
              aria-label="Personal Details"
            >
              <Tab
                sx={{ fontWeight: "600" }}
                icon={<BadgeIcon />}
                iconPosition="start"
                label="Identity Proof"
                value="1"
              />
              <Tab
                sx={{ fontWeight: "600" }}
                icon={<PersonPinCircleIcon />}
                iconPosition="start"
                label="Address"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "0px" }}>
            <IdentityProof {...props} />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0px" }}>
            <AddressDetails
              {...props}
              handleChange={handleInputChange}
              addressDetails={addressDetails}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              curCountryData={curCountryData}
              perCountryData={perCountryData}
              curStateData={curStateData}
              perStateData={perStateData}
              curCityData={curCityData}
              perCityData={perCityData}
              handleCurCountry={handleCurCountry}
              handlePerCountry={handlePerCountry}
              handleCurState={handleCurState}
              handlePerState={handlePerState}
              handleCurCity={handleCurCity}
              handlePerCity={handlePerCity}
              NextStepper={NextStepper}
              errors={errors}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default PersonalPage;
