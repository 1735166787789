import * as ActionTypes from '../ActionTypes';

export const GetAtsInvoiceAction = (params) => {
    return {
        type: ActionTypes.ATS_INVOICE_GET_REQUEST,
        params
    }
}

export const GetAtsInvoiceCandidatesAction = (params) => {
    return {
        type: ActionTypes.ATS_INVOICE_CANDIDATES_GET_REQUEST,
        params
    }
}

export const GetAtsGenerateInvoiceCandidatesAction = (params) => {
    return {
        type: ActionTypes.ATS_INVOICE_GENERATE_CANDIDATES_GET_REQUEST,
        params
    }
}

export const AddAtsInvoiceAction = (params) => {
    return {
        type: ActionTypes.ATS_INVOICE_ADD_REQUEST,
        params
    }
}

export const UpdateAtsInvoiceAction = (params) => {
    return {
        type: ActionTypes.ATS_INVOICE_UPDATE_REQUEST,
        params
    }
}