import { Typography, Box, Card, CardContent, List, Divider, IconButton, Tooltip, ListItem, ListItemText } from '@mui/material';
import { useEffect } from 'react';
import { GetAtsCompletedTaskHelper } from '../../helper/AtsTasksHelper';
import { useState } from 'react';
import { useTheme } from "@mui/material/styles";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import AddIcon from '@mui/icons-material/Add';
import { UpdateStatusHelper } from '../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper';
import { ATS_TASK } from '../../../../Constants/baseConstant';
import MyTaskPopup from './MyTaskPopup';
import { ConvertDateTime } from '../../../Base/helper/baseFunctions';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
const CompletedTask = ({ mode, setMode, openPopup, setOpenPopup, popupData, setPopupData }) => {
    const theme = useTheme();
    const [taskData, setTaskData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [statusId, setStatusId] = useState('');

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        GetAtsCompletedTaskHelper().then(res => {
            if (res?.data?.statuscode === 200) {
                setTaskData(res?.data?.data)
            }
        })
    }

    const handleAdd = () => {
        setPopupData([]);
        setMode("Add")
        setOpenPopup(true);
    };

    const handleStatus = (e, id) => {
        e.stopPropagation();
        setStatusId(id);
        setOpenDialog(true)
    }
    const handlePreview = (task) => {
        setPopupData(task);
        setMode("Edit")
        setOpenPopup(true);
    };
    const handleDeleteConfirm = () => {
        UpdateStatusHelper({ update_id: statusId, status: "Started", column_name: ATS_TASK, successMessage: "Status Updated Successfully" })
            .then(res => {
                if (res?.data?.statuscode === 200) getData()
            })
        setOpenDialog(false);
        setStatusId('')
    };

    return (
        <>
            <Box sx={{ display: 'block', minHeight: "39vh" }}>
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        py: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: 2,
                        }}
                    >
                        <Typography sx={{ pl: 3 }} variant="h6">Completed List</Typography>
                        <Tooltip title="Add Task">
                            <IconButton onClick={handleAdd}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Divider />
                    <List sx={{ px: 2 }}>
                        <ListItem sx={{ height: "12px" }}>
                            <ListItemText primary="Task" sx={{ flex: '0 0 35%' }} />
                            <ListItemText primary="Assigned To" sx={{ flex: '0 0 15%' }} />
                            <ListItemText primary="Status" sx={{ flex: '0 0 10%' }} />
                            <ListItemText primary="Completed Date" sx={{ flex: '0 0 30%' }} />
                            <ListItemText primary="Action" sx={{ flex: '0 0 10%', textAlign: "center" }} />
                        </ListItem>
                    </List>
                    <Divider sx={{ mb: -1 }} />
                </Box>
                <Box sx={{ maxHeight: "30vh", overflowY: "scroll", backgroundColor: "white" }}>
                    <Card sx={{ mt: -3, borderRadius: "0px" }}>
                        <CardContent>
                            {taskData.length === 0 ? (
                                <Typography variant="body1" align="center" p={10}>
                                    Well done. All tasks has been catched up.
                                </Typography>
                            ) : (
                                <List>
                                    {taskData?.map((res) => {
                                        return (
                                            <Card key={res?.task_id} sx={{ mb: 1, height: '33px' }}>
                                                <ListItem
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer'
                                                        },
                                                        mt: -1,
                                                        backgroundColor:theme?.palette?.primary?.bg,
                                                        fontSize:"13px"
                                                    }}
                                                    onClick={() => handlePreview(res)}   >
                                                    <ListItemText primary={res?.title} sx={{ flex: '0 0 35%' }} />
                                                    <ListItemText primary={res?.name} sx={{ flex: '0 0 15%' }} />
                                                    <ListItemText primary={res?.status} sx={{ flex: '0 0 10%' }} />
                                                    <ListItemText primary={res?.completed_date ? ConvertDateTime(res?.completed_date) : ""} sx={{ flex: '0 0 30%' }} />
                                                    <ListItemText sx={{ flex: '0 0 10%', textAlign: "center" }}>
                                                        <Tooltip title="Mark as Incomplete">
                                                            <IconButton onClick={(e) => handleStatus(e, res?.task_id)} sx={{ p: 0 }}>
                                                                <UnpublishedIcon fontSize='small' color="error" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItemText>
                                                </ListItem>
                                            </Card>
                                        );
                                    })}
                                </List>)}
                        </CardContent>
                    </Card>
                </Box>
                <MyTaskPopup mode={mode} setMode={setMode} done={true} openPopup={openPopup} setOpenPopup={setOpenPopup} data={popupData} setData={setPopupData} />
                <ConfirmationDialog
                    title="Are you sure to Mark this as Incomplete?"
                    openDialog={openDialog}
                    closeDialog={setOpenDialog}
                    OkButtonText="Mark as Incomplete"
                    onSubmit={handleDeleteConfirm}
                />
            </Box>
        </>
    )
}

export default CompletedTask;