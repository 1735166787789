import React from 'react';
import UnauthorizedBase from '../../../Base/views/UnauthorizedBase';
import AtsCandidateForm from './AtsCandidateForm';

const AtsCandidatePortal = () => {
    return (
        <>
            <UnauthorizedBase>
                <AtsCandidateForm disable={true} />
            </UnauthorizedBase>
        </>
    );
}

export default AtsCandidatePortal;
