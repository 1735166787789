import React, { useState } from 'react';
import Popup from '../../../Base/views/Popup';
import { Box, Button, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect } from 'react';
import SelectableSearch from '../../../Base/views/SelectableSearch';
import CommonDatePicker from '../../../Base/views/CommonDatePicker';
import { AddAssignedTaskAction, UpdateAssignedTaskAction, UpdateMyTaskAction } from '../../../../../store/ATS/MyTask/Action';
import { useDispatch } from 'react-redux';
import FileUploader from '../ATSCandidate/FileUploader';
import { v4 as uuidv4 } from "uuid";
import { DownloadAttachment, convertBase64, getUserData } from '../../../Base/helper/baseFunctions';
import { GetAtsTaskHistoryHelper, GetAtsTaskUserHelper } from '../../helper/AtsTasksHelper';
import Avatar from 'react-avatar';
import TimeAgo from '../../../Base/views/TimeAgo';
import { GetHomeAction } from '../../../../../store/EmployeeDashboard/Action';

const MyTaskPopup = ({ mode, setMode, data, setData, my_task, assigned_task, done, openPopup, setOpenPopup, home }) => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const today = year + '-' + month.toString().padStart(2, '0') + '-' + day;

    const initialValues = {
        task: "",
        title: "",
        assigned_to: [],
        requested_by: [],
        due_date: today,
        priority: "Low",
        status: "Yet to Start",
        comments: "",
        proof: ""
    };

    const dispatch = useDispatch();
    const userData = getUserData();
    const [mytask, setMyTask] = useState(initialValues);
    const [taskHistory, setTaskHistory] = useState([]);
    const [errors, setErrors] = useState({});
    const [assignedto, setAssignedTo] = useState({ load: true, values: [] });
    const [files, setFiles] = useState([]);
    const [fileUrl, setFileUrl] = useState("");
    const disabled = my_task && mode === "Add" ? false : done && mode === "Add" ? false : assigned_task && ["Add", "Edit"].includes(mode) ? false : true

    useEffect(() => {
        if (data) {
            if (data?.proof !== null || "") setFileUrl(data?.proof)
            else setFileUrl("")
            setMyTask({
                ...mytask,
                ...data,
                assigned_to: data.assigned_to ? [{ ats_users_id: data?.assigned_to, first_name: data?.name }] : []
            });
        } else {
            setMyTask(initialValues);
        }
        if(userData){
        GetAtsTaskHistoryHelper({ is_my_task: my_task ? true : false, task_id: data?.task_id || null }).then((res) => {
            if (res?.data.statuscode === 200) {
                setTaskHistory(res?.data?.data)
            }
        })
    }
        //eslint-disable-next-line
    }, [data]);


    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("task" in fieldValues) {
            temp.task = fieldValues.task === "" ? "Task is required" : "";
        }
        if ("title" in fieldValues) {
            temp.title = fieldValues.title === "" ? "Title is required" : "";
        }
        if ("assigned_to" in fieldValues) {
            temp.assigned_to = fieldValues.assigned_to?.length === 0 ? "Assign to is required" : "";
        }
        if ("due_date" in fieldValues) {
            temp.due_date = fieldValues.due_date === "" ? "Due Date to is required" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleAssignedToLoading = () => {
        GetAtsTaskUserHelper().then((res) => {
            if (res?.data?.statuscode === 200) {
                let data = res?.data?.data?.filter(item => item.status === 'Active' && item.ats_users_id !== userData.ats_user_id);
                const selfOption = {
                    ats_users_id: userData?.ats_user_id.toString(),
                    first_name: 'Self'
                };
                setAssignedTo({ load: false, values: [selfOption, ...data] });
            } else {
                setAssignedTo({ load: false, values: [] });
            }
        });
    };

    const handleInputChange = async (event, value) => {
        if (['assigned_to', 'requested_by'].includes(event)) {
            if (value && Array.isArray(value)) {
                const hasSelf = value.some((item) => item.first_name === 'Self');
                setMyTask({
                    ...mytask,
                    [event]: value,
                    requested_by: hasSelf ? value.find((item) => item.first_name === 'Self') : null
                });
            } else {
                setMyTask({ ...mytask, [event]: value, requested_by: value });
            }
            Object.values?.(errors)?.find((res) => res !== '')?.length > 0 &&
                validate({ ...mytask, assigned_to: value ? value : [] });
        } else {
            setMyTask({ ...mytask, [event.target.name]: event.target.value });
            Object.values?.(errors)?.find((res) => res !== '')?.length > 0 &&
            validate({ ...mytask, [event.target.name]: event.target.value });
        }

        
    };

    const handleSubmit = async () => {
        if (validate(mytask)) {
            if (!data?.task_id) {
                dispatch(AddAssignedTaskAction({
                    assigned_to: `{${mytask.assigned_to?.map(row => row.ats_users_id)}}`,
                    requested_by: mytask?.requested_by?.ats_users_id,
                    task: mytask.task,
                    title: mytask?.title,
                    status: mytask.status,
                    due_date: mytask.due_date,
                    priority: mytask.priority,
                }))
            }
            else {
                let b64string = "";
                let filename = "";
                let content_type = ""
                if (files?.[0]) {
                    b64string = await convertBase64(files?.[0]);
                    let ext = files?.[0]?.name?.split(".").at(-1);
                    content_type = ext === "pdf" ? "application/pdf" : ["png", "JPEG", "jpg"]?.includes(ext) ? "image/jpeg" : "application/msword"
                    filename = `${userData?.domain}/ATS/Task/` + uuidv4() + "." + ext;
                } else {
                    filename = mytask?.resume;
                }
                let functionCall = my_task ? UpdateMyTaskAction : UpdateAssignedTaskAction;
                dispatch(functionCall({
                    assigned_to: mytask.assigned_to?.[0]?.ats_users_id,
                    title: mytask?.title,
                    task: mytask.task,
                    task_id: data?.task_id,
                    status: mytask.status,
                    due_date: mytask.due_date,
                    priority: mytask.priority,
                    proof: b64string || null,
                    proof_name: filename || null,
                    content_type: content_type,
                    comments: mytask.comments,
                    completed_date: mytask.status === "Completed" ? date.toISOString() : null,
                    remainder_date: mytask?.remainder_date || null,
                    is_my_task: my_task ? true : false
                }))
            }
            handleCancel();
            home && dispatch(GetHomeAction());
        }

    }

    const handleCancel = () => {
        setMyTask(initialValues)
        setErrors({})
        setFiles([])
        setFileUrl('')
        setData([])
        setMode("")
        setOpenPopup(false);
    }

    return (
        <>
            <Popup
                title={mytask?.task_id ? `Edit ${mytask?.title}` : `Add Task`}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                close={false}
                maxWidth="sm"
                fullWidth={true}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid container spacing={2} component={"span"}>
                        <>
                            <Grid item xs={12} md={12}>
                                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={3} md={3}>
                                        <Typography component={'span'}>Assign To</Typography>
                                    </Grid>
                                    <Grid item xs={3} md={9}>
                                        <FormControl fullWidth>
                                            <SelectableSearch
                                                isOptionEqualToValue={(option, value) => +option.ats_users_id === +value.ats_users_id}
                                                required={true}
                                                multiple={true}
                                                label="Assign To"
                                                value={mytask.assigned_to}
                                                disabled={disabled}
                                                loading={() => handleAssignedToLoading()}
                                                fieldLabel="first_name"
                                                variant={"contained"}
                                                name="assigned_to"
                                                size="small"
                                                data={assignedto}
                                                onChangeEvent={(event, value = []) => handleInputChange(event, value)}
                                                {...(errors.assigned_to && {
                                                    error: true,
                                                    helperText: errors.assigned_to,
                                                })}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {mytask?.assigned_to && mytask.assigned_to.some((assigned) => assigned.first_name === 'Self') && !(done && mode === "Edit") && (
                                <Grid item xs={12} md={12}>
                                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={3} md={3}>
                                            <Typography component={'span'}>Requested by</Typography>
                                        </Grid>
                                        <Grid item xs={3} md={9}>
                                            <FormControl fullWidth>
                                                <SelectableSearch
                                                    isOptionEqualToValue={(option, value) => +option.ats_users_id === +value.ats_users_id}
                                                    label="Requested By"
                                                    value={mytask?.requested_by}
                                                    disabled={disabled}
                                                    loading={() => handleAssignedToLoading()}
                                                    fieldLabel="first_name"
                                                    variant={"contained"}
                                                    name="requested_by"
                                                    size="small"
                                                    data={assignedto}
                                                    onChangeEvent={(event, value) => handleInputChange(event, value)}
                                                    {...(errors.assigned_to && {
                                                        error: true,
                                                        helperText: errors.assigned_to,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12}>
                                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={3} md={3}>
                                        <Typography component={'span'}>Due Date</Typography>
                                    </Grid>
                                    <Grid item xs={9} md={9}>
                                        <FormControl fullWidth>
                                            <CommonDatePicker
                                                name="due_date"
                                                label="Due Date"
                                                size={"small"}
                                                pickerFormat="dd/MM/yyyy"
                                                pickerType="date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                onKeyPress={(e) => e.preventDefault()}
                                                disabled={disabled}
                                                DefaultDTvalue={mytask?.due_date}
                                                handleChange={(value) =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'due_date',
                                                            value: value ? `${value?.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}` : '',
                                                        },
                                                    })
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={3} md={3}>
                                        <Typography component={'span'}>Title</Typography>
                                    </Grid>
                                    <Grid item xs={9} md={9}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoFocus={true}
                                                required
                                                size="small"
                                                value={mytask.title}
                                                disabled={disabled}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                fullWidth
                                                id="title"
                                                label="Title"
                                                name="title"
                                                {...(errors.title && {
                                                    error: true,
                                                    helperText: errors.title,
                                                })}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={3} md={3}>
                                        <Typography component={'span'}>Description</Typography>
                                    </Grid>
                                    <Grid item xs={9} md={9}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoFocus={true}
                                                required
                                                multiline
                                                rows={4}
                                                size="small"
                                                value={mytask.task}
                                                disabled={disabled}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                fullWidth
                                                id="task"
                                                label="Task"
                                                name="task"
                                                {...(errors.task && {
                                                    error: true,
                                                    helperText: errors.task,
                                                })}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={3} md={3}>
                                        <Typography component={'span'}>Priority</Typography>
                                    </Grid>
                                    <Grid item xs={9} md={9}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="recruiter-type-select-label">Priority</InputLabel>
                                            <Select
                                                name="priority"
                                                labelId="recruiter-type-select-label"
                                                id="recruiter-type-select"
                                                value={mytask.priority}
                                                disabled={disabled}
                                                label="Priority"
                                                onChange={handleInputChange}
                                            >
                                                <MenuItem value={'Low'}>Low</MenuItem>
                                                <MenuItem value={'Medium'}>Medium</MenuItem>
                                                <MenuItem value={'High'}>High</MenuItem>
                                            </Select>
                                            <FormHelperText></FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={3} md={3}>
                                        <Typography component={'span'}>Status</Typography>
                                    </Grid>
                                    <Grid item xs={9} md={9}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="status">Status</InputLabel>
                                            <Select
                                                name="status"
                                                labelId="status"
                                                id="status"
                                                value={mytask.status}
                                                disabled={done && mode === "Add" ? false : my_task && ["Add", "Edit"].includes(mode) ? false : disabled}
                                                label="Status"
                                                onChange={handleInputChange}
                                            >
                                                <MenuItem value={'Yet to Start'}>Yet to Start</MenuItem>
                                                <MenuItem value={'Started'}>Started</MenuItem>
                                                <MenuItem value={'Completed'}>Completed</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        {!assigned_task && !(my_task && mode === "Add") && !(done && mode === "Add") && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={3} md={3}>
                                            <Typography component={'span'}>Proof</Typography>
                                        </Grid>
                                        <Grid item xs={9} md={9} border={!done && fileUrl === "" && "0.1px solid #e3e3e3"} borderRadius="10px">
                                            {done ? (
                                                <>
                                                    {mytask?.proof ? (
                                                        <Typography sx={{ color: "#1155cc", cursor: "pointer" }} onClick={() => DownloadAttachment(mytask?.proof)}>
                                                            {mytask.title}.{mytask?.proof.split(".").at(-1)}
                                                        </Typography>
                                                    ) : (
                                                        <Typography sx={{ fontStyle: "italic" }}>NA</Typography>
                                                    )}
                                                </>
                                            ) : (
                                                <FileUploader
                                                    fileUrl={fileUrl}
                                                    setFileUrl={setFileUrl}
                                                    task={true}
                                                    files={files}
                                                    setFiles={setFiles}
                                                    filename={'Proof'}
                                                    filepath={mytask?.proof}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid container sx={{ alignItems: "center" }}>
                                        <Typography component={'span'} sx={{ mb: 1 }}>Comments</Typography>
                                        <FormControl fullWidth>
                                            <TextField
                                                multiline
                                                rows={4}
                                                size="small"
                                                value={mytask.comments}
                                                disabled={done && mode === "Add" ? false : my_task && ["Add", "Edit"].includes(mode) ? false : disabled}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                fullWidth
                                                id="comments"
                                                name="comments"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                            <Button onClick={handleCancel} sx={{ ':hover': { color: grey[50] }, marginTop: '1.75rem', marginRight: '1rem' }} variant="outlined" className="mpl-secondary-btn">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: '1.75rem', marginRight: '1rem' }}>
                                {mytask?.task_id ? 'Update' : 'Save'}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {taskHistory?.map((hist, i) => {
                                let date = new Date(hist?.modifiedon)
                                date.setHours(date.getHours() + 5);
                                date.setMinutes(date.getMinutes() + 30);
                                return (
                                    <React.Fragment key={i}>
                                        <Divider sx={{ mb: 2 }} />
                                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ display: "flex", flexDirection: "row", marginBottom: 4 }}>
                                                <Avatar name={`${hist?.name || ""}`} round="50%" size='30' style={{ padding: "0px", fontSize: "10px", marginBottom: 2 }} />
                                                <div>
                                                    <Typography sx={{ paddingLeft: 2 }} variant='body1'><span style={{ fontWeight: 600 }}>{hist?.name}</span>  {hist?.modifiedon && <TimeAgo timeStyle="round" date={date} locale="en-US" />}</Typography>
                                                    {hist?.action && <Typography sx={{ paddingLeft: 2 }} variant='body2'>{hist?.action === "add" && "Task has been Created"} </Typography>}
                                                    {/* {hist?.name && <Typography sx={{ paddingLeft: 2 }} variant='body2'>{my_task ? "Task has been Assigned By" : "Task has been Assigned to"} <span style={{ fontWeight: 600 }}>{hist?.name || "None"}</span></Typography>} */}
                                                    {(hist?.priority !== hist?.previous_priority) && <Typography sx={{ paddingLeft: 2 }} variant='body2'> Priority has been Changed to <span style={{ fontWeight: 600 }}>{hist?.priority || "None"}</span></Typography>}
                                                    {(hist?.status !== hist?.previous_status) && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Status  has been Changed to <span style={{ fontWeight: 600 }}>{hist?.status || "None"}</span></Typography>}
                                                    {hist?.comment && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Added comment <span style={{ fontWeight: 600 }}>{hist?.comment}</span></Typography>}
                                                    {hist?.proof && (
                                                        <Typography sx={{ paddingLeft: 2 }} variant='body2'>
                                                            Added Proof{" "}
                                                            <span style={{ fontWeight: 600 }}>
                                                                {hist?.proof !== null && (
                                                                    <span style={{ color: "#1155cc", cursor: "pointer", fontVariant: "inherit" }} onClick={() => DownloadAttachment(hist.proof)}>
                                                                        {hist.title}.{hist?.proof.split(".").at(-1)}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </Typography>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Box>
            </Popup >
        </>
    );
};

export default MyTaskPopup;