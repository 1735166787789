
export const MEEPL_ADMIN_GET_REQUEST = "MEEPL_ADMIN_GET_REQUEST";
export const MEEPL_ADMIN_GET_SUCCESS = "MEEPL_ADMIN_GET_SUCCESS";
export const MEEPL_ADMIN_GET_ERROR = "MEEPL_ADMIN_GET_ERROR";

export const CREATED_SCHEMAS_GET_REQUEST = "CREATED_SCHEMAS_GET_REQUEST";
export const CREATED_SCHEMAS_GET_SUCCESS = "CREATED_SCHEMAS_GET_SUCCESS";
export const CREATED_SCHEMAS_GET_ERROR = "CREATED_SCHEMAS_GET_ERROR";

export const ENABLE_WORKSPACE_REQUEST = "ENABLE_WORKSPACE_REQUEST";
export const ENABLE_WORKSPACE_SUCCESS = "ENABLE_WORKSPACE_SUCCESS";
export const ENABLE_WORKSPACE_ERROR = "ENABLE_WORKSPACE_ERROR";

export const DISABLE_WORKSPACE_REQUEST = "DISABLE_WORKSPACE_REQUEST";
export const DISABLE_WORKSPACE_SUCCESS = "DISABLE_WORKSPACE_SUCCESS";
export const DISABLE_WORKSPACE_ERROR = "DISABLE_WORKSPACE_ERROR";

export const USER_ARCHIVE_REQUEST = "USER_ARCHIVE_REQUEST";
export const USER_ARCHIVE_GET_SUCCESS = "USER_ARCHIVE_GET_SUCCESS";
export const USER_ARCHIVE_GET_ERROR = "USER_ARCHIVE_GET_ERROR";

export const MEEPL_ADMIN_GET_USER_REQUEST = "MEEPL_ADMIN_GET_USER_REQUEST";
export const MEEPL_ADMIN_GET_USER_SUCCESS = "MEEPL_ADMIN_GET_USER_SUCCESS";
export const MEEPL_ADMIN_GET_USER_ERROR = "MEEPL_ADMIN_GET_USER_ERROR";

export const MEEPL_ADMIN_ADD_USER_REQUEST = "MEEPL_ADMIN_ADD_USER_REQUEST";
export const MEEPL_ADMIN_ADD_USER_SUCCESS = "MEEPL_ADMIN_ADD_USER_SUCCESS";
export const MEEPL_ADMIN_ADD_USER_ERROR = "MEEPL_ADMIN_ADD_USER_ERROR";

export const MEEPL_ADMIN_UPDATE_USER_REQUEST = "MEEPL_ADMIN_UPDATE_USER_REQUEST";
export const MEEPL_ADMIN_UPDATE_USER_SUCCESS = "MEEPL_ADMIN_UPDATE_USER_SUCCESS";
export const MEEPL_ADMIN_UPDATE_USER_ERROR = "MEEPL_ADMIN_UPDATE_USER_ERROR";


export const GET_WORKSPACE_EXPIRY_REQUEST = "GET_WORKSPACE_EXPIRY_REQUEST";
export const GET_WORKSPACE_EXPIRY_SUCCESS = "GET_WORKSPACE_EXPIRY_SUCCESS";
export const GET_WORKSPACE_EXPIRY_ERROR = "GET_WORKSPACE_EXPIRY_ERROR";

export const ADMIN_REQUEST = "ADMIN_REQUEST";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";
export const ADMIN_ERROR = "ADMIN_ERROR";

export const UPDATE_WORKSPACE_EXPIRE_REQUEST = "UPDATE_WORKSPACE_EXPIRE_REQUEST";
export const UPDATE_WORKSPACE_EXPIRE_SUCCESS = "UPDATE_WORKSPACE_EXPIRE_SUCCESS";
export const UPDATE_WORKSPACE_EXPIRE_ERROR = "UPDATE_WORKSPACE_EXPIRE_ERROR";

export const UPDATE_PLAN_REQUEST = "UPDATE_PLAN_REQUEST";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_ERROR = "UPDATE_PLAN_ERROR";

export const ADD_REQUEST = "ADD_REQUEST";
export const ADD_SUCCESS = "ADD_SUCCESS";
export const ADD_ERROR = "ADD_ERROR";

export const GET_REQUEST = "GET_REQUEST";
export const GET_SUCCESS = "GET_SUCCESS";
export const GET_ERROR = "GET_ERROR";