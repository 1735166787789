import { combineReducers } from "redux";
import ModuleReducer from "./Module/Reducer";
import PermissionReducer from "./Permission/Reducer";
import RoleReducer from "./Role/Reducer";
import RoleAccessReducer from "./RoleAccess/Reducer";
import SubModuleReducer from "./SubModule/Reducer";
import ATSModuleReducer from "./ATSModule/Reducer";
import ATSModuleFeaturesReducer from "./ATSModuleFeatures/Reducer";

const RoleAndAccessReducer = combineReducers({
    ModuleReducer,
    PermissionReducer,
    RoleReducer,
    RoleAccessReducer,
    SubModuleReducer,
    ATSModuleReducer,
    ATSModuleFeaturesReducer
});

export default RoleAndAccessReducer;