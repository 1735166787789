import {
  Box,
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Divider,
  InputBase,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  AddAtsCandidateAction,
  ProposeCandidateConfirmationAction,
} from "../../../../../store/ATS/Candidate/Action";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import { createStyles, makeStyles } from "@mui/styles";
import FileUploader from "./FileUploader";
import Popup from "../../../Base/views/Popup";
import { grey } from "@mui/material/colors";
import ProposeCandidateForm from "./ProposeCandidateForm";
import { useParams } from "react-router";
import history from "../../../../../history";
import {
  DeleteEmailTokenHelper,
  GetEmailTokenHelper,
} from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import {
  DeleteCandidateCertificateHelper,
  UpdateAtsCandidateHelper,
} from "../../helper/AtsCandidateHelper";
import ProposedJobHistory from "./ProposedJobHistory";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { v4 as uuidv4 } from "uuid";
import {
  AtsEmailHelper,
  sendAddCandidateEmail,
} from "../../helper/ATSEmailHeper";
import { AddEmailTokenHelper } from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import {
  convertBase64,
  getUserData,
  s3Client,
} from "../../../Base/helper/baseFunctions";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { toast } from "react-toastify";
import {
  AddAtsCandidateGeneralHistoryHelper,
  GetAtsCandidateActivityHistoryHelper,
  GetAtsCandidateGeneralHistoryHelper,
} from "../../helper/AtsCandidateJobHistoryHelper";
import { GetProofTypeHelper } from "../../../Ancillary/ProofType/helper/ProofTypeHelper";
import CanditateFormSuccess from "./CandidateFormSuccess";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { renderEmail } from "react-html-email";
import BaseEmailTemplate from "../../../Base/views/BaseEmailTemplate";
import UpdateCandidateEmail from "./UpdateCandidateEmail";
import { APP_NAME } from "../../../../Constants/baseConstant";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TimelineCenter from "./TimeLine";
import {
  DeleteExternalCandidateCertificateHelper,
  UpdateAtsExternalCandidateHelper,
} from "../../helper/AtsExternalCandidateHelper";
import ArrowBackButton from "../../../Base/views/ArrowBackButton";
import { AddAtsJobKanbanHelper } from "../../helper/AtsJobKanbanHelper";

const AtsCandidateForm = ({
  featuresData,
  setCurrentMode,
  currentMode,
  currentData,
  setCurrentData,
  setCandidatevalueData,
  candidatePreview,
  setCandidatePreview,
  setCandidateUpdate,
  disable,
  handleValidatePopup,
  toggleDrawer,
  isProfile,
  editedRecord,
  filter,
  setShowForm,
  ai,
  currentJobData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { guid } = useParams();
  const [files, setFiles] = useState([]);
  const [proofData, setProofData] = useState({ load: true, values: [] });
  const [viewMode, setViewMode] = useState("atsform");
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var stage_id = currentData?.ats_job_stages_id;
  const userData = getUserData();
  const initialFValues = {
    first_name: "",
    last_name: "",
    contact_no: "",
    alternative_mobile: "",
    email_id: "",
    dob: "",
    designation: "",
    years_of_experience: "",
    current_ctc: "",
    expected_pay: "",
    current_employer: "",
    notice_period: "",
    resume: "",
    gender: "",
    primary_skill: "",
    secondary_skill: "",
    status: "Active",
    identity_id: "",
    proof_number: "",
    location: "",
    uan_number: "",
    certficate_details: "",
    github_profile: "",
    linkedin_profile: "",
    notes: "",
  };

  const certficationInitValue = {
    certificate_name: "",
    certificate_link: "",
  };

  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "15px",
        borderRadius: "10px",
      },
    })
  );
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [candidateData, setCandidateData] = useState(initialFValues);
  const [candidateEditedData, setCandidateEditedData] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [activityHistory, setActivityHistory] = useState([]);
  const [generalHistory, setGeneralHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [certificateData, setCertificateData] = useState([]);
  const [proofErrors, setProofErrors] = useState([]);
  const [disabledCertificate, setDisabledCertificate] = useState(false);
  useEffect(() => {
    const allFieldsEmpty = certificateData?.every(
      (certificate) =>
        certificate?.certificate_name !== "" &&
        certificate?.certificate_link !== ""
    );
    setDisabledCertificate(!allFieldsEmpty);
  }, [certificateData]);

  useEffect(() => {
  }, []);
  const handleClickNotes = () => {
    setOpenNotes(true);
  };

  const SubmitNote = () => {
    if (candidateData?.candidate_id) {
      HandleSubmit();
    }
    setOpenNotes(false);
  };

  const handleDisabled = (permission) => {
    const foundPermission = featuresData?.find(
      (item) => item.permission === permission
    );
    return foundPermission ? true : false;
  };
  var disabled =
    isProfile || ai || handleDisabled("candidate_update_candidate");
  const handleClickPropose = () => {
    if (ai) {
      AddAtsJobKanbanHelper({
        kanban_data: JSON.stringify([
          {
            proposed_by:userData?.ats_user_id,
            candidate_id: currentData?.candidate_id,
            ats_jobpost_id: currentJobData?.ats_jobpost_id,
            comment: null,
            ats_job_stages_id: null,
            priority: 5,
            ats_status_id: null,
          },
        ]),
      }).then((res) => {
        if (res?.data?.statuscode === 200) {
          dispatch(
            ProposeCandidateConfirmationAction({
              candidate_data: currentData,
              job_data: currentJobData,
              kanban_data:res?.data?.data?.[0]
            })
          );
        }
      });
    } else setOpenPopup(true);
  };

  useEffect(() => {
    setTimeout(() => {
      if (guid) {
        GetEmailTokenHelper({
          domain: guid?.split("=")?.[1],
          token: guid,
        }).then((res) => {
          if (res?.data?.data?.[0]?.email_tokens_id) {
            let expiry_time =
              Date.parse(res?.data?.data?.[0]?.createdon) + 259500000;
            if (expiry_time < Date.parse(new Date())) {
              history.push("/");
            } else {
              setCandidateData({
                ...res?.data?.data?.[0]?.json_data?.[0],
                domain: guid?.split("=")?.[1],
              });
              setCandidateEditedData({
                ...res?.data?.data?.[0]?.json_data?.[0],
                domain: guid?.split("=")?.[1],
              });
              if (currentData?.certificates !== null) {
                setCertificateData(res?.data?.data?.[0]?.json_data?.[0]?.certificates);
              } else setCertificateData([]);
             
              res?.data?.data?.[0]?.json_data?.[0]?.resume && getResumeSignedUrl(res?.data?.data?.[0]?.json_data?.[0]?.resume);
            }
          } else {
            history.push("/");
          }
        });
      }
    }, 1000);
    if (currentData != null) {
      if (currentData?.certificates !== null) {
        setCertificateData(currentData?.certificates);
      } else setCertificateData([]);
      setCandidateData({
        ...currentData,
        contact_no: currentData?.contact_no
          ? currentData?.contact_no?.toString()
          : "",
        identity_id: currentData?.proof_id
          ? {
              identity_id: currentData?.proof_id,
              name: currentData?.proof_type,
            }
          : "",
        years_of_experience: currentData?.years_of_experience
          ? currentData?.years_of_experience
          : "",
        current_ctc: currentData?.current_ctc ? currentData?.current_ctc : "",
        expected_pay: currentData?.expected_pay
          ? currentData?.expected_pay
          : "",
        notice_period: currentData?.notice_period
          ? currentData?.notice_period
          : "",
        proof_number: currentData?.proof_number
          ? currentData?.proof_number
          : "",
      });
      setCandidateEditedData({
        ...currentData,
        contact_no: currentData?.contact_no
          ? currentData?.contact_no?.toString()
          : "",
        identity_id: currentData?.proof_id
          ? {
              identity_id: currentData?.proof_id,
              name: currentData?.proof_type,
            }
          : "",
        years_of_experience: currentData?.years_of_experience
          ? currentData?.years_of_experience
          : "",
        current_ctc: currentData?.current_ctc ? currentData?.current_ctc : "",
        expected_pay: currentData?.expected_pay
          ? currentData?.expected_pay
          : "",
        notice_period: currentData?.notice_period
          ? currentData?.notice_period
          : "",
        proof_number: currentData?.proof_number
          ? currentData?.proof_number
          : "",
      });
      currentData?.resume && getResumeSignedUrl();

      if (currentData?.candidate_id) {
        if (currentData?.isverified) {
          GetAtsCandidateActivityHistoryHelper({
            candidate_id: currentData?.candidate_id,
          }).then((res) => {
            res?.data?.data?.length > 0 && setActivityHistory(res?.data?.data);
            setLoading(false);
          });
        }
        GetAtsCandidateGeneralHistoryHelper({
          candidate_id: currentData?.candidate_id,
        }).then((res) => {
          setGeneralHistory(res?.data?.data?.[0]?.candidatehist || []);
          setLoading(false);
        });
      }
    }
    //eslint-disable-next-line
  }, [currentData]);

  const getResumeSignedUrl = async (resume) => {
    let filename = currentData?.resume || resume;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setFileUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };

  const handleDownloadResume = async (event, data) => {
    event.stopPropagation();
    let filename = data?.resume;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      window.open(url, "_blank");
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name =
        fieldValues.first_name?.trim() === "" ? "First Name is required" : "";
    }
    if ("contact_no" in fieldValues) {
      temp.contact_no =
        fieldValues.contact_no === "" || fieldValues.contact_no === null
          ? "Mobile No is required"
          : fieldValues?.contact_no.toString() !== "" &&
            !fieldValues?.contact_no
              ?.toString()
              ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
          ? "Invalid Mobile Number."
          : "";
    }
    if ("uan_number" in fieldValues) {
      temp.uan_number =
        fieldValues.uan_number === "" || fieldValues.uan_number === null
          ? ""
          : fieldValues?.uan_number.toString() !== "" &&
            !fieldValues?.uan_number?.toString()?.match(/^\(?[0-9]{12}$/)
          ? "Invalid UAN Number."
          : "";
    }
    if ("alternative_mobile" in fieldValues) {
      temp.alternative_mobile =
        fieldValues.alternative_mobile === "" ||
        fieldValues.alternative_mobile === null
          ? ""
          : fieldValues?.alternative_mobile.toString() !== "" &&
            !fieldValues?.alternative_mobile
              ?.toString()
              ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
          ? "Invalid Mobile Number."
          : "";
    }
    if ("email_id" in fieldValues) {
      temp.email_id =
        fieldValues.email_id === ""
          ? "Email is required"
          : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              fieldValues.email_id
            )
          ? ""
          : "Invalid Email.";
    }
    
    if ('proof_number' in fieldValues) {
      const proofType = candidateData?.identity_id;
      const proof_number = fieldValues.proof_number;
      temp.proof_number =
          fieldValues?.identity_id !== "" && fieldValues.proof_number === ""? "Proof Number is required" : "";

      if (proofType?.identity_length) {
          const length = proofType?.identity_length;
          const isAlphaNumeric = /^[a-zA-Z0-9]+$/.test(proof_number);
          temp.proof_number = !isAlphaNumeric ? "Proof Must be alphanumeric" : proof_number.length !== length ? `Proof Must be ${length} characters` : "";
      }
  }
    setErrors({
      ...temp,
      proof_number: temp.proof_number === false ? "" : temp.proof_number,
    });

    const temp1 = {
      ...temp,
      proof_number: temp.proof_number === false ? "" : temp.proof_number,
    };
    return Object.values(temp1).every((x) => x === "");
  };

  const handleInputChange = (event, value) => {
    if (["identity_id"].includes(event)) {
      if (value)
        setCandidateData({
          ...candidateData,
          [event]: value,
          proof_number: "",
        });
      else
        setCandidateData({ ...candidateData, [event]: "", proof_number: "" });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...candidateData, [event]: value ? value : "" });
      setErrors({ ...errors, proof_number: "" });
    }   
    else {
      setCandidateData({
        ...candidateData,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...candidateData, [event.target.name]: event.target.value });
    }
  };
  const Update = (b64string, filename, old_resume_name, message) => {
    let certfication_details = [];
    for (let i = 0; i < certificateData?.length; i++) {
      const certificate = certificateData[i];
      const certObject = {
        certificate_name: certificate?.certificate_name,
        certificate_link: certificate?.certificate_link,
      };

      if (
        certificate?.certificate_id !== undefined &&
        certificate?.certificate_id !== null
      ) {
        certObject.certificate_id = certificate.certificate_id;
      } else {
        certObject.certificate_id = null;
      }

      certfication_details.push(certObject);
    }

    UpdateAtsCandidateHelper({
      ...candidateData,
      domain: guid?.split("=")?.[1],
      resume: b64string,
      resume_name: filename,
      old_resume_name: old_resume_name,
      message: message,
      certificate_details: `${JSON.stringify(certfication_details)}`,
    }).then((res) => {
      if (res.data.statuscode === 200) {
        let date = new Date();
        if (filter) setShowForm(false);
        else setViewMode("success");
        DeleteEmailTokenHelper({
          domain: guid?.split("=")?.[1],
          token: guid,
        });
        AtsEmailHelper({
          to: candidateData?.email_id,
          subject: "Form Response",
          content: renderEmail(
            <BaseEmailTemplate>
              <UpdateCandidateEmail proposeCandidateData={candidateData} />
            </BaseEmailTemplate>
          ),
        });
        AddAtsCandidateGeneralHistoryHelper({
          domain: guid?.split("=")?.[1],
          user_empid: candidateData?.candidate_id,
          candidate_id: candidateData?.candidate_id,
          form_submitted_date: `${
            date?.getFullYear() +
            "-" +
            ("0" + (date?.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date?.getDate()).slice(-2)
          }`,
        });
      } else toast.error(res?.data?.error?.[0]?.message);
    });
  };

  const HandleSubmit = async (e, message) => {
    e?.preventDefault();
    if (proofvalidate() && validate(candidateData)) {
      let b64string = "";
      let filename = "";
      let old_resume_name = "";
      let certfication_details = [];
      let content_type = "";
      for (let i = 0; i < certificateData?.length; i++) {
        certfication_details.push({
          certificate_name: certificateData[i]?.certificate_name,
          certificate_link: certificateData[i]?.certificate_link,
        });
      }
      if (files?.[0]) {
        b64string = await convertBase64(files?.[0]);
        let ext = files?.[0]?.name?.split(".").at(-1);
        content_type = ext === "pdf" ? "application/pdf" : "application/msword";
        filename = `${userData?.domain}/ATS/Resumes/` + uuidv4() + "." + ext;
        if (candidateData?.resume) {
          old_resume_name = candidateData?.resume;
        }
      } else {
        filename = candidateData?.resume;
      }
      if (filter) {
        Update(b64string, filename, old_resume_name, message);
      }
      if (guid) {
        let certfication_details = [];
        for (let i = 0; i < certificateData?.length; i++) {
          const certificate = certificateData[i];
          const certObject = {
            certificate_name: certificate?.certificate_name,
            certificate_link: certificate?.certificate_link,
          };

          if (
            certificate?.certificate_id !== undefined &&
            certificate?.certificate_id !== null
          ) {
            certObject.certificate_id = certificate.certificate_id;
          } else {
            certObject.certificate_id = null;
          }

          certfication_details.push(certObject);
        }
       
        candidateData?.is_external
          ? UpdateAtsExternalCandidateHelper({
              ...candidateData,
              resume: b64string,
              resume_name: filename,
              old_resume_name: old_resume_name,
              message: message,
              content_type: content_type,
              certificate_details: `${JSON.stringify(certfication_details)}`,
            }).then((res) => {
              if (res.data.statuscode === 200) {
                let date = new Date();
                setViewMode("success");
                DeleteEmailTokenHelper({
                  domain: guid?.split("=")?.[1],
                  token: guid,
                });
                AtsEmailHelper({
                  to: candidateData?.email_id,
                  subject: "Form Response",
                  content: renderEmail(
                    <BaseEmailTemplate>
                      <UpdateCandidateEmail
                        proposeCandidateData={candidateData}
                      />
                    </BaseEmailTemplate>
                  ),
                });
                AddAtsCandidateGeneralHistoryHelper({
                  domain: guid?.split("=")?.[1],
                  user_empid: candidateData?.candidate_id,
                  candidate_id: candidateData?.candidate_id,
                  form_submitted_date: `${
                    date?.getFullYear() +
                    "-" +
                    ("0" + (date?.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + date?.getDate()).slice(-2)
                  }`,
                });
              } else toast.error(res?.data?.error?.[0]?.message);
            })
          : Update(b64string, filename, old_resume_name, message);
      } else {
        if (candidateData?.candidate_id) {
          let certfication_details = [];
          for (let i = 0; i < certificateData?.length; i++) {
            const certificate = certificateData[i];
            const certObject = {
              certificate_name: certificate?.certificate_name,
              certificate_link: certificate?.certificate_link,
            };

            if (
              certificate?.certificate_id !== undefined &&
              certificate?.certificate_id !== null
            ) {
              certObject.certificate_id = certificate.certificate_id;
            } else {
              certObject.certificate_id = null;
            }

            certfication_details.push(certObject);
          }
          if (candidateData?.resume && !fileUrl) {
            old_resume_name = candidateData?.resume;
            filename = filename ? filename : "";
          }
          currentData?.tabvalue === 1
            ? UpdateAtsExternalCandidateHelper({
                ...candidateData,
                resume: b64string,
                resume_name: filename,
                old_resume_name: old_resume_name,
                message: message,
                content_type: content_type,
                certificate_details: `${JSON.stringify(certfication_details)}`,
              })
            : UpdateAtsCandidateHelper({
                ...candidateData,
                resume: b64string,
                resume_name: filename,
                old_resume_name: old_resume_name,
                message: message,
                certificate_details: `${JSON.stringify(certfication_details)}`,
              })
                .then((res) => {
                  if (res?.data?.statuscode === 200) {
                    if (stage_id) {
                      setCandidatevalueData(res?.data?.data?.[0]);
                      setCandidateUpdate(true);
                      resetForm();
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
        } else if (currentData?.tabvalue === 1) {
          UpdateAtsExternalCandidateHelper({
            ...candidateData,
            resume: b64string,
            resume_name: filename,
            old_resume_name: old_resume_name,
            message: message,
            content_type: content_type,
            certificate_details: `${JSON.stringify(certfication_details)}`,
          });
        } else {
          dispatch(
            AddAtsCandidateAction({
              ...candidateData,
              resume: b64string,
              resume_name: filename,
              message: message,
              content_type: content_type,
              certificate_details: `${JSON.stringify(certfication_details)}`,
            })
          );
        }
        setTimeout(() => {
          handleCancel();
        }, 1000);
      }
    }
  };

  const handleCancel = () => {
    setCandidateData(initialFValues);
    setErrors({});
    if (currentMode === "edit" || currentMode === "add") {
      setCurrentMode("grid");
    }
    setOpenDialog(false);
  };

  const resetForm = async () => {
    if (filter) {
      setShowForm(false);
    }
    if (currentData?.ats_job_stages_id || isProfile) {
      if (isProfile) {
        setCandidatePreview(false);
      } else {
        setCandidatePreview(false);
        toggleDrawer(true);
      }
    } else {
      if (
        candidateData?.candidate_id &&
        JSON.stringify(candidateData) !== JSON.stringify(candidateEditedData)
      ) {
        setOpenDialog(true);
      } else handleCancel();
    }
  };

  const sendMail = (e) => {
    if (validate(candidateData)) {
      let date = new Date();
      let message = `Candidate ${
        candidateData?.candidate_id ? "Updated" : "Added"
      } and Email sent Successfully`;
      HandleSubmit(e, message);
      let token = uuidv4() + `=${userData?.domain}`;
      AddEmailTokenHelper({
        token: token,
        email_id: candidateData?.email_id,
        json_data: JSON.stringify([candidateData]),
      });
      sendAddCandidateEmail({
        guid: token,
        name: candidateData?.first_name,
        email_id: candidateData?.email_id,
        subject: "You are requested to fill this form",
      });
      AddAtsCandidateGeneralHistoryHelper({
        candidate_id: candidateData?.candidate_id,
        form_sent_date: `${
          date?.getFullYear() +
          "-" +
          ("0" + (date?.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date?.getDate()).slice(-2)
        }`,
      });
    }
  };

  const handleProofType = () => {
    GetProofTypeHelper({ domain: guid?.split("=")?.[1] }).then((res) => {
      if (res?.data?.statuscode === 200) {
        setProofData({ load: false, values: res?.data?.data });
      }
    });
  };

  const handleVerifyPopup = () => {
    if (validate(candidateData)) {
      setCurrentData(candidateData);
      handleValidatePopup();
      HandleSubmit();
    }
  };

  const proofvalidate = () => {
    let ret_value = [];
    certificateData?.forEach((store, index) => {
      const error = {};
      if (!store.certificate_name)
        error.certificate_name = "Certificate Name is required";
      if (!store.certificate_link)
        error.certificate_link = "Certificate Link is required";
      const newErrors = [...proofErrors];
      while (newErrors.length <= index) {
        newErrors.push({});
      }
      newErrors[index] = error;
      setProofErrors(newErrors);
      ret_value.push(Object.values(error).every((e) => e === ""));
    });
    return ret_value;
  };

  const handleAddPoof = () => {
    setCertificateData((state) => [...state, certficationInitValue]);
  };

  const handleProofDelete = (e, id, deleteid) => {
    let proofData = [...certificateData];
    if (deleteid) {
      if (currentData?.tabvalue === 1) {
        DeleteExternalCandidateCertificateHelper({
          certificate_id: deleteid,
        }).then((res) => {
          if (res?.data?.statuscode === 200) {
            proofData.splice(id, 1);
            setCertificateData(proofData);
          }
        });
      } else {
        DeleteCandidateCertificateHelper({ certificate_id: deleteid }).then(
          (res) => {
            if (res?.data?.statuscode === 200) {
              proofData.splice(id, 1);
              setCertificateData(proofData);
            }
          }
        );
      }
    } else {
      proofData.splice(id, 1);
      setCertificateData(proofData);
    }
  };

  const handleDocumentChange = (e, value, id) => {
    let newArr = [...certificateData];
    const newErrors = [...proofErrors];
    let item = newArr[id];
    item = { ...item, [e]: value };
    newArr[id] = item;
    newErrors[id] = { ...newErrors[id], [e]: "" };
    setCertificateData(newArr);
    setProofErrors(newErrors);
  };

  return (
    <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
      <ConfirmationDialog
        title="Cancel"
        content="Are you sure want to Cancel the Record?"
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Consultancy"
        OkButtonText="Yes"
        onSubmit={handleCancel}
      />
      <Popup
        title="Propose for a Job"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ProposeCandidateForm
          currentData={currentData}
          isProfile={isProfile}
          editedRecord={editedRecord}
          setOpenPopup={setOpenPopup}
          setCandidatePreview={setCandidatePreview}
        />
      </Popup>
      {viewMode === "atsform" && (
        <>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: 1000,
              display: "flex",
              justifyContent: "space-between",
              pl: 2,
              pt: 2,
              pr: 2,
            }}
          >
            {!disable && (
              <ArrowBackButton
                onClick={() => {
                  resetForm();
                  setCandidatePreview && setCandidatePreview(false);
                }}
              />
            )}
            <Typography sx={{fontFamily:"Nunito Sans",fontWeight:700,color:"#000000",fontSize:"large"}}>{currentMode === "add" ? "Create New Candidate" : candidatePreview || currentMode === "edit" ? "Update Candidate" : "Preview Candidate"}</Typography>

            {!guid && (
              <Box>
                {currentData?.candidate_id && currentData?.tabvalue === 1 ? (
                  <Button
                    className="mpl-primary-btn "
                    sx={{
                      ml: 1,
                      color: grey[50],
                      ":hover": {
                        backgroundColor: "#f9c307",
                        color: "#000000",
                      },
                    }}
                    variant="contained"
                    size="small"
                    disabled={handleDisabled("candidate_update_candidate")}
                    onClick={() => {
                      handleVerifyPopup();
                    }}
                  >
                    Update & Verify
                  </Button>
                ) : (
                  currentData?.candidate_id && (
                    <Button
                      className="mpl-primary-btn "
                      sx={{
                        ml: 1,
                        color: grey[50],
                        ":hover": {
                          backgroundColor: "#f9c307",
                          color: "#000000",
                        },
                      }}
                      variant="contained"
                      size="small"
                      disabled={handleDisabled("candidate_propose_candidate")}
                      onClick={handleClickPropose}
                    >
                      Propose
                    </Button>
                  )
                )}
                {currentData?.tabvalue !== 1 &&
                (ai || handleDisabled("candidate_update_candidate")) ? (
                  <Button
                    disabled={true}
                    onClick={HandleSubmit}
                    variant="contained"
                    type="submit"
                    className="mpl-primary-btn"
                    size="small"
                    sx={{
                      ml: 1,
                      color: grey[50],
                      ":hover": {
                        backgroundColor: "#f9c307",
                        color: "#000000",
                      },
                    }}
                  >
                    {currentData?.candidate_id
                      ? "Update Candidate"
                      : "Save Candidate"}
                  </Button>
                ) : (
                  currentData?.tabvalue !== 1 && (
                  <Button
                    disabled={
                      currentData?.candidate_id &&
                      JSON.stringify(candidateData) ===
                        JSON.stringify(candidateEditedData) &&
                      files?.length === 0 &&
                      certificateData?.length === 0
                    }
                    onClick={HandleSubmit}
                    variant="contained"
                    type="submit"
                    className="mpl-primary-btn"
                    size="small"
                    sx={{
                      ml: 1,
                      color: grey[50],
                      ":hover": {
                        backgroundColor: "#f9c307",
                        color: "#000000",
                      },
                    }}
                  >
                    {currentData?.candidate_id
                      ? "Update Candidate"
                      : "Save Candidate"}
                  </Button>
                  )
                )}
              </Box>
            )}
          </Box>
          {disable && (
            <Box
              sx={{
                position: "sticky",
                top: 0,
                background: "#fff",
                zIndex: 1000,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}>
                <Button
                  onClick={HandleSubmit}
                  variant="contained"
                  type="submit"
                  className="mpl-primary-btn"
                  size="small"
                  sx={{
                    ml: 1,
                    color: grey[50],
                    ":hover": {
                      backgroundColor: "#f9c307",
                      color: "#000000",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
          <Grid container>
            <Grid item xs={12} md={12} padding={1.5}>
              <Paper className={classes?.paper}>
                <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ paddingLeft: "15px" }}
                  >
                    Personal Info
                  </Typography>
                  {currentData?.is_verified !== false && (
                    <Tooltip title="Notes">
                      <CommentRoundedIcon
                        onClick={handleClickNotes}
                        sx={{
                          cursor: "pointer",
                          color: theme?.palette?.primary?.main,
                        }}
                        fontSize="small"
                      />
                    </Tooltip>
                  )}
                </Grid>

                <Grid container>
                  <Grid
                    key="first_name"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        autoFocus={true}
                        required
                        size="small"
                        value={candidateData.first_name}
                        onChange={handleInputChange}
                        variant="outlined"
                        disabled={disabled}
                        fullWidth
                        onKeyPress={(e) =>
                          e.target.value?.length >= 35 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 35 && e.preventDefault()
                        }
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        autoComplete="first_name"
                        {...(errors.first_name && {
                          error: true,
                          helperText: errors.first_name,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="last_name"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.last_name}
                        onChange={handleInputChange}
                        variant="outlined"
                        disabled={disabled}
                        fullWidth
                        id="last_name"
                        label="Last Name"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 35 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 35 && e.preventDefault()
                        }
                        name="last_name"
                        autoComplete="last_name"
                        {...(errors.last_name && {
                          error: true,
                          helperText: errors.last_name,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="contact_no"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        type={"number"}
                        required
                        value={candidateData.contact_no}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        label="Mobile No"
                        name="contact_no"
                        autoComplete="contact_no"
                        {...(errors.contact_no && {
                          error: true,
                          helperText: errors.contact_no,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="alternative_mobile"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        type={"number"}
                        size="small"
                        disabled={disabled || false}
                        value={candidateData.alternative_mobile}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="alternative_mobile"
                        label="Alternate Mobile No"
                        name="alternative_mobile"
                        autoComplete="alternative_mobile"
                        {...(errors.alternative_mobile && {
                          error: true,
                          helperText: errors.alternative_mobile,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="email_id"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        required
                        value={candidateData.email_id}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled ||currentData?.tabvalue !== 1 && currentMode !='add'  }
                        id="email_id"
                        label="Email"
                        name="email_id"
                        autoComplete="email_id"
                        {...(errors.email_id && {
                          error: true,
                          helperText: errors.email_id,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="dob"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <CommonDatePicker
                        size="small"
                        name="dob"
                        label="Date of Birth"
                        pickerType="date"
                        disabled={disabled}
                        onKeyDown={(e) => e.preventDefault()}
                        onKeyPress={(e) => e.preventDefault()}
                        pickerFormat="dd/MM/yyyy"
                        DefaultDTvalue={candidateData?.dob}
                        maxDate={new Date()}
                        handleChange={(value) =>
                          handleInputChange({
                            target: {
                              name: "dob",
                              value: value
                                ? `${
                                    value?.getFullYear() +
                                    "-" +
                                    ("0" + (value?.getMonth() + 1)).slice(-2) +
                                    "-" +
                                    ("0" + value?.getDate()).slice(-2)
                                  }`
                                : "",
                            },
                          })
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    key="identity_id"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <SelectableSearch
                        isOptionEqualToValue={(option, value) =>
                          +option.identity_id === +value.identity_id
                        }
                        label="Proof Type"
                        fullWidth
                        value={candidateData?.identity_id}
                        loading={() => handleProofType()}
                        fieldLabel="name"
                        required={false}
                        variant={"outlined"}
                        disabled={disabled}
                        name="identity_id"
                        size="small"
                        onChangeEvent={handleInputChange}
                        data={proofData}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="proof_number"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.proof_number}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="proof_number"
                        disabled={disabled || candidateData?.identity_id === ""}
                        label="Proof Number"
                        name="proof_number"
                        autoComplete="proof_number"
                        {...(errors.proof_number && {
                          error: true,
                          helperText: errors.proof_number,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="location"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                      name="location"
                      label="Location"
                      size="small"
                      disabled={disabled}
                      value={candidateData?.location}
                      onChange={handleInputChange}
                      onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    key="gender"
                    item
                    xs={12}
                    md={5}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl disabled={disabled}>
                      <FormLabel id="gender-label">Gender</FormLabel>
                      <RadioGroup
                        onChange={handleInputChange}
                        row
                        aria-labelledby="gender-label"
                        name="gender"
                        value={candidateData.gender}
                      >
                        <FormControlLabel
                          value="Male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="Female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="Transgender"
                          control={<Radio />}
                          label="Transgender"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} padding={1.5}>
              <Paper className={classes?.paper}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ paddingLeft: "15px" }}
                >
                  Professional Info
                </Typography>
                <Grid container>
                  <Grid
                    key="designation"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                      name="designation"
                      label="Designation"
                      size="small"
                      value={candidateData?.designation}
                      onChange={handleInputChange}
                      onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      fullWidth
                      disabled={disabled}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    key="years_of_experience"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.years_of_experience}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        id="years_of_experience"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 2 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 2 && e.preventDefault()
                        }
                        label="Year of Experience"
                        name="years_of_experience"
                        autoComplete="years_of_experience"
                        {...(errors.years_of_experience && {
                          error: true,
                          helperText: errors.years_of_experience,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="current_ctc"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.current_ctc}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        id="current_ctc"
                        label="Current CTC"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 10 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 10 && e.preventDefault()
                        }
                        name="current_ctc"
                        autoComplete="current_ctc"
                        {...(errors.current_ctc && {
                          error: true,
                          helperText: errors.current_ctc,
                        })}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    key="expected_pay"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.expected_pay}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        id="expected_pay"
                        label="Expected Pay"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 10 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 10 && e.preventDefault()
                        }
                        name="expected_pay"
                        autoComplete="expected_pay"
                        {...(errors.expected_pay && {
                          error: true,
                          helperText: errors.expected_pay,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="current_employer"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.current_employer}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        id="current_employer"
                        label="Current Employer"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 100 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 100 && e.preventDefault()
                        }
                        name="current_employer"
                        autoComplete="current_employer"
                        {...(errors.current_employer && {
                          error: true,
                          helperText: errors.current_employer,
                        })}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    key="notice_period"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        type={"number"}
                        size="small"
                        value={candidateData?.notice_period}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        disabled={disabled}
                        id="notice_period"
                        label="Notice Period"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 3 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 3 && e.preventDefault()
                        }
                        name="notice_period"
                        autoComplete="notice_period"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Days
                            </InputAdornment>
                          ),
                          maxLength: 4,
                        }}
                        {...(errors.notice_period && {
                          error: true,
                          helperText: errors.notice_period,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="uan_number"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                      name="uan_number"
                      label="UAN Number"
                      size="small"
                      type="number"
                      value={candidateData?.uan_number}
                      onChange={handleInputChange}
                      onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      fullWidth
                      disabled={disabled}
                      variant="outlined"
                      {...(errors.uan_number && {
                        error: true,
                        helperText: errors.uan_number,
                      })}
                    />
                  </Grid>

                  <Grid
                    key="git_hub"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                      name="github_profile"
                      label="Github Profile"
                      size="small"
                      value={candidateData?.github_profile}
                      onChange={handleInputChange}
                      onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      fullWidth
                      disabled={disabled}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    key="linkedin_profile"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                      name="linkedin_profile"
                      label="LinkedIn Profile"
                      size="small"
                      value={candidateData?.linkedin_profile}
                      onChange={handleInputChange}
                      onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      fullWidth
                      disabled={disabled}
                      variant="outlined"
                      placeholder="https://www.<linkedin_profile_url>"
                    />
                  </Grid>
                  <Grid
                    key="primary_skill"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData?.primary_skill}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        disabled={disabled}
                        id="primary_skill"
                        label="Skills"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 254 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 254 && e.preventDefault()
                        }
                        name="primary_skill"
                        autoComplete="primary_skill"
                        {...(errors.primary_skill && {
                          error: true,
                          helperText: errors.primary_skill,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="secondary_skill"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData?.secondary_skill}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        disabled={disabled}
                        id="secondary_skill"
                        label="Software & Tools"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 254 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 254 && e.preventDefault()
                        }
                        name="secondary_skill"
                        autoComplete="secondary_skill"
                        {...(errors.secondary_skill && {
                          error: true,
                          helperText: errors.secondary_skill,
                        })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} padding={1.5}>
              <Paper className={classes?.paper}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ paddingLeft: "15px" }}
                  >
                    Certifications
                  </Typography>
                  <Tooltip title="Add Certificate">
                    <IconButton
                      disabled={disabled || disabledCertificate}
                      sx={{ pt: 0 }}
                      onClick={handleAddPoof}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                {certificateData?.map((res, id) => {
                  return (
                    <>
                      <Grid pt={2.5} container spacing={2} padding={1.5}>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              value={res?.certificate_name || ""}
                              onChange={(e) =>
                                handleDocumentChange(
                                  e.target.name,
                                  e.target.value,
                                  id
                                )
                              }
                              variant="outlined"
                              fullWidth
                              required
                              disabled={disabled}
                              id="certificate_name"
                              label="Certificate Name"
                              name="certificate_name"
                              {...(proofErrors[id] &&
                                proofErrors[id].certificate_name && {
                                  error: true,
                                  helperText: proofErrors[id].certificate_name,
                                })}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              value={res?.certificate_link || ""}
                              onChange={(e) =>
                                handleDocumentChange(
                                  e.target.name,
                                  e.target.value,
                                  id
                                )
                              }
                              variant="outlined"
                              fullWidth
                              required
                              disabled={disabled}
                              id="certificate_link"
                              label="Certificate link"
                              name="certificate_link"
                              {...(proofErrors[id] &&
                                proofErrors[id].certificate_link && {
                                  error: true,
                                  helperText: proofErrors[id].certificate_link,
                                })}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              p: 1,
                              pt: 0,
                            }}
                          >
                            <Fab
                              id={res.id}
                              onClick={(e) => {
                                handleProofDelete(e, id, res?.certificate_id);
                              }}
                              size="small"
                              color="error"
                              aria-label="delete"
                              disabled={disabled}
                            >
                              <DeleteIcon fontSize="small" />
                            </Fab>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} padding={1}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ paddingLeft: "15px" }}
              >
                Upload Proof
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6} padding={1}>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary sx={{ minHeight: "30px" }}>
                      Resume
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ ml: -1, mt: -1, mb: -2, height: "20vh" }}
                    >
                      <FileUploader
                        fileUrl={fileUrl}
                        setFileUrl={setFileUrl}
                        files={files}
                        setFiles={setFiles}
                        filename={`${(currentData?.first_name ?? candidateData?.first_name)}_${APP_NAME}`}
                        filepath={currentData?.resume || candidateData?.resume}
                        handleDownloadResume={handleDownloadResume}
                        candidateData={candidateData}
                        disabled={disabled}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
            {!currentData?.ats_job_stages_id && !isProfile && (
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: `${disable ? "flex-end" : "space-between"}`,
                }}
              >
                {!disable && (
                  <Tooltip
                    title={`${
                      handleDisabled("candidate_send_form") ?
                      "You Don't have sufficient permission. Please contact Admin." : ""
                    }`}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ ml: 2 }}
                      className="mpl-primary-btn"
                      disabled={handleDisabled("candidate_send_form")}
                      onClick={sendMail}
                    >
                      Send form to candidate
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            )}
          </Grid>
          {currentData?.candidate_id && (
            <ProposedJobHistory
              activityHistory={activityHistory}
              loading={loading}
            />
          )}
          {generalHistory?.length > 0 && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ paddingLeft: "15px" }}
              >
                General history
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6} padding={1}>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary>
                      {candidateData?.first_name}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ maxHeight: "80vh", overflowY: "auto", mt: -4 }}
                    >
                      <TimelineCenter
                        data={generalHistory}
                        candidateName={candidateData?.first_name}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {viewMode === "success" && <CanditateFormSuccess />}
      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Notes"
        openPopup={openNotes}
        setOpenPopup={setOpenNotes}
      >
        <div>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <InputBase
                disabled={disabled}
                value={candidateData.notes}
                multiline
                rows={15}
                fullWidth
                name="notes"
                onChange={handleInputChange}
                sx={{ padding: "5px 0px" }}
                placeholder="Give Some Notes About Candidate..."
              />
            </Box>
          </Box>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            
            <Button
             disabled={disabled}
              onClick={SubmitNote}
              variant="contained"
              type="submit"
              className="mpl-primary-btn"
              size="small"
              sx={{
                ml: 1,
                mt: 1,
                color: grey[50],
                ":hover": {
                  backgroundColor: "#f9c307",
                  color: "#000000",
                },
              }}
            >
              Save
            </Button>
          </Box>
        </div>
      </Popup>
    </Box>
  );
};

export default AtsCandidateForm;
