import MaterialTable from "material-table";
import { Box, Button, TextField, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from "react-redux";
import { AddIndustryTypeAction, DeleteIndustryTypeAction, GetIndustryTypeAction, GetMeeplAdminIndustryTypeAction, UpdateIndustryTypeAction } from "../../../../../store/ATS/IndustryType/Action";
import { useEffect } from "react";
import { GetSkillsAction } from "../../../../../store/ATS/Skills/Action";
import { GetJobTemplateAction } from "../../../../../store/ATS/JobTemplate/Action";

const IndustryType = () => {
    const dispatch = useDispatch()
    const { industryTypeData, DeleteSuccess } = useSelector(state => state.AtsReducer?.IndustryTypeReducer);
    const domain_name = sessionStorage.getItem("userManagement")

    useEffect(() => {
        domain_name === "meepladmin" ? dispatch(GetMeeplAdminIndustryTypeAction()) :
            dispatch(GetIndustryTypeAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
            dispatch(GetSkillsAction({ industry_code: null }));
            dispatch(GetJobTemplateAction({ industry_code: null }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DeleteSuccess])

    return (
        <>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "Industry Sector", cellStyle: { padding: "0px 10px" }, field: "industry_name", validate: rowData => rowData?.industry_name === '' || rowData?.industry_name === undefined ? 'Name cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        },
                    ]}
                    data={industryTypeData || []}
                    icons={{
                        Add: () => <Button component="span" variant="contained" startIcon={<AddIcon />} className="mpl-primary-btn">Add</Button>,
                        Edit: () => <EditIcon />,
                        Delete: () => <DeleteOutlineIcon color='error' />
                    }}
                    editable={{
                        isEditable: rowData => !rowData?.is_public_data,
                        isDeletable: rowData => !rowData?.is_public_data,
                        onRowAdd: (newRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(AddIndustryTypeAction({ industry_name: newRow?.industry_name }))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                                !newRow?.is_public_data &&
                                    dispatch(UpdateIndustryTypeAction({ industry_type_id: newRow?.industry_type_id, industry_name: newRow?.industry_name, code: newRow?.code }))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowDelete: (selectedRow) =>
                            new Promise((resolve, reject) => {
                                !selectedRow?.is_public_data &&
                                    dispatch(DeleteIndustryTypeAction({ industry_type_id: selectedRow?.code }))
                                setTimeout(() => resolve(), 500);
                            }),
                    }}
                    options={{
                        maxBodyHeight: "35vh",
                        minBodyHeight: "35vh",
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    title="Industry Sector"
                />
            </Box>
        </>
    )
}
export default IndustryType;