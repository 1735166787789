import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Link, Radio, RadioGroup, Typography } from "@mui/material"
import history from "../../../../../history";
import { month } from "../../../Base/helper/baseFunctions";
import pdf from "../../images/pdf.png"

const RegimeSelectionPopup = ({ setOpenPopup, inputData, setInputData }) => {

    const handleInputChange = (event) => {
        if (event.target.name === "agree") {
            setInputData({ ...inputData, [event.target.name]: event.target.checked, })
        }
        else {
            setInputData({ ...inputData, "regime": event.target.value })
        }
    }

    const handleSubmit = () => {
        setOpenPopup(false)
    }

    const handleClose = () => {
        setOpenPopup(false);
        history.push("/payrole")
    }

    return (
        <Box>
            <Grid container direction="row" display="flex" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={6} sx={{ padding: "10px" }}>
                    <Box display={"flex"}>
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Current Status : &nbsp;</Typography>
                        <Typography variant="body1">{inputData?.regime === "old_regime" ? "Old Regime" : "New Regime"} OPTED </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: "10px" }}>
                    <Box display={"flex"}>
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Date: &nbsp;</Typography>
                        <Typography variant="body1">{new Date().getDate()}-{month[new Date().getMonth()]}-{new Date().getFullYear()}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{ padding: "10px" }} >
                    <Box>
                        <Typography variant="body1">
                            Please refer to the attached document for a better understanding of the implications of the
                            New Tax Regime in the Finance Act, 2020.
                            <Link href="#" underline="hover" onClick={() => { }}><img src={pdf} alt="pdf" height="20px" /> Download</Link>
                        </Typography>
                        <br />
                        <Typography variant="body1" fontWeight={600}>
                            In case, you do not select any regime from the below set, Old Regime will be considered
                            for purposes of calculation of tax on salary.
                        </Typography>
                        <br />
                        <FormControl>
                            <FormLabel component='legend'>Please select your prefered Regime for Tax Calculation:</FormLabel>
                            <RadioGroup onChange={handleInputChange} value={inputData?.regime} row defaultValue='old_regime' aria-label='regime' name='regime'>
                                <FormControlLabel value='old_regime' control={<Radio />} label='Old Regime' />
                                <FormControlLabel value='new_regime' control={<Radio disabled={true} />} label='New Regime' />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <br />
                        <Typography variant="body1">
                            I hereby declare that I am aware of the impact on computation of TDS on Salary, basis the tax Regime opted by me.
                            I shall be solely responsible for any incorrect declaration, and confirm that necessary documents as mandated shall be submitted by the due date as intimated by Payroll Department.
                        </Typography>
                        <br />
                        <FormControl required sx={{ mt: 4 }}>
                            <FormControlLabel
                                sx={{ paddingLeft: "10px" }}
                                value="end"
                                control={<Checkbox name="agree" required checked={inputData?.agree} onChange={handleInputChange} />}
                                label="I agree"
                                labelPlacement="end"
                            >
                            </FormControlLabel>
                        </FormControl>

                        <Grid container sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: "10px", paddingTop: "10px" }}>
                            <Box>
                                <Button
                                    sx={{ mr: 2 }}
                                    type="submit"
                                    variant="outlined"
                                    size="medium"
                                    onClick={() => handleClose()}
                                    className="mpl-secondary-btn"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={!inputData?.agree}
                                    type="submit"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => handleSubmit()}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}

export default RegimeSelectionPopup