import * as ActionTypes from '../ActionTypes';

export const GetMeeplAdminIndustryTypeAction = (params) => {
    return {
        type: ActionTypes.MEEPL_ADMIN_INDUSTRY_TYPE_GET_REQUEST,
        params
    }
}

export const GetIndustryTypeAction = (params) => {
    return {
        type: ActionTypes.INDUSTRY_TYPE_GET_REQUEST,
        params
    }
}


export const AddIndustryTypeAction = (params) => {
    return {
        type: ActionTypes.INDUSTRY_TYPE_ADD_REQUEST,
        params
    }
}

export const UpdateIndustryTypeAction = (params) => {
    return {
        type: ActionTypes.INDUSTRY_TYPE_UPDATE_REQUEST,
        params
    }
}

export const DeleteIndustryTypeAction = (params) => {
    return {
        type: ActionTypes.INDUSTRY_TYPE_DELETE_REQUEST,
        params
    }
}