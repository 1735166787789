import { fork } from "redux-saga/effects";
import SignInWatcher from "./Signin/Watcher";
import EmployeeWatcher from "./Employee/EmployeeWatcher";
import AncillaryWatcher from "./Ancillary/AncillaryWatcher";
import LeaveWatcher from "./Leave/LeaveWatcher";
import UserRoleAccess from "./Accesses/watcher";
import RoleAndAccessWatcher from "./RoleAccess/RoleAndAccessWatcher";
import EmployeeDashboardWatcher from "./EmployeeDashboard/Watcher";
import MeeplAdminWatcher from "./MeeplAdmin/MeeplAdminWatcher";
import FinanceWatcher from "./Finance/FinanceWatcher";
import AtsWatcher from "./ATS/AtsWatcher";
import TourWatcher from "./Tour/Watcher";
import OnboardingWatcher from "./Onboarding/OnboardingWatcher";
import PlansWatcher from "./Plans/Watcher";

export default function* startForman() {
    yield fork(SignInWatcher);
    yield fork(EmployeeWatcher);
    yield fork(AncillaryWatcher);
    yield fork(LeaveWatcher);
    yield fork(UserRoleAccess);
    yield fork(RoleAndAccessWatcher);
    yield fork(EmployeeDashboardWatcher);
    yield fork(FinanceWatcher);
    yield fork(AtsWatcher);
    yield fork(TourWatcher);
    yield fork(OnboardingWatcher);
    yield fork(MeeplAdminWatcher);
    yield fork(PlansWatcher);
};