import { grey } from "@mui/material/colors";
import { ShareInvoiceHelper } from "../../helper/ATSEmailHeper";
import { APP_NAME } from "../../../../Constants/baseConstant";
import { Box, Button, Divider, InputBase, Typography } from "@mui/material";
import { useState } from "react";
import AtsInvoicePdf from "./AtsInvoicePdf";
import { pdf } from "@react-pdf/renderer";
import { Mention, MentionsInput } from "react-mentions";
import MentionInputStyles from "../ATSCandidate/MentionStyles/MentionInputStyles";
import { convertBase64 } from "../../../Base/helper/baseFunctions";
import { useEffect } from "react";
import { GetAtsUsersEmailHelper } from "../../helper/AtsUserHelper";

const AtsInvoiceMail = ({
  currentData,
  invoiceCandidateData,
  setSendMail,
  performadata,
  performaCandidateData,
  amountData,
  getAmount,
  today,
}) => {
  const initialValues = {
    bcc: "",
    subject: "",
    content: "",
    invoice_file: "",
    file_name: "",
  };

  const [showCc, setShowCc] = useState(false);
  const [mentionTo, setMentionTo] = useState("");
  const [mentionCc, setMentionCc] = useState("");
  const [mentionUsers, setMentionUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [inputData, setInputData] = useState(initialValues);

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("mentionTo" in fieldValues) {
      temp.mentionTo =
        fieldValues.mentionTo?.trim() === "" ? "To Email is required" : "";
    }
    if ("subject" in fieldValues) {
      temp.subject =
        fieldValues.subject?.trim() === "" ? "Subject is required" : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    if (currentData) {
      setInputData({
        ...inputData,
        subject: `${currentData?.company}-${currentData?.ats_invoice_no}`,
        content: "Sharing Invoice",
      });
    } else {
      setInputData({ ...inputData, subject: `${performadata?.name}-Proforma` });
    }
    GetAtsUsersEmailHelper().then((res) => {
      let arr = [];
      res?.data?.data?.forEach((temp) =>
        arr.push({ ...temp, id: temp?.email_id, display: temp?.email_id })
      );
      setMentionUsers(arr);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowCc = () => {
    setMentionCc("");
    setShowCc(!showCc);
  };

  const handleInputChange = (event) => {
    setInputData({ ...inputData, [event?.target?.name]: event?.target?.value });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...inputData, [event.target.name]: event.target.value });
  };
  const handleChange = (e, name) => {
    const inputValue = e.target.value;
    const endsWithSpace = inputValue.endsWith(" ");
    if (endsWithSpace) {
      const trimmedValue = inputValue.trim();
      const updatedValue = trimmedValue + ",";
      name === "mentionTo"
        ? setMentionTo(updatedValue)
        : setMentionCc(updatedValue);
    } else {
      name === "mentionTo"
        ? setMentionTo(inputValue)
        : setMentionCc(inputValue);
    }
  };

  const handleDownloadPDF = async () => {
    const MyDocument = (
      <AtsInvoicePdf
        invoiceCandidateData={invoiceCandidateData}
        performadata={performadata}
        data={currentData}
        performaCandidateData={performaCandidateData}
        amountData={amountData}
        getAmount={getAmount}
        today={today}
      />
    );

    const blob = await pdf(MyDocument).toBlob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${currentData?.ats_invoice_no || "Proforma"}.pdf`;
    a.click();
    // Clean up the URL object
    URL.revokeObjectURL(url);
  };

  const Base64 = async (blob) => {
    return await convertBase64(blob);
  };

  const handleSend = () => {
    const processEmails = (emailString) => {
      const emailArray = emailString.split(",");
      const uniqueEmailSet = new Set(emailArray);
      const uniqueEmailArray = Array.from(uniqueEmailSet);
      return uniqueEmailArray.join(",");
    };
    const to = processEmails(mentionTo);
    const cc = processEmails(mentionCc);
    if (validate({...inputData, mentionTo})) {
      const attach = async () => {
        const MyDocument = (
          <AtsInvoicePdf
            invoiceCandidateData={invoiceCandidateData}
            performadata={performadata}
            data={currentData}
            performaCandidateData={performaCandidateData}
            amountData={amountData}
            getAmount={getAmount}
            today={today}
          />
        );
        const blob = await pdf(MyDocument).toBlob();
        const base64 = await Base64(blob); // Await the Base64 promise
        if (currentData) {
          ShareInvoiceHelper({
            ...inputData,
            to: to,
            cc: cc,
            invoice_file: base64,
            file_name: `${
              currentData?.ats_invoice_no || "Proforma" || ""
            }_${APP_NAME}.pdf`,
          });
        } else {
          ShareInvoiceHelper({
            ...inputData,
            to: to,
            cc: cc,
            invoice_file: base64,
            file_name: `${
              currentData?.ats_invoice_no || "Proforma" || ""
            }_${APP_NAME}.pdf`,
            message: "Proforma Sent Successfully",
          });
        }
        handleCancel();
      };
      attach();
    }
  };

  const handleCancel = () => {
    setInputData(initialValues);
    setSendMail(false);
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <MentionsInput
              fullWidth
              style={MentionInputStyles}
              value={mentionTo}
              name="mentionTo"
              onChange={(e, name) => handleChange(e, "mentionTo")}
              placeholder={"TO"}
            >
              <Mention
                markup="__id__,"
                displayTransform={(email_id) => `${email_id}, `}
                data={mentionUsers}
                displayName="email_id"
              />
            </MentionsInput>
          </Box>
          <Typography variant="body1">
            <Box
              onClick={handleShowCc}
              sx={{ cursor: "pointer" }}
              component={"span"}
            >
              CC
            </Box>
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Typography color="error">{errors.mentionTo}</Typography>
        {showCc && (
          <>
           <MentionsInput
            fullWidth
            style={MentionInputStyles}
            value={mentionCc}
            name="mentionCc"
            onChange={(e, name) => handleChange(e, "mentionCc")}
            placeholder={"CC"}
          >
            <Mention
              markup="__id__,"
              displayTransform={(email_id) => `${email_id}, `}
              data={mentionUsers}
              displayName="email_id"
            />
          </MentionsInput>
            <Divider sx={{ borderStyle: "dashed" }} />
          </>
        )}
        <InputBase
          fullWidth
          name="subject"
          value={inputData?.subject}
          onChange={handleInputChange}
          sx={{ padding: "5px 0px" }}
          placeholder="Subject"
        />
        <Divider sx={{ borderStyle: "dashed" }} />
        <Typography color="error">{errors.subject}</Typography>
        <InputBase
          fullWidth
          name="content"
          value={inputData?.content}
          onChange={handleInputChange}
          minRows={4}
          multiline
          sx={{ padding: "5px 0px" }}
          placeholder="Content"
        />
        <Box marginBottom={2} display="flex" alignItems={"center"}>
          {currentData ? (
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Invoice:&nbsp;{" "}
            </Typography>
          ) : (
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Proforma:&nbsp;
            </Typography>
          )}
          <Typography
            className="file-name"
            name="invoice_file"
            sx={{ color: "#1155cc" }}
            onClick={() => handleDownloadPDF()}
            style={{ cursor: "pointer" }}
          >
            {currentData?.ats_invoice_no || "Proforma"}.pdf
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            className="mpl-secondary-btn"
            sx={{ ":hover": { color: grey[50] } }}
            size="small"
            variant="text"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            sx={{
              ml: 1,
              color: grey[50],
              ":hover": { backgroundColor: "#f9c307", color: "#000000" },
            }}
            size="small"
            variant="contained"
            className="mpl-primary-btn"
            onClick={handleSend}
          >
            Send
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default AtsInvoiceMail;
