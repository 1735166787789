import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";

export const GetJobHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getJobs`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}


export const AddJobHelper = async (job) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addJob`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            name: job?.name
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Job Added Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateJobHelper = async (job) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateJob`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            jobid: job?.job_id,
            name: job?.name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Job Updated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteJobHelper = async (job) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteJob`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            jobid: job.job_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Job Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Failed", hasError: true, data: err?.data }
        })
}