/* Employees */
export const EMPLOYEES_GET_REQUEST = "GET_EMPLOYEES_REQUEST";
export const EMPLOYEES_GET_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const EMPLOYEES_GET_ERROR = "GET_EMPLOYEES_ERROR";

export const EMPLOYEES_FACE_GET_REQUEST = "EMPLOYEES_FACE_GET_REQUEST";
export const EMPLOYEES_FACE_GET_SUCCESS = "EMPLOYEES_FACE_GET_SUCCESS";
export const EMPLOYEES_FACE_GET_ERROR = "EMPLOYEES_FACE_GET_ERROR";


/*Ats Employees */
export const ATS_EMPLOYEES_GET_REQUEST = "GET_ATS_EMPLOYEES_REQUEST";
export const ATS_EMPLOYEES_GET_SUCCESS = "GET_ATS_EMPLOYEES_SUCCESS";
export const ATS_EMPLOYEES_GET_ERROR = "GET_ATS_EMPLOYEES_ERROR";


/* Employee Basic Details */
export const GET_EMPLOYEE_BASIC_REQUEST = "GET_EMPLOYEE_BASIC_REQUEST";
export const GET_EMPLOYEE_BASIC_SUCCESS = "GET_EMPLOYEE_BASIC_SUCCESS";
export const GET_EMPLOYEE_BASIC_ERROR = "GET_EMPLOYEE_BASIC_ERROR";

export const UPDATE_EMPLOYEE_BASIC_REQUEST = "UPDATE_EMPLOYEE_BASIC_REQUEST";
export const UPDATE_EMPLOYEE_BASIC_SUCCESS = "UPDATE_EMPLOYEE_BASIC_SUCCESS";
export const UPDATE_EMPLOYEE_BASIC_ERROR = "UPDATE_EMPLOYEE_BASIC_ERROR";

export const ADD_EMPLOYEE_BASIC_REQUEST = "ADD_EMPLOYEE_BASIC_REQUEST";
export const ADD_EMPLOYEE_BASIC_SUCCESS = "ADD_EMPLOYEE_BASIC_SUCCESS";
export const ADD_EMPLOYEE_BASIC_ERROR = "ADD_EMPLOYEE_BASIC_ERROR";

/* Employee Bank Details */
export const GET_EMPLOYEE_BANK_REQUEST = "GET_EMPLOYEE_BANK_REQUEST";
export const GET_EMPLOYEE_BANK_SUCCESS = "GET_EMPLOYEE_BANK_SUCCESS";
export const GET_EMPLOYEE_BANK_ERROR = "GET_EMPLOYEE_BANK_ERROR";

export const ADD_EMPLOYEE_BANK_REQUEST = "ADD_EMPLOYEE_BANK_REQUEST";
export const ADD_EMPLOYEE_BANK_SUCCESS = "ADD_EMPLOYEE_BANK_SUCCESS";
export const ADD_EMPLOYEE_BANK_ERROR = "ADD_EMPLOYEE_BANK_ERROR";

export const UPDATE_EMPLOYEE_BANK_REQUEST = "UPDATE_EMPLOYEE_BANK_REQUEST";
export const UPDATE_EMPLOYEE_BANK_SUCCESS = "UPDATE_EMPLOYEE_BANK_SUCCESS";
export const UPDATE_EMPLOYEE_BANK_ERROR = "UPDATE_EMPLOYEE_BANK_ERROR";

export const DELETE_EMPLOYEE_BANK_REQUEST = "DELETE_EMPLOYEE_BANK_REQUEST";
export const DELETE_EMPLOYEE_BANK_SUCCESS = "DELETE_EMPLOYEE_BANK_SUCCESS";
export const DELETE_EMPLOYEE_BANK_ERROR = "DELETE_EMPLOYEE_BANK_ERROR";

/* Employee Family Details */
export const EMPLOYEE_FAMILY_GET_REQUEST = "GET_EMPLOYEE_FAMILY_REQUEST";
export const EMPLOYEE_FAMILY_GET_SUCCESS = "GET_EMPLOYEE_FAMILY_SUCCESS";
export const EMPLOYEE_FAMILY_GET_ERROR = "GET_EMPLOYEE_FAMILY_ERROR";

export const EMPLOYEE_FAMILY_ADD_REQUEST = "ADD_EMPLOYEE_FAMILY_REQUEST";
export const EMPLOYEE_FAMILY_ADD_SUCCESS = "ADD_EMPLOYEE_FAMILY_SUCCESS";
export const EMPLOYEE_FAMILY_ADD_ERROR = "ADD_EMPLOYEE_FAMILY_ERROR";

export const EMPLOYEE_FAMILY_UPDATE_REQUEST = "UPDATE_EMPLOYEE_FAMILY_REQUEST";
export const EMPLOYEE_FAMILY_UPDATE_SUCCESS = "UPDATE_EMPLOYEE_FAMILY_SUCCESS";
export const EMPLOYEE_FAMILY_UPDATE_ERROR = "UPDATE_EMPLOYEE_FAMILY_ERROR";

export const EMPLOYEE_FAMILY_DELETE_REQUEST = "DELETE_EMPLOYEE_FAMILY_REQUEST";
export const EMPLOYEE_FAMILY_DELETE_SUCCESS = "DELETE_EMPLOYEE_FAMILY_SUCCESS";
export const EMPLOYEE_FAMILY_DELETE_ERROR = "DELETE_EMPLOYEE_FAMILY_ERROR";

/* Employee Education Details */
export const EMPLOYEE_EDUCATION_GET_REQUEST = "GET_EMPLOYEE_EDUCATION_REQUEST";
export const EMPLOYEE_EDUCATION_GET_SUCCESS = "GET_EMPLOYEE_EDUCATION_SUCCESS";
export const EMPLOYEE_EDUCATION_GET_ERROR = "GET_EMPLOYEE_EDUCATION_ERROR";

export const EMPLOYEE_EDUCATION_ADD_REQUEST = "ADD_EMPLOYEE_EDUCATION_REQUEST";
export const EMPLOYEE_EDUCATION_ADD_SUCCESS = "ADD_EMPLOYEE_EDUCATION_SUCCESS";
export const EMPLOYEE_EDUCATION_ADD_ERROR = "ADD_EMPLOYEE_EDUCATION_ERROR";

export const EMPLOYEE_EDUCATION_UPDATE_REQUEST = "UPDATE_EMPLOYEE_EDUCATION_REQUEST";
export const EMPLOYEE_EDUCATION_UPDATE_SUCCESS = "UPDATE_EMPLOYEE_EDUCATION_SUCCESS";
export const EMPLOYEE_EDUCATION_UPDATE_ERROR = "UPDATE_EMPLOYEE_EDUCATION_ERROR";

export const EMPLOYEE_EDUCATION_DELETE_REQUEST = "DELETE_EMPLOYEE_EDUCATION_REQUEST";
export const EMPLOYEE_EDUCATION_DELETE_SUCCESS = "DELETE_EMPLOYEE_EDUCATION_SUCCESS";
export const EMPLOYEE_EDUCATION_DELETE_ERROR = "DELETE_EMPLOYEE_EDUCATION_ERROR";

/* Employee Work Experience Details */
export const EMPLOYEE_WORK_EXPERIENCE_GET_REQUEST = "GET_EMPLOYEE_WORK_EXPERIENCE_REQUEST";
export const EMPLOYEE_WORK_EXPERIENCE_GET_SUCCESS = "GET_EMPLOYEE_WORK_EXPERIENCE_SUCCESS";
export const EMPLOYEE_WORK_EXPERIENCE_GET_ERROR = "GET_EMPLOYEE_WORK_EXPERIENCE_ERROR";

export const EMPLOYEE_WORK_EXPERIENCE_ADD_REQUEST = "ADD_EMPLOYEE_WORK_EXPERIENCE_REQUEST";
export const EMPLOYEE_WORK_EXPERIENCE_ADD_SUCCESS = "ADD_EMPLOYEE_WORK_EXPERIENCE_SUCCESS";
export const EMPLOYEE_WORK_EXPERIENCE_ADD_ERROR = "ADD_EMPLOYEE_WORK_EXPERIENCE_ERROR";

export const EMPLOYEE_WORK_EXPERIENCE_UPDATE_REQUEST = "UPDATE_EMPLOYEE_WORK_EXPERIENCE_REQUEST";
export const EMPLOYEE_WORK_EXPERIENCE_UPDATE_SUCCESS = "UPDATE_EMPLOYEE_WORK_EXPERIENCE_SUCCESS";
export const EMPLOYEE_WORK_EXPERIENCE_UPDATE_ERROR = "UPDATE_EMPLOYEE_WORK_EXPERIENCE_ERROR";

export const EMPLOYEE_WORK_EXPERIENCE_DELETE_REQUEST = "DELETE_EMPLOYEE_WORK_EXPERIENCE_REQUEST";
export const EMPLOYEE_WORK_EXPERIENCE_DELETE_SUCCESS = "DELETE_EMPLOYEE_WORK_EXPERIENCE_SUCCESS";
export const EMPLOYEE_WORK_EXPERIENCE_DELETE_ERROR = "DELETE_EMPLOYEE_WORK_EXPERIENCE_ERROR";

/* Employee Personal Details */
export const EMPLOYEE_PERSONAL_GET_REQUEST = "EMPLOYEE_PERSONAL_GET_REQUEST";
export const EMPLOYEE_PERSONAL_GET_SUCCESS = "EMPLOYEE_PERSONAL_GET_SUCCESS";

export const EMPLOYEE_PERSONAL_ADD_REQUEST = "EMPLOYEE_PERSONAL_ADD_REQUEST";
export const EMPLOYEE_PERSONAL_ADD_SUCCESS = "EMPLOYEE_PERSONAL_ADD_SUCCESS";
export const EMPLOYEE_PERSONAL_ADD_ERROR = "EMPLOYEE_PERSONAL_ADD_ERROR";

export const EMPLOYEE_PERSONAL_UPDATE_REQUEST = "EMPLOYEE_PERSONAL_UPDATE_REQUEST";
export const EMPLOYEE_PERSONAL_UPDATE_SUCCESS = "EMPLOYEE_PERSONAL_UPDATE_SUCCESS";
export const EMPLOYEE_PERSONAL_UPDATE_ERROR = "EMPLOYEE_PERSONAL_UPDATE_ERROR";

export const EMPLOYEE_PERSONAL_DELETE_REQUEST = "EMPLOYEE_PERSONAL_DELETE_REQUEST";
export const EMPLOYEE_PERSONAL_DELETE_SUCCESS = "EMPLOYEE_PERSONAL_DELETE_SUCCESS";
export const EMPLOYEE_PERSONAL_DELETE_ERROR = "EMPLOYEE_PERSONAL_DELETE_ERROR";

/* Employee Other Personal Details */
export const EMPLOYEE_OTHER_PERSONAL_GET_REQUEST = "EMPLOYEE_OTHER_PERSONAL_GET_REQUEST";
export const EMPLOYEE_OTHER_PERSONAL_GET_SUCCESS = "EMPLOYEE_OTHER_PERSONAL_GET_SUCCESS";

export const EMPLOYEE_OTHER_PERSONAL_UPDATE_REQUEST = "EMPLOYEE_OTHER_PERSONAL_UPDATE_REQUEST";
export const EMPLOYEE_OTHER_PERSONAL_UPDATE_SUCCESS = "EMPLOYEE_OTHER_PERSONAL_UPDATE_SUCCESS";
export const EMPLOYEE_OTHER_PERSONAL_UPDATE_ERROR = "EMPLOYEE_OTHER_PERSONAL_UPDATE_ERROR";

/* Employee Work  Details */
export const EMPLOYEE_WORK_DETAIL_GET_REQUEST = "GET_EMPLOYEE_WORK_DETAIL_REQUEST";
export const EMPLOYEE_WORK_DETAIL_GET_SUCCESS = "GET_EMPLOYEE_WORK_DETAIL_SUCCESS";
export const EMPLOYEE_WORK_DETAIL_GET_ERROR = "GET_EMPLOYEE_WORK_DETAIL_ERROR";

export const EMPLOYEE_WORK_DETAIL_UPDATE_REQUEST = "UPDATE_EMPLOYEE_WORK_DETAIL_REQUEST";
export const EMPLOYEE_WORK_DETAIL_UPDATE_SUCCESS = "UPDATE_EMPLOYEE_WORK_DETAIL_SUCCESS";
export const EMPLOYEE_WORK_DETAIL_UPDATE_ERROR = "UPDATE_EMPLOYEE_WORK_DETAIL_ERROR";

export const EMPLOYEE_WORK_DETAIL_ADD_REQUEST = "ADD_EMPLOYEE_WORK_DETAIL_REQUEST";
export const EMPLOYEE_WORK_DETAIL_ADD_SUCCESS = "ADD_EMPLOYEE_WORK_DETAIL_SUCCESS";
export const EMPLOYEE_WORK_DETAIL_ADD_ERROR = "ADD_EMPLOYEE_WORK_DETAIL_ERROR";


/* Employee Payroll Details */
export const GET_EMPLOYEE_PAYROLL_DETAILS_REQUEST = "GET_EMPLOYEE_PAYROLL_DETAILS_REQUEST";
export const GET_EMPLOYEE_PAYROLL_DETAILS_SUCCESS = "GET_EMPLOYEE_PAYROLL_DETAILS_SUCCESS";
export const GET_EMPLOYEE_PAYROLL_DETAILS_ERROR = "GET_EMPLOYEE_PAYROLL_DETAILS_ERROR";

export const UPDATE_EMPLOYEE_PAYROLL_DETAILS_REQUEST = "UPDATE_EMPLOYEE_PAYROLL_DETAILS_REQUEST";
export const UPDATE_EMPLOYEE_PAYROLL_DETAILS_SUCCESS = "UPDATE_EMPLOYEE_PAYROLL_DETAILS_SUCCESS";
export const UPDATE_EMPLOYEE_PAYROLL_DETAILS_ERROR = "UPDATE_EMPLOYEE_PAYROLL_DETAILS_ERROR";

export const EMPLOYEE_ADDRESS_UPDATE_REQUEST = "EMPLOYEE_ADDRESS_UPDATE_REQUEST";
export const EMPLOYEE_ADDRESS_UPDATE_SUCCESS = "EMPLOYEE_ADDRESS_UPDATE_SUCCESS";
export const EMPLOYEE_ADDRESS_UPDATE_ERROR = "EMPLOYEE_ADDRESS_UPDATE_ERROR";

export const EMPLOYEE_ADDRESS_GET_REQUEST = "EMPLOYEE_ADDRESS_GET_REQUEST";
export const EMPLOYEE_ADDRESS_GET_SUCCESS = "EMPLOYEE_ADDRESS_GET_SUCCESS";
export const EMPLOYEE_ADDRESS_GET_ERROR = "EMPLOYEE_ADDRESS_GET_ERROR";