import { toast } from "react-toastify";
import NProgress from "nprogress";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetFormTemplateResponseHelper = async (params) => {
  const userData = getUserData();
  NProgress.start();
  return await axiosInstance(`/ats/getFormTemplateResponse`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      feedback_template_id: params?.feedback_template_id,
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const AddFormTemplateResponseHelper = async (params) => {
  const userData = getUserData();
  toast.info("Saving...");
  return await axiosInstance(`/ats/addFormTemplateResponse`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      user_id: params?.user_id,
      feedback_template_id: params?.feedback_template_id,
      feedback_response: params?.feedback_response,
      file: params?.file || null,
      file_name: params?.file_name || null,
      requested_by_id: params?.requested_by_id,
      given_by_name: params?.given_by_name,
      given_by_email: params?.given_by_email,
      requested_date: params?.requested_date || null,
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Form Template Response Added Successfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const AddPreScreenTemplateResponseHelper = async (params) => {
  const userData = getUserData();
  toast.info("Saving...");
  return await axiosInstance(`/ats/addPreScreenTemplateResponse`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      user_id: params?.user_id,
      prescreen_template_id: params?.prescreen_template_id,
      prescreen_response: params?.prescreen_response,
      file: params?.file || null,
      file_name: params?.file_name || null,
      candidate_job_id: params?.candidate_job_id,
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Form Template Response Added Successfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const GetPreScreenTemplateResponseHelper = async (params) => {
  const userData = getUserData();
  NProgress.start();
  return await axiosInstance(`/ats/getPreScreenTemplateResponse`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      prescreening_response_id: params?.prescreening_response_id,
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

// Offer Letter

export const AddOfferLetterTemplateResponseHelper = async (params) => {
  const userData = getUserData();
  toast.info("Saving...");
  return await axiosInstance(`/ats/addOfferLetterTemplateResponse`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      user_id: params?.user_id,
      offer_letter_template_id: params?.offer_letter_template_id,
      offer_letter_fields_response: params?.offer_letter_fields_response,
      candidate_job_id: params?.candidate_job_id,
      file: params?.file || null,
      file_name: params?.file_name || null,
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Congratulations! We have received your response.");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const UpdateOfferLetterTemplateResponseHelper = async (params) => {
  const userData = getUserData();
  return await axiosInstance(`/ats/updateOfferLetterTemplateResponse`, {
    method: "PUT",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      user_id: params?.user_id,
      offer_letter_response_id: params?.offer_letter_response_id,
      offer_letter_response: params?.offer_letter_response,
      offer_letter_fields_response: params?.offer_letter_fields_response,
      file: params?.file || null,
      file_name: params?.file_name || null,
      old_offer_name: params?.old_offer_name || null,
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Response captured successfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const GetOfferLetterTemplateResponseHelper = async (params) => {
  const userData = getUserData();
  NProgress.start();
  return await axiosInstance(`/ats/getOfferLetterTemplateResponse`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      offer_letter_response_id: params?.offer_letter_response_id,
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

//Approve Offer Letter

export const OfferApproveHelper = async (params) => {
  const userData = getUserData();
  NProgress.start();
  return await axiosInstance(`/ats/OfferApprove`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      offer_letter_response_id: params?.offer_letter_response_id,
      requested_by_id: params?.requested_by_id,
      requested_date: params?.requested_date,
      approver_response: params?.approver_response,
      given_by_name: params?.given_by_name,
      given_by_email: params?.given_by_email,
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const OfferAcceptHelper = async (params) => {
  const userData = getUserData();
  NProgress.start();
  return await axiosInstance(`/ats/OfferAccept`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      offer_letter_response_id: params?.offer_letter_response_id,
      requested_by_id: params?.requested_by_id,
      requested_date: params?.requested_date,
      candidate_response: params?.candidate_response,
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const OfferEmailSentHelper = async (params) => {
  const userData = getUserData();
  NProgress.start();
  return await axiosInstance(`/ats/OfferEmailSent`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: userData?.domain || params?.workspace,
      table_name: "offer_letter_response",
      update_tblcolumn: params?.update_tblcolumn,
      update_tblcolumn_value: params?.update_tblcolumn_value,
      user_id: userData?.employee_id,
      primary_key_column_name: "offer_letter_response_pk",
      offer_letter_response_id: params?.offer_letter_response_id,
    },
  })
    .then((res) => {
      NProgress.done();
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      NProgress.done();
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
