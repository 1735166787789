import React from "react";
const FAQ = () => {
  return (
    <>
      <div id="FAQ" className="section orange">
        <div className="container">
          <div className="title-container">
            <div className="center-text">
              <div className="w-layout-grid grid-8-col">
                <div
                  id="w-node-_903bdafb-9de3-e338-f216-51c262f8107c-3f715fba"
                  className="fade-in-move-on-scroll"
                >
                  <h1 className="title">Frequently Asked Questions</h1>
                </div>
              </div>
            </div>
            <div className="questions-holder">
              <div className="questions-container">
                <div className="fade-in-move-on-scroll">
                  <div className="card-title">Basic Users Questions</div>
                </div>
                <div className="questions-wrapper">
                  <div className="question-half">
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            What is Meepl, and how can it help my hiring
                            process?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="f298779b-8198-e36c-310e-3f9642ef0668"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_10">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_10)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Meepl is an ATS tool that helps both the hiring
                          company and recruitment consultants to work together
                          for faster hiring. With Meepl, you can collaborate
                          directly with consultants who hunt for candidates from
                          multiple sources to work together in a single
                          platform. This improves communication and transparency
                          during the hiring process, helping you to hire the
                          right candidate more efficiently.
                        </p>
                      </div>
                    </div>
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            How is Meepl different from other ATS tools on the
                            market?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="6eadc6de-0a83-4aca-f35e-c924a38f9edb"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_17">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_17)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Meepl is different from other ATS tools because it
                          allows both stakeholders and the hiring company to
                          collaborate directly with consultants who hunt for
                          candidates from multiple sources. With Meepl, you can
                          create a hiring team that works together in a single
                          platform, streamlining the hiring process and reducing
                          the chance of miscommunication.
                        </p>
                      </div>
                    </div>
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            Can I use Meepl to collaborate with recruitment
                            consultants and my hiring team simultaneously?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="3c9c4c18-f0a8-e48d-8d5e-d719c789889d"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_24">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_24)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Yes, you can use Meepl to collaborate with recruitment
                          consultants and your hiring team simultaneously. Meepl
                          lets both stakeholders and the company that hires
                          bring their hiring team to collaborate directly with
                          the consultants who hunt for candidates from multiple
                          sources, making it easier to work together to find and
                          hire the right candidate.
                        </p>
                      </div>
                    </div>
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            How does Meepl help to prevent miscommunication
                            during the hiring process?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="b5ca9640-f13c-f107-eb3f-3dcd9a750bf1"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_31">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_31)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Miscommunication can be a common problem during the
                          hiring process, especially with multiple stakeholders
                          involved. Meepl helps to prevent miscommunication by
                          bringing everyone on the same page. Communication with
                          candidates is transparent to everyone, reducing the
                          chance for misunderstandings or confusion.
                        </p>
                      </div>
                    </div>
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            Can I invite all my clients and hiring companies to
                            the same Meepl dashboard, and how does access to
                            information work in that case?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="b5ca9640-f13c-f107-eb3f-3dcd9a750bf1"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_31">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_31)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Yes, you can invite all your clients within one shared
                          workspace. However, they will only be able to see
                          those assigned jobs, ensuring that access to
                          information is secure and restricted based on the
                          user's permissions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="question-half">
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            How many job openings can I create using Meepl?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="fbe68d3a-ecb5-6e1c-e1b8-0e5845090a02"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_38">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_38)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          You can create as many job openings as you need with
                          Meepl, but only 5 can be active for recruiting at any
                          given time.
                        </p>
                      </div>
                    </div>
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            Can I customize the stages of each job individually?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="fbe68d3a-ecb5-6e1c-e1b8-0e5845090a0c"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_45">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_45)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Yes, Meepl is fully customizable. You can design the
                          recruitment flow as per your requirement, including
                          customizing the stages of each job individually.
                        </p>
                      </div>
                    </div>
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            Does Meepl support resume parsing, and what file
                            types are supported?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="fbe68d3a-ecb5-6e1c-e1b8-0e5845090a16"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_52">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_52)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Yes, Meepl supports resume parsing. You can upload up
                          to 50 CVs at a time and create candidate profiles on
                          the fly. For now, Meepl supports PDFs, with Word doc
                          support coming soon.
                        </p>
                      </div>
                    </div>
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            Can I use Meepl to onboard selected candidates once
                            they have been hired?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="fbe68d3a-ecb5-6e1c-e1b8-0e5845090a20"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_59">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_59)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Meepl is currently working on an employee onboarding
                          module, which will be available soon.
                        </p>
                      </div>
                    </div>
                    <div className="question">
                      <div className="question-header">
                        <div className="question-text">
                          <div>
                            Can I add multiple vendors or recruitment agencies
                            to hire for the same job opening?
                          </div>
                        </div>
                        <div className="question-icon-holder">
                          <div
                            data-is-ix2-target="1"
                            className="plus-animation"
                            data-w-id="fbe68d3a-ecb5-6e1c-e1b8-0e5845090a20"
                            data-animation-type="lottie"
                            data-src="https://assets.website-files.com/634e968b219cc43522715fb8/6351597efd13f101c846fb1a_Plus%20to%20Minus.json"
                            data-loop="0"
                            data-direction="1"
                            data-autoplay="0"
                            data-renderer="svg"
                            data-default-duration="0.5166666666666667"
                            data-duration="0"
                            data-ix2-initial-state="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 14 14"
                              width="14"
                              height="14"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <defs>
                                <clipPath id="__lottie_element_59">
                                  <rect
                                    width="14"
                                    height="14"
                                    x="0"
                                    y="0"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g clipPath="url(#__lottie_element_59)">
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M0,7 C0,7 0,-7 0,-7"
                                    ></path>
                                  </g>
                                </g>
                                <g transform="matrix(1,0,0,1,7,7)" opacity="1">
                                  <g
                                    opacity="1"
                                    transform="matrix(1,0,0,1,0,0)"
                                  >
                                    <path
                                      strokeLinecap="butt"
                                      strokeLinejoin="round"
                                      fillOpacity="0"
                                      stroke="rgb(0,0,0)"
                                      strokeOpacity="1"
                                      strokeWidth="2"
                                      d=" M7,0 C7,0 -7,0 -7,0"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="answer-holder">
                        <p className="answer">
                          Yes, you can invite as many vendors or recruitment
                          agencies as you want to hire for the same job opening.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQ;
