import React from "react";
import { useEffect } from "react";
import MaterialTable from "material-table";
import {
  Box,
  Chip,
  FormControl,
  Menu,
  MenuItem,
  ListItemText,
} from "@mui/material";
import {
  ConvertDate,
  ConvertDateTime,
} from "../../../../Base/helper/baseFunctions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetLeaveTypeAction } from "../../../../../../store/Leave/LeaveType/Action";
import SelectableSearch from "../../../../Base/views/SelectableSearch";
import { getUserData } from "../../../../Base/helper/baseFunctions";
import { TimeOffBookingsHelper } from "../../../helper/TimeOff/TimeOffHelper";
import ConfirmationDialog from "../../../../Base/views/ConfirmationDialog";
import { DeleteLeaveRequestHelper } from "../../../../Leave/helper/LeaveRequestHelper";

const TeamTimeOffBookings = ({ flag }) => {
  const empData = getUserData();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    leavetype_id: { leavetype_id: 0, name: "All" },
  });
  const [leaveTypeOptions, setLeaveTypeOptions] = useState({
    load: true,
    values: [],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [leaveStatus, setLeaveStatus] = useState({ load: true, values: [] });
  const [currentData, setCurrentData] = useState({});
  const open = Boolean(anchorEl);

  const { leavetypes_options } = useSelector(
    (state) => state.LeaveReducer.LeaveTypeReducer
  );
  const [leaveReportData, setLeaveReportData] = useState([]);

  useEffect(() => {
    dispatch(GetLeaveTypeAction());
    setLeaveStatus({
      load: false,
      values: [
        {
          id: "1",
          name: "Submitted",
        },
        {
          id: "2",
          name: "Approved",
        },
        {
          id: "3",
          name: "Rejected",
        },
      ],
    });
  }, []);

  const handleLeaveTypeOptions = () => {
    leavetypes_options?.splice(0, 0, { leavetype_id: 0, name: "All" });
    if (empData?.gender === "Female") {
      setLeaveTypeOptions({
        load: false,
        values: leavetypes_options.filter(
          (lt) => lt.name !== "Paternity Leave"
        ),
      });
    } else if (empData?.gender === "Male") {
      setLeaveTypeOptions({
        load: false,
        values: leavetypes_options.filter(
          (lt) => lt.name !== "Maternity Leave"
        ),
      });
    } else {
      setLeaveTypeOptions({ load: false, values: leavetypes_options });
    }
  };

  const handleChange = (event, value) => {
    setData({ ...data, [event]: value });
  };

  useEffect(() => {
    TimeOffBookingsHelper({ ...data, my_time_flag: true, reporting_manager_id:empData?.employee_id }).then((res) => {
      if (res?.data?.statuscode === 200) {
        setLeaveReportData(res?.data?.data);
      }
    });
  }, [data, flag]);

  const handleClick = (event, data) => {
    setCurrentData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleConfirmCancel = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };
  const handleCancel = () => {
    DeleteLeaveRequestHelper(currentData).then((res) => {
      if (res?.data?.statuscode === 200) {
        TimeOffBookingsHelper({ ...data, my_time_flag: true, reporting_manager_id:empData?.employee_id }).then((res) => {
          if (res?.data?.statuscode === 200) {
            setLeaveReportData(res?.data?.data);
          }
        });
        setOpenDialog(false);
      }
    });
  };
  return (
    <>
      <>
        <Box
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl fullWidth sx={{ padding: "5px", pr: 3 }}>
            <SelectableSearch
              label="Leave Type"
              value={data?.leavetype_id}
              loading={() => handleLeaveTypeOptions()}
              fieldLabel="name"
              autoFocus={false}
              required={false}
              variant={"outlined"}
              name="leavetype_id"
              size="small"
              onChangeEvent={handleChange}
              data={leaveTypeOptions}
            />
          </FormControl>
          <FormControl fullWidth sx={{ padding: "5px", pr: 3, pl: 3 }}>
            <SelectableSearch
              label="Leave Status"
              value={leaveStatus?.id}
              loading={() => {}}
              fieldLabel="name"
              autoFocus={false}
              variant={"outlined"}
              name="status"
              size="small"
              onChangeEvent={handleChange}
              data={leaveStatus}
            />
          </FormControl>
        </Box>
        <MaterialTable
          columns={[
            {
              title: "Name",
              field: "employee",
              cellStyle: { padding: "5px 10px" },
            },
            {
              title: "Leave Type",
              field: "leavetype",
              cellStyle: { padding: "5px 10px" },
            },
            {
              title: "Start Date",
              field: "start_date",
              cellStyle: { padding: "5px 10px" },
              render: (rowData) => {
                return ConvertDate(rowData.start_date);
              },
            },
            {
              title: "End Date",
              field: "end_date",
              cellStyle: { padding: "5px 10px" },
              render: (rowData) => {
                return ConvertDate(rowData.end_date);
              },
            },
            {
              title: "Duration",
              field: "effective_days",
              cellStyle: { padding: "5px 10px 5px 30px" },
            },
            {
              title: "Status",
              field: "request_status",
              cellStyle: { padding: "5px 10px" },
              render: (rowData) => {
                return rowData.request_status === "Approved" ? (
                  <Chip
                    label="Approved"
                    color="success"
                    size="small"
                    variant="outlined"
                  />
                ) : rowData.request_status === "Submitted" ? (
                  <Chip
                    label="Submitted"
                    color="info"
                    size="small"
                    variant="outlined"
                  />
                ) : rowData.request_status === "Rejected" ? (
                  <Chip
                    label="Rejected"
                    color="warning"
                    size="small"
                    variant="outlined"
                  />
                ) : rowData.request_status === "Cancel Requested" ? (
                  <Chip
                    title="Cancel Requested"
                    label="Cancel Requested"
                    size="small"
                    variant="outlined"
                    sx={{
                      width: "120px",
                      color: "#ffb400",
                      borderColor: "#ffb400",
                    }}
                  />
                ) : rowData.request_status === "Cancelled" ? (
                  <Chip
                    label="Cancelled"
                    color="error"
                    size="small"
                    variant="outlined"
                  />
                ) : (
                  <p style={{ color: "#B0B700", fontWeight: "bold" }}>
                    {rowData.request_status}
                  </p>
                );
              },
            },
            {
              title: "Last Updated",
              field: "submitted_on",
              cellStyle: { padding: "5px 10px" },
              render: (rowData) => {
                return ConvertDateTime(rowData.submitted_on);
              },
            },
          ]}
          data={leaveReportData}
          options={{
            maxBodyHeight: 230,
            rowStyle: { fontSize: "13px" },
            headerStyle: { padding: "0px 10px", fontWeight: "bold" },
            sorting: true,
            search: true,
            searchText: "",
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            paging: true,
            pageSizeOptions: [10, 25],
            pageSize: 10,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            exportButton: true,
            exportAllData: true,
            exportFileName: "Leave Reports",
            addRowPosition: "first",
            selection: false,
            actionsColumnIndex: -1,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: true,
          }}
          actions={[
            {
              icon: () => <MoreVertIcon fontSize="small" />,
              tooltip: "Settings",
              onClick: (_event, rowData) => {
                handleClick(_event, rowData);
              },
            },
          ]}
          title={"Bookings"}
        />
        <Menu
          id="more-options-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "more-options-button",
          }}
        >
          {currentData?.request_status === "Submitted" ? (
            <div>
              <MenuItem onClick={handleConfirmCancel}>
                <ListItemText>Cancel Leave</ListItemText>
              </MenuItem>
            </div>
          ) : (
            <MenuItem disabled>
              <ListItemText> Cancel Leave</ListItemText>
            </MenuItem>
          )}
        </Menu>
        <ConfirmationDialog
          title={"Cancel Leave"}
          content={"Are you sure want to Cancel Leave?"}
          openDialog={openDialog}
          closeDialog={setOpenDialog}
          popupTitle="Users"
          OkButtonText={"Cancel Leave"}
          onSubmit={handleCancel}
        />
      </>
    </>
  );
};

export default TeamTimeOffBookings;
