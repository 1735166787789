import { Box, Button, Typography, FormControl, FormLabel } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import Expired from "../images/expired.gif";
import { useDispatch } from 'react-redux';
import { signOut } from "../../SignIn/helper/SigninHelper";
import { useState } from "react";
import { requestAdminHelper } from "../../MeeplAdmin/helper/WorkspaceHelper";
import { toast } from "react-toastify";
import { AddRequestAction } from "../../../../store/MeeplAdmin/Workspace/Action";

const PlanExpired = ({
  setOpenPopup,
  userInfoData,
  popupContent,
  setPopupContent,
  planExpired,
  planRemain,
}) => {
  const dispatch = useDispatch();
  const [showbutton, setShowbutton] = useState(true);
  
  const HandleRequest = () => {
    requestAdminHelper({...userInfoData, pre_req: true,
    }).then((res) => {
      toast.success("Your request has been successfully sent");
     setOpenPopup(false);
    });
    dispatch(AddRequestAction({ domainname: userInfoData?.domainname, userid:userInfoData?.userid }));
  };
  const HandleExpireRequest = () => {
    requestAdminHelper({...userInfoData,pre_req: false,
    }).then((res) => {
      toast.success("Your request has been successfully sent");
      setPopupContent("Request has been sent to Admin. You will be notified shortly")
      setShowbutton(false)
    });
  };
  const HandleRemaind = () => {
    dispatch(AddRequestAction({ domainname: userInfoData?.domainname, userid:userInfoData?.userid }));
    setOpenPopup(false)
  }; 
  const HandleLogout = () => {
    signOut();
  };

  return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
          <img src={Expired} alt="Expired" width={"25%"} />
        </Box>
        <FormControl>
          <FormLabel id="gender-label">
            <Typography sx={{ color: "#000000" }} variant="h5">
              {popupContent}
            </Typography>
          </FormLabel>
        </FormControl>
        {planExpired && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {userInfoData?.user_type !== "employee" && (
              <>
                <Button
                  onClick={HandleLogout}
                  variant="contained"
                  type="submit"
                  className="mpl-primary-btn profile-btn"
                  size="small"
                  sx={{
                    margin: "10px",
                    color: grey[50],
                    ":hover": {
                      backgroundColor: "#f9c307",
                      color: "#000000",
                    },
                  }}
                >
                  Logout
                </Button>
                { showbutton && userInfoData?.post_req_count === "0" && (
                  <Button
                    onClick={HandleExpireRequest}
                    variant="contained"
                    type="submit"
                    className="mpl-primary-btn profile-btn"
                    size="small"
                    sx={{
                      margin: "10px",
                      color: grey[50],
                      ":hover": {
                        backgroundColor: "#f9c307",
                        color: "#000000",
                      },
                    }}
                  >
                    Request Admin
                  </Button>
                )}
              </>
            )}
            {userInfoData?.user_type === "employee" && (
              <Button
                onClick={HandleLogout}
                variant="contained"
                type="submit"
                className="mpl-primary-btn profile-btn"
                size="small"
                sx={{
                  margin: "10px",
                  color: grey[50],
                  ":hover": {
                    backgroundColor: "#f9c307",
                    color: "#000000",
                  },
                }}
              >
                Logout
              </Button>
            )}
          </Box>
        )}
        {planRemain && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <>
              <Button
               onClick={HandleRemaind}
                
                variant="contained"
                type="submit"
                className="mpl-primary-btn profile-btn"
                size="small"
                sx={{
                  margin: "10px",
                  color: grey[50],
                  ":hover": {
                    backgroundColor: "#f9c307",
                    color: "#000000",
                  },
                }}
              >
                Remind Later
              </Button>
              <Button
                onClick={HandleRequest}
                variant="contained"
                type="submit"
                className="mpl-primary-btn profile-btn"
                size="small"
                sx={{
                  margin: "10px",
                  color: grey[50],
                  ":hover": {
                    backgroundColor: "#f9c307",
                    color: "#000000",
                  },
                }}
              >
                Request Admin
              </Button>
            </>
          </Box>
        )}
      </Box>
    
  );
};

export default PlanExpired;
