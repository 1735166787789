import React, { useState } from "react";
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStyles } from "./SignIn.js";
import Meepl_logo from "../image/Meepl_Logo.jpeg";
import history from "../../../../history.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectableSearch from "../../Base/views/SelectableSearch";
import { useSelector } from "react-redux";

const PasswordPage = ({
  signinData,
  setSigninData,
  error,
  handleClick,
  handleKeyDown,
  nextLogin,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { workSpaceData } = useSelector((state) => state.SignInReducer);
  const [workspace, setWorkSpace] = useState({ load: true, values: [] });
  const storePassword = (e) => {
    const { name, value } = e.target;
    setSigninData((preData) => ({ ...preData, [name]: value }));
  };
  const storeWorkSpace = (event, value) => {
    if (["domain"].includes(event)) {
      if (value) setSigninData({ ...signinData, [event]: value });
      else setSigninData({ ...signinData, [event]: "" });
    }
  };
  
  const handleForgotPasswordClick = () => {
    history.push({
      pathname: "/forgotPassword",
      state: { email: signinData?.email},
    });
  };

  return (
    <Box className="password-page">
      {/* FORM SIGN IN*/}

      <img
        src={Meepl_logo}
        alt="Logo"
        style={{
          width: "300px",
          height: "auto",
          borderRadius: "4%",
          marginBottom: "10%",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      />

      <Typography className="head-sign-sec-h5" variant="h5">
        Sign In
      </Typography>
      <FormControl className={classes.root} fullWidth sx={{ mt: 2 }}>
        <SelectableSearch
          label="WorkSpace"
          fieldLabel="domainname"
          loading={() => setWorkSpace({ load: false, values: workSpaceData })}
          variantType={"standard"}
          name="domain"
          data={workspace}
          size="medium"
          onChangeEvent={storeWorkSpace}
          value={signinData?.domain}
          error={error.domain}
          helperText={error.domain && "Workspace is required"}
        />
      </FormControl>
      <TextField
        sx={{
          padding: "12px 4px",
          margin: "8px 0",
          width: "100%",
        }}
        variant="standard"
        margin="normal"
        size="medium"
        label="Password"
        onKeyDown={handleKeyDown}
        className={classes.root}
        onChange={storePassword}
        type={showPassword ? "text" : "password"}
        name="password"
        error={error?.password}
        helperText={error?.password && "Password requiered"}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          onClick={() => nextLogin(false)}
          sx={{ color: "black", alignSelf: "start" }}
          variant="text"
        >
          <ArrowBackIcon style={{ fontSize: "35px" }} />
        </Button>
        <Link
         className="forget-sec"
         onClick={handleForgotPasswordClick}
          variant="body2"
          sx={{
            cursor: "pointer",
            fontSize: "18px",
            color: "black",
          }}
        >
          Forgot Password
        </Link>
        <Button onClick={handleClick} sx={{ color: "black" }} variant="text">
          <ArrowForwardIcon style={{ fontSize: "35px" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default PasswordPage;
