import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetLeaveReasonHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/getLeaveReasons`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddLeaveReasonHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/addLeaveReason`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            reason_name: params?.reason_name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Reason added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateLeaveReasonHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/updateLeaveReason`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            leave_reason_id: params?.leave_reason_id,
            user_empid: data?.employee_id,
            reason_name: params?.reason_name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Reason Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteLeaveReasonHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/deleteLeaveReason`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            leave_reason_id: params?.leave_reason_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Reason Deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}