import * as ActionTypes from '../ActionTypes';

export const GetWeekOffConfigureAction = () => {
    return {
        type: ActionTypes.WEEK_OFF_CONFIGURE_GET_REQUEST,
    }
}


export const AddWeekOffConfigureAction = (params) => {
    return {
        type: ActionTypes.WEEK_OFF_CONFIGURE_ADD_REQUEST,
        params
    }
}

export const UpdateWeekOffConfigureAction = (params) => {
    return {
        type: ActionTypes.WEEK_OFF_CONFIGURE_UPDATE_REQUEST,
        params
    }
}
