import MaterialTable from "material-table";
import { Autocomplete, Box, Button, FormControl, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AddSkillsAction, DeleteSkillsAction, GetMeeplAdminSkillsAction, GetSkillsAction, UpdateSkillsAction } from "../../../../../store/ATS/Skills/Action";
import SelectableSearch from "../../../Base/views/SelectableSearch";

const Skills = () => {
    const dispatch = useDispatch()
    const { skillsData } = useSelector(state => state.AtsReducer?.SkillsReducer);
    const { industryTypeData } = useSelector(state => state.AtsReducer?.IndustryTypeReducer);
    const [industryTypedataList, setIndustryTypedataList] = useState({ load: true, values: [] })
    const [filters, setFilters] = useState([]);
    const domain_name = sessionStorage.getItem("userManagement")

    useEffect(() => {
        domain_name === "meepladmin" ? dispatch(GetMeeplAdminSkillsAction({ industry_code: filters?.industry_name?.code || null }))
            : dispatch(GetSkillsAction({ industry_code: filters?.industry_name?.code || null }))

        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [filters])

    const handleFilter = (event, value) => {
        if (["industry_name"].includes(event)) {
            setFilters({ [event]: value })
        }
    }
    const handleLoading = () => {
        setIndustryTypedataList({ load: false, values: industryTypeData })
    }

    const filter = [
        <FormControl fullWidth sx={{ minWidth: "300px", p: 1, ml: -1 }}>
            <SelectableSearch
                isOptionEqualToValue={(option, value) =>
                    +option.industry_type_id === +value.industry_type_id
                }
                onBlur={() => setIndustryTypedataList({ load: false, values: [] })}
                label="Industry Sector"
                value={filters?.industry_name}
                loading={() => { handleLoading() }}
                fieldLabel="industry_name"
                size="small"
                variant={"outlined"}
                name="industry_name"
                onChangeEvent={(e, value) => handleFilter(e, value)}
                data={industryTypedataList}
            />
        </FormControl>]

    return (
        <>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "Industry Sector", cellStyle: { padding: "0px 10px" }, field: "industry_name", validate: rowData => rowData?.tableData?.editing === 'update' && (rowData?.industry_name === '' || rowData?.industry_name === undefined) ? 'Name cannot be empty' : '', editable: 'onUpdate',
                            editComponent: props => (
                                <Autocomplete
                                    defaultValue={{ industry_name: props.rowData?.industry_name || "", id: props.rowData?.industry_type_id || "" }}
                                    id="industry_type_id"
                                    options={industryTypeData}
                                    onChange={(e, data) => { props.onChange(data) }}
                                    size="small"
                                    required={true}
                                    getOptionLabel={option => { return option?.industry_name }}
                                    renderInput={(params) => <TextField size="small" {...params}
                                        variant="standard"
                                        error={props?.error}
                                        helperText={props?.helperText} />}
                                />
                            )
                        },
                        {
                            title: "Skill", cellStyle: { padding: "0px 10px" }, field: "skill", validate: rowData => rowData?.skill === '' || rowData?.skill === undefined ? 'Skill cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            ),
                        },
                    ]}
                    data={skillsData || []}
                    icons={{
                        Add: () => <Button component="span" variant="contained" startIcon={<AddIcon />} className="mpl-primary-btn"> Add</Button>,
                        Edit: () => <EditIcon />,
                        Delete: () => <DeleteOutlineIcon color='error' />
                    }}
                    editable={{
                        isEditable: rowData => !rowData?.is_public_data,
                        isDeletable: rowData => !rowData?.is_public_data,
                        onRowAdd: filters?.industry_name && filters?.industry_name !== null ? (newRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(AddSkillsAction({
                                    industry_code: filters?.industry_name?.code,
                                    skill_name: newRow.skill
                                }))
                                setTimeout(() => resolve(), 500);
                            }) : "",
                        onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(UpdateSkillsAction({
                                    industry_code: newRow?.industry_name?.code || newRow?.industry_code,
                                    skills_id: newRow.skill_id,
                                    skill_code: newRow.skill_code,
                                    skill_name: newRow.skill
                                }))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowDelete: (selectedRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(DeleteSkillsAction({ skill_code: selectedRow?.skill_code, industry_code: filters?.industry_name?.code || selectedRow?.industry_code }))
                                setTimeout(() => resolve(), 1000);
                            }),
                    }}
                    options={{
                        maxBodyHeight: "35vh",
                        minBodyHeight: "35vh",
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    title={filter}
                />
            </Box>
        </>
    )
}
export default Skills;