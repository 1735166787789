/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import MaterialTable from "material-table";
import { useState } from "react";
import Popup from "../../../Base/views/Popup";
import BankModal from "./BankModal";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Success from "../../../MyEmployee/Image/check.png";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  DeleteEmployeeBankAction,
  GetEmployeeBankAction,
} from "../../../../../store/Employee/Bank/Action";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from "@mui/icons-material/Add";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import { getUserData } from "../../../Base/helper/baseFunctions";
import history from "../../../../../history";
import { UpdateEmployeeOnboardStatusHelper } from "../../../Ancillary/ConfigureStatus/helper/ConfigureStatusHelper";
import { EmployeeValidateHelper } from "../../helper/EmployeeHelper";
import { AddMenuHeadingAction } from "../../../../../store/Menu/Action";

const BankDetails = ({
  setCurrentMode,
  OnboardingEmpData,
  disableEdit,
  newdata,
  currentMode,
  setActiveStep,
  OnboardEmployee,
}) => {
  const data = getUserData();
  const [editedRecord, setEditedRecord] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMoreVertIcon = Boolean(anchorEl);
  const handleMoreVertIconClose = () => {
    setAnchorEl(null);
  };
  // const handleMoreVertIconClick = (event, row) => {
  //     setCurrentData(row)
  //     setAnchorEl(event.currentTarget);
  // };
  const dispatch = useDispatch();
  const { empBankData } = useSelector(
    (state) => state?.EmployeeReducer?.EmployeeBankReducer
  );

  const handleConfirmDelete = (rowData) => {
    setAnchorEl(false);
    setOpenDialog(true);
    setCurrentData(rowData);
  };

  const handleAdd = () => {
    setEditedRecord();
    setOpenPopup(true);
  };

  const handleDelete = () => {
    setOpenDialog(false);
    var id = [];
    if (Array.isArray(currentData)) {
      currentData.forEach((item) => {
        id.push(item.emp_bankdet_id);
      });

      if (currentMode === "addemployee") {
        dispatch(
          DeleteEmployeeBankAction({
            emp_bankdet_id: `{${id}}`,
            employee_id: newdata?.employee_id,
          })
        );
      } else {
        dispatch(
          DeleteEmployeeBankAction({
            emp_bankdet_id: `{${id}}`,
            employee_id: OnboardingEmpData
              ? OnboardingEmpData?.employee_id
              : data?.employee_id,
          })
        );
      }
    } else if (currentMode === "addemployee") {
      dispatch(
        DeleteEmployeeBankAction({
          emp_bankdet_id: `{${currentData?.emp_bankdet_id}}`,
          employee_id: newdata?.employee_id,
        })
      );
    } else {
      dispatch(
        DeleteEmployeeBankAction({
          id: `{${currentData?.emp_bankdet_id}}`,
          employee_id: OnboardingEmpData
            ? OnboardingEmpData?.employee_id
            : data?.employee_id,
        })
      );
    }
  };

  const handleBulkDelete = (selectedRow) => {
    setCurrentData(selectedRow);
  };

  const addMode = (resetForm) => {
    resetForm();
    setEditedRecord(null);
    setOpenPopup(false);
  };
  const BackStepper = () => {
    if (OnboardEmployee) {
      setActiveStep(3);
    } else {
      setActiveStep(4);
    }
  };
  const handleComplete = () => {
    EmployeeValidateHelper({}).then((res) => {
      if (res?.data?.statuscode === 200) {
        if (res?.data?.data?.[0]?.fn_get_employee_form_validation) {
          UpdateEmployeeOnboardStatusHelper({}).then((res) => {
            if (res?.data?.statuscode === 200) {
              setSuccessPopup(true);
            }
          });
        } else {
          toast.error(
            "Please fill all mandatory fields",
            {
              duration: 2000,
            }
          );
        }
      }
    });
  };
  const HandleClose = () => {
    setSuccessPopup(false);
    sessionStorage.setItem("selectedMenu", "Home");
     dispatch(AddMenuHeadingAction({ heading: "Home" }));
    history.push("/home");
  };
  useEffect(() => {
    if (currentMode === "editemployee" && OnboardingEmpData) {
      dispatch(
        GetEmployeeBankAction({ employee_id: OnboardingEmpData?.employee_id })
      );
    } else if (currentMode === "addemployee") {
      dispatch(GetEmployeeBankAction({ employee_id: newdata?.employee_id }));
    } else {
      dispatch(GetEmployeeBankAction({ employee_id: data?.employee_id }));
    }
  }, []);

  const addButton = empBankData?.length < 2 && (
    <Button
      onClick={handleAdd}
      variant="contained"
      className="mpl-primary-btn"
      startIcon={<AddIcon />}
    >
      Add
    </Button>
  );

  return (
    <Box
      sx={{
        padding: OnboardEmployee ? "0px 0px 20px 25px" : "0px 0px 0px 0px",
      }}
    >
      {!disableEdit && (
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent:
              currentMode !== undefined ? "space-between" : "flex-end",
            marginRight: "10px",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          <Button
            className="mpl-primary-btn"
            sx={{ marginRight: "10px" }}
            type="submit"
            variant="contained"
            size="medium"
            onClick={BackStepper}
          >
            Back
          </Button>
          {!OnboardEmployee && currentMode !== undefined ? (
            <Button
              className="mpl-primary-btn"
              sx={{ marginRight: "10px" }}
              type="submit"
              variant="contained"
              size="medium"
              onClick={() => history.push("/myemployee")}
            >
              Back to List
            </Button>
          ) : null}
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              textAlign: currentMode !== undefined ? "center" : "center",
            }}
          >
            Bank Account
          </Typography>
          {OnboardEmployee ? (
            <Button
              className="mpl-primary-btn"
              sx={{ marginRight: "10px" }}
              type="submit"
              variant="contained"
              size="medium"
              onClick={handleComplete}
            >
              Complete Form
            </Button>
          ) : null}
        </Grid>
      )}
      <MaterialTable
        columns={[
          {
            title: "Account Number",
            field: "account_number",
            cellStyle: { padding: "0px" },
          },
          {
            title: "Account Holder",
            field: "account_holder",
            cellStyle: { padding: "0px" },
          },
          { title: "Bank", field: "bank_name", cellStyle: { padding: "0px" } },
          {
            title: "Branch",
            field: "bank_branch",
            cellStyle: { padding: "0px" },
          },
          {
            title: "IFSC Code",
            field: "ifsc_code",
            cellStyle: { padding: "0px" },
          },
          {
            title: "Account Type",
            field: "account_type",
            cellStyle: { padding: "0px" },
          },
        ]}
        data={empBankData}
        onSelectionChange={(selectedRow) => handleBulkDelete(selectedRow)}
        options={{
          maxBodyHeight: "59vh",
          minBodyHeight: "59vh",
          rowStyle: { fontSize: "13px" },
          headerStyle: {
            padding: "0px",
            fontWeight: "bold",
            paddingRight: "10px",
          },
          sorting: true,
          search: true,
          searchText: "",
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: false,
          paging: true,
          pageSizeOptions: [25, 50],
          pageSize: 25,
          paginationType: "stepped",
          showFirstLastPageButtons: false,
          paginationPosition: "bottom",
          exportButton: true,
          exportAllData: true,
          exportFileName: "Bank Details",
          addRowPosition: "first",
          selection: true,
          actionsColumnIndex: -1,
          showSelectAllCheckbox: true,
          showTextRowsSelected: true,
          grouping: false,
          columnsButton: true,
        }}
        actions={[
          // {
          //     icon: () => <MoreVertIcon />,
          //     position: "row",
          //     tooltip: "Setting",
          //     onClick: (_event, rowData) => handleMoreVertIconClick(_event, rowData),

          // },
          {
            icon: () => (
              <IconButton sx={{ padding: "0px" }}>
                <DeleteIcon />
              </IconButton>
            ),
            tooltip: "Delete",
            onClick: (_event, rowData) => {
              handleConfirmDelete(rowData);
            },
          },
        ]}
        title={addButton}
      />
      <Popup
        title={editedRecord ? "Edit Bank Detail" : "Add Bank Detail"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <BankModal
          data={data}
          newdata={newdata}
          currentMode={currentMode}
          OnboardingEmpData={OnboardingEmpData}
          empBankData={empBankData}
          editedRecord={editedRecord}
          addMode={addMode}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        openPopup={successPopup}
        setOpenPopup={setSuccessPopup}
        fullWidth={false}
        maxWidth="md"
        close={false}
        hideDividers={true}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
            <img src={Success} alt="Success" width={"15%"} />
          </Box>
          <Typography variant="h6">
            {" "}
            Employee onboarded successfully.
          </Typography>
          <Typography variant="h6">
            {" "}
            For more updates please visit It's Me section.{" "}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Button
              onClick={HandleClose}
              variant="contained"
              type="submit"
              className="mpl-primary-btn profile-btn"
              size="small"
              sx={{
                margin: "10px",
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Popup>
      <ConfirmationDialog
        title="Do you wish to proceed?"
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Country Management"
        OkButtonText="Delete"
        onSubmit={handleDelete}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={openMoreVertIcon}
        onClose={handleMoreVertIconClose}
      >
        <MenuItem key="edit">
          <BookOnlineIcon sx={{ fontSize: "15px" }} /> &nbsp;{" "}
          <Typography sx={{ fontSize: "15px" }}>Raise Ticket</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default BankDetails;
