import { toast } from "react-toastify";
import NProgress from 'nprogress';
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import { addSessionData } from "../../Base/helper/baseHelper";
import * as CryptoJS from "crypto-js";
import { validateEmailHelper } from "../../SignIn/helper/SigninHelper";

export const GetAtsJobAccessUsersHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/user/getAtsJobAccessUsers`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_empid: user?.ats_user_id,
            ats_jobpost_id: params?.ats_jobpost_id,
            ats_grouporg_id: params?.candidate_job_id || 0
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsAllowedUsersHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/user/getAtsAllowedUsers`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_empid: user?.ats_user_id,
            company_id: params?.company_id || null,
            consultancy_id: params?.consultancy_id || null,
            recruiter_id: +params?.recruiter_id,
            is_grouporg: user?.grouporg_flow || false
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsUserInfoHelper = async (params, dispatch) => {
    return await axiosInstance(`/ats/user/getAtsUserInfo`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params.domain,
            user_emp_id: params.userid
        }
    })
        .then((res) => {
            let data = { ...res.data.data?.[0], domain: params?.domain, employee_id: params.userid, name: res.data.data?.[0]?.user_name }
            addSessionData({ key: "userData", value: CryptoJS.AES.encrypt(JSON.stringify(data), 'userData').toString() });
            addSessionData({ key: "company_full_name", value: res.data.data?.[0].company_full_name });
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsUserHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/user/getAtsUser`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_type: params?.user_type || null
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
};


export const DeleteATSConsulUserHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/user/replaceRecruiter`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_empid: user?.ats_user_id,
            recruiter_data: params?.recruiter_data,
            ats_user_id: params?.ats_user_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const GetAtsUserCountHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/user/getAtsUserCount`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetEmpUserEmailHelper = async(params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/user/getEmpUsersEmail`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsAccessUserRecruiterHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/user/getAtsAccessUserRecruiter`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.ats_user_id,
            ats_company_id: params.ats_company_id || null,
            consultancy_id: params.consultancy_id || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const AddAtsUserHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/user/addAtsUser`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_emp_id: user?.ats_user_id,
            user_type: params?.user_type,
            first_name: params?.first_name,
            last_name: params?.last_name || "",
            role_id: params?.role_id,
            ats_company_id: params?.ats_company_id?.ats_company_id || null,
            consultancy_id: params?.consultancy_id?.consultancy_id || null,
            contact_no: params?.contact_no || "",
            email_id: params?.email_id?.email_id ? params?.email_id?.email_id : params?.email_id,
            access_all_jobs: params?.access_all_jobs || false,
            access_company_jobs: params?.access_company_jobs || false,
            access_consultancy_jobs: params?.access_consultancy_jobs || false,
            access_only_created_jobs: params?.access_only_created_jobs || false,
            ats_company_detail_id: params?.ats_company_detail_id && JSON.parse(params?.ats_company_detail_id)?.length > 0 ? params?.ats_company_detail_id : null,
            consultancy_detail_id: params?.consultancy_detail_id && JSON.parse(params?.consultancy_detail_id)?.length > 0 ? params?.consultancy_detail_id : null,
            access_invoice: params?.access_invoice || false,
            access_reports: params?.access_reports || false,
            enable_onboard_access: (params?.enable_onboard_access || false) || (params?.user_type === "Admin"),
            ats_grouporg_id: params?.ats_grouporg_id?.ats_grouporg_id || null,
            ats_grouporg_detail_id: params?.ats_grouporg_detail_id && JSON.parse(params?.ats_grouporg_detail_id)?.length > 0 ? params?.ats_grouporg_detail_id : null,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("User Created successfully")
            if ( params?.user_type  === 'Company' || params?.user_type === 'Consultancy' || params?.user_type === 'Group User') {
            validateEmailHelper({ domain: user?.domain, email: params?.email_id?.email_id ? params?.email_id?.email_id : params?.email_id  })
            }
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsUserHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/user/updateAtsUser`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_emp_id: user?.ats_user_id,
            user_type: params?.user_type,
            ats_users_id: params?.ats_users_id,
            first_name: params?.first_name,
            last_name: params?.last_name || "",
            role_id: params?.role_id,
            ats_company_id: params?.ats_company_id?.ats_company_id || null,
            consultancy_id: params?.consultancy_id?.consultancy_id || null,
            contact_no: params?.contact_no || "",
            email_id: params?.email_id?.email_id ? params?.email_id?.email_id : params?.email_id ,
            access_all_jobs: params?.access_all_jobs || false,
            access_company_jobs: params?.access_company_jobs || false,
            access_consultancy_jobs: params?.access_consultancy_jobs || false,
            access_only_created_jobs: params?.access_only_created_jobs || false,
            ats_company_detail_id: params?.ats_company_detail_id && JSON.parse(params?.ats_company_detail_id)?.length > 0 ? params?.ats_company_detail_id : null,
            consultancy_detail_id: params?.consultancy_detail_id && JSON.parse(params?.consultancy_detail_id)?.length > 0 ? params?.consultancy_detail_id : null,
            access_invoice: params?.access_invoice || false,
            access_reports: params?.access_reports || false,
            enable_onboard_access: (params?.enable_onboard_access || false) || (params?.user_type === "Admin"),
            ats_grouporg_id: params?.ats_grouporg_id?.ats_grouporg_id || null,
            ats_grouporg_detail_id: params?.ats_grouporg_detail_id && JSON.parse(params?.ats_grouporg_detail_id)?.length > 0 ? params?.ats_grouporg_detail_id : null,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("User Updated successfully")            
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteAtsUserHelper = async (params) => {
    var user = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/ats/deleteRecruiter`, {
        method: "DELETE",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            recruiter_id: params?.recruiter_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("User Deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const ValidateAtsUserEmailHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/user/validateAtsUserEmail`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            email_id: params?.email_id,
            user_type: params?.user_type || null
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
export const ValidateAtsCompConsulStatusHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/user/atscompconsulstatus`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            company_id: params?.company_id || null,
            consul_id: params?.consul_id || null
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
};

export const GetConsulUserJobHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/user/getconsuluserjob`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            consul_id: params?.consul_id || null
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


//ATS User EMmail
export const GetAtsUsersEmailHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/user/getAtsUsersEmail`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_empid: user?.ats_user_id,
            ats_user: params?.ats_user || "{}",
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}