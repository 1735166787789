import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
};


export const EmployeePayrollDetailsReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_EMPLOYEE_PAYROLL_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
            };
        case ActionTypes.GET_EMPLOYEE_PAYROLL_DETAILS_SUCCESS:
            return {
                ...state,
                empPayrollData: action.payload?.data?.data,
                isLoading: false,
                success: false
            };
        case ActionTypes.UPDATE_EMPLOYEE_PAYROLL_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false
            };
        case ActionTypes.UPDATE_EMPLOYEE_PAYROLL_DETAILS_SUCCESS:
            return {
                ...state,
                empPayrollData: action.payload?.data?.data,
                success: true,
                isLoading: false
            };
        case ActionTypes.UPDATE_EMPLOYEE_PAYROLL_DETAILS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true
            };
        default:
            return state;
    }
}
