import * as ActionTypes from './ActionTypes';

export const SignInAction = (user, dispatch) => {
    return {
        type: ActionTypes.SIGN_IN_REQUEST,
        user, dispatch
    }
}

export const GetWorkspaceAction = (params) => {
    return {
        type: ActionTypes.GET_WORKSPACE_REQUEST,
        params
    }
}