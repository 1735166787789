import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const SignUpCard = ({ toggle, setToggle }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        top: 0,
        right: 0,
        height: "100%",
        width: "50%",
        gap: 3,
      }}
    >
      <Typography
        className="head-sign-sec-h5"
        variant="h5"
      >
        Hello, Friend!
      </Typography>
      <Typography
        className="head-sign-sec-h3"
        variant="h3"
        sx={{ width: "70%" }}
      >
        Enter Your personal details and start your journey with us
      </Typography>
      {/* <Button
        className="button-sign-sec"
        onClick={() => setToggle(false)}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#ddb106")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#fecb03")}
      >
        Join Us
      </Button> */}
      <Link to="/requestDemo">
        <Button
          className="button-sign-sec"
        >
          Signup For Demo
        </Button>
      </Link>
    </Box>
  );
};

export default SignUpCard;
