import { Paper } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { getUserData } from "../helper/baseFunctions";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text-white"
            align="left"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="/">
                meepl
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const Footer = () => {
    const userData = getUserData();
    const emailToShow = userData?.office_email || userData?.email_id;
    const theme = useTheme();
    return (
        <Paper sx={{ zIndex:"9999",position: "fixed", bottom: 0, left: 16, right: 16, backgroundColor: `${theme.palette.mode === "dark" ? theme.palette?.dark?.main : theme.palette?.root?.main}` }}>
             
            <div style={{ display: "flex", justifyContent:emailToShow ? 'space-between' : 'flex-end', alignItems: "center" }}>
                <Copyright />
                {emailToShow && (
                    <Typography variant="body2" color="text-white" align="right">
                        Logged in as {emailToShow}
                    </Typography>
                )}
            </div>
        </Paper>
    )
}

export default Footer;