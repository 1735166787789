import * as ActionTypes from '../ActionTypes';

const initialState = {
    success_message: "",
    error: false,
    documentations_options: []
};

const DocumentationsReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.DOCUMENTATIONS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                documentations_options: []
            };
        case ActionTypes.DOCUMENTATIONS_GET_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: action.payload.has_error,
                documentations_options: action.payload?.data?.data
            };
        case ActionTypes.DOCUMENTATIONS_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.DOCUMENTATIONS_ADD_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: action.payload.has_error,
                documentations_options: action.payload?.data?.data
            };
        case ActionTypes.DOCUMENTATIONS_ADD_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                isLoading: false,
            };
        case ActionTypes.DOCUMENTATIONS_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.DOCUMENTATIONS_UPDATE_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: action.payload.has_error,
                documentations_options: action.payload?.data?.data
            };
        case ActionTypes.DOCUMENTATIONS_UPDATE_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                isLoading: false,
            };
        case ActionTypes.DOCUMENTATIONS_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.DOCUMENTATIONS_DELETE_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                error: action.payload.has_error,
                documentations_options: action.payload?.data?.data
            };
        case ActionTypes.DOCUMENTATIONS_DELETE_ERROR:
            return {
                ...state,
                error: action.payload.has_error,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default DocumentationsReducer;