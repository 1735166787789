export const addSessionData = ({ key, value }) => {
    sessionStorage.setItem(key, value);
  };
  
  export const removeSessionData = ({ key }) => {
    sessionStorage.removeItem(key);
  };
  
  export const getSessionData = ({ key }) => {
    return sessionStorage.getItem(key);
  };
  
  export const clearSessionData = () => {
    sessionStorage.clear();
  };