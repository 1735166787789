import * as ActionTypes from '../ActionTypes';

const CurrentTimeInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: ""
}

export const CurrentTimeReducer = function (state = CurrentTimeInitialState, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_TIME_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                current_time: []
            };
        case ActionTypes.CURRENT_TIME_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                current_time: action.payload?.data?.data
            };
        case ActionTypes.CURRENT_TIME_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        
        default:
            return state;
    }
}