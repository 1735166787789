import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetYearlyHolidayCalendarHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/getYearlyCalendar`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            year: params?.year
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddYearlyHolidayCalendarHelper = async (params) => {
    const year = parseInt(new Date(params?.date).getFullYear());
    toast.info("Saving...")
    var data = getUserData();
    let te_city = []
    params?.cityList_options?.map(rest => te_city.push({ 'x': +rest?.city_id }))
    return await axiosInstance(`/ancillary/lms/addYearlyCalendar`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            year: year,
            name: params?.name,
            city: `${JSON.stringify(te_city)}`,
            date: params?.date,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Holiday Saved Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateYearlyHolidayCalendarHelper = async (params) => {
    const year = parseInt(new Date(params?.date).getFullYear());
    toast.info("Saving...")
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/updateYearlyCalendar`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            holidaycalendar_id: params?.holidaycalendar_id,
            year: year,
            name: params?.name,
            date: params?.date,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Holiday Update Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteYearlyHolidayCalendarHelper = async (params) => {  
    toast.info("Deleting...")
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/deleteYearlyCalendar`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            holidaycalendar_id: params?.holidaycalendar_id,
            year: params?.year,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Holiday Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}