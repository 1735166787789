import * as ActionTypes from '../ActionTypes';

const initialLeaveDashboardState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
};

export const LeaveDashboardReducer = function (state = initialLeaveDashboardState, action) {
    switch (action.type) {
        case ActionTypes.LEAVE_OVERALL_BALANCE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                leaveOverallBalanceData: []
            };
        case ActionTypes.LEAVE_OVERALL_BALANCE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveOverallBalanceData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_OVERALL_BALANCE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.PENDING_LEAVE_APPROVAL_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                pendingLeaveApprovalData: []
            };
        case ActionTypes.PENDING_LEAVE_APPROVAL_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                pendingLeaveApprovalData: action.payload?.data?.data
            };
        case ActionTypes.PENDING_LEAVE_APPROVAL_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };

        default:
            return state;
    }
}