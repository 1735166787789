import * as ActionTypes from "../ActionTypes";

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: "",
  success: false,
  isCandidateLoading: true,
  sendCandidateEmail: false,
};

export const AtsCandidateReducer = function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PROPOSE_CANDIDATE_CONFIRMATION_REQUEST:
      return {
        ...state,
        candidate_data: action?.params?.candidate_data || [],
        job_data: action?.params?.job_data || [],
        kanban_data: action?.params?.kanban_data || [],
        sendCandidateEmail: !state?.sendCandidateEmail,
      };
    case ActionTypes.ATS_CANDIDATE_BY_EMAIL_GET_REQUEST:
      return {
        ...state,
        isCandidateLoading: true,
        error: false,
        success: false,
        atsCandidateByEmailData: [],
      };
    case ActionTypes.ATS_CANDIDATE_BY_EMAIL_GET_SUCCESS:
      return {
        ...state,
        isCandidateLoading: false,
        success: true,
        successMessage: action.payload?.message,
        atsCandidateByEmailData: action.payload?.data?.data,
      };
    case ActionTypes.ATS_CANDIDATE_BY_EMAIL_GET_ERROR:
      return {
        ...state,
        isCandidateLoading: false,
        error: action?.payload?.hasError,
        errorMessage: action.payload?.data?.error?.[0]?.message,
      };
    case ActionTypes.ATS_CANDIDATE_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        success: false,
      };
    case ActionTypes.ATS_CANDIDATE_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        atsCandidateData: action.payload?.data?.data,
      };
    case ActionTypes.ATS_CANDIDATE_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.hasError,
        errorMessage: action.payload?.data?.error?.[0]?.message,
      };
    case ActionTypes.ATS_CANDIDATE_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        success: false,
      };
    case ActionTypes.ATS_CANDIDATE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        atsCandidateData: action.payload?.data?.data,
      };
    case ActionTypes.ATS_CANDIDATE_DELETE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.hasError,
        errorMessage: action.payload?.data?.error?.[0]?.message,
      };
    case ActionTypes.ATS_QUICK_SEARCH_GET_REQUSET:
      return {
        ...state,
        isLoading: true,
        error: false,
        success: false,
      };
    case ActionTypes.ATS_QUICK_SEARCH_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        atsQuickSearchCandidateData: action.payload?.data?.data,
      };
    case ActionTypes.ATS_QUICK_SEARCH_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.hasError,
        errorMessage: action.payload?.data?.error?.[0]?.message,
      };

    case ActionTypes.ATS_CANDIDATE_ADD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        success: false,
      };
    case ActionTypes.ATS_CANDIDATE_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        atsCandidateData: action.payload?.data?.data,
      };
    case ActionTypes.ATS_CANDIDATE_ADD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.hasError,
        errorMessage: action.payload?.data?.error?.[0]?.message,
      };
    case ActionTypes.ATS_CANDIDATE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        success: false,
      };
    case ActionTypes.ATS_CANDIDATE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload?.message,
        atsCandidateData: action.payload?.data?.data,
      };
    case ActionTypes.ATS_CANDIDATE_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.hasError,
        errorMessage: action.payload?.data?.error?.[0]?.message,
      };
    default:
      return state;
  }
};
