import { put, call } from 'redux-saga/effects';
import {
    GetEmployeeFamilyHelper,
    AddEmployeeFamilyHelper,
    DeleteEmployeeFamilyHelper,
    UpdateEmployeeFamilyHelper,
    GetEmployeeBasicHelper,
    AddEmployeeBasicHelper,
    UpdateEmployeeBasicHelper,
    AddEmployeeBankHelper,
    UpdateEmployeeBankHelper,
    DeleteEmployeeBankHelper,
    GetEmployeeBankHelper,
    GetEmployeeEducationHelper,
    AddEmployeeEducationHelper,
    UpdateEmployeeEducationHelper,
    DeleteEmployeeEducationHelper,
    GetEmployeeWorkExperienceHelper,
    AddEmployeeWorkExperienceHelper,
    UpdateEmployeeWorkExperienceHelper,
    DeleteEmployeeWorkExperienceHelper,
    GetEmployeePersonaleHelper,
    UpdateEmployeePersonaleHelper,
    AddEmployeePersonaleHelper,
    DeleteEmployeePersonaleHelper,
    GetEmployeeOtherPersonaleHelper,
    UpdateEmployeeOtherPersonaleHelper,
    GetEmployeeWorkDetailHelper,
    UpdateEmployeeWorkDetailHelper,
    AddEmployeeWorkDetailHelper,
    GetEmployeesHelper,
    GetEmployeePayrollDetailseHelper,
    UpdateEmployeePayrollDetailsHelper,
    GetAtsEmployeesHelper,
    GetEmployeeAddressHelper,
    UpdateEmployeeAddressHelper,
    GetEmployeesFaceHelper
} from '../../app/Components/Employee/helper/EmployeeHelper';
import * as ActionTypes from './ActionTypes';

// Employees
export function* GetEmployeesSaga(payload) {
    const response = yield call(GetEmployeesHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEES_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEES_GET_ERROR, payload: response })
    }
}

export function* GetEmployeesFaceSaga(payload) {
    const response = yield call(GetEmployeesFaceHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEES_FACE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEES_FACE_GET_ERROR, payload: response })
    }
}

export function* GetAtsEmployeesSaga(payload) {
    const response = yield call(GetAtsEmployeesHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ATS_EMPLOYEES_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.ATS_EMPLOYEES_GET_ERROR, payload: response })
    }
}

export function* GetEmployeeBasicSaga(payload) {
    const response = yield call(GetEmployeeBasicHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.GET_EMPLOYEE_BASIC_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.GET_EMPLOYEE_BASIC_ERROR, payload: response })
    }
}


export function* AddEmployeeBasicSaga(payload) {
    const response = yield call(AddEmployeeBasicHelper, payload.basicDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ADD_EMPLOYEE_BASIC_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.ADD_EMPLOYEE_BASIC_ERROR, payload: response })
    }
}

export function* UpdateEmployeeBasicSaga(payload) {
    const response = yield call(UpdateEmployeeBasicHelper, payload.basicDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.UPDATE_EMPLOYEE_BASIC_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.UPDATE_EMPLOYEE_BASIC_ERROR, payload: response })
    }
}

export function* GetEmployeeFamilySaga(payload) {
    const response = yield call(GetEmployeeFamilyHelper, payload.familyData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_FAMILY_GET_SUCCESS, payload: response })
    }
    else {
        yield put({ type: ActionTypes.EMPLOYEE_FAMILY_GET_ERROR, payload: response })
    }
}

export function* AddEmployeeFamilySaga(payload) {
    const response = yield call(AddEmployeeFamilyHelper, payload.familyData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_FAMILY_ADD_SUCCESS, payload: response })
    }
    else {
        yield put({ type: ActionTypes.EMPLOYEE_FAMILY_ADD_ERROR, payload: response })
    }
}

export function* UpdateEmployeeFamilySaga(payload) {
    const response = yield call(UpdateEmployeeFamilyHelper, payload.familyData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_FAMILY_UPDATE_SUCCESS, payload: response })
    }
    else {
        yield put({ type: ActionTypes.EMPLOYEE_FAMILY_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteEmployeeFamilySaga(payload) {
    const response = yield call(DeleteEmployeeFamilyHelper, payload.familyData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_FAMILY_DELETE_SUCCESS, payload: response })
    }
    else {
        yield put({ type: ActionTypes.EMPLOYEE_FAMILY_DELETE_ERROR, payload: response })
    }
}

export function* GetEmployeeBankSaga(payload) {
    const response = yield call(GetEmployeeBankHelper, payload.bankDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.GET_EMPLOYEE_BANK_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.GET_EMPLOYEE_BANK_ERROR, payload: response })
    }
}

export function* AddEmployeeBankSaga(payload) {
    const response = yield call(AddEmployeeBankHelper, payload.bankDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ADD_EMPLOYEE_BANK_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.ADD_EMPLOYEE_BANK_ERROR, payload: response })
    }
}

export function* UpdateEmployeeBankSaga(payload) {
    const response = yield call(UpdateEmployeeBankHelper, payload.bankDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.UPDATE_EMPLOYEE_BANK_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.UPDATE_EMPLOYEE_BANK_ERROR, payload: response })
    }
}

export function* DeleteEmployeeBankSaga(payload) {
    const response = yield call(DeleteEmployeeBankHelper, payload.bankDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DELETE_EMPLOYEE_BANK_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.DELETE_EMPLOYEE_BANK_ERROR, payload: response })
    }
}

export function* GetEmployeeEducationSaga(payload) {
    const response = yield call(GetEmployeeEducationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_EDUCATION_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_EDUCATION_GET_ERROR, payload: response })
    }
}

export function* AddEmployeeEducationSaga(payload) {
    const response = yield call(AddEmployeeEducationHelper, payload.educationData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_EDUCATION_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_EDUCATION_ADD_ERROR, payload: response })
    }
}

export function* UpdateEmployeeEducationSaga(payload) {
    const response = yield call(UpdateEmployeeEducationHelper, payload.educationData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_EDUCATION_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_EDUCATION_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteEmployeeEducationSaga(payload) {
    const response = yield call(DeleteEmployeeEducationHelper, payload.educationData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_EDUCATION_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_EDUCATION_DELETE_ERROR, payload: response })
    }
}
// Work Experience
export function* GetEmployeeWorkExperienceSaga(payload) {
    const response = yield call(GetEmployeeWorkExperienceHelper, payload.workExperienceData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_GET_ERROR, payload: response })
    }
}

export function* AddEmployeeWorkExperienceSaga(payload) {
    const response = yield call(AddEmployeeWorkExperienceHelper, payload.workExperienceData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_ADD_ERROR, payload: response })
    }
}

export function* UpdateEmployeeWorkExperienceSaga(payload) {
    const response = yield call(UpdateEmployeeWorkExperienceHelper, payload.workExperienceData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteEmployeeWorkExperienceSaga(payload) {
    const response = yield call(DeleteEmployeeWorkExperienceHelper, payload.workExperienceData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_EXPERIENCE_DELETE_ERROR, payload: response })
    }
}

// Work Detail
export function* GetEmployeeWorkDetailSaga(payload) {
    const response = yield call(GetEmployeeWorkDetailHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_DETAIL_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_DETAIL_GET_ERROR, payload: response })
    }
}


export function* AddEmployeeWorkDetailSaga(payload) {
    const response = yield call(AddEmployeeWorkDetailHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_DETAIL_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_DETAIL_ADD_ERROR, payload: response })
    }
}
export function* UpdateEmployeeWorkDetailSaga(payload) {
    const response = yield call(UpdateEmployeeWorkDetailHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_DETAIL_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_WORK_DETAIL_UPDATE_ERROR, payload: response })
    }
}

export function* GetEmployeePersonalSaga(payload) {
    const response = yield call(GetEmployeePersonaleHelper, payload.personalData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_PERSONAL_GET_SUCCESS, payload: response })
    }
}

export function* AddEmployeePersonalSaga(payload) {
    const response = yield call(AddEmployeePersonaleHelper, payload.personalData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_PERSONAL_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_PERSONAL_ADD_ERROR, payload: response })
    }
}

export function* UpdateEmployeePersonalSaga(payload) {
    const response = yield call(UpdateEmployeePersonaleHelper, payload.personalData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_PERSONAL_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_PERSONAL_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteEmployeePersonalSaga(payload) {
    const response = yield call(DeleteEmployeePersonaleHelper, payload.personalData)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_PERSONAL_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_PERSONAL_DELETE_ERROR, payload: response })
    }
}

export function* GetEmployeeOtherPersonalSaga(payload) {
    const response = yield call(GetEmployeeOtherPersonaleHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_OTHER_PERSONAL_GET_SUCCESS, payload: response })
    }
}

export function* UpdateEmployeeOtherPersonalSaga(payload) {
    const response = yield call(UpdateEmployeeOtherPersonaleHelper, payload.personalDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_OTHER_PERSONAL_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_OTHER_PERSONAL_UPDATE_ERROR, payload: response })
    }
}

export function* GetEmployeeAddressSaga(payload) {
    const response = yield call(GetEmployeeAddressHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_ADDRESS_GET_SUCCESS, payload: response })
    }
}

export function* UpdateEmployeeAddressSaga(payload) {
    const response = yield call(UpdateEmployeeAddressHelper, payload.addressDetails)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_ADDRESS_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_ADDRESS_UPDATE_ERROR, payload: response })
    }
}

export function* GetEmployeePayrollDetailsSaga(payload) {
    const response = yield call(GetEmployeePayrollDetailseHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.GET_EMPLOYEE_PAYROLL_DETAILS_SUCCESS, payload: response })
    }
}

export function* UpdateEmployeePayrollDetailsSaga(payload) {
    const response = yield call(UpdateEmployeePayrollDetailsHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.UPDATE_EMPLOYEE_PAYROLL_DETAILS_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.GET_EMPLOYEE_PAYROLL_DETAILS_ERROR, payload: response })
    }
}

