export const HRMS_UTILITY_API_URL = process.env.REACT_APP_HRMS_UTILITY_API_URL
export const HRMS_EMPLOYEE_API_URL = process.env.REACT_APP_HRMS_EMPLOYEE_API_URL
export const HRMS_LMS_API_URL = process.env.REACT_APP_HRMS_LMS_API_URL
export const HRMS_FINANCE_API_URL = process.env.REACT_APP_HRMS_FINANCE_API_URL
export const HRMS_ATS_API_URL = process.env.REACT_APP_HRMS_ATS_API_URL
export const SIGN_IN_SECRET = process.env.REACT_APP_SIGN_IN_SECRET
export const SIGN_IN_INITIAL_VECTOR = process.env.REACT_APP_SIGN_IN_INITIAL_VECTOR

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const ATS_CONTACT_PK = process.env.REACT_APP_ATS_CONTACT_PK
export const ATS_JOBPOST_PK = process.env.REACT_APP_ATS_JOBPOST_PK
export const ATS_USERS_PK = process.env.REACT_APP_ATS_USERS_PK
export const REQUEST_DEMO_PK = process.env.REACT_APP_REQUEST_DEMO_PK
export const ADMIN_WORKSPACE = process.env.REACT_APP_ADMIN_WORKSPACE

export const APP_NAME = process.env.REACT_APP_APP_NAME
export const ATS_CANDIDATE_JOB = process.env.REACT_APP_ATS_CANDIDATE_JOB
export const ATS_CANDIDATE = process.env.REACT_APP_ATS_CANDIDATE
export const ATS_INVOICE = process.env.REACT_APP_ATS_INVOICE
export const ATS_TASK = process.env.REACT_APP_ATS_TASK

export const JOB_LINK_SECRET = process.env.REACT_APP_JOB_LINK_SECRET
export const JOB_LINK_INITIAL_VECTOR = process.env.REACT_APP_JOB_LINK_INITIAL_VECTOR
export const HRMS_RESUME_PARSER_API = process.env.REACT_APP_HRMS_RESUME_PARSER_API


export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const API_KEY = process.env.REACT_APP_API_KEY
export const SCOPES = process.env.REACT_APP_SCOPES