import { Box } from "@mui/material";
import Base from "../../../../Base/views/Base";
import { NoData } from "../../../../Base/views/NotAuthorized";

const AtsReportRevenuePage = () => {
  return (
    <Base>
      <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
        <Box sx={{ textAlign: "center", p: 10 }}>
          <NoData title="We are Coming Soon with this Report!" />
        </Box>
      </Box>
    </Base>
  );
};

export default AtsReportRevenuePage;
