import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
export default function BasicModal({ handleCloseModal, open }) {
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="popup-sec">
        <div
          style={{
            color: "white",
            justifyContent: "cenrer",
            textAlign: "center",
          }}
        >
          <h5 className="title">Invalid User</h5>
          <h5 className="title">
            {" "}
            Please Contact your Workspace administrator
            <br />
            or Join us
          </h5>
        </div>

        <Button
          sx={{
            backgroundColor: "rgb(250,207,57)",
            borderRadius: "15px",
            color: "black",
            width: "13%",
            alignSelf: "center",
            padding: "2%",
          }}
          z
          style={{ fontWeight: "bold", fontSize: "16px" }}
          onClick={handleCloseModal}
          onMouseOver={(e) =>
            (e.target.style.backgroundColor = "rgb(255,180,1)")
          }
          onMouseOut={(e) =>
            (e.target.style.backgroundColor = "rgb(250,207,57)")
          }
        >
          OK
        </Button>
      </Box>
    </Modal>
  );
}
