import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading: true,
    error: false,
    errorMessage: "",
    success: false,
    access: [],
};

const infoState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
};

export const UserAccessReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.USER_ROLE_ACCESS_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.USER_ROLE_ACCESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: true,
                access: action.payload?.data?.data
            };
        default:
            return state;
    }
}

export const UserInfoReducer = function (state = infoState, action) {
    switch (action.type) {
        case ActionTypes.USER_INFO_REQUEST:
            return {
                ...state,
                infoLoading: true,
                error: false,
                infoSuccess: false,
            };
        case ActionTypes.USER_INFO_SUCCESS:
            return {
                ...state,
                infoLoading: false,
                error: false,
                infoSuccess: true,
                data: action.payload?.data
            };
        default:
            return state;
    }
}