import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
};

export const AtsGroupCompanyReducer = function (state = initialState, action) {
    switch (action.type) {

        case ActionTypes.ATS_GROUP_COMPANY_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_GROUP_COMPANY_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsGroupCompanyData: action.payload?.data?.data
            };
        case ActionTypes.ATS_GROUP_COMPANY_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_GROUP_COMPANY_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ATS_GROUP_COMPANY_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsGroupCompanyData: action.payload?.data?.data
            };
        case ActionTypes.ATS_GROUP_COMPANY_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_GROUP_COMPANY_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ATS_GROUP_COMPANY_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsGroupCompanyData: action.payload?.data?.data
            };
        case ActionTypes.ATS_GROUP_COMPANY_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_GROUP_COMPANY_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ATS_GROUP_COMPANY_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsGroupCompanyData: action.payload?.data?.data
            };
        case ActionTypes.ATS_GROUP_COMPANY_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}