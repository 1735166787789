import { createRef, useEffect, useState } from "react"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridWeek from "@fullcalendar/timegrid"
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction"
import { FormControl, Backdrop, Box, Card, CardHeader, CardContent, CircularProgress, Grid, IconButton, Paper, Tooltip, Typography, Divider } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Menu from "@mui/material/Menu";
import SelectableSearch from '../../../Base/views/SelectableSearch';
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import MuiToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import { gapi } from "gapi-script";
import Base from '../../../Base/views/Base'
import { toast } from "react-toastify";
import "../../css/styles.css"
import Popup from "../../../Base/views/Popup";
import InterviewScheduleForm from "./InterviewScheduleForm";
import { useDispatch, useSelector } from "react-redux";
import { GetAtsInterviewDetaiilsAction } from "../../../../../store/ATS/InterviewDetails/Action";
import { GetAtsUserAction } from '../../../../../store/ATS/User/Action';
import { GetAtsDashboardHelper } from "../../helper/AtsDashboardHelper";
import active_job from "../../images/active_job.png";
import interviews_scheduled from "../../images/interviews_scheduled.png";
import positions_yet_to_close from "../../images/positions_yet_to_close.png";
import Chart from 'react-apexcharts';
import { getUserData } from "../../../Base/helper/baseFunctions";
import { GetRecruiterPerformanceReportHelper } from '../../../ATS/helper/AtsRecruiterPerformanceHelper'
import { GetRecruiterPerformanceHelper } from '../../../ATS/helper/AtsRecruiterPerformanceHelper'
import { GetRecruiterHelper } from '../../../ATS/helper/AtsRecruiterPerformanceHelper'
import { GetATSModuleFeaturesAction } from "../../../../../store/RoleAccess/ATSModuleFeatures/Action";
import { PowerUserDisabledAction } from "../../../../../store/RoleAccess/Permission/Action";
import LoginForm from "./LoginForm";
import { API_KEY, CLIENT_ID, SCOPES } from "../../../../Constants/baseConstant";
import { GoogleLogout } from "react-google-login";
import AccessWarning from "./AccessWarning";


const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
    margin: theme.spacing(1),
    border: 'none !important',
    padding: "7px",
    '&:not(:first-of-type)': {
        borderRadius: `${theme.shape.borderRadius}px !important`
    },
    '&:first-of-type': {
        borderRadius: `${theme.shape.borderRadius}px !important`
    }
}))

function AtsDashboard() {

    const dispatch = useDispatch()
    const calendarRef = createRef()
    const [title, setTitle] = useState()
    const [performanceData, setperformanceData] = useState([])
    const [viewType, setViewType] = useState('dayGridMonth')
    const [selectedDate, setSelectedDate] = useState()
    const [dashboardData, setDashboardData] = useState({})
    const [currentData, setCurrentData] = useState(null)
    const [openPopup, setOpenPopup] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const [openAccess, setOpenAccess] = useState(false);
    const accessTokenforGoogle = localStorage.getItem('accessTokenforGoogle');
    const GoogleUserInfo = JSON.parse(localStorage.getItem("GoogleUserInfo"));
    const [gapiInitialized, setGapiInitialized] = useState(false);
    const { atsInterviewDetailsData, isGetLoading } = useSelector(state => state.AtsReducer?.AtsInterviewDetailsReducer)
    const [atsuserData, setatsuserData] = useState({ load: true, values: [] });
    const [orgname, setOrg] = useState("");
    const [hasAccess, setHasAccess] = useState(false);
    const [accessgranted, setAccessGranted] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const userData = getUserData();
    const [barChartData, setBarChartData] = useState([]);
    const [reportTitle, setReportTitle] = useState("Indidual Performance Report");
    const date = new Date()
    const [filters, setFilters] = useState({ month_year: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`, recruiterid: "" });
    const [flag, setFlag] = useState(false);
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
    const interviewShedulePermission = featuresData?.find(item => item?.permission === "dashboard_schedule_interview")

    useEffect(() => {
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Dashboard" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        function start() {
            gapi.client
                .init({
                    apiKey: API_KEY,
                    clientId: CLIENT_ID,
                    discoveryDocs: [
                        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
                    ],
                    scope: SCOPES,
                })
                .then(() => {
                    const authInstance = gapi.auth2.getAuthInstance();
                    if (authInstance.isSignedIn.get()) {
                        const user = authInstance.currentUser.get();
                        setOrg(user?.wt?.Ad);
                        gapi.client.calendar.events.list({
                            'calendarId': 'primary',
                            'maxResults': 1,
                        }).then(response => {
                            setHasAccess(true);
                        }).catch(error => {
                            setHasAccess(false);
                        });
                    } else {
                        setHasAccess(false);
                    }
                    setGapiInitialized(true);
                });
        }
        gapi.load("client:auth2", start);
    }, [accessgranted]);


    useEffect(() => {
        let calendarApi = calendarRef.current.getApi()
        setTitle(calendarApi?.currentDataManager?.data?.viewTitle)
        dispatch(GetAtsInterviewDetaiilsAction({ month_year: calendarApi?.currentDataManager?.data?.viewTitle }))
        getDashboard()
        if (userData) {
            dispatch(GetAtsUserAction());
        }
        const [year, month] = filters?.month_year.split("-");
        GetRecruiterPerformanceHelper({
            month: month,
            year: year,
            recruiter: null
        }).then(res => {
            if (res?.data?.data.length) {
                setperformanceData(res?.data?.data)
                GetRecruiterPerformanceHelper({
                    month: month,
                    year: year,
                    recruiter: res?.data?.data?.[0]?.recruiterid
                }).then(res => {
                    if (res?.data?.statuscode === 200) {
                        setperformanceData(res?.data?.data)
                        setFilters({ ...filters, recruiterid: { recruiterid: res?.data?.data?.[0]?.recruiterid, recruiter_name: res?.data?.data?.[0]?.recruiter_name } })
                    }
                })
                setReportTitle(res?.data?.data?.[0]?.company_name)
                GetRecruiterPerformanceReportHelper({
                    recruiter: res?.data?.data?.[0]?.recruiterid,
                    company: res?.data?.data?.[0]?.company_name,
                    month: (res?.data?.data?.[0]?.month).toString(),
                    year: (res?.data?.data?.[0]?.year).toString(),

                }).then(res => {
                    if (res?.data?.statuscode === 200) {
                        setBarChartData(res?.data?.data);
                    }
                })
            }
        })
        GetRecruiterHelper({
            month: month,
            year: year,
        }).then(res => {
            if (res?.data?.statuscode === 200) {
                setatsuserData(res?.data?.data)
            }
        })

        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (flag) {
            const [year, month] = filters?.month_year.split("-");
            GetRecruiterPerformanceHelper({
                month: month,
                year: year,
                recruiter: filters?.recruiterid?.recruiterid

            }).then(res => {
                if (res?.data?.statuscode === 200) {
                    setperformanceData(res?.data?.data)
                }
            })
            GetRecruiterHelper({
                month: month,
                year: year,
            }).then(res => {
                if (res?.data?.statuscode === 200) {
                    setatsuserData(res?.data?.data)

                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const getDashboard = () => {
        GetAtsDashboardHelper().then(res => {
            setDashboardData(res?.data?.data?.[0])
        })
    }

    const convertToDetailedDate = (dateStr) => {
        // Parse the date string
        const parsedDate = new Date(dateStr);

        // Set the desired time (05:30:00)
        parsedDate.setHours(5, 30, 0, 0); // Set hours, minutes, seconds, and milliseconds

        // Format the date object to the required string format
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
        };
        return parsedDate.toLocaleString('en-US', options);
    }
    const handleDateClick = (arg) => {
        const selectedDate = new Date(arg.dateStr);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (!interviewShedulePermission) {
            if (selectedDate < today) {
                toast.error("You Can't Create event");
                return;
            }
            const formattedDate = convertToDetailedDate(arg.dateStr);
            setSelectedDate(formattedDate)
            setCurrentData()
            if (accessTokenforGoogle) {
                if (hasAccess) {
                    setOpenPopup(true)
                }
                else {
                    setOpenAccess(true)
                }
            }
            else {
                setOpenLogin(true)
            }

        }
        else dispatch(PowerUserDisabledAction())
    }

    const handleEventClick = (data) => {
        setCurrentData(data)
        if (accessTokenforGoogle) {
            setOpenPopup(true)
        }
        else {
            setOpenLogin(true)
        }
    }

    const handleLogin = () => {
        setOpenLogin(true)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onLogoutSuccess = () => {
        localStorage.removeItem('accessTokenforGoogle');
        localStorage.removeItem('GoogleUserInfo');
        setAnchorEl(null);
        setAccessGranted(false)
        toast.success("Logout success");
    };


    const formatTime = (dateTimeString) => {
        const options = {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        };
        return new Date(dateTimeString).toLocaleString("en-US", options);
    };
    const eventContent = ({ event }) => {
        const { extendedProps } = event;
        const { start_date, end_date, event_name } = extendedProps;
        return (
            <div
                style={{
                    position: "relative",
                    backgroundColor: "#f9fafb",
                    color: "#000000",
                    borderRadius: "5px",
                    fontFamily: "Nunito Sans",
                    wordWrap: "break-word",
                    padding: "5px 10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    cursor:"pointer"
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        width: "5px",
                        background: `repeating-linear-gradient(
                        45deg,
                        #883bd5,
                        #883bd5 2px,
                        white 2px,
                        white 4px
                    )`,
                        borderRadius: "5px 0 0 5px",
                    }}
                ></div>
                <div style={{ fontSize: "12px", marginBottom: "5px" }}>
                    {event_name}
                </div>
                <div style={{ fontSize: "12px", marginBottom: "5px" }}>
                    {`${formatTime(start_date)} – ${formatTime(end_date)}`}
                </div>
            </div>
        );
    };

    const handleAlignment = (event, newAlignment) => {
        setViewType(newAlignment)
    }
    const handleChange = (type) => {
        let calendarApi = calendarRef.current.getApi()
        if (type === "today") calendarApi.today()
        else if (type === "next") calendarApi?.next()
        else if (type === "prev") calendarApi?.prev()
        calendarApi = calendarRef.current.getApi()
        setTitle(calendarApi?.currentDataManager?.data?.viewTitle)
        viewType === "dayGridMonth" && dispatch(GetAtsInterviewDetaiilsAction({ month_year: calendarApi?.currentDataManager?.data?.viewTitle }))
    }

    const handleViewTypeChange = (viewType) => {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.changeView(viewType)
        calendarApi = calendarRef.current.getApi()
        setTitle(calendarApi?.currentDataManager?.data?.viewTitle)
    }
    const handleDonutChartClick = (event, chartContext, config) => {
        setReportTitle(config.w.config.labels[config.dataPointIndex])
        GetRecruiterPerformanceReportHelper({
            recruiter: performanceData?.[0]?.recruiterid,
            month: (performanceData?.[0]?.month).toString(),
            year: (performanceData?.[0]?.year).toString(),
            company: config.w.config.labels[config.dataPointIndex]

        }).then(res => {
            if (res?.data?.statuscode === 200) {
                setBarChartData(res?.data?.data);

            }
        })

    };
    const handleFilter = (event, value) => {
        if (["recruiterid"].includes(event)) {
            if (value) setFilters({ ...filters, [event]: value })
            else setFilters({ ...filters, [event]: "" })
        }
        if (["month_year"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value })
        }
        setFlag(true)
    }
    const chartData = performanceData.map((item) => ({
        x: item.company_name,
        y: Number(item.percentage),
    }));
    const donutChartData = {
        series: chartData.map((data) => data.y),
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                events: {
                    dataPointSelection: handleDonutChartClick,
                },
                type: 'donut',
                height: 450
            },
            legend: {
                position: 'bottom',
                fontFamily: 'Nunito Sans',
                fontWeight: 600,
            },
            labels: chartData.map((data) => data.x),
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
            plotOptions: {
                pie: {
                    donut: {
                        size: '65%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                                fontFamily: 'Nunito Sans',
                                fontWeight: 600,
                                color: "#8b44ff",
                                offsetY: -10,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Nunito Sans',
                                fontWeight: 400,
                                color: "#8b44ff",
                                offsetY: 16,
                                formatter: function (val) {
                                    return val
                                }
                            },

                        }
                    }
                }
            }
        },
    };

    const showDonutChart = donutChartData?.series.length === 0;
    const Result = barChartData.map(item => ({
        x: item.job_title,
        y: [parseInt(item.proposed), parseInt(item.joined)]
    }));
    const data = {
        series: [{
            name: "Proposed",
            data: Result.map(item => item.y[0])
        }, {
            name: "Joined",
            data: Result.map(item => item.y[1])
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: true,
                }
            },
            legend: {
                fontFamily: 'Nunito Sans',
                fontWeight: 600,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '22px',
                            fontFamily: 'Nunito Sans',
                            fontWeight: 600,
                            color: "#8b44ff",
                            offsetY: -10,
                            formatter: function (val) {
                                return val
                            }
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            fontFamily: 'Nunito Sans',
                            fontWeight: 400,
                            color: "#8b44ff",
                            offsetY: 16,
                            formatter: function (val) {
                                return val
                            }
                        },

                    }
                }
            },
            colors: [ // this array contains different color code for each data
                "#8b44ff",
                "#f9c307",
            ],
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false,
                fixed: {
                    enabled: false,
                    position: 'bottomRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            xaxis: {
                categories: barChartData.map(item => item.job_title),
            },

        }
    };
    return (
        <>
            <Base>
                {
                    isGetLoading && <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <Box sx={{ maxHeight: "87vh", overflowY: 'auto', pt: 4 }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs={4} md={4} lg={4}>
                            <Card sx={{ display: "block", overflow: "visible" }}>
                                <CardContent sx={{ padding: "12px !important" }}>
                                    <Box justifyContent={"space-between"} display="flex">
                                        <Typography sx={{ fontSize: 14 }} variant='subtitle2' color="primary.contrastText" gutterBottom>
                                            Active Jobs
                                        </Typography>
                                    </Box>
                                    <Box justifyContent={"space-between"} display="flex">
                                        <Typography textAlign="center" variant="h3" gutterBottom >
                                            {
                                                dashboardData?.no_of_jobs ? (dashboardData?.no_of_jobs).toLocaleString("en-US") : 0
                                            }
                                        </Typography>
                                        <img src={active_job} alt="Ratings" className="card-icon" />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Card sx={{ display: "block", overflow: "visible" }}>
                                <CardContent sx={{ padding: "12px !important" }}>
                                    <Box justifyContent={"space-between"} display="flex">
                                        <Typography sx={{ fontSize: 14 }} variant='subtitle2' color="primary.contrastText" gutterBottom>
                                            Interviews Scheduled Today
                                        </Typography>
                                    </Box>
                                    <Box justifyContent={"space-between"} display="flex">
                                        <Typography textAlign="center" variant="h3" gutterBottom >
                                            {
                                                dashboardData?.todays_interview_cnt ? (dashboardData?.todays_interview_cnt).toLocaleString("en-US") : 0
                                            }

                                        </Typography>
                                        <img src={interviews_scheduled} alt="Ratings" className="card-icon" />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Card sx={{ display: "block", overflow: "visible" }}>
                                <CardContent sx={{ padding: "12px !important" }}>
                                    <Box justifyContent={"space-between"} display="flex">
                                        <Typography sx={{ fontSize: 14 }} variant='subtitle2' color="primary.contrastText" gutterBottom>
                                            Positions Yet to Close
                                        </Typography>
                                    </Box>
                                    <Box justifyContent={"space-between"} display="flex">
                                        <Typography textAlign="center" variant="h3" gutterBottom >
                                            {
                                                dashboardData?.positions_yet_to_close ? (dashboardData?.positions_yet_to_close).toLocaleString("en-US") : 0
                                            }
                                        </Typography>
                                        <img src={positions_yet_to_close} alt="Ratings" className="card-icon" />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {
                        ["owner", "Owner", "Admin"].includes(userData?.user_type) && ['cvtmeepl', "xpohrmeepl", "altiio", "adobemeepl"].includes(userData?.domain) &&
                        <Grid mt={1} container display="flex" direction="row" justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Card>
                                    <CardHeader
                                        title={
                                            <Typography pb={1} variant="h6">
                                                Target Achievement Report
                                            </Typography>
                                        }
                                    />
                                    <FormControl sx={{ width: "50%", paddingLeft: "24px" }}>
                                        <CommonDatePicker
                                            size="small"
                                            name="month_year"
                                            openTo="month"
                                            views={['year', 'month']}
                                            label="Year and Month"
                                            pickerType="date"
                                            onKeyDown={(e) => e.preventDefault()}
                                            onKeyPress={(e) => e.preventDefault()}
                                            pickerFormat="MM/yyyy"
                                            DefaultDTvalue={filters.month_year}
                                            maxDate={new Date()}
                                            handleChange={(value) =>
                                                handleFilter({
                                                    target: {
                                                        name: "month_year",
                                                        value: value
                                                            ? `${value?.getFullYear() +
                                                            "-" +
                                                            ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                            "-" +
                                                            ("0" + value?.getDate()).slice(-2)
                                                            }`
                                                            : "",
                                                    },
                                                })
                                            }

                                        />
                                    </FormControl>
                                    <FormControl className="Dash_Picker" sx={{ width: "48%" }}>
                                        <SelectableSearch
                                            isOptionEqualToValue={(option, value) =>
                                                +option.ats_users_id === +value.ats_users_id
                                            }
                                            label="Recruiter"
                                            value={filters?.recruiterid}
                                            fieldLabel="recruiter_name"
                                            loading={() => setatsuserData({ load: false, values: atsuserData })}
                                            variant={"contained"}
                                            required
                                            name="recruiterid"
                                            size="small"
                                            data={atsuserData}
                                            onChangeEvent={handleFilter}
                                        />
                                    </FormControl>
                                    {showDonutChart === true ? (
                                        <>
                                            <Box className="my-donut-chart" sx={{ display: "flex", justifyContent: "center", height: "370px" }}>
                                                <Typography display="flex" justifyContent="center" alignItems="center">  Achievement Not Available </Typography>
                                            </Box>
                                        </>
                                    )
                                        :
                                        (
                                            <>
                                                <Box className="my-donut-chart" sx={{ display: "flex", justifyContent: "center" }}>
                                                    <Chart
                                                        options={donutChartData.options}
                                                        series={donutChartData.series}
                                                        type="donut"
                                                        width="380"
                                                        height="400"
                                                    />

                                                </Box>
                                            </>
                                        )}

                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Card className="my-bar-chart" sx={{ display: "block" }}>
                                    <CardHeader
                                        title={
                                            <Typography variant="h6">
                                                {reportTitle}
                                            </Typography>
                                        }
                                    />
                                    <Chart
                                        series={data.series}
                                        options={data.options}
                                        type="bar"
                                        height="400"
                                    />
                                </Card>
                            </Grid>

                        </Grid>
                    }
                    <Paper sx={{ borderRadius: "16px", mt: 1, backgroundColor: "#e6d9fb7a" }} elevation={2}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "20px",
                        }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <ToggleButtonGroup exclusive value={viewType} onChange={handleAlignment} aria-label='view type'>
                                    <ToggleButton onClick={() => handleViewTypeChange('dayGridMonth')} value='dayGridMonth' aria-label='Month'>
                                        <Tooltip title="Month">
                                            <ViewModuleIcon fontSize="small" />
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton onClick={() => handleViewTypeChange('timeGridWeek')} value='timeGridWeek' aria-label='Week'>
                                        <Tooltip title="Week">
                                            <ViewWeekIcon fontSize="small" />
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton onClick={() => handleViewTypeChange('timeGridDay')} value='timeGridDay' aria-label='Day'>
                                        <Tooltip title="Day">
                                            <ViewDayIcon fontSize="small" />
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton onClick={() => handleViewTypeChange('listWeek')} value='listWeek' aria-label='List Week'>
                                        <Tooltip title="Agenda">
                                            <ViewStreamIcon fontSize="small" />
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <IconButton onClick={() => handleChange('prev')}>
                                    <ArrowBackIosNewIcon sx={{ width: "0.7em", height: "0.75em" }} />
                                </IconButton>
                                <Typography variant="h5" sx={{ fontWeight: 700, fontSize: "1.2rem", margin: "0px 16px 0px 16px" }}>
                                    {title}
                                </Typography>
                                <IconButton onClick={() => handleChange('next')} >
                                    <ArrowForwardIosIcon sx={{ width: "0.7em", height: "0.75em" }} />
                                </IconButton>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {!accessTokenforGoogle ? (
                                    <button onClick={handleLogin} className="google-btn">
                                        <svg className="google-logo" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48">
                                            <path fill="#4285F4" d="M24 9.5c3.7 0 6.3 1.6 7.7 2.9l5.7-5.5C33.9 3.8 29.4 2 24 2 14.6 2 7.1 7.7 4.1 15.5l6.7 5.2c1.4-5.6 6.3-11.2 13.2-11.2z"></path>
                                            <path fill="#34A853" d="M46.6 24.5c0-1.7-.1-3.4-.4-5H24v9.5h12.7c-.5 2.7-2.1 5.3-4.6 6.9l7.2 5.5c4.2-3.9 6.6-9.7 6.6-16.9z"></path>
                                            <path fill="#FBBC05" d="M10.8 28.5c-1.2-3.1-1.2-6.5 0-9.6l-6.7-5.2c-2.5 5-2.5 10.8 0 15.9l6.7-5.2z"></path>
                                            <path fill="#EA4335" d="M24 46c5.4 0 9.9-1.8 13.2-4.9l-7.2-5.5c-2.1 1.4-4.8 2.3-7.7 2.3-6.9 0-12.7-4.6-14.7-11.2L4 33.5C7.1 41.3 14.6 46 24 46z"></path>
                                            <path fill="none" d="M0 0h48v48H0z"></path>
                                        </svg>
                                        <span className="btn-text">Login with Google</span>
                                    </button>
                                ) : (
                                    <Tooltip title={
                                        <div>
                                            <div style={{ marginBottom: "5px" }}><strong>Google Account</strong></div>
                                            <div style={{ marginBottom: "5px" }}>{GoogleUserInfo?.name}</div>
                                            <div style={{ marginBottom: "5px" }}>{GoogleUserInfo?.email}</div>
                                        </div>
                                    } >
                                        <img
                                            alt="Anonymous User"
                                            width="20%"
                                            onClick={handleMenu}
                                            style={{
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                                cursor: "pointer",
                                                borderRadius: "50%",
                                                width: "38px",
                                                height: "38px",
                                            }}
                                            src={GoogleUserInfo?.imageUrl}
                                        />
                                    </Tooltip>
                                )}
                                <div>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        sx={{ marginTop: "2.5rem" }}
                                    >
                                        <div className="appbar-user-name" style={{ padding: "0px 10px 5px 0px" }}>
                                            <div className="appbar-user-img">
                                                <img
                                                    alt="Anonymous User"
                                                    style={{
                                                        borderRadius: "50%",
                                                    }}
                                                    src={GoogleUserInfo?.imageUrl}
                                                />
                                            </div>
                                            <div className="appbar-user-details">
                                                <p className="appbar-user-name">
                                                    {GoogleUserInfo?.name}
                                                </p>
                                                <p className="appbar-user-role">
                                                    Google Account
                                                </p>
                                            </div>
                                        </div>
                                        <Divider orientation="horizontal" />
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <GoogleLogout
                                                clientId={CLIENT_ID}
                                                buttonText="Logout"
                                                onLogoutSuccess={onLogoutSuccess}
                                            />
                                        </Box>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                        <FullCalendar
                            headerToolbar={false}
                            // eventContent={renderEventContent}
                            plugins={[dayGridPlugin, interactionPlugin, timeGridWeek, listPlugin]}
                            initialView="dayGridMonth"
                            contentHeight={500}
                            events={atsInterviewDetailsData?.[0]?.interviewdet || []}
                            eventContent={eventContent}
                            dayMaxEvents={2}
                            dateClick={handleDateClick}
                            droppable={true}
                            editable={false}
                            eventClick={(info) => {
                                handleEventClick(info?.el?.fcSeg?.eventRange?.def?.extendedProps)
                            }
                            }
                            ref={calendarRef}
                            showNonCurrentDates={false}
                        />
                    </Paper>
                    <Popup
                        fullWidth={true}
                        maxWidth="md"
                        title="Schedule Interview"
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <InterviewScheduleForm getDashboard={getDashboard} currentData={currentData} setOpenPopup={setOpenPopup} editedRecord={null} date={selectedDate} gapiInitialized={gapiInitialized} orgname={orgname} title={title} />
                    </Popup>
                    <Popup
                        maxWidth="sm"
                        title="SignIn Using"
                        openPopup={openLogin}
                        setOpenPopup={setOpenLogin}
                    >
                        <LoginForm setOpenPopup={setOpenLogin} setAccessGranted={setAccessGranted} />
                    </Popup>
                    <Popup
                        maxWidth="sm"
                        title="Google Access"
                        openPopup={openAccess}
                        setOpenPopup={setOpenAccess}
                    >
                        <AccessWarning setOpenPopup={setOpenAccess} setAccessGranted={setAccessGranted} />
                    </Popup>
                </Box>
            </Base>
        </>

    )
}

export default AtsDashboard