import { Backdrop, Box, Button, CircularProgress } from "@mui/material"
import { useState } from "react"
import AddAdminUserPopup from "./AddAdminUserPopup"
import MaterialTable from "material-table"
import { useSelector } from "react-redux"
import ArrowBackButton from "../../../Base/views/ArrowBackButton"


const AdminUsers = ({ setAdminUsers }) => {

    const [openPopup, setOpenPopup] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const { isLoading, userData } = useSelector((state) => state?.MeeplAdminReducer?.MeeplAdminUserReducer);
    let data = atob(sessionStorage.getItem("user_type"));

    const handleEdit = (data) => {
        setCurrentData(data);
        setOpenPopup(true);
    }

    return (
        <>
            {
                openPopup &&
                <AddAdminUserPopup currentData={currentData} setOpenPopup={setOpenPopup} />
            }
            {
                isLoading && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <Box
                sx={{
                    display: "flex",
                    WebkitBoxPack: "justify",
                    justifyContent: "space-between",
                    p: 1
                }}
            >
                <ArrowBackButton onClick={() => setAdminUsers(false)} />
                {
                    data === "Owner" &&
                    <Button className="mpl-primary-btn" variant="contained" onClick={() => setOpenPopup(true)}>Add User</Button>
                }
            </Box>
            <Box sx={{ padding: "10px" }}>
                <MaterialTable
                    onRowClick={(event, rowData) => handleEdit(rowData)}
                    columns={[
                        { title: "First Name", cellStyle: { padding: "10px", textAlign: "left" }, field: "first_name" },
                        { title: "Last Name", cellStyle: { padding: "10px", textAlign: "left" }, field: "last_name" },
                        { title: "Email", cellStyle: { padding: "10px", textAlign: "left" }, field: "email_id" },
                        { title: "User Type", cellStyle: { padding: "10px", textAlign: "left" }, field: "user_type" },
                    ]}
                    data={userData || []}
                    options={{
                        maxBodyHeight: "62vh",
                        minBodyHeight: "62vh",
                        rowStyle: { fontSize: "13px" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        showTextRowsSelected: false,
                        showSelectAllCheckbox: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                        columnsButton: false,
                        toolbar: false,
                    }}
                />
            </Box>
        </>
    )
}

export default AdminUsers