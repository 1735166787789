import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Paper, Tab, Box } from '@mui/material'
import { useState } from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import Dashboard from './Dashboard';
import "../css/style.css";
import Base from '../../Base/views/Base';
import AtsSettings from './AtsSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AtsOnboarding from './AtsOnboarding';
import { useLocation } from "react-router-dom";
import FormBuilderLandingPage from './FormBuilderLandingPage';
import OfferLetterLandingPage from './OfferLetterLandingPage';

const AtsSettingsLandingPage = () => {
    const location = useLocation();
    const defaultValue = location.state?.defaultValue || "";
    const [value, setValue] = useState(defaultValue? defaultValue : '1');
    const [accordian, setAccordian] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Base>
                <Paper className='ats-settings admin-setting' sx={{ borderRadius: "10px", minHeight: "87vh", maxHeight: "87vh", overflowY: "auto" }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList sx={{
                                minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                '& .MuiTabs-indicator': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: "center !important",
                                },
                            }} onChange={handleChange} aria-label="lab API tabs example">
                                <Tab icon={<DashboardIcon fontSize='small' />} iconPosition="start" label="Dashboard" value="1" />
                                <Tab icon={<ManageAccountsIcon fontSize='small' />} iconPosition="start" label="ATS" value="2" />
                                <Tab icon={<DirectionsBusIcon fontSize='small' />} iconPosition="start" label="Onboarding" value="3" />
                                <Tab icon={<DynamicFeedIcon fontSize='small' />} iconPosition="start" label="Form Builder" value="4" />
                                <Tab icon={<BusinessCenterIcon fontSize='small' />} iconPosition="start" label="Offer Letter" value="5" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                            <Dashboard setValue={setValue} setAccordian={setAccordian} />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px", minHeight: "45px" }}>
                            <AtsSettings accordian={accordian} />
                        </TabPanel>
                        <TabPanel value="3" sx={{ padding: "0px", minHeight: "45px" }}>
                            <AtsOnboarding accordian={accordian} />
                        </TabPanel>
                        <TabPanel value="4" sx={{ padding: "0px", minHeight: "45px" }}>
                            <FormBuilderLandingPage />
                        </TabPanel>
                        <TabPanel value="5" sx={{ padding: "0px", minHeight: "45px" }}>
                            <OfferLetterLandingPage />
                        </TabPanel>
                    </TabContext>
                </Paper>
            </Base>
        </>
    );
}

export default AtsSettingsLandingPage;
