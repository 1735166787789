import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetEmailTokenHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getEmailToken`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain ? params?.domain : data?.domain,
            token: params?.token || null,
            email_id:params?.email_id || null,
            candidate_job_id:params?.candidate_job_id || null
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddEmailTokenHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/addEmailToken`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain ? params?.domain : data?.domain,
            user_emp_id: data?.employee_id ? data.employee_id : null,
            token: params?.token || "",
            url: params?.url || "",
            email_id: params?.email_id || "",
            json_data: params?.json_data || "[]",
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteEmailTokenHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/deleteEmailToken`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain ? params?.domain : data?.domain,
            token: params?.token || "",
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}