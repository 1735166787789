import { Box, Button, Paper, Tab, Tooltip } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import Popup from "../../../../Base/views/Popup";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch } from "react-redux";
import { GetLeaveSummaryAction } from "../../../../../../store/Leave/LeaveReports/Action";
import { GetLeaveOverallBalanceAction, GetPendingLeaveApprovalAction } from "../../../../../../store/Leave/LeaveDashboard/Action";
import { GetUpcomingHolidaysAction } from "../../../../../../store/Leave/HolidayCalendar/Action";
import TeamTimeOffCalender from "./TeamTimeOffCalender";
import TeamTimeOffBookings from "./TeamTimeOffBokings";
import ApplyLeave from "../../MyTimeSheet/TimeOff/ApplyLeave";
import ViewHolidayCalendar from "../../../../Leave/views/ManageLeave/ApplyLeave/HolidayCalendar";
import TeamBalance from "../../../../Leave/views/ManageLeave/ApplyLeave/TeamBalance";


const TeamTimeOff = () => {
  const dispatch = useDispatch()
  const [openPopup, setOpenPopup] = useState(false);
  const [tbPopup, setTBPopup] = useState(false);
  const [chPopup, setCHPopup] = useState(false);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    dispatch(GetLeaveSummaryAction({ show: "no" }));
    dispatch(GetLeaveOverallBalanceAction({ show: "no" }));
    dispatch(GetUpcomingHolidaysAction({ show: "no" }));
    dispatch(GetPendingLeaveApprovalAction());
    //eslint-disable-next-line
  }, []);
  const handleBookClick = () => {
    setOpenPopup(true);
  };
  const [value, setValue] = useState('1');
  const handleValueChange = (event, newValue, calendarValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ marginTop: "20px" }}>
        {tbPopup && (
          <TeamBalance
            openPopup={tbPopup}
            setOpenPopup={setTBPopup}
          />
        )}
        {chPopup && (
          <ViewHolidayCalendar
            openPopup={chPopup}
            setOpenPopup={setCHPopup}
          />
        )}
        <Button
          sx={{
            fontWeight: 700,
            borderRadius: "8px",
            fontSize: "13px",
            height: "30px",
            padding: "4px 10px",
            boxShadow: "none",
            lineHeight: 1.71429,
            marginRight: "10px",
          }}
          size="small"
          variant="contained"
          className="mpl-primary-btn"
          onClick={() => handleBookClick()}
        >
          Book TimeOff
        </Button>
      </Box>
      <Popup
        fullWidth={true}
        maxWidth="lg"
        title="Book TimeOff"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ApplyLeave
          setOpenPopup={setOpenPopup}
          my_time_off={true}
          setFlag={setFlag}
          team_time_off={true}
        />
      </Popup>

      <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
        <Paper sx={{ borderRadius: "10px", minHeight: "87vh" }}>
          <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TabList sx={{
                  minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                  '& .MuiTabs-indicator': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: "center !important",
                  },
                }} onChange={handleValueChange} aria-label="lab API tabs example">
                  <Tab label="Calendar" value="1" />
                  <Tab label="Bookings" value="2" />
                </TabList>
                <Box sx={{
                  display: "flex", gap: "10px", padding: "8px 16px 0px 16px", cursor: "pointer",
                  color: "#0854ffcf"
                }}>
                  <Tooltip title="Team Leave Balance"><div onClick={() => setTBPopup(true)}>Team Balance</div></Tooltip>
                  <div>|</div>
                  <Tooltip title="Holiday Calendar"><div onClick={() => setCHPopup(true)}>Holiday Calendar</div></Tooltip>
                </Box>
              </Box>
              <TabPanel value="1" sx={{ padding: "0px" }}>
                <TeamTimeOffCalender flag={flag} />
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "0px" }}>
                <TeamTimeOffBookings flag={flag} />
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default TeamTimeOff;
