
import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";

export const GetPlansHelper = async () => {
    return await axiosInstance(`/plans/getPlans`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: false, data: err?.response?.data }
        })
}
