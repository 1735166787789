
import { toast } from 'react-toastify';
import axiosInstance from '../../../../../axiosInstance';
import { getUserData } from '../../../Base/helper/baseFunctions';
import { HRMS_LMS_API_URL } from '../../../../Constants/baseConstant';



export const AddMyTimeSheetHelper = async (params) => {
    var data = getUserData();
    toast.info("Loading...")
    return await axiosInstance(`/lms/addMytimeSheet`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_id: data.emp_users_pk,
            from_date_day: params?.from_date_day,
            from_date_month: params?.from_date_month,
            from_date_year: params?.from_date_year,
            to_date_day: params?.to_date_day,
            to_date_month: params?.to_date_month,
            to_date_year: params?.to_date_year,
            week_no: params?.week_no,
            weekly_working_days:params?.weekly_working_days,
            manager_emp_id:params?.manager_emp_id,
            mytime_configure_id: params?.mytime_configure_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(`MyTimeSheet Submitted successfully`);
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateMyTimeSheetHelper = async (params) => {
    var data = getUserData();
    toast.info("Loading...")
    return await axiosInstance(`/lms/updateMytimeSheet`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_id: data.emp_users_pk,
            from_date_day: params?.from_date_day,
            from_date_month: params?.from_date_month,
            from_date_year: params?.from_date_year,
            to_date_day: params?.to_date_day,
            to_date_month: params?.to_date_month,
            to_date_year: params?.to_date_year,
            week_no: params?.week_no,
            weekly_working_days:params?.weekly_working_days,
            manager_emp_id:params?.manager_emp_id,
            mytime_configure_id: params?.mytime_configure_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(`MyTimeSheet Updated successfully`);
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetMyTimeSheetHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/getMytimeSheet`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_id: data?.emp_users_pk, 
            from_date_day: params?.from_date_day,
            from_date_month: params?.from_date_month,
            from_date_year: params?.from_date_year,
            to_date_day: params?.to_date_day,
            to_date_month: params?.to_date_month,
            to_date_year: params?.to_date_year,
            from_add: false,
            manager_emp_id: params?.my_time_flag ? null : data?.employee_id,
            approval_screen: params?.approval_screen || false
        }
    })
        .then((res) => {
            toast.dismiss();
            res?.data?.data?.length === 0 && toast.warning("No Records Found")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const GetMyTimeSheetLeaveInfoHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/getMytimeSheetLeaveInfo`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.emp_users_pk,
            emp_id: data?.employee_id,
            from_date_day: params?.from_date_day,
            from_date_month: params?.from_date_month,
            from_date_year: params?.from_date_year,
            to_date_day: params?.to_date_day,
            to_date_month: params?.to_date_month,
            to_date_year: params?.to_date_year,
            mytime_configure_id:params?.mytime_configure_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const GetMyTimeSheetProjectHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/getMytimeProject`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetWeekdaysHelper = async (params) => {
    return await axiosInstance(`/lms/getweekday`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            start_date_day: params?.start_date_day,
            start_date_month: params?.start_date_month,
            start_date_year: params?.start_date_year,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetWeekdatesHelper = async (params) => {
    return await axiosInstance(`/lms/getweekdates`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            start_date_day: params?.start_date_day,
            start_date_month: params?.start_date_month,
            start_date_year: params?.start_date_year,
            to_date_day: params?.end_date_day,
            to_date_month: params?.end_date_month,
            to_date_year: params?.end_date_year,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

