import * as ActionTypes from '../ActionTypes';

// Permission
export const GetPermission = () => {
    return {
        type: ActionTypes.PERMISSION_GET_REQUEST,
    }
}

export const PowerUserDisabledAction = () => {
    return {
        type: ActionTypes.POWER_USER_DISABLED_ACCESS,
    }
}