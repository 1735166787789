import { Box } from '@mui/material'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Base from '../../Base/views/Base'
import Employee from '../../Employee/views/Employee';
import EmployeeAddForm from './EmployeeAddForm';
import EmployeeGrid from './EmployeeGrid';

const Onboarding = () => {
    const { access } = useSelector(state => state.UserAccessReducer)
    const { candidateData } = useSelector(state => state?.OnboardingReducer)
    const [currentMode, setCurrentMode] = useState(candidateData ? candidateData?.mode : "grid")
    const [data, setData] = useState()
    const [internal, setInternal] = useState(true)
    const dispatch = useDispatch();
    const [modules, setModules] = useState();

    useEffect(() => {
        dispatch({ type: "ONBOARDING_ENABLE_ADD_REQUEST" })
        setModules(access?.find(item => item.module === sessionStorage.getItem("selectedMenu"))?.submodules?.[0]?.permission)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <> {
            ['add', 'grid'].includes(currentMode) ?
                <Base>

                    <Box sx={{  maxHeight: "87vh", overflowY: 'auto', width: '100%', padding: " 0px 10px 25px 10px" }}>
                        {
                            currentMode === "grid" ? <EmployeeGrid access={modules} setCurrentMode={setCurrentMode} setData={setData} setInternal={setInternal} internal={internal}/>
                                : <EmployeeAddForm setCurrentMode={setCurrentMode} setInternal={setInternal} />
                        }
                    </Box>
                </Base>
                :
                <Employee Onboarding={true} setCurrentMode={setCurrentMode} OnboardingEmpData={data} />
        }

        </>

    )
}

export default Onboarding