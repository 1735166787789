import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function SuccessTemplate({ detectedUser, message, imageUrl }) {
    
    return (
        <>
            <Box sx={{
                margin: '20px',
                display: 'flex',
                alignItems: 'center'
            }}>
                <img
                    src={imageUrl}
                    alt=""
                    style={{ width: '60px', height: '60px', borderRadius: '50%', marginRight: '10px' }}
                />
                <Typography sx={{ fontFamily: "Nunito Sans" }} variant="h4">{detectedUser.name}</Typography>
            </Box>

            <div style={{
                width: '100%',
                height: '86vh',
                backgroundColor: '#32CD32',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                overflow: 'hidden',
            }}>

                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    animation: 'expandFadeOut 1s ease forwards',

                }} />
                <CheckCircleOutlineIcon style={{ fontSize: '100px', color: 'white', zIndex: 1 }} />
                <Typography variant="h4" style={{ color: 'white', zIndex: 1 }}>{message}</Typography>

            </div>
            <style>
                {`
                    @keyframes expandFadeOut {
                        0% {
                            transform: scale(0);
                            opacity: 1;
                        }
                        50% {
                            transform: scale(2);
                            opacity: 0.5;
                        }
                        100% {
                            transform: scale(3);
                            opacity: 0;
                        }
                    }
                `}
            </style>
        </>
    );
}

export default SuccessTemplate;
