import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab } from '@mui/material';
import React from 'react';
import Base from "../../Base/views/Base";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useState } from 'react';
import FinanceLandingPage from './Finance/FinanceLandingPage';
import PaymentIcon from '@mui/icons-material/Payment';
import { GetSalaryStructureAction } from '../../../../store/Finance/SalaryStructure/Action';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const FinanceSettingsLandingPage = () => {

    const [value, setValue] = useState('1');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetSalaryStructureAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Base>
            <Paper className='onboarding-settings admin-setting' sx={{ borderRadius: "10px", minHeight: "87vh", maxHeight: "87vh", overflowY: "auto" }}>
                <TabContext value={value}>
                    <Box>
                        <TabList sx={{
                            minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                            '& .MuiTabs-indicator': {
                                width: '100%',
                                display: 'flex',
                                justifyContent: "center !important",
                            },
                        }} onChange={handleChange} aria-label="lab API tabs example">
                            <Tab icon={<DashboardIcon fontSize='small' />} iconPosition="start" label="Payroll" value="1" />
                            <Tab icon={<ManageAccountsIcon fontSize='small' />} iconPosition="start" label="Tax Declaration" value="2" />
                            <Tab icon={<PaymentIcon fontSize='small' />} iconPosition="start" label="Salary Structure" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                        Hello
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px", minHeight: "45px" }}>
                        World
                    </TabPanel>
                    <TabPanel value="3" sx={{ padding: "0px", minHeight: "45px" }}>
                        <FinanceLandingPage />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Base>
    );
}

export default FinanceSettingsLandingPage;
