import { useState, useEffect } from "react";
import { Box, Typography, Tooltip, Button, Paper } from "@mui/material";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { createStyles, makeStyles } from "@mui/styles";
import {
  convertBase64,
  getUserData,
  s3Client,
} from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";
import {
  GetCompanyLogoHelper,
  UpdateCompanyLogoHelper,
} from "../helper/CompanyDetailsHelper";
import { v4 as uuidv4 } from "uuid";

const CompanyDetail = () => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      Paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        width: "500px",
        borderRadius: "10px",
        backgroundColor: "#e6d9fb7a",
      },
      comingSoon: {
        fontWeight: 600,
        fontSize: "18px",
        marginTop: "30px",
        color: "white",
      },
    })
  );
  const classes = useStyles();
  const userData = getUserData();
  const initialValue = {
    image: "",
  };
  const [inputData, setInputData] = useState(initialValue);
  const [imgfiles, setimgFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    GetCompanyLogoHelper()
      .then((res) => {
        res?.data?.data?.[0]?.companylogo &&
          getImageSignedUrl(res?.data?.data?.[0]?.companylogo);
        setInputData({ image: res?.data?.data?.[0]?.companylogo });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    let img_filename = "";
    let img_b64string = "";
    let old_image = "";
    if (imgfiles?.[0]) {
      img_b64string = await convertBase64(imgfiles?.[0]);
      let ext = imgfiles?.[0]?.name?.split(".").at(-1);
      img_filename =
        `${userData?.domain}/Settings/CompanyLogo/` + uuidv4() + "." + ext;
      if (inputData?.image) {
        old_image = inputData?.image;
      }
    } else {
      img_filename = inputData?.image;
    }
    if (inputData?.image && !imageUrl) {
      old_image = inputData?.image;
      img_filename = img_filename ? img_filename : "";
    }
    UpdateCompanyLogoHelper({
      image: img_filename,
      image_name: img_b64string,
      old_image: old_image,
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();
    setimgFiles([]);
    setImageUrl("");
    setInputData((prevData) => ({
      ...prevData,
      image: "", // Setting image to an empty string
    }));
  };

  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 2000000,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      setimgFiles(acceptedFiles.map((file) => Object.assign(file)));
    },
    onDropRejected: () => {
      toast.error(
        "You can only image upload image with maximum size of 2 MB.",
        {
          duration: 2000,
        }
      );
    },
  });
  const img = imgfiles.map((file) => (
    <img
      style={{ borderRadius: "50%", padding: "2px" }}
      width="100%"
      height="100%"
      key={file.name}
      alt={file.name}
      className="single-file-image"
      src={URL.createObjectURL(file)}
    />
  ));

  return (
    <Box sx={{ display:"flex", justifyContent:"center"}}>
      <Paper
        className={classes.Paper}
        sx={{ padding: "80px 24px 40px", borderRadius: "16px" }}
      >
        <Box sx={{ display:"flex", justifyContent:"space-between"}}>
          <Box>
        <Box
          sx={{
            marginBottom: "5px",
            border: "none !important",
            cursor: "default",
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          <div className="presentation">
            <div className="placeholder">
              {imgfiles.length ? (
                img
              ) : inputData?.image ? (
                <img
                  style={{ borderRadius: "50%", padding: "2px" }}
                  alt="Nothing"
                  width="100%"
                  height="100%"
                  src={imageUrl}
                />
              ) : (
                <>
                  <AddPhotoAlternateIcon />
                  <Typography variant="caption" display="block" gutterBottom>
                    Upload Photo
                  </Typography>
                </>
              )}
            </div>
          </div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="Delete Image">
            <DeleteIcon
              sx={{ cursor: "pointer" }}
              onClick={handleDelete}
              color="error"
            />
          </Tooltip>
        </Box>
        </Box>
        <Box sx={{ display:"flex", alignItems:"center"}}>
        <Typography
          sx={{ margin: "5px auto 0px" }}
          variant="caption"
          className="content-sec"
        >
          Only Allowed *.jpeg, *.jpg, *.png
          <br /> max size of 2 MB
        </Typography>
        </Box>
          <Box sx={{ display:"flex", alignItems:"center"}}>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              onClick={handleSubmit}
              className="mpl-primary-btn"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
export default CompanyDetail;
