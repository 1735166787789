import { Box } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import Base from '../../../Base/views/Base'
import AtsCandidate from './AtsCandidate'
import AtsCandidateForm from './AtsCandidateForm'
import { useLocation } from 'react-router-dom'
import AtsCandidateValidate from './AtsCandidateValidate'
import { GetATSModuleFeaturesAction } from '../../../../../store/RoleAccess/ATSModuleFeatures/Action'
import { useDispatch, useSelector } from 'react-redux'

const AtsCandidateLandingPage = () => {
    const initialValues = {
        first_name: "",
        last_name: "",
        applicant_id: null,
        contact_no: "",
        mail_id: "",
        dob: "",
        country_id: "",
        state_id: "",
        years_of_exp: "",
        current_ctc: "",
        expected_pay: "",
        current_employer: "",
        notice_period: "",
        skills: "",
        resume: ""
    }
    const dispatch = useDispatch();
    const location = useLocation();
    const [currentMode, setCurrentMode] = useState("grid")
    const [currentData, setCurrentData] = useState(initialValues)
    const [openValidatePopup, setOpenValidatePopup] = useState(false);
    const [value, setValue] = useState(0);
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)

    useEffect(() => {
        setCurrentMode("grid");
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Candidate" }));
        //eslint-disable-next-line
    }, [location]);

    const handleValidatePopup = () => {
        setOpenValidatePopup(!openValidatePopup);
    }

    return (
        <Base>
            <Box>
                {
                    openValidatePopup &&
                    <AtsCandidateValidate
                        currentData={currentData}
                        openValidatePopup={openValidatePopup}
                        setOpenValidatePopup={setOpenValidatePopup}
                        setValue={setValue}
                        setCurrentMode={setCurrentMode}
                    />
                }
                {
                    currentMode === "grid" ? (
                    <AtsCandidate
                        featuresData={featuresData}
                        setCurrentMode={setCurrentMode}
                        currentData={currentData}
                        openValidatePopup={openValidatePopup}
                        setCurrentData={setCurrentData}
                        handleValidatePopup={handleValidatePopup}
                        setValue={setValue}
                        value={value}
                    />
                      )  :  <AtsCandidateForm
                                    featuresData={featuresData}
                                    currentData={currentData}
                                    setCurrentData={setCurrentData}
                                    setCurrentMode={setCurrentMode}
                                    currentMode={currentMode}
                                    handleValidatePopup={handleValidatePopup}
                                // disable={value === 1 ? true : ""}
                                />
                }
            </Box>
        </Base>
    )
}

export default AtsCandidateLandingPage