import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import "./ContactUs.css";
import contactusimg from "../../../../../Image/contact.svg";
import Nav from "../Header/Nav";
import { contactEmailHelper } from "../../helper/ContactUsHelper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer/Footer";

function Contact() {
  const initialValues = {
    emailid: "",
    name: "",
    subject:"",
    message:""

  };
  const [data, setData] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...data, [e.target.name]: e.target.value });
  };
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = fieldValues.name?.trim() === "" ? "Name is required" : "";
    }
    if ("emailid" in fieldValues) {
      temp.emailid =
        fieldValues.emailid === ""
          ? "Email ID is required"
          : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              fieldValues.emailid
            )
          ? ""
          : "Invalid Email.";
    }  
    if ("subject" in fieldValues) {
      temp.subject = fieldValues.subject === "" ? "Subject is required" : "";
    }
    if ("message" in fieldValues) {
      temp.message = fieldValues.message === "" ? "Message is required" : "";
  }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate(data)) {
      contactEmailHelper(data)
        .then((res) => {
          if (res?.message?.statuscode === 200) setData(initialValues);
        })
        .catch((err) => {
        });
    }
  };
  return (
    <>
      <Nav />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="section">
        <div className="container">
          <div className="workflow-title-wrapper">
            <div
              data-w-id="36162ea4-93b5-cb92-902f-d3aece1925f2"
              className="workflow-content-left"
            >
              <div className="fade-in-move-on-scroll">
                <div className="title-with-emoji">
                  <h2 className="title">Let's talk about everything!</h2>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Voluptas debitis, fugit natus?
                  </p> */}
                  <div className="contact_img">
                    <img alt="" src={contactusimg} />
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="36162ea4-93b5-cb92-902f-d3aece1925f8"
              className="workflow-content-right"
            >
              <TextField
                value={data?.name}
                onChange={handleChange}
                className="text-field w-input contact_us_input"
                maxLength="256"
                name="name"
                placeholder="Your name"
                id="name"
                {...(errors.name && {
                  error: true,
                  helperText: errors.name,
                })}
              />

              <TextField
                value={data?.emailid}
                onChange={handleChange}
                className="text-field w-input contact_us_input"
                maxLength="256"
                name="emailid"
                placeholder="Your Email"
                id="emailid"
                {...(errors.emailid && {
                  error: true,
                  helperText: errors.emailid,
                })}
              />
              <TextField
              value={data?.subject}
              onChange={handleChange}
                className="text-field w-input contact_us_input"
                maxLength="256"
                name="subject"
                placeholder="Subject"
                id="subject"
                {...(errors.subject && {
                  error: true,
                  helperText: errors.subject,
                })}
              />
              <TextareaAutosize
                value={data?.message}
                onChange={handleChange}
                className="text-field w-input contact_us_input"
                maxLength="256"
                name="message"
                placeholder="Write Your Message"
                id="message"
              />
              <span className="error-msg ">{errors.message}</span>

              <input
                onClick={handleSubmit}
                type="submit"
                className="button w-button"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
