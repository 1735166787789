import * as ActionTypes from './ActionType';

const initialState = {
    isLoading: false,
    error: false,
    success: false,
};

const OnboardingReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ONBOARDING_ENABLE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                candidateData: null
            };
        case ActionTypes.ONBOARDING_ENABLE_ADD_RESPONSE:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
                candidateData: action.payload
            };
        default:
            return state;
    }
}

export default OnboardingReducer;