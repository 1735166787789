import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { PythonApiAction } from "../../../../store/ATS/PythonApi/Action";
import { toast } from "react-toastify";
export const FileUploadHelper = async (params, dispatch) => {
    return await axiosInstance(`/ats/uploadResume`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            b64Files: params?.b64Files,
            fileNames: params?.fileNames,
            contentTypes: params?.contentTypes,
        }
    })
        .then((res) => {
            if (res?.data?.statuscode === 200) {
                dispatch(PythonApiAction(params?.data))
                return { message: "Success", hasError: false, data: res?.data }
            }
            else {
                toast.error("failed to upload")
            }
        }).catch(err => {
            toast.error("failed")
            return { message: "Error" }
        })
}
