import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import NProgress from 'nprogress';

export const GetAtsTaskHistoryHelper = async (params) => {
    NProgress.start()
    var user = getUserData();
    return await axiosInstance(`/ats/getMyTasksHistory`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_id: user?.ats_user_id,
            is_my_task: params?.is_my_task,
            task_id: params?.task_id
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsCompletedTaskHelper = async () => {
    var user = getUserData();
    return await axiosInstance(`/ats/getCompletedTasks`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_id: user?.ats_user_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsTaskHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/getMyTasks`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            assigned_to: params?.type === "assigned_to" ? user?.ats_user_id : null,
            assigned_by: params?.type === "assigned_by" ? user?.ats_user_id : null,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAtsTaskHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addMyTasks`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_id: +params?.requested_by === +user?.ats_user_id ? params?.requested_by : user?.ats_user_id,
            title: params?.title,
            task: params.task,
            assigned_to: params.assigned_to,
            status: params.status,
            due_date: params.due_date,
            priority: params.priority,
            comments: params.comments || "",
            proof: params.proof || "",
            candidate_job_id : null
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Task Created successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsTaskHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateMyTasks`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_id: user?.ats_user_id,
            title: params?.title,
            assigned_to: params.assigned_to,
            task: params.task,
            task_id: params.task_id,
            status: params.status,
            due_date: params.due_date,
            priority: params.priority,
            comments: params.comments,
            proof: params?.proof || null,
            proof_name: params?.proof_name || null,
            completed_date: params?.completed_date || null,
            remainder_date: params?.remainder_date || null,
            is_my_task: params?.is_my_task,
            content_type: params?.content_type || ""
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(params?.message ? params?.message : "Task Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteAtsTaskHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/deleteMyTasks`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_id: user?.ats_user_id,
            task_id: params?.task_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Task Deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsTaskUserHelper = async () => {
    var user = getUserData();
    return await axiosInstance(`/ats/getTasksUsers`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_id: user?.ats_user_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}