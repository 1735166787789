import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Popup from "../../../../Base/views/Popup";

const FlagCandidatePopup = ({ openPopup, setOpenPopup, handleFlagCandidateClick, errors, setValues, values, setErrors }) => {

    const handleClose = () => {
        setOpenPopup(false);
        setErrors([])
    }

    return (
        <>
            <Popup
                fullWidth={true}
                maxWidth="xs"
                title="Flag Candidate"
                openPopup={openPopup}
                setOpenPopup={handleClose}
            >
                <Box>
                    <Grid container spacing={2}>
                        <Grid key="venue" item xs={12} md={12}>
                            <FormControl size="small" fullWidth error={errors?.flag_reason ? true : false}>
                                <InputLabel id="recruiter-type-select-label">Reason</InputLabel>
                                <Select
                                    name='flag_reason'
                                    id="flag_reason"
                                    label="Status"
                                    value={values?.flag_reason}
                                    onChange={(e) => setValues({ ...values, flag_reason: e.target.value })}
                                >
                                    <MenuItem value={"Inconsistent Resume"}>Inconsistent Resume</MenuItem>
                                    <MenuItem value={"Negative Online Presence"}>Negative Online Presence</MenuItem>
                                    <MenuItem value={"Cultural Fit Concerns"}>Cultural Fit Concerns</MenuItem>
                                    <MenuItem value={"No show post offer"}>No show post offer</MenuItem>
                                    <MenuItem value={"Legal or Background Check Issues"}>Legal or Background Check Issues</MenuItem>
                                </Select>
                                <FormHelperText>{errors?.flag_reason}</FormHelperText>
                            </FormControl>

                        </Grid>
                        <Grid display="flex" justifyContent="flex-end" container>
                            <Button
                                sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
                                variant="outlined"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleFlagCandidateClick}
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="mpl-primary-btn"
                                sx={{ marginTop: "1.75rem" }}
                            >
                                Flag Candidate
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popup >
        </>
    )
}

export default FlagCandidatePopup;
