import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: ""
}

export const LeaveReportsReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LEAVE_SUMMARY_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                leaveSummary_data: []
            };
        case ActionTypes.LEAVE_SUMMARY_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveSummary_data: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_SUMMARY_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.LEAVE_REPORT_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                leaveReport_data: []
            };
        case ActionTypes.LEAVE_REPORT_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveReport_data: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_REPORT_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.LEAVE_LEDGER_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                leaveLedger_data: []
            };
        case ActionTypes.LEAVE_LEDGER_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveLedger_data: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_LEDGER_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.BOOKINGS_LEAVE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                leaveBookings_data: []
            };
        case ActionTypes.BOOKINGS_LEAVE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveBookings_data: action.payload?.data?.data

            };
        case ActionTypes.BOOKINGS_LEAVE_GET_ERROR:
            return{
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}