import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, IconButton, TextField, Tooltip, Typography } from '@mui/material'
// import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { GetAtsCompanyAction } from '../../../../../store/ATS/Company/Action';
import { GetAtsConsultancyAction } from '../../../../../store/ATS/Consultancy/Action';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from "@mui/material/styles";
import { getUserData } from '../../../Base/helper/baseFunctions';
import { handleSearch } from '../../../Base/helper/baseFunctions';
import { NoData } from '../../../Base/views/NotAuthorized';

const AtsJobPostFilter = ({ getJobData, handleClearFilter, companyValue, setCompanyValue, handleCompanyChange,  grouporganisationValue ,handleGrouporgChange , consultancyValue, setConsultancyValue, handleConsultancyChange, severityValue, setSeverityValue, handleSeverityChange, handlePrimaryRecruiterChange, primaryRecruiterValue, setPrimaryRecruiterValue, handleSecondaryRecruiterChange, secondaryRecruiterValue, setSecondaryRecruiterValue, JobFilters }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const userData = getUserData()
    const { atsCompanyData } = useSelector(state => state.AtsReducer?.AtsCompanyReducer);
    const { atsConsultancyData } = useSelector(state => state.AtsReducer?.AtsConsultancyReducer);
    const { atsJobFilter } = useSelector(state => state.AtsReducer?.AtsJobFilterReducer);
    const Clients = atsJobFilter?.[0]?.clients;
    const HiringPartners = atsJobFilter?.[0]?.hiringpartners;
    const Severity = atsJobFilter?.[0]?.severity;
    const PrimaryRec = atsJobFilter?.[0]?.primary_recruiter;
    const SecondaryRec = atsJobFilter?.[0]?.secondary_recruiter;
    const Grouporg = atsJobFilter?.[0]?.grouporg;
    const isGroupOrgFlowTrue = userData?.grouporg_flow === true ? 'Group Organisation':'Client';
    const isGroupOrgFlow = userData?.grouporg_flow === true;
    const [expanded, setExpanded] = useState(false);
    const [primaryrecruiterData, setPrimaryRecruiterData] = useState([]);
    const [secondaryrecruiterData, setSecondaryRecruiterData] = useState([]);
    const [severityData, setSeverityData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [consultancyData, setConsultancyData] = useState([]);
    const primaryUserTypes = [...new Set(primaryrecruiterData.map(pr => pr.usertype))];
    const secondaryUserTypes =[...new Set(secondaryrecruiterData.map(pr => pr.usertype))];
    const [primaryUserTypesobjects,setprimaryUserTypesobjects] = useState([]);
    const [secondaryUserTypesobjects,setsecondaryUserTypesobjects] = useState([]);

    const actionSummaryStyle = {
        minHeight: 50,
        maxHeight: 50,
        borderRadius: "10px 10px 0px 0px",
        // backgroundColor: '#a5a5a5',
        '&.Mui-expanded': {
            minHeight: 50,
            maxHeight: 50,
            backgroundColor: theme?.palette?.primary?.dark,
            color: "white",
        }
    }

    const accordionDetailsStyle = {
        minHeight: "30vh", maxHeight: "30vh", display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
    }

    useEffect(() => {

        const primaryUserTypesobj=primaryUserTypes.map((type)=>({usertype:type,expand:false}));
        setprimaryUserTypesobjects(primaryUserTypesobj);

        const secondaryUserTypesobj=secondaryUserTypes.map((type)=>({usertype:type,expand:false}));
        setsecondaryUserTypesobjects(secondaryUserTypesobj);
   
        
        if (!atsCompanyData && !["Company"].includes(userData?.user_type)) {
            dispatch(GetAtsCompanyAction());
        }
        if (!atsConsultancyData && !["Consultancy"].includes(userData?.user_type)) {
            dispatch(GetAtsConsultancyAction());
        }
        if (Clients) {
            const uniqueClients = Clients?.reduce((acc, client) => {
                if (client.ats_company_id && !acc.some(existingClient => existingClient.ats_company_id === client.ats_company_id)) {
                    acc.push(client);
                }
                return acc;
            }, []);
            setCompanyData(uniqueClients);
        }
        if (Grouporg) {
            const uniqueGrouporg = Grouporg?.reduce((acc, grouporg) => {
                if (grouporg.ats_grouporg_id&& !acc.some(existingGrouporg => existingGrouporg.ats_grouporg_id === grouporg.ats_grouporg_id)) {
                    acc.push(grouporg);
                }
                return acc;
            }, []);
            setCompanyData(uniqueGrouporg);
        }

        if (HiringPartners) {
            const uniqueConsultancy = HiringPartners?.reduce((acc, consultancy) => {
                if (consultancy.consultancy_id && !acc.some(existingConsultancy => existingConsultancy.consultancy_id === consultancy.consultancy_id)) {
                    acc.push(consultancy);
                }
                return acc;
            }, []);
            setConsultancyData(uniqueConsultancy);
        }
        if (Severity) {
            const uniqueseverity = Severity?.reduce((acc, severity) => {
                if (severity.priority_id && !acc.some(existingseverity => existingseverity.priority_id === severity.priority_id)) {
                    acc.push(severity);
                }
                return acc;
            }, []);
            setSeverityData(uniqueseverity);
        }
        if (PrimaryRec) {
            const uniquePrimary = PrimaryRec?.reduce((acc, primary_recruiter) => {
                if (primary_recruiter.recruiter_id && !acc.some(existingPrimary => existingPrimary.recruiter_id === primary_recruiter.recruiter_id)) {
                    acc.push(primary_recruiter);
                }
                return acc;
            }, []);
            setPrimaryRecruiterData(uniquePrimary);
        }
        if (SecondaryRec) {
            const uniqueSecondary = SecondaryRec?.reduce((acc, secondary_recruiter) => {
                if (secondary_recruiter.recruiter_id && !acc.some(existingScondary => existingScondary.recruiter_id === secondary_recruiter.recruiter_id)) {
                    acc.push(secondary_recruiter);
                }
                return acc;
            }, []);
            setSecondaryRecruiterData(uniqueSecondary);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [atsCompanyData, atsConsultancyData, atsJobFilter]);
 

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        
    };

    const handleClientSearch = (e) => {
            const uniqueClients = Clients?.reduce((acc, client) => {
                if (client.ats_company_id && !acc.some(existingClient => existingClient.ats_company_id === client.ats_company_id)) {
                    acc.push(client);
                }
                return acc;
            }, []);
        const filter = handleSearch(e.target.value, uniqueClients, "name")
        setCompanyData(filter)
    };

    const handleGrouporgSearch = (e) => {
        const uniqueGrouporg = Grouporg?.reduce((acc, grouporg) => {
            if (grouporg.ats_grouporg_id&& !acc.some(existingGrouporg => existingGrouporg.ats_grouporg_id === grouporg.ats_grouporg_id)) {
                acc.push(grouporg);
            }
            return acc;
        }, []);
    const filter = handleSearch(e.target.value, uniqueGrouporg, "name")
    setCompanyData(filter)
};

    const handleConsultancySearch = (e) => {
        const uniqueConsultancy = HiringPartners?.reduce((acc, consultancy) => {
            if (consultancy.consultancy_id && !acc.some(existingConsultancy => existingConsultancy.consultancy_id === consultancy.consultancy_id)) {
                acc.push(consultancy);
            }
            return acc;
        }, []);
        const filter = handleSearch(e.target.value, uniqueConsultancy, "name")
        setConsultancyData(filter)
    };
   

    const handlePrimaryRecruiterSearch = (e,type) => {
        setprimaryUserTypesobjects(
            (prevtypes)=> prevtypes.map((prev)=>(
         {...prev,expand:true}
            ))
        )
        const uniquePrimary = PrimaryRec?.reduce((acc, primary_recruiter) => {
            
            if (primary_recruiter.recruiter_id && !acc.some(existingPrimary => existingPrimary.recruiter_id === primary_recruiter.recruiter_id)) {
             
                acc.push(primary_recruiter);
                
            }
            return acc;
        }, []);
        const filter = handleSearch(e.target.value, uniquePrimary, "recruiter")
        setPrimaryRecruiterData(filter)
    };

    const handleSecondRecruiterSearch = (e) => {
        
        setsecondaryUserTypesobjects(
            (prevtypes)=> prevtypes.map((prev)=>(
         {...prev,expand:true}
            ))
        )
        const uniqueSecondary = SecondaryRec?.reduce((acc, secondary_recruiter) => {
            if (secondary_recruiter.recruiter_id && !acc.some(existingScondary => existingScondary.recruiter_id === secondary_recruiter.recruiter_id)) {
            
                acc.push(secondary_recruiter);
                
            }
            return acc;
        }, []);
        const filter = handleSearch(e.target.value, uniqueSecondary, "recruiter")
        setSecondaryRecruiterData(filter)
    };

    return (
        <div>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 0px 10px 16px",
            }}>
                <Typography variant='subtitle1'>Filters</Typography>
                <Box>
                    {/* <Tooltip title="Apply"><IconButton onClick={() => { getJobData(); JobFilters() }} color='success' size='large' variant="outlined" ><DoneOutlinedIcon /></IconButton></Tooltip> */}
                    <Tooltip title="Clear"><IconButton color='error' variant="outlined" onClick={handleClearFilter}><DeleteIcon /></IconButton></Tooltip>
                </Box>
            </Box>
            <Divider />
            <Box sx={{
                minHeight: "70vh", maxHeight: "70vh", display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                overflowY: "scroll",
            }}>
                <Box sx={{ display: "flex", flexDirection: "column", }}>
                    <div>
                        {
                            ["Admin", "Owner", "Internal", "Consultancy","Group User","Company"].includes(userData?.user_type) &&
                            <>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={actionSummaryStyle}
                                    >
                                        <Box style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                            <Box>
                                                <Typography sx={{ color: theme.palette.primary.contrastText }} variant='subtitle1' style={{ marginRight: '30px' }}>{isGroupOrgFlowTrue}</Typography>
                                            </Box>
                                            {companyValue?.length > 0 && <Box>
                                                <IconButton color='error' variant="outlined" onClick={(e) => { e.stopPropagation(); setCompanyValue([]); }}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </Box>}
                                        </Box>
                                    </AccordionSummary>
                                   
               {isGroupOrgFlow ?
                    
                    <>
                    {Grouporg ?  (
                                            <AccordionDetails sx={accordionDetailsStyle}>
                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <TextField
                                                        variant="standard"
                                                        type={"text"}
                                                        onChange={(e) => handleGrouporgSearch(e)}
                                                        id="grouporg_search"
                                                        size="small"
                                                        label="Search Group Organisation"
                                                        fullWidth
                                                        sx={{ mb: 2 }}
                                                    />
                                                </Box>
                                                <FormControl component="fieldset" variant="standard">
                                                    <FormGroup>
                                                        {companyData?.map((comp, index) => (
                                                            <FormControlLabel key={index}
                                                                control={<Checkbox size='small' checked={grouporganisationValue?.map(com => +com.ats_grouporg_id).includes(+comp?.ats_grouporg_id)} onChange={(e) => handleGrouporgChange(comp, e)} name={comp?.ats_grouporg_id} />} label={comp?.name}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </FormControl>
                                            </AccordionDetails>
                                            ) : (
                                                <AccordionDetails sx={accordionDetailsStyle}>
                                                <NoData title={"No filter available"} />
                                                </AccordionDetails>
                                        )}
                    </>
                   
               :
               <>
               {Clients ?  (
                           <AccordionDetails sx={accordionDetailsStyle}>
                               <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                   <TextField
                                       variant="standard"
                                       type={"text"}
                                       onChange={(e) => handleClientSearch(e)}
                                       id="client_search"
                                       size="small"
                                       label="Search Client"
                                       fullWidth
                                       sx={{ mb: 2 }}
                                   />
                               </Box>
                               <FormControl component="fieldset" variant="standard">
                                   <FormGroup>
                                       {companyData?.map((comp, index) => (
                                           <FormControlLabel key={index}
                                               control={<Checkbox size='small' checked={companyValue?.map(com => +com.ats_company_id).includes(+comp?.ats_company_id)} onChange={(e) => handleCompanyChange(comp, e)} name={comp?.ats_company_id} />} label={comp?.name}
                                           />
                                       ))}
                                   </FormGroup>
                               </FormControl>
                           </AccordionDetails>
                           ) : (
                               <AccordionDetails sx={accordionDetailsStyle}>
                               <NoData title={"No filter available"} />
                               </AccordionDetails>
                       )}
               </>
               
               
               }

                                </Accordion>

                            </>
                        }
                        {
                            ["Admin", "Owner", "Internal", "Company","Group User"].includes(userData?.user_type) &&
                            <>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={actionSummaryStyle}
                                    >
                                        <Box style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                            <Box>
                                                <Typography sx={{ color: theme.palette.primary.contrastText }} variant='subtitle1' style={{ marginRight: '30px' }}>Hiring Partner</Typography>
                                            </Box>
                                            {consultancyValue?.length > 0 && <Box>
                                                <IconButton color='error' variant="outlined" onClick={async (e) => { e.stopPropagation(); setConsultancyValue([]); }}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </Box>}
                                        </Box>
                                    </AccordionSummary>
                                    {HiringPartners ? (
                                    <AccordionDetails sx={accordionDetailsStyle}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <TextField
                                                variant="standard"
                                                type={"text"}
                                                onChange={(e) => handleConsultancySearch(e)}
                                                id="consultancy_search"
                                                size="small"
                                                label="Search Hiring Partner"
                                                sx={{ mb: 2 }}
                                                fullWidth
                                            />
                                        </Box>
                                        <FormControl component="fieldset" variant="standard">
                                            <FormGroup>
                                                {consultancyData?.map((cons, index) => (
                                                    <FormControlLabel key={index}
                                                        control={<Checkbox size='small' checked={consultancyValue.map(con => +con.consultancy_id).includes(+cons?.consultancy_id)} onChange={(e) => handleConsultancyChange(cons, e)} name={cons?.consultancy_id} />} label={cons?.name}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </AccordionDetails>
                                     ) : (
                                        <AccordionDetails sx={accordionDetailsStyle}>
                                        <NoData title={"No filter available"} />
                                        </AccordionDetails>
                                )}
                                </Accordion>
                            </>}
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={actionSummaryStyle}
                            >
                                <Box style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Box>
                                        <Typography sx={{ color: theme.palette.primary.contrastText }} variant='subtitle1' style={{ marginRight: '30px' }}>Severity</Typography>
                                    </Box>
                                    {severityValue?.length > 0 && <Box>
                                        <IconButton color='error' variant="outlined" onClick={(e) => { e.stopPropagation(); setSeverityValue([]); }}>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    </Box>}
                                </Box>
                            </AccordionSummary>
                            {Severity ? (
                            <AccordionDetails sx={accordionDetailsStyle}>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        {severityData?.map((sev, index) => (
                                            <FormControlLabel key={index}
                                                control={<Checkbox size='small' checked={severityValue.map(se => se.priority).includes(sev.priority)} onChange={(e) => handleSeverityChange(sev, e)} name={sev.priority} />} label={sev.priority}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                            ) : (
                                <AccordionDetails sx={accordionDetailsStyle}>
                                <NoData title={"No filter available"} />
                                </AccordionDetails>
                        )}
                        </Accordion>

                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={actionSummaryStyle}
                            >
                                <Box style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Box>
                                        <Typography sx={{ color: theme.palette.primary.contrastText }} variant='subtitle1' style={{ marginRight: '30px' }}>Primary Recruiter</Typography>
                                    </Box>
                                    {primaryRecruiterValue?.length > 0 && <Box>
                                        <IconButton color='error' variant="outlined" onClick={(e) => { e.stopPropagation(); setPrimaryRecruiterValue([]); }}>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    </Box>}
                                </Box>
                            </AccordionSummary>
                            {PrimaryRec ? (
                            <AccordionDetails sx={accordionDetailsStyle}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                        variant="standard"
                                        type={"text"}
                                        onChange={(e) => handlePrimaryRecruiterSearch(e)}
                                        id="note"
                                        size="small"
                                        label="Search Primary Recruiter"
                                        sx={{ mb: 2 }}
                                        fullWidth
                                    />
                                </Box>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                    {Array.isArray(primaryUserTypesobjects) && primaryUserTypesobjects.map((type, index) => (
                                            <div>    
                                              <Accordion key={index} expanded={type.expand} onChange={
                                                            ()=>(
                                                            setprimaryUserTypesobjects(
                                                                (prevtypes)=> prevtypes.map((prev,idx)=>(
                                                                    idx===index?{...prev,expand:!prev.expand}:prev
                                                                ))
                                                            )
                                                        )
                                              }
                                                >
                                                <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                style={actionSummaryStyle}
                                                >
                                              <Typography sx={{ color: theme.palette.primary.contrastText }} variant='subtitle1' style={{ marginRight: '20px'}}>{type.usertype}</Typography>
                                                
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                {primaryrecruiterData?.filter(pr => pr.usertype===type.usertype)
                                            .map((pr,index)=> (
                                                <div>
                                                <FormControlLabel key={index}
                                                control={<Checkbox size='small' checked={primaryRecruiterValue?.map(p => +p.recruiter_id)?.includes(+pr?.recruiter_id)} onChange={(e) => handlePrimaryRecruiterChange(pr, e , type)} name={pr?.recruiter_id} />} label={pr?.recruiter} 
                                            /><br/>
                                            </div>
                                            ))} 
                                                </AccordionDetails>
                                            </Accordion>
                                           </div>
                                     ))}
                                          
                                        
                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                            ) : (
                                <AccordionDetails sx={accordionDetailsStyle}>
                                <NoData title={"No filter available"} />
                                </AccordionDetails>
                        )}
                        </Accordion>

                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={actionSummaryStyle}
                            >
                                <Box style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Box>
                                        <Typography sx={{ color: theme.palette.primary.contrastText }} variant='subtitle1' style={{ marginRight: '30px' }}>Secondary Recruiter</Typography>
                                    </Box>
                                    {secondaryRecruiterValue?.length > 0 && <Box>
                                        <IconButton color='error' variant="outlined" onClick={(e) => { e.stopPropagation(); setSecondaryRecruiterValue([]); }}>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    </Box>}
                                </Box>
                            </AccordionSummary>
                            {SecondaryRec ? (
                            <AccordionDetails sx={accordionDetailsStyle}>
                                <FormControl component="fieldset" variant="standard">
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <TextField
                                            variant="standard"
                                            type={"text"}
                                            onChange={(e) => handleSecondRecruiterSearch(e)}
                                            id="note"
                                            size="small"
                                            label="Search Secondary Recruiter"
                                            sx={{ mb: 2 }}
                                            fullWidth
                                        />
                                    </Box>
                                    <FormGroup>
                                    {Array.isArray(secondaryUserTypesobjects) && secondaryUserTypesobjects.map((type, index) => (
                                                <div>
                                                    <Accordion key={index} expanded={type.expand} onChange={
                                                          ()=>(
                                                            setsecondaryUserTypesobjects(
                                                                (prevtypes)=> prevtypes.map((prev,idx)=>(
                                                                    idx===index?{...prev,expand:!prev.expand}:prev
                                                                ))
                                                            )
                                                        )
                                                    }>
                                                        <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                        style={actionSummaryStyle}
                                                        >
                                                      <Typography sx={{ color: theme.palette.primary.contrastText }} variant='subtitle1' style={{ marginRight: '20px' }}>{type.usertype}</Typography>
                                                        
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                        {secondaryrecruiterData?.filter(pr => pr.usertype===type.usertype)
                                                    .map((pr,index)=> (
                                                        <div>
                                                        <FormControlLabel key={index}
                                                        control={<Checkbox size='small' checked={secondaryRecruiterValue?.map(p => +p.recruiter_id)?.includes(+pr?.recruiter_id)} onChange={(e) => handleSecondaryRecruiterChange(pr, e)} name={pr?.recruiter_id} />} label={pr?.recruiter} 
                                                    /><br/>
                                                    </div>
                                                    ))} 
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    
                                                   
                                                </div>

                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                            ) : (
                                <AccordionDetails sx={accordionDetailsStyle}>
                                <NoData title={"No filter available"} />
                                </AccordionDetails>
                        )}
                        </Accordion>

                    </div>
                </Box>
            </Box>
        </div>
    )
}

export default AtsJobPostFilter