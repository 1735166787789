import { put, call } from 'redux-saga/effects';
import * as ActionTypes from './ActionTypes';
import {
    GetRoleHelper,
    GetModuleHelper,
    GetSubModuleHelper,
    GetPermissionHelper,
    GetRoleAccessHelper,
    AddRoleAccessHelper,
    UpdateRoleAccessHelper,
    DeleteRoleAccessHelper,
    GetATSModuleHelper,
    GetATSModuleFeaturesHelper,
    GetUserRolesHelper
} from '../../app/Components/RoleAndAccess/helper/RoleAccessHelper';

// Role
export function* GetRoleSaga() {
    const response = yield call(GetRoleHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ROLE_GET_SUCCESS, payload: response })
    }
}

// User Role
export function* GetUserRoleSaga() {
    const response = yield call(GetUserRolesHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ROLE_USER_GET_SUCCESS, payload: response })
    }
}

// Module
export function* GetModuleSaga() {
    const response = yield call(GetModuleHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.MODULE_GET_SUCCESS, payload: response })
    }
}

export function* GetATSModuleSaga(payload) {
    const response = yield call(GetATSModuleHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ATS_MODULE_GET_SUCCESS, payload: response })
    }
}

export function* GetATSModuleFeaturesSaga(payload) {
    const response = yield call(GetATSModuleFeaturesHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ATS_MODULE_FEATURES_GET_SUCCESS, payload: response })
    }
}

// Sub Module
export function* GetSubModuleSaga() {
    const response = yield call(GetSubModuleHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SUB_MODULE_GET_SUCCESS, payload: response })
    }
}
// Permission
export function* GetPermissionSaga() {
    const response = yield call(GetPermissionHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PERMISSION_GET_SUCCESS, payload: response })
    }
}

// Role Access
export function* GetRoleAccessSaga() {
    const response = yield call(GetRoleAccessHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ROLE_ACCESS_GET_SUCCESS, payload: response })
    }
}

export function* AddRoleAccessSaga(payload) {
    const response = yield call(AddRoleAccessHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ROLE_ACCESS_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateRoleAccessSaga(payload) {
    const response = yield call(UpdateRoleAccessHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ROLE_ACCESS_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteRoleAccessSaga(payload) {
    const response = yield call(DeleteRoleAccessHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ROLE_ACCESS_DELETE_SUCCESS, payload: response })
    }
}
