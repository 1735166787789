import * as ActionTypes from '../ActionTypes';

export const GetAtsCompanyAction = () => {
    return {
        type: ActionTypes.ATS_COMPANY_GET_REQUEST,
    }
}

export const AddAtsCompanyAction = (params) => {
    return {
        type: ActionTypes.ATS_COMPANY_ADD_REQUEST,
        params
    }
}
export const UpdateAtsCompanyAction = (params) => {
    return {
        type: ActionTypes.ATS_COMPANY_UPDATE_REQUEST,
        params
    }
}
export const DeleteAtsCompanyAction = (params) => {
    return {
        type: ActionTypes.ATS_COMPANY_DELETE_REQUEST,
        params
    }
}