import * as ActionTypes from '../ActionTypes';

const initialState = {
    designation_options: [],
    isLoading: false,
};

const DesignationReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.DESIGNATION_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSuccess:false,
                designation_options: "",
            };
        case ActionTypes.DESIGNATION_GET_SUCCESS:
            return {
                ...state,
                success_message: action.payload.message,
                error: action.payload.has_error,
                isLoading: false,
                isSuccess:true,
                designation_options: action.payload?.data?.data
            };
        case ActionTypes.DESIGNATION_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                isSuccess:false,
            };
        case ActionTypes.DESIGNATION_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                isSuccess:true,
                successMessage: action.payload?.message,
                designation_options: action.payload?.data?.data
            };
        case ActionTypes.DESIGNATION_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.DESIGNATION_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.DESIGNATION_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                designation_options: action.payload?.data?.data
            };
        case ActionTypes.DESIGNATION_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.DESIGNATION_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.DESIGNATION_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                designation_options: action.payload?.data?.data
            };
        case ActionTypes.DESIGNATION_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}

export default DesignationReducer;