import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetLeaveSummaryHelper = async (show) => {
    var data = getUserData();
    show?.show !== "no" && toast.info("Loading...")
    return await axiosInstance(`/lms/leaveSummary`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id
        }
    })
        .then((res) => {
            toast.dismiss();
            show?.show !== "no" && res?.data?.data?.length === 0 && toast.warning("No Records Found")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetLeaveReportHelper = async (report) => {
    var data = getUserData();
    toast.info("Loading...")
    return await axiosInstance(`/lms/leaveReport`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id,
            leavetype_id: +(report?.leavetype_id?.leavetype_id),
            year: +(report?.year?.year)
        }
    })
        .then((res) => {
            toast.dismiss();
            res?.data?.data?.length === 0 && toast.warning("No Records Found")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetLeaveLedgerHelper = async () => {
    var data = getUserData();
    toast.info("Loading...")
    return await axiosInstance(`/lms/leaveLedger`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            res?.data?.data?.length === 0 && toast.warning("No Records Found")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
