import * as ActionTypes from '../ActionTypes';

export const GetJobAction = () => {
    return {
        type: ActionTypes.JOB_GET_REQUEST,
    }
}

export const AddJobAction = (job) => {
    return {
        type: ActionTypes.JOB_ADD_REQUEST,
        job
    }
}

export const UpdateJobAction = (job) => {
    return {
        type: ActionTypes.JOB_UPDATE_REQUEST,
        job
    }
}

export const DeleteJobAction = (job) => {
    return {
        type: ActionTypes.JOB_DELETE_REQUEST,
        job
    }
}