import { useDispatch, useSelector } from "react-redux";
import { Box, TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddDocumentationsAction, GetDocumentationsAction, DeleteDocumentationsAction, UpdateDocumentationsAction } from "../../../../../store/Ancillary/Documentations/Action";
import { useEffect } from "react";

const Documentations = () => {

    const dispatch = useDispatch()
    const { documentations_options } = useSelector(state => state?.AncillaryReducer?.DocumentationsReducer);

    useEffect(() => {
        dispatch(GetDocumentationsAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "Proof Type (Display Name)", cellStyle: { padding: "0px 10px" }, field: "proof_name", validate: rowData => rowData?.proof_name === '' || rowData?.proof_name === undefined ? 'Proof Name cannot be empty' : '',
                            editComponent: props => {
                                return (
                                    <TextField
                                        required={true}
                                        variant="standard"
                                        value={props?.value}
                                        onChange={e => {
                                            props.onChange(e.target.value);
                                            !props?.rowData?.tableData?.editing &&
                                                props.onRowDataChange({ ...props.rowData, proof_name: e.target.value, proof_folder_name: e.target.value });
                                            props?.rowData?.tableData?.editing &&
                                                props.onRowDataChange({ ...props.rowData, proof_name: e.target.value });
                                        }}
                                        error={props?.error}
                                        helperText={props?.helperText}
                                    />
                                )
                            }
                        },
                        {
                            title: "Cloud Space Name", cellStyle: { padding: "0px 10px" }, field: "proof_folder_name", editable: 'onAdd', validate: rowData => rowData?.proof_folder_name === '' || rowData?.proof_folder_name === undefined ? 'Cloud Space Name cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        },
                    ]}
                    data={documentations_options || []}
                    icons={{
                        Add: () => <AddIcon />,
                        Edit: () => <EditIcon />,
                        Delete: () => <DeleteIcon />
                    }}
                    editable={{
                        onRowAdd: (newRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(AddDocumentationsAction(newRow))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(UpdateDocumentationsAction(newRow))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowDelete: (selectedRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(DeleteDocumentationsAction({ proof_id: selectedRow?.proof_pk }))
                                setTimeout(() => resolve(), 1000);
                            }),
                    }}
                    options={{
                        maxBodyHeight: 220,
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: false,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    title=""
                />
            </Box>
        </>
    );
}

export default Documentations;
