import * as ActionTypes from '../ActionTypes';
const initialState = {
    isParsing: false,
    error: false,
    success: false,
    GetSuccess:""
};
export const AtsPythonApiReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_RESUME_PYTHON_API_REQUEST:
            return {
                ...state, 
                isParsing: true,
                error: false,
                success: false,
                GetSuccess:""
            };
        case ActionTypes.ATS_RESUME_PYTHON_API_SUCCESS:
            return {
                ...state,
                isParsing: false,
                success: true,
                GetSuccess:action.payload?.message
            };
        case ActionTypes.ATS_RESUME_PYTHON_API_ERROR:
            return {
                ...state,
                isParsing: false,
                success:false,
            };
            case ActionTypes.ATS_RESUME_PYTHON_API_RESET:
                return {
                    ...state,
                    GetSuccess: ""
                };
        default:
            return state;
    }
}