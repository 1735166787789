import * as ActionTypes from '../ActionTypes';

export const GetRelationshipAction = () => {
    return {
        type: ActionTypes.RELATIONSHIP_GET_REQUEST,
    }
}

export const AddRelationshipAction = (relationship) => {
    return {
        type: ActionTypes.RELATIONSHIP_ADD_REQUEST,
        relationship
    }
}

export const UpdateRelationshipAction = (relationship) => {
    return {
        type: ActionTypes.RELATIONSHIP_UPDATE_REQUEST,
        relationship
    }
}

export const DeleteRelationshipAction = (relationship) => {
    return {
        type: ActionTypes.RELATIONSHIP_DELETE_REQUEST,
        relationship
    }
}