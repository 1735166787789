import { FormControl, Grid, FormControlLabel, Button, CircularProgress, TextField, Checkbox, Divider, Box, Typography } from '@mui/material';
import React from 'react';
import SelectableSearch from '../../../Base/views/SelectableSearch';

const AddressDetails = ({
    handleChange,
    addressDetails,
    handleSubmit,
    isLoading,
    curCountryData,
    perCountryData,
    curStateData,
    perStateData,
    curCityData,
    perCityData,
    handleCurCountry,
    handlePerCountry,
    handleCurState,
    handlePerState,
    handleCurCity,
    handlePerCity,
    errors,
    disableEdit,
    setCurrentMode,
    OnboardingEmpData,
    newdata,
    currentMode,
    NextStepper
}) => {

    return (
        <>
            <Grid sx={{ padding: "0px 10px 20px 30px" }} container>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "0px",
                marginTop: "10px",
              }}
            >
                {
                        isLoading ?
                            <CircularProgress disableShrink />
                            :
                            <Button
                                type="submit"
                                variant="contained"
                                size="medium"
                                onClick={(e) => handleSubmit(e)}
                                className="mpl-primary-btn"
                            >
                                Save
                            </Button>
                    }
            </Grid>


                <Grid item xs={12} md={5.8} sx={{ marginTop: "1.5rem", paddingRight: ".5rem" }}>
                    <Typography sx={{ fontWeight: 600 }}> Permanent Address</Typography>
                    <Grid container >
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <FormControl fullWidth >
                                <TextField
                                    disabled={disableEdit}
                                    size="small"
                                    value={addressDetails?.per_street1 || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                    label="Street 1"
                                    name="per_street1"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <FormControl fullWidth >
                                <TextField
                                    disabled={disableEdit}
                                    size="small"
                                    value={addressDetails?.per_street2 || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                    label="Street 2"
                                    name="per_street2"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <SelectableSearch
                                disabled={disableEdit}
                                label="Country"
                                value={addressDetails?.per_country}
                                loading={() => handlePerCountry()}
                                fieldLabel="country_name"
                                variant={"contained"}
                                name="per_country"
                                size="small"
                                data={perCountryData}
                                onChangeEvent={handleChange}
                                required
                                {...(errors.per_country && {
                                    error: true,
                                    helperText: errors.per_country,
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <SelectableSearch
                                disabled={disableEdit}
                                label="State"
                                value={addressDetails?.per_state}
                                loading={() => handlePerState()}
                                fieldLabel="state_name"
                                required
                                variant={"contained"}
                                name="per_state"
                                size="small"
                                data={perStateData}
                                onChangeEvent={handleChange}
                                {...(errors.per_state && {
                                    error: true,
                                    helperText: errors.per_state,
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <SelectableSearch
                                disabled={disableEdit}
                                label="City"
                                value={addressDetails?.per_city}
                                loading={() => handlePerCity()}
                                fieldLabel="city_name"
                                required
                                variant={"contained"}
                                name="per_city"
                                size="small"
                                data={perCityData}
                                onChangeEvent={handleChange}
                                {...(errors.per_city && {
                                    error: true,
                                    helperText: errors.per_city,
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <FormControl fullWidth >
                                <TextField
                                    disabled={disableEdit}
                                    size="small"
                                    value={addressDetails?.per_zipcode || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                    label="Zip Code"
                                    name="per_zipcode"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation='vertical' flexItem variant="fullWidth" />
                <Grid item xs={12} md={5.8} sx={{'@media (max-width: 850px)': { paddingLeft: "0px"}, paddingLeft: "2rem", marginTop: "1.5rem" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: 600 }}> Current Address</Typography>
                        <FormControlLabel
                            sx={{ paddingBottom: "0px" }}
                            control={
                                <Checkbox
                                    name="is_permanent"
                                    sx={{ paddingBottom: "0px", paddingTop: "0px" }}
                                    checked={addressDetails?.is_permanent}
                                    onChange={handleChange}
                                />
                            }
                            label="Same as Permanent"
                        />
                    </Box>
                    <Grid container >
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={addressDetails?.cur_street1 || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                    label="Street 1"
                                    name="cur_street1"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={addressDetails?.cur_street2 || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    id="cur_street2"
                                    label="Street 2"
                                    name="cur_street2"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <SelectableSearch
                                label="Country"
                                value={addressDetails?.cur_country}
                                loading={() => handleCurCountry()}
                                fieldLabel="country_name"
                                variant={"contained"}
                                name="cur_country"
                                size="small"
                                data={curCountryData}
                                onChangeEvent={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <SelectableSearch
                                label="State"
                                value={addressDetails?.cur_state}
                                fieldLabel="state_name"
                                variant={"contained"}
                                name="cur_state"
                                size="small"
                                loading={() => handleCurState()}
                                data={curStateData}
                                onChangeEvent={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <SelectableSearch
                                label="City"
                                value={addressDetails?.cur_city}
                                fieldLabel="city_name"
                                loading={() => handleCurCity()}
                                variant={"contained"}
                                name="cur_city"
                                size="small"
                                data={curCityData}
                                onChangeEvent={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={addressDetails?.cur_zipcode || ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                    label="Zip Code"
                                    name="cur_zipcode"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AddressDetails;