import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const UpdateStatusHelper = async (params) => {
    const data = getUserData();
    !params?.hide && toast.dismiss();
    !params?.hide && toast.info("Updating...")
    return await axiosInstance(`/ancillary/updateStatus`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            workspace: data?.domain,
            user_empid: data?.employee_id,
            update_id: params.update_id,
            status: params.status,
            column_name: params.column_name,
        }
    })
        .then((res) => {
            !params?.hide && toast.dismiss();
            !params?.hide && (params?.successMessage ? toast.success(params?.successMessage) : toast.success("Status Updated Successfully"))
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })

}