import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";
import { toast } from "react-toastify";

export const subscribeEmailHelper = async (params) => {
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance("/ancillary/addSubscribe", {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
          user_id: params?.emailid,
        }
    })
        .then((res) => {
          toast.dismiss();
          toast.success("Subscribed Successfully")
          return { message: res?.data };
        })
        .catch((err) => {
          toast.dismiss();
          err?.response?.data?.error?.[0]?.message && toast.error(err?.response?.data?.error?.[0]?.message)
          return { message: err?.response?.data };
        });
};
