import React from "react";
const OurFeature = () => {
  return (
    <>
      <div id="Feature" className="section grey-section">
        <div className="container">
          <div className="title-container">
            <div className="center-text">
              <div className="half-title-container">
                <div className="title-with-emoji">
                  <div className="fade-in-move-on-scroll">
                    <h1 className="title">
                      Features You will
                      <span className="text-with-underline red">love</span>
                    </h1>
                  </div>
                  <div className="emoji-holder _03">
                    <div>🖤</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grey-cards-holder">
              <a href="#FAQ" className="white-card w-inline-block">
                <div className="tab-icon-holder _01">
                  <img
                    loading="lazy"
                    src="https://assets.website-files.com/634e968b219cc43522715fb8/634ff1ecf7785f6544ba245e_Calendar%20Icon.svg"
                    alt=""
                    className="tab-icon"
                  />
                </div>
                <div className="card-title-holder">
                  <div className="card-title">Parsing of CV</div>
                </div>
                <div className="card-description-holder">
                  {/* <p className="card-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Massa dictum diam sit tellus nulla tellus sed condimentum
                    dolor.
                  </p> */}
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle black"></div>
                  <div className="small-circle orange"></div>
                </div>
                <div className="card-borders"></div>
              </a>
              <a href="#FAQ" className="white-card w-inline-block">
                <div className="tab-icon-holder _02">
                  <img
                    loading="lazy"
                    src="https://assets.website-files.com/634e968b219cc43522715fb8/634ff1ecde84c0ce963a5e48_Check%20Icon.svg"
                    alt=""
                    className="tab-icon"
                  />
                </div>
                <div className="card-title-holder">
                  <div className="card-title">
                  Manage your Hiring stage end to end
                  </div>
                </div>
                <div className="card-description-holder">
                  {/* <p className="card-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Massa dictum diam sit tellus nulla tellus sed condimentum
                    dolor.
                  </p> */}
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle black"></div>
                  <div className="small-circle green"></div>
                </div>
                <div className="card-borders"></div>
              </a>
              <a href="#FAQ" className="white-card w-inline-block">
                <div className="tab-icon-holder _03">
                  <img
                    loading="lazy"
                    src="https://assets.website-files.com/634e968b219cc43522715fb8/634ff1ec45e80bc2f55099ef_Pulse%20Icon.svg"
                    alt=""
                    className="tab-icon"
                  />
                </div>
                <div className="card-title-holder">
                  <div className="card-title">
                  Communicate with the candidate
                  </div>
                </div>
                <div className="card-description-holder">
                  {/* <p className="card-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Massa dictum diam sit tellus nulla tellus sed condimentum
                    dolor.
                  </p> */}
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle black"></div>
                  <div className="small-circle blue"></div>
                </div>
                <div className="card-borders"></div>
              </a>
              <a href="#FAQ" className="white-card w-inline-block">
                <div className="tab-icon-holder _04">
                  <img
                    loading="lazy"
                    src="https://assets.website-files.com/634e968b219cc43522715fb8/635007d9f02793a3cbbc0e12_Restart%20Icon.svg"
                    alt=""
                    className="tab-icon"
                  />
                </div>
                <div className="card-title-holder">
                  <div className="card-title">
                  Invite multiple hiring consultants
                  </div>
                </div>
                <div className="card-description-holder">
                  {/* <p className="card-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Massa dictum diam sit tellus nulla tellus sed condimentum
                    dolor.
                  </p> */}
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle black"></div>
                  <div className="small-circle blue"></div>
                </div>
                <div className="card-borders"></div>
              </a>
              <a href="#FAQ" className="white-card w-inline-block">
                <div className="tab-icon-holder _04">
                  <img
                    loading="lazy"
                    src="https://assets.website-files.com/634e968b219cc43522715fb8/635007d915081fe7ad1bd7bf_User%20Icon.svg"
                    alt=""
                    className="tab-icon"
                  />
                </div>
                <div className="card-title-holder">
                  <div className="card-title">
                  Live collaboration with all stake holders
                  </div>
                </div>
                <div className="card-description-holder">
                  {/* <p className="card-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Massa dictum diam sit tellus nulla tellus sed condimentum
                    dolor.
                  </p> */}
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle black"></div>
                  <div className="small-circle purple"></div>
                </div>
                <div className="card-borders"></div>
              </a>
              <a href="#FAQ" className="white-card w-inline-block">
                <div className="tab-icon-holder _05">
                  <img
                    loading="lazy"
                    src="https://assets.website-files.com/634e968b219cc43522715fb8/635007d99511ed0bbffa6ce6_Slider%20Icon.svg"
                    alt=""
                    className="tab-icon"
                  />
                </div>
                <div className="card-title-holder">
                  <div className="card-title">
                  Tag, Assign & Organize all your task
                  </div>
                </div>
                <div className="card-description-holder">
                  {/* <p className="card-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Massa dictum diam sit tellus nulla tellus sed condimentum
                    dolor.
                  </p> */}
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle black"></div>
                  <div className="small-circle"></div>
                </div>
                <div className="card-borders"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurFeature;
