import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: true,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: "",
    leaveYearTypeData: []
}

export const LeaveYearTypeReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LEAVE_YEAR_TYPE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.LEAVE_YEAR_TYPE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveYearTypeData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_YEAR_TYPE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.LEAVE_YEAR_TYPE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.LEAVE_YEAR_TYPE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                leaveYearTypeData: action.payload?.data?.data
            };
        case ActionTypes.LEAVE_YEAR_TYPE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}