import * as ActionTypes from '../ActionTypes';

export const GetMeeplAdminJobTemplateAction = (params) => {
    return {
        type: ActionTypes.MEEPL_ADMIN_JOB_TEMPLATE_GET_REQUEST,
        params
    }
}

export const GetJobTemplateAction = (params) => {
    return {
        type: ActionTypes.JOB_TEMPLATE_GET_REQUEST,
        params
    }
}


export const AddJobTemplateAction = (params) => {
    return {
        type: ActionTypes.JOB_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const UpdateJobTemplateAction = (params) => {
    return {
        type: ActionTypes.JOB_TEMPLATE_UPDATE_REQUEST,
        params
    }
}

export const DeleteJobTemplateAction = (params) => {
    return {
        type: ActionTypes.JOB_TEMPLATE_DELETE_REQUEST,
        params
    }
}