
export const ROLE_GET_REQUEST = "ROLE_GET_REQUEST";
export const ROLE_GET_SUCCESS = "ROLE_GET_SUCCESS";

export const ROLE_USER_GET_REQUEST = "ROLE_USER_GET_REQUEST";
export const ROLE_USER_GET_SUCCESS = "ROLE_USER_GET_SUCCESS";

export const MODULE_GET_REQUEST = "MODULE_GET_REQUEST";
export const MODULE_GET_SUCCESS = "MODULE_GET_SUCCESS";

export const ATS_MODULE_GET_REQUEST = "ATS_MODULE_GET_REQUEST";
export const ATS_MODULE_GET_SUCCESS = "ATS_MODULE_GET_SUCCESS";

export const ATS_MODULE_FEATURES_GET_REQUEST = "ATS_MODULE_FEATURES_GET_REQUEST";
export const ATS_MODULE_FEATURES_GET_SUCCESS = "ATS_MODULE_FEATURES_GET_SUCCESS";

export const SUB_MODULE_GET_REQUEST = "SUB_MODULE_GET_REQUEST";
export const SUB_MODULE_GET_SUCCESS = "SUB_MODULE_GET_SUCCESS";

export const PERMISSION_GET_REQUEST = "PERMISSION_GET_REQUEST";
export const PERMISSION_GET_SUCCESS = "PERMISSION_GET_SUCCESS";

export const ROLE_ACCESS_GET_REQUEST = "ROLE_ACCESS_GET_REQUEST";
export const ROLE_ACCESS_GET_SUCCESS = "ROLE_ACCESS_GET_SUCCESS";

export const ROLE_ACCESS_ADD_REQUEST = "ROLE_ACCESS_ADD_REQUEST";
export const ROLE_ACCESS_ADD_SUCCESS = "ROLE_ACCESS_ADD_SUCCESS";
export const ROLE_ACCESS_ADD_ERROR = "ROLE_ACCESS_ADD_ERROR";

export const ROLE_ACCESS_UPDATE_REQUEST = "ROLE_ACCESS_UPDATE_REQUEST";
export const ROLE_ACCESS_UPDATE_SUCCESS = "ROLE_ACCESS_UPDATE_SUCCESS";
export const ROLE_ACCESS_UPDATE_ERROR = "ROLE_ACCESS_UPDATE_ERROR";

export const ROLE_ACCESS_DELETE_REQUEST = "ROLE_ACCESS_DELETE_REQUEST";
export const ROLE_ACCESS_DELETE_SUCCESS = "ROLE_ACCESS_DELETE_SUCCESS";
export const ROLE_ACCESS_DELETE_ERROR = "ROLE_ACCESS_DELETE_ERROR";

export const POWER_USER_DISABLED_ACCESS = "POWER_USER_DISABLED_ACCESS";