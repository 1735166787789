import React from 'react';
import BadgeIcon from "@mui/icons-material/Badge";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

const AdminMenuBar = ({
    handleMenuClick,
    theme,
    selectedMenu,
    open,
    isMobile
}) => {

    const sideBarData1 = [
        { label: "Dashboard", icon: <DashboardIcon fontSize='small' />, route: "/home" },
        { label: "It's Me", icon: <BadgeIcon fontSize='small' />, route: "/employee" },
        { label: "My Leave", icon: <EditCalendarIcon fontSize='small' />, route: "/leave" },
    ]

    return (
        <div>
            <List sx={{ pt: 0 }}>
                {
                    sideBarData1.map((res, id) => {
                        return (
                            <Link to={res?.route} style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                                <ListItem
                                    key={id}
                                    disablePadding
                                    sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}` }}
                                    onClick={(e) => (handleMenuClick(res?.label))}
                                    className={selectedMenu === res?.label ? "gradiant" : ""}
                                >
                                    <ListItemButton
                                        key={id}
                                        sx={{
                                            minHeight: 10,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            key={id}
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 2 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <span style={{ color: selectedMenu === res?.label && "white" }}>{res?.icon}</span>
                                        </ListItemIcon>
                                        <ListItemText primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        )
                    })
                }
            </List>
        </div>
    );
}

export default AdminMenuBar;
