const RequestEmailTemplate = ({ name }) => (
    <table width="100%" border="0" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0, ml: 2 }}>
                Dear {name},
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" ,textAlign:"center" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0  }}>
                    Thank you for your interest in Meepl. <br />
                    Our admin team will be in touch with you shortly<br />
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)

export default RequestEmailTemplate;