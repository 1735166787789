import * as ActionTypes from './ActionTypes';

const initialState = {
    open: false,
    fixedMenu: false,
    atsCollapse: false,
    atsManageCollapse: false,
    atsReportCollapse: false,
    lmsCollapse:false,
    EmployeeCollapse:false
};

const MenuReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MOBILE_MENU:
            return {
                ...state,
                isMobile: action.payload
            }
        case ActionTypes.FIXED_MENU_OPEN:
            return {
                ...state,
                open: true,
                fixedMenu: true
            };
        case ActionTypes.FIXED_MENU_CLOSE:
            return {
                ...state,
                open: false,
                fixedMenu: false
            };
        case ActionTypes.OPEN_MENU:
            return {
                ...state,
                open: true
            };
        case ActionTypes.CLOSE_MENU:
            return {
                ...state,
                open: false
            };
        case ActionTypes.ATS_COLLAPSE_MENU:
            return {
                ...state,
                atsCollapse: !state?.atsCollapse
            }
        case ActionTypes.ATS_COLLAPSE_SUB_MENU:
            return {
                ...state,
                atsManageCollapse: !state?.atsManageCollapse
            }
        case ActionTypes.ATS_REPORT_COLLAPSE_MENU:
            return {
                ...state,
                atsReportCollapse: !state?.atsReportCollapse
            }
            case ActionTypes.LMS_COLLAPSE_MENU:
                return {
                    ...state,
                    lmsCollapse: !state?.lmsCollapse
                }
                case ActionTypes.EMPLOYEE_COLLAPSE_MENU:
                    return {
                        ...state,
                        EmployeeCollapse: !state?.EmployeeCollapse
                    }
        case ActionTypes.ATS_MENU_HEADING:
            return {
                ...state,
                MenuHeading: action?.params?.heading
            }
        default:
            return state;
    }
}

export default MenuReducer;