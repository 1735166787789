import { put, call } from 'redux-saga/effects';
import { GetPlansHelper} from '../../app/Components/Plans/helper/PlansHelper';
import * as ActionTypes from './ActionTypes';

export function* PlansSaga(payload) {
    const response = yield call(GetPlansHelper, payload?.data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PLANS_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PLANS_GET_ERROR, payload: response?.message?.error?.[0]?.message })
    }
}

