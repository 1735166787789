import { Box, Button, Typography } from "@mui/material";
import React from "react";
import warning from "../../../../Image/alert.png";

const ValidationPopup = ({
    setShow,
    showContent,
}) => {

  return (
    <Box
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <img
      src={warning}
      alt="Warning"
      width={"100px"}
      height={"100px"}
    />
    <Typography sx={{ pt: 1, pb: 1, textAlign:"center" }} variant="h4">
     {showContent}
    </Typography>
    <Button
        onClick={() => {
          setShow(false);
        }}
        variant="contained"
        color="primary"
        type="submit"
        className="mpl-primary-btn"
        sx={{
          marginTop: "1.75rem",
          marginRight: "1rem",
          fontSize: "20px",
          padding: "0px 40px 0px 40px",
        }}
      >
        Ok
      </Button>
  </Box>
    
  );
};

export default ValidationPopup;
