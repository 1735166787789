import * as ActionTypes from '../ActionTypes';

export const GetEmploymentTypeAction = () => {
    return {
        type: ActionTypes.EMPLOYMENT_TYPE_GET_REQUEST
    }
}


export const AddEmploymentTypeAction = (params) => {
    return {
        type: ActionTypes.EMPLOYMENT_TYPE_ADD_REQUEST,
        params
    }
}

export const UpdateEmploymentTypeAction = (params) => {
    return {
        type: ActionTypes.EMPLOYMENT_TYPE_UPDATE_REQUEST,
        params
    }
}

export const DeleteEmploymentTypeAction = (params) => {
    return {
        type: ActionTypes.EMPLOYMENT_TYPE_DELETE_REQUEST,
        params
    }
}