import * as ActionTypes from '../ActionTypes';

export const GetEmployeeBasicAction = (params) => {
    return {
        type: ActionTypes.GET_EMPLOYEE_BASIC_REQUEST,
        params
    }
}

export const AddEmployeeBasicAction = (basicDetails) => {
    return {
        type: ActionTypes.ADD_EMPLOYEE_BASIC_REQUEST,
        basicDetails
    }
}
export const UpdateEmployeeBasicAction = (basicDetails) => {
    return {
        type: ActionTypes.UPDATE_EMPLOYEE_BASIC_REQUEST,
        basicDetails
    }
}
