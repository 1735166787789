import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import NProgress from 'nprogress';
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetFormTemplateHelper = async (params) => {
    const userData = getUserData()
    NProgress.start()
    return await axiosInstance(`/ats/getFormTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain || params?.workspace,
            template_id: params?.template_id || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddFormTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.info("Saving...")
    return await axiosInstance(`/ats/addFormTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            user_id: userData?.employee_id,
            template_name: params?.template_name,
            template_fields: params?.template_fields,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Form Template Added Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateFormTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateFormTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            user_id: userData?.employee_id,
            template_id: params?.template_id,
            template_name: params?.template_name,
            template_fields: params?.template_fields
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Form Template Updated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteFormTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/deleteFormTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            template_id: params?.template_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Form Template Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


//Pre Screen Questions

export const GetPreScreenTemplateHelper = async (params) => {
    const userData = getUserData()
    NProgress.start()
    return await axiosInstance(`/ats/getPreScreenTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain || params?.workspace,
            prescreen_template_id: params?.prescreen_template_id || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddPreScreenTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.info("Saving...")
    return await axiosInstance(`/ats/addPreScreenTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            user_id: userData?.employee_id,
            prescreen_template_name: params?.prescreen_template_name,
            prescreen_template_fields: params?.prescreen_template_fields,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Form Template Added Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdatePreScreenTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updatePreScreenTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            user_id: userData?.employee_id,
            prescreen_template_id: params?.prescreen_template_id,
            prescreen_template_name: params?.prescreen_template_name,
            prescreen_template_fields: params?.prescreen_template_fields
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Form Template Updated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeletePreScreenTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/deletePreScreenTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            prescreen_template_id: params?.prescreen_template_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Form Template Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


//Offer Letter

export const GetOfferLetterTemplateHelper = async (params) => {
    const userData = getUserData()
    NProgress.start()
    return await axiosInstance(`/ats/getOfferLetterTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain || params?.workspace,
            offer_letter_template_id: params?.offer_letter_template_id || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddOfferLetterTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.info("Saving...")
    return await axiosInstance(`/ats/addOfferLetterTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            user_id: userData?.employee_id,
            offer_letter_template_name: params?.offer_letter_template_name,
            offer_letter_template_fields: params?.offer_letter_template_fields,
            access_header: params?.access_header,
            access_footer: params?.access_footer,
            access_company_logo: params?.access_company_logo,
            header: params?.header,
            footer: params?.footer,
            company_logo: params?.company_logo,
            image_name: params?.image_name,
            offer_letter_content: null
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Template Saved as draft")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateOfferLetterTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateOfferLetterTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            user_id: userData?.employee_id,
            offer_letter_template_id: params?.offer_letter_template_id,
            offer_letter_template_name: params?.offer_letter_template_name,
            offer_letter_template_fields: params?.offer_letter_template_fields,
            access_header: params?.access_header,
            access_footer: params?.access_footer,
            access_company_logo: params?.access_company_logo,
            header: params?.header,
            footer: params?.footer,
            company_logo: params?.company_logo,
            image_name: params?.image_name,
            old_image: params?.old_image,
            offer_letter_content: params?.offer_letter_content
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Template Saved as draft")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteOfferLetterTemplateHelper = async (params) => {
    const userData = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/deleteOfferLetterTemplate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            offer_letter_template_id: params?.offer_letter_template_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("OfferLetter Template Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const OfferLetterFieldsHelper = async (params) => {
    const userData = getUserData()
    return await axiosInstance(`/ats/OfferLetterFields`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: userData?.domain,
            offer_letter_template_id: params?.offer_letter_template_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
