import * as ActionTypes from '../ActionTypes';

export const GetEmployeeFamilyAction = (familyData) => {
    return {
        type: ActionTypes.EMPLOYEE_FAMILY_GET_REQUEST,
        familyData
    }
}
export const AddEmployeeFamilyAction = (familyData) => {
    return {
        type: ActionTypes.EMPLOYEE_FAMILY_ADD_REQUEST,
        familyData
    }
}
export const UpdateEmployeeFamilyAction = (familyData) => {
    return {
        type: ActionTypes.EMPLOYEE_FAMILY_UPDATE_REQUEST,
        familyData
    }
}
export const DeleteEmployeeFamilyAction = (familyData) => {
    return {
        type: ActionTypes.EMPLOYEE_FAMILY_DELETE_REQUEST,
        familyData
    }
}