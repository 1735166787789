import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Grid, Skeleton } from '@mui/material';
import { useState } from 'react';
import Avatar from 'react-avatar';
import TimeAgo from '../../../Base/views/TimeAgo';
import { DownloadAttachment } from '../../../Base/helper/baseFunctions';

const ProposedJobHistory = ({ loading, activityHistory, }) => {
    const [expanded, setExpanded] = useState([]);
    const actionSummaryStyle = {
        minHeight: 50,
        maxHeight: 50,
        borderRadius: "10px 10px 0px 0px",
        '&.Mui-expanded': {
            minHeight: 50,
            maxHeight: 50,
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        let f = expanded.findIndex(res => res === panel)
        if (f === -1) setExpanded(expanded => [...expanded, panel]);
        else {
            let orgValue = expanded
            orgValue.splice(f, 1)
            setExpanded([...orgValue]);
        }
    };

    return (
        <>
            {
                loading ?
                    <Grid item xs={12} md={12} padding={1.5}>
                        <Typography variant="h6" gutterBottom sx={{ paddingLeft: "15px" }}>
                            Proposed Job history
                        </Typography>

                        <Grid container spacing={2}>
                            {Array(4).fill().map((d, i) => (
                                <Grid key={i} item xs={12} md={6} lg={6} sm={12}>
                                    <Typography component="div" variant="h2">
                                        <Skeleton />
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    :
                    <>
                        {
                            activityHistory?.length > 0 &&
                            <Grid item xs={12} md={12} padding={1.5}>
                                <Typography variant="h6" gutterBottom sx={{ paddingLeft: "15px" }}>
                                    Proposed Job history
                                </Typography>

                                <Grid container spacing={2}>
                                    {
                                        activityHistory.map((res, index) => {
                                            return (
                                                <Grid key={index} item xs={12} md={6} lg={6} sm={12}>
                                                    <Accordion expanded={expanded?.includes(index)} onChange={handleChange(index)}>
                                                        <AccordionSummary
                                                            sx={actionSummaryStyle}
                                                            aria-controls="activity-history-content"
                                                            id="activity-history-header"
                                                        >
                                                            <Typography sx={{ flexShrink: 0, fontWeight: 600, fontSize: "13px", pr: 1 }}>
                                                                {res?.title}
                                                            </Typography>
                                                            <Typography sx={{ color: 'text.secondary', pr: 1 }}>{res?.company}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ maxHeight: "30vh", overflowY: "auto" }}>
                                                            {res?.cand_activity_hist?.map((hist, i) => {
                                                                let date = new Date(hist?.modifiedon)
                                                                date.setHours(date.getHours() + 5);
                                                                date.setMinutes(date.getMinutes() + 30);
                                                                return (
                                                                    <div key={i} style={{ display: "flex", alignItems: "center", paddingTop: "10px", }}>
                                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                                            <Avatar name={`${hist?.modifiedby || ""}`} round="50%" size='30' style={{ padding: "0px", fontSize: "10px", marginBottom: 2 }} />
                                                                            <div>
                                                                                <Typography sx={{ paddingLeft: 2 }} variant='body1'><span style={{ fontWeight: 600 }}>{hist?.modifiedby}</span>  {hist?.modifiedon && <TimeAgo timeStyle="round" date={date} locale="en-US" />}</Typography>
                                                                                {(hist?.ats_job_stages !== hist?.previous_stage_id) && !hist?.is_feedback_record && !hist?.requester_name && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Changed the stage <span style={{ fontWeight: 600 }}>{hist?.previous_stage_id === "" || hist?.previous_stage_id === null ? "Candidate Proposed" : `${hist?.previous_stage_id || "None"} → ${hist?.ats_job_stages || "None"}`}</span></Typography>}
                                                                                {hist?.priority !== hist?.previous_priority && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Changed the priority <span style={{ fontWeight: 600 }}>{hist?.previous_priority || "None"} → {hist?.priority || "None"}</span></Typography>}
                                                                                {hist?.status !== hist?.previous_status && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Changed the status <span style={{ fontWeight: 600 }}>{hist?.previous_status || "None"} → {hist?.status || "None"}</span></Typography>}
                                                                                {(hist?.selected !== hist?.previous_selected && hist?.selected === "True") && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Candidate got  <span style={{ fontWeight: 600 }}>Selected</span></Typography>}
                                                                                {(hist?.selected !== hist?.previous_selected && hist?.selected === "False") && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Candidate got  <span style={{ fontWeight: 600 }}>Unselected </span></Typography>}
                                                                                {hist?.comment && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Added comment <span style={{ fontWeight: 600 }}>{hist?.comment}</span></Typography>}
                                                                                {hist?.interview_feedback && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Added feedback <span style={{ fontWeight: 600 }}>{hist?.interview_feedback}</span></Typography>}
                                                                                {hist?.active_status === "Inactive" && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Candidate Archived from the Job</Typography>}
                                                                                {hist?.interview_feedback && <Typography sx={{ paddingLeft: 2 }} variant='body2'>Added feedback <span style={{ fontWeight: 600 }}>{hist?.interview_feedback}</span>{hist?.requester_name !== null && hist?.responder_name !== null && <span style={{ fontWeight: 600 }}> and eligibility {hist?.is_eligible ? "Yes" : "No"}</span>}<br />{hist?.feedback_proof_name !== null && <Typography sx={{ color: "#1155cc", cursor: "pointer" }} onClick={() => DownloadAttachment(hist.feedback_proof_name)}>Feedback Document</Typography>}</Typography>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })

                                                            }

                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        }
                    </>
            }

        </>
    )
}

export default ProposedJobHistory