import { HRMS_LMS_API_URL } from '../../../Constants/baseConstant';
import { getUserData } from '../../Base/helper/baseFunctions';
import axiosInstance from '../../../../axiosInstance';

export const addFaceDataHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/addFaceData`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_id:data.employee_id,
            emp_id: params?.employee_id,
            record_time: params?.record_time,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const getFaceDataHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/getFaceData`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            from_date: params?.from_date,
            to_date: params?.to_date,
            emp_id: params?.emp_id,
            reporting_manager_emp_id: params?.reporting_manager_emp_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}