import React, { useEffect } from "react";
import MaterialTable from "material-table";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ConvertDateTime } from "../../Base/helper/baseFunctions";
import { getWorkspaceExpiryRequestAction } from "../../../../store/MeeplAdmin/Workspace/Action";
import SelectableSearch from "../../Base/views/SelectableSearch";
import { PlansAction } from "../../../../store/Plans/Action";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Popup from "../../Base/views/Popup";
import CommonDatePicker from "../../Base/views/CommonDatePicker";
import UpdateIcon from "@mui/icons-material/Update";
import { updateWorkspaceExpiryRequestHelper } from "../helper/WorkspaceHelper";

const ExpiryWorkspace = ({ value, setworkspaceManage }) => {
  const enable_initialValues = {
    expiry_date: "",
    planid: "",
  };
  const dispatch = useDispatch();
  const theme = useTheme();
  const { ExtendWorkspaceRequestdata, Loading } = useSelector(
    (state) => state?.MeeplAdminReducer?.WorkspaceReducer
  );
  const [enable, setEnable] = useState(false);
  const { plansdata } = useSelector((state) => state?.PlansReducer);
  const [plansData, setplansData] = useState({ load: true, values: [] });
  const [enableData, setEnableData] = useState(enable_initialValues);
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    if (value === "3") {
      dispatch(getWorkspaceExpiryRequestAction({ pre_req: "true" }));
    } else {
      dispatch(getWorkspaceExpiryRequestAction({ pre_req: "false" }));
    }
    dispatch(PlansAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    currentData !== null &&
      setEnableData({
        ...currentData,
        planid: currentData?.planid
          ? {
              planid: currentData?.planid,
              planname: currentData?.planname,
            }
          : "",
      });
    // eslint-disable-next-line
  }, [currentData]);

  const handleEnableChange = (event, value) => {
    if (["planid"].includes(event)) {
      if (value) {
        setEnableData({ ...enableData, [event]: value });
      } else {
        setEnableData({ ...enableData, [event]: "" });
      }
    } else {
      setEnableData({ ...enableData, [event.target.name]: event.target.value });
    }
  };

  const handleEnableWorkspace = () => {
    if (currentData?.company_id) {
      if (value === "3") {
        updateWorkspaceExpiryRequestHelper({
          ...enableData,
          pre_req: true,
        })
          .then((res) => {
            dispatch(getWorkspaceExpiryRequestAction({ pre_req: "true" }));
          })
          .catch((err) => {});
      } else {
        updateWorkspaceExpiryRequestHelper({
          ...enableData,
          pre_req: false,
        })
          .then((res) => {
            dispatch(getWorkspaceExpiryRequestAction({ pre_req: "false" }));
          })
          .catch((err) => {});
      }

      setEnable(false);
      setCurrentData([]);
      setEnableData(enable_initialValues);
    }
  };

  const handleEnableclose = () => {
    setEnable(false);
    setCurrentData([]);
    setEnableData(enable_initialValues);
  };

  return (
    <>
      {Loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          margin: "16px 16px 0px 16px",
          boxShadow:
            "0px 2px 4px -1px rgba(158, 158, 158, 0.2), 0px 4px 5px 0px rgba(158, 158, 158, 0.14), 0px 1px 10px 0px rgba(158, 158, 158, 0.12)",
        }}
      >
        <MaterialTable
          columns={[
            {
              title: "Company Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "company_full_name",
              grouping: false,
            },
            {
              title: "Workspace",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "domainname",
              grouping: false,
            },
            {
              title: "Email ID",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "email_id",
              grouping: false,
            },
            {
              title: "Team Size",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "team_size",
              grouping: false,
            },
            {
              title: "Plan Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "planname",
              grouping: false,
            },
            {
              title: "Expiry Date",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "expiry_date",
              grouping: false,
              render: (rowData) => {
                return rowData?.expiry_date
                  ? ConvertDateTime(rowData?.expiry_date)
                  : "";
              },
            },
            {
              title: "Requested Date",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "requested_date",
              grouping: false,
              render: (rowData) => {
                return rowData?.requested_date
                  ? ConvertDateTime(rowData?.requested_date)
                  : "";
              },
            },
          ]}
          data={ExtendWorkspaceRequestdata || []}
          options={{
            maxBodyHeight: "62vh",
            minBodyHeight: "62vh",
            rowStyle: { fontSize: "13px" },
            headerStyle: {
              backgroundColor:
                theme?.palette?.mode === "light"
                  ? "#fff"
                  : theme.palette.grey[500],
              color:
                theme?.palette?.mode === "light"
                  ? "#000"
                  : theme.palette.grey[800],
              fontWeight: "bold",
              padding: "10px 10px 10px 13px",
            },
            sorting: true,
            search: false,
            searchText: "",
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            paging: true,
            pageSizeOptions: [25, 50],
            pageSize: 25,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            exportButton: false,
            exportAllData: true,
            exportFileName: "Client",
            addRowPosition: "first",
            selection: false,
            actionsColumnIndex: -1,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: false,
            toolbar: false,
          }}
          actions={[
            (rowData) => ({
              icon: () => {
                return <UpdateIcon fontSize="small" color="success" />;
              },
              tooltip: "Extend Expiry Date",
              onClick: (_event, rowData) => {
                setCurrentData(rowData);
                setEnable(true);
              },
            }),
          ]}
          title="Created Schemas"
        />
      </Box>

      <Popup
        maxWidth="sm"
        title="Extend Expiry Date"
        isMasterProduct={true}
        setOpenPopup={handleEnableclose}
        openPopup={enable}
        fullWidth={true}
      >
        <Box>
          <FormControl fullWidth>
            <CommonDatePicker
              size="small"
              name="expiry_date"
              label="Expiry Date"
              pickerType="date"
              onKeyPress={(e) => e.preventDefault()}
              onKeyDown={(e) => e.preventDefault()}
              pickerFormat="dd/MM/yyyy"
              DefaultDTvalue={enableData?.expiry_date}
              required
              minDate={new Date()}
              handleChange={(value) =>
                handleEnableChange({
                  target: {
                    name: "expiry_date",
                    value: value
                      ? `${
                          value?.getFullYear() +
                          "-" +
                          ("0" + (value?.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + value?.getDate()).slice(-2)
                        }`
                      : "",
                  },
                })
              }
            />
          </FormControl>
          <FormControl fullWidth sx={{ pt: 1 }}>
            <SelectableSearch
              isOptionEqualToValue={(option, value) =>
                +option.planid === +value.planid
              }
              filterOptions="planid"
              keys={["planid", "planname"]}
              label="Plans"
              required
              value={enableData?.planid}
              loading={() =>
                setplansData({ load: false, values: plansdata || [] })
              }
              fieldLabel="planname"
              variant={"contained"}
              name="planid"
              size="small"
              data={plansData}
              onChangeEvent={handleEnableChange}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.planname} - &#8377; {option.plancost}
                </Box>
              )}
            />
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: -2,
            }}
          >
            <Button
              disabled={Object.values(enableData)?.includes("")}
              onClick={handleEnableWorkspace}
              variant="contained"
              className="mpl-primary-btn"
              sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Popup>
    </>
  );
};

export default ExpiryWorkspace;
