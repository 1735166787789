import * as ActionTypes from '../ActionTypes';

export const GetLeaveRequestAction = (params) => {
    return {
        type: ActionTypes.LEAVE_REQUEST_GET_REQUEST,
        params
    }
}

export const AddLeaveRequestAction = (leaveRequestDetails) => {
    return {
        type: ActionTypes.LEAVE_REQUEST_ADD_REQUEST,
        leaveRequestDetails
    }
}
export const UpdateLeaveRequestAction = (leaveRequestDetails) => {
    return {
        type: ActionTypes.LEAVE_REQUEST_UPDATE_REQUEST,
        leaveRequestDetails
    }
}
export const DeleteLeaveRequestAction = (leaveRequestDetails) => {
    return {
        type: ActionTypes.LEAVE_REQUEST_DELETE_REQUEST,
        leaveRequestDetails
    }
}