import { Box, Button, Paper,Tab } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import Popup from "../../../../Base/views/Popup";
import ApplyLeave from "./ApplyLeave";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Calender from "./MyTimeOffCalender";
import { useDispatch } from "react-redux";
import MyTimeOffBookings from "./MyTimeOffBokings";
import { GetLeaveSummaryAction } from "../../../../../../store/Leave/LeaveReports/Action";
import { GetLeaveOverallBalanceAction, GetPendingLeaveApprovalAction } from "../../../../../../store/Leave/LeaveDashboard/Action";
import { GetUpcomingHolidaysAction } from "../../../../../../store/Leave/HolidayCalendar/Action";


const MyTimeOff = () => {
  const dispatch = useDispatch()
  const [openPopup, setOpenPopup] = useState(false);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    dispatch(GetLeaveSummaryAction({ show: "no" }));
    dispatch(GetLeaveOverallBalanceAction({ show: "no" }));
    dispatch(GetUpcomingHolidaysAction({ show: "no" }));
    dispatch(GetPendingLeaveApprovalAction());
    //eslint-disable-next-line
}, []);
  const handleBookClick = () => {
    setOpenPopup(true);
  };
  const [value, setValue] = useState('1');
  const handleValueChange = (event, newValue, calendarValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ marginTop: "20px" }}>
        <Button
          sx={{
            fontWeight: 700,
            borderRadius: "8px",
            fontSize: "13px",
            height: "30px",
            padding: "4px 10px",
            boxShadow: "none",
            lineHeight: 1.71429,
            marginRight: "10px",
          }}
          size="small"
          variant="contained"
          className="mpl-primary-btn"
          onClick={() => handleBookClick()}
        >
          Book TimeOff
        </Button>
      </Box>
      <Popup
        fullWidth={true}
        maxWidth="lg"
        title="Book TimeOff"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ApplyLeave
          setOpenPopup={setOpenPopup}
          my_time_off={true}
          setFlag={setFlag}
        />
      </Popup>

      <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
            <Paper sx={{ borderRadius: "10px", minHeight: "87vh" }}>
                <Box sx={{ width: '100%' }}>
                    <TabContext  value={value}>
                        <Box>
                            <TabList sx={{
                                minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                '& .MuiTabs-indicator': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: "center !important",
                                },
                            }} onChange={handleValueChange} aria-label="lab API tabs example">
                                <Tab label="Calendar" value="1" />
                                <Tab label="Bookings" value="2" />
                            </TabList>
                        </Box>
                       
                        <TabPanel value="1" sx={{ padding: "0px" }}>
                            <Calender flag={ flag }/>
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                            < MyTimeOffBookings flag={ flag }/>
                        </TabPanel>
                       
                    </TabContext>
                </Box>
            </Paper>
            </Box>
      
    </>
  );
};

export default MyTimeOff;
