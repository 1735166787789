import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetMyTimeConfigureHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/getMyTimeConfigure`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddMyTimeConfigureHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/addMyTimeConfigure`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.emp_users_pk,
            project_code:params?.project_code,
            weekly_hours: params?.weekly_hours,
            weekly_working_days: params?.weekly_working_days,
            working_days:params?.working_days,
            daily_hours:params?.daily_hours,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("MyTimeConfigure added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateMyTimeConfigureHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/updateMyTimeConfigure`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.emp_users_pk,
            project_code:params?.project_code,
            weekly_hours: params?.weekly_hours,
            weekly_working_days: params?.weekly_working_days,
            working_days:params?.working_days,
            daily_hours:params?.daily_hours,
            mytime_id: params?.mytime_pk
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("MyTimeConfigureUpdated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteMyTimeConfigureHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/deleteMyTimeConfigure`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            mytime_id: params?.mytime_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("MyTimeConfigure Deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}