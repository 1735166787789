// ** MUI Imports
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import Typography from '@mui/material/Typography'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import MuiTimeline from '@mui/lab/Timeline'
import Avatar from 'react-avatar'
import { ConvertDateTime } from '../../../Base/helper/baseFunctions'

// Styled Timeline component
const Timeline = styled(MuiTimeline)({
    paddingLeft: 0,
    paddingRight: 0,
    overflow: "auto",
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "flex-start",
    '& .MuiTimelineItem-root': {
        width: '100%',
        '&:before': {
            display: 'flex'
        },

    }
})


const TimelineCenter = ({ data, candidateName }) => {
    return (

        <Timeline sx={{
            [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
            },
        }}>
            {data?.map((row) => {
                return (
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot sx={{ backgroundColor: "inherit", border: "none" }}>
                                <Avatar name={`${`${row?.name || candidateName}` || ""}`} round="50%" size='30' style={{ padding: "0px", fontSize: "10px" }} />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Box sx={{ mt: 1.5 }} >
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant='body2' sx={{ mr: 2, fontWeight: 600, color: 'text.primary' }}>
                                        {row?.name || candidateName}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant='body2' sx={{ mb: 0, color: 'text.primary' }}>
                                {
                                    row?.validate_type === "add" ? `${candidateName} details has been added from external data on ${ConvertDateTime(row?.modified_on)} by ${row?.modified_by}`
                                        : row?.validate_type === "merge" ? `${candidateName} details has been merged from external data on ${ConvertDateTime(row?.modified_on)} by ${row?.modified_by}`
                                            : row?.form_submitted_date ? `${candidateName} has submitted the form on ${row?.form_submitted_date}`
                                                : `${row?.name} has sent form to ${candidateName} on  ${row?.form_sent_date}`
                                }
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                )
            })}
        </Timeline >
    )
}

export default TimelineCenter
