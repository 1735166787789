import { put, call } from 'redux-saga/effects';
import {
    AddRelationshipHelper,
    DeleteRelationshipHelper,
    GetRelationshipHelper,
    UpdateRelationshipHelper
} from "../../app/Components/Ancillary/Relationship/helper/RelationshipHelper"
import {
    AddBankHelper,
    DeleteBankHelper,
    GetBankHelper,
    UpdateBankHelper
} from "../../app/Components/Ancillary/Bank/helper/BankHelper";
import * as ActionTypes from './ActionTypes';
import {
    GetStudyLevelHelper,
    AddStudyLevelHelper,
    UpdateStudyLevelHelper,
    DeleteStudyLevelHelper
} from "../../app/Components/Ancillary/StudyLevel/helper/StudyLevelHelper"

import {
    GetStudyProgramHelper,
    AddStudyProgramHelper,
    UpdateStudyProgramHelper,
    DeleteStudyProgramHelper
} from "../../app/Components/Ancillary/StudyProgram/helper/StudyProgramHelper"

import {
    GetDesignationHelper,
    AddDesignationHelper,
    UpdateDesignationHelper,
    DeleteDesignationHelper
} from '../../app/Components/Ancillary/Designation/helper/DesignationHelper';
import {
    AddJobHelper,
    DeleteJobHelper,
    GetJobHelper,
    UpdateJobHelper
} from '../../app/Components/Ancillary/Job/helper/JobHelper';
import {
    GetEmploymentTypeHelper,
    AddEmploymentTypeHelper,
    UpdateEmploymentTypeHelper,
    DeleteEmploymentTypeHelper
} from "../../app/Components/Ancillary/EmploymentType/helper/EmploymentTypeHelper";
import {
    AddPaygradeHelper,
    DeletePaygradeHelper,
    GetPaygradeHelper,
    UpdatePaygradeHelper
} from "../../app/Components/Ancillary/Paygrade/helper/PaygradeHelper";
import {
    GetCostCenterHelper,
    AddCostCenterHelper,
    UpdateCostCenterHelper,
    DeleteCostCenterHelper
} from "../../app/Components/Ancillary/CostCenter/helper/CostCenterHelper";
import { GetCityListHelper, GetHolidayCalendarCityHelper } from '../../app/Components/Ancillary/City/helper/CityHelper';
import {
    GetLeavingReasonHelper,
    AddLeavingReasonHelper,
    UpdateLeavingReasonHelper,
    DeleteLeavingReasonHelper
} from "../../app/Components/Ancillary/LeavingReason/helper/LeavingReasonHelper";
import {
    GetBusinessUnitHelper,
    AddBusinessUnitHelper,
    UpdateBusinessUnitHelper,
    DeleteBusinessUnitHelper
} from '../../app/Components/Ancillary/BusinessUnit/helper/BusinessUnitHelper';
import {
    GetSubBusinessUnitHelper,
    AddSubBusinessUnitHelper,
    UpdateSubBusinessUnitHelper,
    DeleteSubBusinessUnitHelper
} from '../../app/Components/Ancillary/SubBusinessUnit/helper/SubBusinessUnitHelper';
import {
    GetCasteHelper,
    AddCasteHelper,
    UpdateCasteHelper,
    DeleteCasteHelper
} from '../../app/Components/Ancillary/Caste/helper/CasteHelper';
import {
    AddWorkLocationHelper,
    DeleteWorkLocationHelper,
    GetWorkLocationHelper,
    UpdateWorkLocationHelper
} from '../../app/Components/Ancillary/WorkLocation/helper/WorkLocation';
import {
    GetProofTypeHelper,
    AddProofTypeHelper,
    UpdateProofTypeHelper,
    DeleteProofTypeHelper
} from '../../app/Components/Ancillary/ProofType/helper/ProofTypeHelper';
import {
    AddNotificationHelper,
    GetNotificationHelper,
    UpdateNotificationHelper
} from '../../app/Components/Ancillary/Notification/helper/NotificationHelper';
import {
    AddDocumentationsHelper,
    DeleteDocumentationsHelper,
    GetDocumentationsHelper,
    UpdateDocumentationsHelper
} from "../../app/Components/Ancillary/Documentations/helper/documentationsHelper";

import { GetUsersHelper } from '../../app/Components/Ancillary/Users/helper/UsersHelper';
import { GetConfigureStatusHelper, UpdateConfigureStatusHelper, GetCountryHelper } from '../../app/Components/Ancillary/ConfigureStatus/helper/ConfigureStatusHelper';
import { GetWeekDaysHelper } from '../../app/Components/Ancillary/WeekDays/helper/GetWeekDaysHelper';



export function* GetUsersSaga(payload) {
    const response = yield call(GetUsersHelper,payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.GET_USERS_SUCCESS, payload: response })
    }
}
export function* GetRelationshipSaga() {
    const response = yield call(GetRelationshipHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.RELATIONSHIP_GET_SUCCESS, payload: response })
    }
}

export function* AddRelationshipSaga(payload) {
    const response = yield call(AddRelationshipHelper, payload.relationship)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.RELATIONSHIP_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.RELATIONSHIP_ADD_ERROR, payload: response })
    }
}

export function* UpdateRelationshipSaga(payload) {
    const response = yield call(UpdateRelationshipHelper, payload.relationship)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.RELATIONSHIP_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.RELATIONSHIP_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteRelationshipSaga(payload) {
    const response = yield call(DeleteRelationshipHelper, payload.relationship)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.RELATIONSHIP_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.RELATIONSHIP_DELETE_ERROR, payload: response })
    }
}

export function* GetBankSaga() {
    const response = yield call(GetBankHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.GET_BANK_SUCCESS, payload: response })
    }
}

export function* AddBankSaga(payload) {
    const response = yield call(AddBankHelper, payload.bank_data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.ADD_BANK_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.ADD_BANK_ERROR, payload: response })
    }
}

export function* UpdateBankSaga(payload) {
    const response = yield call(UpdateBankHelper, payload.bank_data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.UPDATE_BANK_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.UPDATE_BANK_ERROR, payload: response })
    }
}

export function* DeleteBankSaga(payload) {
    const response = yield call(DeleteBankHelper, payload.bank_data)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DELETE_BANK_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.DELETE_BANK_ERROR, payload: response })
    }
}

// Study Level
export function* GetStudyLevelSaga() {
    const response = yield call(GetStudyLevelHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.STUDYLEVEL_GET_SUCCESS, payload: response })
    }
}


export function* AddStudyLevelSaga(payload) {
    const response = yield call(AddStudyLevelHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.STUDYLEVEL_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateStudyLevelSaga(payload) {
    const response = yield call(UpdateStudyLevelHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.STUDYLEVEL_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteStudyLevelSaga(payload) {
    const response = yield call(DeleteStudyLevelHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.STUDYLEVEL_DELETE_SUCCESS, payload: response })
    }
}


// GetStudyProgramHelper,
// AddStudyProgramHelper,
// UpdateStudyProgramHelper,
// DeleteStudyProgramHelper

// Study Program
export function* GetStudyProgramSaga(payload) {
    const response = yield call(GetStudyProgramHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.STUDYPROGRAM_GET_SUCCESS, payload: response })
    }
}


export function* AddStudyProgramSaga(payload) {
    const response = yield call(AddStudyProgramHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.STUDYPROGRAM_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateStudyProgramSaga(payload) {
    const response = yield call(UpdateStudyProgramHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.STUDYPROGRAM_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteStudyProgramSaga(payload) {
    const response = yield call(DeleteStudyProgramHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.STUDYPROGRAM_DELETE_SUCCESS, payload: response })
    }
}


export function* GetDesignationSaga() {
    const response = yield call(GetDesignationHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DESIGNATION_GET_SUCCESS, payload: response })
    }
}

export function* AddDesignationSaga(payload) {
    const response = yield call(AddDesignationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DESIGNATION_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateDesignationSaga(payload) {
    const response = yield call(UpdateDesignationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DESIGNATION_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteDesignationSaga(payload) {
    const response = yield call(DeleteDesignationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DESIGNATION_DELETE_SUCCESS, payload: response })
    }
}

export function* GetJobSaga() {
    const response = yield call(GetJobHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.JOB_GET_SUCCESS, payload: response })
    }
}

export function* AddJobSaga(payload) {
    const response = yield call(AddJobHelper, payload.job)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.JOB_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.JOB_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateJobSaga(payload) {
    const response = yield call(UpdateJobHelper, payload.job)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.JOB_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.JOB_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteJobSaga(payload) {
    const response = yield call(DeleteJobHelper, payload.job)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.JOB_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.JOB_DELETE_SUCCESS, payload: response })
    }
}

// Employment Type
export function* GetEmploymentTypeSaga() {
    const response = yield call(GetEmploymentTypeHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYMENT_TYPE_GET_SUCCESS, payload: response })
    }
}

export function* AddEmploymentTypeSaga(payload) {
    const response = yield call(AddEmploymentTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYMENT_TYPE_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateEmploymentTypeSaga(payload) {
    const response = yield call(UpdateEmploymentTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYMENT_TYPE_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteEmploymentTypeSaga(payload) {
    const response = yield call(DeleteEmploymentTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYMENT_TYPE_DELETE_SUCCESS, payload: response })
    }
}


export function* GetPaygradeSaga() {
    const response = yield call(GetPaygradeHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYGRADE_GET_SUCCESS, payload: response })
    }
}

export function* AddPaygradeSaga(payload) {
    const response = yield call(AddPaygradeHelper, payload.paygrade)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYGRADE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYGRADE_ADD_ERROR, payload: response })
    }
}

export function* UpdatePaygradeSaga(payload) {
    const response = yield call(UpdatePaygradeHelper, payload.paygrade)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYGRADE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYGRADE_UPDATE_ERROR, payload: response })
    }
}

export function* DeletePaygradeSaga(payload) {
    const response = yield call(DeletePaygradeHelper, payload.paygrade)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYGRADE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYGRADE_DELETE_ERROR, payload: response })
    }
}

// Cost Center
export function* GetCostCenterSaga() {
    const response = yield call(GetCostCenterHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.COST_CENTER_GET_SUCCESS, payload: response })
    }
}

export function* AddCostCenterSaga(payload) {
    const response = yield call(AddCostCenterHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.COST_CENTER_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateCostCenterSaga(payload) {
    const response = yield call(UpdateCostCenterHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.COST_CENTER_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteCostCenterSaga(payload) {
    const response = yield call(DeleteCostCenterHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.COST_CENTER_DELETE_SUCCESS, payload: response })
    }
}

export function* GetCityListSaga() {
    const response = yield call(GetCityListHelper);
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CITY_LIST_GET_SUCCESS, payload: response })
    }
}

export function* GetHolidayCalendarCitySaga() {
    const response = yield call(GetHolidayCalendarCityHelper);
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.HOLIDAY_CALENDAR_CITY_GET_SUCCESS, payload: response })
    }
}


export function* GetLeavingReasonSaga() {
    const response = yield call(GetLeavingReasonHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVING_REASON_GET_SUCCESS, payload: response })
    }
}

export function* AddLeavingReasonSaga(payload) {
    const response = yield call(AddLeavingReasonHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVING_REASON_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateLeavingReasonSaga(payload) {
    const response = yield call(UpdateLeavingReasonHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVING_REASON_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteLeavingReasonSaga(payload) {
    const response = yield call(DeleteLeavingReasonHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LEAVING_REASON_DELETE_SUCCESS, payload: response })
    }
}

// Business Unit
export function* GetBusinessUnitSaga() {
    const response = yield call(GetBusinessUnitHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.BUSINESSUNIT_GET_SUCCESS, payload: response })
    }
}

export function* AddBusinessUnitSaga(payload) {
    const response = yield call(AddBusinessUnitHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.BUSINESSUNIT_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateBusinessUnitSaga(payload) {
    const response = yield call(UpdateBusinessUnitHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.BUSINESSUNIT_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteBusinessUnitSaga(payload) {
    const response = yield call(DeleteBusinessUnitHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.BUSINESSUNIT_DELETE_SUCCESS, payload: response })
    }
}

// Sub Business Unit
export function* GetSubBusinessUnitSaga() {
    const response = yield call(GetSubBusinessUnitHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SUB_BUSINESSUNIT_GET_SUCCESS, payload: response })
    }
}

export function* AddSubBusinessUnitSaga(payload) {
    const response = yield call(AddSubBusinessUnitHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SUB_BUSINESSUNIT_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateSubBusinessUnitSaga(payload) {
    const response = yield call(UpdateSubBusinessUnitHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SUB_BUSINESSUNIT_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteSubBusinessUnitSaga(payload) {
    const response = yield call(DeleteSubBusinessUnitHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SUB_BUSINESSUNIT_DELETE_SUCCESS, payload: response })
    }
}

// Caste
export function* GetCasteSaga() {
    const response = yield call(GetCasteHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CASTE_GET_SUCCESS, payload: response })
    }
}

export function* AddCasteSaga(payload) {
    const response = yield call(AddCasteHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CASTE_ADD_SUCCESS, payload: response })
    }
}

export function* UpdateCasteSaga(payload) {
    const response = yield call(UpdateCasteHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CASTE_UPDATE_SUCCESS, payload: response })
    }
}

export function* DeleteCasteSaga(payload) {
    const response = yield call(DeleteCasteHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CASTE_DELETE_SUCCESS, payload: response })
    }
}

// Work Location
export function* GetWorkLocationSaga() {
    const response = yield call(GetWorkLocationHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WORK_LOCATION_GET_SUCCESS, payload: response })
    }
}

export function* AddWorkLocationSaga(payload) {
    const response = yield call(AddWorkLocationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WORK_LOCATION_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WORK_LOCATION_ADD_ERROR, payload: response })
    }
}

export function* UpdateWorkLocationSaga(payload) {
    const response = yield call(UpdateWorkLocationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WORK_LOCATION_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WORK_LOCATION_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteWorkLocationSaga(payload) {
    const response = yield call(DeleteWorkLocationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WORK_LOCATION_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WORK_LOCATION_DELETE_ERROR, payload: response })
    }
}

// Proof Type
export function* GetProofTypeSaga(payload) {
    const response = yield call(GetProofTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PROOF_TYPE_GET_SUCCESS, payload: response })
    }
}

export function* AddProofTypeSaga(payload) {
    const response = yield call(AddProofTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PROOF_TYPE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PROOF_TYPE_ADD_ERROR, payload: response })
    }
}

export function* UpdateProofTypeSaga(payload) {
    const response = yield call(UpdateProofTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PROOF_TYPE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PROOF_TYPE_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteProofTypeSaga(payload) {
    const response = yield call(DeleteProofTypeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PROOF_TYPE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PROOF_TYPE_DELETE_ERROR, payload: response })
    }
}

// Notification
export function* GetNotificationSaga(payload) {
    const response = yield call(GetNotificationHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.NOTIFICATION_GET_SUCCESS, payload: response })
    }
}
export function* AddNotificationSaga(payload) {
    const response = yield call(AddNotificationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.NOTIFICATION_ADD_SUCCESS, payload: response })
    }
}
export function* UpdateNotificationSaga(payload) {
    const response = yield call(UpdateNotificationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.NOTIFICATION_UPDATE_SUCCESS, payload: response })
    }
}

//Documentations

export function* GetDocumentationsSaga() {
    const response = yield call(GetDocumentationsHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DOCUMENTATIONS_GET_SUCCESS, payload: response })
    }
}
export function* AddDocumentationsSaga(payload) {
    const response = yield call(AddDocumentationsHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DOCUMENTATIONS_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.DOCUMENTATIONS_ADD_ERROR, payload: response })
    }
}
export function* UpdateDocumentationsSaga(payload) {
    const response = yield call(UpdateDocumentationsHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DOCUMENTATIONS_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.DOCUMENTATIONS_UPDATE_ERROR, payload: response })
    }
}
export function* DeleteDocumentationsSaga(payload) {
    const response = yield call(DeleteDocumentationsHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.DOCUMENTATIONS_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.DOCUMENTATIONS_DELETE_ERROR, payload: response })
    }
}


export function* GetConfigureStatusSaga(payload) {
    const response = yield call(GetConfigureStatusHelper,payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CONFIGURE_STATUS_GET_SUCCESS, payload: response })
    }
}


export function* UpdateConfigureStatusSaga(payload) {
    const response = yield call(UpdateConfigureStatusHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CONFIGURE_STATUS_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.CONFIGURE_STATUS_UPDATE_ERROR, payload: response })
    }
}

export function* GetCountrySaga() {
    const response = yield call(GetCountryHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.COUNTRY_GET_SUCCESS, payload: response })
    }
}

export function* GetWeekDaysSaga() {
    const response = yield call(GetWeekDaysHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WEEK_DAYS_GET_SUCCESS, payload: response })
    }
}