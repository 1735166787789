import React from 'react';
import { BASE_URL } from '../../../../Constants/baseConstant';

const AddCandidateEmail = ({
    url = "/ats/add-candidate/",
    userName,
    guid,
    message = "Dear User please fill the form",
    button = "Fill form",
    data,
}) => {

    return (
        <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
            style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
            <tr style={{ textAlign: "left" }}>
                <span style={{ color: "#455056", fontSize: "18px", fontWeight: 'bold', lineHeight: "24px", margin: 0 }}>
                    Dear {userName}
                </span>
            </tr>
            <tr>
                <td style={{ height: "40px" }}>&nbsp;</td>
            </tr>
            <tr>
                <td style={{ padding: "0 35px" }}>
                    <span
                        style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                    <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                        {message}
                    </span>
                    <br />
                    <br />
                    {data && <span style={{ fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                        {data}
                    </span>}
                    {data && <br />}
                    <br />
                    <a href={`${BASE_URL}${url}${guid}`} style={{
                        background: "#20e277",
                        textDecoration: "none !important", fontWeight: 500, marginTop: "35px",
                        color: "#fff", textTransform: "uppercase", fontSize: "14px",
                        padding: "10px 24px", display: "inlineBlock", borderRadius: "50px"
                    }}>
                        {button}
                    </a>
                </td>
            </tr>
            <tr>
                <td style={{ height: "20px" }}>&nbsp;</td>
            </tr>
        </table>
    )
}

export default AddCandidateEmail;