import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import {
  convertBase64,
  getUserData,
} from "../../../../Base/helper/baseFunctions";
import { GetOfferLetterTemplateHelper } from "../../../helper/AtsFormTemplateHelper";
import {
  AddOfferLetterTemplateResponseHelper,
  GetOfferLetterTemplateResponseHelper,
  OfferEmailSentHelper,
  UpdateOfferLetterTemplateResponseHelper,
} from "../../../helper/AtsFormTemplateResponseHelper";
import { OfferComponentsRender } from "../OfferComponentsRender";
const PromptInput = ({
  currentData,
  currentJobData,
  activeStep,
  setActiveStep,
  setResData,
  setIsOfferForm,
  OfferEdit,
}) => {
  const [viewMode, setViewMode] = useState("Loading");
  const [formFields, setFormFields] = useState([]);
  const [formComponents, setFormComponents] = useState([]);
  const [formHeading, setFormHeading] = useState({ heading: "" });
  const [description, setDescription] = useState("");
  const [formData, setFormData] = useState([]);
  const [previewErrors, setpreviewErrors] = useState({});
  const [checkData, setCheckData] = useState({ values: [] });
  const userData = getUserData();

  useEffect(() => {
    if (currentData?.offer_letter_response_id && OfferEdit === "true") {
      GetOfferLetterTemplateResponseHelper({
        offer_letter_response_id: currentData?.offer_letter_response_id,
      }).then((res) => {
        setFormFields(res?.data?.data[0]?.offer_letter_template_fields);
        setViewMode("Feedback");
        setDescription(
          res?.data?.data[0]?.offer_letter_edited ||
            res?.data?.data[0]?.offer_letter_response
        );
        const initialFormData = {};
        if (res?.data?.data?.[0]?.offer_letter_fields_response) {
          Object.keys(
            res?.data?.data?.[0].offer_letter_fields_response
          ).forEach((key) => {
            initialFormData[key.replace("@{", "").replace("}", "")] =
              res?.data?.data?.[0].offer_letter_fields_response[key];
          });
        }
        setFormData(initialFormData);
      });
    } else {
      GetOfferLetterTemplateHelper({
        workspace: userData?.domain,
        offer_letter_template_id: currentJobData?.offer_letter_template_id,
      }).then((res) => {
        setFormFields(res?.data?.data[0]?.offer_letter_template_fields);
        setFormHeading({
          heading: res?.data?.data[0]?.offer_letter_template_name,
        });
        setViewMode("Feedback");
      });
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formFields?.length > 0) {
      setFormComponents([]);
      OfferComponentsRender({
        formFields,
        checkData,
        setCheckData,
        formData,
        setFormData,
        previewErrors,
        setFormComponents,
        Previewvalidate,
      });
    }
    //eslint-disable-next-line
  }, [formFields]);

  useEffect(() => {
    OfferComponentsRender({
      formFields,
      checkData,
      setCheckData,
      formData,
      setFormData,
      previewErrors,
      setFormComponents,
      Previewvalidate,
    });
    //eslint-disable-next-line
  }, [previewErrors]);

  const Previewvalidate = (fieldDefinitions, fieldValues) => {
    let temp = { ...previewErrors };
    fieldDefinitions?.forEach((field, index) => {
      const isRequired = field.required;
      if (isRequired && !fieldValues[field.name]) {
        temp[field.name] = `${field.label} is required`;
      } else {
        temp[field.name] = "";
      }
    });
    setpreviewErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleResponseSubmit = async () => {
    if (Previewvalidate(formFields, formData)) {
      let b64string = "";
      let filename = "";
      for (const field of formFields) {
        const fieldName = field.name;
        const fieldType = field.type;
        if (fieldType === "file" && formData[fieldName]) {
          const fileField = formData[fieldName];
          if (fileField instanceof File) {
            b64string = await convertBase64(fileField);
            const ext = fileField.name.split(".").pop();
            filename = `${
              userData?.domain
            }/ATS/OfferLetter/Documents/${uuidv4()}.${ext}`;
            formData[fieldName] = filename;
            break;
          }
        }
      }
      const transformedData = {};
      for (let key in formData) {
        transformedData[`@{${key}}`] = formData[key];
      }
      if (currentData?.offer_letter_response_id && OfferEdit === "true") {
        UpdateOfferLetterTemplateResponseHelper({
          workspace: userData?.domain,
          user_id: userData?.employee_id,
          offer_letter_response_id: currentData?.offer_letter_response_id,
          offer_letter_fields_response: JSON.stringify(transformedData),
          offer_letter_response: description,
          file: null,
          file_name: currentData?.offer_letter_cloud_path || null,
        }).then((res) => {
          if (res?.data?.statuscode === 200) {
            setResData(res?.data.data?.[0]);
            setActiveStep(activeStep + 1);
            OfferEmailSentHelper({
              update_tblcolumn: "approver_response",
              offer_letter_response_id: currentData?.offer_letter_response_id,
              update_tblcolumn_value: "false",
            });
          }
        });
      } else {
        AddOfferLetterTemplateResponseHelper({
          workspace: userData?.domain,
          user_id: userData?.employee_id,
          offer_letter_template_id: currentJobData?.offer_letter_template_id,
          offer_letter_fields_response: JSON.stringify(transformedData),
          candidate_job_id: currentData?.candidate_job_id,
          file: b64string,
          file_name: filename,
        }).then((res) => {
          if (res?.data?.statuscode === 200) {
            setResData(res?.data.data?.[0]);
            setActiveStep(activeStep + 1);
          }
        });
      }
    }
  };

  return (
    <>
      {viewMode === "Loading" ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardContent>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", pb: 2 }}
                  >
                    <Skeleton animation="wave" width={200} />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Skeleton
                        animation="wave"
                        variant="rectangualar"
                        height={350}
                        width={400}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Skeleton animation="wave" height={50} width={100} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : (
        viewMode === "Feedback" && (
          <>
            <Box className="response-main">
              {formComponents?.length > 0 && (
                <>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography
                          variant="h4"
                          gutterBottom
                          fontSize="2.25rem"
                        >
                          {formHeading?.heading}
                        </Typography>
                      </Box>
                      <Grid container className="form_content">
                        {formComponents.map((component, index) => (
                          <Grid
                            item
                            key={index}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "1.5rem 1.25rem" }}
                          >
                            {component}
                          </Grid>
                        ))}
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: OfferEdit === "true"? "space-between":"flex-end",
                          mt: 1,
                        }}
                      >       
                      {OfferEdit === "true" && (
                        <Button
                          sx={{ fontSize: "15px" }}
                          variant="contained"
                          className="mpl-primary-btn"
                          onClick={() => setIsOfferForm(false)}
                        >
                          Cancel
                        </Button>
                          )}
                        <Button
                          sx={{ fontSize: "15px" }}
                          variant="contained"
                          className="mpl-primary-btn"
                          onClick={handleResponseSubmit}
                        >
                          Next
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </>
        )
      )}
    </>
  );
};
export default PromptInput;
