import { toast } from "react-toastify";
import NProgress from 'nprogress';
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetAtsContactHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getContacts`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            type: params?.type,
            company_id: params?.company_id || null,
            consultancy_id: params?.consultancy_id || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAtsContactHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/addContact`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_emp_id: +user?.ats_user_id,
            type: params?.type,
            company_id: params?.company_id || null,
            consultancy_id: params?.consultancy_id || null,
            contact_data: params?.contact_data
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsContactHelper = async (params) => {
    var user = getUserData();
    return await axiosInstance(`/ats/updateContact`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_emp_id: +user?.ats_user_id,
            type: params?.type,
            company_id: params?.company_id || null,
            consultancy_id: params?.consultancy_id || null,
            contact_data: params?.contact_data
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}