import * as ActionTypes from '../ActionTypes';

const MyTimeSheetInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: "",
    GetSuccess:"",
    getSuccess:"",
}

export const MyTimeSheetReducer = function (state = MyTimeSheetInitialState, action) {
    switch (action.type) {
        case ActionTypes.MY_TIME_SHEET_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                GetSuccess:"",
                getSuccess:"",
                mytime_sheet_options: []
            };
        case ActionTypes.MY_TIME_SHEET_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                mytime_sheet_options: action.payload?.data?.data,
                GetSuccess:action.payload?.data?.statuscode,
                getSuccess:""
            };
        case ActionTypes.MY_TIME_SHEET_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

            case ActionTypes.MY_TIME_SHEET_LEAVE_INFO_GET_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                    success: false,
                    successMessage: "",
                    error: false,
                    errorMessage: "",
                    GetSuccess:"",
                    mytime_sheet_leave: []
                };
            case ActionTypes.MY_TIME_SHEET_LEAVE_INFO_GET_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    successMessage: action.payload?.message,
                    mytime_sheet_leave: action.payload?.data?.data,
                    GetSuccess:action.payload?.data?.statuscode
                };
            case ActionTypes.MY_TIME_SHEET_LEAVE_INFO_GET_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    error: true,
                    success: false,
                    errorMessage: action.payload?.data?.error?.[0]?.message
                }

        case ActionTypes.MY_TIME_SHEET_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                getSuccess:""
            };
        case ActionTypes.MY_TIME_SHEET_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                mytime_sheet_options: action.payload?.data?.data,
                getSuccess:action.payload?.data?.statuscode
            };
        case ActionTypes.MY_TIME_SHEET_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.MY_TIME_SHEET_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                getSuccess:""
            };
        case ActionTypes.MY_TIME_SHEET_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                mytime_sheet_options: action.payload?.data?.data,
                getSuccess:action.payload?.data?.statuscode
            };
        case ActionTypes.MY_TIME_SHEET_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.MY_TIME_SHEET_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.MY_TIME_SHEET_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                mytime_sheet_options: action.payload?.data?.data
            };
        case ActionTypes.MY_TIME_SHEET_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
            case ActionTypes.GET_FACE_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                GetSuccess:"",
                getSuccess:"",
                face_data: []
            };
        case ActionTypes.GET_FACE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                face_data: action.payload?.data?.data,
                GetSuccess:action.payload?.data?.statuscode,
                getSuccess:""
            };
        case ActionTypes.GET_FACE_DATA_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}