import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    relationship_options: []
};

const RelationshipReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.RELATIONSHIP_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                relationship_options: []
            };
        case ActionTypes.RELATIONSHIP_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: action.payload.has_error,
                relationship_options: action.payload?.data?.data
            };
        case ActionTypes.RELATIONSHIP_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.RELATIONSHIP_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: action.payload.has_error,
                relationship_options: action.payload?.data?.data
            };
        case ActionTypes.RELATIONSHIP_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: action.payload.has_error,
            };
        case ActionTypes.RELATIONSHIP_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.RELATIONSHIP_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: action.payload.has_error,
                relationship_options: action.payload?.data?.data
            };
        case ActionTypes.RELATIONSHIP_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: action.payload.has_error,
            };
        case ActionTypes.RELATIONSHIP_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.RELATIONSHIP_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: action.payload.has_error,
                relationship_options: action.payload?.data?.data
            };
        case ActionTypes.RELATIONSHIP_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: action.payload.has_error,
            };
        default:
            return state;
    }
}

export default RelationshipReducer;