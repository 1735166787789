import React, { useEffect } from 'react';
import Popup from "../../../../Base/views/Popup";
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import { GetTeamBalanceAction } from '../../../../../../store/Leave/MyTeam/Action';

const TeamBalance = ({
    openPopup,
    setOpenPopup
}) => {
    const dispatch = useDispatch();
    const { TeamBalanceData } = useSelector(state => state?.LeaveReducer.TimeSheetApprovalReducer);

    useEffect(() => {
        dispatch(GetTeamBalanceAction());
        //eslint-disable-next-line
    }, []);
    const leaveTypes = new Set();
    TeamBalanceData?.forEach(employee => {
        employee?.leave_balances?.forEach(leave => {
            leaveTypes.add(leave?.name);
        });
    });

    const columns = [
        { title: "Name", field: "employee_name", cellStyle: { padding: "10px" } },
        ...Array.from(leaveTypes).map(leaveType => ({
            title: leaveType,
            field: leaveType,
            cellStyle: { padding: "10px", textAlign:"center" },
            headerStyle:{ textAlign:"center"}
        })),
    ];

    const transformedData = TeamBalanceData?.map(employee => {
        const leaveBalanceData = {};
        employee?.leave_balances?.forEach(leave => {
            leaveBalanceData[leave?.name] = leave?.leavebalance;
        });
        return {
            employee_name: employee.employee_name,
            ...leaveBalanceData
        };
    });

    return (
        <>
            <Popup
                title="Team Leave Balance"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                fullWidth={true}
                maxWidth="md"
            >
                <Box margin="0px">
                    <MaterialTable
                        columns={columns}
                        data={transformedData}
                        options={{
                            maxBodyHeight: 265,
                            rowStyle: { fontSize: "13px", border: "1px" },
                            headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                            sorting: false,
                            search: true,
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            paging: true,
                            pageSizeOptions: [10, 50],
                            pageSize: 10,
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            paginationPosition: "bottom",
                            exportFileName:"Team Leave Balance",
                            exportAllData:true,
                            exportButton:true
                        }}
                        title=""
                        components={{
                            Toolbar: (props) => (
                                <Box sx={{ minHeight: "5px" }}>
                                    <MTableToolbar {...props} />
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </Popup>
        </>
    );
};

export default TeamBalance;
