import React from 'react';
import Routes from "./Routes";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "./history";
import rootStore from "./store/rootStore";
import ReactDOM from "react-dom/client";
import './css/appCss.css';
import { GenerateHrmsBearerToken } from './app/Components/Base/helper/authHelper';
import { CookiesProvider } from 'react-cookie';
import "nprogress/nprogress.css";
const store = rootStore();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <CookiesProvider>
      <Router history={history}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </Router>
    </CookiesProvider>
  </>
);
GenerateHrmsBearerToken();

