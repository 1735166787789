import { Box } from "@mui/material";
import * as React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import FormBuilder from "./FormBuilder/FormBuilder";
import PreScreen from "./PreScreen/PreScreen";
export default function FormBuilderLandingPage() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box>
            <TabList
              sx={{
                minHeight: "40px",
                ".MuiTab-root": {
                  minHeight: "20px",
                  padding: "12px 16px 12px 16px",
                },
                "& .MuiTabs-indicator": {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center !important",
                },
              }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{ fontWeight: "600" }}
                label="FeedBack"
                value="1"
              />
              <Tab
                sx={{ fontWeight: "600" }}
                label="Pre Screen Questions"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px", mt:2 }}>
            <FormBuilder/>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0px", mt:2 }}>
            <PreScreen />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}