import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    isLoading: false,
    success: false,
    role_access_data: []
};

const RoleAccessReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ROLE_ACCESS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                role_access_data: []
            };
        case ActionTypes.ROLE_ACCESS_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success_message: action.payload.message,
                error: action.payload.hasError,
                role_access_data: action.payload?.data?.data
            };
        case ActionTypes.ROLE_ACCESS_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ROLE_ACCESS_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: true,
                role_access_data: action.payload?.data?.data
            };
        case ActionTypes.ROLE_ACCESS_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.data
            };
        case ActionTypes.ROLE_ACCESS_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ROLE_ACCESS_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: true,
                role_access_data: action.payload?.data?.data
            };
        case ActionTypes.ROLE_ACCESS_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.data
            };
        case ActionTypes.ROLE_ACCESS_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ROLE_ACCESS_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: true,
                role_access_data: action.payload?.data?.data
            };
        case ActionTypes.ROLE_ACCESS_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.data
            };
        default:
            return state;
    }
}

export default RoleAccessReducer;