import {
  Box,
  Button,
  FormControl,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import {
  AddDesignationAction,
  DeleteDesignationAction,
  GetDesignationAction,
} from "../../../../store/Ancillary/Designation/Action";
import Des_img from "../../../../Image/developer.png";
import { useDispatch, useSelector } from "react-redux";
import { GetCountryAction } from "../../../../store/Ancillary/ConfigureStatus/Action";
import ValidationPopup from "./ValidationPopup";
import ErrorIcon from '@mui/icons-material/Error';
import Popup from "../../Base/views/Popup";
const ConfigureDesignation = ({ setActiveStep, setStepper }) => {
  const dispatch = useDispatch();
  const initialFValues = {
    designation: "",
  };
  const { designation_options, isSuccess } = useSelector(
    (state) => state?.AncillaryReducer?.DesignationReducer
  );
  const [configureData, setConfigureData] = useState(initialFValues);
  const [show, setShow] = useState(false);
  const [showContent, setShowContent] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    dispatch(GetDesignationAction());
    dispatch(GetCountryAction());
  }, []);

  const handleInputChange = (event) => {
    setConfigureData({
      ...configureData,
      [event.target.name]: event.target.value,
    });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...configureData, [event.target.name]: event.target.value });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("designation" in fieldValues) {
      temp.designation =
        fieldValues.designation?.trim() === "" ? "Designation is required" : "";
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleDesignation();
    }
  };
  async function handleDesignation() {
    if (validate(configureData)) {
      dispatch(AddDesignationAction(configureData));
      if(isSuccess){
        setConfigureData(initialFValues);
      }
    }
  }

  const handleCancel = () => {
    setStepper(2);
  };
  const handleNext = () => {
    if(designation_options?.length === 0){
      setShow(true)
      setShowContent("You Have to Create Atleast One Designation")
    }
    else{
      setActiveStep(1)
      setConfigureData(initialFValues);
      setErrors([]);
    }
    
  };
  
  const handleDelete = (id) => {
    dispatch(DeleteDesignationAction({ designation_id: `{${id}}` }))
  };
  const Alert = () => {
    const containerStyle = {
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "5px 5px",
      color: "#000",
      position: "relative",
      marginBottom:"5px",
      width:"40%"
    };
  
    const infoIconStyle = {
      color: "#007bff",
      marginRight: "5px",
      fontSize: "14px",
    };
  
    const textStyle = {
      margin: 0,
      flex: 1,
      color:"#1b1c21",
      fontSize:"12px",
      fontStyle:"italic"
    };
  
    return (
      <div style={containerStyle}>
        <span style={infoIconStyle}><ErrorIcon fontSize="extra-small" /></span>
        <p style={textStyle}>
        Atleast 1 Designation is required to proceed 
        </p>
      </div>
    );
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={Des_img}
            alt="Designation"
            width={"100px"}
            height={"100px"}
          />
          <Typography sx={{ pt: 1, pb:1 }} variant="h3">
            What are the types of roles at your organization?
          </Typography>
        </Box>
        
        {designation_options?.length === 0 && (
         <Alert />
          )}
        <FormControl fullWidth>
          <TextField
            value={configureData?.designation}
            required
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            label="Designation"
            onKeyDown={handleKeyDown}
            name="designation"
            {...(errors.designation && {
              error: true,
              helperText: errors.designation,
            })}
          />
        </FormControl>
        <Box
          sx={{
            pt: 2,
            maxHeight: "25vh",
            overflowY: "auto",
          }}
        >
          {designation_options &&
            designation_options.length > 0 &&
            designation_options.map((name, index) => (
              <Chip
                onDelete={() => handleDelete(name?.designation_id)}
                key={index}
                label={`${index + 1}. ${name?.name}`}
                variant="filled"
                style={{ margin: "0.5rem" }}
              />
            ))}
        </Box>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={() => handleCancel()}
            variant="contained"
            color="primary"
            type="submit"
            className="mpl-primary-btn"
            sx={{ marginTop: "1.75rem", marginRight: "1rem", fontSize:"20px", padding:"0px 40px 0px 40px" }}
          >
            Skip
          </Button>
          <div>
            <Button
              onClick={() => handleDesignation()}
              variant="contained"
              color="primary"
              type="submit"
              className="mpl-primary-btn"
              sx={{ marginTop: "1.75rem", marginRight: "1rem", fontSize:"20px", padding:"0px 40px 0px 40px" }}
            >
              Save
            </Button>
            <Button
             onClick={() => handleNext()}
              variant="contained"
              color="primary"
              type="submit"
              className="mpl-primary-btn"
              sx={{ marginTop: "1.75rem", fontSize:"20px", padding:"0px 40px 0px 40px" }}
            >
              Next
            </Button>
          </div>
        </div>
      </Box>
      <Popup maxWidth="xs" openPopup={show} setOpenPopup={setShow}>
        <ValidationPopup openPopup={show} setShow={setShow}  showContent={showContent}/>
      </Popup>
    </>
  );
};

export default ConfigureDesignation;
