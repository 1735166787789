import { useTheme } from "@mui/material/styles";
import { Paper, Box } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RoleAccess from './RoleAccess'
import RoleAccessForm from './RoleAccessForm'
import "../../css/style.css";
import { useEffect } from 'react'
import { GetRoleAccessAction } from '../../../../../store/RoleAccess/RoleAccess/Action'

const RoleAndAccessLandingPage = () => {
    const [mode, setMode] = useState("list")
    const [editedRecord, setEditedRecord] = useState(null);
    const { role_access_data } = useSelector(state => state?.RoleAndAccessReducer?.RoleAccessReducer);
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetRoleAccessAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Paper sx={{ ".MuiDrawer-paper": { backgroundColor: `${theme.palette.mode === "dark" ? "#544166" : "white"}` }, borderRadius: "10px", paddingTop: "5px", minHeight: "87vh" }}>
            <Box className="role-access" sx={{ width: '100%' }}>
                {mode === "list"
                    ?
                    <RoleAccess role_access_data={role_access_data} setEditedRecord={setEditedRecord} setMode={setMode} />
                    :
                    <RoleAccessForm role_access_data={role_access_data} editedRecord={editedRecord} mode={mode} setMode={setMode} />}
            </Box>
        </Paper>

    )
}

export default RoleAndAccessLandingPage