import { Box, Button, Typography } from "@mui/material";
import Popup from "./Popup";
import { useDispatch, useSelector } from "react-redux";
import { ProposeCandidateEmailHelper } from "../../ATS/helper/AtsJobKanbanHelper";
import { ProposeCandidateConfirmationAction } from "../../../../store/ATS/Candidate/Action";

const ProposeCandidateConfirmation = () => {
    const dispatch = useDispatch();
    const { sendCandidateEmail, candidate_data, job_data, kanban_data } = useSelector(state => state?.AtsReducer?.AtsCandidateReducer)
    const handlePopup = () => {
        dispatch(ProposeCandidateConfirmationAction())
    }
    const handlePropose = () => {
        ProposeCandidateEmailHelper({ candidateData: candidate_data, jobData: job_data, kanban_data: kanban_data })
        dispatch(ProposeCandidateConfirmationAction())
    }

    return (
        <>
            <Popup
                fullWidth={true}
                maxWidth="sm"
                title="Confirmation"
                openPopup={sendCandidateEmail}
                setOpenPopup={handlePopup}
            >
                <Typography variant="h6">Do you want to send confirmation mail to candidate ?</Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
                    <Button
                        className="mpl-secondary-btn"
                        variant="outlined"
                        onClick={handlePopup}
                    >
                        Skip
                    </Button>
                    <Button
                        className="mpl-primary-btn"
                        variant="contained"
                        onClick={handlePropose}
                        sx={{ ml: 2 }}
                    >
                        Send Mail
                    </Button>
                </Box>
            </Popup>
        </>
    )
}

export default ProposeCandidateConfirmation;