import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import history from '../../../../../history';
import { Grid } from '@mui/material';
import EmailSvg from '../../../../../svg/EmailSvg';


export default function CanditateFormSuccess() {

    return (
        <>
            <CssBaseline />
            <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent="center" marginTop="48px">

                    <Grid item>
                        <Box
                            sx={{

                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                maxWidth: "400px"
                            }}
                        >
                            <div style={{ marginBottom: "40px", height: "96px" }}>
                                <EmailSvg />
                            </div>
                            <Typography component="h1" variant="h3" fontWeight={700} lineHeight={1.5}>
                                Form Submitted Successfully                            </Typography>
                            <Typography textAlign={"center"} variant="body1" color="text.secondary">
                                Your form is Submitted, please contact your recruiter for further procedures .
                            </Typography>
                            <Button sx={{ mt: 3 }} onClick={() => history.push("/")}>Back to login</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
