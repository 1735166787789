import { Paper } from '@mui/material'
import { useState, useEffect } from 'react'
import Base from '../../../Base/views/Base'
import AtsCompany from './AtsCompany'
import AtsCompanyForm from './AtsCompanyForm'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GetAtsCompanyAction } from '../../../../../store/ATS/Company/Action'
import { useDispatch } from 'react-redux'
import { GetATSModuleFeaturesAction } from '../../../../../store/RoleAccess/ATSModuleFeatures/Action'

const AtsCompanyLandingPage = () => {
    const initialValues = {
        name: "",
        address: "",
        contact_person: "",
        mobile_number: "",
        office_number: "",
        country_id: "",
        state_id: "",
        city_id: "",
        zip_code: "",
        industry: "",
        about_company: "",
        website: "",
        primary_owner: "",
        company_lead_id: "",
        business_unit_id: "",
        email_id: "",
        billing_address: "",
        billing_name: "",
        gst_applicable: "",
        gst: "",
        sgst: "9",
        cgst: "",
        igst: "9",
        sac_code: "",
    }
    const dispatch = useDispatch();
    const location = useLocation();
    const defaultValue = location.state?.defaultValue || "";
    const { atsCompanyData } = useSelector(state => state.AtsReducer?.AtsCompanyReducer);
    const [currentMode, setCurrentMode] = useState(defaultValue? defaultValue : "grid")
    const [currentData, setCurrentData] = useState(initialValues)
    const [companyData, setCompanyData] = useState(atsCompanyData)
    useEffect(() => {
        dispatch(GetAtsCompanyAction());
        setCompanyData(atsCompanyData);
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Manage" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <Base>
            <Paper sx={{ borderRadius: "10px", maxHeight: "87vh", minHeight: "87vh", overflowY: "auto" }}>
                {
                    currentMode === "grid" ? <AtsCompany companyData={companyData} setCurrentMode={setCurrentMode} setCurrentData={setCurrentData} currentData={currentData} />
                        : <AtsCompanyForm setAtsCompanyData={setCompanyData} currentData={currentData} setCurrentMode={setCurrentMode} />
                }
            </Paper>
        </Base>
    )
}

export default AtsCompanyLandingPage