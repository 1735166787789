import axios from "axios";
import {
  getSessionData,
  removeSessionData,
} from "./app/Components/Base/helper/baseHelper";
import { GenerateHrmsBearerToken } from "./app/Components/Base/helper/authHelper";
import { HRMS_UTILITY_API_URL } from "./app/Constants/baseConstant";

const axiosInstance = axios.create({
  baseURL: HRMS_UTILITY_API_URL,
  headers: { 'content-type': 'application/json' }
});
axiosInstance.interceptors.request.use(
  (config) => {
    const hrmsbearertoken = getSessionData({ key: "hrmsbearertoken" });
    config.headers.Authorization = hrmsbearertoken
      ? `Bearer ${hrmsbearertoken}`
      : "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => Promise.resolve(response),
  async (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 403) {
      removeSessionData({ key: "hrmsbearertoken" });
    } else if (error.response.status === 401) {
      // var result = window.confirm(
      //   "Session Expired, Do you want to create a new session ? "
      // );
      // if (result) {
      await GenerateHrmsBearerToken();

      return new Promise(function (resolve, reject) {
        const newConfig = error.config;
        const hrmsbearertoken = getSessionData({ key: "hrmsbearertoken" });
        newConfig.headers.Authorization = hrmsbearertoken
          ? `Bearer ${hrmsbearertoken}`
          : "";

        axios(newConfig)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
      // }
      // else {return Promise.reject(error);}
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
