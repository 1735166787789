import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetEmploymentTypeHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getEmploymentTypes`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })

}

export const AddEmploymentTypeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addEmploymentType`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data.employee_id,
            etype_name: params?.etype_name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Employment Type Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateEmploymentTypeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateEmploymentType`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data.employee_id,
            employmenttype_id: params?.employmenttype_id,
            etype_name: params?.etype_name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Employment Type Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteEmploymentTypeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteEmploymentType`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            employmenttype_id: params?.employmenttype_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Employment Type Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}