import { Paper } from '@mui/material';
import React from 'react';
import Base from '../../Base/views/Base';
import { TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const AdminLandingPage = () => {
    const data = [
        { name: "Basic Pay", code: "Basic" },
        { name: "House Rent Allowance", code: "HRA" },
        { name: "Travel Allowance", code: "TA" },
        { name: "Gross", code: "gross" },
        { name: "Net Salary", code: "Net" },
    ]

    return (
        <Base>
            <Paper sx={{ borderRadius: "10px", minHeight: "87vh", maxHeight: "87vh", overflowY: "auto" }}>
                <MaterialTable
                    columns={[
                        {
                            title: "Name", cellStyle: { padding: "0px 10px" }, field: "name", validate: rowData => rowData?.name === '' || rowData?.name === undefined ? 'Name cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        },
                        {
                            title: "Code", cellStyle: { padding: "0px 10px" }, field: "code", validate: rowData => rowData?.code === '' || rowData?.code === undefined ? 'Code cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            ),
                        },
                    ]}
                    data={data || []}
                    icons={{
                        Add: () => <AddIcon />,
                        Edit: () => <EditIcon />,
                        Delete: () => <DeleteIcon />
                    }}
                    editable={{
                        onRowAdd: (newRow) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowDelete: (selectedRow) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => resolve(), 1000);
                            }),
                    }}
                    options={{
                        maxBodyHeight: 440,
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: true,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        paging: true,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    title="Salary Components"
                />
            </Paper>
        </Base>
    );
}

export default AdminLandingPage;
