import * as ActionTypes from '../ActionTypes';

export const GetLeaveApprovalAction = () => {
    return {
        type: ActionTypes.LEAVE_APPROVAL_GET_REQUEST,
    }
}

export const LeaveStatusAction = (leaveDetails, bulkApprovalData) => {
    return {
        type: ActionTypes.LEAVE_STATUS_UPDATE_REQUEST,
        leaveDetails,
        bulkApprovalData
    }
}