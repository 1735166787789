import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react'


const TDStep1 = ({ handleInputChange, step1, setStep1, activeStep, setActiveStep, disableEdit = false }) => {

    const useStyles = makeStyles((theme) =>
        createStyles({
            paper: {
                boxShadow: "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
                padding: "20px",
                borderRadius: "10px",
                minHeight: "130px"
            }
        }),
    );
    const classes = useStyles();

    const fields = [
        { name: "et_80c_lip", desc: "Payment of Life Insurance Premium (For self, spouse & children)", label: "80C - LIP" },
        { name: "et_80c_ulip", desc: "Contribution to Unit Linked Insurance Plan (For self, spouse & children)", label: "80C - ULIP" },
        { name: "et_80ccc_pension", desc: "Contribution to Pension Plans (self)", label: "80CCC - PENSION" },
        { name: "et_80c_ppf", desc: "Deposit in Public Provident Fund PPF (For self, spouse & children)", label: "80C - PPF" },
        { name: "et_80c_nsc", desc: "Purchase of National Savings Certificate (Self)", label: "80C - NSC" },
        { name: "et_80c_elss", desc: "Contribution to Equity Linked Savings Scheme - TAX SAVER-MF (Self)", label: "80C - ELSS" },
        { name: "et_80c_fees", desc: "Payment of tution fees for children for any School, College, University or Educational Institution (Self)", label: "80C - FEES" },
        { name: "et_80c_fd", desc: "Fixed Deposit for 5 years with a Scheduled Bank (Tax Saver - Self)", label: "80C - FD" },
        { name: "et_80c_sukanya", desc: "Sukanya Samriddhi Scheme (For Girl child)", label: "80C - SUKANYA" },
        { name: "et_80c_stamp_duty", desc: "Stamp Duty/Registration Charges paid for new House Property", label: "80C - STAMP_DUTY" },
        { name: "et_80c_hlp", desc: "Housing Loan Principle (Amout will auto populate after updating 'Loss from House Property' Section)", label: "80C - HLP" },
    ]

    const handleChange = (e) => {
        setStep1({ ...step1, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        handleInputChange({ target: { name: "tds1", value: step1 } })
        setActiveStep(activeStep + 1)
    }

    return (
        <Box>
            <Typography variant='h6'>Section 80C - Maximum Eligible deduction Rs 1.50 lakhs</Typography>
            <Grid container>
                {
                    fields.map((data, id) => {
                        return (
                            <>
                                {
                                    !disableEdit ?
                                        <Grid item key={id} xs={12} sm={6} md={6} padding={1.5}>
                                            <Paper className={classes.paper} >
                                                <Box minHeight={"3rem"}>
                                                    <Typography variant="body2" gutterBottom mb={2}>
                                                        {data?.desc}
                                                    </Typography>
                                                </Box>
                                                <TextField
                                                    size='small'
                                                    type="number"
                                                    autoComplete='first_name'
                                                    onChange={handleChange}
                                                    name={data?.name}
                                                    label={data?.label}
                                                    placeholder='Enter Amount'
                                                    fullWidth
                                                    value={step1?.[data?.name] || ""}
                                                />
                                            </Paper>
                                        </Grid>
                                        : disableEdit && step1?.[data?.name] !== null && step1?.[data?.name] !== undefined &&
                                        <Grid item key={id} xs={12} sm={6} md={6} padding={1.5}>
                                            <Paper className={classes.paper} >
                                                <Box minHeight={"3rem"}>
                                                    <Typography variant="body2" gutterBottom mb={2}>
                                                        {data?.desc}
                                                    </Typography>
                                                </Box>
                                                <TextField
                                                    size='small'
                                                    type="number"
                                                    autoComplete='first_name'
                                                    onChange={handleChange}
                                                    name={data?.name}
                                                    label={data?.label}
                                                    disabled={disableEdit}
                                                    placeholder='Enter Amount'
                                                    fullWidth
                                                    value={step1?.[data?.name] || ""}
                                                />
                                            </Paper>
                                        </Grid>
                                }
                            </>
                        )
                    })
                }
            </Grid>
            {
                !disableEdit &&
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {activeStep !== 0 &&
                        <Button
                            sx={{ marginRight: "10px", mt: 5 }}
                            type="submit"
                            variant="outlined"
                            size="medium"
                            onClick={() => setActiveStep(activeStep - 1)}
                        >
                            Back
                        </Button>
                    }
                    {activeStep < 5 &&
                        <Button
                            sx={{ marginRight: "10px", mt: 5, }}
                            size="medium"
                            variant="contained"
                            className="mpl-primary-btn"
                            disabled={Object.values(step1)?.reduce((a, b) => +a + +b, 0) > 150000 ? true : false}
                            onClick={() => handleSubmit()}
                        >
                            Next
                        </Button>
                    }
                </Box>
            }
        </Box>
    )
}

export default TDStep1