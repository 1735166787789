import React from 'react'
import PropTypes from 'prop-types'
import ReactTimeAgo from 'react-time-ago'
import { Tooltip } from '@mui/material'
import { default as ta } from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

ta.addDefaultLocale(en)
ta.addLocale(ru)

export default function TimeAgo(props) {
    return (
        <ReactTimeAgo
            {...props}
            wrapperComponent={TooltipContainer}
            tooltip={false} />
    )
}

const TooltipContainer = ({ verboseDate, children, ...rest }) => (
    <Tooltip {...rest} title={verboseDate}>
        {children}
    </Tooltip>
)

TooltipContainer.propTypes = {
    // `verboseDate` is not generated on server side
    // (because tooltips are only shown on mouse over),
    // so it's not declared a "required" property.
    verboseDate: PropTypes.string,
    children: PropTypes.node.isRequired
}