import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TDStep3 from './TDStep3';
import TDStep4 from './TDStep4';
import TDStep5 from './TDStep5';
import { useDispatch } from 'react-redux';
import { AddTaxDeclarationAction, UpdateTaxDeclarationAction } from '../../../../../store/Finance/TaxDeclaration/Action';
import TDStep1 from './TDStep1';
import TDStep2 from './TDStep2';

const actionSummaryStyle = {
    marginTop: "1%",
    minHeight: 50,
    maxHeight: 50,
    borderRadius: "10px 10px 0px 0px",
    '&.Mui-expanded': {
        minHeight: 50,
        maxHeight: 50,
        backgroundColor: '#76548B',
        color: "white",
    }
}


const TaxSummary = ({ data, activeStep, setActiveStep }) => {


    const [expanded, setExpanded] = useState(1)
    const dispatch = useDispatch()

    const handleSubmit = () => {
        const taxdata = {
            id: data?.taxDeclaration_data?.[0]?.emp_tax_declaration_id,
            emptd: JSON.stringify([{
                ...data?.step1, ...data?.step2, ...data?.step5,
                status: "S",
                approved_date: null,
                year: new Date().getFullYear(),
                preferred_regime: data?.regimeData?.regime
            }]),
            emplp: JSON.stringify(data?.step3),
            emphp: JSON.stringify(data?.step4)
        }
        if (data?.taxDeclaration_data?.[0]?.emp_tax_declaration_id) dispatch(UpdateTaxDeclarationAction(taxdata))
        else dispatch(AddTaxDeclarationAction(taxdata))
    }

    return (
        <>
            <Box>
                <Typography variant='h6'>Summary</Typography>
                {
                    data?.step1 && Object.values(data?.step1)?.length > 0 &&
                    <Accordion
                        expanded={expanded === 1}
                        onChange={e => expanded === 1 ? setExpanded("") : setExpanded(1)}
                        sx={{ borderRadius: "10px" }}
                    >
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                            sx={actionSummaryStyle}
                        >
                            <Typography variant="body1">
                                Section 80C - Maximum Eligible deduction Rs 1.50 lakhs
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TDStep1 disableEdit={true} step1={data?.step1} />
                        </AccordionDetails>
                    </Accordion>
                }
                {
                    data?.step2 && Object.values(data?.step2)?.length > 0 &&
                    <Accordion
                        expanded={expanded === 2}
                        onChange={e => expanded === 2 ? setExpanded("") : setExpanded(2)}
                        sx={{ borderRadius: "10px" }}
                    >
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                            sx={actionSummaryStyle}
                        >
                            <Typography variant="body1">
                                Other Chapter VIA - Maximum Eligible deduction as per Section Limits
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TDStep2 disableEdit={true} step2={data?.step2} />
                        </AccordionDetails>
                    </Accordion>
                }
                {
                    data?.step3 && Object.values(data?.step3)?.length > 0 &&
                    <Accordion
                        expanded={expanded === 3}
                        onChange={e => expanded === 3 ? setExpanded("") : setExpanded(3)}
                        sx={{ borderRadius: "10px" }}
                    >
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                            sx={actionSummaryStyle}
                        >
                            <Typography variant="body1">
                                House Rent Exemption (as per limits U/s 10(13A))
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TDStep3 step3={data?.step3} disableEdit={true} />
                        </AccordionDetails>
                    </Accordion>
                }
                {
                    data?.step4 && Object.values(data?.step4)?.length > 0 &&
                    <Accordion
                        expanded={expanded === 4}
                        onChange={e => expanded === 4 ? setExpanded("") : setExpanded(4)}
                        sx={{ borderRadius: "10px" }}
                    >
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                            sx={actionSummaryStyle}
                        >
                            <Typography variant="body1">
                                Section 24 - Income / Lose on House Property, additional deduction under Section 80EE / 80EEA
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TDStep4 step4={data?.step4} disableEdit={true} />
                        </AccordionDetails>
                    </Accordion>
                }
                {
                    data?.step5 && Object.values(data?.step5)?.length > 0 &&
                    <Accordion
                        expanded={expanded === 5}
                        onChange={e => expanded === 5 ? setExpanded("") : setExpanded(5)}
                        sx={{ borderRadius: "10px" }}
                    >
                        <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                            sx={actionSummaryStyle}
                        >
                            <Typography variant="body1"> Other Details </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TDStep5 step5={data?.step5} disableEdit={true} />
                        </AccordionDetails>
                    </Accordion>
                }
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", bottom: 30 }}>
                {activeStep !== 0 &&
                    <Button
                        sx={{ marginRight: "10px", mt: 5 }}
                        type="submit"
                        variant="outlined"
                        size="medium"
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Back
                    </Button>
                }
                <Button
                    sx={{ marginRight: "10px", mt: 5, }}
                    size="medium"
                    variant="contained"
                    className="mpl-primary-btn"
                    onClick={() => handleSubmit()}
                >
                    Save
                </Button>
            </Box>
        </>
    );
}

export default TaxSummary;
