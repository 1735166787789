import * as ActionTypes from '../ActionTypes';


export const GetAtsConsultancyAction = (params) => {
    return {
        type: ActionTypes.ATS_CONSULTANCY_GET_REQUEST,
        params
    }
}

export const AddAtsConsultancyAction = (params) => {
    return {
        type: ActionTypes.ATS_CONSULTANCY_ADD_REQUEST,
        params
    }
}
export const UpdateAtsConsultancyAction = (params) => {
    return {
        type: ActionTypes.ATS_CONSULTANCY_UPDATE_REQUEST,
        params
    }
}
export const DeleteAtsConsultancyAction = (params) => {
    return {
        type: ActionTypes.ATS_CONSULTANCY_DELETE_REQUEST,
        params
    }
}