import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";
import { addSessionData } from "./baseHelper";

export async function GenerateHrmsBearerToken() {
  return await axiosInstance("/auth/GenerateHrmsBearerToken", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: { hrms_secret: "MEEPL_BEARER_TOKEN_OAUTH_HRMS_UTILITY_API_SECRET" }
  })
    .then((response) => {
      addSessionData({ key: "hrmsbearertoken", value: response.data.data });
    })
    .catch((err) => { });
}
