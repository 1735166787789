import React, { useEffect, useState } from 'react';
import FormResponse from './FormResponse';

const GiveFeedback = () => {
  const [feedbackData, setFeedbackData] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem('feedbackData');
    if (storedData) {
      setFeedbackData(JSON.parse(storedData));
    }
  }, []);

  if (!feedbackData) {
    return <div>Loading...</div>;
  }

  return (
    <FormResponse
      data={feedbackData?.data}
      userData={feedbackData?.userData}
    />
  );
};

export default GiveFeedback;
