import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
    atsInternalUserToggle: false,
    atsCompanyUserToggle: false,
    atsConsultancyUserToggle: false,
    atsGroupUserToggle:false
};

export const AtsUserReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_USER_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                atsUserData: []
            };
        case ActionTypes.ATS_USER_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsUserData: action.payload?.data?.data
            };
        case ActionTypes.ATS_USER_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_USER_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ATS_USER_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsUserData: action.payload?.data?.data
            };
        case ActionTypes.ATS_USER_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_USER_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ATS_USER_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsUserData: action.payload?.data?.data
            };
        case ActionTypes.ATS_USER_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_INTERNAL_USER_TOGGLE:
            return {
                ...state,
                atsInternalUserToggle: !state?.atsInternalUserToggle
            };
        case ActionTypes.ATS_COMPANY_USER_TOGGLE:
            return {
                ...state,
                atsCompanyUserToggle: !state?.atsCompanyUserToggle
            };
        case ActionTypes.ATS_CONSULTANCY_USER_TOGGLE:
            return {
                ...state,
                atsConsultancyUserToggle: !state?.atsConsultancyUserToggle
            };
            case ActionTypes.ATS_GROUP_USER_TOGGLE:
                return {
                    ...state,
                    atsGroupUserToggle: !state?.atsGroupUserToggle
                };
            
        default:
            return state;
    }
}