import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    isLoading: false,
    permission_data: [],
    diablePowerUserShow: false,
};

const PermissionReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.PERMISSION_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                permission_data: []
            };
        case ActionTypes.PERMISSION_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success_message: action.payload.message,
                error: action.payload.hasError,
                permission_data: action.payload?.data?.data
            };
        case ActionTypes.POWER_USER_DISABLED_ACCESS:
            return {
                diablePowerUserShow: !state?.diablePowerUserShow
            }
        default:
            return state;
    }
}

export default PermissionReducer;