import React, { useEffect, useState } from "react";
import { Button, Typography, Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import PlaceHolder from "../../../ATS/images/placeholder-image.jpg";
import Success from "../../../ATS/images/confetti.gif";
import { s3Client } from "../../../Base/helper/baseFunctions";

const Preview = ({
  setCurrentMode,
  currentMode,
  activeStep,
  setActiveStep,
  data,
}) => {
  // Component code goes here
  const [imageUrl, setImageUrl] = useState("");
  const [step, setStep] = useState("1");
  useEffect(() => {
    data?.company_logo && getImageSignedUrl(data?.company_logo);
    //eslint-disable-next-line
  }, []);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const onSubmit = async () => {
    setStep("2");
    setTimeout(() => {
      setCurrentMode("grid");
    }, 3000);
  };
  return (
    <>
      {step === "1" ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography sx={{ mb: 1, textAlign: "center" }} variant="h6">
              {" "}
              Offer Letter Preview
            </Typography>
          </Box>
          <Grid container>
            <div className="a4-sheet">
              <div className="a4-header">
                {data?.company_logo && (
                  <img className="company_logo" alt="Nothing" src={imageUrl} />
                )}
                <div className="header-text">{data?.header}</div>
              </div>
              <div className="a4-body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.offer_letter_content,
                  }}
                ></div>
              </div>
              <div className="a4-footer"> {data?.footer}</div>
            </div>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  padding: "0px 10px",
                }}
              >
                <Button
                  variant="contained"
                  className="mpl-primary-btn"
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  className="mpl-primary-btn"
                  onClick={onSubmit}
                >
                  Complete Offer Letter
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              maxWidth: "600px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#e6f4ea",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              padding: "20px",
              margin: "auto",
            }}
          >
            <img
              src={Success}
              alt="Success"
              width={"100px"}
              height={"100px"}
              style={{
                backgroundColor: "#fff",
                borderRadius: "50%",
                padding: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
            <Typography sx={{ pt: 1, pb: 1, textAlign: "center" }} variant="h4">
              {currentMode === "Edit"
                ? " Your Offer Letter Template is Updated Successfully"
                : " Your Offer Letter Template is Created Successfully"}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default Preview;
