import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetStudyProgramHelper = async (studyProgram) => {
    return await axiosInstance(`/ancillary/getStudyPrograms`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            studylevel_id: studyProgram.studylevel_id
        }
    })
        .then((res) => {

            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })

}

export const AddStudyProgramHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addStudyProgram`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_empid: data.employee_id,
            studylevel_id:params?.studylevel?.studylevel_id,
            name: params?.name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Study Program Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateStudyProgramHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateStudyProgram`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_empid: data.employee_id,
            studylevel_id: params?.studylevel?.studylevel_id || params?.studylevel_id,
            studyprogram_id:params?.studyprogram_id,
            name: params?.name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Study Program Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteStudyProgramHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteStudyProgram`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_empid: data?.employee_id,
            studylevel_id: params?.studylevel_id,
            studyprogram_id:params?.studyprogram_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Study Program Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}