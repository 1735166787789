import { Paper } from '@mui/material'
import { useState, useEffect } from 'react'
import Base from '../../../Base/views/Base'
import AtsConsultancyForm from './AtsConsultancyForm'
import AtsConsultancyGrid from './AtsConsultancyGrid'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetAtsConsultancyAction } from '../../../../../store/ATS/Consultancy/Action'
import { GetATSModuleFeaturesAction } from '../../../../../store/RoleAccess/ATSModuleFeatures/Action'

const AtsConsultancyLandingPage = () => {
    const initialValues = {
        name: "",
        address: "",
        contact_person: "",
        mobile_number: "",
        office_number: "",
        country_id: "",
        state_id: "",
        city_id: "",
        zip_code: "",
        website: "",
        primary_owner: "",
        company_lead_id: "",
        business_unit_id: "",
        email_id: ""
    }
    const dispatch = useDispatch()
    const location = useLocation();
    const defaultValue = location.state?.defaultValue || "";
    const [currentMode, setCurrentMode] = useState(defaultValue ? defaultValue : "grid")
    const [currentData, setCurrentData] = useState(defaultValue ? null : initialValues)
    const { atsConsultancyData, isLoading } = useSelector(state => state.AtsReducer?.AtsConsultancyReducer);
    const [consultancyData, setConsultancyData] = useState([])
    useEffect(() => {
        dispatch(GetAtsConsultancyAction());
        setConsultancyData(atsConsultancyData)
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Manage" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <Base>
            <Paper sx={{ borderRadius: "10px", maxHeight: "87vh", minHeight: "87vh", overflowY: "auto" }}>
                {
                    currentMode === "grid" ? <AtsConsultancyGrid setCurrentMode={setCurrentMode} setCurrentData={setCurrentData} currentData={currentData} consultancyData={consultancyData} isLoading={isLoading} />
                        : <AtsConsultancyForm currentData={currentData} setCurrentMode={setCurrentMode} setConsultancyData={setConsultancyData} />
                }
            </Paper>
        </Base>
    )
}

export default AtsConsultancyLandingPage