import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";
import { toast } from "react-toastify";

export const FeatureHelper = async (params) => {
    
    return await axiosInstance("/plans/getFeatures", {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
        }
    })
        .then((res) => {
          return { data: res?.data };
        })
        .catch((err) => {
          err?.response?.data?.error?.[0]?.message && toast.error(err?.response?.data?.error?.[0]?.message)
          return { message: err?.response?.data };
        });
};
