import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetCasteHelper = async () => {
    return await axiosInstance(`/ancillary/getCastes`, {
        method: "GET",
        baseURL: HRMS_UTILITY_API_URL,
        data: {}
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddCasteHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addCaste`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data.employee_id,
            name: params?.name,
            code: params?.code,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Caste Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateCasteHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateCaste`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data.employee_id,
            caste_id: params?.caste_id,
            name: params?.name,
            code: params?.code,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Caste Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteCasteHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteCaste`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            user_emp_id: data.employee_id,
            caste_id: params?.caste_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Caste Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}