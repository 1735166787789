import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Fab, FormControl, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import CommonDatePicker from '../../../Base/views/CommonDatePicker';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const actionSummaryStyle = {
    marginTop: "1%",
    minHeight: 50,
    maxHeight: 50,
    borderRadius: "10px 10px 0px 0px",
    '&.Mui-expanded': {
        minHeight: 50,
        maxHeight: 50,
        backgroundColor: '#76548B',
        color: "white",
    }
}

const TDStep3 = ({ handleInputChange, disableEdit = false, step3, setStep3, houseObj, activeStep, setActiveStep }) => {

    const maxProperty = 3

    const [expanded, setExpanded] = useState(0)
    const [errors, setErrors] = useState([]);

    const handleChange = (event, index) => {
        let newArr = [...step3];
        let item = newArr[index];
        delete item?.emp_tax_declaration_fk
        delete item?.employee_fk
        item = { ...item, [event.target.name]: event.target.value };
        newArr[index] = item;
        setStep3(newArr);
    }

    const hanldeAddStore = () => {
        let objDetail = JSON.parse(JSON.stringify(houseObj));
        setStep3(step3.concat(JSON.parse(JSON.stringify([objDetail]))));
    }

    const onRemoveStore = (e, index) => {
        const list = [...step3];
        list.splice(index, 1);
        setStep3([...list]);
        const errorList = [...errors];
        errorList.splice(index, 1);
        setErrors([...errorList]);
    }

    const validate = () => {
        let ret_value = []
        step3?.forEach((store, index) => {
            const error = {};
            if (!store.rent_from) error.rent_from = "Rent From is required";
            if (!store.rent_to) error.rent_to = "Rent To is required";
            if (!store.rent_per_month) error.rent_per_month = "Rent is required";
            if (!store.city) error.city = "City is required";
            if (!store.lp_address) error.lp_address = "Address is required";
            if (!store.landl_name) error.landl_name = "Name is required";
            if (!store.landl_panno) error.landl_panno = "PAN is required";
            const temp = errors;
            temp[index] = error;
            setErrors([...temp]);
            ret_value.push(Object.values(error).every((e) => e === ""));
        })
        return ret_value
    };

    const handleSubmit = () => {
        if (!(validate().includes(false))) {
            handleInputChange({ target: { name: "tds3", value: step3 } })
            setActiveStep(activeStep + 1)
        }
    }

    return (
        <>
            <Box>
                {
                    !disableEdit &&
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant='h6'>House Rent Exemption (as per limits U/s 10(13A))</Typography>
                        <Box display="flex" flexDirection="row-reverse">
                            {step3 && step3?.length <= maxProperty && (
                                <Box mb={1}>
                                    <Button
                                        onClick={hanldeAddStore}
                                        variant="contained"
                                        size="medium"
                                        startIcon={<AddIcon />}
                                        className='mpl-primary-btn'
                                    >
                                        Add Lease Period {step3.length + 1} &nbsp;&nbsp;
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                }
                {
                    step3?.map((store, id) => {
                        return (
                            <Accordion
                                key={id}
                                expanded={id === expanded}
                                onChange={e => id === expanded ? setExpanded("") : setExpanded(id)}
                                sx={{ borderRadius: "10px" }}
                            >
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowDownIcon />}
                                    id='controlled-panel-header-1'
                                    aria-controls='controlled-panel-content-1'
                                    sx={actionSummaryStyle}
                                >
                                    <Typography variant="body1">
                                        Lease Period {id + 1}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails key={id}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} md={4} p={1}>
                                            <FormControl fullWidth >
                                                <CommonDatePicker
                                                    size="small"
                                                    name="rent_from"
                                                    label="Rent From"
                                                    pickerType="date"
                                                    required={true}
                                                    disabled={disableEdit}
                                                    pickerFormat="dd/MM/yyyy"
                                                    DefaultDTvalue={store?.rent_from || ""}
                                                    maxDate={new Date(store?.rent_to)}
                                                    handleChange={(value) => handleChange({ target: { name: "rent_from", value: `${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}` } }, id)}
                                                    {...(errors[id] &&
                                                        errors[id].rent_from && {
                                                        error: true,
                                                        helperText: errors[id].rent_from,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} p={1}>
                                            <FormControl fullWidth >
                                                <CommonDatePicker
                                                    size="small"
                                                    name="rent_to"
                                                    label="Rent To"
                                                    required={true}
                                                    pickerType="date"
                                                    disabled={disableEdit}
                                                    pickerFormat="dd/MM/yyyy"
                                                    DefaultDTvalue={store?.rent_to || ""}
                                                    minDate={new Date(store?.rent_from)}
                                                    handleChange={(value) => handleChange({ target: { name: "rent_to", value: `${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}` } }, id)}
                                                    {...(errors[id] &&
                                                        errors[id].rent_to && {
                                                        error: true,
                                                        helperText: errors[id].rent_to,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} p={1}>
                                            <TextField
                                                required
                                                size='small'
                                                onChange={(e) => handleChange(e, id)}
                                                name="rent_per_month"
                                                label="Rent per Month"
                                                fullWidth
                                                disabled={disableEdit}
                                                type="number"
                                                onKeyPress={e => e.target.value?.length >= 7 && e.preventDefault()}
                                                value={store?.rent_per_month || ""}
                                                {...(errors[id] &&
                                                    errors[id].rent_per_month && {
                                                    error: true,
                                                    helperText: errors[id].rent_per_month,
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} p={1}>
                                            <FormControl fullWidth >
                                                <TextField
                                                    label="City"
                                                    value={store?.city}
                                                    required={true}
                                                    variant={"outlined"}
                                                    name="city"
                                                    size="small"
                                                    disabled={disableEdit}
                                                    onChange={(e) => handleChange(e, id)}
                                                    {...(errors[id] &&
                                                        errors[id].city && {
                                                        error: true,
                                                        helperText: errors[id].city,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} p={1}>
                                            <TextField
                                                required
                                                size='small'
                                                onChange={(e) => handleChange(e, id)}
                                                name="lp_address"
                                                label="Lease Property Address"
                                                fullWidth
                                                disabled={disableEdit}
                                                value={store?.lp_address || ""}
                                                {...(errors[id] &&
                                                    errors[id].lp_address && {
                                                    error: true,
                                                    helperText: errors[id].lp_address,
                                                })}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Typography variant='h6' p={1}>Add Landlord Details</Typography>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} md={4} p={1}>
                                            <TextField
                                                required
                                                size='small'
                                                onChange={(e) => handleChange(e, id)}
                                                name={"landl_name"}
                                                label="Name"
                                                fullWidth
                                                disabled={disableEdit}
                                                value={store?.landl_name || ""}
                                                {...(errors[id] &&
                                                    errors[id].landl_name && {
                                                    error: true,
                                                    helperText: errors[id].landl_name,
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} p={1}>
                                            <TextField
                                                required
                                                size='small'
                                                onChange={(e) => handleChange(e, id)}
                                                name="landl_panno"
                                                label="PAN Number"
                                                fullWidth
                                                disabled={disableEdit}
                                                value={store?.landl_panno || ""}
                                                onKeyPress={e => e.target.value?.length >= 10 && e.preventDefault()}
                                                {...(errors[id] &&
                                                    errors[id].landl_panno && {
                                                    error: true,
                                                    helperText: errors[id].landl_panno,
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
                                            <Fab
                                                id={store.id}
                                                onClick={(e) => {
                                                    onRemoveStore(e, id);
                                                }}
                                                size="small"
                                                color="error"
                                                aria-label="delete"
                                            >
                                                <DeleteIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </Box>
            {
                !disableEdit &&
                <Box sx={{ display: "flex", justifyContent: "space-between", bottom: 30 }}>
                    {activeStep !== 0 &&
                        <Button
                            sx={{ marginRight: "10px", mt: 5 }}
                            type="submit"
                            variant="outlined"
                            size="medium"
                            onClick={() => setActiveStep(activeStep - 1)}
                        >
                            Back
                        </Button>
                    }
                    {activeStep < 5 &&
                        <Button
                            sx={{ marginRight: "10px", mt: 5, }}
                            size="medium"
                            variant="contained"
                            className="mpl-primary-btn"
                            onClick={() => handleSubmit()}
                        >
                            Next
                        </Button>
                    }
                </Box>
            }
        </>
    );
}

export default TDStep3;
