import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    isLoading: false,
    module_data: []
};

const ModuleReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.MODULE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                module_data: []
            };
        case ActionTypes.MODULE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success_message: action.payload.message,
                error: action.payload.hasError,
                module_data: action.payload?.data?.data
            };
        default:
            return state;
    }
}

export default ModuleReducer;