import * as ActionTypes from '../ActionTypes';

const initialState = {
    domain_name: "",
    isLoading: false,
    bank_options: []
};

const BankReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_BANK_REQUEST:
            return {
                ...state,
                isLoading: true,
                bank_options: []
            };
        case ActionTypes.GET_BANK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success_message: action.payload.message,
                error: action.payload.hasError,
                bank_options: action.payload?.data?.data
            };
        case ActionTypes.ADD_BANK_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.ADD_BANK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: true,
                bank_options: action.payload?.data?.data
            };
        case ActionTypes.ADD_BANK_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.data
            };
        case ActionTypes.UPDATE_BANK_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.UPDATE_BANK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: true,
                bank_options: action.payload?.data?.data
            };
        case ActionTypes.UPDATE_BANK_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.data
            };
        case ActionTypes.DELETE_BANK_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.DELETE_BANK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: true,
                bank_options: action.payload?.data?.data
            };
        case ActionTypes.DELETE_BANK_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.data
            };
        default:
            return state;
    }
}

export default BankReducer;