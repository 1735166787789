import { takeLatest } from 'redux-saga/effects';
import { BirthdaysSaga, EmployeeDashboardSaga , HomeSaga, GetQuickLinkSaga} from './Saga';
import * as ActionTypes from './ActionTypes';

export default function* EmployeeDashboardWatcher() {
    yield takeLatest(ActionTypes.EMPLOYEE_DASHBOARD_GET_REQUEST, EmployeeDashboardSaga);
    yield takeLatest(ActionTypes.EMPLOYEE_BIRTHDAY_REQUEST, BirthdaysSaga);
    yield takeLatest(ActionTypes.HOME_GET_REQUEST, HomeSaga);
    yield takeLatest(ActionTypes.QUICK_LINKS_GET_REQUEST, GetQuickLinkSaga);
    
}

