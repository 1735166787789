import { Box, Button, Grid, Typography } from "@mui/material"
import FeedNotFoundSvg from "../../../../svg/NotFoundSvgTwo"
import history from "../../../../history"


const PreScreenTokenNotFound = ({ navigateButton }) => {
    return (
        <Grid item>
            <Box
                className="response-main"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: "400px"
                }}
            >
                <Typography padding={"20px"} textAlign={"center"} component="h1" variant="h3" fontWeight={700} lineHeight={1.5}>
                Pre Screen Quetion Submitted Successfully 
                </Typography>
                <Typography  padding={"10px"} textAlign={"center"} variant="body1" color="text.secondary">
                You have already submitted  Pre Screen Quetion successfully. No action is required for this request. To share other feedback, please get in touch with the recruiter for a new form.
                </Typography>

                <div style={{ marginTop:"20px",marginBottom: "40px", height: "196px" }}>
                    <FeedNotFoundSvg />
                </div>
                {navigateButton && navigateButton}
                <Button sx={{ mt: 3 }} onClick={() => history.push("/")}>Close</Button>
            </Box>
        </Grid>
    )
}

export default PreScreenTokenNotFound