import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";
import { matchSorter } from 'match-sorter';

export default function SelectableSearch(props) {
  const [open, setOpen] = React.useState(false);
  const loading = open && props?.data?.values?.length === 0 ? true : false;
  var optionLabel = props?.fieldLabel || "optionname"

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    if (active) {
      props?.loading();
    }
    return () => {
      active = false;
    };
    //eslint-disable-next-line
  }, [loading, props?.data?.value]);


  const filterOptions = (options, { inputValue }) => {
    let vals = matchSorter(options, inputValue, { keys: props?.keys });
    return vals
  };

  return (
    <React.Fragment>
      <Autocomplete
        multiple={props?.multiple}
        freeSolo={props?.disabled}
        size="small"
        limitTags={3}
        disabled={props?.disabled}
        noOptionsText={props?.noOptionText || "No Options"}
        value={props?.value}
        id={props?.id}
        name={props?.name}
        disableClearable={props?.disableClearable}
        openOnFocus
        open={open} 
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, value) => props?.onChangeEvent(props?.name, value)}
        filterSelectedOptions
        getOptionLabel={(option) => {
          if (option && option?.[optionLabel]) {
            return option?.[optionLabel];
          } else {
            return "";
          }
        }}
        options={
          props?.data && props?.data?.values?.length > 0 ? props?.data?.values : []
        }
        loading={props?.data?.load}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props?.label}
            autoFocus={props?.autoFocus}
            required={props?.required}
            variant={props?.variantType ? props?.variantType : "outlined"}
            name={props?.name}
            onBlur={props?.onBlur}
            error={props?.error}
            size={props?.size}
            helperText={props?.helperText}
            inputprops={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props?.data?.load ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps?.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...(props?.filterOptions && { filterOptions: filterOptions })}
        {...(props?.renderOption && { renderOption: props?.renderOption })}
        {...(props?.isOptionEqualToValue && { isOptionEqualToValue: props?.isOptionEqualToValue })}
        {...(props?.grouping ? { groupBy: (option) => option.user_type } : {})}
      />
    </React.Fragment>
  );
}