import { takeLatest } from 'redux-saga/effects';
import { GetWorkspaceSaga, SignInSaga } from './Saga';
import * as ActionTypes from './ActionTypes';

export default function* SignInWatcher() {
    yield takeLatest(ActionTypes.SIGN_IN_REQUEST, SignInSaga);
    yield takeLatest(ActionTypes.GET_WORKSPACE_REQUEST, GetWorkspaceSaga)
}


