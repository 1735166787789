import * as ActionTypes from '../ActionTypes';

const TimeSheetApprovalInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: "",
    GetSuccess: "",
    GetSuccessCode: ""
}

export const TimeSheetApprovalReducer = function (state = TimeSheetApprovalInitialState, action) {
    switch (action.type) {
        case ActionTypes.TIME_SHEET_APPROVAL_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                time_sheet_approval_data: [],
            };
        case ActionTypes.TIME_SHEET_APPROVAL_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                time_sheet_approval_data: action.payload?.data?.data
            };
        case ActionTypes.TIME_SHEET_APPROVAL_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.APPROVE_TIME_SHEET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                GetSuccess: "",
            };
        case ActionTypes.APPROVE_TIME_SHEET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                GetSuccess: action.payload?.data?.statuscode
            };
        case ActionTypes.APPROVE_TIME_SHEET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.APPROVE_TIME_OFF_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                GetSuccessCode: "",
            };
        case ActionTypes.APPROVE_TIME_OFF_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                GetSuccessCode: action.payload?.data?.statuscode
            };
        case ActionTypes.APPROVE_TIME_OFF_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.TEAM_BALANCE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                TeamBalanceData: [],
            };
        case ActionTypes.TEAM_BALANCE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                TeamBalanceData: action.payload?.data?.data
            };
        case ActionTypes.TEAM_BALANCE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        default:
            return state;
    }
}