// Employee Dashboard
export const EMPLOYEE_DASHBOARD_GET_REQUEST = "EMPLOYEE_DASHBOARD_GET_REQUEST";
export const EMPLOYEE_DASHBOARD_GET_SUCCESS = "EMPLOYEE_DASHBOARD_GET_SUCCESS";
export const EMPLOYEE_DASHBOARD_GET_ERROR = "EMPLOYEE_DASHBOARD_GET_ERROR";

//Employees Birthday
export const EMPLOYEE_BIRTHDAY_REQUEST = "EMPLOYEE_BIRTHDAY_REQUEST";
export const EMPLOYEE_BIRTHDAY_SUCCESS = "EMPLOYEE_BIRTHDAY_SUCCESS";
export const EMPLOYEE_BIRTHDAY_ERROR = "EMPLOYEE_BIRTHDAY_ERROR";

//Home
export const HOME_GET_REQUEST = "HOME_GET_REQUEST";
export const HOME_GET_SUCCESS = "HOME_GET_SUCCESS";
export const HOME_GET_ERROR = "HOME_GET_ERROR";

//Quick Links
export const QUICK_LINKS_GET_REQUEST = "QUICK_LINKS_GET_REQUEST";
export const QUICK_LINKS_GET_SUCCESS = "QUICK_LINKS_GET_SUCCESS";
export const QUICK_LINKS_GET_ERROR = "QUICK_LINKS_GET_ERROR";
