import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  Divider,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import PlaceHolder from "../../../ATS/images/placeholder-image.jpg";
import { s3Client } from "../../../Base/helper/baseFunctions";
import { OfferLetterFieldsHelper, UpdateOfferLetterTemplateHelper } from "../../helper/AtsFormTemplateHelper";
import Quill from "../../../Base/views/Quill";
const General = ({
  activeStep,
  setActiveStep,
  data,
  setData,
}) => {
  // Component code goes here
  const useStyles = makeStyles((theme) =>
    createStyles({
      Paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
      },
    })
  );
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState("");
  const [mentionUsers, setMentionUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [offerletter,] = useState({
    description: "",
  });
  const [description, setDescription] = useState("");
  useEffect(() => {
    data?.company_logo && getImageSignedUrl(data?.company_logo);
    setDescription(data?.offer_letter_content);
    OfferLetterFieldsHelper({
      offer_letter_template_id: data?.offer_letter_template_id,
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        let arr = [];
        res?.data?.data?.forEach((temp) =>
          arr.push({ ...temp, id: temp?.label, display: temp?.label })
        );
        setMentionUsers(arr);
      }
    });
    //eslint-disable-next-line
  }, []);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const validate = (fieldValues, description) => {
    let temp = { ...errors };
    if ("description" in fieldValues) {
      temp.description =
        description === "" || description === null
          ? "Description is required"
          : description === "<p><br></p>"
          ? "Description is required"
          : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const onSubmit = async () => {
    if (validate(offerletter,description)) {
      if (data?.offer_letter_template_id) {
        UpdateOfferLetterTemplateHelper({
          ...data,
          offer_letter_template_id: data?.offer_letter_template_id,
          offer_letter_template_name: data?.offer_letter_template_name,
          offer_letter_template_fields: JSON.stringify(data?.offer_letter_template_fields),
          offer_letter_content: description
        }).then((res) => {
          if (res?.data?.statuscode === 200) {
            setActiveStep(activeStep + 1);
            setData(res?.data?.data?.[0]);
          }
        });
      }
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} padding={1.5}>
        <Typography sx={{ mb: 1, textAlign: "center" }} variant="h6">
          {" "}
          Offer Letter Customization
        </Typography>
          <Paper className={classes?.paper}>
            <Grid container alignItems="center" sx={{ textAlign: "center" }}>
            {data?.company_logo && (
              <Grid item>
                <Box className="office_logo">
                    <img
                      width={80}
                      height={80}
                      style={{ padding: "4px 4px", borderRadius: "6px" }}
                      alt="Nothing"
                      src={imageUrl}
                    />
                </Box>
              </Grid>
               )}
              {data?.header && (
                <Grid item xs>
                  <Typography variant="h6" gutterBottom>
                    {data?.header}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {(data?.header || data?.company_logo) && (
            <Divider variant="middle" flexItem />
          )}
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                minHeight: "300px",
              }}
              className="offer_customization"
            >
              <Quill
                name="description"
                value={description}
                setValue={setDescription}
                error={Boolean(errors.description)}
                style={{ height: "200px" }}
                hideHeading={true}
                mentionUsers={mentionUsers}
              />
              {errors?.description && (
                <Typography
                  sx={{
                    color: "red",
                    paddingLeft: "20px",
                    paddingTop: 6,
                  }}
                >
                  {errors?.description}
                </Typography>
              )}
            </Grid>
            {data?.footer && (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Divider variant="middle" flexItem />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    paddingLeft: "15px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  {data?.footer}
                </Typography>
              </Grid>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              padding: "0px 10px",
            }}
          >
            <Button
              variant="contained"
              className="mpl-primary-btn"
              onClick={() => setActiveStep(activeStep - 1)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              className="mpl-primary-btn"
              onClick={onSubmit}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default General;
