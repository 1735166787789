import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
    atsRecruiterData:[]
};

export const AtsReportReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_TARGET_ACHIEVEMENT_REPORT_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_TARGET_ACHIEVEMENT_REPORT_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsTargetAchievement: action.payload?.data?.data
            };
        case ActionTypes.ATS_TARGET_ACHIEVEMENT_REPORT_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.ATS_QUALITY_OF_RESUME_REPORT_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_QUALITY_OF_RESUME_REPORT_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsQualityofResume: action.payload?.data?.data
            };
        case ActionTypes.ATS_QUALITY_OF_RESUME_REPORT_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };

            case ActionTypes.ATS_DAILY_PRODUCTIVITY_REPORT_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_DAILY_PRODUCTIVITY_REPORT_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsDailyProductivity: action.payload?.data?.data
            };
        case ActionTypes.ATS_DAILY_PRODUCTIVITY_REPORT_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };

            case ActionTypes.ATS_MONTHLY_REPORT_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_MONTHLY_REPORT_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsMonthlyReport: action.payload?.data?.data
            };
        case ActionTypes.ATS_MONTHLY_REPORT_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };

        case ActionTypes.ATS_REVENUE_REPORT_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_REVENUE_REPORT_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                atsRevenue: action.payload?.data?.data
            };
        case ActionTypes.ATS_REVENUE_REPORT_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };

            case ActionTypes.ATS_REPORT_RECRUITER_GET_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                    error: false,
                    success: false,
                    atsRecruiterData: []
                };
            case ActionTypes.ATS_REPORT_RECRUITER_GET_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    successMessage: action.payload?.message,
                    atsRecruiterData: action.payload?.data?.data
                };
            case ActionTypes.ATS_REPORT_RECRUITER_GET_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    error: action?.payload?.hasError,
                    errorMessage: action.payload?.data?.error?.[0]?.message
                };
                case ActionTypes.ATS_WORK_LOAD_REPORT_GET_REQUEST:
                    return {
                        ...state,
                        isLoading: true,
                        error: false,
                        success: false
                    };
                case ActionTypes.ATS_WORK_LOAD_REPORT_GET_SUCCESS:
                    return {
                        ...state,
                        isLoading: false,
                        success: true,
                        successMessage: action.payload?.message,
                        atsWorkLoadData: action.payload?.data?.data
                    };
                case ActionTypes.ATS_WORK_LOAD_REPORT_GET_ERROR:
                    return {
                        ...state,
                        isLoading: false,
                        error: action?.payload?.hasError,
                        errorMessage: action.payload?.data?.error?.[0]?.message
                    };
                    case ActionTypes.ATS_CANDIDATE_VERIFIED_REPORT_GET_REQUEST:
                        return {
                            ...state,
                            isLoading: true,
                            error: false,
                            success: false
                        };
                    case ActionTypes.ATS_CANDIDATE_VERIFIED_REPORT_GET_SUCCESS:
                        return {
                            ...state,
                            isLoading: false,
                            success: true,
                            successMessage: action.payload?.message,
                            atsCandidateVerifiedData: action.payload?.data?.data
                        };
                    case ActionTypes.ATS_CANDIDATE_VERIFIED_REPORT_GET_ERROR:
                        return {
                            ...state,
                            isLoading: false,
                            error: action?.payload?.hasError,
                            errorMessage: action.payload?.data?.error?.[0]?.message
                        };
                        case ActionTypes.ATS_QUALITY_REPORT_GET_REQUEST:
                            return {
                                ...state,
                                isLoading: true,
                                error: false,
                                success: false
                            };
                        case ActionTypes.ATS_QUALITY_REPORT_GET_SUCCESS:
                            return {
                                ...state,
                                isLoading: false,
                                success: true,
                                successMessage: action.payload?.message,
                                atsQualityReport: action.payload?.data?.data
                            };
                        case ActionTypes.ATS_QUALITY_REPORT_GET_ERROR:
                            return {
                                ...state,
                                isLoading: false,
                                error: action?.payload?.hasError,
                                errorMessage: action.payload?.data?.error?.[0]?.message
                            };
        default:
            return state;
    }
}