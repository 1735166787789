import { Card, CardContent, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import SelectableSearch from '../../../Base/views/SelectableSearch'
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system'
import { createStyles, makeStyles, styled } from '@mui/styles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { GetEmployeePaySlipHelper } from '../../helper/PaySlipHelper'
import { Download } from '@mui/icons-material'
import PaySlipPdf from './PaySlipPdf/PaySlipPdf'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { toast } from 'react-toastify'

const PaySlip = () => {
    const HeaderCell = styled(TableCell)(({ theme }) => ({
        fontSize: "0.75rem",
        fontWeight: 600,
        padding: "10px",
        paddingBottom: "5px"
    }))
    const PayrollDataCell = styled(TableCell)(({ theme }) => ({
        border: "none",
        padding: "7px"
    }))
    const EmpDataCell = styled(TableCell)(({ theme }) => ({
        border: "none",
        padding: "0px"
    }))
    const TotalSectionDiv = styled('div')(({ theme }) => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: "3px",
        paddingBottom: "3px"
    }))
    const useStyles = makeStyles((theme) =>
        createStyles({
            paper: {
                boxShadow: "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
                borderRadius: "10px",
                minHeight: "70vh", maxHeight: "70vh", overflowY: "auto"
            }
        }),
    );
    const classes = useStyles();
    const [inputData, setInputData] = useState();
    const [errors, setErrors] = useState({});
    const [years, setYears] = useState({ load: true, values: [] });
    const [data, setData] = useState({ year: { id: 0, "year": `${new Date().getFullYear()}` }, month: "0" });
    const monthData = [{ id: 0, name: "January" }, { id: 1, name: "February" }, { id: 2, name: "March" }, { id: 3, name: "April" }, { id: 4, name: "May" }, { id: 5, name: "June" }, { id: 6, name: "July" }, { id: 6, name: "August" }, { id: 8, name: "September" }, { id: 9, name: "October" }, { id: 10, name: "November" }, { id: 11, name: "December" }]
    useEffect(() => {
        getYears();
        //eslint-disable-next-line
    }, [])

    const getYears = () => {
        let year = []
        const cur_year = new Date().getFullYear() - 5;
        for (var i = 1; i <= 5; i++) {
            year.splice(0, 0, { id: i, "year": `${cur_year + i}` });
        }
        setYears({ load: false, values: year });
    }
    const validate = (fieldValues) => {
        let temp = {};
        if (!fieldValues.year) temp.year = "Year is required";
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleChange = (event, value) => {
        if (event === "year") {
            setData({ ...data, [event]: value })
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...data, [event]: value })
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...data, [event.target.name]: event.target.value })
        }
    }

    const handleGeneratePaySlip = () => {
        if (validate(data)) {
            var from_date = new Date(data?.year?.year, data?.month, 1);
            var to_date = new Date(data?.year?.year, data?.month + 1, 0);
            GetEmployeePaySlipHelper({ from_date: from_date.toLocaleDateString('en-CA'), to_date: to_date.toLocaleDateString('en-CA') }).then(res => {

                if (res?.data?.data?.[0]) {
                    toast.success("Pay Slip generated")
                    setInputData(
                        {
                            ...res?.data?.data?.[0],
                        }
                    )
                } else {
                    toast.warning("No data found")
                }

            })
        }
    }
    return (
        <Box sx={{ paddingLeft: "20px", paddingRight: "15px" }}>
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Grid item md={1.5} padding="10px" margin="10px">
                    <FormControl fullWidth sx={{ padding: "5px" }}>
                        <SelectableSearch
                            label="Year"
                            value={data?.year}
                            fieldLabel="year"
                            loading={getYears}
                            required={false}
                            variant={"outlined"}
                            name="year"
                            size="small"
                            onChangeEvent={handleChange}
                            data={years}
                            {...(errors?.year && {
                                error: true,
                                helperText: errors.year,
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={1.5} padding="10px" margin="10px">
                    <FormControl fullWidth sx={{ padding: "5px" }} size="small">
                        <InputLabel id="month-select-small">Month</InputLabel>
                        <Select
                            defaultValue={0}
                            labelId="month-select-small"
                            name="month"
                            id="month-select-small"
                            value={data?.month}
                            label="Month"
                            onChange={handleChange}
                        >
                            {monthData.map((month, index) => (
                                <MenuItem key={index} value={month.id}>{month.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={0.5}>
                    <FormControl sx={{ padding: "5px" }} >
                        <IconButton aria-label="search" title="Search" onClick={handleGeneratePaySlip}>
                            <SearchIcon />
                        </IconButton>
                    </FormControl>
                </Grid>
            </Grid>
            {
                inputData &&
                <Card className={classes.paper}>
                    <CardContent padding="5px 15px 5px 15px">
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item md={4} >
                                <Typography variant="h6">Payslip</Typography>
                            </Grid>
                            <Grid item md={0.5}>
                                <PDFDownloadLink document={<PaySlipPdf payslip={inputData} />} fileName="Salaryslip.pdf">
                                    <IconButton>
                                        <Download />
                                    </IconButton>
                                </PDFDownloadLink>
                            </Grid>
                        </Grid>

                        <Divider />
                        <Grid paddingTop={"10px"} paddingBottom={"10px"} container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item md={4}>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <EmpDataCell><Typography sx={{ paddingRight: "10px" }} fontWeight={600} fontSize={"0.9rem"} variant='body2'>Employee ID</Typography></EmpDataCell>
                                                <EmpDataCell >{inputData?.employee_no}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Employee Name </EmpDataCell>
                                                <EmpDataCell>{inputData?.name}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Location</EmpDataCell>
                                                <EmpDataCell>{inputData?.work_location}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Unit Code</EmpDataCell>
                                                <EmpDataCell>{inputData?.unit_code}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Designation</EmpDataCell>
                                                <EmpDataCell>{inputData?.designation}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Pay Grade</EmpDataCell>
                                                <EmpDataCell>{inputData?.paygrade}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Bank Name</EmpDataCell>
                                                <EmpDataCell>{inputData?.bank_name}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Bank Account</EmpDataCell>
                                                <EmpDataCell>{inputData?.bank_account}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Attendance</EmpDataCell>
                                                <EmpDataCell>{inputData?.attendance}/{inputData?.no_wdays}</EmpDataCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item md={4} >
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <EmpDataCell>Date of Joining</EmpDataCell>
                                                <EmpDataCell sx={{ border: "none", padding: "0px" }}>{inputData?.joining_date}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Date of Birth</EmpDataCell>
                                                <EmpDataCell>{inputData?.birthdate}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>Gender</EmpDataCell>
                                                <EmpDataCell>{inputData?.gender}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>PAN</EmpDataCell>
                                                <EmpDataCell>{inputData?.pan_no}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>PF No.</EmpDataCell>
                                                <EmpDataCell>{inputData?.pf_no}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>UAN</EmpDataCell>
                                                <EmpDataCell>{inputData?.uan_no}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>EPS</EmpDataCell>
                                                <EmpDataCell>{inputData?.eps}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>ESI</EmpDataCell>
                                                <EmpDataCell>{inputData?.esi_no}</EmpDataCell>
                                            </TableRow>
                                            <TableRow>
                                                <EmpDataCell>LoP Days</EmpDataCell>
                                                <EmpDataCell>{inputData?.actual_lop}</EmpDataCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <HeaderCell>EARNINGS</HeaderCell>
                                        <HeaderCell>AMOUNT</HeaderCell>
                                        <HeaderCell>DEDUCTIONS</HeaderCell>
                                        <HeaderCell>AMOUNT</HeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        [...Array(inputData?.ecomps?.length >= inputData?.dcomps?.length ? inputData?.ecomps?.length : inputData?.dcomps?.length)]?.map((payrol, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <PayrollDataCell>{inputData?.ecomps?.[i]?.earnings_name}</PayrollDataCell>
                                                    <PayrollDataCell>{inputData?.ecomps?.[i]?.earnings_value}</PayrollDataCell>
                                                    <PayrollDataCell>{inputData?.dcomps?.[i]?.deductions_name}</PayrollDataCell>
                                                    <PayrollDataCell>{inputData?.dcomps?.[i]?.deductions_value}</PayrollDataCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                    {/* <Divider /> */}
                                    <TableRow>
                                        <PayrollDataCell sx={{ fontWeight: 600 }}>Earnings</PayrollDataCell>
                                        <PayrollDataCell sx={{ fontWeight: 600 }}>{inputData?.ecomps?.reduce((a, b) => +a + +b.earnings_value, 0)}</PayrollDataCell>
                                        <PayrollDataCell sx={{ fontWeight: 600 }}>Deductions</PayrollDataCell>
                                        <PayrollDataCell sx={{ fontWeight: 600 }}>{inputData?.dcomps?.reduce((a, b) => +a + +b.deductions_value, 0)}</PayrollDataCell>
                                    </TableRow>
                                    <TableRow>
                                        <PayrollDataCell sx={{ fontWeight: 600 }}>Gross Pay</PayrollDataCell>
                                        <PayrollDataCell sx={{ fontWeight: 600 }}>{inputData?.ecomps?.reduce((a, b) => +a + +b.earnings_value, 0)}</PayrollDataCell>
                                        <PayrollDataCell sx={{ fontWeight: 600 }}>Net Pay</PayrollDataCell>
                                        <PayrollDataCell sx={{ fontWeight: 600 }}>{inputData?.ecomps?.reduce((a, b) => +a + +b.earnings_value, 0) - inputData?.dcomps?.reduce((a, b) => +a + +b.deductions_value, 0)}</PayrollDataCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Divider />

                        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item sm={6} xs={12} md={2}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <TotalSectionDiv>
                                        <Typography variant='body1' >Allowance</Typography>
                                        <Typography variant='body1' >3600</Typography>
                                    </TotalSectionDiv>
                                    <TotalSectionDiv>
                                        <Typography variant='body1'>Deductions</Typography>
                                        <Typography variant='body1'>0</Typography>
                                    </TotalSectionDiv>
                                    <TotalSectionDiv>
                                        <Typography variant='body1'>Gross Pay</Typography>
                                        <Typography variant='body1'>13600</Typography>
                                    </TotalSectionDiv>
                                    <TotalSectionDiv>
                                        <Typography variant='body1'>Net Pay</Typography>
                                        <Typography variant='body1'>13600</Typography>
                                    </TotalSectionDiv>
                                    <Divider />
                                    <TotalSectionDiv>
                                        <Typography variant='body1' fontWeight={600}>Total</Typography>
                                        <Typography variant='body1' fontWeight={600}>15000</Typography>
                                    </TotalSectionDiv>
                                </div>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            }
        </Box >
    )
}

export default PaySlip