import React, { useEffect, useState } from "react";
import { GetPreScreenTemplateResponseHelper } from "../../../ATSSettings/helper/AtsFormTemplateResponseHelper"
import { Box, Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import { DownloadAttachment } from "../../../Base/helper/baseFunctions";
import { NoData } from "../../../Base/views/NotAuthorized";
import { useParams } from "react-router";
import { makeStyles } from "@mui/styles";
import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";

const PreScreenResponses = () => {
    const { guid } = useParams();
    const decguid = atob(guid);
    const domain = decguid?.split("=")?.[1];
    const res_id = decguid?.split("=")?.[0];
    const useStyles = makeStyles({
        row: {
    padding:"20px 0px",

        },
        label: {
            fontSize: "0.75rem", fontWeight: 400, width: "400px", flexShrink: 0,
            color: "rgb(99, 115, 129)", height: "30px", lineHeight: "15px", margin: "4px 0px 4px 0px",paddingTop:"9px"
        },
        value: {
            display: "flex", flexFlow: "row wrap", alignItems: "center",
            marginTop:"30px", paddingLeft:"20px", backgroundColor:"rgb(245, 245, 245)", minHeight:"40px", borderRadius:"4px"
        }
    });
    const [viewMode, setViewMode] = useState("loading")
    const [response, setResponse] = useState([]);
    const classes = useStyles()

    useEffect(() => {
        GetPreScreenTemplateResponseHelper({ workspace:domain , prescreening_response_id: res_id }).then((res) => {
            if (res?.data?.statuscode === 200) {
                setResponse(res?.data?.data)
                setViewMode("response")
            }
            else {
                setViewMode("not_found")
            }
        })
        //eslint-disable-next-line
    }, [])

    const formattedKey = (key) => {
        return key.replace(/_/g, ' ').replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1));
    };

    return (
        <UnauthorizedBase>
        <Box className="bg_form_main" sx={{minHeight:"90vh", paddingTop:"50px"}}>
            {viewMode === "loading" ?
                <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }} >
                    <Grid item xs={12} sm={12} md={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {Array(4)
                                        .fill()
                                        .map((data, index) => (
                                            <>
                                                <Grid item xs={12} sm={12} md={12} >
                                                    <Skeleton />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} >
                                                    <Skeleton width={120} />
                                                </Grid>
                                            </>))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                : viewMode === "response" ?
                <Box className="response-main"> 
                    {response?.map((row, rowIndex) => (
                        <Box className="response-content" key={rowIndex}>
                           <Box sx={{ padding: "40px 50px 40px" }}>
                            <Typography variant="h4" sx={{ paddingBottom:"20px", lineHeight:"30px"}}>{row?.prescreen_template_name}</Typography>
                                {Object.entries(row?.prescreen_response)?.map(([key, value], index) => (
                                    <Box item xs={12} sm={12} md={12} key={key}>
                                        <div>
                                        <div className={classes.row}>
                                            <span style={{ fontSize:"18px"}} className={classes.label}>{`${index + 1}. ${formattedKey(key)}`}</span>
                                            <div className={classes.value}>{typeof value === "string" && value.includes(".") ? (
                                            <span style={{ color: "blue", textDecoration: "none",cursor: 'pointer', '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }} onClick={() => DownloadAttachment(value.replace(/"/g, ''))}>
                                                Download the Attachment
                                            </span>
                                        ) : (
                                            <span>{Object.keys(value).length > 0 ? formattedKey(value) : ""}</span>
                                        )} </div>
                                        </div>
                                        </div>
                                    </Box>
                                 ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
                     : <NoData title="No Response Found" />
            }

        </Box>
        </UnauthorizedBase>
    )
}

export default PreScreenResponses;