import { Box, Button, FormControl, Grid } from '@mui/material';
import React from 'react';
import { DeleteATSConsulUserHelper, GetAtsAllowedUsersHelper, GetAtsUserHelper } from '../../helper/AtsUserHelper';
import SelectableSearch from '../../../Base/views/SelectableSearch';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from '@mui/material';
import { UpdateStatusHelper } from '../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper';
import { GetAtsUserAction } from '../../../../../store/ATS/User/Action';
import { ATS_USERS_PK } from '../../../../Constants/baseConstant';
import { useEffect } from 'react';


const AddRecruiterPopup = ({jobList,setOpenPopup,currentData}) => {
    const dispatch = useDispatch()
    const [recruiterData, setRecruiterData] = useState({ load: true, values: [] });
    const [newRecruiter,setNewRecruiter] = useState([])
    const [errors,setErrors] = useState({})
    const [adminData,setAdminData] = useState({})
    
    useEffect(() => {
        GetAtsUserHelper({user_type:"Internal"}).then((res)=>{
            const admin = res?.data?.data?.filter(user => user.user_type === "Admin" )
            setAdminData([{
            user_type: admin?.[0]?.user_type,
            recruiter_id: admin?.[0]?.ats_users_id,
            recruiter: admin?.[0]?.first_name + " " + admin?.[0]?.last_name
        }])
    })
    // eslint-disable-next-line
    }, []);



    const validate = (fieldValues) => {
       let temp = { ...errors };
       temp.recruiter_error =  jobList.length !== fieldValues.length ? "Plese select for all jobs" : "";
       setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleSubmit = () => {
        if (validate(newRecruiter)){
            setErrors({})
            DeleteATSConsulUserHelper({recruiter_data: JSON.stringify(newRecruiter),ats_user_id:currentData?.ats_users_id})
            .then((res) => {
                if (res?.data?.statuscode === 200) {
                    UpdateStatusHelper({ update_id: currentData?.ats_users_id, status: "Inactive", column_name: ATS_USERS_PK, successMessage: "User made Inactive Successfully" }).then(res => {
                        if (res?.data?.statuscode === 200) {
                            dispatch(GetAtsUserAction({ user_type: "Consultancy" }));
                        }
                    })
                }
            })
            setOpenPopup(false)
        }
    }
    const handleRecruiterLoading = () => {
        GetAtsAllowedUsersHelper({
                company_id:  "Internal",
                consultancy_id: `{${[currentData?.consultancy_id]}}`
            }).then(res => {
                const consul_user = res?.data?.data?.filter(user => user.recruiter_id !== currentData?.ats_users_id)
                const mergedData = [
                    ...consul_user,
                    ...adminData
                ]
                setRecruiterData({ load: false, values: mergedData})
            });
    }
    const handleChange = (index) => (event,value) => {
        if (value) {
        const updatedRecruiterArray = [...newRecruiter];
        updatedRecruiterArray[index] = {
        ats_jobpost_id: jobList[index]?.ats_jobpost_id,
        recruiter_id: value
        };
        setNewRecruiter(updatedRecruiterArray);
       }
        else{
            setNewRecruiter([])
        }
        Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
        validate({newRecruiter});
    }
    return (
    <>
        { errors?.recruiter_error && <span style={{color:'red', display:'flex', justifyContent:'center', fontFamily:'Nunito Sans',fontWeight:500 }}>* Plese fill all job </span> }
        <Grid container sx={{display:"flex"}}>
        {jobList.map((value, id) => { 
            return ( 
                <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow xs={12} md={6}>
                                <TableCell>
                                <strong>{value?.title}</strong>
                                </TableCell>
                                <TableCell sx={{width:"250px"}}>
                                <FormControl fullWidth key={id} xs={12} md={6}>
                                <SelectableSearch
                                    isOptionEqualToValue={(option, value) => +option.recruiter_id === +value.recruiter_id}
                                    required={true}
                                    label="Primary Recruiter"
                                    value={newRecruiter[id]?.recruiter_id || []}
                                    loading={() => handleRecruiterLoading()}
                                    fieldLabel="recruiter"
                                    variant={"contained"}
                                    name="primary_recruiter_id"
                                    size="small"
                                    data={recruiterData}
                                    onChangeEvent={handleChange(id)}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option?.recruiter} - {option?.comcons_name ? option?.comcons_name : option?.user_type}
                                        </Box>
                                    )}
                                   />
                                    </FormControl>
                                    </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            )})}
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between"  sx={{m:"10px"}}>
                <Button variant="contained" color="primary" className="mpl-primary-btn" 
                onClick={handleSubmit}
                >
                    OK
                </Button>
                <Button variant="contained" color="primary" className="mpl-primary-btn" onClick={()=>setOpenPopup(false)}>
                    Cancel
                </Button>
                </Box>
            </Grid>
    </>
    );
}
export default AddRecruiterPopup;