import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";
import { addSessionData } from "../../Base/helper/baseHelper";
import * as CryptoJS from "crypto-js";
import { GetUserAccessAction } from "../../../../store/Accesses/Action";

export const GetUserRoleAccessHelper = async (data) => {
    return await axiosInstance(`/user/userAccess`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            emp_id: data?.employee_id
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "User Access Retrived", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch User Access", hasError: false, data: err?.response?.data }
        })
}

export const GetUserInfoHelper = async (params, dispatch) => {
    return await axiosInstance(`/user/userInfo`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain,
            emp_id: params?.userid
        }
    })
        .then((res) => {
            toast.dismiss();
            let data = { ...res.data.data?.[0], domain: params?.domain }
            addSessionData({ key: "userData", value: CryptoJS.AES.encrypt(JSON.stringify(data), 'userData').toString() });
            dispatch(GetUserAccessAction(data));
            return { message: "User Info Retrived", hasError: false, data: data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch User Access", hasError: true, data: err?.response?.data }
        })
}



