import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: false,
    error: false,
    errorMessage: "",
    success: false,
    getsuggestions:[]
};

export const AtsProfileScoreReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_PROFILE_SCORE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
                getsuggestions:[]
            };
        case ActionTypes.ATS_PROFILE_SCORE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                getsuggestions: action.payload?.data?.data
            };
        case ActionTypes.ATS_PROFILE_SCORE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                success: false,
            };
        default:
            return state;
    }
}