import UnauthorizedBase from "../../Base/views/UnauthorizedBase";
import { Grid, Box, Typography, Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { MeeplAdminAction } from "../../../../store/MeeplAdmin/AdminUser/Action";
import { CreatedSchemasGetAction } from "../../../../store/MeeplAdmin/Workspace/Action";
import { useDispatch, useSelector } from "react-redux";
import ManageWorkspace from "./ManageWorkspace";
import AdminUsers from "./adminusers/AdminUsers";
import { MeeplAdminGetUserAction } from "../../../../store/MeeplAdmin/Users/Action";
import ConfigurationLandingPage from "./configuration/ConfigurationLandingPage";

const MeeplAdminLandingPage = () => {
  const dispatch = useDispatch();
  const [workspaceManage, setworkspaceManage] = useState(false);
  const [adminUsers, setAdminUsers] = useState(false);
  const [configuration, setConfiguration] = useState(false);
  const { userData } = useSelector((state) => state?.MeeplAdminReducer?.MeeplAdminUserReducer);

  useEffect(() => {
    dispatch(MeeplAdminAction({}));
    dispatch(CreatedSchemasGetAction({ is_active: 'true' }))
    dispatch(MeeplAdminGetUserAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const adminData = [
    { label: "Manage Workspace", onclick: setworkspaceManage },
    { label: "Users", count: userData?.length, onclick: setAdminUsers },
    { label: "Configuration", onclick: setConfiguration },
  ]

  return (
    <>
      <UnauthorizedBase>
        {workspaceManage === true ?
          <ManageWorkspace workspaceManage={workspaceManage} setworkspaceManage={setworkspaceManage} /> :
            adminUsers === true ? <AdminUsers setAdminUsers={setAdminUsers} adminUsers={adminUsers} /> :
              configuration === true ? <ConfigurationLandingPage setConfiguration={setConfiguration} configuration={configuration} /> :
                <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
                  <Typography
                    pt={2}
                    pb={2}
                    display="flex"
                    justifyContent="center"
                    variant="h3"
                    gutterBottom
                  >
                    Meepl Dashboard
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    {
                      adminData?.map((res, id) => {
                        return (
                          <Grid
                            key={id}
                            sx={{
                              height: "228px",
                              padding: "15px !important",
                              cursor: "pointer",
                            }}
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                          >
                            <Card
                              onClick={() => res?.onclick(true)}
                              sx={{
                                display: "block",
                                overflow: "visible",
                                height: "200px",
                              }}
                            >
                              <CardContent
                                sx={{
                                  padding: "12px !important",
                                  height: "200px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid container direction="row">
                                  <Grid item xs={12} display="flex" justifyContent="center">
                                    <Typography className="workspace_name" variant="h5">
                                      {res?.label}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} display="flex" justifyContent="center">
                                    <Typography className="workspace_name" variant="h4">
                                      {res?.count}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Box>
        }
      </UnauthorizedBase >
    </>
  );
};

export default MeeplAdminLandingPage;
