import React from "react";
import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { DeleteEmailTokenHelper, GetEmailTokenHelper } from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import {OfferApproveHelper } from "../../../ATSSettings/helper/AtsFormTemplateResponseHelper";
const OfferApproval = () => {
  const { guid } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const domain = guid.split('=')[1]
  const approver_response = params.get("approved");

  useEffect(() => {
    setTimeout(() => {
      GetEmailTokenHelper({
        domain: domain,
        token: guid,
      }).then((res) => {
        if (res?.data?.data?.[0]?.email_tokens_id) {
          let result = res?.data?.data?.[0]?.json_data?.[0];
          OfferApproveHelper({
            workspace: domain,
            offer_letter_response_id: result?.offer_letter_response_id,
            requested_by_id: result?.request_emp_id,
            requested_date: res?.data?.data?.[0]?.createdon,
            approver_response: approver_response,
            given_by_name: result?.responser_name,
            given_by_email: result?.to,
          }).then((res) => {
            DeleteEmailTokenHelper({
              domain: domain,
              token: guid,
            });
          });
        }
      });
    }, 1000);
    //eslint-disable-next-line
  }, []);

  return (
    <UnauthorizedBase>
      <Box className="bg_form_main">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "90vh" }}
        >
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Box sx={{ paddingTop: "50px", width: "100%" }}>
              <Box
                className="response-main"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  textAlign={"center"}
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                  padding={"20px"}
                >
                  Thanks! You’re all set. Feel free to close this page.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </UnauthorizedBase>
  );
};

export default OfferApproval;
