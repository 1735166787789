import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
};


export const EmployeeBasicReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_EMPLOYEE_BASIC_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                empData: ""
            };
        case ActionTypes.GET_EMPLOYEE_BASIC_SUCCESS:
            return {
                ...state,
                empData: action.payload?.data?.data,
                isLoading: false,
                success: false
            };
            case ActionTypes.ADD_EMPLOYEE_BASIC_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false
            };
        case ActionTypes.ADD_EMPLOYEE_BASIC_SUCCESS:
            return {
                ...state,
                empData: action.payload?.data?.data,
                success: true,
                isLoading: false
            };
        case ActionTypes.ADD_EMPLOYEE_BASIC_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true
            };
        case ActionTypes.UPDATE_EMPLOYEE_BASIC_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false
            };
        case ActionTypes.UPDATE_EMPLOYEE_BASIC_SUCCESS:
            return {
                ...state,
                empData: action.payload?.data?.data,
                success: true,
                isLoading: false
            };
        case ActionTypes.UPDATE_EMPLOYEE_BASIC_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true
            };
        default:
            return state;
    }
}
