import * as React from "react";
import Box from "@mui/material/Box";
import { useDispatch  } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { Divider, Paper, Grid, Container, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepLabel from "@mui/material/StepLabel";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ConfigureDesignation from "./ConfigureDesignation";
import ConfigureWorklocation from "./ConfigureWorklocation";
import history from "../../../../history";
import { AddMenuHeadingAction } from "../../../../store/Menu/Action";
export default function ConfigureStepper({ stepper ,setStepper, title, setTitle, message, setMessage, imageSrc, setImageSrc}) {
  const dispatch = useDispatch();
  const icons = {
    1: <PermContactCalendarIcon sx={{ fontSize:"30px"}} />,
    2: <AccountBalanceIcon  sx={{ fontSize:"30px"}}/>,
  };
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.secondary : "#8f8f94",
    display: "flex",
    height: 22,
    fontSize: 50,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme?.palette?.primary?.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: theme?.palette?.primary?.main,
      zIndex: 1,
      fontSize: 50,
    },
    "& .QontoStepIcon-circle": {
      width: 30,
      height: 30,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      fontWeight: "800",
    },
  }));
  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#e6d9fb7a",
      },
    })
  );
  const classes = useStyles();

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : props?.icon ? (
          icons[props?.icon]
        ) : (
          <Check />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
  };
  const steps = ["Designation", "Work Location"];

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <ConfigureDesignation setActiveStep={setActiveStep} setStepper={setStepper}/>;
      case 1:
        return <ConfigureWorklocation setStepper={setStepper} setTitle={setTitle}  setMessage={setMessage}  setImageSrc={setImageSrc} />;

      default:
        return "Unknown stepIndex";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
 
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  return (
    <>
      {stepper === 1 ? (
        <Container maxWidth="lg">
          <Grid
            spacing={1}
            justifyContent="center"
            marginTop="48px"
            minHeight="75vh"
          >
            <Paper className={classes.paper}>
              <Grid sx={{ width: "100%" }} item>
                <Box
                  sx={{
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "100%",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Grid container>
                      <Grid xs={12} md={3}>
                        <Stepper
                          nonLinear
                          orientation="vertical"
                          activeStep={activeStep}
                          sx={{
                            paddingTop: "15px",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          {steps.map((label, index) => (
                            <Step key={label}>
                              <StepButton
                                color="inherit"
                                onClick={handleStep(index)}
                                sx={{ padding: "10px 16px" }}
                              >
                                <StepLabel
                                  sx={{
                                    ".Mui-active": {
                                      fontWeight: "700!important",
                                    },
                                    ".MuiStepLabel-label":{
                                      fontSize:"20px"
                                    }
                                   
                                  }}
                                  StepIconComponent={QontoStepIcon}
                                >
                                  {label}
                                </StepLabel>
                              </StepButton>
                            </Step>
                          ))}
                        </Stepper>
                      </Grid>
                      <Divider
                        variant="middle"
                        orientation="vertical"
                        flexItem
                        sx={{ paddingTop: "10px" }}
                      />
                      <Grid sx={{ pl: 4 }} xs={12} md={8.9}>
                        <div className="employee">
                          {getStepContent(activeStep)}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      ) : (
        <>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
              marginTop="48px"
            >
              <Grid item>
                <Box
                  sx={{
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "100%",
                  }}
                >
                  <img
                    src={imageSrc}
                    alt="Complete"
                    width={"100px"}
                    height={"100px"}
                  />
                  <Typography
                    component="h1"
                    variant="h2"
                    pb={2}
                    fontWeight={700}
                    lineHeight={1.5}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h4"
                    color="text.secondary"
                    textAlign={"center"}
                    sx={{
                      fontWeight: 600,
                      fontSize: "0.775rem",
                      pb: 2,
                    }}
                  >
                    {message}
                  </Typography>
                  <div
                    style={{
                      width: "100%"
                    }}
                  >
                    <div
                      onClick={() => {
                        sessionStorage.setItem("selectedMenu", "Home");
                        dispatch(AddMenuHeadingAction({ heading: "Home" }));
                        history.push("/home")
                      }}
                      class="btn-conteiner"
                    >
                      <span class="btn-content">
                        <span class="btn-title">Continue</span>
                        <span class="icon-arrow">
                          <svg width="66px" height="43px" viewBox="0 0 66 43">
                            <g
                              id="arrow"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <path
                                id="arrow-icon-one"
                                d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                                fill="#FFFFFF"
                              ></path>
                              <path
                                id="arrow-icon-two"
                                d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                                fill="#FFFFFF"
                              ></path>
                              <path
                                id="arrow-icon-three"
                                d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                                fill="#FFFFFF"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}
