import * as ActionTypes from '../ActionTypes';

export const GetUpcomingHolidaysAction = (params) => {
    return {
        type: ActionTypes.UPCOMING_HOLIDAYS_GET_REQUEST,
        params
    }
}

export const GetHolidayCalendarAction = (params) => {
    return {
        type: ActionTypes.HOLIDAY_CALENDAR_GET_REQUEST,
        params
    }
}

export const AddHolidayCalendarAction = (holidayCalendarDetails) => {
    return {
        type: ActionTypes.HOLIDAY_CALENDAR_ADD_REQUEST,
        holidayCalendarDetails
    }
}
export const UpdateHolidayCalendarAction = (holidayCalendarDetails) => {
    return {
        type: ActionTypes.HOLIDAY_CALENDAR_UPDATE_REQUEST,
        holidayCalendarDetails
    }
}
export const DeleteHolidayCalendarAction = (holidayCalendarDetails) => {
    return {
        type: ActionTypes.HOLIDAY_CALENDAR_DELETE_REQUEST,
        holidayCalendarDetails
    }
}