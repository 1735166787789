import NProgress from 'nprogress';
import { toast } from 'react-toastify';
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetAtsJobStageHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getJobStages`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            ats_jobpost_id: params?.ats_jobpost_id,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const AddAtsJobStageHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addJobStages`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_empid: user?.ats_user_id,
            ats_jobpost_id: params?.ats_jobpost_id,
            name: params?.name,
            indexKey: params?.indexKey
        }
    })
}

export const UpdateAtsJobStageHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateJobStages`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_empid: user?.ats_user_id,
            ats_job_stages_id: params?.ats_job_stages_id,
            ats_jobpost_id: params?.ats_jobpost_id,
            name: params?.name,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Stage Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteAtsJobStageHelper = async (params) => {
    var user = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/ats/deleteJobStage`, {
        method: "DELETE",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            user_empid: user?.ats_user_id,
            ats_job_stages_id: params?.jobStage_id,
            del_candidates: params?.del_candidates,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Job Stage Deleted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}