import { Box } from '@mui/material'
import { useState } from 'react';
import { useEffect } from 'react';
import OfferLetterTemplates from './OfferLetter/OfferLetterTemplates';
import OfferLetterForm from './OfferLetter/OfferLetterForm';

const OfferLetterLandingPage = () => {
    const [currentMode, setCurrentMode] = useState("grid");
    const [data, setData] = useState("");
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <> 
        {
            <Box sx={{ width: '100%', padding: " 0px 10px 25px 10px" }}>
                    {
                            currentMode === "grid" ? <OfferLetterTemplates setCurrentMode={setCurrentMode} setData={setData} data={data}/>
                                : <OfferLetterForm setCurrentMode={setCurrentMode} currentMode={currentMode} setData={setData} data ={data}/>
                    }
                    </Box>
        }
        </>

    )
}

export default OfferLetterLandingPage