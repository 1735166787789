import React from "react";
// import "./HowWork.css";
function HowPage() {
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="cards-section">
            <div className="title-holder">
              <div className="fade-in-move-on-scroll">
                <div className="title-with-emoji">
                  <h2 className="title">How it works in 3 steps</h2>
                  <div className="emoji-holder">
                    <div>😌</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards-container">
              <a
                href="#Product"
                data-w-id="04ac1aa8-de87-99f9-328c-2ebd130a724f"
                className="grey-card w-inline-block"
              >
                <div className="card-number-holder blue">
                  <div className="card-number">01</div>
                  <div className="black-circle"></div>
                </div>
                <div className="card-title-holder">
                  <div className="card-title">
                  	Configure your Hiring WorkFlow
                  </div>
                </div>
                <div className="card-description-holder">
                  <p className="card-description">
                  Set your Interview stages based on the Job description
                  </p>
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle"></div>
                  <div className="small-circle blue-light"></div>
                </div>
              </a>
              <a href="#Pricing" className="grey-card middle w-inline-block">
                <div className="card-number-holder green">
                  <div className="card-number">02</div>
                  <div className="black-circle"></div>
                </div>
                <div className="card-title-holder">
                  <div className="card-title">Invite Company & Candidaite</div>
                </div>
                <div className="card-description-holder">
                  <p className="card-description">
                  The Hiring company & recruiting agency look at one common board to collaborate with the candidate 
                  </p>
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle"></div>
                  <div className="small-circle orange-light"></div>
                </div>
              </a>
              <a href="#About-us" className="grey-card w-inline-block">
                <div className="card-number-holder red">
                  <div className="card-number">03</div>
                  <div className="black-circle"></div>
                </div>
                <div className="card-title-holder">
                  <div className="card-title">Close your Hire Faster</div>
                </div>
                <div className="card-description-holder">
                  <p className="card-description">
                  Hiring team communicates with candidate at various stages to hire faster.
                  </p>
                  <div className="card-link-holder">
                    {/* <div>Learn more</div> */}
                  </div>
                </div>
                <div className="orange-circle-holder">
                  <div className="main-circle"></div>
                  <div className="small-circle red-light"></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowPage;
