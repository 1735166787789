import {
  InputBase,
  Box,
  Button,
  ButtonGroup,
  Paper,
  Grow,
  Popper,
  MenuList,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  TextField,
  FormHelperText,
  Tab,
} from "@mui/material";
import { useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { makeStyles } from "@mui/styles";
import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DescriptionIcon from "@mui/icons-material/Description";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import ViewOffer from "../OfferLetter/ViewOffer";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  PreScreenCandidateEmailHelper,
  UpdateAtsJobKanbanHelper,
  UpdateAtsJobKanbanOfferHelper,
} from "../../helper/AtsJobKanbanHelper";
import { GetAtsCandidateJobHistoryHelper } from "../../helper/AtsCandidateJobHistoryHelper";
import TimeAgo from "../../../Base/views/TimeAgo";
import Avatar from "react-avatar";
import CreatableSearch from "../../../Base/views/CreatableSearch";
import {
  AddAtsJobStatusHelper,
  GetAtsJobStatusHelper,
} from "../../helper/AtsJobStatusHelper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Mention, MentionsInput } from "react-mentions";
import MentionInputStyles from "./MentionStyles/MentionInputStyles";
import { GetAtsJobAccessUsersHelper } from "../../helper/AtsUserHelper";
import RequestFeedback from "./RequestFeedback";
import Popup from "../../../Base/views/Popup";
import { AddNotificationHelper } from "../../../Ancillary/Notification/helper/NotificationHelper";
import { APP_NAME } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";
import { DownloadAttachment } from "../../../Base/helper/baseFunctions";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import history from "../../../../../history";
import { useDispatch } from "react-redux";
import { EnableAddRequestAction } from "../../../../../store/Onboarding/Action";
import CustomRating from "../../../Base/views/Rating";
import { AddMenuHeadingAction } from "../../../../../store/Menu/Action";
import { addSessionData } from "../../../Base/helper/baseHelper";
import SendApprovalOfferLetter from "./SendApprovalOfferLetter";
import SendReshareOfferLetter from "./SendReshareOfferLetter";
import OfferLetterResponseStepper from "../../../ATSSettings/views/OfferLetter/OfferLetterResponseStepper";

const AtsCandidatePreview = ({
  setIsOnboard,
  setIsOffer,
  setElements,
  toggleDrawer,
  data,
  stageData,
  setCandidatePreview,
  setCandidateUpdate,
  currentJobData,
  offerdata,
  setCurrentData
}) => {
  const useStyles = makeStyles({
    row: {
      display: "flex",
      flexDirection: "row",
    },
    label: {
      fontSize: "0.75rem",
      fontWeight: 400,
      width: "120px",
      flexShrink: 0,
      color: "rgb(99, 115, 129)",
      height: "30px",
      lineHeight: "30px",
      margin: "4px 0px 4px 0px",
    },
    value: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
    },
  });
  const dispatch = useDispatch();
  const theme = useTheme();
  const date = new Date();
  const default_date = `${date?.getFullYear() +
    "-" +
    ("0" + (date?.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date?.getDate()).slice(-2)
    }`;
  const options = ["Give FeedBack", "Request FeedBack"];
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [previewData, setPreviewData] = useState({
    stage_id: "",
    feedback: "",
    priority: "",
    ats_status_id: "",
    assignee: "",
  });
  const [historyData, setHistoryData] = useState(null);
  const [errors, setErrors] = useState({});
  const userData = getUserData();
  const classes = useStyles();
  const [statusData, setStatusData] = useState({ load: true, values: [] });
  const [stagesData, setStagesData] = useState({ load: true, values: [] });
  const [assigneeData, setAssigneeData] = useState({ load: true, values: [] });
  const [mentionValue, setMentionValue] = useState("");
  const [mentionUsers, setMentionUsers] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [viewOffer, setViewOffer] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openprescreen, setOpenPreScreen] = useState(false);
  const [openreshare, setOpenReshare] = useState(false);
  const [openapprove, setOpenApprove] = useState(false);
  const [isofferview, setIsOfferView] = useState(false);
  const [isofferedit, setIsOfferEdit] = useState(false);
  const [value, setValue] = useState("1");
  useEffect(() => {
    GetAtsCandidateJobHistoryHelper({
      candidate_job_id: data?.candidate_job_id,
    }).then((resp) => {
      setHistoryData(resp?.data?.data?.[0]?.candidatejob);
    });
    data &&
      setPreviewData({
        ...previewData,
        priority: data?.priority,
        stage_id: {
          ats_job_stages_id: data?.ats_job_stages_id,
          name: data?.job_stage_name,
        },
        ats_status_id: {
          ats_status_id: data?.ats_status_id,
          name: data?.job_status_name,
        },
        joined_ctc: data?.joined_ctc,
        accepted_ctc: data?.accepted_ctc,
        joined_date: data?.joined_date,
        offer_released_date: data?.offer_released_date || default_date,
        tentative_joining_date: data?.tentative_joining_date,
        rejected_reason: data?.rejected_reason,
        reverting_reason: data?.reverting_reason,
        assignee: { id: data?.assignee_id, display: data?.assignee_name },
      });
    GetAtsJobAccessUsersHelper({ ats_jobpost_id: data?.ats_jobpost_id, candidate_job_id: data?.candidate_job_id }).then(
      (res) => {
        let arr = [];
        res?.data?.data?.forEach((temp) =>
          arr.push({
            ...temp,
            id: temp?.recruiter_id,
            display: temp?.recruiter,
          })
        );
        setMentionUsers(arr);
      }
    );
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    if (selectedIndex === 1) {
      setOpenPopup(true);
    } else {
      localStorage.setItem("feedbackData", JSON.stringify({ data, userData }));
      const url = "/ats/give-feedback/";
      window.open(url, "_blank");
    }
  };
  const handleApprove = () => {
    setOpenApprove(true);
  };

  const handleReshare = () => {
    setOpenReshare(true);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleClickNotes = () => {
    setOpenNotes(true);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  function createData(name, calories, fat) {
    return { name, calories, fat };
  }

  const rows = [
    createData("Submitted by", 159, 6.0),
    createData("Submitted on", 237, 9.0),
    createData("Feedback", 262, 16.0),
  ];

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("stage_id" in fieldValues) {
      temp.stage_id = fieldValues?.stage_id === "" ? "Stage is required" : "";
    }
    if ("joined_date" in fieldValues) {
      temp.joined_date =
        fieldValues?.joined_date === "" && data?.job_stage_name === "Joined"
          ? "Join date is required"
          : "";
    }
    if ("tentative_joining_date" in fieldValues) {
      temp.tentative_joining_date =
        fieldValues?.tentative_joining_date === "" &&
          data?.job_stage_name === "Joined"
          ? "Tentative joning date is Required"
          : "";
    }
    if ("rejected_reason" in fieldValues) {
      temp.rejected_reason =
        fieldValues?.rejected_reason === "" ? "Reason is required" : "";
    }
    if ("reverting_reason" in fieldValues) {
      temp.reverting_reason =
        fieldValues?.reverting_reason === "" ? "Revert Reason is required" : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleInputChange = (event, value) => {
    if (["stage_id"].includes(event)) {
      if (value) {
        if (value.name === "Offer Accepted") {
          setPreviewData({
            ...previewData,
            [event]: value,
            accepted_ctc: "",
            tentative_joining_date: "",
          });
        } else if (value.name === "Offer Released") {
          setPreviewData({
            ...previewData,
            [event]: value,
            offer_released_date: default_date,
          });
        } else if (value.name === "Joined") {
          addSessionData({ key: "popup", value: "false" });
          setPreviewData({
            ...previewData,
            [event]: value,
            joined_ctc: "",
            joined_date: "",
          });
        } else if (value.name === "Rejected") {
          setPreviewData({
            ...previewData,
            [event]: value,
            rejected_reason: "",
          });
        } else if (data.job_stage_name === "Rejected") {
          setPreviewData({
            ...previewData,
            [event]: value,
            reverting_reason: "",
          });
        } else {
          setPreviewData({ ...previewData, [event]: value });
        }
      } else {
        setPreviewData({ ...previewData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...previewData, [event]: value ? value : "" });
    } else if (["assignee"].includes(event)) {
      if (value) setPreviewData({ ...previewData, [event]: value });
      else setPreviewData({ ...previewData, assignee: "" });
    } else if (["ats_status_id"].includes(event)) {
      if (value) {
        if (value?.id === null) {
          AddAtsJobStatusHelper({ name: value?.name }).then((res) => {
            setStatusData({ load: false, values: res?.data?.data });
            setPreviewData({
              ...previewData,
              ats_status_id: res?.data?.data?.filter(
                (resp) => resp?.name === value?.name
              )?.[0],
            });
          });
        } else setPreviewData({ ...previewData, [event]: value });
      }
    } else {
      setPreviewData({
        ...previewData,
        [event.target.name]: event?.target?.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...previewData, [event.target.name]: event?.target?.value });
    }
  };

  const updateKanban = (kanbanData, stageName) => {
    if (data?.offer_letter) {
      UpdateAtsJobKanbanOfferHelper({
        kanban_data: kanbanData,
      }).then((res) => {
        res?.data?.data && setElements(res?.data?.data);
        GetAtsCandidateJobHistoryHelper({
          candidate_job_id: data?.candidate_job_id,
        }).then((resp) => {
          setHistoryData(resp?.data?.data?.[0]?.candidatejob);
        });
        if (stageName === "Joined") {
          if (data?.access_onboard_candidate) {
            setIsOnboard(true);
          }
        } else if (stageName === "Offer Preparation") {
          if (!data?.offer_preparation_flag) {
            setIsOffer(true);
          }
        }
      });
    } else {
      UpdateAtsJobKanbanHelper({
        kanban_data: kanbanData,
      }).then((res) => {
        res?.data?.data && setElements(res?.data?.data);
        GetAtsCandidateJobHistoryHelper({
          candidate_job_id: data?.candidate_job_id,
        }).then((resp) => {
          setHistoryData(resp?.data?.data?.[0]?.candidatejob);
        });
        if (stageName === "Joined") {
          if (data?.access_onboard_candidate) {
            setIsOnboard(true);
          }
        }
      });
    }
  };
  const handleSubmit = () => {
    if (validate(previewData)) {
      let mystr = mentionValue.substring(1);
      var arry = mystr.split(" @");
      let ids = [];
      var returnArr = [];
      var finalValue = mentionValue;
      for (var a of arry) {
        var obj = {};
        var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
        var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));
        obj[`firstName`] = value;
        obj[`id`] = key;
        ids.push(`(${key})`);
        returnArr.push(obj);
      }
      const unique = [
        ...new Map(returnArr.map((item) => [item["id"], item])).values(),
      ];
      ids?.forEach((dat) => {
        finalValue = finalValue.replace(dat, "");
      });
      finalValue = finalValue.replace(/\[|\]/g, "").replace("@", "");
      updateKanban(
        JSON.stringify([
          {
            proposed_by: data?.proposed_by,
            candidate_job_id: data?.candidate_job_id,
            ats_jobpost_id: data?.ats_jobpost_id,
            interview_feedback: finalValue,
            ats_job_stages_id: previewData?.stage_id?.ats_job_stages_id,
            priority: previewData?.priority,
            ats_status_id: previewData?.ats_status_id?.ats_status_id || null,
            selected: null,
            candidate_id: data?.candidate_id,
            accepted_ctc: previewData?.accepted_ctc
              ? previewData?.accepted_ctc
              : 0,
            tentative_joining_date: previewData?.tentative_joining_date || null,
            joined_ctc: previewData?.joined_ctc ? previewData?.joined_ctc : 0,
            joined_date:
              previewData?.stage_id?.name === "Joined" &&
                (!previewData?.joined_date || previewData?.joined_date === "")
                ? default_date
                : previewData?.joined_date || null,
            offer_released_date:
              previewData?.stage_id?.name === "Offer Released" &&
                (!previewData?.offer_released_date ||
                  previewData?.offer_released_date === "")
                ? default_date
                : previewData?.offer_released_date || default_date,
            rejected_reason: previewData?.rejected_reason || null,
            assignee_id: Number(previewData?.assignee?.id),
            reverting_reason: previewData?.reverting_reason || null,
          },
        ]),
        previewData?.stage_id?.name
      );
      if (unique?.length > 0) {
        let value = [];
        unique?.forEach((res) =>
          value.push({
            user_id: +res?.id,
            comment: `You were noted by ${userData?.user_name} in ${data?.title}. ${finalValue}`,
            is_read: "N",
          })
        );
        AddNotificationHelper({ notification_data: JSON.stringify(value) });
      }
      setMentionValue("");
      setErrors({});
    }
  };
  const handleStatusData = () => {
    GetAtsJobStatusHelper().then((res) =>
      setStatusData({ load: false, values: res?.data?.data })
    );
  };
  const handleClickPreview = () => {
    toggleDrawer(false);
    setCandidatePreview(true);
    setCandidateUpdate(false);
  };
  const handleChange = (e, id) => {
    setMentionValue(e.target.value);
  };
  const handleOnboarding = () => {
    setOpenPopup(false);
    dispatch(EnableAddRequestAction({ mode: "add", data: data }));
    addSessionData({ key: "selectedMenu", value: "Employee Onboarding" });
    dispatch(AddMenuHeadingAction({ heading: "Employee Onboarding" }));
    history.push("/onboarding");
  };
  const handleViewFeedback = (res_id) => {
    const resId = res_id;
    let pre_screen_token = resId + `=${userData?.domain}`;
    const url = `/ats/feedback-responses/${btoa(pre_screen_token)}`;
    window.open(url, "_blank");
  };
  const handleSend = () => {
    PreScreenCandidateEmailHelper({
      jobData: currentJobData,
      kanban_data: data,
    });
    setOpenPreScreen(false);
  };
  const handlePreScreen = () => {
    setOpenPreScreen(true);
  };
  const handleResponseScreen = () => {
    const resId = data?.f_prescreen_response_fk;
    let pre_screen_token = resId + `=${userData?.domain}`;
    const url = `/ats/prescreen-responses/${btoa(pre_screen_token)}`;
    window.open(url, "_blank");
  };
  const handleViewOffer = () => {
    setIsOfferView(true);
  };
  const handleEdit = () => {
    setIsOfferEdit(true);
  };

  const handleStages = () => {
    let filteredData = stageData;

    if (currentJobData?.offer_letter) {
      if (
        data?.job_stage_name === "Offer Preparation" &&
        !data?.offer_preparation_completed
      ) {
        // If in "Offer Preparation" stage and not completed, load all stages
        const excludedStages = ["6", "7", "8", "9"];
        filteredData = stageData.filter((stage) => {
          return !excludedStages.includes(stage.sort_key);
        });
      } else if (
        data?.job_stage_name === "Offer Preparation" ||
        data?.job_stage_name === "Offer Released" ||
        data?.job_stage_name === "Offer Rejected" ||
        data?.job_stage_name === "Offer Accepted" ||
        data?.job_stage_name === "Joined"
      ) {
        // Load all stages without exclusion
        filteredData = stageData;
      } else {
        // Exclude stages with sort_key "6", "7", "8", "9"
        const excludedStages = ["6", "7", "8", "9"];
        filteredData = stageData.filter((stage) => {
          return !excludedStages.includes(stage.sort_key);
        });
      }
    }

    setStagesData({ load: false, values: filteredData });
  };

  return (
    <>
      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Request Feedback"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <RequestFeedback data={data} setOpenPopup={setOpenPopup} />
      </Popup>
      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Request for Offer Letter Approval"
        openPopup={openapprove}
        setOpenPopup={setOpenApprove}
      >
        <SendApprovalOfferLetter
          currentJobData={currentJobData}
          data={data}
          setOpenPopup={setOpenApprove}
          setElements={setElements}
        />
      </Popup>
      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Send Offer Letter"
        openPopup={openreshare}
        setOpenPopup={setOpenReshare}
      >
        <SendReshareOfferLetter
          currentData={data}
          setOpenPopup={setOpenReshare}
          currentJobData={currentJobData}
          setElements={setElements}
        />
      </Popup>

      <Box sx={{ padding: "0px 10px 10px 10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 8px 8px 16px",
          }}
        >
          <div sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h5">
              {data?.full_name || data?.first_name}
            </Typography>
          </div>
          <div>
            {data?.linkedin_profile !== null &&
              data?.linkedin_profile !== "" && (
                <a
                  style={{
                    pointerEvents: data?.linkedin_profile ? "auto" : "none",
                  }}
                  href={data?.linkedin_profile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton>
                    <Tooltip title="LinkedIn Profile">
                      <LinkedInIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </a>
              )}
            <IconButton>
              <Tooltip title="Notes">
                <CommentRoundedIcon
                  onClick={handleClickNotes}
                  sx={{
                    cursor: "pointer",
                    color: theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </Tooltip>
            </IconButton>
            <IconButton onClick={handleClickPreview}>
              <Tooltip title="Preview">
                <VisibilityIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => toggleDrawer(false)}>
              <Tooltip title="Close">
                <CloseIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </div>
        </Box>
        <Divider />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 20px 10px 20px",
              flex: "0 0 60%",
              height: "450px",
              overflowY: "auto",
            }}
          >
            <Typography variant="h5">{data?.name}</Typography>

            <div className={classes.row}>
              <span className={classes.label}>Contact</span>
              <div className={classes.value}>: &nbsp; {data?.contact_no}</div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Email</span>
              <div className={classes.value}>: &nbsp; {data?.email_id}</div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Designation</span>
              <div className={classes.value}>: &nbsp; {data?.designation}</div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Date of Birth</span>
              <div className={classes.value}>: &nbsp; {data?.dob}</div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Gender</span>
              <div className={classes.value}>: &nbsp; {data?.gender}</div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Year of Experience</span>
              <div className={classes.value}>
                : &nbsp; {data?.years_of_experience}
              </div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Primary Skills</span>
              <div className={classes.value}>
                : &nbsp;
                {data?.primary_skill ? (
                  data?.primary_skill.split(",").map((row) => {
                    return (
                      <Chip
                        label={row}
                        variant="outlined"
                        sx={{
                          mr: 2,
                          mb: 2,
                          fontSize: "1 rem",
                        }}
                        mt={1}
                      />
                    );
                  })
                ) : (
                  <Typography variant="span" sx={{ fontSize: "1.7rem" }}>
                    -
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Secondary Skills</span>
              <div className={classes.value}>
                : &nbsp; {data?.secondary_skill}
              </div>
            </div>

            <div className={classes.row}>
              <span className={classes.label}>Current CTC</span>
              <div className={classes.value}>: &nbsp; {data?.current_ctc}</div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Expected Pay</span>
              <div className={classes.value}>: &nbsp; {data?.expected_pay}</div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Current Employer</span>
              <div className={classes.value}>
                : &nbsp; {data?.current_employer}
              </div>
            </div>
            <div className={classes.row}>
              <span className={classes.label}>Notice Period</span>
              <div className={classes.value}>
                : &nbsp; {data?.notice_period}
              </div>
            </div>
            {data?.resume && (
              <div className={classes.row}>
                <span className={classes.label}>Resume</span>
                <div
                  className={classes.value}
                  onClick={() => DownloadAttachment(data?.resume)}
                  style={{ cursor: "pointer" }}
                >
                  : &nbsp;
                  <DescriptionIcon />{" "}
                  <Typography pl={1}>
                    {data?.first_name || ""}_{APP_NAME}.
                    {data?.resume.split(".").at(-1)}
                  </Typography>
                </div>
              </div>
            )}

            {/* has_prescreen_response */}
            <div className={classes.row}>
              <span className={classes.label}>Pre Screen Question</span>
              <div className={classes.value}>
                : &nbsp;
                {data?.f_prescreen_response_fk === "0" ? (
                  <Tooltip
                    title={
                      !currentJobData?.prescreen_template_id
                        ? "Please set pre-screen template for this job first"
                        : ""
                    }
                  >
                    <button
                      disabled={!currentJobData?.prescreen_template_id}
                      onClick={handlePreScreen}
                      className={
                        !currentJobData?.prescreen_template_id
                          ? "cta-disable"
                          : "cta"
                      }
                    >
                      <span className="hover-underline-animation">
                        Collect Response
                      </span>
                      <svg
                        id="arrow-horizontal"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="10"
                        viewBox="0 0 46 16"
                      >
                        <path
                          id="Path_10"
                          data-name="Path 10"
                          fill={
                            !currentJobData?.prescreen_template_id
                              ? "#d3d3d3"
                              : "#883bd5"
                          }
                          d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                          transform="translate(30)"
                        ></path>
                      </svg>
                    </button>
                  </Tooltip>
                ) : (
                  <button onClick={handleResponseScreen} className="cta">
                    <span className="hover-underline-animation">
                      View Response
                    </span>
                    <svg
                      id="arrow-horizontal"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="10"
                      viewBox="0 0 46 16"
                    >
                      <path
                        id="Path_10"
                        data-name="Path 10"
                        fill="#883bd5"
                        d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                        transform="translate(30)"
                      ></path>
                    </svg>
                  </button>
                )}
              </div>
            </div>
            {data?.offer_letter_cloud_path && (
              <div className={classes.row}>
                <span className={classes.label}>Offer Letter</span>
                <div
                  className={classes.value}
                  onClick={() =>
                    DownloadAttachment(data?.offer_letter_cloud_path)
                  }
                  style={{ cursor: "pointer" }}
                >
                  : &nbsp;
                  <DescriptionIcon />{" "}
                  <Typography pl={1}>
                    {data?.first_name || ""}_Offer_Letter.
                    {data?.offer_letter_cloud_path.split(".").at(-1)}
                  </Typography>
                </div>
              </div>
            )}
            <Divider orientation="horizontal" />
            <TabContext value={value}>
              <Box>
                <TabList
                  sx={{
                    minHeight: "40px",
                    ".MuiTab-root": { minHeight: "20px" },
                    "& .MuiTabs-indicator": {
                      width: "100%",
                      display: "flex",
                      justifyContent: "center !important",
                    },
                  }}
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="History" value="1" />
                  <Tab label="Feedback" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {historyData?.map((hist, index) => {
                  let date = new Date(hist?.modifiedon);
                  date.setHours(date.getHours() + 5);
                  date.setMinutes(date.getMinutes() + 30);
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        padding: "10px 10px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Avatar
                          name={`${hist?.requester_name || hist?.modifiedby || ""}`}
                          round="50%"
                          size="30"
                          style={{
                            padding: "0px",
                            fontSize: "10px",
                            marginBottom: 2,
                          }}
                        />
                        <div>
                          <Typography sx={{ paddingLeft: 2 }} variant="body1">
                            <span style={{ fontWeight: 600 }}>
                              {hist?.requester_name || hist?.modifiedby}
                            </span>{" "}
                            {hist?.modifiedon && (
                              <TimeAgo
                                timeStyle="round"
                                date={date}
                                locale="en-US"
                              />
                            )}
                          </Typography>
                          {hist?.is_feedback_record && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              <span style={{ fontWeight: 600 }}>
                                {hist?.modifiedby}
                              </span>{" "}
                              has requested feedback from{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.responder_name}
                              </span>
                            </Typography>
                          )}
                          {hist?.ats_job_stages !== hist?.previous_stage_id &&
                            !hist?.is_feedback_record &&
                            !hist?.requester_name && (
                              <Typography sx={{ paddingLeft: 2 }} variant="body2">
                                Changed the stage{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {hist?.previous_stage_id === "" ||
                                    hist?.previous_stage_id === null
                                    ? "Candidate Proposed"
                                    : `${hist?.previous_stage_id || "None"} → ${hist?.ats_job_stages || "None"
                                    }`}
                                </span>
                              </Typography>
                            )}
                          {hist?.priority !== hist?.previous_priority && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              Changed the priority{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.previous_priority || "None"} →{" "}
                                {hist?.priority || "None"}
                              </span>
                            </Typography>
                          )}
                          {hist?.status !== hist?.previous_status && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              Changed the status{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.previous_status || "None"} →{" "}
                                {hist?.status || "None"}
                              </span>
                            </Typography>
                          )}
                          {hist?.rejected_reason !==
                            hist?.previous_rejected_reason && (
                              <Typography sx={{ paddingLeft: 2 }} variant="body2">
                                Candidate rejected reason{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {hist?.previous_rejected_reason || "None"} →{" "}
                                  {hist?.rejected_reason || "None"}
                                </span>
                              </Typography>
                            )}
                          {hist?.reverting_reason !==
                            hist?.previous_reverting_reason && (
                              <Typography sx={{ paddingLeft: 2 }} variant="body2">
                                Candidate revert reason{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {hist?.previous_reverting_reason || "None"} →{" "}
                                  {hist?.reverting_reason || "None"}
                                </span>
                              </Typography>
                            )}
                          {hist?.selected !== hist?.previous_selected &&
                            hist?.selected === "True" && (
                              <Typography sx={{ paddingLeft: 2 }} variant="body2">
                                Candidate got{" "}
                                <span style={{ fontWeight: 600 }}>Selected</span>
                              </Typography>
                            )}
                          {hist?.selected !== hist?.previous_selected &&
                            hist?.selected === "False" && (
                              <Typography sx={{ paddingLeft: 2 }} variant="body2">
                                Candidate got{" "}
                                <span style={{ fontWeight: 600 }}>Unselected </span>
                              </Typography>
                            )}
                          {hist?.comment && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              Added comment{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.comment}
                              </span>
                            </Typography>
                          )}
                          {hist?.interview_feedback && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              Added feedback{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.interview_feedback}
                              </span>
                              {hist?.requester_name !== null &&
                                hist?.responder_name !== null && (
                                  <span style={{ fontWeight: 600 }}>
                                    {" "}
                                    and eligibility{" "}
                                    {hist?.is_eligible ? "Yes" : "No"}
                                  </span>
                                )}
                              <br />
                              {hist?.feedback_proof_name !== null && (
                                <Typography
                                  sx={{ color: "#1155cc", cursor: "pointer" }}
                                  onClick={() =>
                                    DownloadAttachment(hist.feedback_proof_name)
                                  }
                                >
                                  Feedback Document
                                </Typography>
                              )}
                            </Typography>
                          )}
                          {hist?.feedback_response_id && (
                            <>
                              <Typography sx={{ paddingLeft: 2 }} variant="body2">
                                Added feedback{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {hist?.interview_feedback}
                                </span>
                                {hist?.requester_name !== null &&
                                  hist?.responder_name !== null && (
                                    <span style={{ fontWeight: 600 }}> </span>
                                  )}
                                <br />
                                {hist?.feedback_proof_name !== null && (
                                  <Typography
                                    sx={{ color: "#1155cc", cursor: "pointer" }}
                                    onClick={() =>
                                      DownloadAttachment(hist.feedback_proof_name)
                                    }
                                  >
                                    Feedback Document
                                  </Typography>
                                )}
                              </Typography>
                              <Box
                                sx={{ paddingLeft: 2 }}
                                onClick={() =>
                                  handleViewFeedback(hist?.feedback_response_id)
                                }
                              >
                                <Typography
                                  sx={{
                                    color: "blue",
                                    textDecoration: "none",
                                    "&:hover": {
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    },
                                  }}
                                >
                                  View Feedback
                                </Typography>
                              </Box>
                            </>
                          )}
                          {hist?.tentative_joining_date && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              <span style={{ fontWeight: 600 }}>
                                {hist?.modifiedby}
                              </span>{" "}
                              Added tentative joining date as{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.tentative_joining_date}
                              </span>
                            </Typography>
                          )}

                          {hist?.offer_released_date && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              <span style={{ fontWeight: 600 }}>
                                {hist?.modifiedby}
                              </span>{" "}
                              added released date as{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.offer_released_date}
                              </span>
                            </Typography>
                          )}
                          {hist?.joined_date && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              <span style={{ fontWeight: 600 }}>
                                {hist?.modifiedby}
                              </span>{" "}
                              added joined date as{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.joined_date}
                              </span>
                            </Typography>
                          )}
                          {hist?.assignee !== hist?.previous_assignee && (
                            <Typography sx={{ paddingLeft: 2 }} variant="body2">
                              Changed the assignee{" "}
                              <span style={{ fontWeight: 600 }}>
                                {hist?.previous_assignee || "None"} →{" "}
                                {hist?.assignee || "None"}
                              </span>
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </TabPanel>
              <TabPanel value="2">
                <TableContainer component={Paper} sx={{ width: "92%" }} >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            bgcolor: "",
                            fontWeight: "bold",
                            textAlign: "center",
                            padding: "5px 0px",
                            fontSize: "14px"
                          },
                        }}
                      >
                        <TableCell>Submitted by</TableCell>
                        <TableCell>Submitted on</TableCell>
                        <TableCell>Feedback</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyData
                        ?.filter((hist) => hist?.feedback_response_id)
                        ?.map((hist, index) => {
                          let date = new Date(hist?.modifiedon);
                          date.setHours(date.getHours() + 5);
                          date.setMinutes(date.getMinutes() + 30);
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "& td": { textAlign: "center", padding: "5px 0px", fontSize: "14px" },
                              }}
                            >
                              <TableCell>{hist?.responder_name}</TableCell>
                              <TableCell>
                                {hist?.modifiedon && (
                                  <TimeAgo
                                    timeStyle="round"
                                    date={date}
                                    locale="en-US"
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{ paddingLeft: 2 }}
                                  onClick={() =>
                                    handleViewFeedback(
                                      hist?.feedback_response_id
                                    )
                                  }
                                >
                                  <Typography
                                    sx={{
                                      color: "blue",
                                      textDecoration: "none",
                                      fontSize: "0.75rem",
                                      "&:hover": {
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    View Feedback
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabContext>
          </div>
          <div
            style={{
              flex: "1",
              height: "450px",
              overflowY: "auto",
              marginLeft: "10px",
            }}
          >
            {["Admin", "Owner"].includes(userData?.user_type) &&
              (data?.job_stage_name === "Joined" ||
                data?.job_stage_name === "Offer Accepted") && (
                <>
                  <Divider sx={{ mb: 1 }} />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h5" sx={{ m: 1 }}>
                      Offer Details
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setViewOffer(!viewOffer)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                  {!viewOffer ? (
                    <>
                      <FormControl fullWidth>
                        <TextField
                          sx={{ mb: "10px" }}
                          size="small"
                          value="*******"
                          variant="outlined"
                          fullWidth
                          id="joined_ctc"
                          label={
                            stageData.findIndex(
                              (obj) =>
                                obj.ats_job_stages_id === data.ats_job_stages_id
                            ) ===
                              stageData.length - 1 ||
                              previewData?.stage_id.name === "Joined"
                              ? "Joined CTC"
                              : "Accepted CTC"
                          }
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          sx={{ mb: "10px" }}
                          size="small"
                          value="*******"
                          variant="outlined"
                          fullWidth
                          id="joined_ctc"
                          label={
                            stageData.findIndex(
                              (obj) =>
                                obj.ats_job_stages_id === data.ats_job_stages_id
                            ) ===
                              stageData.length - 1 ||
                              previewData?.stage_id.name === "Joined"
                              ? "Joined Date"
                              : "Tentative Joining Date"
                          }
                        />
                      </FormControl>
                    </>
                  ) : stageData.findIndex(
                    (obj) => obj.ats_job_stages_id === data.ats_job_stages_id
                  ) ===
                    stageData.length - 1 ||
                    previewData?.stage_id.name === "Joined" ? (
                    <>
                      <FormControl fullWidth>
                        <TextField
                          sx={{ mb: "10px" }}
                          size="small"
                          type="number"
                          value={previewData?.joined_ctc}
                          onChange={handleInputChange}
                          variant="outlined"
                          fullWidth
                          id="joined_ctc"
                          label="Joined CTC"
                          name="joined_ctc"
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: "10px" }}>
                        <CommonDatePicker
                          size="small"
                          required
                          name="joined_date"
                          label="Joined Date"
                          pickerType="date"
                          onKeyDown={(e) => e.preventDefault()}
                          onKeyPress={(e) => e.preventDefault()}
                          pickerFormat="dd/MM/yyyy"
                          DefaultDTvalue={previewData?.joined_date}
                          handleChange={(value) =>
                            handleInputChange({
                              target: {
                                name: "joined_date",
                                value: value
                                  ? `${value?.getFullYear() +
                                  "-" +
                                  ("0" + (value?.getMonth() + 1)).slice(
                                    -2
                                  ) +
                                  "-" +
                                  ("0" + value?.getDate()).slice(-2)
                                  }`
                                  : "",
                              },
                            })
                          }
                          {...(errors.joined_date && {
                            error: true,
                            helperText: errors.joined_date,
                          })}
                        />
                      </FormControl>
                    </>
                  ) : (
                    (stageData.findIndex(
                      (obj) => obj.ats_job_stages_id === data.ats_job_stages_id
                    ) ===
                      stageData.length - 2 ||
                      previewData?.stage_id.name === "Offer Accepted") && (
                      <>
                        <FormControl fullWidth>
                          <TextField
                            sx={{ mb: "10px" }}
                            size="small"
                            type="number"
                            value={previewData?.accepted_ctc}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            id="accepted_ctc"
                            label="Accepted CTC"
                            name="accepted_ctc"
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: "10px" }}>
                          <CommonDatePicker
                            size="small"
                            required
                            name="tentative_joining_date"
                            label="Tentative Joining Date"
                            pickerType="date"
                            onKeyDown={(e) => e.preventDefault()}
                            onKeyPress={(e) => e.preventDefault()}
                            pickerFormat="dd/MM/yyyy"
                            DefaultDTvalue={previewData?.tentative_joining_date}
                            handleChange={(value) =>
                              handleInputChange({
                                target: {
                                  name: "tentative_joining_date",
                                  value: value
                                    ? `${value?.getFullYear() +
                                    "-" +
                                    ("0" + (value?.getMonth() + 1)).slice(
                                      -2
                                    ) +
                                    "-" +
                                    ("0" + value?.getDate()).slice(-2)
                                    }`
                                    : "",
                                },
                              })
                            }
                            {...(errors.tentative_joining_date && {
                              error: true,
                              helperText: errors.tentative_joining_date,
                            })}
                          />
                        </FormControl>
                      </>
                    )
                  )}
                </>
              )}
            {["Admin", "Owner"].includes(userData?.user_type) &&
              (data?.job_stage_name === "Offer Released" ||
                data?.job_stage_name === "Joined" ||
                previewData?.stage_id.name === "Offer Released") && (
                <>
                  {(data?.job_stage_name === "Offer Released" || previewData?.stage_id.name === "Offer Released") && (
                    <>
                      <Divider sx={{ mb: 1 }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h5" sx={{ m: 1 }}>
                          Offer Details
                        </Typography>
                      </Box>
                    </>
                  )}
                  <FormControl fullWidth sx={{ mb: "10px" }}>
                    <CommonDatePicker
                      size="small"
                      name="offer_released_date"
                      label="Offer Released Date"
                      pickerType="date"
                      onKeyDown={(e) => e.preventDefault()}
                      onKeyPress={(e) => e.preventDefault()}
                      pickerFormat="dd/MM/yyyy"
                      DefaultDTvalue={previewData?.offer_released_date}
                      handleChange={(value) =>
                        handleInputChange({
                          target: {
                            name: "offer_released_date",
                            value: value
                              ? `${value?.getFullYear() +
                              "-" +
                              ("0" + (value?.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + value?.getDate()).slice(-2)
                              }`
                              : "",
                          },
                        })
                      }
                    />
                  </FormControl>
                </>
              )}
            <Divider sx={{ mb: 2 }} />
            <Grid container>
              <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                <FormControl fullWidth sx={{ pr: 1 }}>
                  <TextField
                    disabled={true}
                    label="Propose By"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={
                      data?.proposed_by_name
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                <FormControl fullWidth sx={{ pr: 1 }}>
                  <SelectableSearch
                    isOptionEqualToValue={(option, value) =>
                      +option.recruiter_id === +value.recruiter_id
                    }
                    label="Assignee"
                    loading={() =>
                      setAssigneeData({ load: false, values: mentionUsers })
                    }
                    value={previewData?.assignee}
                    fieldLabel="display"
                    variant={"contained"}
                    size="small"
                    name="assignee"
                    data={assigneeData}
                    onChangeEvent={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                <FormControl fullWidth sx={{ pr: 1 }}>
                  <SelectableSearch
                    isOptionEqualToValue={(option, value) =>
                      +option.ats_job_stages_id === +value.ats_job_stages_id
                    }
                    label="Select stage"
                    value={previewData?.stage_id}
                    // loading={() =>
                    //   setStagesData({ load: false, values: stageData })
                    // }
                    loading={() => handleStages()}
                    fieldLabel="name"
                    required={true}
                    variant={"contained"}
                    name="stage_id"
                    size="small"
                    data={stagesData}
                    onChangeEvent={handleInputChange}
                    {...(errors?.stage_id && {
                      error: true,
                      helperText: errors?.stage_id,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                <FormControl fullWidth sx={{ pr: 1 }}>
                  <CreatableSearch
                    isOptionEqualToValue={(option, value) =>
                      +option.ats_status_id === +value.ats_status_id
                    }
                    label="Select Status"
                    value={previewData?.ats_status_id}
                    fieldLabel="name"
                    loading={() => handleStatusData()}
                    required={false}
                    variant={"contained"}
                    name="ats_status_id"
                    size="small"
                    data={statusData}
                    onChangeEvent={handleInputChange}
                  />
                </FormControl>
              </Grid>
              {previewData?.stage_id.name === "Rejected" && (
                <FormControl
                  size="small"
                  required
                  fullWidth
                  error={errors?.reason ? true : false}
                >
                  <InputLabel id="recruiter-type-select-label">
                    Reason
                  </InputLabel>
                  <Select
                    name="rejected_reason"
                    id="rejected_reason"
                    value={previewData?.rejected_reason}
                    label="Status"
                    onChange={handleInputChange}
                    {...(errors.rejected_reason && {
                      error: true,
                      helperText: errors.rejected_reason,
                    })}
                  >
                    <MenuItem value={"Fake Candidate"}>Fake Candidate</MenuItem>
                    <MenuItem value={"Technical Reject"}>
                      Technical Reject
                    </MenuItem>
                    <MenuItem value={"Cultural Reject"}>
                      Cultural Reject
                    </MenuItem>
                    <MenuItem value={"No Show"}>No Show</MenuItem>
                    <MenuItem value={"Doesn't fit the role"}>
                      Doesn't fit the role
                    </MenuItem>
                    <MenuItem value={"Internal approval rejected"}>
                      Internal approval rejected
                    </MenuItem>
                  </Select>
                  <FormHelperText>{errors?.reason}</FormHelperText>
                </FormControl>
              )}
              {data.job_stage_name === "Rejected" && (
                <>
                  <TextField
                    label={"Reverting the Rejection Reason"}
                    name="reverting_reason"
                    id="reverting_reason"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={previewData?.reverting_reason}
                    fullWidth
                    onChange={handleInputChange}
                    {...(errors.reverting_reason && {
                      error: true,
                      helperText: errors.reverting_reason,
                    })}
                  />
                </>
              )}
              <Grid item xs={12} md={12}>
                <Typography sx={{ pl: 1 }}>Candidate Score</Typography>
                <CustomRating
                  max={10}
                  name={"priority"}
                  value={previewData?.priority}
                  handleInputChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <div style={{ margin: "1px 0px 0px 3px" }}>
              <MentionsInput
                style={MentionInputStyles}
                value={mentionValue}
                onChange={(e, id) => handleChange(e, id)}
                placeholder={"Mention people using '@'"}
              >
                <Mention
                  displayTransform={(ats_users_id, first_name) =>
                    `@${first_name}`
                  }
                  data={mentionUsers}
                  displayName="first_name"
                />
              </MentionsInput>
            </div>
            <Box
              padding="16px 5px 16px 5px"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {data.job_stage_name === "Joined" ? (
                <Tooltip
                  title={
                    data?.access_onboard_candidate === false ||
                      data?.access_onboard_candidate === null
                      ? "You don't have sufficient permission to perform this action. Please contact Admin."
                      : ""
                  }
                >
                  <div>
                    <Button
                      disabled={
                        data?.is_onboarded === true ||
                        data?.access_onboard_candidate === false ||
                        data?.access_onboard_candidate === null
                      }
                      className="mpl-primary-btn"
                      size="medium"
                      variant="contained"
                      onClick={() => handleOnboarding()}
                    >
                      Onboard Candidate
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <ButtonGroup
                  disabled={!data?.feedback_template_id}
                  variant="contained"
                  ref={anchorRef}
                  aria-label="split button"
                >
                  <Button
                    style={{ borderColor: theme?.palette?.primary?.main }}
                    className="mpl-primary-btn"
                    onClick={handleClick}
                  >
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    className="mpl-primary-btn"
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
              )}
              <Button
                className="mpl-primary-btn"
                size="medium"
                variant="contained"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </Box>

            {data?.offer_letter && (
              <Box
                padding="0px 5px 16px 5px"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {(data?.job_stage_name === "Offer Preparation" ||
                  data?.job_stage_name === "Offer Released" ||
                  data?.job_stage_name === "Offer Rejected" ||
                  data?.job_stage_name === "Offer Accepted" ||
                  data?.job_stage_name === "Joined") && (
                    <Button
                      disabled={!data?.offer_preparation_completed}
                      className="mpl-primary-btn"
                      size="medium"
                      variant="contained"
                      onClick={handleViewOffer}
                    >
                      View Offer
                    </Button>
                  )}
                {data?.job_stage_name === "Offer Preparation" && (
                  <Button
                    disabled={
                      data?.managers_approval ||
                      !data?.offer_preparation_completed
                    }
                    className="mpl-primary-btn"
                    size="medium"
                    variant="contained"
                    onClick={handleApprove}
                  >
                    Send Approval
                  </Button>
                )}
                {(data?.job_stage_name === "Offer Released" ||
                  data?.job_stage_name === "Offer Rejected" ||
                  data?.job_stage_name === "Offer Accepted" ||
                  data?.job_stage_name === "Joined") && (
                    <Button
                      className="mpl-primary-btn"
                      size="medium"
                      variant="contained"
                      onClick={handleReshare}
                    >
                      Send Offer
                    </Button>
                  )}
                {data?.job_stage_name === "Offer Preparation" && (
                  <Button
                    disabled={!data?.offer_preparation_completed}
                    className="mpl-primary-btn"
                    size="medium"
                    variant="contained"
                    onClick={handleEdit}
                  >
                    Edit Offer
                  </Button>
                )}
              </Box>
            )}
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
        <Popup
          fullWidth={true}
          maxWidth="md"
          title="Notes"
          openPopup={openNotes}
          setOpenPopup={setOpenNotes}
        >
          <div>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <InputBase
                  value={data?.notes}
                  multiline
                  rows={15}
                  fullWidth
                  name="notes"
                  disabled
                  sx={{ padding: "5px 0px" }}
                  placeholder="Notes About Candidate..."
                />
              </Box>
            </Box>
            <Divider sx={{ borderStyle: "dashed" }} />
          </div>
        </Popup>
        <Popup
          fullWidth={true}
          maxWidth="sm"
          title="Confirmation"
          openPopup={openprescreen}
          setOpenPopup={setOpenPreScreen}
        >
          <Typography variant="h6">
            Do you want to send mail to candidate ?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
            <Button
              className="mpl-secondary-btn"
              variant="outlined"
              onClick={(e) => setOpenPreScreen(false)}
            >
              Skip
            </Button>
            <Button
              className="mpl-primary-btn"
              variant="contained"
              onClick={handleSend}
              sx={{ ml: 2 }}
            >
              Send Mail
            </Button>
          </Box>
        </Popup>
        <Popup
          maxWidth="lg"
          title="Preview Offer Letter"
          isMasterProduct={false}
          setOpenPopup={setIsOfferView}
          openPopup={isofferview}
        >
          <ViewOffer data={data} />
        </Popup>
        <Popup
          fullWidth
          maxWidth="xl"
          title="Edit Offer Letter"
          isMasterProduct={false}
          setOpenPopup={setIsOfferEdit}
          openPopup={isofferedit}
        >
          <OfferLetterResponseStepper
            currentData={data}
            currentJobData={currentJobData}
            setIsOfferForm={setIsOfferEdit}
            setElements={setElements}
            OfferEdit={"true"}
            setCurrentData={setCurrentData}
          />
        </Popup>
      </Box>
    </>
  );
};
export default AtsCandidatePreview;
