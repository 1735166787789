import * as ActionTypes from '../ActionTypes';

const structureInitialState = {
    isLoading: false,
    success: false,
    error: false,
    salaryStructure_data: []
}

export const SalaryStructureReducer = function (state = structureInitialState, action) {
    switch (action.type) {
        case ActionTypes.SALARY_STRUCTURE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.SALARY_STRUCTURE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                salaryStructure_data: action.payload?.data?.data
            };
        case ActionTypes.SALARY_STRUCTURE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.SALARY_STRUCTURE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.SALARY_STRUCTURE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                salaryStructure_data: action.payload?.data?.data
            };
        case ActionTypes.SALARY_STRUCTURE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.SALARY_STRUCTURE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.SALARY_STRUCTURE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                salaryStructure_data: action.payload?.data?.data
            };
        case ActionTypes.SALARY_STRUCTURE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.SALARY_STRUCTURE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.SALARY_STRUCTURE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                salaryStructure_data: action.payload?.data?.data
            };
        case ActionTypes.SALARY_STRUCTURE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}
