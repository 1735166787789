import * as ActionTypes from '../ActionTypes';

export const GetSubBusinessUnitAction = () => {
    return {
        type: ActionTypes.SUB_BUSINESSUNIT_GET_REQUEST,
    }
}

export const AddSubBusinessUnitAction = (params) => {
    return {
        type: ActionTypes.SUB_BUSINESSUNIT_ADD_REQUEST,
        params
    }
}

export const UpdateSubBusinessUnitAction = (params) => {
    return {
        type: ActionTypes.SUB_BUSINESSUNIT_UPDATE_REQUEST,
        params
    }
}

export const DeleteSubBusinessUnitAction = (params) => {
    return {
        type: ActionTypes.SUB_BUSINESSUNIT_DELETE_REQUEST,
        params
    }
}