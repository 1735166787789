
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const rootStore = () => {
  const sagaMiddleWare = createSagaMiddleware();
  return {
    ...createStore(rootReducer, applyMiddleware(sagaMiddleWare)),
    runSaga: sagaMiddleWare.run(rootSaga),
  };
};

export default rootStore;
