import React, { useState } from "react";
import { Box, Button, TextField, Typography, FormControl } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useStyles } from "../../SignIn/views/SignIn.js";
import { signUpEmailHelper } from "../helper/SignUpHelper.js";
import Meepl_logo from "../image/Meepl_Logo.jpeg";
import SelectableSearch from "../../Base/views/SelectableSearch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Signup = ({ setToggle, setShow }) => {
  const initialValues = {
    company_name: "",
    contactno: "",
    emailid: "",
    teamsize: "",
    full_name: "",
  };
  const [SignUpData, setSignUpData] = useState(initialValues);
  const classes = useStyles();
  const [nextButton, setNextButton] = useState(false);
  const [error, setError] = useState({});
  const [emailErrorLabel, setEmailErrorLabrl] = useState("Email is required");
  const [contact_noError, setContact_noError] = useState(
    "Contact no is required"
  );
  const [teamOptions, setTeamOptions] = useState({ load: true, values: [] });

  const isValidEmail = (email) => {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/;
    const patter1 = /^[\w.%+-]+@[\w.-]+\.[A-Za-z]{2,}$/;
    return pattern.test(email) || patter1.test(email);
  };

  const handleClick = (event) => {
    if (nextButton) {
      const updatedErrors = {};

      if (SignUpData.company_name === "") {
        updatedErrors.company_name = true;
      } else {
        updatedErrors.company_name = false;
      }

      if (SignUpData.teamsize === "") {
        updatedErrors.teamsize = true;
      } else {
        updatedErrors.teamsize = false;
      }

      setError(updatedErrors);

      if (!updatedErrors.company_name && !updatedErrors.teamsize) {
        setShow(true);
        signUpEmailHelper(SignUpData)
          .then((res) => {
            if (res?.message?.statuscode === 200) setToggle(true);
            setShow(false);
            setSignUpData(initialValues);
          })
          .catch((err) => {
            setShow(false);
          });
      }
    } else {
      const updatedErrors = {};

      if (SignUpData.full_name === "") {
        updatedErrors.full_name = true;
      } else {
        updatedErrors.full_name = false;
      }

      if (SignUpData.emailid === "") {
        updatedErrors.emailid = true;
      } else if (!isValidEmail(SignUpData.emailid?.trim())) {
        updatedErrors.emailid = true;
        setEmailErrorLabrl("Email is invalid");
      } else {
        updatedErrors.emailid = false;
      }

      if (SignUpData.contactno === "") {
        updatedErrors.contactno = true;
        setContact_noError("Contact no is required");
      } else if (
        !SignUpData.contactno
          ?.toString()
          ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
      ) {
        updatedErrors.contactno = true;
        setContact_noError("Invalid Contact Number.");
      } else {
        updatedErrors.contactno = false;
      }

      setError(updatedErrors);

      if (
        !updatedErrors.emailid &&
        !updatedErrors.full_name &&
        !updatedErrors.contactno
      ) {
        setNextButton(true);
      }
    }
  };

  const options = [
    { value: "1-5", label: "1-5" },
    { value: "5-10", label: "5-10" },
    { value: "10-25", label: "10-25" },
    { value: "25-50", label: "25-50" },
    { value: "50-100", label: "50-100" },
    { value: "greater than 100", label: "More than 100" },
  ];

  const storeSignUpdata = (e, value) => {
    if (e === "teamsize") {
      if (value) setSignUpData({ ...SignUpData, [e]: value });
      else setSignUpData({ ...SignUpData, [e]: "" });
    } else {
      const { name, value } = e.target;
      setSignUpData((preData) => ({ ...preData, [name]: value }));
    }
  };
  return (
    <>
      <Box className="login-box">
        {/* FORM SIGNUP*/}
        <img
          src={Meepl_logo}
          alt="Logo"
          style={{
            width: "300px",
            height: "auto",
            borderRadius: "4%",
            marginBottom: "10%",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        />

        <Typography
          className="head-sign-sec-h5"
          variant="h5"
        >
          Join Us
        </Typography>
        {nextButton ? (
          <>
            <TextField
              sx={{
                padding: "12px 4px",
                margin: "8px 0",
                width: "100%",
              }}
              className={classes.root}
              variant="standard"
              type="text"
              label="Company Name"
              name="company_name"
              onChange={storeSignUpdata}
              error={error.company_name}
              helperText={error.company_name && "Company name is required"}
              value={SignUpData.company_name}
            />

            <FormControl className={classes.root} fullWidth sx={{ mt: 2 }}>
              <SelectableSearch
                label="Team Size"
                fieldLabel="label"
                loading={() => setTeamOptions({ load: false, values: options })}
                variantType={"standard"}
                name="teamsize"
                data={teamOptions}
                size="medium"
                value={SignUpData?.teamsize}
                onChangeEvent={storeSignUpdata}
                handleClick={handleClick}
                error={error.teamsize}
                helperText={error.teamsize && "Team size is required"}
              />
            </FormControl>
            <Button
              onClick={() => setNextButton(false)}
              sx={{ alignSelf: "start", color: "black" }}
              variant="text"
            >
              <ArrowBackIcon style={{ fontSize: "35px" }} />
            </Button>
          </>
        ) : (
          <>
            <TextField
              sx={{
                padding: "12px 4px",
                margin: "8px 0",
                width: "100%",
              }}
              variant="standard"
              type="text"
              // placeholder="Full Name"
              label="Full Name"
              name="full_name"
              className={classes.root}
              onChange={storeSignUpdata}
              error={error.full_name}
              helperText={error.full_name && "Full name is required"}
              value={SignUpData.full_name}
            />
            <TextField
              sx={{
                padding: "12px 4px",
                margin: "8px 0",
                width: "100%",
              }}
              variant="standard"
              type="text"
              // placeholder="Email"
              label="Email"
              name="emailid"
              className={classes.root}
              onChange={storeSignUpdata}
              error={error.emailid}
              helperText={error.emailid && emailErrorLabel}
              value={SignUpData.emailid}
            />
            <TextField
              sx={{
                padding: "12px 4px",
                margin: "8px 0",
                width: "100%",
              }}
              variant="standard"
              type="number"
              label="Contact No"
              name="contactno"
              onChange={storeSignUpdata}
              error={error.contactno}
              helperText={error.contactno && contact_noError}
              value={SignUpData.contactno}
              className={classes.root}
            />
          </>
        )}
        <Button
          onClick={handleClick}
          sx={{ alignSelf: "end", color: "black" }}
          variant="text"
        >
          <ArrowForwardIcon style={{ fontSize: "35px" }} />
        </Button>
      </Box>
    </>
  );
};

export default Signup;
