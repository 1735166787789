import React from 'react'
import Base from '../../Base/views/Base'
import RoleAndAccessLandingPage from './RoleAccess/RoleAccessLandingPage'

const RoleAndAccess = () => {
    return (
        <Base>
            <RoleAndAccessLandingPage />
        </Base>
    )
}

export default RoleAndAccess