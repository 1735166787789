import * as ActionTypes from '../ActionTypes';

const initialState = {
    studylevel_options: [],
    success_message: ""
};

const StudyLevelReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.STUDYLEVEL_GET_REQUEST:
            return {
                ...state,
                studylevel_options: []
            };
        case ActionTypes.STUDYLEVEL_GET_SUCCESS:
            return {
                ...state,
                success_message: action.payload.message,
                error: action.payload.has_error,
                studylevel_options: action.payload?.data?.data
            };
        case ActionTypes.STUDYLEVEL_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.STUDYLEVEL_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                studylevel_options: action.payload?.data?.data
            };
        case ActionTypes.STUDYLEVEL_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.STUDYLEVEL_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.STUDYLEVEL_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                studylevel_options: action.payload?.data?.data
            };
        case ActionTypes.STUDYLEVEL_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.STUDYLEVEL_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.STUDYLEVEL_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                studylevel_options: action.payload?.data?.data
            };
        case ActionTypes.STUDYLEVEL_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}

export default StudyLevelReducer;