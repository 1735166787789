import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoginCart from "./LoginCart";
import PasswordPage from "./PasswordPage";
import Signup from "../../SignUp/views/SignUp";
import BG_image from "../image/BG_image.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SignInAction } from "../../../../store/Signin/Action";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BasicModal from "../views/ModalComponent";
import history from "../../../../history";
import SignUpCard from "../../SignUp/views/SignUpCard";
import SignIn from "./SignIn";
import { CLEAR_ERROR } from "../../../../store/Signin/ActionTypes";

function SlidingSignIn() {
  const { info } = useParams();
  const [nextLogin, setNextLogin] = useState(false);
  const [SigninData, setSigninData] = useState({
    email: "",
    domain: "",
    password: "",
  });
  const [emailErrorLabel, setEmailErrorLabrl] = useState("Email is required");
  const [error, setError] = useState({});
  const [rememberCheck, setRememberCheck] = useState(false);
  const dispatch = useDispatch();
  const { errorMessage, errorToast } = useSelector(
    (state) => state?.SignInReducer
  );
  const { loading, success } = useSelector((state) => state.SignInReducer);
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  let value = useSelector((state) => state?.SlideReducer?.activeComponent);
  const [toggle, setToggle] = useState(false);
  const handleCloseModal = () => {
    dispatch({ type: CLEAR_ERROR });
    if (errorMessage === "Invalid Workspace!!!") {
      history.push("/forgotworkspace") && setOpen(false);
    } else {
      setOpen(false);
    }
  };
  const handleOpenModal = () => setOpen(true);
  const handleRemember = () => {
    if (rememberCheck === false) {
      localStorage.removeItem("userData", "");
    } else if (rememberCheck) {
      localStorage.setItem(
        "userData",
        JSON.stringify({ email: SigninData?.email })
      );
    }
  };

  const isValidEmail = (email) => {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/;
    const patter1 = /^[\w.%+-]+@[\w.-]+\.[A-Za-z]{2,}$/;
    return pattern.test(email) || patter1.test(email);
  };

  const handleClick = (e) => {
    if (!nextLogin) {
      const updatedErrors = {};

      if (SigninData.email === "") {
        updatedErrors.email = true;
        setEmailErrorLabrl("Email is required");
      } else if (!isValidEmail(SigninData.email?.trim())) {
        updatedErrors.email = true;
        setEmailErrorLabrl("Email is invalid");
      } else {
        updatedErrors.email = false;
        setEmailErrorLabrl("Email is required");
      }
      setError(updatedErrors);
      if (!updatedErrors.email) {
        dispatch(
          SignInAction(
            {
              email: SigninData.email,
              guid: info,
              step: 1,
              setNextLogin: setNextLogin,
            },
            dispatch
          )
        );
      }
    } else {
      const updatedErrors = {};
      if (SigninData.password === "") {
        updatedErrors.password = true;
      } else {
        updatedErrors.password = false;
      }
      if (SigninData.domain === "") {
        updatedErrors.domain = true;
      } else {
        updatedErrors.domain = false;
      }
      setError(updatedErrors);
      if (!updatedErrors.password && !updatedErrors.domain) {
        handleRemember();
        dispatch(
          SignInAction(
            {
              email: SigninData.email,
              password: SigninData.password,
              domain: SigninData.domain?.domainname,
              guid: info,
              step: 2,
            },
            dispatch
          )
        );
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };
  useEffect(() => {
    if (!value) {
      setToggle(true);
    }
    if (errorMessage === "Password is invalid") {
      errorToast && toast.error(errorMessage);
    }
    (errorMessage === "Invalid Workspace!!!" ||
      errorMessage === "Invalid Email ID!!!") &&
      handleOpenModal();
    rememberCheck && handleRemember();
    const userData = localStorage.getItem("userData");
    if (userData) {
      let data = JSON.parse(userData);
      setSigninData({
        ...SigninData,
        email: data?.email,
      });

      setRememberCheck(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorToast, success, nextLogin]);

  return (
    <>
      <BasicModal
        handleCloseModal={handleCloseModal}
        handleKeyDown={handleKeyDown}
        open={open}
      />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || show}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          display: "flex",
          backgroundImage: `url(${BG_image})`,
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Helvetica",
          height: "100vh",
          width: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Box className="main-card">
          {!nextLogin ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                height: "100%",
                transition: "all 0.6s ease-in-out",
                left: "50%",
                width: "50%",
                opacity: toggle ? 0 : 1,
                zIndex: toggle ? 1 : 5,
                transform: !toggle && "translateX(-100%)",
              }}
            >
              <Signup
                setToggle={setToggle}
                setNextLogin={setNextLogin}
                nextLogin={nextLogin}
                setShow={setShow}
                handleKeyDown={handleKeyDown}
              />
            </Box>
          ) : (
            <PasswordPage
              signinData={SigninData}
              setSigninData={setSigninData}
              error={error}
              handleClick={handleClick}
              handleKeyDown={handleKeyDown}
              nextLogin={setNextLogin}
            />
          )}

          {!nextLogin && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                height: "100%",
                transition: "all 0.6s ease-in-out",
                left: "50%",
                width: "50%",
                zIndex: 2,
                transform: !toggle && "translateX(-100%)",
              }}
            >
              <SignIn
                signinData={SigninData}
                setSigninData={setSigninData}
                handleClick={handleClick}
                emailErrorLabel={emailErrorLabel}
                error={error}
                rememberCheck={rememberCheck}
                setRememberCheck={setRememberCheck}
                handleKeyDown={handleKeyDown}
              />
            </Box>
          )}

          {/* SECOND LAYER */}
          {!nextLogin && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                height: "100%",
                transition: "transform 0.6s ease-in-out",
                left: "0%",
                width: "50%",
                zIndex: 100,
                overflow: "hidden",
                transform: !toggle && "translateX(100%)",
              }}
            >
              <Box
                sx={{
                  background: "#0C0C0C",
                  color: "#ffffff",
                  position: "relative",
                  left: "-100%",
                  height: "100%",
                  width: "200%",
                  transform: toggle ? "translateX(0)" : "translateX(50%)",
                  transition: "transform 0.6s ease-in-out",
                }}
              >
                <LoginCart toggle={toggle} setToggle={setToggle} />
                <SignUpCard toggle={toggle} setToggle={setToggle} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default SlidingSignIn;
