import * as ActionTypes from '../ActionTypes';

export const GetEmployeeOtherPersonalAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_OTHER_PERSONAL_GET_REQUEST,
        params
    }
}

export const UpdateEmployeeOtherPersonalAction = (personalDetails) => {
    return {
        type: ActionTypes.EMPLOYEE_OTHER_PERSONAL_UPDATE_REQUEST,
        personalDetails
    }
}

export const GetEmployeeAddressAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_ADDRESS_GET_REQUEST,
        params
    }
}
export const UpdateEmployeeAddressAction = (addressDetails) => {
    return {
        type: ActionTypes.EMPLOYEE_ADDRESS_UPDATE_REQUEST,
        addressDetails
    }
}
