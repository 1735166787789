import * as ActionTypes from '../ActionTypes';

const professionlSummaryInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: ""
}

export const EmployeeProfessionalSummaryReducer = function (state = professionlSummaryInitialState, action) {
    switch (action.type) {
        case ActionTypes.EMPLOYEE_EDUCATION_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                educationData: []
            };
        case ActionTypes.EMPLOYEE_EDUCATION_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                educationData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_EDUCATION_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.EMPLOYEE_EDUCATION_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.EMPLOYEE_EDUCATION_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                educationData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_EDUCATION_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.EMPLOYEE_EDUCATION_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.EMPLOYEE_EDUCATION_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                educationData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_EDUCATION_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.EMPLOYEE_EDUCATION_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.EMPLOYEE_EDUCATION_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: "Data Deleted Successfully",
                educationData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_EDUCATION_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                workExperienceData: []
            };
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                workExperienceData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                workExperienceData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                workExperienceData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: "Data Deleted Successfully",
                workExperienceData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_WORK_EXPERIENCE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        default:
            return state;
    }
}