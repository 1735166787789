import { Backdrop, Box, Card, CardContent, CircularProgress, Grid, Paper, Tab, Tabs } from "@mui/material";
import Base from "../../../Base/views/Base";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import AssignedTask from "./AssingedTask";
import Notification from "./Notification";
import { useSelector } from "react-redux";
import MyTasks from "./MyTask";
import { useLocation } from "react-router-dom";
import CompletedTask from "./CompletedTask";
import { useDispatch } from "react-redux";
import { GetUserRoleAction } from "../../../../../store/RoleAccess/Role/Action";
import { GetATSModuleFeaturesAction } from '../../../../../store/RoleAccess/ATSModuleFeatures/Action'

const TaskLandingPage = () => {
    const location = useLocation();
    const defaultValue = location.state?.defaultValue || "";
    const [value, setValue] = useState(defaultValue ? defaultValue : 0);
    const { atsUserInfoIsLoading } = useSelector(state => state?.AtsReducer?.AtsUserInfoReducer)
    const [openPopup, setOpenPopup] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [mode, setMode] = useState("");
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetUserRoleAction());
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Job Post" }));
        //eslint-disable-next-line
    }, [])
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Paper sx={{ borderRadius: "10px", maxHeight: "87vh", overflowY: "auto" }}>
                        {children}
                    </Paper>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Base>
            {
                atsUserInfoIsLoading && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            <Box sx={{ display: "flex", flexDirection: "column", maxHeight: "87vh", overflowY: 'auto' }}>
                <Grid container direction={"column"} spacing={2} >
                    <Grid item xs={12} md={12}>

                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ mb: 1 }}>
                            <Tab label="To-Do" {...a11yProps(0)} />
                            <Tab label="Assigned" {...a11yProps(1)} />
                            <Tab label="Done" {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <MyTasks mode={mode} setMode={setMode} openPopup={openPopup} setOpenPopup={setOpenPopup} popupData={popupData} setPopupData={setPopupData} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <AssignedTask mode={mode} setMode={setMode} openPopup={openPopup} setOpenPopup={setOpenPopup} popupData={popupData} setPopupData={setPopupData} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <CompletedTask mode={mode} setMode={setMode} openPopup={openPopup} setOpenPopup={setOpenPopup} popupData={popupData} setPopupData={setPopupData} />
                        </TabPanel>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Card>
                            <CardContent>
                                <Notification sx={{ display: { md: 'flex', sm: "flex", xs: "flex" } }} id={"simple-popover"} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Base>
    )
}

export default TaskLandingPage;