import MaterialTable from 'material-table'
import { useDispatch, useSelector } from 'react-redux';
import { GetAtsUserAction } from '../../../../../store/ATS/User/Action';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useState } from 'react';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
import Popup from '../../../Base/views/Popup';
import AddUserPopup from './AddUserPopup';
import AddRecruiterPopup from './AddRecruiterPopup';
import { useTheme } from "@mui/material/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UpdateStatusHelper } from '../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper';
import { ATS_USERS_PK } from '../../../../Constants/baseConstant';
import { validateEmailHelper } from '../../../SignIn/helper/SigninHelper';
import { getUserData } from '../../../Base/helper/baseFunctions';
import { GetConsulUserJobHelper, ValidateAtsCompConsulStatusHelper, ValidateAtsUserEmailHelper } from '../../helper/AtsUserHelper';
import { ATS_COMPANY_USER_TOGGLE, ATS_CONSULTANCY_USER_TOGGLE, ATS_INTERNAL_USER_TOGGLE, ATS_GROUP_USER_TOGGLE} from '../../../../../store/ATS/ActionTypes';
import CustomToggle from '../../../Base/views/CustomToggle';
import { toast } from 'react-toastify';

const AtsUserGrid = ({ user_type, featuresData }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const user = getUserData()
    const { atsUserData } = useSelector(state => state.AtsReducer?.AtsUserReducer);
    const { atsInternalUserToggle, atsCompanyUserToggle, atsConsultancyUserToggle, atsGroupUserToggle } = useSelector(state => state.AtsReducer?.AtsUserReducer);
    const [currentData, setCurrentData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [openEmailDialog, setOpenEmailDialog] = useState(false)
    const [openPopup, setOpenPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showResetConfirm, setShowResetConfirm] = useState(false);
    const [openJobList,setOpenJobList] = useState(false);
    const [jobList,setJobList] = useState([]);
    const open = Boolean(anchorEl);
    useEffect(() => {
        if (user_type === "Internal") {
            dispatch(GetAtsUserAction({ user_type: "Internal" }));
        } else if (user_type === "Company") {
            dispatch(GetAtsUserAction({ user_type: "Company" }));
        } else if (user_type === "Consultancy") {
            dispatch(GetAtsUserAction({ user_type: "Consultancy" }));
        }
        else if (user_type === "Group User") {
            dispatch(GetAtsUserAction({ user_type: "Group User" }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_type]);

    const handleDisabled = (permission) => {
        const foundPermission = featuresData?.find((item) => item.permission === permission);
        return foundPermission ? true : false;
    };

    const handleClick = (event, data) => {
        setCurrentData(data)
        setAnchorEl(event.currentTarget);

    };
    const handleConfirmArchive = () => {
        setOpenDialog(true);
        setAnchorEl(null)
    };

    const handleArchive = () => {
        if(user_type === "Consultancy") {
            GetConsulUserJobHelper({consul_id:currentData?.ats_users_id}).then((res)=>{
                if (res?.data?.statuscode === 200) {
                    if(res?.data?.data.length > 0) {
                        setOpenJobList(true)
                        setJobList(res?.data?.data)
                    }
                    else{
                        UpdateStatusHelper({ update_id: currentData?.ats_users_id, status: "Inactive", column_name: ATS_USERS_PK, successMessage: "User made Inactive Successfully" }).then(res => {
                            if (res?.data?.statuscode === 200) {
                                dispatch(GetAtsUserAction({ user_type: user_type }));
                            }
                        })
                    }
                }
            })
        }
        else {
        UpdateStatusHelper({ update_id: currentData?.ats_users_id, status: "Inactive", column_name: ATS_USERS_PK, successMessage: "User made Inactive Successfully" }).then(res => {
            if (res?.data?.statuscode === 200) {
                dispatch(GetAtsUserAction({ user_type: user_type }));
            }
        })
        }
        setOpenDialog(false);
    };

    const handleUnArchive = () => {
        if(user_type !== "Internal" ) {
        ValidateAtsCompConsulStatusHelper({company_id: currentData?.ats_company_id || null,consul_id: currentData?.consultancy_id || null}).then((res)=>{
            if(res?.data?.statuscode === 200 && res?.data?.data?.[0].fn_ats_comp_consul_status === "Active" ){
                ValidateAtsUserEmailHelper({ email_id: currentData?.email_id, add_user: true }).then((res) => {
                    if (res?.data?.statuscode === 200) {
                        let case_no = res?.data?.data?.[0]?.case_no;
                        let message = res?.data?.data?.[0]?.message;
                        if (case_no === "case1" || case_no === "case4") {
                            toast.error(message);
                            return true;
                        }
                        else {
                            UpdateStatusHelper({ update_id: currentData?.ats_users_id, status: "Active", column_name: ATS_USERS_PK, successMessage: "User made Active Successfully" }).then(res => {
                                dispatch(GetAtsUserAction({ user_type: user_type }));
                            })
                        }
                    }
                })
            }
            else{
                toast.dismiss()
                toast.error("User is associated with an inactive Client/Hiring Partner. Please make the Client/Hiring Partner active first.")
            }
        })
       }
       else {
        ValidateAtsUserEmailHelper({ email_id: currentData?.email_id, add_user: true }).then((res) => {
            if (res?.data?.statuscode === 200) {
                let case_no = res?.data?.data?.[0]?.case_no;
                let message = res?.data?.data?.[0]?.message;
                if (case_no === "case1" || case_no === "case4") {
                    toast.error(message);
                    return true;
                }
                else {
                    UpdateStatusHelper({ update_id: currentData?.ats_users_id, status: "Active", column_name: ATS_USERS_PK, successMessage: "User made Active Successfully" }).then(res => {
                        dispatch(GetAtsUserAction({ user_type: user_type }));
                    })
                }
            }
        })
       }
       setOpenDialog(false);
    };

    const handleResetPassword = () => {
        validateEmailHelper({ domain: user?.domain, email: currentData?.email_id, message: "Reset Link has been sent to the Email" })
        setShowResetConfirm(false);
    }

    const hanldeEdit = (event, selectedRow) => {
        setCurrentData(selectedRow);
        setOpenPopup(true);
    }

    const handleAdd = () => {
        setCurrentData(null);
        setOpenPopup(true);
    };

    const addMode = (resetForm) => {
        resetForm();
        setCurrentData(null);
        setOpenPopup(false);
    };

    const handleToggle = () => {
        if (user_type === "Internal") {
            dispatch({ type: ATS_INTERNAL_USER_TOGGLE })
        } else if (user_type === "Company") {
            dispatch({ type: ATS_COMPANY_USER_TOGGLE })
        } else if (user_type === "Consultancy") {
            dispatch({ type: ATS_CONSULTANCY_USER_TOGGLE })
        } else if (user_type === "Group User") {
            dispatch({ type: ATS_GROUP_USER_TOGGLE })
        }

    }

    const validateToggle = () => {
        if (user_type === "Internal") {
            return atsInternalUserToggle;
        }
        if (user_type === "Company") {
            return atsCompanyUserToggle;
        }
        if (user_type === "Consultancy") {
            return atsConsultancyUserToggle;
        }
        if (user_type === "Group User") {
            return atsGroupUserToggle;
        }
        
    }

    const getActiveUser = () => {
        let data = atsUserData.filter(function (user) {
            return user.status === 'Active'
        })
        return data
    }
    const getInactiveUser = () => {
        let data = atsUserData.filter(function (user) {
            return user.status === 'Inactive'
        })
        return data
    }

    return (
        <>
            <MaterialTable
                onRowClick={(event, rowData) => hanldeEdit(event, rowData)}
                columns={[
                    { title: "First Name", cellStyle: { padding: "10px 10px 10px 13px" }, field: "first_name", grouping: false },
                    { title: "Last Name", cellStyle: { padding: "10px 10px 10px 13px" }, field: "last_name", grouping: false },
                    { title: "Email", cellStyle: { padding: "10px 10px 10px 13px" }, field: "email_id" },
                    ...(user_type === "Company" || user_type === "Consultancy" || user_type === "Group User"
                        ? []
                        : [
                            {
                                title: "User Type",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "user_type",
                                render: rowData =>
                                    rowData?.user_type === "Company"
                                        ? "Client"
                                        : rowData?.user_type === "Consultancy"
                                            ? "Hiring Partner"
                                        : rowData?.user_type === "Group User"
                                            ? "Group User"
                                            : rowData?.user_type,
                            },
                        ]),
                    ...(user_type === "Company" ? [{ title: "Client", cellStyle: { padding: "10px 10px 10px 13px" }, field: "company" }] : []),
                    ...(user_type === "Consultancy" ? [{ title: "Hiring Partner", cellStyle: { padding: "10px 10px 10px 13px" }, field: "consultancy" }] : []),
                    ...(user_type === "Group User" ? [{ title: "Group Org", cellStyle: { padding: "10px 10px 10px 13px" }, field: "ats_grouporg" }] : []),
                    { title: "User Category", cellStyle: { padding: "10px 10px 10px 13px" }, field: "role_name" },
                ]}

                data={!validateToggle() ? (atsUserData ? getActiveUser() : []) : (atsUserData ? getInactiveUser() : [])}
                options={{
                    maxBodyHeight: "59vh",
                    minBodyHeight: "59vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: {
                        backgroundColor: theme?.palette?.mode === "light" ? theme.palette.grey[0] : theme.palette.grey[500],
                        color: theme?.palette?.mode === "light" ? theme.palette.primary.contrastText : theme.palette.grey[800],
                        fontWeight: "bold",
                        padding: "10px 10px 10px 13px"
                    },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: true,
                    exportFileName: "Recruiters",
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                }}
                actions={[
                    {
                        icon: () => <MoreVertIcon fontSize='small' />,
                        tooltip: "Settings",
                        onClick: (_event, rowData) => { handleClick(_event, rowData) },
                    },
                    {
                        icon: () => (
                            <CustomToggle
                                isChecked={
                                    user_type === "Internal"
                                        ? atsInternalUserToggle
                                        : user_type === "Group User"
                                        ? atsGroupUserToggle
                                        : user_type === "Company"
                                            ? atsCompanyUserToggle
                                            : atsConsultancyUserToggle
                                }
                                setIsChecked={handleToggle}
                                handleToggleChange={handleToggle}
                                Yestext={'Inactive'}
                                NoText={'Active'}
                                style={{ height: '32px', padding: '0px', width: '145px', labelPadding: '1px' }}
                            />
                        ),
                        position: "row",
                        isFreeAction: true,
                        tooltip: "Toggle to view archived users",
                    },
                     user_type !== "Internal" && {
                        icon: () => (
                            <Button
                                component="span"
                                disabled={handleDisabled("ats_users_add_user")}
                                variant="contained"
                                startIcon={<AddIcon />}
                                className="mpl-primary-btn"
                            >
                                Add
                            </Button>
                        ),
                        position: "row",
                        isFreeAction: true,
                        tooltip: handleDisabled("ats_users_add_user")
                            ? "You Don't have sufficient permission. Please contact Admin."
                            : "Add",
                        disabled: handleDisabled("ats_users_add_user"),
                        onClick: (_event, rowData) => handleAdd(),
                    },
                ].filter(Boolean)}
                
                title={validateToggle() ? "Deactivated Users" : "Users"}
            />

            <Menu
                id="more-options-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'more-options-button',
                }}
            >
                {user_type === 'Internal' ? (
                <Tooltip title={validateToggle() ? "Make Active from My Employees" : "Make Inactive from My Employees"} arrow>
                    <div>
                        <MenuItem disabled>
                            <ListItemIcon>
                                <ArchiveIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{validateToggle() ? "Make Active" : "Make Inactive"}</ListItemText>
                        </MenuItem>
                    </div>
                </Tooltip>
            ) : (
                <MenuItem onClick={handleConfirmArchive}>
                    <ListItemIcon>
                        <ArchiveIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{validateToggle() ? "Make Active" : "Make Inactive"}</ListItemText>
                </MenuItem>
            )}
                <MenuItem onClick={() => { setShowResetConfirm(true); setAnchorEl(null); }}>
                    <ListItemIcon>
                        <LockResetIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Reset Password</ListItemText>
                </MenuItem>
            </Menu>

            <Popup
                title={"Add Replacement Recruiter for below Active Jobs"}
                openPopup={openJobList}
                setOpenPopup={setOpenJobList}
                isMasterProduct={true}
            >
                <AddRecruiterPopup
                    jobList={jobList}
                    setOpenPopup={setOpenJobList}
                    currentData = {currentData}
                />
            </Popup>

            <Popup
                title={currentData ? "Edit User" : "Add ATS user confirmation"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                isMasterProduct={true}
            >
                <AddUserPopup
                    editedRecord={currentData}
                    addMode={addMode}
                    setOpenPopup={setOpenPopup}
                    featuresData={featuresData}
                    user_type={user_type}
                />
            </Popup>
            <ConfirmationDialog
                title={validateToggle() ? "Make Active" : "Make Inactive"}
                content={validateToggle() ? "Are you sure want to make active ?" : "Are you sure want to make Inactive ?"}
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Users"
                OkButtonText={validateToggle() ? "Make Active" : "Make Inactive"}
                onSubmit={validateToggle() ? handleUnArchive : handleArchive}
            />
            <ConfirmationDialog
                title="Active User Found"
                content="There is Active user with same email-id. Please edit user to change role or Make Inactive to proceed further"
                openDialog={openEmailDialog}
                closeDialog={setOpenEmailDialog}
                popupTitle="Users"
                CancelButtonText=""
                OkButtonText=""
                onCancel={() => setOpenEmailDialog(false)}
                onSubmit={() => setOpenEmailDialog(false)}
            />
            <ConfirmationDialog
                title="Reset Password"
                content="Are you sure want to sent reset password email ?"
                openDialog={showResetConfirm}
                closeDialog={setShowResetConfirm}
                popupTitle="Users"
                CancelButtonText="Cancel"
                OkButtonText="Send Email"
                onCancel={() => setShowResetConfirm(false)}
                onSubmit={() => handleResetPassword()}
            />
        </>
    )
}

export default AtsUserGrid