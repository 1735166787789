import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import Base from '../../../Base/views/Base';
import Dashboard from './Dashboard';
import ProfileLandingPage from './Profile/ProfileLandingPage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { Link } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RuleIcon from '@mui/icons-material/Rule';

const PayroleLandingPage = () => {

    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <Base>
            <Paper sx={{ borderRadius: "10px", minHeight: "87vh" }}>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList sx={{
                                minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                '& .MuiTabs-indicator': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: "center !important",
                                },
                            }} onChange={handleChange} aria-label="lab API tabs example">
                                <Tab icon={<DashboardIcon fontSize='small' />} iconPosition="start" label="Dashboard" value="1" />
                                <Tab icon={<ManageHistoryIcon fontSize='small' />} iconPosition="start" label="My Profile" value="2" />
                                <Link to="/finance-report" onClick={() => sessionStorage.setItem("selectedMenu", "Reports")}><Tab icon={<AssessmentIcon fontSize='small' />} iconPosition="start" label="My Payslip" /></Link>
                                <Link to="/tax-declaration" onClick={() => sessionStorage.setItem("selectedMenu", "Tax Declaration")}><Tab icon={<RuleIcon fontSize='small' />} iconPosition="start" label="Tax Declaration" /></Link>
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                            <Dashboard />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                            <ProfileLandingPage />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
        </Base>
    );
}

export default PayroleLandingPage;
