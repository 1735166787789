import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Box } from "@mui/material";
import { useState } from "react";
import CreatedSchemas from "./CreatedSchemas";
import SignUpRequest from "./SignUpRequest";
import ExpiryWorkspace from "./ExpiryWorkspace";
import ArrowBackButton from "../../Base/views/ArrowBackButton";

const ManageWorkspace = ({ setworkspaceManage }) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setworkspaceManage(false);
  };

  return (
    <>
      <TabContext value={value}>
        <Box>
          <TabList
            sx={{
              minHeight: "40px",
              margin: "10px 0px 0px 10px",
              ".MuiTab-root": { minHeight: "20px" },
              "& .MuiTabs-indicator": {
                width: "100%",
                display: "flex",
                justifyContent: "center !important",
              },
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Box
              sx={{
                display: "flex",
                WebkitBoxPack: "justify",
                justifyContent: "flex-start",
                padding: "8px 16px 0px 16px",
              }}
            >
              <ArrowBackButton onClick={handleClose} />
            </Box>
            <Tab label="New Workspace Request" value="1" />
            <Tab label="Onboarded Workspace" value="2" />
            <Tab label="Pre Extension Workspace Request" value="3" />
            <Tab label="Expired Workspace Request" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
          <SignUpRequest
            setworkspaceManage={setworkspaceManage}
            setValue={setValue}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: "0px", minHeight: "45px" }}>
          <CreatedSchemas setworkspaceManage={setworkspaceManage} />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: "0px", minHeight: "45px" }}>
          <ExpiryWorkspace value={value} setworkspaceManage={setworkspaceManage} />
        </TabPanel>
        <TabPanel value="4" sx={{ padding: "0px", minHeight: "45px" }}>
          <ExpiryWorkspace value={value} setworkspaceManage={setworkspaceManage} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default ManageWorkspace;
