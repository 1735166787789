import * as ActionTypes from '../ActionTypes';

export const GetCasteAction = () => {
    return {
        type: ActionTypes.CASTE_GET_REQUEST,
    }
}

export const AddCasteAction = (params) => {
    return {
        type: ActionTypes.CASTE_ADD_REQUEST,
        params
    }
}

export const UpdateCasteAction = (params) => {
    return {
        type: ActionTypes.CASTE_UPDATE_REQUEST,
        params
    }
}

export const DeleteCasteAction = (params) => {
    return {
        type: ActionTypes.CASTE_DELETE_REQUEST,
        params
    }
}