import { Box, Button, Skeleton, CardHeader, CardContent, Card, Grid, IconButton, Tooltip, Typography, Divider, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import EmailIcon from '@mui/icons-material/Email';
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import PsychologyIcon from '@mui/icons-material/Psychology';
import LinearProgress from '@mui/material/LinearProgress';
import { getUserData } from "../../../Base/helper/baseFunctions";
import AtsCandidateForm from "../ATSCandidate/AtsCandidateForm";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { AddAtsJobKanbanHelper } from "../../helper/AtsJobKanbanHelper";
import { GetAtsProfileScoreAction } from "../../../../../store/ATS/ProfileScore/Action";
import { ProposeCandidateConfirmationAction } from '../../../../../store/ATS/Candidate/Action';


const AtsSuggestCandidates = ({ editedRecord, setsuggestCandidates, setJobPostFormMode }) => {
  const dispatch = useDispatch();
  const { getsuggestions, isLoading } = useSelector(
    (state) => state.AtsReducer?.AtsProfileScoreReducer
  );
  const [candidatePreview, setCandidatePreview] = useState(false)
  const [currentData, setCurrentData] = useState()
  const [data, setData] = useState()
  const userData = getUserData();
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorE, setAnchorE] = useState(null);
  const [page, setPage] = useState(1);
  const showCandidate = getsuggestions.length === 0;
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!candidatePreview]);
  const getData = (e) => {
    dispatch(GetAtsProfileScoreAction({
      domain: userData?.domain,
      ats_jobpost_id: editedRecord.ats_jobpost_id,
      page_number: typeof e === "number" ? e : 1,
      page_size: 12,
    }));
  }

  const handleClickPreview = (value) => {
    setCurrentData(value)
    setCandidatePreview(true)
  }
  const proposeCandidate = (value) => {
    setData(value)
    setAnchorE(null);
    setOpenDialog(true);
  }
  const handleConfirmPropose = () => {
    AddAtsJobKanbanHelper({
      kanban_data: JSON.stringify([{
        "proposed_by":userData?.ats_user_id,
        "candidate_id": data?.candidate_id, "ats_jobpost_id": editedRecord?.ats_jobpost_id,
        "comment": null, "ats_job_stages_id": null, "priority": 5, "ats_status_id": null
      }]),
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        dispatch(ProposeCandidateConfirmationAction({ candidate_data: data, job_data: editedRecord, kanban_data:res?.data?.data?.[0] }))
      }
      getData();
    })
    setOpenDialog(false);
  };
  const handleClose = () => {
    setJobPostFormMode("Card");
    setsuggestCandidates(false)
  }
  const handlePagination = (event, value) => {
    setPage(value)
    getData(value);
  };
  return (
    <>
      {isLoading ? (
        <>
          <Grid container direction="row" alignItems="center">
            {Array(8)
              .fill()
              .map((data, index) => (
                <Grid key={index} item p={1.5} lg={6} md={6} sm={6} xs={12}>
                  <Card>
                    <CardHeader
                      action={null}
                      title={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      }
                      subheader={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="40%"
                          style={{ marginBottom: 6 }}
                        />
                      }
                    />
                    <Skeleton
                      sx={{ height: 130 }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <CardContent>
                      <>
                        <Skeleton
                          animation="wave"
                          height={10}
                          style={{ marginBottom: 6 }}
                        />
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                        />
                      </>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </>
      ) : (
        <>
          {candidatePreview === true ? <AtsCandidateForm setCandidatePreview={setCandidatePreview} currentData={currentData} isProfile={true} editedRecord={editedRecord} /> :
            <Box sx={{ minHeight: "87vh" }}>
              <Box sx={{ display: "flex", WebkitBoxPack: "justify", justifyContent: "space-between", }}>
                <Typography variant='h5' sx={{ ml: 1, pb: 1 }}>Suggested Candidates</Typography>
                <Button variant='outlined' sx={{ mr: 2 }} className='mpl-secondary-btn' onClick={handleClose}>Back</Button>
              </Box>
              <Box sx={{ p: 2, mt: 2, backgroundColor: "white", borderRadius: "10px", }}>
                {showCandidate > 0 && <Typography display="flex" justifyContent="center">  Candidates Not Available </Typography>}
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  {getsuggestions?.map((su, index) => (
                    <Grid sx={{ padding: "10px" }} key={index} item xs={12} sm={6} md={6} lg={6}>
                      <Card sx={{ padding: "14px", }}>
                        <Box sx={{ paddingRight: "8px" }}>
                          <Box sx={{ display: "flex", WebkitBoxPack: "justify", justifyContent: "space-between", alignItems: "center", width: "100%" }} >
                            <Tooltip placement="bottom-start" title="Name"><Typography sx={{ mb: 0 }} variant='subtitle2' gutterBottom>{su?.first_name} {su?.last_name || ""}</Typography></Tooltip>
                            <IconButton onClick={() => proposeCandidate(su)}><Tooltip title="Propose"><PersonAddAlt1Icon color="success" fontSize='small' /></Tooltip></IconButton>
                          </Box>
                          <Divider sx={{ mb: 2 }} variant="middle" />
                          <Grid sx={{ cursor: "Pointer" }} onClick={() => handleClickPreview(su)} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <Tooltip placement="bottom-start" title="Email"><span style={{ display: "flex" }} ><EmailIcon fontSize='small' /><Typography pl={1} variant='body2' gutterBottom>{su?.email_id}</Typography></span></Tooltip>
                              <Tooltip placement="bottom-start" title="Location"><span style={{ display: "flex" }} ><LocationOnRoundedIcon fontSize='small' /><Typography pl={1} variant='body2'>{su?.location || "-"}</Typography></span></Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <Tooltip placement="bottom-start" title="Primary Skills"><span style={{ display: "flex" }} ><PsychologyIcon fontSize='small' /><Typography pl={1} variant='body2' gutterBottom>{su?.primary_skill}</Typography></span></Tooltip>
                              <Tooltip placement="bottom-start" title="Secondary Skills"><span style={{ display: "flex" }} ><PsychologyIcon fontSize='small' /><Typography pl={1} variant='body2'>{su?.secondary_skill || "-"}</Typography></span></Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <LinearProgress className='progress-value' variant="determinate" value={su?.profile_score ? su?.profile_score : 0} /><span style={{ display: "flex", justifyContent: "center" }}><Typography pl={1} variant='body2'>{su?.profile_score ? `${su?.profile_score}%` : "0%"}</Typography></span>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {getsuggestions?.[0]?.total_count > 12 && (
                <Pagination
                  sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
                  showFirstButton
                  showLastButton
                  count={
                    Number.isInteger(getsuggestions?.[0]?.total_count / 12)
                      ? getsuggestions?.[0]?.total_count / 12
                      : Math.round(getsuggestions?.[0]?.total_count / 12 + 0.5) ||
                      0
                  }
                  page={page}
                  color="secondary"
                  onChange={(event, value) => handlePagination(event, value)}
                />
              )}
            </Box>
          }
        </>
      )}
      <ConfirmationDialog
        title="Propose Candidate"
        content={`Do you wish to Propose ${data?.first_name} to ${editedRecord.title}?`}
        anchorE={anchorE}
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Job"
        OkButtonText="Yes Propose"
        onSubmit={handleConfirmPropose}
      />
    </>
  );
}

export default AtsSuggestCandidates;
