import * as ActionTypes from '../ActionTypes';

export const GetAtsJobFilterAdminAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_FILTER_ADMIN_GET_REQUEST,
        params
    }
}

export const GetAtsJobFilterInternalAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_FILTER_INTERNAL_GET_REQUEST,
        params
    }
}

export const GetAtsJobFilterConsultancyAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_FILTER_CONSULTANCY_GET_REQUEST,
        params
    }
}

export const GetAtsJobFilterCompanyAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_FILTER_COMPANY_GET_REQUEST,
        params
    }
}
export const GetAtsJobFilterGroupOrgAction = (params) => {
    return {
        type: ActionTypes.ATS_JOB_FILTER_GROUP_ORG_GET_REQUEST,
        params
    }
}
