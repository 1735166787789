import * as ActionTypes from '../ActionTypes';

const initialState = {
    casteData: [],
    isLoading: false,
};

const CasteReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CASTE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                casteData: "",
            };
        case ActionTypes.CASTE_GET_SUCCESS:
            return {
                ...state,
                success_message: action.payload.message,
                error: action.payload.has_error,
                isLoading: false,
                casteData: action.payload?.data?.data
            };
        case ActionTypes.CASTE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.CASTE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                casteData: action.payload?.data?.data
            };
        case ActionTypes.CASTE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.CASTE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.CASTE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                casteData: action.payload?.data?.data
            };
        case ActionTypes.CASTE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.CASTE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false,
            };
        case ActionTypes.CASTE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                casteData: action.payload?.data?.data
            };
        case ActionTypes.CASTE_DELETE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action?.payload?.hasError,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        default:
            return state;
    }
}

export default CasteReducer;